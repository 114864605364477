import { Surface,Path,Text,Group,Image,LinearGradient,geometry } from '@progress/kendo-drawing';
const { Rect,Point,Size,transform } = geometry;
import { GetTableInformation,UpdateTableInformation,Groups,Tables,gradient1,gradient2,gradient3 } from './AppCommon.js';

//var Groups = [];
//var Tables = []; 

export function restaurantDisplay(surface) {
  //console.log(surface);
  makeDraggable(surface);

  // Create the square border by drawing a straight path.
  const border = new Path({
    stroke: {
      color: "blue",
      width: 3
    }
  });
  //let getCD = { x: { s: SessionID,q: SessionInfo.sequenceid,t: tableID,k: key,i: pageID,o: operation,y: control,p: callParm,f: modexMethod,m: optionColumns },d: {} };
  //let paths = [];
  //let texts = [];
  //Tables = getTables();
  GetTableInformation(); // Convet Sublocations to Tables
  //console.log(`table: ${JSON.stringify(tables)}`);
  // Load tables
  for (let ix = 0; ix < Tables.length; ix++) { // Create Groups for each Sublocation (Table) & Name
    let tab = Tables[ix];
    //console.log(`rect: ${JSON.stringify(tab)}`);
    //let rPath = new Rect({ x: tab.x,y: tab.y,width: tab.width,height: tab.height,rx: tab.rx });
    //rPath.addAttribute("fill",gradient1);
    const rPath = new Path({
      stroke: {
        color: tab.border,
        width: 5
      },
      fill: gradient3
    });
    rPath.moveTo(0,0).lineTo(tab.width,0).lineTo(tab.width,tab.length).lineTo(0,tab.length).close();
    let left = parseInt(tab.width / 6);
    let ht = parseInt(tab.length / 2);
    const text = new Text(
      tab.name,
      new Point(left,ht),
      { font: 'bold 15px Arial' }
    );
    const grp = new Group();
    grp.append(rPath,text);
    grp.transform(transform().translate(tab.x,tab.y));
    Groups.push(grp);
    //texts.push(text);
    //console.log(grp);
  }
  console.log(`Tables done`);

  // Create the border wall
  border.moveTo(0,0).lineTo(1200,0).lineTo(1200,600).lineTo(0,600).close();
  //for (let ix = 0; ix < Tables.length; ix++) {
  //  console.log(Groups[ix]);
  //}
  //let rct = new Rect({ x: 10,y: 50,width: 100,height: 50,rx: 5,fill: gradient2 });

  function makeDraggable(surface) {
    //console.log(surface);
    //console.log(surface._rootElement);
    var svg = surface._rootElement;

    svg.addEventListener('mousedown',startDrag);
    svg.addEventListener('mousemove',drag);
    svg.addEventListener('mouseup',endDrag);
    svg.addEventListener('mouseleave',endDrag);
    svg.addEventListener('touchstart',startDrag);
    svg.addEventListener('touchmove',drag);
    svg.addEventListener('touchend',endDrag);
    svg.addEventListener('touchleave',endDrag);
    svg.addEventListener('touchcancel',endDrag);

    var selectedElement,offset,transform,bbox,minX,maxX,minY,maxY,confined;
    var tableObj,textObj,groupIx,dragX,dragY

    var boundaryX1 = 10.5;
    var boundaryX2 = 30;
    var boundaryY1 = 2.2;
    var boundaryY2 = 19.2;
    function getMousePosition(evt) {
      var CTM = svg.getScreenCTM();
      if (evt.touches) { evt = evt.touches[0]; }
      return {
        x: (evt.clientX - CTM.e) / CTM.a,
        y: (evt.clientY - CTM.f) / CTM.d
      };
    }
    function startDrag(evt) {
      //if (evt.target.classList.contains('draggable')) { 
      groupIx = -1;
      selectedElement = evt.target;
      if (evt.srcElement._kendoNode) {
        let kndo = evt.srcElement._kendoNode.parent;
        //console.log(`kndo`);
        //console.log(kndo);
        tableObj = kndo.childNodes[0].element._kendoNode.element._kendoNode.element._kendoNode.srcElement;
        //console.log(`table obj`);
        //console.log(tableObj);
        textObj = kndo.childNodes[1].element._kendoNode.element._kendoNode.element._kendoNode.srcElement;
        //console.log(`text obj`);
        //console.log(textObj);
        //let tableNode = kndo.childNodes[0].element._kendoNode;
        //let textNode = kndo.childNodes[1].element._kendoNode;
        //console.log(textNode);   
        //textObj = textNode.element._kendoNode.element._kendoNode.srcElement;
        let txtval = textObj.options.content;
        //console.log(`textval`);
        //console.log(txtval);   
        //console.log(`Moving table: ${txtval}`);
        let objtyp = selectedElement.toString();
        //console.log(`start Drag - typeof: ${typeof selectedElement}`);
        //console.log(`start Drag - typeof: ${objtyp}`);
        //console.log(selectedElement);
        //console.log(evt);
        //console.log(selectedElement);   
        if (objtyp.indexOf("SVGPath") > 0) {
          //console.log(`Find Group`);
          for (let ix = 0; ix < Groups.length; ix++) {
            //console.log(Groups[ix].children);
            if (Tables[ix].name == txtval) {
              //console.log(`Group Found: ${ix}`);
              groupIx = ix;
              //console.log(Groups[ix]);
              break;
            }
          }
          offset = getMousePosition(evt);
          console.log("offset")
          console.log(offset);
          if (groupIx >= 0) { // Only proceed if Index to Tables is found
            // Get mouse offset on table
            let xoff = offset.x - Tables[groupIx].x;
            let yoff = offset.y - Tables[groupIx].y;
            //console.log(`xoff: ${xoff}, yoff: ${yoff}`);
            Tables[groupIx].Xoff = xoff;
            Tables[groupIx].Yoff = yoff;
            // Make sure the first transform on the element is a translate transform  
            //if (selectedElement.transform) {
            //  console.log(`source transform`);
            //  console.log(selectedElement.transform)
            //}
            var transforms = selectedElement.transform.baseVal;
            if (transforms.length === 0 || transforms.getItem(0).type !== SVGTransform.SVG_TRANSFORM_TRANSLATE) {
              // Create an transform that translates by (0, 0) 
              console.log("create Transform for Path Object");
              //console.log(selectedElement.transform);
              var translate = svg.createSVGTransform();
              translate.setTranslate(0,0);
              selectedElement.transform.baseVal.insertItemBefore(translate,0);
            }
            //----------------------------------------
            // Get initial translation
            transform = transforms.getItem(0);
            offset.x -= transform.matrix.e;
            offset.y -= transform.matrix.f;
            //------------ Text Object Transform -----  
            if (textObj.transform) {
              console.log(`text transform`);
              //console.log(textObj.transform)
            }
            // Setup Text Object
            transforms = textObj.transform.baseVal;
            //console.log("TextObj - Transform");
            //console.log(textObj.transform);
            //console.log(transforms);
            //if (!transforms || transforms.length === 0 || transforms.getItem(0).type !== SVGTransform.SVG_TRANSFORM_TRANSLATE) {
            //  // Create an transform that translates by (0, 0)
            //  console.log("create Transform for Text Object");
            //  var translate = svg.createSVGTransform();
            //  translate.setTranslate(0,0);
            //  console.log(textObj.transform);
            //  textObj.transform.baseVal.insertItemBefore(translate,0);
            //}

            confined = evt.target.classList.contains('confine');
            if (confined) {
              bbox = selectedElement.getBBox();
              minX = boundaryX1 - bbox.x;
              maxX = boundaryX2 - bbox.x - bbox.width;
              minY = boundaryY1 - bbox.y;
              maxY = boundaryY2 - bbox.y - bbox.height;
            }
          } else
            selectedElement = undefined;
        } else
          selectedElement = undefined;
      } else
        selectedElement = undefined;
    }
    function drag(evt) {
      if (selectedElement) {
        evt.preventDefault();
        var coord = getMousePosition(evt);
        var dx = coord.x - offset.x;
        var dy = coord.y - offset.y;
        if (confined) {
          if (dx < minX) { dx = minX; }
          else if (dx > maxX) { dx = maxX; }
          if (dy < minY) { dy = minY; }
          else if (dy > maxY) { dy = maxY; }
        }
        transform.setTranslate(dx,dy);
        //console.log(`drag groupIx: ${groupIx}, dx: ${coord.x}, dy: ${coord.y}`);
        //let txtObj = Groups[groupIx];
        //let txtObj = Groups[groupIx].children[1];
        //let txtObj = texts[groupIx];
        //console.log(textObj);
        //console.log(textObj.transform);
        //textObj.transform(coord.x,coord.y);
        dragX = coord.x;
        dragY = coord.y;
        //txtObj.transform(transform().translate(coord.x,coord.y));
      }
    }
    function endDrag(evt) {
      if (groupIx >= 0) {                              
        //console.log(`end Drag - x: ${dragX}, y: ${dragY}`);
        Tables[groupIx].newX = dragX;
        Tables[groupIx].newY = dragY;
        //Groups[groupIx].transform(transform().translate(dragX,dragY));
        //console.log(selectedElement);
        UpdateTableInformation();
      }
      selectedElement = false;
      groupIx = -1;
    }
  }
  //function getTables() {
  //  let tables = [];
  //  tables.push({ x: 10,y: 50,width: 100,height: 50,rx: 5,border: 'red',fill: gradient1, name: 'Table1', newX: 0, newY: 0 });
  //  tables.push({ x: 200,y: 50,width: 100,height: 50,rx: 5,border: 'blue',fill: gradient2,name: 'Table2',newX: 0,newY: 0 });
  //  tables.push({ x: 10,y: 150,width: 100,height: 50,rx: 5,border: 'red',fill: gradient1,name: 'Table3',newX: 0,newY: 0 });
  //  tables.push({ x: 200,y: 150,width: 100,height: 50,rx: 5,border: 'blue',fill: gradient2,name: 'Table4',newX: 0,newY: 0 });
  //  tables.push({ x: 10,y: 300,width: 100,height: 50,rx: 5,border: 'red',fill: gradient1,name: 'Table5',newX: 0,newY: 0 });
  //  tables.push({ x: 200,y: 300,width: 100,height: 50,rx: 5,border: 'blue',fill: gradient2,name: 'Table6',newX: 0,newY: 0 });
  //  return tables;
  //}

  // Render the path on the surface.
  surface.draw(border);
  console.log(`draw Groups`);
  Groups.forEach(grp => { // Display the tables                       
    //console.log(`draw paths: ${JSON.stringify(pth)}`);
    surface.draw(grp);
  });
}