//----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// PageSide - Standard Side Page - Version 1.041- July 29, 2022
//----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
import React, { } from 'react';
import { Link } from 'react-router-dom'
import { TreeView } from '@progress/kendo-react-treeview';
import { SvgIcon } from '@progress/kendo-react-common';
import { CallOM, displayMessage, displayWarning, displayError, LogOffReset } from './CommonCode.js';
import { SessionInfo } from './App';
import { ReactComponent as CompanyLogout } from "./images/company-logout.svg";
import { facebookIcon } from '@progress/kendo-svg-icons';
//import { Block } from 'material-ui-icons'; DO NOT USE - causes build failure on svgicon - no idea why


class PageSide extends React.Component {
  constructor(props) {
    super(props);
    //SessionInfo.History = props.history;
    //console.log("Side Session History: " + JSON.stringify(SessionInfo.History));
    //if (SessionInfo.session === '')
    //  props.history.push("/");
    //if (SessionInfo.PageSide) { // Data saved for this session
    //  this.state = SessionInfo.PageSide;
    //  this.loadExistingPage = true;
    //}
  }
  compUpdate = true;
  async componentDidMount() {
    //console.log('Mount SideBar: ');
  }
  componentWillUnmount() {
    //console.log('UNMount SideBar: ');
  }
  shouldComponentUpdate() {
    //console.log("Should Update?");
    if (this.compUpdate === true)
      return true;
    else
      return false;
  }
  onExpandChange = (event) => {
    event.item.expanded = !event.item.expanded;
    this.forceUpdate();
  }
                               
  onItemClick = async (event) => {
    if (SessionInfo.currentItem !== undefined) {
      //console.log('Menu Item: ' + JSON.stringify(SessionInfo.currentItem));
      SessionInfo.currentItem.selected = false;
      //SessionInfo.currentItem.expanded = false; // This will only close the last item if it is a root element - I don't know how to find the root?
    }
    SessionInfo.currentItem = event.item;
    console.log(`event Item: ${event.item.text}, currentItem: ${SessionInfo.currentItem.text}`);
    event.item.selected = true;
    event.item.expanded = true;
    //console.log("Event - name: " + JSON.stringify(event.item));
    SessionInfo.MenuSelect = event.item;
    if (SessionInfo.menuAction !== undefined) {
      await SessionInfo.menuAction(); // onMenuItemClick in AppMain.js
    }
    this.forceUpdate();
  }
  // Log off, logoff, sign out, sign off, signoff
  handleLogoff = async () => {
    await LogOffReset("Logoff");    
  }
  iconName = (items) => {
    if (items.icon !== undefined)
      return items.icon;
    else
      return undefined;
  }
  iconSpace = (item) => {
    if (item.icon !== undefined)
      return '<span>&nbsp;&nbsp;</span>';
    else
      return undefined;
  }
  // item = { props => [this.iconName(props.item), props.item.text]}
  // item = { props => [<SvgIcon icon={facebookIcon} />, props.item.text]} - works!  
  // item = { props => [<SvgIcon icon={this.iconName(props.item)} />, props.item.text]} - works
  render() {
    return (
      <div id="sideContainer">
        <a id="headerLogo" href="http://www.omnovos.com" target="_blank" rel="noopener noreferrer" />
        <div className="sideMenu">
          <TreeView
            data={SessionInfo.MenuControl}
            expandIcons={true}
            onExpandChange={this.onExpandChange}
            onItemClick={this.onItemClick}
            aria-multiselectable={true}
            className='sideMenuSub'
            item={props => [<span key={props.item.key} className={props.item.className}>{props.item.icon}{props.item.space}{props.item.text}</span>]}
          />
          {/* <SvgIcon viewBox="0 26 100 48" size="medium" themeColor="primary">{props.item.icon2}</SvgIcon> */}
          {/* <span> {props.item.icon2} </span> */}
          {/* <SvgIcon icon={props.item.icon} /> */}
          {/* item={props => [<SvgIcon icon={props.item.icon} />, <span dangerouslySetInnerHTML = { props.item.space } />, props.item.text]} */}
        </div>
        <div className="sideLogout">
          <div className="sideLogoutLabel">
            <label id="logout" style={{ fontSize: '22px', color: '#1B2026', fontWeight: 'bold' }} onClick={this.handleLogoff}> Log Out </label>
          </div>
          <CompanyLogout alt="" id="Logout" title={`Logout ${SessionInfo.Name}`} fill="#1B2026" className="logoutImage" onClick={this.handleLogoff} /> 
        </div>
      </div>
    )
  }
}
      
export default PageSide;