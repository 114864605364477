//----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// CommonInfo - Common Controls Etc
//              Version 1.01 - March 14, 2023
//----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------


export const ENAppCnfg = {
  HasScanAndPay          : 0x0000000000000002,     // ScanAndPay - ScanAndPay
  HasOrderAndPay         : 0x0000000000000004,     // OrderAndPay - OrderAndPay
  ScanPayIndividualItems : 0x0000000000000008,     // ScanPayIndividualItems - ScanPayIndividualItems
  HasIngredients         : 0x0000000000000020,     // HasIngredients - HasIngredients
  HasOrderToTable        : 0x0000000000000040,     // HasOrderToTable - HasOrderToTable
  HasDelivery            : 0x0000000000000080,     // HasDelivery - HasDelivery
  HasTip                 : 0x0000000000000010,     // HasTip - HasTip
  HasItemLookup          : 0x0000000000000100,     // HasItemLookup - HasItemLookup
  HasRecipeLookup        : 0x0000000000000200,     // Has Recipe Lookup - Has Recipe Lookup
  HasStorePromotion      : 0x0000000000000400,    // HasStorePromotion - HasStorePromotion
  HasAccountInfo         : 0x0000000000000800,    // HasAccountInfo - HasAccountInfo
  HasGiftCards           : 0x0000000000001000,    // HasGiftCards - HasGiftCards
  HasCoupons             : 0x0000000000002000,    // HasCoupons - HasCoupons
  HasShoppingList        : 0x0000000000004000,    // HasShoppingList - HasShoppingList
  HasCategoryPage        : 0x0000000000008000,    // CategoryPage - CategoryPage
  HasPartialPayment      : 0x0000000000010000,    // HasPartialPayment - HasPartialPayment
  HasSeats               : 0x0000000000020000,    // HasSeats - HasSeats
  HasGuestInvite         : 0x0000000000040000,    // HasGuestInvite - HasGuestInvite
  HasDiscounts           : 0x0000000000080000,    // HasDiscounts - HasDiscounts
  HasCredit              : 0x0000000000100000,    // HasCredit - HasCredit
  HasReturns             : 0x0000000000400000,    // HasReturns - HasReturns
  HasTax1                : 0x0000000001000000,    // HasTax1 - HasTax1
  HasTax2                : 0x0000000002000000,    // HasTax2 - HasTax2
  HasTax3                : 0x0000000004000000,    // HasTax3 - HasTax3
  HasWorkflow            : 0x0000000008000000,    // HasWorkflow - HasWorkflow
  HasCashPayment         : 0x0000000010000000,    // HasCashPayment - HasCashPayment
  HasVoidOrder           : 0x0000000020000000,    // HasVoidOrder - HasVoidOrder
  HasVoidItem            : 0x0000000040000000,    // HasVoidItem - HasVoidItem
}

export const ENLayoutCnfg = {
  HasLandingPage    : 0x0000000000000002,     // HasLandingPage
  HasLocationInfo   : 0x0000000000000004,     // HasLocationInfo
  HasMenuImage      : 0x0000000000000008,     // HasMenuImage
  UseAlternateItem  : 0x0000000000000010,     // UseAlternateItem
}