//----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// OrderManagement - Custom Order Preparation and Management within a Store or Restuarant
//              Version 1.042 - February 17, 2023
//----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
import React, { Component, useState, useEffect, useMemo, useRef, useCallback} from 'react';
import { withRouter } from './withRouter';
import { Grid as TGrid, GridColumn as Column} from '@progress/kendo-react-grid';
import { TabStrip, TabStripTab, Splitter, TileLayout } from '@progress/kendo-react-layout';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { DropDownList } from '@progress/kendo-react-dropdowns';  
import { DateTimePicker } from '@progress/kendo-react-dateinputs';   
import { Popup } from '@progress/kendo-react-popup';
import { Button } from '@progress/kendo-react-buttons';
//import styled from 'styled-components'
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { orderBy, filterBy } from '@progress/kendo-data-query';
import { ListView,ListViewHeader,ListViewFooter } from '@progress/kendo-react-listview';
import { Switch,MaskedTextBox } from "@progress/kendo-react-inputs";
//--------------                                                           
import { guid } from '@progress/kendo-react-common';
import { IntlProvider, load, LocalizationProvider, loadMessages } from '@progress/kendo-react-intl';
import { Scheduler, TimelineView, DayView, WeekView, MonthView, AgendaView } from '@progress/kendo-react-scheduler';
import { SchedControl,getTaskID } from './CommonControls.js';
import { ENAppCnfg,ENLayoutCnfg } from './CommonInfo.js';
import weekData from 'cldr-core/supplemental/weekData.json';
import currencyData from 'cldr-core/supplemental/currencyData.json';
import likelySubtags from 'cldr-core/supplemental/likelySubtags.json';
import numbers from 'cldr-numbers-full/main/es/numbers.json';
import dateFields from 'cldr-dates-full/main/es/dateFields.json';
import currencies from 'cldr-numbers-full/main/es/currencies.json';
import caGregorian from 'cldr-dates-full/main/es/ca-gregorian.json';
import timeZoneNames from 'cldr-dates-full/main/es/timeZoneNames.json';
import '@progress/kendo-date-math/tz/Etc/UTC';
import '@progress/kendo-date-math/tz/Europe/Sofia';
import '@progress/kendo-date-math/tz/Europe/Madrid';
import '@progress/kendo-date-math/tz/Asia/Dubai';
import '@progress/kendo-date-math/tz/Asia/Tokyo';
import '@progress/kendo-date-math/tz/America/New_York';
import '@progress/kendo-date-math/tz/America/Los_Angeles';
//--------------
import { NumericTextBox } from '@progress/kendo-react-inputs'; 
import { MultiSelect } from '@progress/kendo-react-dropdowns';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { SessionInfo } from './App';
import moment from 'moment';
import PageHeader from './PageHeader.js';
import PageFooter from './PageFooter.js';
import PageSide from './PageSide.js';
//-------------------------------------- TimeZone -----------------------------   
import { timezoneNames } from "@progress/kendo-date-math";
//-----------------------------------------------------------------------------opdownData
import { CallOM,GetTableRow,GetTableRowRaw,GetTableRowTrans,GetTableData,GetTableSearch,UpdateRow,DeleteRowTry,GetFileExtension,displayMessage,displayError,displayNotify,getHelpTopics,getHelpDetail,LogOffResetMsg,GetDropdownData,GetDependentDropdownData,getImageFileFromServer,displayWarning,MaskString,UnmaskString, OMTrace, CTYP } from './CommonCode.js';
import { TimePickerCell,MoneyCell,cellQuantity,cellOptions,cellCurrency,SetProductType,SetProductItemCategory,ProductItemCategoryDropDownCell,SetPricingType,EditGridCommandCell } from './CommonFormat.js';
import { getAmenities } from './AppCommon.js';
//---------------
const StoreLocationsO = [{ n: 'StoreLocationID', t: 24, p: 0 }, { n: 'StoreName', t: 21, p: 0 }, { n: 'Description', t: 21, p: 0 }, { n: 'CompanyID', t: 24, p: 0 }, { n: 'LocationID', t: 24, p: 0 }, { n: 'CurrentlyOpen', t: 22, p: 0 }, { n: 'AreaSize', t: 16, p: 0 }, { n: 'ContactID', t: 24, p: 0 }, { n: 'CurrentWaitTime', t: 24, p: 0 }, { n: 'StoreGroupID', t: 24, p: 0 }, { n: 'TaxRate', t: 16, p: 0 }, { n: 'TaxRate2', t: 16, p: 0 }, { n: 'TaxRate3', t: 16, p: 0 }, { n: 'TaxRuleID', t: 24, p: 0 }, { n: 'TipRate1', t: 16, p: 0 }, { n: 'TipRate2', t: 16, p: 0 }, { n: 'TipRate3', t: 16, p: 0 }, { n: 'MaximumPurchaseAmount', t: 28, p: 0 }, { n: 'StandardMarkupPercent', t: 16, p: 8 }, { n: 'ExtendedMarkupPercent', t: 16, p: 8 }, { n: 'SetPriceType', t: 201, p: 0 }, { n: 'SpecialtyList', t: 21, p: 0 }, { n: 'MarketingMessage', t: 21, p: 0 }, { n: 'PromotionalMessage', t: 21, p: 0 }, { n: 'StoreImage', t: 41, p: 0 }, { n: 'StoreAmenities', t: 15, p: 0 }, { n: 'LocationID', t: 24, p: 0 }, { n: 'LocationName', t: 21, p: 0 }, { n: 'Description', t: 21, p: 0 }, { n: 'CompanyID', t: 24, p: 0 }, { n: 'LocationTypeID', t: 24, p: 0 }, { n: 'UnitNumber', t: 21, p: 0 }, { n: 'Address', t: 21, p: 0 }, { n: 'CityName', t: 21, p: 0 }, { n: 'ProvinceStateID', t: 24, p: 0 }, { n: 'CountryID', t: 24, p: 0 }, { n: 'PostalCode', t: 21, p: 0 }, { n: 'PhoneNumber', t: 21, p: 0 }, { n: 'EmailAddress', t: 21, p: 0 }, { n: 'ContactID', t: 24, p: 0 }, { n: 'AccountID', t: 24, p: 0 }, { n: 'DUNSNumber', t: 21, p: 0 }, { n: 'GeoLocation', t: 21, p: 0 }, { n: 'ControlFlags', t: 27, p: 0 }];
const StoreItemsO = [{ n: 'StoreItemID', t: 195, p: 100 }, { n: 'StoreLocationID', t: 195, p: 100 }, { n: 'ReferenceItemID', t: 195, p: 100 }, { n: 'AssociatedItemID', t: 195, p: 100 }, { n: 'ItemName', t: 21, p: 0 }, { n: 'ItemDescription', t: 21, p: 0 }, { n: 'ItemFullDescription', t: 29, p: 0 }, { n: 'ProductItemCategoryID', t: 195, p: 100 }, { n: 'ProductItemGroupID', t: 195, p: 100 }, { n: 'ProductItemTypeID', t: 195, p: 100 }, { n: 'BrandID', t: 195, p: 100 }, { n: 'UPCNumber', t: 21, p: 0 }, { n: 'BarcodeNumber', t: 21, p: 0 }, { n: 'ItemStatusID', t: 195, p: 100 }, { n: 'PackagingTypeID', t: 195, p: 100 }, { n: 'ItemSizeCodeID', t: 195, p: 100 }, { n: 'TotalQuantity', t: 16, p: 0 }, { n: 'TotalNetWeight', t: 16, p: 0 }, { n: 'ReservedQuantity', t: 16, p: 0 }, { n: 'LockedQuantity', t: 16, p: 0 }, { n: 'AvailableQuantity', t: 16, p: 0 }, { n: 'DocumentID', t: 41, p: 0 }, { n: 'ImageID', t: 41, p: 0 }, { n: 'ItemPrice', t: 28, p: 0 }, { n: 'PromotionPrice', t: 28, p: 0 }, { n: 'Promotion2Price', t: 28, p: 0 }, { n: 'PromotionType', t: 201, p: 0 }, { n: 'StorePromotionID', t: 195, p: 100 }, { n: 'ShelfLifeDays', t: 24, p: 0 }, { n: 'ItemNetWeight', t: 16, p: 0 }, { n: 'PricingTypeID', t: 195, p: 100 }, { n: 'TaxableTypeID', t: 195, p: 100 }, { n: 'RateTypeID', t: 195, p: 100 }, { n: 'SeasonCodeID', t: 195, p: 100 }, { n: 'DayOfWeek', t: 201, p: 0 }, { n: 'DaysOfWeek', t: 15, p: 0 }, { n: 'DayPeriod', t: 201, p: 0 }, { n: 'TimeOfDayID', t: 195, p: 100 }, { n: 'WeightUnitsCodeID', t: 195, p: 100 }, { n: 'StorageControlID', t: 195, p: 100 }, { n: 'IngredientsText', t: 21, p: 0 }, { n: 'UsageInstructions', t: 21, p: 0 }, { n: 'StoreInventoryLocationID', t: 195, p: 100 }, { n: 'TotalReceivedQuantity', t: 16, p: 0 }, { n: 'TotalSoldQuantity', t: 16, p: 0 }, { n: 'TotalDisposedQuantity', t: 16, p: 0 }, { n: 'TotalLostQuantity', t: 16, p: 0 }, { n: 'AssociatedItemCount', t: 24, p: 0 }, { n: 'IsRecordActive', t: 22, p: 0 }];
const StorePromotionsO = [{ n: 'StorePromotionID', t: 24, p: 0 }, { n: 'StorePromotionName', t: 21, p: 0 }, { n: 'PromotionDescription', t: 21, p: 0 }, { n: 'StoreLocationID', t: 24, p: 0 }, { n: 'StoreGroupID', t: 24, p: 0 }, { n: 'StoreItemID', t: 24, p: 0 }, { n: 'ProductItemCategoryID', t: 24, p: 0 }, { n: 'ProductItemGroupID', t: 24, p: 0 }, { n: 'ProductItemTypeID', t: 24, p: 0 }, { n: 'DaysForDiscount', t: 24, p: 0 }, { n: 'DiscountAmount', t: 28, p: 0 }, { n: 'DiscountPercentage', t: 23, p: 8 }, { n: 'Price', t: 28, p: 0 }, { n: 'PromotionType', t: 201, p: 0 }, { n: 'StartDateTime', t: 35, p: 0 }, { n: 'EndDateTime', t: 35, p: 0 }, { n: 'ImageID', t: 41, p: 0 }, { n: 'PromotionIsActive', t: 22, p: 0 }];

//------ Object Definition For CustomOrders -- Date: 2023-11-30-10:40 AM ------
const CustomOrdersOID = 545072;
const CustomOrdersO = [{ n: 'CustomOrderID',t: 195,p: 100 },{ n: 'StoreLocationID',t: 195,p: 100 },{ n: 'CustomerID',t: 195,p: 100 },{ n: 'CustomOrderParentID',t: 195,p: 100 },{ n: 'CreatedBy',t: 195,p: 100 },{ n: 'CustomOrderType',t: 201,p: 0 },{ n: 'RequestDateTime',t: 35,p: 0 },{ n: 'ScheduledPrepDateTime',t: 35,p: 0 },{ n: 'PickupDeliveryDateTime',t: 35,p: 0 },{ n: 'Persons',t: 120,p: 0 },{ n: 'OMUserID',t: 195,p: 100 },{ n: 'TotalTaxAmount',t: 28,p: 0 },{ n: 'TotalTax2Amount',t: 28,p: 0 },{ n: 'TotalTax3Amount',t: 28,p: 0 },{ n: 'AmountPaid',t: 28,p: 0 },{ n: 'DiscountAmount',t: 28,p: 0 },{ n: 'CreditAmountUsed',t: 28,p: 0 },{ n: 'RefundAmount',t: 16,p: 0 },{ n: 'UserName',t: 21,p: 0 },{ n: 'AmountPaidWithTip',t: 28,p: 0 },{ n: 'AddressID',t: 195,p: 100 },{ n: 'PhoneNumber',t: 21,p: 0 },{ n: 'ItemCount',t: 16,p: 0 },{ n: 'OptionCount',t: 24,p: 0 },{ n: 'OptionsPrice',t: 28,p: 0 },{ n: 'TotalQuantity',t: 16,p: 0 },{ n: 'OrderCreationDate',t: 35,p: 0 },{ n: 'OrderPaidDate',t: 35,p: 0 },{ n: 'Rating',t: 16,p: 0 },{ n: 'Persons',t: 120,p: 0 },{ n: 'OMUserID',t: 195,p: 100 },{ n: 'OrderNotes',t: 21,p: 0 },{ n: 'RefundTaxAmount',t: 28,p: 0 },{ n: 'PaymentAuthCode',t: 21,p: 0 },{ n: 'TransactionID',t: 24,p: 0 },{ n: 'UserName',t: 21,p: 0 },{ n: 'OrderSourceID',t: 195,p: 100 },{ n: 'POSCheckNumber',t: 21,p: 0 },{ n: 'ThirdPartyOrderNumber',t: 21,p: 0 },{ n: 'VerifiedDate',t: 35,p: 0 },{ n: 'LastModifiedOn',t: 35,p: 0 },{ n: 'OrderPreparationTypeID',t: 195,p: 100 },{ n: 'ReturnCycle',t: 24,p: 0 },{ n: 'CompletedReturnCycle',t: 24,p: 0 },{ n: 'SublocationID',t: 195,p: 100 },{ n: 'SeatNumber',t: 24,p: 0 },{ n: 'SeatName',t: 21,p: 0 },{ n: 'IndividualItems',t: 22,p: 0 },{ n: 'RecordProcessed',t: 22,p: 0 },{ n: 'ActiveCounted',t: 22,p: 0 },{ n: 'PaymentInProcess',t: 22,p: 0 },{ n: 'PaymentComplete',t: 22,p: 0 }];
//       Object Value Definition For CustomOrders
const COV = { 'CustomOrderID': 0,'StoreLocationID': 0,'CustomerID': 0,'CustomOrderParentID': 0,'CreatedBy': 0,'CustomOrderType': 0,'RequestDateTime': new Date(),'ScheduledPrepDateTime': new Date(),'PickupDeliveryDateTime': new Date(),'Persons': '','OMUserID': 0,'TotalTaxAmount': 0,'TotalTax2Amount': 0,'TotalTax3Amount': 0,'AmountPaid': 0,'DiscountAmount': 0,'CreditAmountUsed': 0,'RefundAmount': 0,'UserName': '','AmountPaidWithTip': 0,'AddressID': 0,'PhoneNumber': '','ItemCount': 0,'OptionCount': 0,'OptionsPrice': 0,'TotalQuantity': 0,'OrderCreationDate': new Date(),'OrderPaidDate': new Date(),'Rating': 0,'Persons': '','OMUserID': 0,'OrderNotes': '','RefundTaxAmount': 0,'PaymentAuthCode': '','TransactionID': 0,'UserName': '','OrderSourceID': 0,'POSCheckNumber': '','ThirdPartyOrderNumber': '','VerifiedDate': new Date(),'LastModifiedOn': new Date(),'OrderPreparationTypeID': 0,'ReturnCycle': 0,'CompletedReturnCycle': 0,'SublocationID': 0,'SeatNumber': 0,'SeatName': '','IndividualItems': false,'RecordProcessed': false,'ActiveCounted': false,'PaymentInProcess': false,'PaymentComplete': false };
//       Object Index Definition For CustomOrders
const COX = { 'CustomOrderID': 0,'StoreLocationID': 1,'CustomerID': 2,'CustomOrderParentID': 3,'CreatedBy': 4,'CustomOrderType': 5,'RequestDateTime': 6,'ScheduledPrepDateTime': 7,'PickupDeliveryDateTime': 8,'Persons': 9,'OMUserID': 10,'TotalTaxAmount': 11,'TotalTax2Amount': 12,'TotalTax3Amount': 13,'AmountPaid': 14,'DiscountAmount': 15,'CreditAmountUsed': 16,'RefundAmount': 17,'UserName': 18,'AmountPaidWithTip': 19,'AddressID': 20,'PhoneNumber': 21,'ItemCount': 22,'OptionCount': 23,'OptionsPrice': 24,'TotalQuantity': 25,'OrderCreationDate': 26,'OrderPaidDate': 27,'Rating': 28,'Persons': 29,'OMUserID': 30,'OrderNotes': 31,'RefundTaxAmount': 32,'PaymentAuthCode': 33,'TransactionID': 34,'UserName': 35,'OrderSourceID': 36,'POSCheckNumber': 37,'ThirdPartyOrderNumber': 38,'VerifiedDate': 39,'LastModifiedOn': 40,'OrderPreparationTypeID': 41,'ReturnCycle': 42,'CompletedReturnCycle': 43,'SublocationID': 44,'SeatNumber': 45,'SeatName': 46,'IndividualItems': 47,'RecordProcessed': 48,'ActiveCounted': 49,'PaymentInProcess': 50,'PaymentComplete': 51 };

//--------------------------------------------------------------------------------------------------------           
//const StyledButton = styled(Button)`
//        color: palevioletred;
//        font-weight: bold;
//    `;
//--- Sched ------ 
//import esMessages from './es.json'; 
//loadMessages(esMessages, 'es-ES');
//import { sampleDataWithCustomSchema, displayDate, customModelFields } from './events-utc';
load(likelySubtags, currencyData, weekData, numbers, currencies, caGregorian, dateFields, timeZoneNames);
const esMessages = {
  "scheduler": {
    "today": "Hoy",
    "allDay": "todo el dia",
    "showWorkDay": "Mostrar horas laborables",
    "showFullDay": "Mostrar d�a completo",
    "agendaViewTitle": "Agenda",
    "dayViewTitle": "D�a",
    "weekViewTitle": "Semana",
    "workWeekViewTitle": "Semana laboral",
    "monthViewTitle": "Mes"
  }
}
// Sample Data
var TaskData = [];
//-------------------------------------
export const customModelFields = {
  id: "TaskID",
  title: "Title",
  description: "Description",
  start: "Start",
  end: "End",
  recurrenceRule: "RecurrenceRule",
  recurrenceId: "RecurrenceID",
  recurrenceExceptions: "RecurrenceException",
};
//--------------------------------------------------------------------------------------------------------
//--------------------------------------------------------------------------------------------------------   
const CustomItem = ({ order }) => {
  const styles = {
    padding: 10
  }
  const content = [
    { src: 'https://www.telerik.com/kendo-react-ui/static/65786c1820120efbf7a76190d820ddee/5c11c/get-started.png', text: '<p>Get Started with KendoReact in 6 Minutes (Video)</p><p>And then after that you will be ready to go!</p><p>Seriously</p>' },
    { src: 'https://www.telerik.com/kendo-react-ui/static/a86e8b9e851a224d5419be4fdfb81e3d/5c11c/dive-into-code.png', text: 'Dive Into the Code of a Sample App' },
    { src: 'https://www.telerik.com/kendo-react-ui/static/65fd761bb5671af14bbfab842df0a13e/5c11c/training.png', text: 'Start Our Product Training Course' }
  ]
  return (
    <React.Fragment>
      <img src={content[order].src} style={{ width: '100%', height: 'auto' }} />
      {content[order].text}
    </React.Fragment>
  )
};
const custTiles = [
  { defaultPosition: { col: 1, colSpan: 1, rowSpan: 1 }, item: <CustomItem order={0} /> },
  { defaultPosition: { col: 2, colSpan: 1, rowSpan: 1 }, item: <CustomItem order={1} /> },
  { defaultPosition: { col: 3, colSpan: 1, rowSpan: 1 }, item: <CustomItem order={2} /> },
];
//----------------------------------------------------------------------------------------------
//----------------------------------------------------------------------------------------------
const OrderTileItem = ({ orderHdr, orderItems }) => { // see BuildTiles and GetOrderItems
  const [orderDone, setDone] = useState(false);
  const [showDetailPopup, setShowDetail] = useState(false); // Hooks
  const [showOptionPopup, setShowOption] = useState(false);
  const [optionList, setOptionList] = useState([]);
  const [optionItemName, setItemName] = useState([]);
  const [ingredients, setIngredients] = useState([]);
  //const [dialogueVisible, setDialogueVisible] = useState(false);
  const rowClick = async (event) => { // Row Click on OrderItem Line
    //console.log("rowClick: " + JSON.stringify(event.dataItem));
    let OrderItemID = event.dataItem.ID;
    let CD = await CallOM("GetOrderItemIngredients", 0, OrderItemID, 0);
    setIngredients(CD.d);
    CD = await CallOM("GetOrderItemOptionsList", 0, OrderItemID, 0); 
    //console.log("Options: " + JSON.stringify(CD.d));
    let items = [];
    try {
      const OIOptions = CD.d.rows.map(dataItem => Object.assign({ selected: false }, dataItem));
      //console.log("OIOptions: " + OIOptions.length);
      setOptionList(OIOptions);
      setItemName(event.dataItem.Item);
      for (let item of OIOptions) {
        items.push({ Item: item.StoreItemID, GrpName: item.GrpName, POName: item.POName, Qty: item.Quantity, Weight: item.Weight, ID: item.ID });
        //console.log("items: " + JSON.stringify(items));
      }
    } catch (error) {
      console.log("Failure in GetOrderItemOptions - Error: " + error.message);
    }
    //setOptionList(items);
    setShowOption(true);
  }
  const completeClick = async (event) => {
    if (window.confirm("Confirm Done - Order # : " + orderHdr.OrderID)) {
      //if (orderDone === false) {
      //  setDone(true);
      //  setShowDetail(false);
      //  setShowOption(false);
      //}
      //else {
      //  setDone(false);
      setShowDetail(false);
      setShowOption(false);
      SessionInfo.orderIDDone = orderHdr.OrderID;
      //  }
    }
  }
  const detailClick = async (event) => {
    setShowDetail(true);
  }
  const hideDetailClick = async (event) => {
    setShowDetail(false);
  }
  const hideOptionClick = async (event) => {
    setShowOption(false);
  }
  useEffect(() => {
    console.log("--------------------- use effect ---------------------");
    //displayMessage("started");
    //return function cleanup() {
    //return () => {
    //  displayMessage("finished");
    //};
  }, []); // add if want to execute based on change, [orderNone]);); - Note Empty dependency array is to deal with infinite loop issue  - https://javascript.plainenglish.io/using-reacts-useeffect-hook-to-fetch-data-and-periodically-refresh-that-data-2a69b6d44081
  const detailOffset = { left: 850, top: 90 };
  const optionOffset = { left: 950, top: 620 };
  let profileAnchor = undefined;
  return (
    <React.Fragment>
      <div id="tileFrag">
        <span id='tileHeader'> Order #: {orderHdr.OrderID}</span>
        <span>&nbsp;&nbsp;</span>
        <span id='tileTime'>{orderHdr.Time}</span>
        <span>&nbsp;&nbsp;</span>
        <span id='tileName'>{orderHdr.Name}</span>
        <span>&nbsp;&nbsp;</span>
        <span id='tileName'>{orderHdr.OType}</span>
        <span>&nbsp;&nbsp;</span>
        <Button id="tileButton" icon="k-i-launch" color="primary" onClick={detailClick}>Details</Button>
        <span>&nbsp;&nbsp;</span>
        <Button icon="k-i-checkmark" color="primary" onClick={completeClick}>Done</Button>
        <span>&nbsp;&nbsp;</span>
        {/*<input type="checkbox" checked={orderDone} name="taskComplete" onChange={() => setDone(!orderDone)} />*/}
        {/*<span className="editFieldLabel"> - Complete</span>*/}
        <hr />
        <TGrid style={{ top: 0, height: '100%' }} data={orderItems} selectedField="selected" onRowClick={rowClick}>
          <Column field="Item" title="Name" width="200px" />
          <Column field="Qty" title="Quantity" width="80px" cell={cellQuantity}/>
          <Column field="Weight" title="Weight" width="70px" />
          <Column field="Price" title="Price" width="70px" cell={cellCurrency}/>
          <Column field="OCnt" title="Options" width="80px" cell={cellOptions}/>
        {/*  <Column field="IsItemDone" cell={BoolCell} title="Done" width='60px' />*/}
        </TGrid>
        <Popup offset={detailOffset} show={showDetailPopup} popupClass={'popupDetail'} >
          <div id="detailPopup" ref={(div) => { profileAnchor = div; }}>
              <h3>Order Detail</h3>
            <div>
              <br/>
              <div id="div2" className="editField">
                <span className="editFieldLabelR">Customer</span>
                <input value={orderHdr.Name} name="CustomerID" className="editInput" readOnly="{true}" />
              </div>
              <div id="div2" className="editField">
                <span className="editFieldLabelR">Receipt #</span>
                <input value={orderHdr.OrderID} name="CustomOrderID" className="editInputShort" readOnly="{true}" />
              </div>
              <div id="div2" className="editField">
                <span className="editFieldLabelR">Order Type</span>
                <input value={orderHdr.OType} name="CustomOrderType" className="editInput" readOnly="{true}" />
              </div>
              <div id="div2" className="editField">
                <span className="editFieldLabelR">Order Creation Date</span>
                <input value={orderHdr.Time} className="editInputShort" readOnly="{true}" />
              </div>
              <div id='div2' className='editField'>
                <span className='editFieldLabelR'>Notes</span>
              </div>
              <div id='div2' className='editField'>
                <textarea value={orderHdr.OrderNotes} name='OrderNotes' onChange={evt => this.chgFldVal(evt)} className='editTAInputFullHigh' />
              </div>
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <div id='div2' className='editField'>
                <Button id="tileButton" icon="refresh" color="primary" onClick={hideDetailClick}>Hide Detail</Button>
              </div>
            </div>
          </div>
        </Popup>
        <Popup offset={optionOffset} show={showOptionPopup} popupClass={'popupOptions'} >
          <div id="optionPopup" ref={(div) => { profileAnchor = div; }}>
            <h3>Order Options for {optionItemName}</h3>
            <div>
              <TGrid style={{ top: 0, height: '100%' }} data={optionList} selectedField="selected" onRowClick={rowClick}>
                <Column field="GrpName" title="Name" width="130px" />
                <Column field="POName" title="Name" width="130px" />
                <Column field="Qty" title="Qty" width="50px" />
                <Column field="Weight" title="Wht" width="50px" />
                <Column field="Item" title="Item" width="110px" />
              </TGrid>
            </div>
            <br />
            <br />
            <div id='div2' className='editField'>
              <span className='editFieldLabelR'>Ingredients</span>
            </div>
            <div id='div2' className='editField'>
              <textarea value={ingredients} name='Ingredients' onChange={evt => this.chgFldVal(evt)} className='editTAInputFullHigh' />
            </div>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <Button id="tileButton" icon="refresh" color="primary" onClick={hideOptionClick}>Hide Options</Button>
          </div></Popup>
      </div>
    </React.Fragment>
  )
};
//---------------------------------------------------------------
const OrderItemHeader = () => {
  return (
    <ListViewHeader style={{ color: 'black', fontSize: 18 }} className='pl-4 pb-2 pt-2'>
      Order Details for: {this.state.CustomerName}
    </ListViewHeader>
  );
}          
const OrderItemFooter = () => {
  return (
    <ListViewFooter className='listFooter'>
      <div className='footerLeft'>
        <span>SubTotal </span>
        <span>{this.moneyFormat(this.state.SubTotalPrice)}</span>
        <br />
        <span style={{ left: '180px', }}>Tax </span>
        <span>{this.moneyFormat(this.state.TotalTaxAmount)}</span>
      </div>
      <div className='footerRight'>
        <span>TOTAL </span>
        <span>{this.moneyFormat(this.state.TotalPrice)}</span>
        <br />
      </div>
    </ListViewFooter>
  );
}
//--------------------------------------------------------------------------------------------------------

class OrderManagement extends Component {
  //------------------------------------------------------------------------------------------------------
  constructor(props) {
    super(props);
    if (!SessionInfo.session)
      return;
    if (SessionInfo.OrderManagement) { // Data saved for this session
      this.state = SessionInfo.OrderManagement;
      this.loadExistingPage = true;
    }
    //------------- Edit StoreItem Grid ------------------------------
    this.enterInsertSI = this.enterInsertSI.bind(this);
    this.itemChangeSI = this.itemChangeSI.bind(this);
    this.enterEditSI = this.enterEditSI.bind(this);
    this.saveSI = this.saveSI.bind(this);
    this.cancelSI = this.cancelSI.bind(this);
    this.removeSI = this.removeSI.bind(this);
    this.StoreItemCommandCell = props => (<EditGridCommandCell {...props} edit={this.enterEditSI} remove={this.removeSI} add={this.saveSI} discard={this.removeSI} update={this.saveSI} cancel={this.cancelSI} editField='inEdit' keyField='StoreItemID' nameField='ItemDescription' />);
  }
  //--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------  
  state = {
    StoreLocationID: 0,
    CompanyID: 0,
    LocationID: 0,
    CurrentlyOpen: 0,
    AreaSize: 0,
    ContactID: 0,
    StoreGroupID: 0,
    TaxRate: 0,
    TaxRate2: 0,
    TaxRate3: 0,
    TaxRuleID: 0,
    TipRate1: 0,
    TipRate2: 0,
    TipRate3: 0,
    StoreAmenities: 0,
    MaximumPurchaseAmount: 0,
    StandardMarkupPercent: 0,
    ExtendedMarkupPercent: 0,
    CurrentInStoreWaitTime: 0,

    LocationTypeID: 0,
    ProvinceStateID: 0,
    CountryID: 0,
    //-------------------------------- 
    StoreItemID: 0,
    StorePromotionID: 0,
    DiscountPercentage: 0,
    ProductItemCategoryID: 0,
    ProductItemGroupID: 0,
    ProductItemTypeID: 0,
    ShelfLifeDays: 0,
    ItemPrice: 0,
    ItemStatusID: 0,
    PackagingTypeID: 0,
    PricingTypeID: 0,
    RateTypeID: 0,
    PromotionType: 0,
    //--------------------------------
    CustomOrderID: 0,
    TotalQuantity: 0,
    TotalNetWeight: 0,
    TotalPrice: 0,
    AmountOwing: 0,
    AmountPaid: 0,
    TotalAmount: 0,
    DiscountAmount: 0,
    CreditAmountUsed: 0,
    RefundAmount: 0,
    TipAmount: 0,
    TotalTaxAmount: 0,
    TotalTax2Amount: 0,
    TotalTax3Amount: 0,
    TotalDiscountAmount: 0,
    Price: 0,
    PromotionPrice: 0,
    TotalCost: 0,
    TotalTime: 0,
    StartDateTime: 0,
    EndDateTime: 0,
    OrderCreationDate: 0,
    OrderPaidDate: 0,
    PaymentMethodID: 0,
    IndividualItems: 0,
    PaymentVerifiedID: 0,
    DaysForDiscount: 0,
    PromotionIsActive: 0,
    ImageID: 0,
    //------ End of Field Values --------------------------------------------------------------------------------------------------------------------------
    HelpPages: [],
    showHelpDetail: false,
    showHelpListPopup: false,
    string: '',

    StoreName: '',
    Description: '',
    SpecialtyList: '',
    MarketingMessage: '',
    PromotionalMessage: '',
    StoreImage: '',

    LocationName: '',
    UnitNumber: '',
    Address: '',
    CityName: '',
    PostalCode: '',
    PhoneNumber: '',
    EmailAddress: '',
    GeoLocation: '',

    FirstName: '',
    AdditionalName: '',
    LastName: '',
    AlternateName: '',
    Organization: '',
    BusinessPhone: '',
    ContractAddress: '',
    //--------------------------------  
    CustomerID: '',
    CustomOrderStatus: '',
    CustomOrderType: '',

    ItemName: '',
    ItemDescription: '',
    UPCNumber: '',
    BarcodeNumber: '',
    Comments: '',
    //--------------------------------
    PaidComment: '',
    OrderNotes: '',
    StorePromotionName: '',
    PromotionDescription: '',
    //--------------------------------

    selectedTopTab: 0,
    //--------------------------------
    itemStateHasChanged: false,
    ScheduledPrepDateTime: undefined,
    SubTotalPrice: 0,

    CustomerName: "",
    isMainSearch: true,
    CurrentStoreLocationID: 0,

    StoreLocations: [],
    StoreLocationsSkip: 0,
    StoreLocationsTake: 8,
    StoreLocationsCount: 0,

    CustomOrderItems: [],
    customOrderItemsSkip: 0,
    customOrderItemsTake: 8,
    receiptFilter: { logic: "and", filters: [] },
    receiptSort: [],
    customOrderItemsCount: 0,
    //--------------------------------
    PendingOrders: [],
    sortPendingOrders: [],
    CurrentOrders: [],
    sortCurrentOrders: [],
    ReadyOrders: [],
    sortReadyOrders: [],
    PickupOrders: [],
    sortPickupOrders: [],
    DeliveryOrders: [],
    sortDeliveryOrders: [],
    InPaymentOrders: [],
    sortInPaymentOrders: [],
    CompletedOrders: [],
    sortCompletedOrders: [],

    StoreAmenitiesVals: [],
    StoreAmenitiesList: [],
    StoreGroups: [],
    ProvinceStates: [],
    Currencies: [],
    ProjectTypes: [], //[{ text: 'Invoice Tracking 2', id: 1 }, { text: 'Supply-Chain 2', id: 2 }],
    Contacts: [],
    Sublocations: [],

    SchedEvents: [],

    //--------------------------------
    TilesPending: [],
    TilesAccepted: [],
    TilesReady: [],
    TilesDone: [],
    TilesRejected: [],
    //--------------------------------
    ProductItemCategories: [],
    ProductItemGroups: [],
    ProductItemTypes: [],
    ItemStatusSet: [],
    PromotionTypes: [],
    PackagingTypes: [],
    PricingTypes: [],
    RateTypes: [],
    PaymentMethods: [],
    //------ Dictionary Arrays ------
    StorePromotions: [],
    StoreLocationsDict: [],
    StoreItemsDict: [],
    SchedulerData: [],
    //--
    files: [],
    UploadFile: undefined,
    ImageType: "",
    DocumentTypeID: 0,
    //--------------------------------
    LeftO: false,
    RightO: false,
    horizontalPanes: [{ size: '20%', min: '20px', collapsible: false }, { min: '300px', collapsible: false }, { size: '20%', collapsed: true, collapsible: true }], // Left, Middle, Right
    //verticalPanes: [{ min: '300px' }, { size: '50%', collapsible: true }],  // Top, Bottom   
    //horizontalSubPanes: [{ size: '50%', min: '250px', collapsible: false, collapsed: false, scrollable: false }, { min: '200px', collapsible: true, collapsed: true, scrollable: false }], // Left, Right
  };
  horizSplitOC = JSON.parse('[{"0":"[","collapsible":false,"collapsed":false,"resizable":true,"scrollable":true,"size":"20%"},{"0":"{","collapsible":false,"collapsed":false,"resizable":true,"scrollable":true}, { "collapsible": true, "collapsed": true, "resizable": true, "scrollable": true, "size": "20%" }]');
  horizSplitCC = JSON.parse('[{"0":"[","collapsible":false,"collapsed":false,"resizable":true,"scrollable":true,"size":"50px"},{"0":"{","collapsible":false,"collapsed":false,"resizable":true,"scrollable":true}, { "collapsible": true, "collapsed": true, "resizable": true, "scrollable": true, "size": "20%" }]');
  horizSplitCO = JSON.parse('[{"0":"[","collapsible":false,"collapsed":false,"resizable":true,"scrollable":true,"size":"50px"},{"0":"{","collapsible":false,"collapsed":false,"resizable":true,"scrollable":true}, { "collapsible": true, "collapsed": false, "resizable": true, "scrollable": true, "size": "20%" }]');
  horizSplitOO = JSON.parse('[{"0":"[","collapsible":false,"collapsed":false,"resizable":true,"scrollable":true,"size":"20%"},{"0":"{","collapsible":false,"collapsed":false,"resizable":true,"scrollable":true}, { "collapsible": true, "collapsed": false, "resizable": true, "scrollable": true, "size": "20%" }]');
  horizSplitCOW = JSON.parse('[{"0":"[","collapsible":false,"collapsed":false,"resizable":true,"scrollable":true,"size":"50px"},{"0":"{","collapsible":false,"collapsed":false,"resizable":true,"scrollable":true}, { "collapsible": true, "collapsed": false, "resizable": true, "scrollable": true, "size": "60%" }]');
  horizSplitOOW = JSON.parse('[{"0":"[","collapsible":false,"collapsed":false,"resizable":true,"scrollable":true,"size":"20%"},{"0":"{","collapsible":false,"collapsed":false,"resizable":true,"scrollable":true}, { "collapsible": true, "collapsed": false, "resizable": true, "scrollable": true, "size": "60%" }]');

  //--------------------------------  
  styles = { padding: 10, margin: 'auto', userSelect: 'none' }
  testTiles = []
  tiles = [
    {
      defaultPosition: { col: 1, colSpan: 2, rowSpan: 1 },
      body: 'Chicken Avocado Sandwich  Hawiian Ham and Cheese (Extra Mayo)',
      orderID: "Order #: 1 - 1:15 PM"
    },
    {
      defaultPosition: { col: 1, colSpan: 1, rowSpan: 2 },
      body: "Chicken Avocado Sandwich 1  Hawiian Ham and Cheese (Extra Mayo)",
      orderID: "Order #:  - 1:25 PM"
    },
    {
      defaultPosition: { col: 3, colSpan: 1, rowSpan: 1 },
      body: "Chicken Avocado Sandwich 2  Hawiian Ham and Cheese (Extra Mayo)",
      orderID: "Order #: 3 - 1:30 PM"
    },
    {
      defaultPosition: { col: 2, colSpan: 2, rowSpan: 1 },
      body: "Chicken Avocado Sandwich   Hawiian Ham and Cheese (Extra Mayo)",
      orderID: "Order #: 4 - ASAP"
    },
    {
      defaultPosition: { col: 2, colSpan: 2, rowSpan: 1 },
      body: "Chicken Avocado Sandwich   Hawiian Ham and Cheese (Extra Mayo)",
      orderID: "Order #: 2 - 2:00 PM"
    },
  ];
  tileInfo = [
    { "defaultPosition": { "col": 1, "colSpan": 1, "rowSpan": 2 }, "item": <OrderTileItem orderID="Order #: 2366" prepTime={"10:00"} orderDesc='[{"Item": "Beef Dip Sandwich", "Qty": 1, "Price": 6.49 },{"Item": "Beef Barley Soup", "Qty": 1, "Price": 5.99 },{"Item": "Corn Salad", "Qty": 1, "Price": 5.19 }]' /> },
    { "defaultPosition": { "col": 1, "colSpan": 1, "rowSpan": 1 }, "item": <OrderTileItem orderID="Order #: 2367" prepTime={"10:03"} orderDesc='[{"Item": "Beef Barley Soup", "Qty": 1, "Price": 5.99 }, {"Item": "Fruit Nut Snacks", "Qty": 1, "Price": 2.69 }]' /> },
    { "defaultPosition": { "col": 1, "colSpan": 1, "rowSpan": 2 }, "item": <OrderTileItem orderID="Order #: 2368" prepTime={"10:06"} orderDesc='[{"Item": "Hawiian Ham and Cheese Sandwich", "Qty": 1, "Price": 5.99 }, {"Item": "Thai Salad", "Qty": 1, "Price": 4.89 }]' /> },
    { "defaultPosition": { "col": 1, "colSpan": 1, "rowSpan": 1 }, "item": <OrderTileItem orderID="Order #: 2369" prepTime={"10:09"} orderDesc='[{"Item": "Thai Salad", "Qty": 1, "Price": 4.89 }, {"Item": "Thai Salad", "Qty": 1, "Price": 4.89 }, {"Item": "Thai Salad", "Qty": 1, "Price": 4.89 }]' /> },
    { "defaultPosition": { "col": 1, "colSpan": 1, "rowSpan": 1 }, "item": <OrderTileItem orderID="Order #: 2372" prepTime={"10:12"} orderDesc='[{"Item": "Thai Salad", "Qty": 1, "Price": 4.89 }, {"Item": "Thai Salad", "Qty": 1, "Price": 4.89 }, {"Item": "Thai Salad", "Qty": 1, "Price": 4.89 }, {"Item": "Thai Salad", "Qty": 1, "Price": 4.89 }]' /> },
    { "defaultPosition": { "col": 1, "colSpan": 1, "rowSpan": 1 }, "item": <OrderTileItem orderID="Order #: 2366" prepTime={"10:14"} orderDesc='[{"Item": "Wings By The Piece", "Qty": 1, "Price": 10.09 }, {"Item": "Chicken Avocado Sandwich", "Qty": 1, "Price": 39.89 }]' /> },
    { "defaultPosition": { "col": 1, "colSpan": 1, "rowSpan": 1 }, "item": <OrderTileItem orderID="Order #: 2366" prepTime={"10:16"} orderDesc='[{"Item": "Chicken Avocado Sandwich", "Qty": 1, "Price": 39.89 }]' /> },
    { "defaultPosition": { "col": 1, "colSpan": 1, "rowSpan": 1 }, "item": <OrderTileItem orderID="Order #: 2366" prepTime={"10:18"} orderDesc='[{"Item": "Beef Dip Sandwich", "Qty": 1, "Price": 6.49 }, {"Item": "Beef Barley Soup", "Qty": 1, "Price": 5.99 }, {"Item": "Corn Salad", "Qty": 1, "Price": 5.19 }]' /> }];
  //--------------------------------
  newTileInfo = [];
  curOrderPos = [];
  curOrders = [];
  clearScreen = false; 

  //StoreItemsOID = 544619;
  //StorePromotionsOID = 544701;
  hasOptions = false;
  displayWarning = false;
  compUpdate = true;
  lastTab = -1;
  timerCount = 0;
  currentTab = 0;
  tabs = {
    PENDINGORDERS: 0,
    KITCHENORDERS: 1,
    READYORDERS: 2,
    READYFORPICKUP: 3,
    INDELIVERY: 4,
    INPAYMENT: 5,
    COMPLETEDORDERS: 6,
    MANAGE: 7
  };

  orderStatus = {
    OrderOpen: 1,
    OrderPending: 2,
    OrderAccepted: 3,
    OrderInProgress: 5,
    OrderReady: 6,
    InDelivery: 7,
    Delivered: 8,
    PickedUp: 9,
    OrderRejected: 10,
    OrderPartialPaid: 11,
    OrderPaid: 12,
    OrderReturned: 14,
    Problems: 15,
    OrderInDispute: 16,
    Abandoned: 20,
    Scrapped: 21
  }

  helpListPopupOffset = { left: 150, top: 60 };
  helpDetailOffset = { left: 200, top: 120 };


  vertSplitC = JSON.parse('[{"collapsible":false,"collapsed":false,"resizable":true,"scrollable":true,"min":"300px"},{"collapsible":true,"collapsed":true,"resizable":true,"scrollable":true,"size":"50%"}]');
  vertSplitO = JSON.parse('[{"collapsible":false,"collapsed":false,"resizable":true,"scrollable":true,"min":"300px"},{"collapsible":true,"collapsed":false,"resizable":true,"scrollable":true,"size":"50%"}]');
  loadExistingPage = false;
  popupAnchor = null;
  popupOffset = { left: 250, top: 70 };
  currentYear = new Date().getFullYear();
  parseAdjust = (eventDate) => {
    const date = new Date(eventDate);
    date.setFullYear(this.currentYear);
    return date;
  };
  taskData = undefined;
  //TaskData = baseSchedData;

  //-------------------------------------------------------------------
  //----------------------------------------------------------------------------------------------------------
  async componentDidMount() {
    //console.log("Mount - load OrderManagement "); 
    if (!SessionInfo.session || SessionInfo.session === '') {
      console.log(`forceLogoff set in OrderManagement - go to Login`);
      this.props.navigate("/Login");
    }
    let windowHt = window.innerHeight;
    let takeLinesD = (windowHt - 200) / 48.0;
    let takeLines = parseInt(takeLinesD);
    this.setState({ fullTake: takeLines });
    this.setState({ storeLocationsTake: takeLines });
    this.setState({ PendingOrdersTake: this.state.fullTake });
    this.setState({ CurrentOrdersTake: this.state.fullTake });
    this.setState({ ReadyOrdersTake: this.state.fullTake });
    this.setState({ DeliveryOrdersTake: this.state.fullTake });
    this.setState({ CompletedOrdersTake: this.state.fullTake });
    this.setState({ customersTake: takeLines });
    this.setState({ customOrderItemsTake: takeLines });
    try {
      SessionInfo.searchTitle = "Order Management";
      SessionInfo.currentPage = "OrderManagement";
      console.log("Get Locations - count: " + this.state.StoreLocationsCount);
      if (this.state.StoreLocationsCount <= 1) {
        await this.getStoreLocations();
      }
      console.log("After Get Locations - count: " + this.state.StoreLocationsCount);
      SessionInfo.currSaveFunc = this.saveRecord;
      SessionInfo.currDeleteFunc = this.deleteRecord;
      SessionInfo.currClearFunc = this.clearRecord;
      SessionInfo.currRefreshGridFunc = this.refreshGrid;
      SessionInfo.currAddGridRowFunc = undefined;
      SessionInfo.currExcelExportFunc = undefined;
      SessionInfo.currShowHelp = this.showHelp;
      SessionInfo.currExpandLPane = this.openLeftPane;
      SessionInfo.currCollapseLPane = this.closeLeftPane;
      SessionInfo.currExpandRPane = this.openRightPane;
      SessionInfo.currCollapseRPane = this.closeRightPane;
      SessionInfo.searchFunc = this.searchCurrent;
      SessionInfo.tabSelectFunc = this.selectTab;
      document.documentElement.style.setProperty('--prompt-left', '130px');
      console.log("Load Existing: " + this.loadExistingPage);
      if (this.loadExistingPage === true) {
        if (this.state.StoreLocationID > 0) {
          SessionInfo.headerTitle = this.state.StoreName;
        }
        this.forceUpdate();
      }
      else {
        this.openLeftPane();
        this.initializeLocation();
      }
      if (SessionInfo.GoToTab >= 0) {
        await this.selectTab(SessionInfo.GoToTab);
        SessionInfo.GoToTab = 0;
      }
      this.monitorProcess();
      this.currentPanel = this.tabs.PENDINGORDERS;
      //----------------------------------    
      //if (this.state.OpenOrders.length <= 1) {   
      //---------------------------------- 
      if (this.runMonitorProcess === false) {
        this.runMonitorProcess = true;
        this.monitorProcess();
      }
      console.log(`---------------------- Call Get Orders - Location: ${SessionInfo.OrderLocationID} -------------------`);
      //await this.loadOrders(1);
      await this.loadOrders(2); // Open/Pending
      //await this.loadOrders(5); // Accepted/InProgress
      //await this.loadOrders(6); // Ready
      //await this.loadOrders(7); // Delivery
      //await this.loadOrders(8); // Complete
      //                                
      //console.log("Build Tiles -------------------------------------------------");
      //await this.buildTiles(2);
      //}
      //console.log("Get Tasks -------------------------------------------------");
      let CD = await CallOM("GetTasks", 0, this.state.CurrentStoreLocationID, 0); // TaskList Returned for Scheduler   
      getTaskID(); // Initialize TaskID for create
      //console.log("W Tasks: " + JSON.stringify(CD.d));
      this.taskData = CD.d.rows;
      //console.log("TaskData: " + JSON.stringify(this.taskData));
      TaskData = this.taskData.map(dataItem => (
        {
          TaskID: dataItem.COTaskID,
          OwnerID: 2,
          Title: dataItem.Title,
          Description: dataItem.Description,
          Start: new Date(dataItem.StartDateTime),
          End: new Date(dataItem.EndDateTime),
          isAllDay: dataItem.isAllDay,
          RecurrenceRule: dataItem.RecurrenceRule,
          RecurrenceId: dataItem.RecurrenceID,
          RecurrenceExceptions: dataItem.RecurrenceException,
          TaskCommandID: dataItem.TaskCommandID,
          Parameters: dataItem.Parameters,
        }
      ));
      //console.log("TaskData: " + JSON.stringify(TaskData));
      this.setState({ SchedulerData: TaskData });
      if (SessionInfo.gotoAutoPage !== '') {
        console.log(`Go to AutoPage: ${SessionInfo.gotoAutoPage}`);
        await this.selectStore("1");
      }
      //  var TaskData2 = baseSchedData.map((dataItem) => ({
      //    ...dataItem,
      //    Start: new Date(dataItem.Start),
      //    End: new Date(dataItem.End),
      //  }));
      //  console.log("TaskData2: " + JSON.stringify(this.TaskData2));
    }
    catch (error) {
      console.log(`OrderManager Error: ${error.message}`);
    }
  }
  componentDidUpdate() {
    // On Update - from https://reactjs.org/docs/hooks-effect.html
    // Note 19Mar21 - Need to find out when this is fired
  }
  componentDidCatch() {
    // What is this?? 19Mar21
  }
  componentWillUnmount() {
    //console.log("OrderManagement Will UnMount");  
    this.runMonitorProcess = false;
    if (SessionInfo.session !== '')
      SessionInfo.OrderManagement = this.state;
  }
  shouldComponentUpdate() {
    //console.log("Should Update?");
    if (this.compUpdate === true)
      return true;
    else
      return false;
  }
  //--------------- Monitor Process ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------    
  runMonitorProcess = false; 
  setTimer() {
    if (SessionInfo.loggedOn === true)
      this.timeout = setTimeout(this.monitorProcess.bind(this),1000); // 10 microseconds       
    else
      this.runMonitorProcess = false;
  }
  //----- Timer Process - Application Control Logic -------------------------------------------------
  monitorProcess() {
    //console.log("monitor proc");
    if (SessionInfo.forceLogoff === true || SessionInfo.loggedOn === false)
      this.props.navigate("/Login");
    if (this.runMonitorProcess === true) {
      this.setTimer();
      if (SessionInfo.orderIDDone > 0) {
        displayMessage(`Order Done: ${SessionInfo.orderIDDone}`);
        let updated;
        //eslint-disable-next-line 
        //this.state.TilesPending.forEach((elem) => {
        //  console.log("TilesPending items=" + JSON.stringify(elem.item));
        //});
        let index = this.state.TilesPending.findIndex(p => p.item.props.orderID === SessionInfo.orderIDDone);
        console.log("index=" + index);
        if (index >= 0) {
          updated = Object.assign({},this.state.TilesPending[index]); // The row to be removed
          console.log("updated=" + JSON.stringify(updated));
          //this.state.PendingOrders[index] = updated;
          //console.log("Orders BEFORE update=" + JSON.stringify(this.state.TilesPending)); 
          this.state.TilesPending.splice(index,1);
          //console.log("Orders AFTER update=" + JSON.stringify(this.state.TilesPending)); 
          this.setState({ TilesPending: this.state.TilesPending });
        }
        //let index = this.state.PendingOrders.findIndex(p => p.CustomOrderID === SessionInfo.orderIDDone);
        //console.log("index=" + index);
        //if (index >= 0) {
        //  updated = Object.assign({}, this.state.PendingOrders[index]); // The row to be removed
        //  console.log("updated=" + JSON.stringify(updated));
        //  //this.state.PendingOrders[index] = updated;
        //  console.log("Orders BEFORE update=" + JSON.stringify(this.state.PendingOrders));
        //  this.state.PendingOrders.splice(index, 1);
        //  console.log("Orders AFTER update=" + JSON.stringify(this.state.PendingOrders));
        //  this.setState({ PendingOrders: this.state.PendingOrders });
        //  this.forceUpdate();
        //}
        SessionInfo.orderIDDone = 0;
      }
      if (this.timerCount++ > 10) {
        this.timerCount = 0;
        console.log(`refresh timer in OrderManager`);
        this.selectTab(this.currentPanel);
      }
      //  if (SessionInfo.orderIDComplete > 0) {
      //    let index = this.state.TilesPending.findIndex(p => p.item.props.orderID === SessionInfo.orderIDComplete);
      //    console.log("index=" + index + " orderDone=" + SessionInfo.orderIDComplete);
      //    if (index >= 0) {
      //      let updated = Object.assign({}, this.state.TilesPending[index]); // The row to be removed
      //      console.log("updated=" + JSON.stringify(updated));
      //      updated.defaultPosition.col = 3;
      //      console.log("Updated=" + JSON.stringify(updated));
      //      this.state.TilesPending[index].defaultPosition.col = 3;
      //      //this.state.PendingOrders[index] = updated;
      //      //console.log("Orders BEFORE update=" + JSON.stringify(this.state.TilesPending)); 
      //      //console.log("Orders AFTER update=" + JSON.stringify(this.state.TilesPending)); 
      //      this.setState({ TilesPending: this.state.TilesPending });
      //      //this.forceUpdate();
      //    }
      //    SessionInfo.orderIDComplete = 0;
      //  }
    }
  }
  //--------------- Standard Page Functions ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
  searchCurrent = async (search) => {
    if (!search || typeof search !== 'string' || search === 'all' || search === 'ALL')
      search = "";
    if (this.currentTab === this.tabs.PENDINGORDERS) { // Pending Orders
      displayMessage("Search for Pending Orders");
      this.getCustomOrders(search, this.orderStatus.OrderPending);
    }
    else if (this.currentTab === this.tabs.KITCHENORDERS) {
      displayMessage("Search Current Orders");
      this.getCustomOrders(search, this.orderStatus.OrderInProgress);
    }
    else if (this.currentTab === this.tabs.READY) {
      displayMessage("Search Ready Orders");
      this.getCustomOrders(search, this.orderStatus.Ready);
    }
    else if (this.currentTab === this.tabs.READYFORPICKUP) {
      displayMessage("Search Ready for Pickup Orders");
      this.getCustomOrders(search, 102); // Note - 102 : Ready and Type: Delivery or Pickup
    }
    else if (this.currentTab === this.tabs.INDELIVERY) {
      displayMessage("Search Orders Being Delivered");
      this.getCustomOrders(search, this.orderStatus.InDelivery);
    }
    else if (this.currentTab === this.tabs.INPAYMENT) {
      displayMessage("Search Orders InPayment Process");
      this.getCustomOrders(search, 100); // Note: 100 : InPayment
    }
    else if (this.currentTab === this.tabs.COMPLETEDORDERS) {
      displayMessage("Search Completed Orders");
      this.getCustomOrders(search, this.orderStatus.OrderPaid);
    }
    else { // Search What?
    }
  }

  saveRecord = async () => {
    let StoreAmenities = getAmenities(this.state.StoreAmenitiesVals);
    this.setState({ StoreAmenities });
    //let copyState = Object.assign({}, this.state);
    //let type = 0;
    //for (var prop in copyState) {
    //  if (prop === "string") {
    //    type = 1;
    //    copyState[prop] = undefined;
    //  }
    //  else if (prop === "selectedTopTab")
    //    type = 2;
    //  else if (type === 0) {
    //  }
    //  if (type === 2) {
    //    copyState[prop] = undefined;
    //  }
    //}
    console.log("Save Record Tab: " + JSON.stringify(this.state.selectedTopTab));
    //export async function UpdateRow(tableID, control, allData, item, key, keyName) { // SaveRow
    if (this.state.selectedTopTab === 4) { // Store Item
      let propState = {};
      StoreItemsO.forEach((elem) => {
        if (elem.n in this.state) {
          propState[elem.n] = this.state[elem.n];
        }
      });
      propState["StoreAmenities"] = StoreAmenities;
      //console.log("Save Record: " + JSON.stringify(propState));
      await UpdateRow(544619, 0, undefined, propState, this.state.StoreItemID, "StoreItemID"); // Save StoreItem to OM
    }
    else if (this.state.selectedTopTab === 5) { // Store Receipts - do nothing
    }
    else if (this.state.selectedTopTab === 6) { // Promotions   
      //let propState = [];
      //StorePromotionsO.forEach((elem) => {
      //  if (elem.n in this.state) {
      //    let sobj = {};
      //    sobj[elem.n] = this.state[elem.n];
      //    propState.push(sobj);
      //  }
      //});  
      let propState = {};
      StorePromotionsO.forEach((elem) => {
        if (elem.n in this.state) {
          propState[elem.n] = this.state[elem.n];
        }
      });
      //console.log("Save Promotion Record: " + JSON.stringify(propState));
      //await UpdateRow(544701, 0, undefined, propState, this.state.StorePromotionID, "StorePromotionID"); // Save StorePromotion to 
      await CallOM("UpdatePromotion", 0, this.state.StorePromotionID, 13, propState);
    }
    else {
      let propState = {};
      //console.log("Save StoreLocation");
      StoreLocationsO.forEach((elem) => {
        if (elem.n in this.state) {
          propState[elem.n] = this.state[elem.n];
        }
      });
      //console.log("Save StoreLocation: " + JSON.stringify(propState));
      await CallOM("UpdateStoreLocation", 0, this.state.StoreLocationID, 13, propState);
      //await UpdateRow(544596, 0, undefined, copyState, copyState.StoreLocationID, "StoreLocationID"); // Same StoreLocation Info to OM     
      //await UpdateRow(509178, 0, undefined, copyState, copyState.LocationID, "LocationID"); // Same StoreLocation Info to OM 
    }
    this.setItemStateChanged(false);
  }
  deleteRecord = async () => {
    let CD = await DeleteRowTry(544596, this.state.StoreLocationID);
    //console.log("Delete Msg Lvl: " + CD.x.o);
    this.setItemStateChanged(false);
  }
  clearRecord = async () => { // Note => functions do not bind their own this - if used clearRecord() - would have to bind this to the function
    //console.log("Clear Screen - state: " + JSON.stringify(this.state)); 
    let fields = CustomOrdersO;
    fields.forEach((elem) => {
      let sobj = {};
      try {
        if (elem.t === 34 || elem.t === 35)
          sobj[elem.n] = new Date();
        else if (elem.t === 21 || elem.t === 22)
          sobj[elem.n] = '';
        else
          sobj[elem.n] = 0;
        this.setState(sobj);
      } catch (error) {
        console.log("Clear Promotion Field: " + elem.n + " Error: " + error.message);
      }
    });
    this.clearScreen = true;
    this.setState({ CFProjectParticipants: [] });
    this.setItemStateChanged(false);
  }
  refreshGrid = async () => { // Note => functions do not bind their own this - if used clearRecord() - would have to bind this to the function
    //console.log("Clear Screen - state: " + JSON.stringify(this.state)); 
    let fields = [];
    //  if (this.state.selectedTopTab === 4) { // Store Items 
    //    this.getStoreItems('');
    //  }
    //  else if (this.state.selectedTopTab === 5) { // CustomOrders - Receipts
    //    this.getCustomOrders('');
    //  }
    //  else if (this.state.selectedTopTab === 6) { // Promotions 
    //    this.getPromotions('');
    //  }
  }
  clearTables = () => {
    this.setState({ storeLocationsCount: 0 });
    this.setState({ storeLocationsSkip: 0 });
    this.setState({ storeLocationsTake: this.state.fullTake });
    //--------------------------------
    this.setState({ PendingOrdersCount: 0 });
    this.setState({ PendingOrdersSkip: 0 });
    this.setState({ PendingOrdersTake: this.state.fullTake });

    this.setState({ CurrentOrdersCount: 0 });
    this.setState({ CurrentOrdersSkip: 0 });
    this.setState({ CurrentOrdersTake: this.state.fullTake });

    this.setState({ ReadyOrdersCount: 0 });
    this.setState({ ReadyOrdersSkip: 0 });
    this.setState({ ReadyOrdersTake: this.state.fullTake });

    this.setState({ DeliveryOrdersCount: 0 });
    this.setState({ DeliveryOrdersSkip: 0 });
    this.setState({ DeliveryOrdersTake: this.state.fullTake });

    this.setState({ CompletedOrdersCount: 0 });
    this.setState({ CompletedOrdersSkip: 0 });
    this.setState({ CompletedOrdersTake: this.state.fullTake });
    this.forceUpdate();
  }

  defaultItemType = { text: "Select Type ..." };
  defaultCFProjectControlTypeID = { text: "Select Type ..." };

  closeLeftPane = () => {
    console.log("Close Left Pane");
    this.setState({ LeftO: false });
    if (this.state.RightO === false)
      this.setState({ horizontalPanes: this.horizSplitCC });
    else
      this.setState({ horizontalPanes: this.horizSplitCO });
    SessionInfo.setLPaneOpen = 1;
    SessionInfo.setLPaneClose = 2;
  }
  openLeftPane = () => {
    console.log("Open Left Pane");
    this.setState({ LeftO: true });
    if (this.state.RightO === false)
      this.setState({ horizontalPanes: this.horizSplitOC });
    else
      this.setState({ horizontalPanes: this.horizSplitOO });
    SessionInfo.setLPaneClose = 1;
    SessionInfo.setLPaneOpen = 2;
  }
  closeRightPane = () => {
    console.log("Close Right Pane");
    this.setState({ RightO: false });
    if (this.state.LeftO === false)
      this.setState({ horizontalPanes: this.horizSplitCC });
    else
      this.setState({ horizontalPanes: this.horizSplitOC });
    SessionInfo.setRPaneOpen = 1;
    SessionInfo.setRPaneClose = 2;
  }
  openRightPane = () => {
    console.log("Open Right Pane");
    this.setState({ RightO: true });
    if (this.state.LeftO === false)
      this.setState({ horizontalPanes: this.horizSplitCO });
    else
      this.setState({ horizontalPanes: this.horizSplitOO });
    SessionInfo.setRPaneClose = 1;
    SessionInfo.setRPaneOpen = 2;
  }
  openRightPaneWide = (leftOpen) => {
    if (leftOpen === undefined)
      leftOpen = this.state.LeftO;
    console.log(`Open Right Pane - Left: ${leftOpen}`);
    this.setState({ RightO: true });
    if (leftOpen === false)
      this.setState({ horizontalPanes: this.horizSplitCOW });
    else
      this.setState({ horizontalPanes: this.horizSplitOOW });
    SessionInfo.setRPaneClose = 1;
    SessionInfo.setRPaneOpen = 2;
  }
  //--------------- End of Standard Page Functions ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- 
  selectStore = async (storeLocation) => {
    //await this.searchCurrent(`*StoreLocationID=${storeLocation}`);
    SessionInfo.OrderLocationID = storeLocation;
    await this.initializeLocation();
    await this.selectTab(this.tabs.KITCHENORDERS);
  }
  //--------------- Page Setup and Load Functions ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

  //------ Help Information -------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
  showHelp = async (key) => {
    if (this.state.showHelpListPopup == true) { // Close Help
      this.setState({ showHelpListPopup: false });
      this.setState({ showHelpDetail: false });
    } else {
      if (!key)
        key = '';
      this.setState({ showHelpDetail: false });
      let HelpPages = await getHelpTopics(key);
      //console.log("caller HelpPages: " + JSON.stringify(HelpPages));
      this.setState({ HelpPages });
      this.setState({ showHelpListPopup: true });
    }
  }
  closeHelpList = () => {
    this.setState({ showHelpListPopup: false });
  }
  onHelpItemClick = async (item) => {
    let helpDetail = await getHelpDetail(item.HelpPageID);
    this.setState({ helpDetail });
    this.setState({ showHelpDetail: true });
  }
  closeHelpTime = async () => {
    this.setState({ showHelpDetail: false });
  }
  onObjectHelp = async (objectID) => {
    let helpDetail = await getHelpDetail(objectID,2);
    this.setState({ helpDetail });
    this.setState({ showHelpDetail: true });
  }
  closeHelpTime = async () => {
    this.setState({ showHelpDetail: false });
  }
  // Help List Layout
  HelpPageHeader = () => {
    return (
      <ListViewHeader className='listHelpHeader' onClick={(e) => this.closeHelpList()} >
        <div className='listHeaderLeft' onClick={(e) => this.closeHelpList()}>
          <span className="listHelpTitle">{SessionInfo.HelpTitle}</span>
        </div>
        <div className='listHeaderRight' onClick={(e) => this.closeHelpList()}>
          <Button id="cancelButton" icon="cancel" look="outline" className="medButton" onClick={this.closeHelpList} >EXIT HELP</Button>
        </div>
      </ListViewHeader>
    );
  }
  HelpPageFormat = props => {
    let item = props.dataItem;
    return (
      <div>
        <div className="listHelpRow" onClick={(e) => this.onHelpItemClick(item)}>
          <div className="listColA">
            <span className="listHelpItemTitle" >{item.HelpTitle}&nbsp;&nbsp;&nbsp;</span>
            <div dangerouslySetInnerHTML={{ __html: item.HelpContent }}></div>
          </div>
        </div>
      </div>
    );
  }
  HelpPageFooter = () => {
    //const [state, onCheckout] = React.useContext(StateContext);
    return (
      <ListViewFooter className='listHelpFooter' onClick={(e) => this.closeHelpList()} >
        <div className='listHeaderRight' onClick={(e) => this.closeHelpList()}>
          <Button id="cancelButton" icon="cancel" look="outline" className="medButton" onClick={this.closeHelpList} >EXIT HELP</Button>
        </div>
      </ListViewFooter>
    );
  }
  ShowFieldHelp = async (key) => {
    console.log(`Field Help - Key: ${key}`);
    this.onObjectHelp(key);
    //this.showHelp(key);
    //console.log(`Field Help: ${JSON.stringify(item)}`);
  }
  //------ End of Help -------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
  async loadOrders(orderStatus) {
    if (SessionInfo.OrderLocationID == 0) {
      if (this.displayWarning == false) {
        this.displayWarning = true;
        displayWarning("Select A Location First");
      }
    }
    else {
      let CD = await CallOM("GetOrders", orderStatus, SessionInfo.OrderLocationID, 2, ""); // Fetch CustomOrders based on OrderStatus
      //console.log("Load Orders: " + JSON.stringify(CD.d));
      if (CD.x.o >= 9000 && CD.x.o < 9500) {
        try {
          if (orderStatus === 2)
            this.setState({ PendingOrders: CD.d.rows });
          else if (orderStatus === 5)
            this.setState({ CurrentOrders: CD.d.rows });
          else if (orderStatus === 6)
            this.setState({ ReadyOrders: CD.d.rows });
          else if (orderStatus === 7)
            this.setState({ DeliveryOrders: CD.d.rows });
          else if (orderStatus === 10)
            this.setState({ CompletedOrders: CD.d.rows });
        } catch (error) {
          console.log(error.message);
        }
      }
      else {
        displayError(`Unable to retrieve orders for Location: ${SessionInfo.OrderLocationID}, Status: ${orderStatus}`);
        this.setState({ StoreLocations: [] });
      }
    }
  }
  // Fetch the Orders for a Given Status - As Tiles
  async buildTiles(orderStatus) {
    let OrderList;
    let OrderPos;
    console.log(`Build Tiles Status: ${orderStatus}`);
    try {
      if (orderStatus === 1) {
        OrderList = this.state.OpenOrders;
        OrderPos = this.state.OpenPos;
        this.curOrders = this.state.OpenOrders;
      }
      else if (orderStatus === 2) {
        OrderList = this.state.PendingOrders;
        OrderPos = this.state.PendingPos;
        this.curOrders = this.state.PendingOrders;
        this.setState({ TilesPending: [] });
      }
      else if (orderStatus === 3) {
        OrderList = this.state.AcceptedOrders;
        OrderPos = this.state.AcceptedPos;
        this.curOrders = this.state.AcceptedOrders;
      }
      else if (orderStatus === 5) {
        OrderList = this.state.InProgressOrders;
        OrderPos = this.state.InProgressPos;
        this.curOrders = this.state.InProgressOrders;
      }
      else if (orderStatus === 6) {
        OrderList = this.state.ReadyOrders;
        OrderPos = this.state.ReadyPos;
        this.curOrders = this.state.ReadyPos;
      }
      else if (orderStatus === 7) {
        OrderList = this.state.DoneOrders;
        OrderPos = this.state.DonePos;
        this.curOrders = this.state.DonePos;
      }
      else if (orderStatus === 10) {
        OrderList = this.state.RejectedOrders;
        this.curOrders = this.state.RejectedOrders;
      }
      let curTiles = [];
      let rowcnt = 1;
      let itemTileX = new Object();
      let itemTile = new Object();
      let orderCount = 0;
      let column = 2;
      let rowSpan = 1;
      //console.log("Tiles Orders: " + JSON.stringify(OrderList)); 
      console.log("curOrderPos: " + JSON.stringify(this.curOrderPos));
      let newSet = false;
      if (this.curOrderPos.length === 0) {
        this.curOrderPos = [];
        newSet = true;
      }
      console.log(`curOrderPos: ${JSON.stringify(this.curOrderPos)}`);
      let ix = 0;
      let cnt = OrderList.length;
      for (let ix = 0; ix < cnt; ix++) {
        let ordr = OrderList[ix];
        //console.log(`Custom (${ix}) Order: ${JSON.stringify(ordr)}`);
        let CustOrderID = ordr.CustomOrderID;
        this.curOrders.push({ ix: ix, key: CustOrderID });
        //let CD = await GetTableRowTrans(545072, ordr.CustomOrderID); // Get the CustomOrder Information  
        //console.log("CustomOrder: " + JSON.stringify(CD.d.row));
        //const CustomOrder = CD.d.row;
        //console.log("CustomOrder: " + JSON.stringify(CustomOrder));
        //----------------------------------------
        let PrepTime = new Date(ordr.ScheduledPrepDateTime);
        let header = {
          OrderID: ordr.CustomOrderID, Time: PrepTime.toLocaleTimeString(navigator.language, { hour: '2-digit', minute: '2-digit' }), Name: ordr.CustomerID, OType: ordr.CustomOrderType
        }; // 11:03
        let items = [];
        //console.log("Get CustomOrderItems - locationID: " + this.state.CurrentStoreLocationID + ", CustomOrderID=" + ordr.CustomOrderID);
        //CD = await GetTableSearch(545113, "*CustomOrderID=" + ordr.CustomOrderID, "StoreItemID,Quantity,TotalPrice"); // Get CustomOrderItems   
        let CD = await CallOM("GetOrderItems", 0, CustOrderID, 0); // Order Item Line
        if (!CD.d) {
          console.log(`Unknown CustOrderID: ${CustOrderID}`);
        }
        else {
          //console.log(" Order Items: " + JSON.stringify(CD.d));
          try {
            const CustomOrderItems = CD.d.rows.map(dataItem => Object.assign({ selected: false }, dataItem));
            console.log(" Order Items Count: " + CustomOrderItems.length);
            for (let item of CustomOrderItems) {
              items.push({ Item: item.StoreItem, Qty: item.Quantity, Price: item.Price, Weight: item.Weight, OCnt: item.OptionCount, ID: item.ID, IsItemDone: false });
              //console.log("items: " + JSON.stringify(items));
            }
          } catch (error) {
            console.log("Failure in getCustomOrderItems - Error: " + error.message);
          }
          //----------------------------------------
          //await this.getCustomOrderItems();
          //itemTile = { orderID: `Order #: ${ordr.CustomOrderID}`, defaultPosition: { col: 1, colSpan: 1, rowSpan: 1 }, body: items }; //, row: rowcnt++ 
          //console.log("itemTile: " + JSON.stringify(itemTile));
          //curTiles.push(itemTile);
          //-------
          //let lineitem = '[{Item: "Beef Dip Sandwich",  Qty: 1,  Price: 6.49 }]';
          if (newSet) {
            rowSpan = 1;
            if (items.length > 3) {
              rowSpan = 2;
              if (items.length > 7)
                rowSpan = 3;
            }
            column = 1;
            orderCount++;
          }
          else {
            const curix = this.curOrderPos.findIndex(order => order.key === CustOrderID);
            let current = this.curOrderPos[curix];
            console.log(" current POS: " + JSON.stringify(current));
            if (current) {
              orderCount = current.order;
              column = current.col;
              rowSpan = current.rowSpan;
            }
            else {
              let rowSpan = 1;
              if (items.length > 3) {
                rowSpan = 2;
                if (items.length > 7)
                  rowSpan = 3;
              }
              column = 1;
              orderCount++;
            }
          }
          //let line = <OrderTileItem orderID={OrderNum} orderDesc={lineitem} />;
          //let line = { "defaultPosition": { "col": 1, "colSpan": 1, "rowSpan": 1 }, "item": <OrderTileItem orderID="Order #: 2366" prepTime={"11:00"} orderDesc='[{"Item": "Beef Dip Sandwich", "Qty": 1, "Price": 6.49 },{"Item": "Beef Barley Soup", "Qty": 1, "Price": 5.99 },{"Item": "Corn Salad", "Qty": 1, "Price": 5.19 }]' /> };
          let line = {
            defaultPosition: { order: orderCount, rowSpan: rowSpan, colSpan: 1, col: column }, item: <OrderTileItem orderHdr={header} orderItems={items} key={CustOrderID} />
          };

          //console.log("string line: " + JSON.stringify(line));
          curTiles.push(line);
          column = 1;
        }
        //break;
      }
      //curTiles = [{ "defaultPosition": { "col": 1, "colSpan": 1, "rowSpan": 2 }, "item": <OrderTileItem orderID="Order #: 2366" prepTime={"11:05"} orderDesc='[{"Item": "Beef Dip Sandwich", "Qty": 1, "Price": 6.49 },{"Item": "Beef Barley Soup", "Qty": 1, "Price": 5.99 },{"Item": "Corn Salad", "Qty": 1, "Price": 5.19 }]' /> }];
      //console.log("Created Tiles: " + JSON.stringify(curTiles));

      if (orderStatus === 1) {
        //  this.setState({ TilesPending: this.testTilesx });
        //  this.forceUpdate();
      }
      else if (orderStatus === 2) {
        this.newTileInfo = this.tileInfo;
        this.setState({ TilesPending: curTiles }); //this.tileInfo });  
        this.forceUpdate();
        console.log("TilesPending: " + JSON.stringify(this.state.TilesPending));
      }
      else if (orderStatus === 3)
        this.setState({ TilesAccepted: curTiles });
      else if (orderStatus === 5)
        this.setState({ TilesInProgress: curTiles });
      else if (orderStatus === 6)
        this.setState({ TilesReady: curTiles });
      else if (orderStatus === 7)
        this.setState({ TilesDone: curTiles });
      else if (orderStatus === 10)
        this.setState({ TilesRejected: curTiles });
      //console.log("TilesPending: " + JSON.stringify(this.state.TilesPending));
    } catch (error) {
      console.log("Failure in buildTiles - Error: " + error.message);
    }
  }
  //---------- Scheduler Events ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
  async loadEvents(eventStatus) {
    console.log(`Start Date: ${SessionInfo.CurrentSchedDate}`);
    let CD = await CallOM("GetEvents", eventStatus, SessionInfo.OrderLocationID, 2, SessionInfo.CurrentSchedDate); // Fetch CustomOrders based on OrderStatus
    console.log("Load Events: " + JSON.stringify(CD.d));
    if (CD.x.o >= 9000 && CD.x.o < 9500) {
      try {
        this.setState({ SchedEvents: CD.d.rows });
      } catch (error) {
        console.log(error.message);
      }
    }
    else {
      displayError(`Unable to retrieve Schedule for Location: ${SessionInfo.OrderLocationID}, Status: ${eventStatus}`);
      this.setState({ StoreLocations: [] });
    }
  }
  //-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
  repositionCurrent = (event) => {
    try {
      console.log("repositionCurrent value: " + JSON.stringify(event.value)); // + " target: " + JSON.stringify(event.target.props.items.item.key));
      let cnt = event.value.length;
      let currentOrders = this.state.PendingOrders;
      this.curOrderPos = [];
      for (let ix = 0; ix < cnt; ix++) { // Save all positions
        this.curOrderPos.push({
          key: currentOrders[ix].CustomOrderID, order: event.value[ix].order, rowSpan: event.value[ix].rowSpan, col: event.value[ix].col
        });
      }
      console.log("curOrderPos: " + JSON.stringify(this.curOrderPos));
    }
    catch (ex) {
      console.log("ex: " + ex.message);
    }
  }
  async initializeLocation() {
    //this.setState({ verticalPanes: this.vertSplitC });
    if (SessionInfo.OrderLocationID > 0) {
      await this.getAllStoreLocationDetail();
      await this.setState({ StoreLocationID: SessionInfo.OrderLocationID });
    }
    if (this.state.StoreLocationID > 0 && this.state.CurrentStoreLocationID !== this.state.StoreLocationID) {
      this.state.CurrentStoreLocationID = this.state.StoreLocationID;
      console.log("Init - LocationID: " + this.state.CurrentStoreLocationID);
      SessionInfo.headerTitle = this.state.StoreName;
      SessionInfo.itemName = "Item";
      this.state.RightO = false;
      this.setState({ selectedTopTab: 0 });
      this.clearTables();
      if (this.state.Contacts.length <= 1) {
        let CD = await CallOM("GetContacts", 0, this.state.CurrentStoreLocationID, 0); // NameIDList returned
        console.log("Contacts: " + JSON.stringify(CD.d));
        this.setState({ Contacts: CD.d });
      }
      console.log("Get Sublocations - current loc: " + this.state.CurrentStoreLocationID);
      if (this.state.Sublocations.length <= 1) {
        let CD = await CallOM("GetSublocations", 0, this.state.CurrentStoreLocationID, 0); // NameIDList returned
        //console.log("Sublocations: " + JSON.stringify(CD.d));
        this.setState({ Sublocations: CD.d });
      }
      if (this.state.StoreGroups.length <= 1) {
        let CD = await GetDropdownData(544709);
        console.log("StoreGroups Codes: " + JSON.stringify(CD.d));
        this.setState({ StoreGroups: CD.d });
      }
      if (this.state.ProvinceStates.length <= 1) {
        let CD = await GetDependentDropdownData(489030, 124, 163266);
        //console.log("ProvinceState Codes: " + JSON.stringify(CD.d));
        this.setState({ ProvinceStates: CD.d });
      }
      this.closeLeftPane();
      //----------- StoreItem Dictionaries --------------------------------- 
      this.state.StoreAmenitiesList = ["Wifi Available", "Coffee", "Specialty Coffee", "Salads", "Soups", "Hot Meals", "Desserts", "Wine", "Beer"];
      if (this.state.ProductItemCategories.length <= 1) {
        let CD = await GetDropdownData(1104137);
        this.setState({ ProductItemCategories: CD.d });
        this.ItemCategoriesDD = CD.d;
        SetProductItemCategory(CD.d);
      }
      if (this.state.ProductItemGroups.length <= 1) {
        let CD = await GetDropdownData(1104119);
        this.setState({ ProductItemGroups: CD.d });
      }
      if (this.state.ProductItemTypes.length <= 1) {
        let CD = await GetDropdownData(1104125);
        this.setState({ ProductItemTypes: CD.d });
        SetProductType(CD.d);
      }
      if (this.state.ItemStatusSet.length <= 1) {
        let CD = await GetDropdownData(1104159);
        this.setState({ ItemStatusSet: CD.d });
      }
      if (this.state.PromotionTypes.length <= 1) {
        let CD = await GetDropdownData(545179);
        this.setState({ PromotionTypes: CD.d });
      }
      if (this.state.PackagingTypes.length <= 1) {
        let CD = await GetDropdownData(519062);
        this.setState({ PackagingTypes: CD.d });
      }
      if (this.state.PricingTypes.length <= 1) {
        let CD = await GetDropdownData(1104167);
        this.setState({ PricingTypes: CD.d });
        SetPricingType(CD.d);
      }
      if (this.state.RateTypes.length <= 1) {
        let CD = await GetDropdownData(545339);
        this.setState({ RateTypes: CD.d });
      }
      if (this.state.PaymentMethods.length <= 1) {
        let CD = await GetDropdownData(544793);
        this.setState({ PaymentMethods: CD.d });
      }
      //this.setState({ DaysOfWeek: this.DayOfWeekDD });
      //------ Initialization Code - StoreLocations ------
      if (this.state.StoreLocationsDict.length <= 1) {
        let CD = await GetDropdownData(544596);
        this.setState({ StoreLocationsDict: CD.d });
      }
      if (this.state.StoreGroups.length <= 1) {
        let CD = await GetDropdownData(544709);
        this.setState({ StoreGroups: CD.d });
      }
      if (this.state.StoreItemsDict.length <= 1) {
        let CD = await GetDependentDropdownData(544619, this.state.StoreLocationID, 544598);
        this.setState({ StoreItemsDict: CD.d });
      }
      if (this.state.PromotionTypes.length <= 1) {
        let CD = await GetDropdownData(545179);
        this.setState({ PromotionTypes: CD.d });
      }
    }
    await this.selectTab(this.tabs.PENDINGORDERS);
  }
  //-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------  
  getStoreLocations = async (search) => { // Get the available StoreLocations
    let CD;
    if (!search)
      CD = await GetTableData(545207); // Get StoreInfoView
    else
      CD = await GetTableSearch(545207, search); // Search StoreInfoView - returns different results
    if (CD.x.o >= 9000 && CD.x.o < 9500) {
      try {
        const StoreLocations = CD.d.rows;
        this.setState({ StoreLocations });
        this.setState({ StoreLocationsCount: StoreLocations.length });
      } catch (error) {
        console.log(error.message);
      }
    }
    else {
      displayError("Unable to retrieve StoreLocations");
      this.setState({ StoreLocations: [] });
    }
  }
  storeLocationRowClick = (event) => {
    //displayMessage("Row Click");
    let last = this.lastSelectedIndex;
    const current = this.state.StoreLocations.findIndex(dataItem => dataItem === event.dataItem);
    this.lastSelectedIndex = last = current;
    this.state.StoreLocations.forEach(item => item.selected = false);
    const select = !event.dataItem.selected;
    for (let i = Math.min(last, current); i <= Math.max(last, current); i++) {
      this.state.StoreLocations[i].selected = select;
    }
    let PKID = this.state.StoreLocations[current].StoreLocationID;
    SessionInfo.OrderLocationID = PKID;
    //displayMessage("Select PKID: " + PKID);
    this.initializeLocation();
  }
  getStoreItems = async (search) => { // Get StoreItems for this Location
    if (!search)
      search = '';
    search = search + "*StoreLocationID=" + this.state.CurrentStoreLocationID;
    let CD = await CallOM("GetProductItemsForLocation", 1, this.state.CurrentStoreLocationID, 0, "", search); // Note - Retrieve Translated
    //this.setState({ StoreItems: CD.d });     
    //.log("Store Items d : " + JSON.stringify(CD.d));
    try {
      const StoreItems = CD.d.rows.map(dataItem => Object.assign({ selected: false }, dataItem));
      //console.log("Store Items: " + JSON.stringify(StoreItems));
      this.setState({ StoreItems });
    } catch (error) {
      console.log(error.message);
    }
  }
  getProductItems = async (search) => { // Get the MainStore product items - Company
    if (!search)
      search = '';
    search = search + "*StoreLocationID=0";
    let CD = await CallOM("GetMainProductItemsForLocation", 2, this.state.CurrentStoreLocationID, 2, search, "StoreItemID,ItemName,ItemDescription,BarcodeNumber,ItemPrice");
    //console.log("Product Items: " + JSON.stringify(CD.d));
    //this.setState({ StoreItems: CD.d });
    try {
      const MainStoreItems = CD.d.rows.map(dataItem => Object.assign({ selected: false }, dataItem));
      //console.log("MainStore Items: " + JSON.stringify(MainStoreItems));
      this.setState({ MainStoreItems });
      this.setState({ mainStoreItemsCount: MainStoreItems.length });
    } catch (error) {
      console.log("Error GetProductItems: " + error.message);
    }
  }
  getHoursOfOperation = async (search) => {
    if (!search)
      search = '';
    search = search + "*StoreLocationID=" + this.state.CurrentStoreLocationID;
    let CD = await CallOM("GetHoursOfOperation", 544608, this.state.CurrentStoreLocationID);
    //console.log("Hours of Operation: " + JSON.stringify(CD.d));
    try {
      const HoursOfOperation = CD.d.rows.map(dataItem => Object.assign({ selected: false }, dataItem));
      this.setState({ HoursOfOperation });
      this.setState({ hoursOfOperationCount: HoursOfOperation.length });
      this.state.saveHoursOfOperation = HoursOfOperation;
    } catch (error) {
      console.log(error.message);
    }
  }
  formatMoney = (amount) => {
    let decimalCount = 2;
    const decimal = ".";
    const thousands = ",";
    try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

      const negativeSign = amount < 0 ? "-" : "";

      let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
      let j = (i.length > 3) ? i.length % 3 : 0;

      return negativeSign + (j ? i.substring(0, j) + thousands : '') + i.substring(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
    } catch (e) {
      console.log(e)
    }
  }
  frmt = true;
  percentFormat = this.frmt === true ? (value) => new Intl.NumberFormat('en-US', { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value) : (value) => value;
  moneyFormat = (value) => new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
  dateTimeFormat = (value) => new Intl.DateTimeFormat('en-CA', { dateStyle: 'long', timeStyle: 'short' }).format(value)
  dateTimeSFormat = (value) => new Intl.DateTimeFormat('en-CA', { month: 'short', day: 'numeric', hour: 'numeric', minute: '2-digit' }).format(Date.parse(value))
  //
  getCustomOrders = async (search, statusID) => {
    if (!search)
      search = '';
    console.log(`GetCustomOrders - locationID: (${this.state.CurrentStoreLocationID}, status: ${statusID}`);
    let CD = await CallOM("GetOrders", statusID, SessionInfo.OrderLocationID, 2, search); // Fetch Pending Orders 
    //let CD = await GetTableSearch(545072, "*StoreLocationID=" + this.state.CurrentStoreLocationID + " and CustomOrderStatus=" + statusID + " and TotalPrice>0", "CustomerID,CustomOrderStatus,OrderCreationDate,OrderPaidDate,TotalPrice,ItemCount");
    try {
      const CustomOrders = CD.d.rows.map(dataItem => Object.assign({ selected: false }, dataItem));
      console.log(`GetCustomOrders(${statusID}): ${JSON.stringify(CustomOrders)}`);
      if (statusID === this.orderStatus.OrderPending)
        this.setState({ PendingOrders: CustomOrders });
      else if (statusID === this.orderStatus.OrderInProgress)
        this.setState({ CurrentOrders: CustomOrders });
      else if (statusID === this.orderStatus.Ready)
        this.setState({ ReadyOrders: CustomOrders });
      else if (statusID === 102)
        this.setState({ PickupOrders: CustomOrders });
      else if (statusID === this.orderStatus.InDelivery)
        this.setState({ DeliveryOrders: CustomOrders });
      else if (statusID === 100)
        this.setState({ InPaymentOrders: CustomOrders });
      else if (statusID >= 8) // Might be >= 8?  this.orderStatus.OrderPaid
        this.setState({ CompletedOrders: CustomOrders });
    } catch (error) {
      console.log("Failure in getCustomOrders - Error: " + error.message);
    }
  }
  getCustomOrdersStatus = async (statusID) => {
    console.log(`Get CustomOrdersStatus - locationID: ${this.state.CurrentStoreLocationID} StatusID: ${statusID}`);
    this.getCustomOrders("", statusID);
  }
  getAllStoreLocationDetail = async () => {
    console.log("StoreLocationID: " + SessionInfo.OrderLocationID);
    displayMessage("GREEN");
    //await this.getContact(this.state.ContactID);
    //await this.getLocation(this.state.LocationID);            
    await this.getStoreLocationRecord(SessionInfo.OrderLocationID);
    this.loadExistingPage = false;
  }
  getStoreLocationRecord = async (Key) => {
    let CD = await CallOM("GetStoreLocation", 0, Key, 0);
    //let CD = await GetTableRow(544596, Key); // Get the current StoreLocation
    //console.log("after GetStoreLocation: " + JSON.stringify(CD.d));
    this.setState({ StoreLocationID: Key });
    StoreLocationsO.forEach((elem) => {
      if (elem.n in CD.d) {
        //console.log("Set " + elem.n + ": " + CD.d.row[elem.n]); 
        let sobj = {};
        try {
          if (elem.t === 34 || elem.t === 35)
            sobj[elem.n] = new Date(CD.d[elem.n]);
          else
            sobj[elem.n] = CD.d[elem.n];
          //console.log("sobj " + JSON.stringify(sobj));
          this.setState(sobj);
        } catch (error) {
          console.log("Set StoreLocation Field: " + elem.n + " Error: " + error.message);
        }
      }
    });
    //console.log("Amenities: " + this.state.StoreAmenities);
    let AList = [];

    if ((this.state.StoreAmenities & 0x0000000000000800) === 0x0000000000000800)
      AList.push("Beer");
    if ((this.state.StoreAmenities & 0x0000000000000004) === 0x0000000000000004)
      AList.push("Coffee");
    if ((this.state.StoreAmenities & 0x0000000000000200) === 0x0000000000000200)
      AList.push("Desserts");
    if ((this.state.StoreAmenities & 0x0000000000000020) === 0x0000000000000020)
      AList.push("Salads");
    if ((this.state.StoreAmenities & 0x0000000000000040) === 0x0000000000000040)
      AList.push("Soups");
    if ((this.state.StoreAmenities & 0x0000000000000080) === 0x0000000000000080)
      AList.push("Hot Meals");
    if ((this.state.StoreAmenities & 0x0000000000000008) === 0x0000000000000008)
      AList.push("Specialty Coffee");
    if ((this.state.StoreAmenities & 0x0000000000000002) === 0x0000000000000002)
      AList.push("Wifi Available");
    if ((this.state.StoreAmenities & 0x0000000000000400) === 0x0000000000000400)
      AList.push("Wine");
    console.log("Amenities AList: " + JSON.stringify(AList));
    this.setState({ StoreAmenitiesVals: AList });
    //this.state.StoreAmenitiesList = ["Wifi Available", "Coffee", "Specialty Coffee", "Salads", "Soups", "Hot Meals", "Desserts", "Wine", "Beer"];
    this.setItemStateChanged(false);
  }
  //-------------------- End of Page Edit -----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

  //------------------------- Select Invite----------------------------------------------------------------------
  selectionHoursOfOpChange = (event) => {
    event.dataItem.selected = !event.dataItem.selected;
    this.forceUpdate();
  }
  //------------------------- Sort Order Row ---------------------------------------------------
  getOrderSort = (sort) => {
    switch (this.currentPanel) {
      case this.tabs.PENDINGORDERS:
        return orderBy(this.state.PendingOrders,sort);
      case this.tabs.KITCHENORDERS:
        return orderBy(this.state.CurrentOrders,sort);
      case this.tabs.READY:
        return orderBy(this.state.ReadyOrders,sort);
      case this.tabs.READYFORPICKUP:
        return orderBy(this.state.PickupOrders,sort);
      case this.tabs.INDELIVERY:
        return orderBy(this.state.DeliveryOrders,sort);
      case this.tabs.INPAYMENT:
        return orderBy(this.state.InPaymentOrders,sort);
      case this.tabs.COMPLETEDORDERS:
        return orderBy(this.state.CompletedOrders,sort);
    }
  }
  //------------------------- Select Order Row ---------------------------------------------------
  orderRowClick = async (event) => {
    console.log(`orderRowClick - currentPanel: ${this.currentPanel}`);
    let current = -1;
    let orderLine = undefined;
    switch (this.currentPanel) {
      case this.tabs.PENDINGORDERS:
        current = this.state.PendingOrders.findIndex(dataItem => dataItem === event.dataItem);
        if (current >= 0) orderLine = this.state.PendingOrders[current];
        break;
      case this.tabs.KITCHENORDERS:
        current = this.state.CurrentOrders.findIndex(dataItem => dataItem === event.dataItem);
        if (current >= 0) orderLine = this.state.CurrentOrders[current];
        break;
      case this.tabs.READY:
        current = this.state.ReadyOrders.findIndex(dataItem => dataItem === event.dataItem);
        if (current >= 0) orderLine = this.state.ReadyOrders[current];
        break;
      case this.tabs.READYFORPICKUP:
        current = this.state.PickupOrders.findIndex(dataItem => dataItem === event.dataItem);
        if (current >= 0) orderLine = this.state.PickupOrders[current];
        break;
      case this.tabs.INDELIVERY:
        current = this.state.DeliveryOrders.findIndex(dataItem => dataItem === event.dataItem);
        if (current >= 0) orderLine = this.state.DeliveryOrders[current];
        break;
      case this.tabs.INPAYMENT:
        current = this.state.InPaymentOrders.findIndex(dataItem => dataItem === event.dataItem);
        if (current >= 0) orderLine = this.state.InPaymentOrders[current];
        break;
      case this.tabs.COMPLETEDORDERS:
        current = this.state.CompletedOrders.findIndex(dataItem => dataItem === event.dataItem);
        if (current >= 0) orderLine = this.state.CompletedOrders[current];
        break;
    }
    console.log(`CustomOrder Click - current: ${current}`);
    if (current >= 0) {
      SessionInfo.CustomOrderID = orderLine.CustomOrderID;
      let CD = await GetTableRowTrans(545072, SessionInfo.CustomOrderID);
      //console.log("after Get: " + JSON.stringify(CD.d));
      CustomOrdersO.forEach((elem) => {
        if (elem.n in CD.d.row) {
          //console.log("Set " + elem.n + ": " + CD.d.row[elem.n]); 
          let sobj = {};
          try {
            if (elem.t === 34 || elem.t === 35)
              sobj[elem.n] = new Date(CD.d.row[elem.n]);
            else
              sobj[elem.n] = CD.d.row[elem.n];
            //console.log("sobj " + JSON.stringify(sobj));
            this.setState(sobj);
          } catch (error) {
            console.log("Set CustomOrder Field: " + elem.n + " Error: " + error.message);
          }
        }
      });
      this.setItemStateChanged(false);
      await this.getCustomOrderItems();
    }
  }
  getCustomOrderItems = async (search) => {
    if (!search)
      search = '';
    console.log("Get CustomOrderItems - CustomOrderID=" + SessionInfo.CustomOrderID);
    let CD = await GetTableSearch(545113, search + "*CustomOrderID=" + SessionInfo.CustomOrderID, "StoreItemID,Quantity,OptionCount,TotalPrice"); // Note - CustomOrderID needs to be set
    //.log("CustomOrderItems: " + JSON.stringify(CD.d));
    try {
      const CustomOrderItems = CD.d.rows.map(dataItem => Object.assign({ selected: false }, dataItem));
      this.setState({ CustomOrderItems });
      this.setState({ customOrderItemsCount: CustomOrderItems.length });
    } catch (error) {
      console.log("Failure in getCustomOrderItems - Error: " + error.message);
    }
  }
  customOrdersPageChange = (event) => {
    this.setState({ customOrdersSkip: event.page.skip, customOrdersTake: event.page.take });
  }
  //--------------------------------------------------------------
  promoRowClick = async (event) => { // GetPromotions
    const current = this.state.Promotions.findIndex(dataItem => dataItem === event.dataItem);
    if (current >= 0) {
      this.state.StorePromotionID = this.state.Promotions[current].StorePromotionID;
      let CD = await GetTableRowRaw(544701, this.state.StorePromotionID);
      console.log("after Get: " + JSON.stringify(CD.d));
      StorePromotionsO.forEach((elem) => {
        if (elem.n in CD.d.row) {
          //console.log("Set " + elem.n + ": " + CD.d.row[elem.n]); 
          let sobj = {};
          try {
            if (elem.t === 34 || elem.t === 35)
              sobj[elem.n] = new Date(CD.d.row[elem.n]);
            else
              sobj[elem.n] = CD.d.row[elem.n];
            //console.log("sobj " + JSON.stringify(sobj));
            this.setState(sobj);
          } catch (error) {
            console.log("Set Promotion Field: " + elem.n + " Error: " + error.message);
          }
        }
      });
      await this.GetPromotionImage(this.state.ImageID);
      this.setItemStateChanged(false);
      this.setState({ selectedTopTab: 6 });  // Promotions
    }
  }
  promoPageChange = (event) => {
    this.setState({ promoSkip: event.page.skip, promoTake: event.page.take });
  }

  //------------------------- Image Management ------------------------------
  onSelectImageFile = async (event) => {
    event.stopPropagation();
    event.preventDefault();
    //console.log("StoreItemID: " + this.state.StoreItemID);
    //if (this.state.StoreItemID === 0 && this.InsertRecordType != 1)
    if (this.state.StoreItemID === 0)
      displayWarning("Product Item must be Selected or Added First");
    else {
      this.file = event.target.files[0];
      //console.log("File: " + JSON.stringify(file));
      //this.setState({ ContractFileName: file.name });
      let reader = new window.FileReader();
      this.fileReady = false;
      try {
        reader.readAsArrayBuffer(this.file);
        reader.onloadend = async () => {
          await this.convertToBuffer(reader);
          this.fileReady = true;
          displayMessage("Image: " + this.file.name + " Loaded");
          this.DocumentLoaded = true;
          this.DocumentSaved = false;
        }
        this.fileCheck();
        event.target.value = null;
      }
      catch (error) {
        console.log("FileReader Error: " + error);
        displayError("Error on File Read");
        return;
      }
    }
  }
  fileCheck = async () => {
    if (this.fileReady === true) {
      let ImageType = GetFileExtension(this.file.name);
      //console.log("ImageType: " + ImageType);
      ImageType = ImageType.toLowerCase();
      this.setState({ ImageType });
      let ix = SessionInfo.DocumentTypes.findIndex(c => c.ddName === ImageType);
      if (ix >= 0) {
        this.setState({ DocumentTypeID: SessionInfo.DocumentTypes[ix].ddID });
        let iVal = await this.saveImageToServer(this.StorePromotionsOID, this.state.StorePromotionID); // Note StorePromotionsTable
        displayMessage(`Image saved for '${this.state.ItemName}'`);
        setTimeout(this.retrieveImage, 1000, iVal);
      }
      else
        displayWarning("Unknown file type: " + ImageType);
      return;
    }
    setTimeout(this.fileCheck, 200);
  }
  retrieveImage = async (iVal) => {
    console.log("Retrieve Image: " + iVal);
    await this.GetPromotionImage(iVal);
  }
  convertToBuffer = async (reader) => { // File is converted to a buffer to prepare for uploading to OM
    const UploadFile = await Buffer.from(reader.result);
    //set this buffer -using es6 syntax
    this.setState({ UploadFile });
    //console.log("converToBuffer - Image uploadfile Length: " + UploadFile.length);
    // Display the image
    let image = new Blob([UploadFile], { type: 'image/jpeg' });
    let imageURL = URL.createObjectURL(image);
    this.setState({ ProductItemPhotoURL: imageURL });
  };
  saveImageToServer = async (tableObjID, itemID) => {
    //console.log('saveImageToServer: ');
    if (this.isSavingDocument) {
      displayMessage("Document Save in Progress");
      return;
    }
    else if (!this.state.UploadFile)
      displayError('A file must be selected');
    else {
      this.isSavingDocument = true;
      //displayMessage('Saving Image for ' + this.state.ProductItemCategory);
      //console.log("Save Image " + JSON.stringify(this.state.UploadFile));
      let CD = await CallOM("SaveProductImage", tableObjID, itemID, 20, this.state.UploadFile, this.state.ImageType); // 17
      console.log("Saved Image " + this.state.UploadFile.length);
      this.isSavingDocument = false;
      let ival = CD.d;
      return ival;
    }
  }
  GetPromotionImage = async (imageID) => {
    const imageURL = await getImageFileFromServer(imageID);
    this.setState({ PromotionPhotoURL: imageURL });
  }
  //--------------------------------------------------------------
  onSaveStoreItem = async () => {
    let copyState = Object.assign({}, this.state);
    let type = 0;
    for (var prop in copyState) {
      if (prop === "string") {
        type = 1;
        copyState[prop] = undefined;
      }
      else if (prop === "selectedTopTab")
        type = 2;
      else if (type === 0) {
      }
      if (type === 2) {
        copyState[prop] = undefined;
      }
    }
    //console.log("WorkflowID: " + JSON.stringify(copyState));
    await UpdateRow(544619, 0, undefined, copyState, copyState.StoreItemID, "StoreItemID"); // Save to OM 
    //await this.getRecords();
    this.setItemStateChanged(false);
  }
  onAddStoreItem = async () => {
    this.setState({ StoreItemID: 0 });
    this.setState({ ProductItemCategoryID: 0 });
    this.setState({ ProductItemGroupID: 0 });
    this.setState({ ProductItemTypeID: 0 });
    this.setState({ ShelfLifeDays: 0 });
    this.setState({ ItemPrice: 0 });
    this.setState({ ItemStatusID: 0 });
    this.setState({ PackagingTypeID: 0 });
    this.setState({ PricingTypeID: 0 });
    this.setState({ RateTypeID: 0 });

    this.setState({ ItemName: 'NewItem' });
    this.setState({ ItemDescription: '' });
    this.setState({ UPCNumber: '' });
    this.setState({ BarcodeNumber: '' });
    displayMessage("Ready for New Store Item");
  }
  //------------------------- Edit StoreItem Grid ------------------------------------------------------------------  
  StoreItemCommandCell;
  enterInsertSI() {
    const dataItem = { inEdit: true };
    const allRecords = this.state.StoreItems.slice();
    allRecords.unshift(dataItem); // Add to the beginning
    this.updateStoreItems(allRecords, dataItem);
    this.setState({ StoreItems: allRecords });
  }
  enterEditSI(dataItem) {
    this.updateStoreItems(this.state.StoreItems, dataItem).inEdit = true;
    this.setState({ StoreItems: this.state.StoreItems.slice() });
  }
  saveSI(dataItem) {
    dataItem.inEdit = undefined;
    if (dataItem.StoreItemID === undefined)
      dataItem.StoreItemID = 0;
    console.log("Save");
    dataItem.StoreItemID = this.updateStoreItems(this.state.StoreItems, dataItem).StoreItemID;  // Save to OM
    this.setState({ StoreItems: this.state.StoreItems.slice() });
    //console.log("Update Row - locationID: " + dataItem.StoreLocationID);
    UpdateRow(545207, 0, this.state.StoreItems, dataItem, dataItem.StoreLocationID, "StoreLocationID");  // Update StoreInfoView
  }
  cancelSI(dataItem) {
    if (dataItem.StoreItemID) {
      let originalItem = this.state.StoreItems.find(p => p.StoreItemID === dataItem.StoreItemID)
      if (originalItem.inEdit) originalItem.inEdit = false;
      this.updateStoreItems(this.state.StoreItems, originalItem);
    } else {
      this.updateStoreItems(this.state.StoreItems, dataItem, !dataItem.StoreItemID); // remove false
    }
    this.setState({ StoreItems: this.state.StoreItems.slice() });
  }
  removeSI(dataItem) {
    dataItem.inEdit = undefined;
    let key = dataItem.StoreItemID;
    console.log("remove: " + key);
    //console.log("dateitem: " + JSON.stringify(dataItem));
    //console.log("Before StoreItems: " + JSON.stringify(this.state.StoreItems.slice()));
    this.updateStoreItems(this.state.StoreItems, dataItem, true);
    //console.log("After StoreItems: " + JSON.stringify(this.state.StoreItems.slice()));
    DeleteRowTry(this.StoreItemsOID, key); // Delete in the StoreItems Table in OM  
    this.setState({ StoreItems: this.state.StoreItems.slice() });
  }
  itemChangeSI(event) {
    const value = event.value;
    const name = event.field;
    if (!name) {
      return;
    }
    const updatedData = this.state.StoreItems.slice();
    const item = this.updateStoreItems(updatedData, event.dataItem);
    //console.log("itemChange - name: " + name + " value: " + JSON.stringify(value));
    if (!value.ddID)
      item[name] = value;
    else
      item[name] = value.ddID;
    this.setState({ StoreItems: updatedData });
  }
  updateStoreItems(data, item, remove) {  // data - is the entire data set (JSON), item - is the current line 
    let updated;
    //eslint-disable-next-line                   
    let index = data.findIndex(p => p === item || item.StoreItemID && p.StoreItemID === item.StoreItemID); // Note - for new line will find 0
    if (index >= 0) {
      updated = Object.assign({}, item);
      data[index] = updated;
    }
    if (remove) {
      data = data.splice(index, 1);
    }
    return data[index];
  }
  //------------------------- End Edit StoreItem Grid ------------------------------------------------------------------

  //------------------------ Promotions ---------------------------------------------------------------
  onCreatePromotion = async () => {
    await this.saveRecord();
  }
  //------------------------- Edit HoursOfOperation Grid ------------------------------------------------------------------
  HOOItemCommandCell;
  enterInsertHOO() {
    console.log("Insert HoursOfOperation");
    const dataItem = { inEdit: true };
    const allRecords = this.state.HoursOfOperation.slice();
    allRecords.unshift(dataItem); // Add to the beginning 
    //console.log("Call Update HoursOfOperation");
    this.updateHOOItems(allRecords, dataItem);
    //console.log("After Update HoursOfOperation");
    this.setState({ HoursOfOperation: allRecords });
    console.log("end HoursOfOperation");
  }
  enterEditHOO(dataItem) {
    this.updateHOOItems(this.state.HoursOfOperation, dataItem).inEdit = true;
    this.setState({ HoursOfOperation: this.state.HoursOfOperation.slice() });
  }
  saveHOO(dataItem) {
    dataItem.inEdit = undefined;
    if (dataItem.HoursOfOpID === undefined)
      dataItem.HoursOfOpID = 0;
    //console.log("Save");
    dataItem.HoursOfOpID = this.updateHOOItems(this.state.HoursOfOperation, dataItem).HoursOfOpID;  // Save to OM
    this.setState({ HoursOfOperation: this.state.HoursOfOperation.slice() });
    this.updateHOOItems(this.state.HoursOfOperation, dataItem);
    //console.log("save dataItem: " + JSON.stringify(dataItem));
    let saveItem = JSON.parse(JSON.stringify(dataItem)); // Copy Object
    let offset = new Date().getTimezoneOffset(); // UTC Offset
    console.log("starttime: " + dataItem.StartTime + " offset: " + offset);
    if (offset !== 0) {
      let strdate = moment(dataItem.StartTime).toISOString(); // convert to UTC
      let strt = new Date(strdate);
      //console.log("save - starttime: " + strt + " offset: " + offset);
      saveItem.StartTime = new Date(strt.setTime(strt.getTime() + -offset * 60000));
      //console.log("save after - starttime: " + JSON.stringify(saveItem.StartTime) + " offset: " + offset);
      //console.log("after after - starttime: " + saveItem.StartTime + " offset: " + offset);
    }
    //console.log("endtime: " + saveItem.EndTime.toString() + " offset: " + offset);
    if (offset !== 0) {
      try {
        let end = new Date(dataItem.EndTime.toISOString()); // Convert to UTC and back to Date Object
        saveItem.EndTime = new Date(end.setTime(end.getTime() + -offset * 60000));
      }
      catch (Ex) {
        console.log("saveHOO - EndTime: " + saveItem.EndTime + " Msg: " + Ex);
      }
    }
    //console.log("Update Row : " + JSON.stringify(saveItem));
    CallOM("UpdateHoursOfOperation", 544608, saveItem.HoursOfOpID, 13, saveItem);
    console.log("after dataItem: " + JSON.stringify(dataItem));
    //UpdateRow(544608, 0, this.state.HoursOfOperation, dataItem, dataItem.HoursOfOpID, "HoursOfOpID");  // Update StoreInfoView
  }
  cancelHOO(dataItem) {
    if (dataItem.HoursOfOpID) {
      let originalItem = this.state.HoursOfOperation.find(p => p.HoursOfOpID === dataItem.HoursOfOpID)
      if (originalItem.inEdit) originalItem.inEdit = false;
      this.updateHOOItems(this.state.HoursOfOperation, originalItem);
    } else {
      this.updateHOOItems(this.state.HoursOfOperation, dataItem, !dataItem.HoursOfOpID); // remove false
    }
    this.setState({ HoursOfOperation: this.state.HoursOfOperation.slice() });
  }
  removeHOO(dataItem) {
    dataItem.inEdit = undefined;
    let key = dataItem.HoursOfOpID;
    console.log("remove: " + key);
    this.updateHOOItems(this.state.HoursOfOperation, dataItem, true);
    this.state.saveHoursOfOperations = this.state.HoursOfOperation;
    DeleteRowTry(544608, key); // Delete in the HoursOfOperation Table in OM
    this.setState({ HoursOfOperation: this.state.HoursOfOperation.slice() });
  }
  itemChangeHOO(event) {
    const value = event.value;
    const name = event.field;
    if (!name)
      return;
    const updatedData = this.state.HoursOfOperation.slice();
    const item = this.updateHOOItems(updatedData, event.dataItem);
    console.log("itemChange - name: " + name + " value: " + JSON.stringify(value));
    if (!value.ddID)
      item[name] = value;
    else
      item[name] = value.ddID;
    this.setState({ HoursOfOperation: updatedData });
  }
  updateHOOItems(data, item, remove) {  // data - is the entire data set (JSON), item - is the current line 
    let updated;
    //eslint-disable-next-line                   
    let index = data.findIndex(p => p === item || item.HoursOfOpID && p.HoursOfOpID === item.HoursOfOpID); // Note - for new line will find 0
    if (index >= 0) {
      updated = Object.assign({}, item);
      data[index] = updated;
    }
    if (remove) {
      data = data.splice(index, 1);
    }
    return data[index];
  }
  //------------------------- End Edit HoursOfOperation Grid ------------------------------------------------------------------    
  //--------------------- List Element Formats -----------------------------------------------
  OrderHeader = () => {
    return (
      <ListViewHeader style={{ color: 'rgb(160, 160, 160)', fontSize: 14 }} className='pl-4 pb-2 pt-2'>
        Orders {this.state.OrderState}
      </ListViewHeader>
    );
  }
  OrderFormat = props => {
    const minDate = new Date(2019, 9, 9);
    let item = props.dataItem;
    let imageVal = undefined;
    if (item["Image"]) {
      let value = item["Image"];
      //console.log("ImageCell value: " + value);
      const byteCharacters = window.atob(value);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      let image = new Blob([byteArray], { type: 'image/jpeg' });
      //console.log("image: " + JSON.stringify(image));
      imageVal = URL.createObjectURL(image);
    }
    return (
      <div className="listRow" onClick={(e) => this.ViewDetail(item)}>
        <div className="listCol1">
          <span style={{ fontSize: 16, color: "black" }} >Order Type: </span>
          <span style={{ fontSize: 16, color: "black", fontWeight: "bold" }} >{item.CustomOrderType}</span><br />
          <span style={{ fontSize: 16, color: "black", fontWeight: "bold" }} >{item.CustomerID}</span><br />
          <span style={{ fontSize: 16, color: "black", fontWeight: "bold" }} >{item.PhoneNumber}</span><br />
        </div>
        <div className="listCol2">
          <span style={{ fontSize: 16, color: "black" }} >Total: </span>
          <span style={{ fontSize: 16, color: "black", fontWeight: "bold" }} >{this.moneyFormat(item.TotalPrice)}</span> <br />
          <span style={{ fontSize: 16, color: "black" }} >Items: </span>
          <span style={{ fontSize: 16, color: "black", fontWeight: "bold" }} >{item.ItemCount}</span>
        </div>
        <div className="listCol3">
          <span style={{ fontSize: 16, color: "black" }} >Ordered: </span>
          <span style={{ fontSize: 16, color: "black", fontWeight: "bold" }} >{!item.OrderCreationDate || Date.parse(item.OrderCreationDate) < minDate ? "" : this.dateTimeSFormat(item.OrderCreationDate)}</span>  <br />
          <span style={{ fontSize: 16, color: "black" }} >Requested: </span>
          <span style={{ fontSize: 16, color: "black", fontWeight: "bold" }} >{!item.OrderCreationDate || Date.parse(item.RequestDateTime) < minDate ? "ASAP" : this.dateTimeSFormat(item.RequestDateTime)}</span> <br />
          <span style={{ fontSize: 16, color: "black" }} >Scheduled: </span>
          <span style={{ fontSize: 16, color: "black", fontWeight: "bold" }} >{!item.ScheduledPrepDateTime || Date.parse(item.ScheduledPrepDateTime) < minDate ? "" : this.dateTimeSFormat(item.ScheduledPrepDateTime)}</span>
        </div>
        <div className="listCol4">
          <img src={require("./images/delete.svg")} alt="" id="GridRefresh" title="Accept the current entry" className="functionImage" onClick={(e) => this.ClickPending(item)} /><br /> {/*onClick={this.ClickPending(item)} */}
          <Button imageUrl={require("./images/delete.svg")} onClick={(e) => this.ClickPending(item)}>View Details</Button> {/* */}
        </div>
      </div>
    );
  }
  OrderFooter = () => {
    return (
      <ListViewFooter className='listFooter'>
        <div className='footerLeft'>
          <span>SubTotal </span>
          <span>{this.moneyFormat(this.state.SubTotalPrice)}</span>
          <br />
          <span style={{ left: '180px', }}>Tax </span>
          <span>{this.moneyFormat(this.state.TotalTaxAmount)}</span>
        </div>
        <div className='footerRight'>
          <span>TOTAL </span>
          <span>{this.moneyFormat(this.state.TotalPrice)}</span>
          <br />
          <Button id="checkoutButton" icon="restore" style={{ cursor: 'pointer', fontSize: 'large', color: 'black', fontWeight: 'bold', borderRadius: '10px', left: '80px', }} onClick={this.onCheckout} >Checkout</Button>
        </div>
      </ListViewFooter>
    );
  }
  //---------------------------------------------------------------
  OrderItemHeader = () => {
    return (
      <ListViewHeader style={{ color: 'black', fontSize: 18 }} className='pl-4 pb-2 pt-2'>
        Order Details For: {this.state.CustomerName}
      </ListViewHeader>
    );
  }
  OrderItemFormat = props => {
    let item = props.dataItem;
    let imageVal = undefined;
    let optionsList = "<span style='color:#ff1a75'> OPTIONS </span><br/>";
    console.log("optionList value: " + optionsList);
    if (item["Image"]) {
      let value = item["Image"];
      //console.log("ImageCell value: " + value);
      const byteCharacters = window.atob(value);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      let image = new Blob([byteArray], { type: 'image/jpeg' });
      //console.log("image: " + JSON.stringify(image));
      imageVal = URL.createObjectURL(image);
    }
    return (
      <div className="listRow">
        <div className="topItems">
          <div className="listCol1">
            <span style={{ fontSize: 16, color: "black" }} >Item: </span>
            <span style={{ fontSize: 16, color: "black", fontWeight: "bold" }} >{item.StoreItemID}</span><br />
            <span style={{ fontSize: 16, color: "black" }} >{item.ItemDescription}</span>
          </div>
          <div className="listCol2">
            <span style={{ fontSize: 16, color: "black" }} >Price: </span>
            <span style={{ fontSize: 16, color: "black", fontWeight: "bold" }} >{this.moneyFormat(item.Price)}</span> <br />
            <span style={{ fontSize: 16, color: "black" }} >Options: </span>
            <span style={{ fontSize: 16, color: "black", fontWeight: "bold" }} >{this.moneyFormat(item.OptionsPrice)}</span>
          </div>
          {/*<div className="listCol4">
            <Avatar shape='rounded' type='image' style={{ height: '50px', width: '50px', }}>
            <img src={imageVal} style={{ height: '50px', width: '50px', }} />
          </Avatar> 
          </div>  */}
          <div className="listCol3">
            <img src={require("./images/delete.svg")} alt="" id="GridRefresh" title="Delete the current entry" className="functionImage" onClick={(e) => window.confirm(`Delete ${item.ItemName.trimRight()}?`) && this.onDeleteItem(item)} /> {/* */}
          </div>
        </div>
        {this.hasOptions == true ?
          <div className="bottomItems">
            <div id="itemOptions" dangerouslySetInnerHTML={{ __html: optionsList }} ref={(div) => { this.anchor = div; }}></div>
          </div> : null}
      </div>
    );
  }
  OrderItemFooter = () => {
    return (
      <ListViewFooter className='listFooter'>
        <div className='footerLeft'>
          <span>SubTotal </span>
          <span>{this.moneyFormat(this.state.SubTotalPrice)}</span>
          <br />
          <span style={{ left: '180px', }}>Tax </span>
          <span>{this.moneyFormat(this.state.TotalTaxAmount)}</span>
        </div>
        <div className='footerRight'>
          <span>TOTAL </span>
          <span>{this.moneyFormat(this.state.TotalPrice)}</span>
          <br />
        </div>
      </ListViewFooter>
    );
  }
  //------------------- ListView Events ----------------------------------------------------------
  ClickPending = (item) => {
    console.log("ClickPending: " + JSON.stringify(item));
    var CustomerID = item.CustomerID;
    //this.setState({ CustomerName: item.CustomOrderID }); 
    var CustomerName = item.CustomerID;
    console.log("CustomerName: " + CustomerName);
    this.setState({ CustomerName: item.CustomerID });
    const curTime = new Date();
    this.state.ScheduledPrepDateTime = curTime.setMinutes(curTime.getMinutes() + 30);
    this.setState({ showPopup: !this.state.showPopup });
  }
  ClickMinus = () => {
    console.log("ScheduledPrepDateTime: " + this.state.ScheduledPrepDateTime);
    //const curTime = Date.parse(this.state.ScheduledPrepDateTime);
    const curTime = new Date(this.state.ScheduledPrepDateTime);
    this.setState({
      ScheduledPrepDateTime: curTime.setMinutes(curTime.getMinutes() - 1)
    });
  }
  ClickPlus = () => {
    console.log("ScheduledPrepDateTime: " + this.state.ScheduledPrepDateTime);
    //const curTime = Date.parse(this.state.ScheduledPrepDateTime);
    const curTime = new Date(this.state.ScheduledPrepDateTime);
    this.setState({
      ScheduledPrepDateTime: curTime.setMinutes(curTime.getMinutes() + 1)
    });
  }
  ViewDetail = (item) => {
    this.state.CustomOrderID = item.CustomOrderID;
    this.getCustomOrderItemsStatus(2);
    this.setState({ CustomerName: item.CustomerID });
    var TaxAmt = item.TotalTaxAmount + item.TotalTax2Amount + item.TotalTax3Amount;
    this.setState({ TotalTaxAmount: TaxAmt });
    this.setState({ TotalPrice: item.TotalPrice });
    this.setState({ SubTotalPrice: item.TotalPrice - TaxAmt });
  }
  //---------------------
  /**
 * Adds time to a date. Modelled after MySQL DATE_ADD function.
 * Example: dateAdd(new Date(), 'minute', 30)  //returns 30 minutes from now.
 * https://stackoverflow.com/a/1214753/18511
 * 
 * @param date  Date to start with
 * @param interval  One of: year, quarter, month, week, day, hour, minute, second
 * @param units  Number of units of the given interval to add.
 */
  dateAdd(date, interval, units) {
    if (!(date instanceof Date))
      return undefined;
    var ret = new Date(date); //don't change original date
    var checkRollover = function () { if (ret.getDate() !== date.getDate()) ret.setDate(0); };
    switch (String(interval).toLowerCase()) {
      case 'year': ret.setFullYear(ret.getFullYear() + units); checkRollover(); break;
      case 'quarter': ret.setMonth(ret.getMonth() + 3 * units); checkRollover(); break;
      case 'month': ret.setMonth(ret.getMonth() + units); checkRollover(); break;
      case 'week': ret.setDate(ret.getDate() + 7 * units); break;
      case 'day': ret.setDate(ret.getDate() + units); break;
      case 'hour': ret.setTime(ret.getTime() + units * 3600000); break;
      case 'minute': ret.setTime(ret.getTime() + units * 60000); break;
      case 'second': ret.setTime(ret.getTime() + units * 1000); break;
      default: ret = undefined; break;
    }
    return ret;
  }
  //---- Top Tab -----------------------------------
  handleTopTabSelect = async (e) => { // Tab Selection
    await this.selectTab(e.selected);
  }
  selectTab = async (tabNumber) => {
    console.log(`selectTab - TabN=${tabNumber} LocationID=${SessionInfo.OrderLocationID}`);
    if (SessionInfo.clearSearch)
      SessionInfo.clearSearch();
    this.clearRecord();
    if (SessionInfo.OrderLocationID > 0) {
      await this.setState({ selectedTopTab: tabNumber });
      SessionInfo.setGridRefresh = 2; // Hide Grid Refresh in orderID 
      SessionInfo.setExcelExport = 2;
      SessionInfo.setGridAdd = 2;
      this.currentPanel = tabNumber;
      if (tabNumber === this.tabs.PENDINGORDERS) {
        this.closeLeftPane();
        this.openRightPaneWide(false);
        SessionInfo.searchTitle = "Search Pending Orders";
        await this.getCustomOrdersStatus(this.orderStatus.OrderPending); // Pending + OrderOpen
        //await this.buildTiles(2);
      }
      else if (tabNumber === this.tabs.KITCHENORDERS) {
        this.closeRightPane();
        SessionInfo.searchTitle = "Search Current Orders";
        this.closeLeftPane();
        this.openRightPaneWide(false);
        await this.getCustomOrdersStatus(this.orderStatus.OrderInProgress);
        //await this.buildTiles(2);
        //await this.getCustomOrdersStatus(5); // InProgress + Accepted
      }
      else if (tabNumber === this.tabs.READYORDERS) {
        this.closeRightPane();
        SessionInfo.searchTitle = "Search Ready Orders";
        this.closeLeftPane();
        this.openRightPaneWide(false);
        await this.getCustomOrdersStatus(this.orderStatus.Ready);
        //await this.getCustomOrdersStatus(6); // Ready
      }
      else if (tabNumber === this.tabs.READYFORPICKUP) {
        this.closeRightPane();
        SessionInfo.searchTitle = "Search Ready For Pickup Orders";
        this.closeLeftPane();
        this.openRightPaneWide(false);
        await this.getCustomOrdersStatus(102);
      }
      else if (tabNumber === this.tabs.DELIVERY) {
        this.closeRightPane();
        SessionInfo.searchTitle = "Search In Delivery Orders";
        this.closeLeftPane();
        this.openRightPaneWide(false);
        await this.getCustomOrdersStatus(this.orderStatus.InDelivery);
      }
      else if (tabNumber === this.tabs.INPAYMENT) {
        this.closeRightPane();
        SessionInfo.searchTitle = "Search InPayment Orders";
        this.closeLeftPane();
        this.openRightPaneWide(false);
        SessionInfo.setGridRefresh = 1; // Grid Refresh in orderID  
        SessionInfo.setExcelExport = 1;
        SessionInfo.currExcelExportFunc = this.exportStoreItemsExcel;
        await this.getCustomOrdersStatus(100); // Delivered + PickedUP,OrderPaid,OrderUnderPaid
      }
      else if (tabNumber === this.tabs.COMPLETEDORDERS) {
        this.closeRightPane();
        SessionInfo.searchTitle = "Search Completed Orders";
        this.closeLeftPane();
        this.openRightPaneWide(false);
        SessionInfo.setGridRefresh = 1; // Grid Refresh in orderID  
        SessionInfo.setExcelExport = 1;
        SessionInfo.currExcelExportFunc = this.exportStoreItemsExcel;
        await this.getCustomOrdersStatus(8); // Delivered + PickedUP,OrderPaid,OrderUnderPaid
      }
      else if (tabNumber === this.tabs.MANAGE) {
        this.closeRightPane();
        SessionInfo.setShowSave = 1; // Show Save in orderID 
        SessionInfo.setShowNew = 1;
        SessionInfo.currExcelExportFunc = this.exportPromotionsExcel;
      }
      else
        this.setState({ verticalPanes: this.vertSplitC });
    }
    else {
      if (tabNumber === 0) {
        this.setState({ selectedTopTab: tabNumber });
        SessionInfo.setGridRefresh = 2; // Hide Grid Refresh in orderID 
        SessionInfo.setExcelExport = 2;
        SessionInfo.setGridAdd = 2;
        this.currentPanel = this.tabs.PENDINGORDERS;
        this.state.RightO = false;
        SessionInfo.searchTitle = "Search Locations";
        //this.closeLeftPane();
        //this.setState({ verticalPanes: this.vertSplitC });
      }
      else if (tabNumber === this.tabs.SCHEDULER) {
        displayWarning("Scheduler Selected");
        this.setState({ selectedTopTab: tabNumber });
        this.closeRightPane();
        //await this.loadEvents(2); // Open/Pending
        SessionInfo.setGridRefresh = 1; // Grid Refresh in orderID  
        SessionInfo.setExcelExport = 1;
        SessionInfo.currExcelExportFunc = this.exportCustomOrdersExcel;
        SessionInfo.searchTitle = "Search Events";
        this.closeRightPane();
        this.closeLeftPane();
        this.setState({ verticalPanes: this.vertSplitO });
      }
      else { // SessionInfo.OrderLocationID == 0
        displayWarning("Store Location must be Selected");
        this.setState({ selectedTopTab: 0 });
      }
    }
  }
  //---- Bottom Tab -----------------------------------
  handleBottomTabSelect = async (e) => {
    this.setState({ selectedBottomTab: e.selected });
    //if (e.selected === 0) {
    //  if (this.state.StoreItems.length <= 1)
    //    await this.getStoreItems('');
    //}
    //else { //---------------- Custom Orders -------------------------------------------
    //  if (this.state.CustomOrders.length <= 1) {
    //    await this.getCustomOrders('');
    //  }
    //}     
    //else if (e.selected === 4) {
    //  if (this.state.MainStoreItems.length <= 1)
    //    await this.getProductItems('');
    //}
  }
  _exportStoreItems;
  exportStoreItemsExcel = () => {
    this._exportStoreItems.save();
  }
  _exportCustomOrders;
  exportCustomOrdersExcel = () => {
    this._exportCustomOrders.save();
  }
  _exportPromotions;
  exportPromotionsExcel = () => {
    this._exportPromotions.save();
  }
  handleChange = (event) => {
    this.setState({
      value: event.target.value
    });
  }
  blurMaskFldVal(event,mask) {
    //console.log(`blur Mask: ${this.state[event.target.name]}`);
    this.setState({ [event.target.name]: UnmaskString(this.state[event.target.name],mask) });
    //console.log(`money target: ${this.state[event.target.name + 'S']}`);
  }
  setItemStateChanged = (changed) => {
    this.setState({ itemStateHasChanged: changed });
    if (changed === true) {
      SessionInfo.setShowSave = 1; // Show Save in Header  
      if (this.clearScreen === true) {
        this.clearScreen = false;
        SessionInfo.setShowNew = 1;
      }
    } else
      SessionInfo.setShowSave = 2; // Turn Off
  }
  chgFldValMask(event,mask) {
    //console.log(`chg masked value: ${event.target.value}, mask: ${mask}`);
    this.setState({ [event.target.name]: UnmaskString(event.target.value,mask) });
    //console.log(`chg masked - name: ${event.target.name}:${event.target.value}`);
    this.setItemStateChanged(true);
  }
  chgFldVal(event) {
    this.setState({ [event.target.name]: event.target.value });
    //console.log(`${event.target.name}:${event.target.value}`);
    this.setItemStateChanged(true);
  }
  chgMoneyFldVal(event) {
    //var stateCopy = Object.assign({}, this.state[event.target.name]);
    let newVal = event.target.value.replace(/[^\d.-]/g, ''); // Matches any single single character not in the bracket [] and replaces with ''  - https://www.infobyip.com/regularexpressioncalculator.php
    //console.log("money newval: " + newVal);
    this.setState({ [event.target.name]: newVal });
    this.setItemStateChanged(true);
  }
  chgPercentStart(event) {
    this.frmt = false;
    let newVal = event.target.value.replace(/[^\d.-]/g, '');
    console.log("start newval: " + newVal);
    this.setState({ [event.target.name]: newVal });
    this.setItemStateChanged(true);
  }
  chgPercFldVal(event) {
    let newVal = event.target.value.replace(/[^\d.-]/g, '');
    console.log("perc newval: " + newVal);
    this.setState({ [event.target.name]: newVal });
    this.setItemStateChanged(true);
  }
  chgPercentFldVal(event) {
    //var stateCopy = Object.assign({}, this.state[event.target.name]);
    let newVal = event.target.value.replace(/[^\d.-]/g, '');
    console.log("percent blur newval: " + newVal);
    newVal /= 100.0;
    this.setState({ [event.target.name]: newVal });
    this.setItemStateChanged(true);
    this.frmt = true;
  }
  chgDDFldVal = (event) => {
    this.setState({ [event.target.props.name]: event.target.value.ddID });
    this.setItemStateChanged(true);
  }
  onChangeAmenities = (event) => {
    this.setState({ StoreAmenitiesVals: [...event.target.value] });
    console.log("Amenities: " + this.state.StoreAmenitiesVals);
  }
  chgCheckboxVal(event) {
    //console.log("stateCopy-" + event.target.name + " : " + JSON.stringify(this.state[event.target.name]));
    let setVal = true;
    if (this.state[event.target.name] === true)
      setVal = false;
    this.setState({ [event.target.name]: setVal });
    this.setItemStateChanged(true);
  }
  //-------------------------------

  customOrderItemsPageChange = (event) => {
    this.setState({ customOrderItemsSkip: event.page.skip, customOrderItemsTake: event.page.take });
  }
  selectionCustomOrderItemsChange = (event) => {
    event.dataItem.selected = !event.dataItem.selected;
    this.forceUpdate();
  }
  getCustomOrderItemsSort = (sort) => {
    return orderBy(this.state.CustomOrderItems, sort);
  }
  //------------------------- Select StoreItem from Main Database -------------------------------------------------------------------------
  mainStoreItemsPageChange = (event) => {
    this.setState({ mainStoreItemsSkip: event.page.skip, mainStoreItemsTake: event.page.take });
  }
  selectionStoreItemChange = (event) => {
    event.dataItem.selected = !event.dataItem.selected;
    this.forceUpdate();
  }
  showOptionsForItem = async (item) => { // Show options and Instructions for OrderItem
    console.log(`Show Options - item: ${JSON.stringify(item.dataItem)}`);
    console.log(item);
  }
  onDisplayContract = async () => {
    window.open('https://rinkeby.etherscan.io/address/' + this.state.ProjectInstanceContractAddress, "_blank") //to open new page
  }
  onDisplayAccount = async () => {
    window.open('https://rinkeby.etherscan.io/address/' + this.state.MetaMaskAccount, "_blank") //to open new page
  }
  onDisplayTx = async () => {
    if (this.state.transactionHash === '')
      displayMessage("No Transaction Specified");
    else
      window.open('https://rinkeby.etherscan.io/tx/' + this.state.transactionHash, "_blank") //to open new page
  }
  sortChange = (event) => {
    this.setState({ products: this.GetProducts(event.sort), sort: event.sort });
  }
  getProductItemsSort = (sort) => {
    return orderBy(this.state.MainStoreItems, sort);
  }

  //------------------------- End Select StoreItem ----------------------------------------------------------------------

  onAdvanceCommand = async () => {
    console.log(`Advance Status for OrderID: ${this.state.CustomOrderID}`);
    let CD = await CallOM("AdvanceStatus", 0, this.state.CustomOrderID, 0); // Fetch CustomOrders based on OrderStatus
  }
  //onVerticalLayoutChange = (event) => {
  //  console.log("vertical: " + JSON.stringify(event.newState));
  //  this.setState({ verticalPanes: event.newState });
  //}
  onHorizontalLayoutChange = (event) => {
    //console.log("horizontal: " + JSON.stringify(updatedState));
    this.setState({ horizontalPanes: event.newState });
  }
  onHorizontalSubLayoutChange = (event) => {
    console.log("horizontal: " + JSON.stringify(event.newState));
    this.setState({ horizontalSubPanes: event.newState });
  }

  render() {
    return (
      <div id="OrderManagement" className="pageMain">
        <PageSide />
        <PageHeader L1='Home' Select='2' PageTitle='Order Management' NoDelete='true' IsApp='y' ItemName='Location' />
        <div id="mainCntr">
          <Splitter style={{ height: '100%', border: 'none' }} panes={this.state.horizontalPanes} orientation={'horizontal'} onChange={this.onHorizontalLayoutChange}>
            <div id="splitterLeft" className="splitPaneContent">
              <TGrid style={{ position: 'absolute', top: 0, height: '100%' }} data={this.state.StoreLocations} selectedField="selected"
                onRowClick={this.storeLocationRowClick} resizable={true} sortable={true} pageable={false}>
                <Column field="StoreName" title="Store Name" width="140px" />
                <Column field="CityName" title="City" width="120px" />
                <Column field="Address" title="Address" width="150px" />
              </TGrid>
            </div>
            <div id="splitterRight" className="splitPaneContent">
              <TabStrip selected={this.state.selectedTopTab} onSelect={this.handleTopTabSelect} className="tabStrip">
                <TabStripTab title="Pending Orders" style={{ height: '100%', width: '100%' }}>
                  <div className="editTab">
                    <div className="editTabLeft">
                      <TGrid style={{ position: 'absolute', top: 5, height: '98%' }} data={this.state.PendingOrders} selectedField="selected"
                        onRowClick={this.orderRowClick} resizable={true} sortable={true} pageable={false}
                        sortable={{ allowUnsort: true,mode: 'multiple' }} sort={this.state.sortPendingOrders} onSortChange={(event) => { this.setState({ PendingOrders: this.getOrderSort(event.sort),sortPendingOrders: event.sort }); }}                      >
                        <Column field="CustomOrderID" title="Order #" width="80px" />
                        <Column field="CustomerID" title="Customer" width="110px" />
                        <Column field="RequestDateTime" title="Request Time" cell={TimePickerCell} width='125px' readOnly='true' />
                        <Column field="TotalPrice" title="Total" cell={MoneyCell} width='80px' />
                        <Column field="TipAmount" title="Tip" cell={MoneyCell} width='70px' />
                        <Column field="ItemCount" title="Cnt" width="50px" />
                        <Column field="OrderNotes" title="Notes" width="100px" />
                      </TGrid>
                    </div>
                  </div>
                </TabStripTab>
                <TabStripTab title="In Process" style={{ height: '100%', width: '100%' }}>
                  <div className="editTab">
                    <div className="editTabLeft">
                      <TGrid style={{ position: 'absolute', top: 5, height: '98%' }} data={this.state.CurrentOrders} selectedField="selected"
                        onRowClick={this.orderRowClick} resizable={true} sortable={true} pageable={false}
                        sortable={{ allowUnsort: true,mode: 'multiple' }} sort={this.state.sortCurrentOrders} onSortChange={(event) => { this.setState({ CurrentOrders: this.getOrderSort(event.sort),sortCurrentOrders: event.sort }); }}>
                        <Column field="CustomOrderID" title="Order #" width="80px" />
                        <Column field="CustomerID" title="Customer" width="110px" />
                        <Column field="RequestDateTime" title="Request Time" cell={TimePickerCell} width='125px' readOnly='true' />
                        <Column field="TotalPrice" title="Total" cell={MoneyCell} width='80px' />
                        <Column field="TipAmount" title="Tip" cell={MoneyCell} width='70px' />
                        <Column field="ItemCount" title="Cnt" width="50px" />
                        <Column field="OrderNotes" title="Notes" width="100px" />
                      </TGrid>
                    </div>
                  </div>
                </TabStripTab>
                <TabStripTab title="Ready To Serve">
                  <div className="editTab">
                    <div className="editTabLeft">
                      <TGrid style={{ position: 'absolute', top: 5, height: '98%' }} data={this.state.ReadyOrders} selectedField="selected"
                        onRowClick={this.orderRowClick} resizable={true} sortable={true} pageable={false}
                        sortable={{ allowUnsort: true,mode: 'multiple' }} sort={this.state.sortReadyOrders} onSortChange={(event) => { this.setState({ ReadyOrders: this.getOrderSort(event.sort),sortReadyOrders: event.sort }); }}>
                        <Column field="CustomOrderID" title="Order #" width="80px" />
                        <Column field="CustomerID" title="Customer" width="110px" />
                        <Column field="RequestDateTime" title="Request Time" cell={TimePickerCell} width='125px' readOnly='true' />
                        <Column field="TotalPrice" title="Total" cell={MoneyCell} width='80px' />
                        <Column field="TipAmount" title="Tip" cell={MoneyCell} width='70px' />
                        <Column field="ItemCount" title="Cnt" width="50px" />
                        <Column field="OrderNotes" title="Notes" width="100px" />
                      </TGrid>
                    </div>
                  </div>
                </TabStripTab>
                <TabStripTab title="Ready For Pickup">
                  <div className="editTab">
                    <div className="editTabLeft">
                      <TGrid style={{ position: 'absolute', top: 5, height: '98%' }} data={this.state.PickupOrders} selectedField="selected"
                        onRowClick={this.orderRowClick} resizable={true} sortable={true} pageable={false}
                        sortable={{ allowUnsort: true,mode: 'multiple' }} sort={this.state.sortPickupOrders} onSortChange={(event) => { this.setState({ PickupOrders: this.getOrderSort(event.sort),sortPickupOrders: event.sort }); }}                      >
                        <Column field="CustomOrderID" title="Order #" width="80px" />
                        <Column field="CustomerID" title="Customer" width="110px" />
                        <Column field="RequestDateTime" title="Request Time" cell={TimePickerCell} width='125px' readOnly='true' />
                        <Column field="TotalPrice" title="Total" cell={MoneyCell} width='80px' />
                        <Column field="TipAmount" title="Tip" cell={MoneyCell} width='70px' />
                        <Column field="ItemCount" title="Cnt" width="50px" />
                        <Column field="OrderNotes" title="Notes" width="100px" />
                      </TGrid>
                    </div>
                  </div>
                </TabStripTab>
                <TabStripTab title="In Delivery">
                  <div className="editTab">
                    <div className="editTabLeft">
                      <TGrid style={{ position: 'absolute', top: 5, height: '98%' }} data={this.state.DeliveryOrders} selectedField="selected"
                        onRowClick={this.orderRowClick} resizable={true} pageable={false}
                        sortable={{ allowUnsort: true,mode: 'multiple' }} sort={this.state.sortDeliveryOrders} onSortChange={(event) => { this.setState({ DeliveryOrders: this.getOrderSort(event.sort),sortDeliveryOrders: event.sort }); }}                      >
                        <Column field="CustomOrderID" title="Order #" width="80px" />
                        <Column field="CustomerID" title="Customer" width="110px" />
                        <Column field="RequestDateTime" title="Request Time" cell={TimePickerCell} width='125px' readOnly='true' />
                        <Column field="TotalPrice" title="Total" cell={MoneyCell} width='80px' />
                        <Column field="TipAmount" title="Tip" cell={MoneyCell} width='70px' />
                        <Column field="ItemCount" title="Cnt" width="50px" />
                        <Column field="OrderNotes" title="Notes" width="100px" />
                      </TGrid>
                    </div>
                  </div>
                </TabStripTab>
                <TabStripTab title="In Payment">
                  <div className="editTab">
                    <div className="editTabLeft">
                      <TGrid style={{ position: 'absolute', top: 5, height: '98%' }} data={this.state.InPaymentOrders} selectedField="selected"
                        onRowClick={this.orderRowClick} resizable={true} pageable={false}
                        sortable={{ allowUnsort: true,mode: 'multiple' }} sort={this.state.sortInPaymentOrders} onSortChange={(event) => { this.setState({ InPaymentOrders: this.getOrderSort(event.sort),sortInPaymentOrders: event.sort }); }}                      >
                        <Column field="CustomOrderID" title="Order #" width="80px" />
                        <Column field="CustomerID" title="Customer" width="110px" />
                        <Column field="RequestDateTime" title="Request Time" cell={TimePickerCell} width='125px' readOnly='true' />
                        <Column field="TotalPrice" title="Total" cell={MoneyCell} width='80px' />
                        <Column field="TipAmount" title="Tip" cell={MoneyCell} width='70px' />
                        <Column field="ItemCount" title="Cnt" width="50px" />
                        <Column field="OrderNotes" title="Notes" width="100px" />
                      </TGrid>
                    </div>
                  </div>
                </TabStripTab>
                <TabStripTab title="Completed Orders">
                  <div className="editTab">
                    <div className="editTabLeft">
                      <TGrid style={{ position: 'absolute', top: 5, height: '98%' }} data={this.state.CompletedOrders} selectedField="selected"
                        onRowClick={this.orderRowClick} resizable={true} pageable={false}
                        sortable={{ allowUnsort: true,mode: 'multiple' }} sort={this.state.sortCompletedOrders} onSortChange={(event) => { this.setState({ CompletedOrders: this.getOrderSort(event.sort),sortCompletedOrders: event.sort }); }}                      >
                        <Column field="CustomOrderID" title="Order #" width="80px" />
                        <Column field="CustomerID" title="Customer" width="110px" />
                        <Column field="RequestDateTime" title="Request Time" cell={TimePickerCell} width='125px' readOnly='true' />
                        <Column field="TotalPrice" title="Total" cell={MoneyCell} width='80px' />
                        <Column field="TipAmount" title="Tip" cell={MoneyCell} width='70px' />
                        <Column field="ItemCount" title="Cnt" width="50px" />
                        <Column field="OrderNotes" title="Notes" width="100px" />
                      </TGrid>
                    </div>
                  </div>
                </TabStripTab>
                <TabStripTab title="Management">
                  <div className="editTab">
                    <div className="editTabTop">
                      <br />
                      <div className="editTabLeftNarrow">
                        <br />
                        <div id="div1" className="editInside">
                          <div className="editField">
                            <span className="editFieldLabel">Store Name</span>
                            <input value={this.state.StoreName} name="StoreName" onChange={evt => this.chgFldVal(evt)} className="editInput" />
                          </div>
                          <div className="editField">
                            <span className="editFieldLabel">Description</span>
                            <input value={this.state.Description} name="Description" onChange={evt => this.chgFldVal(evt)} className="editInput" />
                          </div>
                          <div className="editField">
                            <span className="editFieldLabel">Location Name</span>
                            <input value={this.state.LocationName} name="LocationName" onChange={evt => this.chgFldVal(evt)} className="editInput" />
                          </div>
                          <div className="editField">
                            <span className="editFieldLabel">Store Manager</span>
                            <div className="editDDPos">
                              <DropDownList data={this.state.Contacts} textField="ddName" dataItemKey="ddID" value={this.state.Contacts.find(c => c.ddID === this.state.ContactID)} name="ContactID" onChange={evt => this.chgDDFldVal(evt)} className="editInputDD" />  {/* defaultItem={this.contactName} */}
                            </div>
                          </div>
                          <br />
                          <div className="editField">
                            <span className="editFieldLabel">Sub-Location</span>
                            <div className="editDDPos">
                              <DropDownList data={this.state.Sublocations} textField="ddName" dataItemKey="ddID" value={this.state.Sublocations.find(c => c.ddID === this.state.SublocationID)} name="SublocationID" onChange={evt => this.chgDDFldVal(evt)} className="editInputDD" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="editTabMiddle">
                        <div id="div1" className="editInside">
                          <h4>Control Values</h4>
                          <div className="editField">
                            <span className="editFieldLabel">Store Group</span>
                            <div className="editDDPos">
                              <DropDownList data={this.state.StoreGroups} textField="ddName" dataItemKey="ddID" value={this.state.StoreGroups.find(c => c.ddID === this.state.StoreGroupID)} name="StoreGroupID" onChange={evt => this.chgDDFldVal(evt)} className="editInputDD" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="editTabRightNarrow">
                        <div id="div1" className="editInside">
                          <h4>Taxes</h4>
                          <div id="div2" className="editField">
                            <span className="editFieldLabel">Outstanding Orders</span>
                            <NumericTextBox format="p1" name="TaxRate" value={this.state.TaxRate} min={0.0} max={0.3} step={0.01} onChange={evt => this.chgFldVal(evt)} width={100} className="NumTxtExtShortInput" />
                          </div>
                          <div id="div2" className="editField">
                            <span className="editFieldLabel">Average Time Per Order</span>
                            <NumericTextBox format="p1" name="TaxRate2" value={this.state.TaxRate2} min={0.0} max={0.3} step={0.01} onChange={evt => this.chgFldVal(evt)} width={100} className="NumTxtExtShortInput" />
                          </div>
                          <div id="div2" className="editField">
                            <span className="editFieldLabel">Current Wait Time</span>
                            <NumericTextBox format="p1" name="TaxRate3" value={this.state.TaxRate3} min={0.0} max={0.3} step={0.01} onChange={evt => this.chgFldVal(evt)} width={100} className="NumTxtExtShortInput" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </TabStripTab>
              </TabStrip>
            </div>
            {/* Side Pane */}
            <div id="splitterRight" className="splitPaneContent">
              <Splitter style={{ height: '100%', border: 'none' }} panes={this.state.verticalPanes} orientation={'vertical'} onChange={this.onVerticalLayoutChange}>
                <div className="editPane">  {/* Receipt Details */}
                  <div className="editTabLeft">
                    <div id="div1" className="editInside">
                      <h4>Receipt Details</h4>
                      <div id="div2" className="editField">
                        <span className="editFieldLabel">Customer</span>
                        <input value={this.state.CustomerID} name="CustomerID" onChange={evt => this.chgFldVal(evt)} className="editInput" readOnly="{true}" />
                      </div>
                      <div id="div2" className="editField">
                        <span className="editFieldLabel">Receipt #</span>
                        <input value={this.state.CustomOrderID} name="CustomOrderID" onChange={evt => this.chgFldVal(evt)} className="editInputMedium" readOnly="{true}" />
                      </div>
                      <div id="div2" className="editField">
                        <span className="editFieldLabel">Order Status</span>
                        <input value={this.state.CustomOrderStatus} name="CustomOrderStatus" onChange={evt => this.chgFldVal(evt)} className="editInputMedium" readOnly="{true}" />
                      </div>
                      <div id="div2" className="editField">
                        <span className="editFieldLabel">Order Type</span>
                        <input value={this.state.CustomOrderType} name="CustomOrderType" onChange={evt => this.chgFldVal(evt)} className="editInputMedium" readOnly="{true}" />
                      </div>
                      <div id="div2" className="editField">
                        <span className="editFieldLabel">Order Date</span>
                        <div className="editDDPos">
                          <DateTimePicker value={new Date(this.state.OrderCreationDate)} name="OrderCreationDate" onChange={evt => this.chgFldVal(evt)} className="editDateInput" readOnly="{true}" />
                          {/*<DateTimePicker value={this.state.OrderCreationDate < new Date(2000, 1, 1) ? new Date(2000, 1, 1) : new Intl.DateTimeFormat("en-CA", { dateStyle: "long", timeStyle: "short" }).format(this.state.OrderCreationDate)} name="OrderCreationDate" onChange={evt => this.chgFldVal(evt)} className="editDateInput" readOnly="{true}" />*/}
                        </div>
                      </div>
                      <div id="div2" className="editField">
                        <span className="editFieldLabel">Order Paid Date</span>
                        <div className="editDDPos">
                          <DateTimePicker value={new Date(this.state.OrderPaidDate)} name="OrderPaidDate" onChange={evt => this.chgFldVal(evt)} className="editDateInput" readOnly="{true}" />
                        </div>
                      </div>
                      <div className="editField">
                        <span className="editFieldLabel">Payment Method</span>
                        <input value={this.state.PaymentMethodTypeID} name="PaymentMethodTypeID" onChange={evt => this.chgFldVal(evt)} className="editInputMedium" readOnly="{true}" />
                      </div>
                      <div id='div2' className='editField'>
                        <span className='editFieldLabel'>Comments</span>
                        <input value={this.state.OrderNotes} name='OrderNotes' onChange={evt => this.chgFldVal(evt)} className='editInput' readOnly="{true}" />
                      </div>
                      <div id='div2' className='editField'>
                        <span className='editFieldLabel'>Paid Comment</span>
                        <input value={this.state.PaidComment} name='PaidComment' onChange={evt => this.chgFldVal(evt)} className='editInput' readOnly="{true}" />
                      </div>
                      {this.state.selectedTopTab >= 10 ?
                        <div>
                          <h4>Delivery</h4>
                          <div id='div2' className='editField'>
                            <span className='editFieldLabel'>Address</span>
                            <textarea value={this.state.Address} name='Address' onChange={evt => this.chgFldVal(evt)} className='editTAInput' />
                          </div>
                          <br />
                          <br />
                          <div id='div2' className='editField'>
                            <span className='editFieldLabel'>Phone Number</span>
                            <MaskedTextBox value={MaskString(this.state.PhoneNumber,"(###) ###-####")} name='PhoneNumber' onChange={evt => this.chgFldValMask(evt,"(###) ###-####")} mask="(999) 000-0000" className='editPhoneInput' />
                          </div>
                        </div> : null}
                      <br />

                      <div className="editField">
                        <Button icon="arrow-seek-right" color="primary" onClick={this.onAdvanceCommand}>Advance Status</Button>
                      </div>
                      <br />
                    </div>
                  </div>
                  <div className="editTabRight">
                    <div id="div1" className="editInside">
                      <h4>Receipt Totals</h4>
                      <div id="div2" className="editField">
                        <span className="editFieldLabel">Total Price</span>
                        <input value={this.moneyFormat(this.state.TotalPrice)} name="TotalPrice" onChange={evt => this.chgMoneyFldVal(evt)} className="editMoneyInput" readOnly="{true}" />
                      </div>
                      {(SessionInfo.AppCfg & ENAppCnfg.HasDiscounts) > 0 ?
                        <div id="div2" className="editField">
                          <span className="editFieldLabel">Discount Amount</span>
                          <input value={this.moneyFormat(this.state.DiscountAmount)} name="DiscountAmount" onChange={evt => this.chgMoneyFldVal(evt)} className="editMoneyInput" readOnly="{true}" />
                        </div> : null}
                      {(SessionInfo.AppCfg & ENAppCnfg.HasCredit) > 0 ?
                        <div className="editField">
                          <span className="editFieldLabel">Credit Amount Used</span>
                          <input value={this.moneyFormat(this.state.CreditAmountUsed)} name="CreditAmountUsed" onChange={evt => this.chgMoneyFldVal(evt)} className="editMoneyInput" readOnly="{true}" />
                        </div> : null}
                      {(SessionInfo.AppCfg & ENAppCnfg.HasReturns) > 0 ?
                        <div className="editField">
                          <span className="editFieldLabel">Refund Amount</span>
                          <input value={this.moneyFormat(this.state.RefundAmount)} name="RefundAmount" onChange={evt => this.chgMoneyFldVal(evt)} className="editMoneyInput" readOnly="{true}" />
                        </div> : null}
                      {(SessionInfo.AppCfg & ENAppCnfg.HasTax1) > 0 ?
                        <div className="editField">
                          <span className="editFieldLabel">Tax Amount</span>
                          <input value={this.moneyFormat(this.state.TotalTaxAmount)} name="TotalTaxAmount" onChange={evt => this.chgMoneyFldVal(evt)} className="editMoneyInput" readOnly="{true}" />
                        </div> : null}
                      {(SessionInfo.AppCfg & ENAppCnfg.HasTax2) > 0 ?
                        <div className="editField">
                          <span className="editFieldLabel">Tax2 Amount</span>
                          <input value={this.moneyFormat(this.state.TotalTax2Amount)} name="TotalTax2Amount" onChange={evt => this.chgMoneyFldVal(evt)} className="editMoneyInput" readOnly="{true}" />
                        </div> : null}
                      {(SessionInfo.AppCfg & ENAppCnfg.HasTax3) > 0 ?
                        <div className="editField">
                          <span className="editFieldLabel">Tax3 Amount</span>
                          <input value={this.moneyFormat(this.state.TotalTax2Amount)} name="TotalTax3Amount" onChange={evt => this.chgMoneyFldVal(evt)} className="editMoneyInput" readOnly="{true}" />
                        </div> : null}
                      <div id="div2" className="editField">
                        <span className="editFieldLabel">Total Amount</span>
                        <input value={this.moneyFormat(this.state.TotalAmount)} name="TotalAmount" onChange={evt => this.chgMoneyFldVal(evt)} className="editMoneyInput" readOnly="{true}" />
                      </div>

                      <h4>Receipt Totals</h4>
                      <div id="div2" className="editField">
                        <span className="editFieldLabel">Amount Paid</span>
                        <input value={this.moneyFormat(this.state.AmountPaid)} name="AmountPaid" onChange={evt => this.chgMoneyFldVal(evt)} className="editMoneyInput" readOnly="{true}" />
                      </div>
                      <div className="editField">
                        <span className="editFieldLabel">Tip Amount</span>
                        <input value={this.moneyFormat(this.state.TipAmount)} name="TipAmount" onChange={evt => this.chgMoneyFldVal(evt)} className="editMoneyInput" readOnly="{true}" />
                      </div>
                      <div id="div2" className="editField">
                        <span className="editFieldLabel">Total Paid</span>
                        <input value={this.moneyFormat(this.state.AmountPaidWithTip)} name="AmountPaidWithTip" onChange={evt => this.chgMoneyFldVal(evt)} className="editMoneyInput" readOnly="{true}" />
                      </div>
                    </div>
                  </div> {/* End of Receipt */}
                </div> 
                <div id="splitterBottom">
                  <div id="splitterRight" className="splitPaneContent">
                    <TGrid style={{ position: 'absolute', top: 0, height: '100%' }} data={this.state.CustomOrderItems.slice(this.state.customOrderItemsSkip, this.state.customOrderItemsTake + this.state.customOrderItemsSkip)} selectedField="selected" sortable={{ allowUnsort: true, mode: 'multiple' }}
                      skip={this.state.customOrderItemsSkip} take={this.state.customOrderItemsTake} total={this.state.CustomOrderItems.length} onPageChange={this.selectionCustomOrderItemsChange}
                      onRowClick={(item) => { this.showOptionsForItem(item) }} selectedField="selected" resizable={true} sort={this.state.sortCustomOrderItems} onSortChange={(event) => { this.setState({ CustomOrderItems: this.getCustomOrderItemsSort(event.sort), customOrderItemsSort: event.sort }); }}
                      pageable={{ info: true, type: 'numeric', pageSizes: true, previousNext: true }}
                      pageSize={18}>
                      <Column field="selected" width="0px" headerSelectionValue={this.state.CustomOrderItems.findIndex(dataItem => dataItem.selected === false) === -1} />
                      <Column field="StoreItemID" title="Item Name" width='200px' />
                      <Column field="Quantity" title="Qty" width='60px' />
                      <Column field="OptionCount" title="Options" width='80px' />
                      <Column field="TotalPrice" title="Price" cell={MoneyCell} width='120px' />
                    </TGrid>
                  </div>
                </div>
              </Splitter>
            </div>
          </Splitter>
          <Popup offset={this.helpListPopupOffset} show={this.state.showHelpListPopup} popupClass={'popupHelp'} >
            <div id="helpBox">
              <div className="helpList">
                <ListView data={this.state.HelpPages} item={this.HelpPageFormat} header={this.HelpPageHeader} footer={this.HelpPageFooter} />
              </div>
              <br />
            </div>
          </Popup>
          <Popup offset={this.helpDetailOffset} show={this.state.showHelpDetail} popupClass={'popupHelpDetail'} onClick={this.closeHelpTime}>
            <div className="frameInfo" id="notifyBoxPU" dangerouslySetInnerHTML={{ __html: this.state.helpDetail }} onClick={this.closeHelpTime}></div>
          </Popup>
        </div >
        {SessionInfo.displayFooter === true ?
          <PageFooter L1='Home' IsApp='y' /> : null}
        <Popup offset={this.popupOffset} show={this.state.showPopup} popupClass={'popup-content'} >  {/*anchor={this.anchor} */}
          <div id="setTime" className="createPopUP">
            <div id="div2" className="editField">
              <span className="editFieldLabel">Customer Name</span>
              <span className="editInput">{this.state.CustomerName}</span>
            </div>
            <div id="div2" className="editField">
              <span className="editFieldLabel">Scheduled Date</span>
              <div className="editDDPos">
                <DateTimePicker value={new Date(this.state.ScheduledPrepDateTime)} name="ScheduledPrepDateTime" onChange={evt => this.chgFldVal(evt)} className="editDateInput" readOnly="{true}" />
                {/*<DateTimePicker value={this.state.OrderCreationDate < new Date(2000, 1, 1) ? new Date(2000, 1, 1) : new Intl.DateTimeFormat("en-CA", { dateStyle: "long", timeStyle: "short" }).format(this.state.OrderCreationDate)} name="OrderCreationDate" onChange={evt => this.chgFldVal(evt)} className="editDateInput" readOnly="{true}" />*/}
              </div>
            </div>
            <div id="div2" className="editField">
              <span className="editFieldLabel">Adjust Time</span>
              <img src={require("./images/Arrow3Left.png")} alt="" id="MinuteMinus" title="Minus Minutes" className="functionImage" onClick={this.ClickMinus} />
              <img src={require("./images/Arrow3Right.png")} alt="" id="MinutePlus" title="Plus Minutes" className="functionImage" onClick={this.ClickPlus} />
            </div>
          </div>
        </Popup>
      </div >
    );
  }
}

export default withRouter(OrderManagement);