import React from 'react';              
import { SessionInfo } from './App';    
import PageHeader from './PageHeader.js';
import PageFooter from './PageFooter.js';
import PageSide from './PageSide.js';
import { withRouter } from './withRouter';
import { Input } from '@progress/kendo-react-inputs';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Grid as TGrid,GridColumn as Column } from '@progress/kendo-react-grid';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { FloatingLabel } from '@progress/kendo-react-labels'
import { Popup } from '@progress/kendo-react-popup';
import { TreeView } from '@progress/kendo-react-treeview';
import { ListView, ListViewHeader, ListViewFooter } from '@progress/kendo-react-listview';
import { TabStrip, TabStripTab, Splitter } from '@progress/kendo-react-layout';
import { Editor,EditorTools,EditorUtils } from "@progress/kendo-react-editor";
import { Button } from '@progress/kendo-react-buttons';
import { CallOM,OMTrace,displayMessage,displayWarning,displayError,GetDropdownData,getHelpTopics,getHelpDetail, GetFileExtension, CreateKeys, LogOffResetMsg, shortDesc, TYP, CTYP } from './CommonCode.js'; 
import { ReactComponent as ButtonSearch } from "./images/search.svg";
import { ReactComponent as ButtonClear } from "./images/button-clear.svg";

const {
  Bold,
  Italic,
  Underline,
  Strikethrough,
  Subscript,
  Superscript,
  ForeColor,
  BackColor,
  CleanFormatting,
  AlignLeft,
  AlignCenter,
  AlignRight,
  AlignJustify,
  Indent,
  Outdent,
  OrderedList,
  UnorderedList,
  NumberedList,
  BulletedList,
  Undo,
  Redo,
  FontSize,
  FontName,
  FormatBlock,
  Link,
  Unlink,
  InsertImage,
  ViewHtml,
  InsertTable,
  InsertFile,
  SelectAll,
  Print,
  Pdf,
  AddRowBefore,
  AddRowAfter,
  AddColumnBefore,
  AddColumnAfter,
  DeleteRow,
  DeleteColumn,
  DeleteTable,
  MergeCells,
  SplitCell,
} = EditorTools;

const HelpPagesOID = 178428;
const HelpPagesO = [{ n: 'HelpPageID', t: 24, p: 0 }, { n: 'ObjectID', t: 24, p: 0 }, { n: 'ContextObjectID', t: 24, p: 0 }, { n: 'InstanceID', t: 24, p: 0 }, { n: 'HelpTopic', t: 21, p: 0 }, { n: 'SearchTags', t: 21, p: 0 }, { n: 'OntologyDescription', t: 21, p: 0 }, { n: 'ControlFlags', t: 27, p: 0 }, { n: 'SortOrder', t: 24, p: 0 }, { n: 'HelpTitle', t: 21, p: 0 }, { n: 'HelpWidth', t: 24, p: 0 }, { n: 'HelpHeight', t: 24, p: 0 }, { n: 'Length', t: 24, p: 0 }, { n: 'HelpContent', t: 29, p: 0 }];
//       Object Value Definition For HelpPages
const HPV = { 'HelpPageID': 0, 'ObjectID': 0, 'ContextObjectID': 0, 'InstanceID': 0, 'HelpTopic': '', 'SearchTags': '', 'OntologyDescription': '', 'ControlFlags': 0, 'SortOrder': 0, 'HelpTitle': '', 'HelpWidth': 0, 'HelpHeight': 0, 'Length': 0, 'HelpContent': '' };
//       Object Index Definition For HelpPages
const HPX = { 'HelpPageID': 0, 'ObjectID': 1, 'ContextObjectID': 2, 'InstanceID': 3, 'HelpTopic': 4, 'SearchTags': 5, 'OntologyDescription': 6, 'ControlFlags': 7, 'SortOrder': 8, 'HelpTitle': 9, 'HelpWidth': 10, 'HelpHeight': 11, 'Length': 12, 'HelpContent': 13 };
const HelpTypesDD = [{ ddName: 'Common Help', ddID: 0 }, { ddName: 'General Help', ddID: 1 }, { ddName: 'System Help', ddID: 2 }, { ddName: 'Developer Help', ddID: 3 }, { ddName: 'Menu Help', ddID: 11 }, { ddName: 'Manager Help', ddID: 12 }];

var editorHTMLContent = '';
const editorRef = React.createRef();
const textareaRef = React.createRef();

class AboutPage extends React.Component {
  constructor(props) {
    super(props);
    if (!SessionInfo.session || SessionInfo.session === '')
      this.props.navigate("/AppMain");
    SessionInfo.currentPage = "AboutPage";
    if (SessionInfo.AboutPage) { // Data saved for this session
      this.state = SessionInfo.AboutPage;
      this.loadExistingPage = true;
    }
    else {
      this.loadExistingPage = false;
    }
  }
  state = {
    searchValue: '',
    MessagesValue: '',
    currentTime: '',
    MessagesValue: '',
    currentTime: '',
    showMenu: false,
    showDetail: false,
    helpDetail: '',
    HelpPages: [],
    lastSearchValue: '',
    clearSetAll: false,
    selectedTopTab: 0,
    HelpTypes: [],
    HelpObjects: [],

    HelpPagesSkip: 0,
    HelpPagesTake: 8,
    saveHelpPages: [],
    HelpPagesFilter: { logic: "and",filters: [] },
    HelpPagesSort: [],
    HelpPagesTabNumber: 0,
    //------ Integer Values - HelpPages ------
    HelpPageID: 0,
    ObjectID: 0,
    ContextObjectID: 0,
    InstanceID: 0,
    ControlFlags: 0,
    SortOrder: 0,
    HelpWidth: 0,
    HelpHeight: 0,
    Length: 0,

    //------ String Values - HelpPages ------
    HelpTopic: '',
    SearchTags: '',
    OntologyDescription: '',
    HelpTitle: '',
    HelpContent: '',
    itemStateHasChanged: false,
  };
  refreshCount = 6;
  detailOffset = { left: 5,top: 10 };
  refreshCount = 6;
  currentImageID = 0;
  htmlText = "";
  loadExistingPage = false;
  clearScreen = false; 

  async componentDidMount() {
    if (!SessionInfo.session || SessionInfo.session === '')
      this.props.navigate("/AppMain");
    else {
      SessionInfo.currentPage = "AboutPage";
      let windowHt = window.innerHeight;
      let takeLinesD = (windowHt - 200) / 54.0;
      let takeLines = parseInt(takeLinesD);
      this.setState({ HelpPagesTake: takeLines });
      await this.getHelpTopics(this.state.searchValue);
      this.setState({ StoreName: SessionInfo.StoreName });
      this.setState({ TableName: SessionInfo.TableName });
      this.setState({ SeatName: SessionInfo.SeatName });
      this.setState({ SeatCount: SessionInfo.SeatCount });
      this.setState({ ItemCount: SessionInfo.ItemCount });
      SessionInfo.searchFunc = this.searchHelp;
      SessionInfo.currSaveFunc = this.saveHelpItem;
      SessionInfo.currClearFunc = this.clearHelpItem;
      SessionInfo.currDeleteFunc = this.deleteRecord;
      SessionInfo.tabSelectFunc = this.selectTab;
      SessionInfo.currRefreshGridFunc = undefined;
      SessionInfo.currAddGridRowFunc = undefined;
      SessionInfo.currExcelExportFunc = this.exportExcel;
      SessionInfo.currShowHelp = undefined;
      if (this.state.HelpTypes.length <= 1) {
        let CD = await GetDropdownData(555164);
        console.log(`Help Types: ${JSON.stringify(CD.d)}`);
        this.setState({ HelpTypes: CD.d });
      }
      if (this.state.HelpObjects.length <= 1) {
        let CD = await CallOM("GetHelpObjects",0,0,0);
        if (CD.d) {
          console.log(`Help Objects: ${JSON.stringify(CD.d)}`);
          this.setState({ HelpObjects: CD.d });
        }
      }
      this.setState({ HelpTypes: HelpTypesDD });
      this.selectTab(this.state.selectedTopTab);
      //await this.getHelpTopics(this.state.searchValue);
    }
  }
  componentWillUnmount() {
    if (SessionInfo.session !== '')
      SessionInfo.AboutPage = this.state;
  }
  currentTab = 0;
  tabs = {
    ManagerHelp: 0,
    MenuHelp: 1,
    Editor: 2,
    HelpList: 3,
  }
  getHelpTopics = async (search) => {
    try {
      if (!search)
        search = '';
      let helpType = 0;
      if (this.currentTab == this.tabs.ManagerHelp)
        helpType = 12; // Manager App Help
      else if (this.currentTab == this.tabs.MenuHelp)
        helpType = 11; // Menu App Help
      else if (this.currentTab == this.tabs.HelpList)
        helpType = 0;
      console.log(`Get Help Pages - type: ${helpType}, search: ${search}`);
      let CD = await CallOM("GetHelpPages",helpType,0,CTYP.STR,search);
      //console.log(`Get Help Return - o: ${JSON.stringify(CD)}`);
      if (!CD || CD.x.o === 0) {
        await LogOffResetMsg(`Web Session Has Failed in ${SessionInfo.currentPage} - Session: ${CD.x.s}`,1);
        this.props.navigate("/Login");
      }
      else if (CD.x.o < 9500) {
        if (CD.d) {
          try {
            //console.log("Help Items: " + JSON.stringify(CD));
            let HelpPages = CD.d.rows.map(dataItem => Object.assign({ isFavorite: false,selected: false },dataItem));
            //console.log("Help Items: " + JSON.stringify(HelpPages));
            this.setState({ HelpPages });
          } catch (error) {
            await OMTrace(2,`Failure in getHelpTopics - Error: ${error}`);
          }
        }
      }
    } catch (error) {
      await OMTrace(2,`Failure (2) in getHelpTopics - Error: ${error}`);
    }
  }
  //--------------------------------------------------------------------------------------------------------------------------------------------------------------------
  onHelpRowClick = async (event) => {
    const current = this.state.HelpPages.findIndex(dataItem => dataItem === event.dataItem);
    console.log("HelpPages Click - current: " + current);
    await this.onHelpItemClick(this.state.HelpPages[current]);
  }
  onHelpItemClick = async (item) => {
    console.log(`Help Item: ${JSON.stringify(item)}`);
    let CD = await CallOM("GetHelpPageRecord",0,0,1,item.HelpPageID);
    console.log(`Get HelpPage Return: ${JSON.stringify(CD.d)}`);
    if (CD.x.o === 0) {
      await LogOffResetMsg(`Web Session Has Failed in ${SessionInfo.currentPage} - Session: ${CD.x.s}`,1);
      this.props.navigate("/Login");
    }
    else if (CD.x.o < 9500) {
      if (CD && CD.d) {
        let row = CD.d;
        HelpPagesO.forEach((elem) => {
          if (elem.n in row) {
            //console.log(`Set ${elem.n}: ${row[elem.n]}`);
            let sobj = {};
            try {
              if (elem.t === 34 || elem.t === 35) { // Date, DateTime
                //sobj[elem.n] = new Date(row[elem.n]); // For use with DateTime Control
                sobj[elem.n] = row[elem.n];
                HPV[elem.n] = new Date(row[elem.n]);
              } else if (elem.t === 21 || elem.t === 195 || elem.t === 201) { // String, FK-Translated, Enum
                sobj[elem.n] = row[elem.n];
                let sobjS = {};
                sobjS[elem.n + 'S'] = row[elem.n];
                console.log(`Set String Field: ${elem.n + 'S'}, Value: ${sobjS[elem.n + 'S']}`);
                this.setState(sobjS); // Set the String shadow var
                HPV[elem.n] = row[elem.n];
              } else if (elem.t === 28) { // Money
                sobj[elem.n] = row[elem.n];
                let sobjS = {};
                sobjS[elem.n + 'S'] = this.moneyFormat(row[elem.n]);
                //console.log(`Set Money Field: ${elem.n + 'S'}, Value: ${ sobjS[elem.n + 'S']}`);
                this.setState(sobjS); // Set the String shadow var
                HPV[elem.n] = row[elem.n];
              } else if (elem.t === 41) { // Media - Image or Document
                sobj[elem.n] = row[elem.n];
                HPV[elem.n] = row[elem.n];
                if (elem.n === 'ImageID')
                  this.currentImageID = row[elem.n];
              } else {
                sobj[elem.n] = row[elem.n];
                HPV[elem.n] = row[elem.n];
              }
              //console.log(`sobj ${JSON.stringify(sobj)}, t: ${elem.t}, val: ${HPV[elem.n]}`);
              this.setState(sobj);
              // Add item specific code here
              //if (elem.n === 'ImageID')
              //  ImageID = row[elem.n];
            }
            catch (error) {
              console.log(`Set HelpPagesO Field: ${elem.n}, Error: ${error.message}`);
            }
          }
        });
      }
    }
    this.setState({ ConextObjectID: 11 });
    //console.log(`Help Content: ${HPV.HelpContent}`);
    editorHTMLContent = HPV.HelpContent;
    //  this.setState({ helpDetail });
    //  this.setState({ showDetail: true });}
    this.selectTab(2);
  }
  saveHelpItem = async () => {
    await this.getHtmlText();
    //await this.setState({ HelpContent: this.htmlText });
    let propState = {};
    HelpPagesO.forEach((elem) => {
      if (elem.n in this.state) {
        propState[elem.n] = this.state[elem.n];
      }
    });
    console.log(`Save (${this.state.HelpPageID}) HelpO: ${JSON.stringify(propState)}`);
    await CallOM("UpdateHelpRecord",0,this.state.HelpPageID,CTYP.DICTNO,propState);
    let CD = await CallOM("GetHelpContent",0,this.state.HelpPageID);
    let content = CD.d;
    this.setState({ itemStateHasChanged: false });
    this.selectTab(this.currentTab);
    let ix = this.state.HelpPages.findIndex(c => c.HelpPageID === this.state.HelpPageID);
    if (ix >= 0) { // Update
      this.state.HelpPages[ix].HelpContent = content;
    }
    else { // New
      this.getHelpTopics(this.state.searchValue);
    }
    this.setState({ HelpContent: content });
  }
  clearHelpItem = async () => {
    HelpPagesO.forEach((elem) => {
      let sobj = {};
      try {
        if (elem.t === 34 || elem.t === 35)
          sobj[elem.n] = new Date();
        else if (elem.t === 21 || elem.t === 22)
          sobj[elem.n] = '';
        else
          sobj[elem.n] = 0;
        this.setState(sobj);
      } catch (error) {
        console.log(`Clear Help Field: ${elem.n} Error: ${error.message}`);
      }
    });
    let helpType = 12;
    this.setState({ ContextObjectID: helpType });
    await this.setState({ HelpContent: "" });
    editorHTMLContent = "";
    //console.log(`HelpContent: ${this.state.HelpContent}`);
    if (textareaRef.current) {
      textareaRef.current.value = "";
    }            
    this.showHtml();
    console.log(`Help cleared`);
  }
  closePopup = async (event) => {
    this.setState({ showDetail: false });
  }
  HelpPagePageChange = async (event) => {

  }
  //------------------------- Search ---------------------------------------------------------------------------------------------------------------------------------------------
  searchButton = async (event) => {
    console.log('search Button: ' + this.state.searchValue);
    event.preventDefault();
    event.stopPropagation();
    //await this.searchCurrent(this.state.searchValue);
    await this.getHelpTopics(this.state.searchValue);
  }
  searchHelp = async (searchStr) => { // Original Search Value event handler
    //console.log(`searchValue: ${this.state.searchValue}, targetname: ${evt.name}, event.value: ${evt.target.value}, ${newValue}`);
    if (!(searchStr.length > 0 && (searchStr[0] === '*' || searchStr[0] === '`'))) {
      if (searchStr.length >= 2 || this.state.lastSearchValue.length > searchStr.length) {
        this.setState({ lastSearchValue: searchStr });
        await this.getHelpTopics(searchStr);
      }
      else {
        this.setState({ lastSearchValue: searchStr });
      }
    }
    this.setState({ searchValue: searchStr }); // Note - moved save to State to last operation
  }
  updateSearchValue = async (evt) => { // Original Search Value event handler
    let newValue = evt.value;
    //console.log(`searchValue: ${this.state.searchValue}, targetname: ${evt.name}, event.value: ${evt.target.value}, ${newValue}`);
    if (!(newValue.length > 0 && (newValue[0] === '*' || newValue[0] === '`'))) {
      if (newValue.length >= 2 || this.state.lastSearchValue.length > newValue.length) {
        this.setState({ lastSearchValue: newValue });
        await this.getHelpTopics(newValue);
      }
      else {
        this.setState({ lastSearchValue: newValue });
      }
    }
    this.setState({ searchValue: newValue }); // Note - moved save to State to last operation
  }
  async chgSrchVal(event) {
    this.setState({ searchValue: event.target.value });
    this.setItemStateChanged(true);
    console.log(`searchValue: ${this.state.searchValue}, targetname: ${event.target.name}, event.value: ${event.target.value}`);
    this.setState({ [event.target.name]: event.target.value });
    this.setItemStateChanged(true);
    if (!(event.target.value.length > 0 && (event.target.value[0] === '*' || event.target.value[0] === '`'))) {
      if (event.target.value.length >= 2 || this.state.lastSearchValue.length > event.target.value.length) {
        this.setState({ lastSearchValue: event.target.value });
        await this.getHelpTopics(event.target.value);
      }
      else {
        this.setState({ lastSearchValue: event.value });
      }
    }
  }
  async chgTAFldVal(event) {
    console.log(`searchValue: ${this.state.searchValue}, targetname: ${event.target.name}, event.value: ${event.value}`);
    this.setState({ [event.target.name]: event.value });
    this.setItemStateChanged(true);
    if (!(event.value.length > 0 && (event.value[0] === '*' || event.value[0] === '`'))) {
      if (event.value.length >= 2 || this.state.lastSearchValue.length > event.value.length) {
        let newValue = event.value;
        this.setState({ lastSearchValue: newValue });
        await this.getHelpTopics(newValue);
      }
      else {
        this.setState({ lastSearchValue: event.value });
      }
    }
  }
  itemSearch = (srchStr) => { // Search for Match
    console.log(`ItemSearch - search: ${srchStr}`);
    //console.log(`saveStoreItems: ${JSON.stringify(SessionInfo.saveStoreItems)}`);
    this.closePopups(3);
    if (srchStr.length > 0) {
      let StoreItems = [];
      let cnt = 0;
      let srch = new RegExp(srchStr,"i");
      let cats = [];
      this.state.Categories.forEach(cat => {
        if (cat.ProductItemCategory.search(srch) >= 0)
          cats.push(cat.ProductItemCategoryID);
      });
      SessionInfo.saveStoreItems.forEach(row => {
        //console.log(`search idx: ${row.ItemName.search(srchStr)}, name: ${row.ItemName}, ItemDesc: ${row.ItemDescription.search(srchStr)}, Desc: ${row.ItemDescription}`);
        let found = false;
        if (cats.length > 0) {
          cats.forEach(catID => {
            if (found == true)
              return;
            if (row.ProductItemCategoryID == catID) {
              StoreItems.push(row);
              cnt++
              found = true;
            }
          });
        }
        if (found == false) {
          if (row.ItemName.search(srch) >= 0) {
            StoreItems.push(row);
            cnt++
          } else if (row.ItemDescription.search(srch) >= 0) {
            StoreItems.push(row);
            cnt++
          }
        }
      });
      console.log(`cat match: ${cnt}`);
      this.lastCategory = "";
      if (cnt === 0) {
        this.setState({ StoreItems: SessionInfo.saveStoreItems });
        this.setNotifyMessage = 'No Items Found';
      } else
        this.setState({ StoreItems });
    } else { // ALL
      this.setState({ StoreItems: SessionInfo.saveStoreItems });
    }
  }
  clearSearch = async (event) => { // ClearDisplay, Clear Messages - see updateSearchValue
    //console.log(`clear search: ${this.state.searchValue}`);
    if (this.state.searchValue !== '') {
      await this.getHelpTopics('');
      this.setState({ searchValue: '' });
      this.setState({ lastSearchValue: '' });
      this.setState({ clearSetAll: true });
      this.setItemStateChanged(true);
    }
  }
  handleTopTabSelect = async (e) => { // Tab Selection
    await this.selectTab(e.selected);
  }
  selectTab = async (tabNumber) => { // Note - Load Dictionaries based on Tab
    console.log("Tab Select in Manager - Tab: " + tabNumber);
    this.searchAlt = 0;
    //let horizontalPanes = [{ collapsible: false, min: '65%', collapsed: false }, { collapsible: true, collapsed: true }]; // Left/Right
    try {
      if (SessionInfo.clearSearch)
        SessionInfo.clearSearch();
      this.setState({ selectedTopTab: tabNumber });
      this.setState({ bottomDisplay: 0 });
      SessionInfo.setShowSave = 2; // Turn off Save in Header 
      SessionInfo.setGridAdd = 2;
      SessionInfo.setShowNew = 2;
      SessionInfo.setExcelExport = 2;
      SessionInfo.setGridRefresh = 2;
      this.setState({ selectedTopTab: tabNumber });
      if (tabNumber === this.tabs.ManagerHelp) {
        console.log(`get Manager Help - current: ${this.currentTab}`);
        if (tabNumber != this.currentTab) {
          this.currentTab = tabNumber;
          await this.getHelpTopics(this.state.searchValue);
        }
        SessionInfo.setShowSave = 2; // Disable Save in Header 
        SessionInfo.searchTitle = "Search Manager Help";
      }
      else if (tabNumber === this.tabs.MenuHelp) {
        console.log("get Menu Help");
        if (tabNumber != this.currentTab) {
          this.currentTab = tabNumber;
          await this.getHelpTopics(this.state.searchValue);
        }
        SessionInfo.setShowSave = 2; // Disable Save in Header 
        SessionInfo.searchTitle = "Search Menu Help";
      }
      else if (tabNumber === this.tabs.Editor) {
        SessionInfo.setShowSave = 1; // Enable Save in Header 
        SessionInfo.setShowNew = 1;
        SessionInfo.searchTitle = "";
      }
      else if (tabNumber === this.tabs.HelpList) {
        if (tabNumber != this.currentTab) {
          this.currentTab = tabNumber;
          await this.getHelpTopics(this.state.searchValue);
        }
        SessionInfo.setShowSave = 2; // Enable Save in Header 
        SessionInfo.setShowNew = 2;
        SessionInfo.searchTitle = "Search Help";          
      }
    }
    catch (ex) {
      displayError("Error in Tab Select: " + ex);
    }
  }
  setItemStateChanged = (changed) => {
    this.setState({ itemStateHasChanged: changed });
    if (changed === true) {
      SessionInfo.setShowSave = 1; // Show Save in Header  
      if (this.clearScreen === true) {
        this.clearScreen = false;
        SessionInfo.setShowNew = 1;
      }
    } else
      SessionInfo.setShowSave = 2; // Turn Off
  }
  chgFldVal(event) {
    this.setState({ [event.target.name]: event.target.value });
    this.setItemStateChanged(true);
  }
  chgMoneyFldVal(event) {
    //var stateCopy = Object.assign({}, this.state[event.target.name]);
    let newVal = event.target.value.replace(/[^\d.-]/g,'');
    this.setState({ [event.target.name]: newVal });
  }
  chgPercentFldVal(event) {
    //var stateCopy = Object.assign({}, this.state[event.target.name]);
    let newVal = event.target.value.replace(/[^\d.-]/g,'');
    //console.log("percent newval: " + newVal);
    newVal /= 100.0;
    this.setState({ [event.target.name]: newVal });
    this.setItemStateChanged(true);
  }
  chgDDFldVal = (event) => {
    this.setState({ [event.target.props.name]: event.target.value.ddID });
    this.setItemStateChanged(true);
  }
  chgCheckboxVal(event) {
    let setVal = true;
    if (this.state[event.target.name] === true)
      setVal = false;
    this.setState({ [event.target.name]: setVal });
    this.setItemStateChanged(true);
  }
  //------------------------- Menu Page List Template ----------------------------------------------------------------------------------------------------------------------------
  HelpPageHeader = () => {
    return (
      <ListViewHeader className='listHelpHeader'>
        <div className='listHeaderLeft'>
          <span style={{ fontSize: 22,fontWeight: 'bold',color: 'black' }}>&nbsp; &nbsp; Interact &nbsp; &nbsp; Help Information </span>
        </div>
        <div className='listHeaderRight'>
          <Button id="cancelButton" icon="cancel" look="outline" className="leftButton" onClick={this.exitHelp} >EXIT HELP</Button>
        </div>
      </ListViewHeader>
    );
  }
  HelpPageFormat = props => {
    let item = props.dataItem;
    //if (!item.imageVal) {
    //  let value = item.Image;
    //  if (!value) { // Note - blank
    //    value = "/9j/4AAQSkZJRgABAQEAYABgAAD/2wBDAAgGBgcGBQgHBwcJCQgKDBQNDAsLDBkSEw8UHRofHh0aHBwgJC4nICIsIxwcKDcpLDAxNDQ0Hyc5PTgyPC4zNDL/2wBDAQkJCQwLDBgNDRgyIRwhMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjL/wAARCAAZABkDASIAAhEBAxEB/8QAHwAAAQUBAQEBAQEAAAAAAAAAAAECAwQFBgcICQoL/8QAtRAAAgEDAwIEAwUFBAQAAAF9AQIDAAQRBRIhMUEGE1FhByJxFDKBkaEII0KxwRVS0fAkM2JyggkKFhcYGRolJicoKSo0NTY3ODk6Q0RFRkdISUpTVFVWV1hZWmNkZWZnaGlqc3R1dnd4eXqDhIWGh4iJipKTlJWWl5iZmqKjpKWmp6ipqrKztLW2t7i5usLDxMXGx8jJytLT1NXW19jZ2uHi4+Tl5ufo6erx8vP09fb3+Pn6/8QAHwEAAwEBAQEBAQEBAQAAAAAAAAECAwQFBgcICQoL/8QAtREAAgECBAQDBAcFBAQAAQJ3AAECAxEEBSExBhJBUQdhcRMiMoEIFEKRobHBCSMzUvAVYnLRChYkNOEl8RcYGRomJygpKjU2Nzg5OkNERUZHSElKU1RVVldYWVpjZGVmZ2hpanN0dXZ3eHl6goOEhYaHiImKkpOUlZaXmJmaoqOkpaanqKmqsrO0tba3uLm6wsPExcbHyMnK0tPU1dbX2Nna4uPk5ebn6Onq8vP09fb3+Pn6/9oADAMBAAIRAxEAPwD32iiigAooooAKKKKACiiigD//2Q==";
    //  }
    //  const byteCharacters = window.atob(value);
    //  const byteNumbers = new Array(byteCharacters.length);
    //  for (let i = 0; i < byteCharacters.length; i++) {
    //    byteNumbers[i] = byteCharacters.charCodeAt(i);
    //  }
    //  const byteArray = new Uint8Array(byteNumbers);
    //  let image = new Blob([byteArray], { type: 'image/jpeg' });
    //  item.imageVal = URL.createObjectURL(image);
    //}

    return (
      <div>
        <div className="listHelpRow" onClick={(e) => this.onHelpItemClick(item)}>
          <div className="listColA">
            <span style={{ fontSize: 16,color: "black",fontWeight: "bold" }} >{item.HelpTitle}&nbsp;&nbsp;&nbsp;
            </span><br />
            <div dangerouslySetInnerHTML={{ __html: item.HelpContent }}></div>
          </div>
        </div>
      </div>
    );
  }
  HelpPageFooter = () => {
    //const [state, onCheckout] = React.useContext(StateContext);
    return (
      <ListViewFooter className='listHelpFooter'>
      </ListViewFooter>
    );
  }
  // Move HTML to Text area
  getHtmlText = async () => {
    if (editorRef.current) {
      const view = editorRef.current.view;
      if (view) {
        this.htmlText = EditorUtils.getHtml(view.state);
        console.log(`get Text: ${this.htmlText}`);
        await this.setState({ HelpContent: this.htmlText });
        if (textareaRef.current)
          textareaRef.current.value = this.htmlText;
      }
    }
  };
  // Move Text to HTML area
  showHtml = () => {
    if (editorRef.current) {
      const view = editorRef.current.view;
      if (textareaRef.current) {
        if (view) {
          EditorUtils.setHtml(view,textareaRef.current.value);
        }
      } else if (view)
        EditorUtils.setHtml(view,"");
      else
        console.log(`Unable to showHTML - no view`);
    }
    else
      console.log(`Unable to showHTML - no editorRef`);
  };
  // Excel Export Support - one copy add to just before render
  _export;
  exportExcel = () => {
    this._export.save();
  }
  //---------------------------------------------------------------------------------------------------------

  render() {
    return (
      <div id="AboutPage" className="pageMain">
        <PageSide />
        <PageHeader L1='Home' PageTitle={`About Interact Suite V-${SessionInfo.appVersion}-${SessionInfo.debugProd}`} IsApp='y' ItemName='About' />
        <div id="mainCntr">
          <TabStrip selected={this.state.selectedTopTab} onSelect={this.handleTopTabSelect} className="tabStrip">
            <TabStripTab title="Manager Application Help">
              <div className="aboutList">
                <ListView data={this.state.HelpPages} item={this.HelpPageFormat} header={this.HelpPageHeader} footer={this.HelpPageFooter} />
              </div>
            </TabStripTab>
            <TabStripTab title="Menu App Help">
              <div className="menuList">
                <ListView data={this.state.HelpPages} item={this.HelpPageFormat} header={this.HelpPageHeader} footer={this.HelpPageFooter} />
              </div>
            </TabStripTab>

            <TabStripTab title="Editor">
              <div className="editTabHelp">
                {/*<div className="editTabTop">*/}
                <div id='div2' className='editFieldHZ1'>
                  <span className='editFieldLabelHZ1'>Help Title</span>
                  <input value={this.state.HelpTitle} name='HelpTitle' onChange={evt => this.chgFldVal(evt)} className='editHZInputWide' />
                </div>
                <div id='div2' className='editFieldHZ2'>
                  <span className='editFieldLabel'>Help Topic</span>
                  <input value={this.state.HelpTopic} name='HelpTopic' onChange={evt => this.chgFldVal(evt)} className='editHZInput' />
                </div>
                <div id='div2' className='editFieldHZ3'>
                  <span className='editFieldLabel'>Search Tags</span>
                  <input value={this.state.SearchTags} name='SearchTags' onChange={evt => this.chgFldVal(evt)} className='editHZInput' />
                </div>
                <div id='div2' className='editFieldHZ4'>
                  <span className='editFieldLabel'>Help Type</span>
                  <div className='editDDPosHZ'>
                    <DropDownList data={this.state.HelpTypes} textField='ddName' dataItemKey='ddID' value={this.state.HelpTypes.find(c => c.ddID === this.state.ContextObjectID)} name='ContextObjectID' onChange={evt => this.chgDDFldVal(evt)} className='editInputDD' />
                  </div>
                </div>
                <div id='div2' className='editFieldHZ5'>
                  <span className='editFieldLabel'>Help Field ID</span>
                  <input value={this.state.ObjectID} name='ObjectID' onChange={evt => this.chgFldVal(evt)} className='editHZInput' />
                  {/*<div className='editDDPosHZ'>*/}
                  {/*  <DropDownList data={this.state.HelpObjects} textField='ddName' dataItemKey='ddID' value={this.state.HelpObjects.find(c => c.ddID === this.state.ContextObjectID)} name='ObjectID' onChange={evt => this.chgDDFldVal(evt)} className='editInputDD' />*/}
                  {/*</div>*/}
                  </div>{/**/}
                {/*</div>*/}
                <div className="editTabWholeLow">
                  <Editor
                    tools={[
                      [Bold,Italic,Underline,Strikethrough],
                      [Subscript,Superscript],
                      ForeColor,
                      BackColor,
                      [CleanFormatting],
                      [AlignLeft,AlignCenter,AlignRight,AlignJustify],
                      [Indent,Outdent],
                      [OrderedList,UnorderedList],
                      [NumberedList,BulletedList],
                      FontSize,
                      FontName,
                      FormatBlock,
                      [SelectAll],
                      [Undo,Redo],
                      [Link,Unlink,InsertImage,ViewHtml],
                      [InsertTable,InsertFile],
                      [Pdf,Print],
                      [AddRowBefore,AddRowAfter,AddColumnBefore,AddColumnAfter],
                      [DeleteRow,DeleteColumn,DeleteTable],
                      [MergeCells,SplitCell],
                    ]}
                    contentStyle={{ height: 630,width: '96%' }}
                    defaultContent={editorHTMLContent} ref={editorRef}
                  />
                  {(SessionInfo.PermLevel >= 499) ?
                    <div>
                      <br />
                      <button className="k-button k-button-icontext" onClick={this.getHtmlText}><span className="k-icon k-i-arrow-60-down" />Get HTML text&nbsp;</button>
                      &nbsp;&nbsp;&nbsp;
                      <button className="k-button k-button-icontext" onClick={this.showHtml}><span className="k-icon k-i-arrow-60-up" />Show HTML&nbsp;</button>
                      <br />
                      <br />
                      <textarea className="k-textarea" style={{ height: 200,width: "100%",resize: "none",}} defaultValue={this.state.HelpContent} ref={textareaRef} />
                    </div> : null}
                </div>
              </div>
            </TabStripTab>
            {/*------ HelpPages Grid Definition ------ .slice(this.state.HelpPagesSkip,this.state.HelpPagesTake + this.state.HelpPagesSkip)} */}
            <TabStripTab title="Help Pages">
              <div className="editTab">
                <ExcelExport data={this.state.HelpPages} ref={(exporter) => { this._export = exporter; }}> 
                <TGrid style={{ position: 'absolute',top: 0,height: '100%' }}
                    data={this.state.HelpPages.slice(this.state.HelpPagesSkip,this.state.HelpPagesTake + this.state.HelpPagesSkip)}
                    onRowClick={this.onHelpRowClick} resizable={true}
                    skip={this.state.HelpPagesSkip} take={this.state.HelpPagesTake} total={this.state.HelpPages.length} onPageChange={this.HelpPagePageChange} pageable={{ info: true,type: 'numeric',pageSizes: true,previousNext: true }} pageSize={20}>
                    <Column field="HelpTitle" title="Help Title" width='200px' />
                    <Column field="HelpTopic" title="Topic Name" width='200px' />
                    <Column field="SearchTags" title="Search Keywords" width='200px' />
                    <Column field="ObjectID" title="Object" width='230px' filter='numeric' /> 
                  </TGrid>
                </ExcelExport>
              </div>
            </TabStripTab>
          </TabStrip>

          <Popup offset={this.detailOffset} show={this.state.showDetail} popupClass={'popupAboutDetail'} onClick={this.closePopup}>
            <div className="frameInfo" id="notifyBoxPU" dangerouslySetInnerHTML={{ __html: this.state.helpDetail }} onClick={this.closePopup}/>
          </Popup>
        </div>
        {SessionInfo.displayFooter === true ?
          <PageFooter L1='Home' /> : null}
      </div >
    );
  }
}

export default withRouter(AboutPage)