//----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// CustomerProfile - Interact Suite Customer  - Detailed information about the Customer, Customer settings, and information regarding subscribed projects
//                      Version 1.007 - June 5, 2019
//----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
import React, { Component } from 'react';
import { withRouter } from './withRouter';
import { Buffer } from 'buffer';
import { Grid as TGrid, GridColumn as Column } from '@progress/kendo-react-grid';
import { TabStrip, TabStripTab, Splitter } from '@progress/kendo-react-layout';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Button } from '@progress/kendo-react-buttons';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { ExternalDropZone, Upload } from "@progress/kendo-react-upload";
import { orderBy, filterBy } from '@progress/kendo-data-query';
import { Popup } from '@progress/kendo-react-popup';
import { DateTimePicker } from '@progress/kendo-react-dateinputs';
import { ListView,ListViewHeader,ListViewFooter } from '@progress/kendo-react-listview';
import { Switch, MaskedTextBox } from "@progress/kendo-react-inputs";
import { Avatar } from '@progress/kendo-react-layout';
import { SessionInfo } from './App';
import PageHeader from './PageHeader.js';
import PageFooter from './PageFooter.js';
import PageSide from './PageSide.js';
import { CallOM, OMTrace, UpdateRow, DeleteRowTry, GetTableRowTrans, GetTableRowRaw, displayMessage, displayWarning, displayError,displayNotify,getHelpTopics,getHelpDetail, saveImageFileToServer, getDocumentType, getImageFileFromServer, GetDropdownData, GetTableData, GetTableSearch, CreateKeys, LogOffResetMsg, MaskString,UnmaskString, CTYP, TYP } from './CommonCode.js';
import { DayOfWeekDropDownCell, TimePickerCell, MoneyCell, cellDateTime, cellCurrency, SetProductType, SetProductItemCategory, ProductItemCategoryDropDownCell, SetPricingType, EditGridCommandCell } from './CommonFormat.js';

let ItemCategoriesDD = [];
class CategoryDropDownCell extends React.Component {
  handleChange = (e) => { // e.target.value is the object {ddID: n, ddName: 'n'}
    //console.log("change - field: " + this.props.field + "  value: " + JSON.stringify(e.target.value) + " dataitem: " + JSON.stringify(this.props.dataItem));
    this.props.onChange({
      dataItem: this.props.dataItem,
      field: this.props.field,
      syntheticEvent: e.syntheticEvent,
      value: e.target.value
    });
    this.props.dataItem[this.props.field] = e.target.value.ddID;
    this.forceUpdate();
  }
  render() {
    let dataValue = this.props.dataItem[this.props.field];
    if (dataValue === undefined || dataValue === null)
      dataValue = 0;
    if (!this.props.dataItem.inEdit) {
      if (dataValue === 0)
        return (<td>{" "}</td>);
      else if (ItemCategoriesDD.find(c => c.ddID === dataValue) === undefined)
        return (
          <td>{"Unknown: " + dataValue}</td>
        );
      else
        return (
          <td>
            {ItemCategoriesDD.find(c => c.ddID === dataValue).ddName}
          </td>
        );
    }
    return (
      <td>
        <DropDownList style={{ width: "100px" }} data={ItemCategoriesDD} textField="ddName" dataItemKey="ddID" value={ItemCategoriesDD.find(c => c.ddID === dataValue)} onChange={this.handleChange} />
      </td>
    );
  }
}

//------ Object Definition For Customers -- Date: 2024-03-21-7:31 PM ------
const CustomersOID = 1020151; // NOTE - 7May24 - Modified - p: 200 and up is readonly - what is p: 100?? - I think it means FK Lookup
const CustomersO = [{ n: 'CustomerID',t: 195,p: 100 },{ n: 'FirstName',t: 21,p: 0 },{ n: 'AdditionalName',t: 21,p: 0 },{ n: 'LastName',t: 21,p: 0 },{ n: 'Address',t: 21,p: 0 },{ n: 'City',t: 21,p: 0 },{ n: 'ProvinceStateID',t: 195,p: 100 },{ n: 'CountryID',t: 195,p: 100 },{ n: 'PostalCode',t: 21,p: 0 },{ n: 'DateOfBirth',t: 34,p: 0 },{ n: 'Gender',t: 201,p: 0 },{ n: 'PhoneNumber',t: 21,p: 0 },{ n: 'PhoneNumber2',t: 21,p: 0 },{ n: 'EmailAddress',t: 21,p: 0 },{ n: 'EmailReceipts',t: 22,p: 0 },{ n: 'EmailPromotions',t: 22,p: 0 },{ n: 'SMSReminders',t: 22,p: 0 },{ n: 'SoundOn',t: 22,p: 0 },{ n: 'AutoScanLocation',t: 22,p: 0 },{ n: 'FlashOnScan',t: 22,p: 0 },{ n: 'CustomerIsInStore',t: 22,p: 0 },{ n: 'CustomerIsActive',t: 22,p: 0 },{ n: 'CustomerOrderActive',t: 22,p: 0 },{ n: 'MultiPersonOrder',t: 22,p: 0 },{ n: 'PaymentInProcess',t: 22,p: 0 },{ n: 'HasAbandonedOrder',t: 22,p: 0 },{ n: 'CustomerHasPaid',t: 22,p: 0 },{ n: 'HasExceededMax',t: 22,p: 0 },{ n: 'TemporaryCustomer',t: 22,p: 0 },{ n: 'TemporaryInUse',t: 22,p: 0 },{ n: 'TotalPurchaseAmount',t: 28,p: 0 },{ n: 'MaximumPurchaseAmount',t: 28,p: 0 },{ n: 'TodayPurchaseAmount',t: 28,p: 0 },{ n: 'PersonPhotoID',t: 41,p: 0 },{ n: 'DeviceProfileID',t: 195,p: 100 },{ n: 'OMUserID',t: 195,p: 200 },{ n: 'AccessToken',t: 21,p:200 },{ n: 'TransactionCount',t: 24,p: 200 },{ n: 'AbandonedSalesCount',t: 24,p: 200 },{ n: 'CardAddCount',t: 24,p: 0 },{ n: 'CustomerConfig',t: 15,p: 0 },{ n: 'CustomerControls',t: 15,p: 0 },{ n: 'GeoLocation',t: 21,p: 200 },{ n: 'RoleID',t: 195,p: 100 },{ n: 'LastTimeActive',t: 35,p: 0 },{ n: 'LastStoreVisitedID',t: 24,p:200 },{ n: 'StoreLocationID',t: 195,p: 100 },{ n: 'SublocationID',t: 195,p: 100 },{ n: 'StorePromotionID',t: 195,p: 100 },{ n: 'PurchaseFrequency',t: 24,p: 200 },{ n: 'PaceToken',t: 21,p: 200 },{ n: 'PaceID',t: 21,p: 200 },{ n: 'PacePassword',t: 21,p: 0 },{ n: 'UseCount',t: 24,p: 200 }];
//       Object Value Definition For Customers
const CV = { 'CustomerID': 0,'FirstName': '','AdditionalName': '','LastName': '','Address': '','City': '','ProvinceStateID': 0,'CountryID': 0,'PostalCode': '','DateOfBirth': new Date(),'Gender': 0,'PhoneNumber': '','PhoneNumber2': '','EmailAddress': '','EmailReceipts': false,'EmailPromotions': false,'SMSReminders': false,'SoundOn': false,'AutoScanLocation': false,'FlashOnScan': false,'CustomerIsInStore': false,'CustomerIsActive': false,'CustomerOrderActive': false,'MultiPersonOrder': false,'PaymentInProcess': false,'HasAbandonedOrder': false,'CustomerHasPaid': false,'HasExceededMax': false,'TemporaryCustomer': false,'TemporaryInUse': false,'TotalPurchaseAmount': 0,'MaximumPurchaseAmount': 0,'TodayPurchaseAmount': 0,'PersonPhotoID': '','DeviceProfileID': 0,'OMUserID': 0,'AccessToken': '','TransactionCount': 0,'AbandonedSalesCount': 0,'CardAddCount': 0,'CustomerConfig': 0,'CustomerControls': 0,'GeoLocation': '','RoleID': 0,'LastTimeActive': new Date(),'LastStoreVisitedID': 0,'StoreLocationID': 0,'SublocationID': 0,'StorePromotionID': 0,'PurchaseFrequency': 0,'PaceToken': '','PaceID': '','PacePassword': '','UseCount': 0 };
//       Object Index Definition For Customers
const CX = { 'CustomerID': 0,'FirstName': 1,'AdditionalName': 2,'LastName': 3,'Address': 4,'City': 5,'ProvinceStateID': 6,'CountryID': 7,'PostalCode': 8,'DateOfBirth': 9,'Gender': 10,'PhoneNumber': 11,'PhoneNumber2': 12,'EmailAddress': 13,'EmailReceipts': 14,'EmailPromotions': 15,'SMSReminders': 16,'SoundOn': 17,'AutoScanLocation': 18,'FlashOnScan': 19,'CustomerIsInStore': 20,'CustomerIsActive': 21,'CustomerOrderActive': 22,'MultiPersonOrder': 23,'PaymentInProcess': 24,'HasAbandonedOrder': 25,'CustomerHasPaid': 26,'HasExceededMax': 27,'TemporaryCustomer': 28,'TemporaryInUse': 29,'TotalPurchaseAmount': 30,'MaximumPurchaseAmount': 31,'TodayPurchaseAmount': 32,'PersonPhotoID': 33,'DeviceProfileID': 34,'OMUserID': 35,'AccessToken': 36,'TransactionCount': 37,'AbandonedSalesCount': 38,'CardAddCount': 39,'CustomerConfig': 40,'CustomerControls': 41,'GeoLocation': 42,'RoleID': 43,'LastTimeActive': 44,'LastStoreVisitedID': 45,'StoreLocationID': 46,'SublocationID': 47,'StorePromotionID': 48,'PurchaseFrequency': 49,'PaceToken': 50,'PaceID': 51,'PacePassword': 52,'UseCount': 53 };

//------ Encoded Values - CustomerConfig ------
const CustomerConfigFixedValues = [{ n: 'Customer Reset',k: 1 },{ n: 'Diet Vegetarian',k: 5 },{ n: 'Diet Vegan',k: 6 },{ n: 'Diet Nut Alergy',k: 7 },{ n: 'Diet Lactose',k: 8 },{ n: 'Diet Gluten',k: 9 },{ n: 'Diet Other',k: 12 }];
//------ Encoded Entries - CustomerConfig ------
const CustomerConfigList = ['Customer Reset','Diet Vegetarian','Diet Vegan','Diet Nut Alergy','Diet Lactose','Diet Gluten','Diet Other'];
//------ Encoded Values - CustomerControls ------
const CustomerControlsFixedValues = [{ n: 'Customer Active',k: 1 },{ n: 'Customer On Hold',k: 2 },{ n: 'Customer Verified',k: 3 },{ n: 'Customer Password Verified',k: 4 },{ n: 'Customer Has Credit',k: 5 },{ n: 'Customer Direct Pay',k: 6 },{ n: 'Customer Watch',k: 7 },{ n: 'Common Customer',k: 20 }];
//------ Encoded Entries - CustomerControls ------
const CustomerControlsList = ['Customer Active','Customer On Hold','Customer Verified','Customer Password Verified','Customer Has Credit','Customer Direct Pay','Customer Watch','Common Customer'];

//------ Object Definition For CustomOrders -- Date: 2024-01-18-10:19 AM ------
const CustomOrdersOID = 545072;
const CustomOrdersO = [{ n: 'CustomOrderID',t: 195,p: 100 },{ n: 'StoreLocationID',t: 195,p: 100 },{ n: 'CustomerID',t: 195,p: 100 },{ n: 'CustomOrderParentID',t: 195,p: 100 },{ n: 'CustomOrderStatus',t: 201,p: 0 },{ n: 'CustomOrderType',t: 201,p: 0 },{ n: 'RequestDateTime',t: 35,p: 0 },{ n: 'ScheduledPrepDateTime',t: 35,p: 0 },{ n: 'PickupDeliveryDateTime',t: 35,p: 0 },{ n: 'TotalPrice',t: 28,p: 0 },{ n: 'AmountOwing',t: 28,p: 0 },{ n: 'TotalTaxAmount',t: 28,p: 0 },{ n: 'TotalTax2Amount',t: 28,p: 0 },{ n: 'TotalTax3Amount',t: 28,p: 0 },{ n: 'AmountPaid',t: 28,p: 0 },{ n: 'DiscountAmount',t: 28,p: 0 },{ n: 'CreditAmountUsed',t: 28,p: 0 },{ n: 'RefundAmount',t: 16,p: 0 },{ n: 'TipAmount',t: 28,p: 0 },{ n: 'AmountPaidWithTip',t: 28,p: 0 },{ n: 'AddressID',t: 195,p: 100 },{ n: 'PhoneNumber',t: 21,p: 0 },{ n: 'ItemCount',t: 16,p: 0 },{ n: 'OptionCount',t: 24,p: 0 },{ n: 'OptionsPrice',t: 28,p: 0 },{ n: 'TotalQuantity',t: 16,p: 0 },{ n: 'OrderCreationDate',t: 35,p: 0 },{ n: 'OrderPaidDate',t: 35,p: 0 },{ n: 'Rating',t: 16,p: 0 },{ n: 'PaidComment',t: 21,p: 0 },{ n: 'CustomOrderPaymentID',t: 195,p: 100 },{ n: 'OrderNotes',t: 21,p: 0 },{ n: 'RefundTaxAmount',t: 28,p: 0 },{ n: 'PaymentAuthCode',t: 21,p: 0 },{ n: 'TransactionID',t: 24,p: 0 },{ n: 'OrderNumber',t: 21,p: 0 },{ n: 'OrderSourceID',t: 195,p: 100 },{ n: 'POSCheckNumber',t: 21,p: 0 },{ n: 'ThirdPartyOrderNumber',t: 21,p: 0 },{ n: 'VerifiedDate',t: 35,p: 0 },{ n: 'VerifiedBy',t: 24,p: 0 },{ n: 'OrderPreparationTypeID',t: 195,p: 100 },{ n: 'ReturnCycle',t: 24,p: 0 },{ n: 'CompletedReturnCycle',t: 24,p: 0 },{ n: 'SublocationID',t: 195,p: 100 },{ n: 'SeatNumber',t: 24,p: 0 },{ n: 'SeatName',t: 21,p: 0 },{ n: 'IndividualItems',t: 22,p: 0 },{ n: 'RecordProcessed',t: 22,p: 0 },{ n: 'ActiveCounted',t: 22,p: 0 },{ n: 'PaymentInProcess',t: 22,p: 0 },{ n: 'PaymentComplete',t: 22,p: 0 }];
//       Object Value Definition For CustomOrders
const COV = { 'CustomOrderID': 0,'StoreLocationID': 0,'CustomerID': 0,'CustomOrderParentID': 0,'CustomOrderStatus': 0,'CustomOrderType': 0,'RequestDateTime': new Date(),'ScheduledPrepDateTime': new Date(),'PickupDeliveryDateTime': new Date(),'TotalPrice': 0,'AmountOwing': 0,'TotalTaxAmount': 0,'TotalTax2Amount': 0,'TotalTax3Amount': 0,'AmountPaid': 0,'DiscountAmount': 0,'CreditAmountUsed': 0,'RefundAmount': 0,'TipAmount': 0,'AmountPaidWithTip': 0,'AddressID': 0,'PhoneNumber': '','ItemCount': 0,'OptionCount': 0,'OptionsPrice': 0,'TotalQuantity': 0,'OrderCreationDate': new Date(),'OrderPaidDate': new Date(),'Rating': 0,'PaidComment': '','CustomOrderPaymentID': 0,'OrderNotes': '','RefundTaxAmount': 0,'PaymentAuthCode': '','TransactionID': 0,'OrderNumber': '','OrderSourceID': 0,'POSCheckNumber': '','ThirdPartyOrderNumber': '','VerifiedDate': new Date(),'VerifiedBy': 0,'OrderPreparationTypeID': 0,'ReturnCycle': 0,'CompletedReturnCycle': 0,'SublocationID': 0,'SeatNumber': 0,'SeatName': '','IndividualItems': false,'RecordProcessed': false,'ActiveCounted': false,'PaymentInProcess': false,'PaymentComplete': false };
//       Object Index Definition For CustomOrders
const COX = { 'CustomOrderID': 0,'StoreLocationID': 1,'CustomerID': 2,'CustomOrderParentID': 3,'CustomOrderStatus': 4,'CustomOrderType': 5,'RequestDateTime': 6,'ScheduledPrepDateTime': 7,'PickupDeliveryDateTime': 8,'TotalPrice': 9,'AmountOwing': 10,'TotalTaxAmount': 11,'TotalTax2Amount': 12,'TotalTax3Amount': 13,'AmountPaid': 14,'DiscountAmount': 15,'CreditAmountUsed': 16,'RefundAmount': 17,'TipAmount': 18,'AmountPaidWithTip': 19,'AddressID': 20,'PhoneNumber': 21,'ItemCount': 22,'OptionCount': 23,'OptionsPrice': 24,'TotalQuantity': 25,'OrderCreationDate': 26,'OrderPaidDate': 27,'Rating': 28,'PaidComment': 29,'CustomOrderPaymentID': 30,'OrderNotes': 31,'RefundTaxAmount': 32,'PaymentAuthCode': 33,'TransactionID': 34,'OrderNumber': 35,'OrderSourceID': 36,'POSCheckNumber': 37,'ThirdPartyOrderNumber': 38,'VerifiedDate': 39,'VerifiedBy': 40,'OrderPreparationTypeID': 41,'ReturnCycle': 42,'CompletedReturnCycle': 43,'SublocationID': 44,'SeatNumber': 45,'SeatName': 46,'IndividualItems': 47,'RecordProcessed': 48,'ActiveCounted': 49,'PaymentInProcess': 50,'PaymentComplete': 51 };

const uploadPersonPhotoRef = React.createRef();
let currentImageID = 0;

class CustomerProfile extends Component {
  //------------------------------------------------------------------------------------------------------
  constructor(props) {
    super(props);
    //console.log("CustomerProfile");
    if (!SessionInfo || !SessionInfo.session) {
      this.props.navigate("/AppMain");
    } else {
      if (SessionInfo.CustomerProfile) { // Data saved for this session
        this.state = SessionInfo.CustomerProfile;
        this.loadExistingPage = true;
      }
      else {
        this.loadExistingPage = false;
      }
      // Bind Functions
      this.saveRecord = this.saveRecord.bind(this);
      this.clearRecord = this.clearRecord.bind(this);
      this.deleteRecord = this.deleteRecord.bind(this);
      this.createKeys = CreateKeys.bind(this);
      //console.log("finish Bind");   
    }
  }
  //--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------  
  // State
  state = {
    //------ Integer Values - Customers ------
    CustomerID: 0,
    ProvinceStateID: 0,
    Gender: 0,
    EmailReceipts: false,
    EmailPromotions: false,
    SMSReminders: false,
    SoundOn: false,
    AutoScanLocation: false,
    FlashOnScan: false,
    CustomerIsInStore: false,
    CustomerIsActive: false,
    CustomerOrderActive: false,
    MultiPersonOrder: false,
    PaymentInProcess: false,
    CustomerHasPaid: false,
    HasAbandonedOrder: false,
    HasExceededMax: false,
    TotalPurchaseAmount: 0,
    MaximumPurchaseAmount: 0,
    MaximumPurchaseAmountS: '',
    DeviceProfileID: 0,
    OMUserID: 0,
    TransactionCount: 0,
    AbandonedSalesCount: 0,
    CardAddCount: 0,
    CustomerControls: 0,
    RoleID: 0,
    LastTimeActive: 0,

    //--------------------------------
    // CustomerAccounts
    Amount: 0,
    TransactionCount: 0,
    MinusTransactions: 0,
    PlusTransactions: 0,
    TotalMinusAmount: 0,
    TotalPlusAmount: 0,
    MaximumAmount: 0,
    LastUsedOn: 0,
    CustomerAccountStatus: 0,
    CustomerControls: 0,
    //------ Integer Values - CustomOrders ------
    CustomOrderID: 0,
    StoreLocationID: 0,
    CustomerID: 0,
    CustomOrderStatus: '',
    CustomOrderType: 0,
    RequestDateTime: 0,
    ScheduledPrepDateTime: 0,
    PickupDeliveryDateTime: 0,
    TotalPrice: 0,
    AmountOwing: 0,
    TotalTaxAmount: 0,
    TotalTax2Amount: 0,
    TotalTax3Amount: 0,
    AmountPaid: 0,
    DiscountAmount: 0,
    CreditAmountUsed: 0,
    RefundAmount: 0,
    TipAmount: 0,
    AmountPaidWithTip: 0,
    ItemCount: 0,
    TotalQuantity: 0,
    OrderCreationDate: '',
    OrderPaidDate: 0,
    PaymentMethodID: 0,
    RefundTaxAmount: 0,
    IndividualItems: 0,
    TransactionID: 0,
    VerifiedDate: 0,
    VerifiedBy: 0,
    OrderPreparationTypeID: 0,
    ReturnCycle: 0,
    CompletedReturnCycle: 0,
    RecordProcessed: 0,
    TodayPurchaseAmount: 0,
    CustomerConfig: 0,
    //------ Encoded Bit Values For CustomerConfig -------
    CustomerReset: false,
    DietVegetarian: false,
    DietVegan: false,
    DietNutAlergy: false,
    DietLactose: false,
    DietGluten: false,
    DietOther: false,
    //------ String Values - CustomOrders ------
    Address: '',
    PhoneNumber: '',
    PaidComment: '',
    OrderNotes: '',
    PaymentAuthCode: '',
    OrderNumber: '',
    //--------------------------------
    HelpPages: [],
    showHelpDetail: false,
    helpDetail: '',
    showHelpListPopup: false,
    showSendAMessage: false,
    SendMessage: '',
    showChangePassword: false,
    newPassword: '',

    UserID: 0,
    UserName: '',

    //------ String Values - Customers ------
    FirstName: '',
    AdditionalName: '',
    LastName: '',
    Address: '',
    City: '',
    PhoneNumber: '',
    PhoneNumber2: '',
    EmailAddress: '',
    AccessToken: '',
    GeoLocation: '',

    selectedTopTab: 0,
    selectedBottomTab: 0,
    itemStateHasChanged: false,

    isMainSearch: true,
    PersonPhotoID: 0,
    PersonPhotoImageURL: '',
    isWalletUser: false,
    contractsVisible: false,
    StoreLocationID: 0,
    selectCustomerID: 0,

    CustomOrders: [],
    customOrdersSkip: 0,
    customOrdersTake: 8,
    receiptFilter: { logic: "and",filters: [] },
    receiptSort: [],

    CustomOrderItems: [],
    customOrderItemsSkip: 0,
    customOrderItemsTake: 8,
    receiptFilter: { logic: "and",filters: [] },
    receiptSort: [],

    accountActive: false,
    accountHold: false,
    accountHasCredit: false,
    customerDirectPay: false,
    customerWatch: false,

    Customers: [],
    saveCustomers: [],
    customersSkip: 0,
    customersTake: 20,

    Roles: [],
    CustomerAccountStatusInfo: [],
    CreditAmount: 0,

    //------ Integer Values - CustomOrders ------
    CustomOrderID: 0,
    StoreLocationID: 0,
    CustomerID: 0,
    CustomOrderParentID: 0,
    RequestDateTime: 0,
    ScheduledPrepDateTime: 0,
    PickupDeliveryDateTime: 0,
    TotalPrice: 0,
    AmountOwing: 0,
    TotalTaxAmount: 0,
    TotalTax2Amount: 0,
    TotalTax3Amount: 0,
    AmountPaid: 0,
    DiscountAmount: 0,
    CreditAmountUsed: 0,
    RefundAmount: 0,
    TipAmount: 0,
    AmountPaidWithTip: 0,
    ItemCount: 0,
    OptionCount: 0,
    TotalQuantity: 0,
    OrderCreationDate: 0,
    OrderPaidDate: 0,
    Rating: 0,
    PaymentMethodID: 0,
    RefundTaxAmount: 0,
    TransactionID: 0,
    OrderSourceID: 0,
    VerifiedDate: 0,
    VerifiedBy: 0,
    OrderPreparationTypeID: 0,
    ReturnCycle: 0,
    CompletedReturnCycle: 0,
    SublocationID: 0,
    SeatNumber: 0,
    IndividualItems: false,
    RecordProcessed: false,
    PaymentComplete: false,
    NotificationTypeID: 0,

    //------ String Values - CustomOrders ------
    Address: '',
    PhoneNumber: '',
    PaidComment: '',
    OrderNotes: '',
    PaymentAuthCode: '',
    OrderNumber: '',
    POSCheckNumber: '',
    ThirdPartyOrderNumber: '',
    SeatName: '',

    //------ Dictionary Arrays - CustomOrders ------
    CustomOrdersDict: [],
    StoreLocationsDict: [],
    CustomersDict: [],
    ProvinceStateCodesDict: [],
    CountriesDict: [],
    CustomOrdersDict: [],
    CustomOrderStatusDict: [],
    CustomOrderTypes: [],
    PaymentMethodsDict: [],
    OrderSourcesDict: [],
    OrderPreparationTypesDict: [],
    SublocationsDict: [],
    NotificationTypesDict: [],

    //horizontalPanes: [{ size: '20%', min: '200px', collapsible: false }, { size: '80%', min: '700px', collapsed: false, collapsible: false }, { min: '200px', collapsed: true, collapsible: true }], // Left, Right
    horizontalPanes: [{ size: '20%',min: '200px',collapsible: false },{ size: '80%',min: '700px',collapsed: false,collapsible: false }], // Left, Right
    verticalPanes: [{ min: '300px' },{ size: '50%',collapsible: true,collapsed: true }],  // Top, Bottom
  };
  vertSplitC = JSON.parse('[{"collapsible":false,"collapsed":false,"resizable":true,"scrollable":true,"min":"300px"},{"collapsible":true,"collapsed":true,"resizable":true,"scrollable":true,"size":"50%"}]');
  vertSplitO = JSON.parse('[{"collapsible":false,"collapsed":false,"resizable":true,"scrollable":true,"min":"300px"},{"collapsible":true,"collapsed":false,"resizable":true,"scrollable":true,"size":"50%"}]');

  horizSplitOC = JSON.parse('[{"0":"[","collapsible":false,"collapsed":false,"resizable":true,"scrollable":true,"size":"20%"},{"0":"{","collapsible":false,"collapsed":false,"resizable":true,"scrollable":true}, { "collapsible": true, "collapsed": true, "resizable": true, "scrollable": true, "size": "20%" }]');
  horizSplitCC = JSON.parse('[{"0":"[","collapsible":false,"collapsed":false,"resizable":true,"scrollable":true,"size":"50px"},{"0":"{","collapsible":false,"collapsed":false,"resizable":true,"scrollable":true}, { "collapsible": true, "collapsed": true, "resizable": true, "scrollable": true, "size": "20%" }]');
  horizSplitCO = JSON.parse('[{"0":"[","collapsible":false,"collapsed":false,"resizable":true,"scrollable":true,"size":"50px"},{"0":"{","collapsible":false,"collapsed":false,"resizable":true,"scrollable":true}, { "collapsible": true, "collapsed": false, "resizable": true, "scrollable": true, "size": "20%" }]');
  //horizSplitOO = JSON.parse('[{"0":"[","collapsible":false,"collapsed":false,"resizable":true,"scrollable":true,"size":"20%"},{"0":"{","collapsible":false,"collapsed":false,"resizable":true,"scrollable":true}, { "collapsible": true, "collapsed": false, "resizable": true, "scrollable": true, "size": "20%" }]');
  horizSplitOO = JSON.parse('[{"0":"[","collapsible":false,"collapsed":false,"resizable":true,"scrollable":true,"size":"20%"},{"0":"{","collapsible":false,"collapsed":false,"resizable":true,"scrollable":true}, { "collapsible": true, "collapsed": false, "resizable": true, "scrollable": true, "size": "20%" }]');

  helpListPopupOffset = { left: 150,top: 60 };
  helpDetailOffset = { left: 200,top: 120 };
  sendMessagePopupOffset = { left: 400,top: 400 };
  changePWOffset = { left: 800,top: 600 };

  currentCustomerID = 0;
  file = undefined;
  saveEmailAddress = "";
  createKeys;
  loadExistingPage = false;
  lastSelectedPID = 0;
  compUpdate = true;
  clearScreen = false;

  tabs = {
    CustomerInfo: 0,
    Setup: 1,
    Receipts: 2,
    Account: 3
  }
  bottomTabs = {
    Receipts: 0
  }
  mountInProgress = false;

  async componentDidMount() {
    if (!SessionInfo.session || SessionInfo.forceLogoff === true) {
      console.log(`forceLogoff set in CustomerProfile - go to Login`);
      this.props.navigate("/Login");
    }
    this.mountInProgress = true;
    console.log(`Customer Profile Did Mount - loadExistingPage: ${this.loadExistingPage}`);
    SessionInfo.currentPage = "CustomerProfile";
    let windowHt = window.innerHeight;
    let takeLinesD = (windowHt - 200) / 54.0;
    let takeLines = parseInt(takeLinesD);
    this.setState({ customersTake: takeLines });
    this.setState({ customOrderItemsTake: takeLines });
    takeLines = parseInt(takeLinesD / 2);
    this.setState({ customOrdersTake: takeLines });
    if (SessionInfo.refreshCustomerProfile === true) {
      this.loadExistingPage = false;
      SessionInfo.refreshCustomerProfile = false;
    }
    if (this.loadExistingPage === true)
      this.forceUpdate();
    else {  //------ Initialization Code - Customers ------
      this.setState({ selectCustomerID: SessionInfo.CustomerID });
      this.lastSelectedPID = SessionInfo.CustomerID;
      if (SessionInfo.SelectedCustomerID > 0) {
        //console.log("Get Customer Info for: " + SessionInfo.SelectedCustomerID);
        await this.getCustomerRecord(SessionInfo.SelectedCustomerID);
        //console.log("Get Record Complete");
        displayMessage("GREEN");
        SessionInfo.SelectedCustomerID = 0;
      }
      await this.getCustomerRecords();
      if (this.state.CustomerAccountStatusInfo.length <= 1) {
        let CD = await GetDropdownData(545784); // CustomerAccountStatus
        this.setState({ CustomerAccountStatusInfo: CD.d });
      }
      if (this.state.Roles.length <= 1) {
        let CD = await GetDropdownData(131286); // Roles 
        //console.log("Roles: " + JSON.stringify(CD.d));
        this.setState({ Roles: CD.d });
      }
      if (this.state.StoreLocationsDict.length <= 1) {
        let CD = await GetDropdownData(544596); // StoreLocations 
        //console.log("Roles: " + JSON.stringify(CD.d));
        this.setState({ StoreLocationsDict: CD.d });
      }
      if (this.state.NotificationTypesDict.length <= 1) {
        let CD = await GetDropdownData(178051); // NotificationTypes 
        //console.log("Roles: " + JSON.stringify(CD.d));
        this.setState({ NotificationTypesDict: CD.d });
      }
      if (this.state.ProvinceStateCodesDict.length <= 1) {
        let CD = await GetDropdownData(489030);
        this.setState({ ProvinceStateCodesDict: CD.d });
      }
      if (this.state.CountriesDict.length <= 1) {
        let CD = await GetDropdownData(154824);
        this.setState({ CountriesDict: CD.d });
      }
    }
    SessionInfo.currSaveFunc = this.saveRecord;
    SessionInfo.currDeleteFunc = this.deleteRecord;
    SessionInfo.currClearFunc = this.clearRecord;
    SessionInfo.currAddGridRowFunc = undefined;
    SessionInfo.currExcelExportFunc = undefined;
    SessionInfo.currShowHelp = this.showHelp;
    SessionInfo.currExpandLPane = this.openLeftPane;
    SessionInfo.currCollapseLPane = this.closeLeftPane;
    SessionInfo.currExpandRPane = this.openRightPane;
    SessionInfo.currCollapseRPane = this.closeRightPane;
    SessionInfo.searchFunc = this.searchCurrent;
    SessionInfo.currRefreshGridFunc = this.searchCurrent;
    SessionInfo.tabSelectFunc = this.selectTab;
    if (SessionInfo.GoToTab >= 0) {
      this.setState({ selectedTopTab: SessionInfo.GoToTab });
      SessionInfo.GoToTab = 0;
    }
    if (SessionInfo.AccountShowCurrent === true) {
      await this.getCustomerRecord(SessionInfo.CustomerID); // SessionInfo.userID);
      SessionInfo.AccountShowCurrent = false;
    }
    this.setState({ accountHasCredit: true });
    this.mountInProgress = false;
  }
  componentWillUnmount() {
    if (SessionInfo.session !== '')
      SessionInfo.CustomerProfile = this.state;
    this.mountInProgress = false;
  }
  shouldComponentUpdate() {
    //console.log("Should Update?");
    if (this.compUpdate === true)
      return true;
    else
      return false;
  }
  //--------------- Standard Page Functions ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
  searchCurrent = async (search) => {
    if (this.state.selectedTopTab === 2) {
      displayMessage("Search Receipts");
      this.setState({ customOrdersSkip: 0 });
      this.setState({ customOrdersTake: 8 })
      this.setState({ receiptFilter: { logic: "and",filters: [] } });
      this.setState({ receiptSort: [] });
      await this.getCustomOrders(search);
    }
    else {
      displayMessage("Search Customers");
      await this.getCustomerRecords(search);
      this.setState({ customersSkip: 0 });
      this.setState({ customersTake: 20 })
    }
  }
  saveRecord = async () => {
    console.log(`Save Customer - RoleID: ${this.state.RoleID}, PermLevel: ${SessionInfo.PermLevel}`);
    console.log(`Save Customer - EmailAddress: ${SessionInfo.logonEmailAddress}, saveEmailAddress: ${this.saveEmailAddress}, phone: ${this.state.PhoneNumber}`);
    // PermLevel is RoleID + 220 below
    if (SessionInfo.PermLevel > (this.state.RoleID + 220) || SessionInfo.logonEmailAddress === this.saveEmailAddress || this.state.PhoneNumber === "6442000000") { // Note the conversion between PermLevel (490-499) and RoleID (270-279)
      let custCtrl = 0;
      custCtrl += this.saveBit(this.state.accountActive,1);
      custCtrl += this.saveBit(this.state.accountHold,2);
      custCtrl += this.saveBit(this.state.accountHasCredit,5);
      custCtrl += this.saveBit(this.state.customerDirectPay,6);
      custCtrl += this.saveBit(this.state.customerWatch,7);
      await this.setState({ CustomerControls: custCtrl });
      await this.getCustomerConfigBitEncoded();
      console.log("CustomerControls: " + this.state.CustomerControls);
      let propState = {};
      //console.log("Save StoreLocation");
      CustomersO.forEach((elem) => {
        if (elem.p != 200) { // Don't update on p = 200
          if (elem.n in this.state) {
            if (this.state[elem.n] == undefined) {
              if (elem.t === 34 || elem.t === 35)
                propState[elem.n] = new Date();
              else if (elem.t === 21 || elem.t === 19 || elem.t === 29) // String, Memo, Enum?
                propState[elem.n] = '';
              else if (elem.t === 28 || elem.t === 201) { // Money, Enum
                propState[elem.n] = 0;
              } else if (elem.t === 19) { // Money
                propState[elem.n] = 0;
              } else if (elem.t === 195) { // Do not clear FK fields on first click
                propState[elem.n] = 0;
              } else
                propState[elem.n] = 0;
            }
            else
              propState[elem.n] = this.state[elem.n];
          }
        }
      });
      //-----------   
      //await UpdateRow(1020151, 0, undefined, propState, this.currentCustomerID, "CustomerID"); // Save to OM  
      let CD = await CallOM("SaveCustomerRecord",1020151,this.currentCustomerID,CTYP.DICTNO,propState,0); // Save to OM
      await this.getCustomerRecords(); // For Sidebar
    }
    else
      displayError("Cannot Save Account Information due to permissions");
    //await this.getProjectRecords();
    this.setItemStateChanged(false);
  }
  deleteRecord = async () => {
    //console.log("Delete CustomerProfile: " + this.state.CustomerID);
    let CD = await DeleteRowTry(543619,this.currentCustomerID);
    console.log("Delete Customer - o: " + CD.x.o);
    this.setItemStateChanged(false);
  }
  clearRecord = async () => {
    CustomersO.forEach((elem) => {
      let sobj = {};
      try {
        if (elem.t === 34 || elem.t === 35)
          sobj[elem.n] = new Date();
        else if (elem.t === 21 || elem.t === 19 || elem.t === 29) // String, Memo, Enum? 
          sobj[elem.n] = '';
        else if (elem.t === 28 || elem.t === 201) { // Money, Enum
          sobj[elem.n] = 0;
          let sobjS = {};
          sobjS[elem.n + 'S'] = '';
          this.setState(sobjS);
        } else if (elem.t === 19) { // Money 
          sobj[elem.n] = 0;
          let sobjS = {};
          sobjS[elem.n + 'S'] = '';
        } else if (elem.t === 195) { // Do not clear FK fields on first click
          if (SessionInfo.clearStage === 2)
            sobj[elem.n] = 0;
        } else
          sobj[elem.n] = 0;
        this.setState(sobj);
      } catch (error) {
        console.log(`Clear Customer Field: ${elem.n} Error: ${error.message}`);
      }
    });
    this.setItemStateChanged(false);
    this.clearScreen = true;
  }
  closeLeftPane = () => {
    console.log("Close Left Pane");
    this.setState({ LeftO: false });
    if (this.state.RightO === false)
      this.setState({ horizontalPanes: this.horizSplitCC });
    else
      this.setState({ horizontalPanes: this.horizSplitCO });
    SessionInfo.setLPaneOpen = 1;
    SessionInfo.setLPaneClose = 2;
  }
  openLeftPane = () => {
    console.log("Open Left Pane");
    this.setState({ LeftO: true });
    if (this.state.RightO === false)
      this.setState({ horizontalPanes: this.horizSplitOC });
    else
      this.setState({ horizontalPanes: this.horizSplitOO });
    SessionInfo.setLPaneClose = 1;
    SessionInfo.setLPaneOpen = 2;
  }
  closeRightPane = () => {
    console.log("Close Right Pane");
    this.setState({ RightO: false });
    if (this.state.LeftO === false)
      this.setState({ horizontalPanes: this.horizSplitCC });
    else
      this.setState({ horizontalPanes: this.horizSplitOC });
    SessionInfo.setRPaneOpen = 1;
    SessionInfo.setRPaneClose = 2;
  }
  openRightPane = () => {
    console.log(`Open Right Pane - LeftO: ${this.state.LeftO}`);
    this.setState({ RightO: true });
    if (this.state.LeftO === false)
      this.setState({ horizontalPanes: this.horizSplitCO });
    else
      this.setState({ horizontalPanes: this.horizSplitOO });
    SessionInfo.setRPaneClose = 1;
    SessionInfo.setRPaneOpen = 2; // Set Markers in Header
  }
  //--------------- End of Standard Page Functions ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

  //------ Help Information -------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
  showHelp = async (key) => {
    if (this.state.showHelpListPopup == true) { // Close Help
      this.setState({ showHelpListPopup: false });
      this.setState({ showHelpDetail: false });
    } else {
      if (!key)
        key = '';
      this.setState({ showHelpDetail: false });
      let HelpPages = await getHelpTopics(key);
      //console.log("caller HelpPages: " + JSON.stringify(HelpPages));
      this.setState({ HelpPages });
      this.setState({ showHelpListPopup: true });
    }
  }
  closeHelpList = () => {
    this.setState({ showHelpListPopup: false });
  }
  onHelpItemClick = async (item) => {
    let helpDetail = await getHelpDetail(item.HelpPageID);
    this.setState({ helpDetail });
    this.setState({ showHelpDetail: true });
  }
  closeHelpTime = async () => {
    this.setState({ showHelpDetail: false });
  }
  onObjectHelp = async (objectID) => {
    let helpDetail = await getHelpDetail(objectID,2);
    this.setState({ helpDetail });
    this.setState({ showHelpDetail: true });
  }
  closeHelpTime = async () => {
    this.setState({ showHelpDetail: false });
  }
  // Help List Layout
  HelpPageHeader = () => {
    return (
      <ListViewHeader className='listHelpHeader' onClick={(e) => this.closeHelpList()} >
        <div className='listHeaderLeft' onClick={(e) => this.closeHelpList()}>
          <span className="listHelpTitle">{SessionInfo.HelpTitle}</span>
        </div>
        <div className='listHeaderRight' onClick={(e) => this.closeHelpList()}>
          <Button id="cancelButton" icon="cancel" look="outline" className="medButton" onClick={this.closeHelpList} >EXIT HELP</Button>
        </div>
      </ListViewHeader>
    );
  }
  HelpPageFormat = props => {
    let item = props.dataItem;
    return (
      <div>
        <div className="listHelpRow" onClick={(e) => this.onHelpItemClick(item)}>
          <div className="listColA">
            <span className="listHelpItemTitle" >{item.HelpTitle}&nbsp;&nbsp;&nbsp;</span>
            <div dangerouslySetInnerHTML={{ __html: item.HelpContent }}></div>
          </div>
        </div>
      </div>
    );
  }
  HelpPageFooter = () => {
    //const [state, onCheckout] = React.useContext(StateContext);
    return (
      <ListViewFooter className='listHelpFooter' onClick={(e) => this.closeHelpList()} >
        <div className='listHeaderRight' onClick={(e) => this.closeHelpList()}>
          <Button id="cancelButton" icon="cancel" look="outline" className="medButton" onClick={this.closeHelpList} >EXIT HELP</Button>
        </div>
      </ListViewFooter>
    );
  }
  ShowFieldHelp = async (key) => {
    console.log(`Field Help - Key: ${key}`);
    this.onObjectHelp(key);
    //this.showHelp(key);
    //console.log(`Field Help: ${JSON.stringify(item)}`);
  }
  //------ End of Help -------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
  CommandCell;
  //-------------------- Page Edit ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- 
  getCustomerRecords = async (search) => { // Get the available Customers
    console.log(`getCustomerRecords - search: ${search}, PermLevel: ${SessionInfo.PermLevel}`);
    if (!search)
      search = '';
    let colList = [search,"FirstName","LastName","PhoneNumber","EmailAddress","ProvinceStateID"];
    if (SessionInfo.PermLevel < 493)
      console.log(`call GetCustomerList - search: ${search}`);
    let CD = await CallOM("GetCustomerList",0,0,CTYP.STRA,colList,"",1);
    if (CD.x.o === 0) {
      LogOffResetMsg(`Web Session Has Failed in ${SessionInfo.currentPage} - Session: ${CD.x.s}`);
      this.props.navigate("/Login");
    } else if (CD.x.o < 9500) {
      try {
        const Customers = CD.d.rows.map(dataItem => Object.assign({ selected: false },dataItem));
        //console.log("Customers: " + JSON.stringify(Customers));
        this.setState({ Customers });
      } catch (error) {
        console.log(error.message);
      }
    }
  }
  getCustomerRecord = async (Key) => {
    //console.log("Get Customer Key: " + Key);
    //let CD = await GetTableRow(1020151, Key); // Save to OM   
    let CD = await CallOM("GetCustomerRecord",0,Key);
    //console.log(`Get Customer CD: ${JSON.stringify(CD)}`);   
    this.currentCustomerID = Key;
    this.setState({ CustomerID: Key });
    let row = CD.d;
    if (!row)
      this.props.navigate("/AppMain");
    // Retrieved Value Assignment for: Customers 
    CustomersO.forEach(elem => {
      if (elem.n in row) {
        //console.log(`Set ${elem.n}: ${row[elem.n]}`);
        let sobj = {};
        try {
          if (elem.t === 34 || elem.t === 35) { // Date, DateTime
            sobj[elem.n] = new Date(row[elem.n]); // For use with DateTime Control
            //sobj[elem.n] = row[elem.n];
            CV[elem.n] = new Date(row[elem.n]);
          } else if (elem.t === 21 || elem.t === 195 || elem.t === 201) { // String, FK-Translated, Enum
            sobj[elem.n] = row[elem.n];
            let sobjS = {};
            sobjS[elem.n + 'S'] = row[elem.n];
            //console.log(`Set String Field: ${elem.n + 'S'}, Value: ${ sobjS[elem.n + 'S']}`);
            this.setState(sobjS); // Set the String shadow var
            CV[elem.n] = row[elem.n];
          } else if (elem.t === 22) { // Bool
            let val = row[elem.n].toString().charAt();
            if (val === '1' || val === 't' || val === 'T') {
              sobj[elem.n] = true;
              CV[elem.n] = true;
            } else {
              sobj[elem.n] = false;
              CV[elem.n] = false;
            }
          } else if (elem.t === 23) { // Decimal
            if (elem.n.indexOf('Percent') >= 0 || elem.n.indexOf('Markup') >= 0) {
              sobj[elem.n] = row[elem.n];
              let sobjS = {};
              sobjS[elem.n + 'S'] = this.moneyFormat(row[elem.n]);
              this.setState(sobjS); // Set the String shadow var
              CV[elem.n] = row[elem.n];
            } else { // Decimal
              sobj[elem.n] = row[elem.n];
              CV[elem.n] = row[elem.n];
            }
          } else if (elem.t === 28) { // Money
            sobj[elem.n] = row[elem.n];
            let sobjS = {};
            sobjS[elem.n + 'S'] = this.moneyFormat(row[elem.n]);
            //console.log(`Set Money Field: ${elem.n + 'S'}, Value: ${ sobjS[elem.n + 'S']}`);
            this.setState(sobjS); // Set the String shadow var
            CV[elem.n] = row[elem.n];
          } else if (elem.t === 41) { // Media - Image or Document
            sobj[elem.n] = row[elem.n];
            CV[elem.n] = row[elem.n];
            if (elem.n === 'ImageID')
              currentImageID = row[elem.n];
          } else if (elem.t === 15) { // Encoded
            //------ Assign Encoded Values ------

            let AList = [];

            if (elem.n === 'CustomerConfig') {
              // Assignment for CustomerConfig
              let EncodeVal = row[elem.n];
              CustomerConfigFixedValues.forEach((ent) => {
                if ((EncodeVal & (0x001 << ent.k)) === (0x001 << ent.k))
                  AList.push(ent.n);
              });
              //this.setCustomerConfigBitEncoded(row[elem.n]);
            }
            if (elem.n === 'CustomerControls') {
              // Assignment for CustomerControls
              let EncodeVal = row[elem.n];
              CustomerControlsFixedValues.forEach((ent) => {
                if ((EncodeVal & (0x001 << ent.k)) === (0x001 << ent.k))
                  AList.push(ent.n);
              });
              //this.setCustomerControlsBitEncoded(row[elem.n]);
            }
            let sobjV = {};
            sobjV[`${elem.n}Vals`] = AList; // Set the Vals Array
            this.setState(sobjV); // Set the Vals var
            sobj[elem.n] = row[elem.n];
            //obj[elem.n] = row[elem.n]; - no obj exists --
            CV[elem.n] = row[elem.n];
            console.log(`Encoded sobj ${JSON.stringify(sobj)}, t: ${elem.t}, val: ${CV[elem.n]}`);
          } else { // Default - Number
            sobj[elem.n] = row[elem.n];
            CV[elem.n] = row[elem.n];
          }
          //console.log(`sobj ${JSON.stringify(sobj)}, t: ${elem.t}, val: ${CV[elem.n]}`);
          if (this.state[elem.n] === undefined)
            console.log(`=== ${elem.n} is not defined in state ===`);
          this.setState(sobj);
          // Add item specific code here
          //if (elem.n === 'ImageID')
          //  ImageID = row[elem.n];
        }
        catch (error) {
          console.log(`Set CustomersO Field: ${elem.n}, Error: ${error.message}`);
        }
      }
    });
    //----                         
    if (SessionInfo.PermLevel >= 499) {
      this.setState({ UserID: row["UserID"] });
      this.setState({ UserName: row["UserName"] });
    }
    this.setState({ selectedTopTab: 0 });
    this.setItemStateChanged(false);
    SessionInfo.Name = CV.FirstName + " " + CV.LastName;
    SessionInfo.headerTitle = SessionInfo.Name;
    this.saveEmailAddress = CV.EmailAddress;
    console.log(`CustomerControls: ${CV.CustomerControls}`);
    let actv = CV.CustomerControls & 0x00000002;
    this.setState({ accountActive: this.testBit(CV.CustomerControls,1) });
    this.setState({ accountHold: this.testBit(CV.CustomerControls,2) });
    this.setState({ accountHasCredit: this.testBit(CV.CustomerControls,5) });
    this.setState({ customerDirectPay: this.testBit(CV.CustomerControls,6) });
    this.setState({ customerWatch: this.testBit(CV.CustomerControls,7) });
    console.log(`getCustomer - PersonPhotoID: ${CV.PersonPhotoID}`);
    if (CV.PersonPhotoID > 0)
      await this.GetPersonPhotoImage(CV.PersonPhotoID);
    else
      await this.GetPersonPhotoImage(2);
    if (CV.CustomerOrderActive === true) {
      await this.GetCustomOrderForCustomer(Key);
    }
  }
  // Convert Encoded Bit-Values For CustomerConfig
  // ** Add to saveRecord  await getCustomerConfigBitEncoded()
  getCustomerConfigBitEncoded = async () => {
    let CustomerConfig = 0x0000000000000000;
    if (this.state.CustomerReset === true)
      CustomerConfig |= (0x001 << 1);
    if (this.state.DietVegetarian === true)
      CustomerConfig |= (0x001 << 5);
    if (this.state.DietVegan === true)
      CustomerConfig |= (0x001 << 6);
    if (this.state.DietNutAlergy === true)
      CustomerConfig |= (0x001 << 7);
    if (this.state.DietLactose === true)
      CustomerConfig |= (0x001 << 8);
    if (this.state.DietGluten === true)
      CustomerConfig |= (0x001 << 9);
    if (this.state.DietOther === true)
      CustomerConfig |= (0x001 << 12);
    await this.setState({ CustomerConfig });
  }
  // Set State Variables for Encoded Bit-Values For CustomerConfig
  // ** Add to getRecord  await setCustomerConfigBitEncoded(CustomerConfigVals)
  setCustomerConfigBitEncoded = async (CustomerConfigVals) => {
    if ((CustomerConfigVals & (0x001 << 1)) === (0x001 << 1))
      this.setState({ CustomerReset: true });
    else
      this.setState({ CustomerReset: false });
    if ((CustomerConfigVals & (0x001 << 5)) === (0x001 << 5))
      this.setState({ DietVegetarian: true });
    else
      this.setState({ DietVegetarian: false });
    if ((CustomerConfigVals & (0x001 << 6)) === (0x001 << 6))
      this.setState({ DietVegan: true });
    else
      this.setState({ DietVegan: false });
    if ((CustomerConfigVals & (0x001 << 7)) === (0x001 << 7))
      this.setState({ DietNutAlergy: true });
    else
      this.setState({ DietNutAlergy: false });
    if ((CustomerConfigVals & (0x001 << 8)) === (0x001 << 8))
      this.setState({ DietLactose: true });
    else
      this.setState({ DietLactose: false });
    if ((CustomerConfigVals & (0x001 << 9)) === (0x001 << 9))
      this.setState({ DietGluten: true });
    else
      this.setState({ DietGluten: false });
    if ((CustomerConfigVals & (0x001 << 12)) === (0x001 << 12))
      this.setState({ DietOther: true });
    else
      this.setState({ DietOther: false });
  }
  GetCustomOrderForCustomer = async (Key) => {
    console.log(`getCustomOrderForCustomer: ${Key}`);
    let CD = await CallOM("GetCustomOrderForCustomer",0,Key);
    console.log(`Custom Order: ${JSON.stringify(CD.d)}`);
    let row = CD.d;
    // Retrieved Value Assignment for: CustomOrders
    CustomOrdersO.forEach(elem => {
      if (elem.n in row) {
        console.log(`Set ${elem.n}: ${row[elem.n]}, type: ${elem.t}`);
        let sobj = {};
        try {
          if (elem.t === 34 || elem.t === 35) { // Date, DateTime
            sobj[elem.n] = new Date(row[elem.n]); // For use with DateTime Control
            //sobj[elem.n] = row[elem.n];
            COV[elem.n] = new Date(row[elem.n]);
          } else if (elem.t === 21 || elem.t === 195 || elem.t === 201) { // String, FK-Translated, Enum
            sobj[elem.n] = row[elem.n];
            let sobjS = {};
            sobjS[elem.n + 'S'] = row[elem.n];
            //console.log(`Set String Field: ${elem.n + 'S'}, Value: ${ sobjS[elem.n + 'S']}`);
            this.setState(sobjS); // Set the String shadow var
            COV[elem.n] = row[elem.n];
          } else if (elem.t === 22) { // Bool  
            let val = row[elem.n].toString().charAt();
            if (val === '1' || val === 't' || val === 'T') {
              sobj[elem.n] = true;
              COV[elem.n] = true;
            } else {
              sobj[elem.n] = false;
              COV[elem.n] = false;
            }
          } else if (elem.t === 28) { // Money
            sobj[elem.n] = row[elem.n];
            let sobjS = {};
            sobjS[elem.n + 'S'] = this.moneyFormat(row[elem.n]);
            //console.log(`Set Money Field: ${elem.n + 'S'}, Value: ${ sobjS[elem.n + 'S']}`);
            this.setState(sobjS); // Set the String shadow var
            COV[elem.n] = row[elem.n];
          } else if (elem.t === 41) { // Media - Image or Document
            sobj[elem.n] = row[elem.n];
            COV[elem.n] = row[elem.n];
            if (elem.n === 'ImageID')
              currentImageID = row[elem.n];
          } else if (elem.t === 15) { // Encoded
            //------ Assign Encoded Values ------
            let AList = [];
            let sobjV = {};
            sobjV[`${elem.n}Vals`] = AList; // Set the Vals Array
            this.setState(sobjV); // Set the Vals var
            sobj[elem.n] = row[elem.n];
            //obj[elem.n] = row[elem.n];
            COV[elem.n] = row[elem.n];
            //console.log(`sobj ${JSON.stringify(sobj)}, t: ${elem.t}, val: ${ECV[elem.n]});
          } else {
            sobj[elem.n] = row[elem.n];
            COV[elem.n] = row[elem.n];
          }
          //console.log(`sobj ${ JSON.stringify(sobj) },t: ${ elem.t },val: ${ COV[elem.n]}`);
          if (this.state[elem.n] === undefined)
            console.log(`$ === { elem.n } is not defined in state === `);
          this.setState(sobj);
          // Add item specific code here
          //if (elem.n === 'ImageID')
          //  ImageID = row[elem.n];
        }
        catch (error) {
          console.log(`Set CustomOrdersO Field: ${elem.n},Error: ${error.message}`);
        }
      }
    });
  }
  GetPersonPhotoImage = async (imageID) => {
    console.log(`get Person Image: ${imageID}`);
    const imageURL = await getImageFileFromServer(imageID);
    console.log(`Image URL: ${imageURL}`);
    this.setState({ PersonPhotoImageURL: imageURL });
  }
  onRemove = (event) => {
    console.log('onRemove: ',event.affectedFiles);
    this.setState({ files: event.newState });
  }
  onStatusChange = (event) => {
    console.log('onStatusChange: ',event.affectedFiles,event.serverResponse);
    console.log(`onStatusChange newState: ${JSON.stringify(event.newState)}`);
    //console.log(event.newState);
    let cnt = event.newState.length;
    event.newState[cnt - 1].status = 1;
    //console.log(event.newState);
    this.setState({ files: event.newState });
  }
  addPersonPhotoImage = async (event) => {
    try {
      const fileObj = event.affectedFiles[0].getRawFile();
      let imageType = getDocumentType(event.affectedFiles[0].extension,"Person Photo");
      let reader = new FileReader();
      reader.readAsArrayBuffer(fileObj);
      reader.onloadend = async () => {
        let imageFile = await Buffer.from(reader.result);
        this.setState({ PersonPhotoImageURL: URL.createObjectURL(fileObj) });
        let iVal = await saveImageFileToServer(imageFile,imageType,CustomersOID,this.currentCustomerID,1040572);
        this.setState({ PersonPhotoID: iVal });
      }
      event.target.value = null;
    }
    catch (error) {
      console.log(`addPersonPhotoImage Error: ${error}`);
    }
  };
  testBit = (controls,bitPos) => {
    let testBit = 0x01 << bitPos;
    let actv = controls & testBit;
    //console.log(`bitPos: ${bitPos}, actv: ${actv}`);
    return actv;
  }
  saveBit = (control,bitPos) => {
    let testBit = 0x01 << bitPos;
    //console.log(`bitPos: ${bitPos}, testBit: ${testBit}`);
    if (control === true)
      return testBit;
    else
      return 0;
  }
  // Select tabs
  //-------------------- End of Page Edit -----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
  //------------------------- Edit Grid ------------------------------------------------------------------
  enterInsert() {
    const dataItem = { inEdit: true };
    const allRecords = this.state.CFCustomers.slice();
    allRecords.unshift(dataItem); // Add to the beginning
    this.update(allRecords,dataItem);
    this.setState({ CFCustomers: allRecords });
  }
  enterEdit(dataItem) {
    this.update(this.state.CFCustomers,dataItem).inEdit = true;
    this.setState({ CFCustomers: this.state.CFCustomers.slice() });
  }
  save(dataItem) {
    dataItem.inEdit = undefined;
    if (dataItem.CustomerID === undefined)
      dataItem.CustomerID = 0;
    dataItem.CustomerID = this.update(this.state.CFCustomers,dataItem).CustomerID;
    //let index = this.state.CFCustomers.findIndex(p => p === dataItem || dataItem.CustomerID && p.CustomerID === dataItem.CustomerID);
    this.setState({ CFCustomers: this.state.CFCustomers.slice() });
    UpdateRow(543619,0,this.state.CFCustomers,dataItem,dataItem.CustomerID,"CustomerID"); // Save to OM
  }
  cancel(dataItem) {
    if (dataItem.CustomerID) {
      let originalItem = this.state.saveCFCustomers.find(p => p.CustomerID === dataItem.CustomerID)
      if (originalItem.inEdit) originalItem.inEdit = false;
      this.update(this.state.CFCustomers,originalItem);
    } else {
      this.update(this.state.CFCustomers,dataItem,!dataItem.CustomerID); // remove false
    }
    this.setState({ CFCustomers: this.state.CFCustomers.slice() });
  }
  remove(dataItem) {
    dataItem.inEdit = undefined;
    let key = dataItem.CustomerID;
    this.update(this.state.CFCustomers,dataItem,true);
    this.state.saveCFCustomers = this.state.CFCustomers;
    DeleteRowTry(543619,key); // Save to OM
    this.setState({ CFCustomers: this.state.CFCustomers.slice() });
  }
  itemChange(event) {
    const value = event.value;
    const name = event.field;
    if (!name) {
      return;
    }
    const updatedData = this.state.CFCustomers.slice();
    const item = this.update(updatedData,event.dataItem);
    item[name] = value;
    this.setState({ CFCustomers: updatedData });
  }
  update(data,item,remove) {  // data - is the entire data set (JSON), item - is the current line item
    let updated;
    //eslint-disable-next-line                   
    let index = data.findIndex(p => p === item || item.CustomerID && p.CustomerID === item.CustomerID); // Note - for new line will find 0
    if (index >= 0) {
      updated = Object.assign({},item);
      data[index] = updated;
    }
    if (remove) {
      data = data.splice(index,1);
    }
    return data[index];
  }
  //------------------------- Select ----------------------------------------------------------------------
  rowCustomerClick = async (event) => {
    const current = this.state.Customers.findIndex(dataItem => dataItem === event.dataItem);
    SessionInfo.setShowSave = 1; // Show Save in Header 
    SessionInfo.setShowNew = 1;
    this.state.Customers.forEach(item => item.selected = false);
    if (this.state.itemStateHasChanged === true) {
      if (SessionInfo.onChangeAskSave === true) {
        let okay = window.confirm(`Save Current Changes to: ${this.state.FirstName} ${this.state.LastName}?`);
        if (okay === false) {
          displayMessage("Changes to the Current Account Abandoned");
        }
        else {
          await this.saveRecord();
          //console.log("Row Saved");
        }
      }
      else {
        let okay = window.confirm(`Abandon Current Changes to: ${this.state.FirstName} ${this.state.LastName}?`);
        if (okay === false) {
          displayMessage("Row must be Saved First");
          return;
        }
        displayMessage("Changes to the Current Account Abandoned");
      }
    }
    this.state.Customers[current].selected = true;
    let CustomerID = this.state.Customers[current].CustomerID;
    this.lastSelectedPID = this.state.selectCustomerID;
    this.setState({ selectCustomerID: CustomerID });
    //console.log("Select Customer PKID: " + PKID);  
    this.setState({ verticalPanes: this.vertSplitC });
    await this.getCustomerRecord(CustomerID);
    this.forceUpdate();
    SessionInfo.currExcelExportFunc = this.exportExcel;
  }
  //------------------------- Field Edit ------------------------------------------------------------------  
  // Tab Selection                     
  handleTopTabSelect = async (e) => { // Tab Selection
    await this.selectTab(e.selected);
  }
  selectTab = async (tabNumber) => {
    //console.log("selectTab - TabN=" + tabNumber);
    if (this.state.selectCustomerID > 0) {
      SessionInfo.setExcelExport = 2; // Hide Export in Header  
      SessionInfo.setGridRefresh = 2; // Hide Grid Refresh in Header  
      SessionInfo.setShowSave = 1; // Show Save in Header 
      SessionInfo.setShowNew = 1;
      if (tabNumber === this.tabs.CustomerInfo)
        this.setState({ verticalPanes: this.vertSplitC });
      else if (tabNumber === this.tabs.Setup) {
        this.setState({ verticalPanes: this.vertSplitC });
      }
      else if (tabNumber === this.tabs.Receipts) { // Receipts
        SessionInfo.setExcelExport = 1; // Show Export in Header 
        SessionInfo.setGridRefresh = 1; // Show Grid Refresh in Header  
        SessionInfo.setShowSave = 2; // Hide Save in Header 
        SessionInfo.setShowNew = 2;
        SessionInfo.currRefreshGridFunc = this.searchCurrent;
        this.setState({ verticalPanes: this.vertSplitO });
        this.getCustomOrders('');
      }
      else if (tabNumber === this.tabs.Account) { // Customer Account - Note - not currently implemented
        this.setState({ verticalPanes: this.vertSplitC });
        await this.getCustomerAccount();
      }
      this.setState({ selectedTopTab: tabNumber })
    }
    else
      displayWarning("Customer must be Selected");
  }
  handleBottomTabSelect = async (e) => {
    this.setState({ selectedBottomTab: e.selected });
  }
  _exportCustomOrders;
  exportExcel = () => {
    this._exportCustomOrders.save();
  }
  async blurMaskFldVal(event,mask) {
    //console.log(`blur Mask: ${this.state[event.target.name]}`);
    this.setState({ [event.target.name]: UnmaskString(this.state[event.target.name],mask) });
    //console.log(`money target: ${this.state[event.target.name + 'S']}`);
  }
  setItemStateChanged = (changed) => {
    this.setState({ itemStateHasChanged: changed });
    if (changed === true) {
      SessionInfo.setShowSave = 1; // Show Save in Header  
      if (this.clearScreen === true) {
        this.clearScreen = false;
        SessionInfo.setShowNew = 1;
      }
    } else
      SessionInfo.setShowSave = 2; // Turn Off
  }
  chgFldValMask(event,mask) {
    //console.log(`chg masked value: ${event.target.value}, mask: ${mask}`);
    this.setState({ [event.target.name]: UnmaskString(event.target.value,mask) });
    //console.log(`chg masked - name: ${event.target.name}:${event.target.value}`);
    this.setItemStateChanged(true);
  }
  chgFldVal(event) {
    this.setState({ [event.target.name]: event.target.value });
    console.log(`name: ${event.target.name}:${event.target.value}`);
    this.setItemStateChanged(true);
  }
  async chgMoneyFldVal(event) {
    //var stateCopy = Object.assign({}, this.state[event.target.name]);
    let newVal = event.target.value.replace(/[^\d.-]/g,''); // Matches any single single character not in the bracket [] and replaces with ''  - https://www.infobyip.com/regularexpressioncalculator.php
    console.log(`money newval: ${newVal}, target: ${this.state[event.target.name]},S: ${this.state[event.target.name + 'S']}, name: ${event.target.name}`);
    if (this.state[event.target.name + 'S'].toString() === '0' && newVal > 9) {
      newVal /= 10;
    }
    this.setState({ [event.target.name + 'S']: newVal });
    await this.setState({ [event.target.name]: newVal });
    //console.log(`updated target: ${this.state[event.target.name]}, str: ${this.state[event.target.name + 'S']}`); 
    this.setItemStateChanged();
  }
  async focusMoneyFldVal(event) {
    console.log(`money target: ${this.state[event.target.name]}`);
    this.setState({ [event.target.name + 'S']: this.state[event.target.name] });
    console.log(`money target: ${this.state[event.target.name + 'S']}`);
  }
  async blurMoneyFldVal(event) {
    //console.log(`money target: ${this.state[event.target.name]}`);
    this.setState({ [event.target.name + 'S']: this.moneyFormat(this.state[event.target.name]) });
    //console.log(`money target: ${this.state[event.target.name + 'S']}`);
  }
  chgPercentFldVal(event) {
    //var stateCopy = Object.assign({}, this.state[event.target.name]);
    let newVal = event.target.value.replace(/[^\d.-]/g,'');
    //console.log("percent newval: " + newVal);
    newVal /= 100.0;
    this.setState({ [event.target.name]: newVal });
    this.setItemStateChanged(true);
  }
  chgDDFldVal = (event) => {
    this.setState({ [event.target.props.name]: event.target.value.ddID });
    this.setItemStateChanged(true);
  }
  chgCheckboxVal(event) {
    let setVal = true;
    if (this.state[event.target.name] === true)
      setVal = false;
    this.setState({ [event.target.name]: setVal });
    this.setItemStateChanged(true);
  }
  //------------------------- Field Edit ------------------------------------------------------------------ 
  customersPageChange = (event) => {
    this.setState({ customersSkip: event.page.skip,customersTake: event.page.take });
  }
  onLayoutChange = (event) => {
    console.log("horizontal:Layout Change");
    this.setState({ horizontalPanes: event.newState });
  }
  onVerticalLayoutChange = (event) => {
    //console.log("vertical: " + JSON.stringify(updatedState));
    this.setState({ verticalPanes: event.newState });
  }
  onHorizontalLayoutChange = (event) => {
    console.log("horizontal layout change");
    this.setState({ horizontalPanes: event.newState });
  }
  //------------------------- Customer Order List ------------------------------------------------------------------

  moneyFormat = (value) => new Intl.NumberFormat('en-US',{ style: 'currency',currency: 'USD' }).format(value);

  getCustomOrders = async (search) => { // Get Receipts
    if (!search)
      search = '';
    console.log(`Get CustomOrders - ID: ${this.currentCustomerID}`);
    let CD = await GetTableSearch(545072,search + "*CustomerID=" + this.currentCustomerID);
    //console.log("CustomOrders: " + JSON.stringify(CD.d));
    try {
      const CustomOrders = CD.d.rows.map(dataItem => Object.assign({ selected: false },dataItem));
      this.setState({ CustomOrders });
    } catch (error) {
      console.log("Failure in getCustomOrders - Error: " + error.message);
    }
  }
  customOrderRowClick = async (event) => { // Get Receipt Details & Display
    const current = this.state.CustomOrders.findIndex(dataItem => dataItem === event.dataItem);
    console.log("CustomOrder Click - current: " + current);
    if (current >= 0) {
      SessionInfo.SelectCustomOrderID = this.state.CustomOrderID;
      SessionInfo.CustomOrderID = this.state.CustomOrders[current].CustomOrderID;
      let CD = await GetTableRowTrans(545072,SessionInfo.CustomOrderID);
      console.log("Get Order Record : " + JSON.stringify(CD.d));
      // Fill in Custom Order Fields
      this.compUpdate = false;
      let row = CD.d.row;
      // Retrieved Value Assignment for: CustomOrders
      CustomOrdersO.forEach(elem => {
        if (elem.n in row) {
          //console.log(`Set ${elem.n}: ${row[elem.n]}`);
          let sobj = {};
          try {
            if (elem.t === 34 || elem.t === 35) { // Date, DateTime
              sobj[elem.n] = new Date(row[elem.n]); // For use with DateTime Control
              //sobj[elem.n] = row[elem.n];
              COV[elem.n] = new Date(row[elem.n]);
            } else if (elem.t === 21 || elem.t === 195 || elem.t === 201) { // String, FK-Translated, Enum
              sobj[elem.n] = row[elem.n];
              let sobjS = {};
              sobjS[elem.n + 'S'] = row[elem.n];
              //console.log(`Set String Field: ${elem.n + 'S'}, Value: ${ sobjS[elem.n + 'S']}`);
              this.setState(sobjS); // Set the String shadow var
              COV[elem.n] = row[elem.n];
            } else if (elem.t === 22) { // Bool
              let val = row[elem.n].toString().charAt();
              if (val === '1' || val === 't' || val === 'T') {
                sobj[elem.n] = true;
                COV[elem.n] = true;
              } else {
                sobj[elem.n] = false;
                COV[elem.n] = false;
              }
            } else if (elem.t === 28) { // Money
              sobj[elem.n] = row[elem.n];
              let sobjS = {};
              sobjS[elem.n + 'S'] = this.moneyFormat(row[elem.n]);
              //console.log(`Set Money Field: ${elem.n + 'S'}, Value: ${ sobjS[elem.n + 'S']}`);
              this.setState(sobjS); // Set the String shadow var
              COV[elem.n] = row[elem.n];
            } else if (elem.t === 41) { // Media - Image or Document
              sobj[elem.n] = row[elem.n];
              COV[elem.n] = row[elem.n];
              if (elem.n === 'ImageID')
                currentImageID = row[elem.n];
            } else if (elem.t === 15) { // Encoded
              //------ Assign Encoded Values ------
              let AList = [];
              let sobjV = {};
              sobjV[`${elem.n}Vals`] = AList; // Set the Vals Array
              this.setState(sobjV); // Set the Vals var
              sobj[elem.n] = row[elem.n];
              //obj[elem.n] = row[elem.n];
              COV[elem.n] = row[elem.n];
              console.log(`Encoded sobj ${JSON.stringify(sobj)}, t: ${elem.t}, val: ${COV[elem.n]}`);
            } else { // Default - Number
              sobj[elem.n] = row[elem.n];
              COV[elem.n] = row[elem.n];
            }
            //console.log(`sobj ${JSON.stringify(sobj)}, t: ${elem.t}, val: ${COV[elem.n]}`);
            if (this.state[elem.n] === undefined)
              console.log(`=== ${elem.n} is not defined in state ===`);
            this.setState(sobj);
            // Add item specific code here
            //if (elem.n === 'ImageID')
            //  ImageID = row[elem.n];
          }
          catch (error) {
            console.log(`Set CustomOrdersO Field: ${elem.n}, Error: ${error.message}`);
          }
        }
      });
      //console.log("Fields Done ");
      this.setItemStateChanged(false);
      this.setState({ selectedBottomTab: 0 });
      //this.setState({ verticalPanes: this.vertSplitO });
      await this.getCustomOrderItems();
      this.openRightPane();
      this.compUpdate = true;
      this.forceUpdate();
      //console.log("Final Done ");
    }
  }
  getCustomOrderItems = async (search) => {
    if (!search)
      search = '';
    console.log(`Get CustomOrderItems - CustomOrderID=${COV.CustomOrderID}`);
    let CD = await GetTableSearch(545113,search + `*CustomOrderID=${COV.CustomOrderID}`,"StoreItemID,Quantity,TotalPrice");
    //.log("CustomOrderItems: " + JSON.stringify(CD.d));
    try {
      const CustomOrderItems = CD.d.rows.map(dataItem => Object.assign({ selected: false },dataItem));
      //console.log("CustomOrderItems: " + CustomOrderItems);
      this.setState({ CustomOrderItems });
      await this.setState({ customOrderItemsCount: CustomOrderItems.length });
    } catch (error) {
      console.log("Failure in getCustomOrderItems - Error: " + error.message);
    }
  }
  customOrdersPageChange = (event) => {
    this.setState({ customOrdersSkip: event.page.skip,customOrdersTake: event.page.take });
  }
  //------------------------- Get Customer Account ------------------------------------------------------------------
  getCustomerAccount = async (event) => {
    console.log("Get Customer Account: " + this.state.CustomerID);
    let CD = await GetTableRowRaw(545756,this.state.CustomerID);
    //console.log("after Get: " + JSON.stringify(CD));
    for (var prop in CD.d.row) {
      if (prop in this.state) {
        //console.log("Set " + prop + ": " + CD.d.row[prop]);
        let obj = {};
        obj[prop] = CD.d.row[prop];
        if (prop.indexOf("LastUsedOn") >= 0) {
          obj[prop] = new Date(CD.d.row[prop]); //new Date()
        }
        this.setState(obj);
      }
    }
    this.setState({ verticalPanes: this.vertSplitC });
  }
  onGiveCreditAmount = async () => {
    if (this.state.CreditAmount === 0)
      displayWarning("A Credit Amount must be provided.")
    else {
      displayMessage("Give Credit Amount (" + this.state.CreditAmount + ") to " + this.state.FirstName + " : " + this.state.LastName);
      let CD = await CallOM("GiveCreditAmount",0,this.state.CustomerID,CTYP.DEC,this.state.CreditAmount);
      if (CD.x.o === 0) {
        LogOffResetMsg(`Web Session Has Failed in ${SessionInfo.currentPage} - Session: ${CD.x.s}`);
        this.props.navigate("/Login");
      } else if (CD.x.o < 9500) {
        try {
          displayMessage(CD.m);
          console.log("Amount : " + JSON.stringify(CD));
        } catch (error) {
          console.log(error.message);
        }
        await this.getCustomerAccount();
      }
    }
  }
  onUseCreditAmount = async () => {
    let Amount = 0;
    if (this.state.CreditAmount === 0)
      displayWarning("An Amount must be provided.")
    else {
      displayMessage("Decrease Credit Amount (" + this.state.CreditAmount + ") to " + this.state.FirstName + " : " + this.state.LastName);
      let CD = await CallOM("UseCreditAmount",0,this.state.CustomerID,CTYP.DEC,this.state.CreditAmount);
      if (CD.x.o === 0) {
        LogOffResetMsg(`Web Session Has Failed in ${SessionInfo.currentPage} - Session: ${CD.x.s}`);
        this.props.navigate("/Login");
      } else if (CD.x.o < 9500) {
        try {
          displayMessage(CD.m);
          console.log("Amount : " + JSON.stringify(CD));
          Amount = CD.d
        } catch (error) {
          console.log(error.message);
        }
        await this.getCustomerAccount();
      }
    }
  }
  onSendAMessage = async () => {
    this.setState({ showSendAMessage: true });
  }
  onSendTheMessage = async () => {
    console.log(`Send Message: ${this.state.SendMessage}, to: ${this.currentCustomerID}`);
    await CallOM("AddCustomerNotification",this.state.NotificationTypeID,this.currentCustomerID,CTYP.STR,this.state.SendMessage,'',2);
    this.setState({ showSendAMessage: false });
  }
  changePassword = async () => {
    this.setState({ showChangePassword: true });
  }
  createLogonToken = async () => {
    console.log("currentCustomerID: " + this.state.CustomerID);
    let CD = await CallOM("CreateUserToken",0,this.state.CustomerID,0);
    console.log("Response: " + JSON.stringify(CD));
    displayMessage(`Token: ${CD.d}`);
  }
  onChangeThePassword = async () => {
    if (this.state.UserID === 0 || this.state.newPassword === '') {
      displayMessage(`Select User and Specify valid Password`);
      this.setState({ showChangePassword: false });
    } else {
      console.log(`Change Password for: ${this.state.UserID}, to: ${this.newPassword}`);
      let retCD = await CallOM("ChangePassword",0,this.state.UserID,CTYP.STR,this.state.newPassword,'',2);
      console.log("Response: " + JSON.stringify(retCD));
      if (retCD.d == true)
        displayMessage(`Password Change Successful`);
      else
        displayError(`Password Change NOT Successful`);
      this.setState({ showChangePassword: false });
    }
  }
  clearChangePasswordPopup = async () => {
    this.setState({ showChangePassword: false });
  }
  //------------------------- Render ------------------------------------------------------------------
  render() {
    return (
      <div id="CustomerProfile" className="pageMain">
        <PageSide />
        <PageHeader L1='Home' Select='4' PageTitle='User Profile' IsApp='y' ItemName='User' />
        <div id="mainCntr">
          <Splitter style={{ height: '100%',border: 'none' }} panes={this.state.horizontalPanes} orientation={'horizontal'} onChange={this.onHorizontalLayoutChange}>
            <div id="splitterLeft" className="splitPaneContent">
              <TGrid style={{ position: 'absolute',top: 0,height: '100%' }} data={this.state.Customers.slice(this.state.customersSkip,this.state.customersTake + this.state.customersSkip)}
                skip={this.state.customersSkip} take={this.state.customersTake} total={this.state.Customers.length} onPageChange={this.customersPageChange} onRowClick={this.rowCustomerClick} resizable={true} selectedField="selected"
                pageable={{ info: true,type: 'numeric',pageSizes: true,previousNext: true }} pageSize={20}>
                <Column field="FirstName" title="First Name" width="160px" />
                <Column field="LastName" title="Last Name" width="160px" />
              </TGrid>
            </div>
            {/* Middle Component is 2 Vertical Panes */}
            <Splitter panes={this.state.verticalPanes} orientation={'vertical'} onChange={this.onVerticalLayoutChange}>
              <div id="splitterMiddle" className="splitPaneContent">
                <TabStrip selected={this.state.selectedTopTab} onSelect={this.handleTopTabSelect} className="tabStrip">
                  <TabStripTab title="Personal Information">
                    <div className="editTab">
                      <div className="editTabLeftNarrow">
                        <div id="div1" className="editInside">
                          <h4>Contact Information</h4>
                          <br />
                          <div id="div2" className="editField">
                            <span className="editFieldLabel">First Name</span>
                            <input value={this.state.FirstName} name="FirstName" onChange={evt => this.chgFldVal(evt)} className="editInput" />
                          </div>
                          <div id="div2" className="editField">
                            <span className="editFieldLabel">Last Name</span>
                            <input value={this.state.LastName} name="LastName" onChange={evt => this.chgFldVal(evt)} className="editInput" />
                          </div>
                          <div id="div2" className="editField">
                            <span className="editFieldLabel">Email Address</span>
                            <input value={this.state.EmailAddress} name="EmailAddress" onChange={evt => this.chgFldVal(evt)} className="editInput" />
                          </div>
                          <div id="div2" className="editField">
                            <span className="editFieldLabel">Address</span>
                            <input value={this.state.Address} name="Address" onChange={evt => this.chgFldVal(evt)} className="editInput" />
                          </div>
                          <div id='div2' className='editField'>
                            <span className='editFieldLabel'>City</span>
                            <input value={this.state.City} name='City' onChange={evt => this.chgFldVal(evt)} className='editInput' />
                          </div>
                          <div id='div2' className='editField'>
                            <span className='editFieldLabel'>State/Province</span>
                            <div className='editDDPos'>
                              <DropDownList data={this.state.ProvinceStateCodesDict} textField='ddName' dataItemKey='ddID' value={this.state.ProvinceStateCodesDict.find(c => c.ddID === this.state.ProvinceStateID)} name='ProvinceStateID' onChange={evt => this.chgDDFldVal(evt)} className='editInputDD' />
                            </div>
                          </div>
                          <div id='div2' className='editField'>
                            <span className='editFieldLabel'>Country</span>
                            <div className='editDDPos'>
                              <DropDownList data={this.state.CountriesDict} textField='ddName' dataItemKey='ddID' value={this.state.CountriesDict.find(c => c.ddID === this.state.CountryID)} name='CountryID' onChange={evt => this.chgDDFldVal(evt)} className='editInputDD' />
                            </div>
                          </div>
                          <div id="div2" className="editField">
                            <span className="editFieldLabel">Phone Number</span>
                            <MaskedTextBox value={MaskString(this.state.PhoneNumber,"(###) ###-####")} name='PhoneNumber' onChange={evt => this.chgFldValMask(evt,"(###) ###-####")} mask="(999) 000-0000" className='editPhoneInput' />
                          </div>
                          <div id="div3" className="editField">
                            <span className="editFieldLabel">Second Phone</span>
                            <MaskedTextBox value={MaskString(this.state.PhoneNumber2,"(###) ###-####")} name='PhoneNumber2' onChange={evt => this.chgFldValMask(evt,"(###) ###-####")} mask="(999) 000-0000" className='editPhoneInput' />
                          </div>
                          <br />
                          {SessionInfo.PermLevel > 493 ?
                            <div id='div2' className='editDiv'>
                              <h4>Employee Information</h4>
                              <br />
                              <div id='div2' className='editField'>
                                <span className='editFieldLabel'>Store Location</span>
                                <div className='editDDPos'>
                                  <DropDownList data={this.state.StoreLocationsDict} textField='ddName' dataItemKey='ddID' value={this.state.StoreLocationsDict.find(c => c.ddID === this.state.StoreLocationID)} name='StoreLocationID' onChange={evt => this.chgDDFldVal(evt)} className='editInputDD' />
                                </div>
                              </div>
                              <div id='div2' className='editField'>
                                <span className='editFieldLabel'>Sublocation</span>
                                <div className='editDDPos'>
                                  <DropDownList data={this.state.SublocationsDict} textField='ddName' dataItemKey='ddID' value={this.state.SublocationsDict.find(c => c.ddID === this.state.SublocationID)} name='SublocationID' onChange={evt => this.chgDDFldVal(evt)} className='editInputDD' />
                                </div>
                              </div>
                              <br />
                            </div> : null}
                          {this.state.CustomerOrderActive ?
                            <div className='editDivBlue'>
                              <h4>Current Order Information</h4>
                              <br />
                              <div id="div2" className="editField">
                                <span className="editFieldLabel">Order Number</span>
                                <input value={this.state.CustomOrderID} name="CustomOrderID" onChange={evt => this.chgFldVal(evt)} className="editNumShortInputRO" readOnly="{true}" />
                              </div>
                              <div id="div2" className="editField">
                                <span className="editFieldLabel">Order Status</span>
                                <input value={this.state.CustomOrderStatus} name="CustomOrderStatus" onChange={evt => this.chgFldVal(evt)} className="editInputMedium" readOnly="{true}" />
                              </div>
                              <div id="div2" className="editField">
                                <span className="editFieldLabel">Order Creation</span>
                                <input value={this.state.OrderCreationDate < new Date(2000,1,1) ? "" : new Intl.DateTimeFormat("en-CA",{ dateStyle: "long",timeStyle: "short" }).format(this.state.OrderCreationDate)} name="OrderCreationDate" onChange={evt => this.chgFldVal(evt)} className="editInput" readOnly="{true}" />
                              </div>
                              <div id="div2" className="editField">
                                <span className="editFieldLabel">Total Price</span>
                                <input value={this.moneyFormat(this.state.TotalPrice)} name="TotalPrice" onChange={evt => this.chgMoneyFldVal(evt)} className="editMoneyInputRO" readOnly="{true}" />
                              </div>
                              <div id="div2" className="editField">
                                <span className="editFieldLabel">Total Amount Paid</span>
                                <input value={this.moneyFormat(this.state.AmountPaid)} name="AmountPaid" onChange={evt => this.chgMoneyFldVal(evt)} className="editMoneyInputRO" readOnly="{true}" />
                              </div>
                            </div> : null}
                        </div>
                      </div>
                      <div className="editTabMiddle">
                        <div id="div1" className="editInside">
                          <h4>Activity and Limits</h4>
                          <br />
                          <div className="editField">
                            <span className="editFieldLabel">Transaction Count</span>
                            <input value={this.state.TransactionCount} name="TransactionCount" readOnly={true} className="editNumShortInputRO" />
                          </div>
                          <div className="editField">
                            <span className="editFieldLabel">Abandoned Sales</span>
                            <input value={this.state.AbandonedSalesCount} name="AbandonedSalesCount" readOnly={true} className="editNumShortInputRO" />
                          </div>
                          <div className="editField">
                            <span className="editFieldLabel">Total Purchases</span>
                            <input value={this.moneyFormat(this.state.TotalPurchaseAmount)} name="TotalPurchaseAmount" readOnly={true} className="editMoneyInputRO" />
                          </div>
                          <div id='div2' className='editField'>
                            <span className='editFieldLabel'>Purchases Today</span>
                            <input value={this.moneyFormat(this.state.TodayPurchaseAmount)} name='TodayPurchaseAmount' readOnly={true} className="editMoneyInputRO" />
                          </div>
                          <div id='div2' className='editField'>
                            <span className='editFieldLabel'>Last Time Active</span>
                            <div className='editDDPos'>
                              <DateTimePicker value={new Date(this.state.LastTimeActive)} name='LastTimeActive' onChange={evt => this.chgFldVal(evt)} className='editDateInput' />
                            </div>
                          </div>
                          {/*<div id='div2' className='editField'>
                              <span className='editFieldLabel'>Last Store Visited</span>
                              <input value={this.state.LastStoreVisitedID} name='LastStoreVisitedID' onChange={evt => this.chgFldVal(evt)} className='editNumShortInput' />
                            </div>
                            <div id='div2' className='editField'>
                              <span className='editFieldLabel'>Purchase Frequency</span>
                              <input value={this.state.PurchaseFrequency} name='PurchaseFrequency' onChange={evt => this.chgFldVal(evt)} className='editNumShortInput' />
                            </div> */}
                          <div id='div2' className='editField'>
                            <br />
                            <h4>Limits and Permission</h4>
                            <br />
                            <div className="editField">
                              <span className="editFieldLabel">Max Purchase Amnt</span>
                              <input value={this.state.MaximumPurchaseAmountS} name="MaximumPurchaseAmount" onChange={evt => this.chgMoneyFldVal(evt)} onFocus={evt => this.focusMoneyFldVal(evt)} onBlur={evt => this.blurMoneyFldVal(evt)} className="editMoneyInput" />
                            </div>
                            <div className="editField">
                              <span className="editFieldLabel">Job/Role</span>
                              <div className="editDDPos">
                                <DropDownList data={this.state.Roles} textField="ddName" dataItemKey="ddID" value={this.state.Roles.find(c => c.ddID === this.state.RoleID)} name="RoleID" onChange={evt => this.chgDDFldVal(evt)} className="editInputDD" />
                              </div>
                            </div>
                            {(SessionInfo.PermLevel >= 499) ?
                              <>
                                <br />
                                <div id='div2' className='editField'>
                                  <span className='editFieldLabel'>Customer ID</span>
                                  <input value={this.state.CustomerID} name='CustomerID' onChange={evt => this.chgFldVal(evt)} readOnly={true} className='editNumInputRO' />
                                </div>
                                <div id='div2' className='editField'>
                                  <span className='editFieldLabel'>User Name</span>
                                  <input value={this.state.UserName} name='UserName' onChange={evt => this.chgFldVal(evt)} readOnly={true} className='editInputRO' />
                                </div>
                                <div id='div2' className='editField'>
                                  <span className='editFieldLabel'>User ID</span>
                                  <input value={this.state.UserID} name='UserID' onChange={evt => this.chgFldVal(evt)} readOnly={true} className='editNumInputRO' />
                                </div>
                                <br />
                                <div className="editField">
                                  <Button icon="tell-a-friend" className="medButton" onClick={this.changePassword}>Change Password</Button>
                                </div>
                                <br />
                                <div className="editField">
                                  <Button icon="link-vertical" className="medButton" onClick={this.createLogonToken}>Create Logon Token</Button>
                                </div>
                              </> : null}
                            <br />
                            <div className="editField">
                              <Button icon="tell-a-friend" className="medButton" onClick={this.onSendAMessage}>Send A Message</Button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="editTabRightNarrow">
                        <div id="div1" className="editInside">
                          <h4>Status Information</h4>
                          <br />
                          <div id="div2" className="editField">
                            <Switch checked={this.state.accountActive} name="accountActive" onChange={evt => this.chgCheckboxVal(evt)} />
                            <span className="editFieldLabel"> &nbsp; Account Active</span>
                          </div>
                          <div id="div2" className="editField">
                            <Switch checked={this.state.accountHold} name="accountHold" onChange={evt => this.chgCheckboxVal(evt)} />
                            <span className="editFieldLabel"> &nbsp; Account On Hold</span>
                          </div>
                          <div id="div2" className="editField">
                            <Switch checked={this.state.customerDirectPay} readOnly={true} name="directPay" />
                            <span className="editFieldLabel"> &nbsp; Auto Pay</span>
                          </div>
                          <div id="div2" className="editField">
                            <Switch checked={this.state.customerWatch} name="customerWatch" readOnly={true} />
                            <span className="editFieldLabel"> &nbsp; Account Watch</span>
                          </div>
                          <div id="div2" className="editField">
                            <Switch checked={this.state.CustomerIsInStore} readOnly={true} name="CustomerIsInStore" />
                            <span className="editFieldLabel"> &nbsp; Customer In Store</span>
                          </div>
                          <div id="div2" className="editField">
                            <Switch checked={this.state.CustomerIsActive} readOnly={true} name="CustomerIsActive" />
                            <span className="editFieldLabel"> &nbsp; Customer Is Active</span>
                          </div>
                          <div id="div2" className="editField">
                            <Switch checked={this.state.CustomerOrderActive} readOnly={true} name="CustomerOrderActive" />
                            <span className="editFieldLabel"> &nbsp; Customer Order Is Active</span>
                          </div>
                          <div id="div2" className="editField">
                            <Switch checked={this.state.MultiPersonOrder} readOnly={true} name="MultiPersonOrder" />
                            <span className="editFieldLabel"> &nbsp; Multi Person Order</span>
                          </div>
                          <div id="div2" className="editField">
                            <Switch checked={this.state.PaymentInProcess} readOnly={true} name="PaymentInProcess" />
                            <span className="editFieldLabel"> &nbsp; Payment In Process</span>
                          </div>
                          <div id="div2" className="editField">
                            <Switch checked={this.state.CustomerHasPaid} readOnly={true} name="CustomerHasPaid" />
                            <span className="editFieldLabel"> &nbsp; Customer Has Paid</span>
                          </div>
                          <div id="div2" className="editField">
                            <Switch checked={this.state.HasAbandonedOrder} readOnly={true} name="HasAbandonedOrder" />
                            <span className="editFieldLabel"> &nbsp; Has Abandoned Order</span>
                          </div>
                          <div id="div2" className="editField">
                            <Switch checked={this.state.HasExceededMax} readOnly={true} name="HasExceededMax" />
                            <span className="editFieldLabel"> &nbsp; Has Exceeded Max</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </TabStripTab>
                  <TabStripTab title="Setup">
                    <div className="editTab">
                      <div className="editTabLeft">
                        <div id="div1" className="editInside">
                          <h4> &nbsp; &nbsp;Diet Preferences </h4>
                          <br />
                          <div id="div2" className="editField">
                            <Switch checked={this.state.DietVegetarian} name="DietVegetarian" onChange={evt => this.chgCheckboxVal(evt)} /><span className="editFieldLabel"> &nbsp; Vegetarian</span>
                          </div>
                          <div id="div2" className="editField">
                            <Switch checked={this.state.DietVegan} name="DietVegan" onChange={evt => this.chgCheckboxVal(evt)} /><span className="editFieldLabel"> &nbsp; Vegan</span>
                          </div>
                          <div id="div2" className="editField">
                            <Switch checked={this.state.DietNutAlergy} name="DietNutAlergy" onChange={evt => this.chgCheckboxVal(evt)} /><span className="editFieldLabel"> &nbsp; Nut Alergies</span>
                          </div>
                          <div id="div2" className="editField">
                            <Switch checked={this.state.DietLactose} name="DietLactose" onChange={evt => this.chgCheckboxVal(evt)} /><span className="editFieldLabel"> &nbsp; Lactose Intolerance</span>
                          </div>
                          <div id="div2" className="editField">
                            <Switch checked={this.state.DietGluten} name="DietGluten" onChange={evt => this.chgCheckboxVal(evt)} /><span className="editFieldLabel"> &nbsp; Gluten Free</span>
                          </div>
                          <div id="div2" className="editField">
                            <Switch checked={this.state.DietOther} name="DietOther" onChange={evt => this.chgCheckboxVal(evt)} /><span className="editFieldLabel"> &nbsp; Diet Other</span>
                          </div>
                          <br />
                          <h4>&nbsp;&nbsp;Settings</h4>
                          <br />
                          <div id="div2" className="editField">
                            <Switch checked={this.state.EmailReceipts} name="EmailReceipts" onChange={evt => this.chgCheckboxVal(evt)} />
                            <span className="editFieldLabel"> &nbsp; Email Receipts</span>
                          </div>
                          <div id="div2" className="editField">
                            <Switch checked={this.state.EmailPromotions} name="EmailPromotions" onChange={evt => this.chgCheckboxVal(evt)} />
                            <span className="editFieldLabel"> &nbsp; Email Promotions</span>
                          </div>
                          <div id="div2" className="editField">
                            <Switch checked={this.state.SMSReminders} name="SMSReminders" onChange={evt => this.chgCheckboxVal(evt)} />
                            <span className="editFieldLabel"> &nbsp; SMS Reminders</span>
                          </div>
                          <div id="div2" className="editField">
                            <Switch checked={this.state.SoundOn} name="SoundOn" onChange={evt => this.chgCheckboxVal(evt)} />
                            <span className="editFieldLabel"> &nbsp; Sound On</span>
                          </div>
                          <div id="div2" className="editField">
                            <Switch checked={this.state.AutoScanLocation} name="AutoScanLocation" onChange={evt => this.chgCheckboxVal(evt)} />
                            <span className="editFieldLabel"> &nbsp; AutoScan Location</span>
                          </div>
                          <div id='div2' className='editField'>
                            <Switch type='checkbox' checked={this.state.FlashOnScan} name='FlashOnScan' onChange={evt => this.chgCheckboxVal(evt)} />
                            <span className='editFieldLabel'> &nbsp; Flash On Scan</span>
                          </div>
                        </div>
                      </div>
                      <div className="editTabRight">
                        <div id="div1" className="editInside">
                          <h4>Profile Image</h4>
                          <br />
                          <br />
                          <div className="framePic">
                            <Avatar rounded='large' size='large' type='image' className='avatarFramePic'>
                              <img src={this.state.PersonPhotoImageURL} height={280} width={360} style={{ objectFit: 'fill',}} />
                            </Avatar>
                          </div>
                          <div className="framePicUpload">
                            <div style={{ position: "relative",top: "0px",width: "370px",border: "solid 2px grey" }}>
                              <Upload
                                ref={uploadPersonPhotoRef}
                                batch={false}
                                multiple={true}
                                files={this.state.files}
                                onAdd={this.addPersonPhotoImage}
                                onRemove={this.onRemove}
                                onStatusChange={this.onStatusChange}
                                saveUrl={null}
                                defaultFiles={[]}
                                withCredentials={false}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </TabStripTab>
                  <TabStripTab title="Sales Receipts">
                    <div className="editTab">
                      <ExcelExport data={this.state.CustomOrders} ref={(exporter) => { this._exportCustomOrders = exporter; }}>
                        <TGrid style={{ position: 'absolute',top: 0,height: '100%' }} data={orderBy(filterBy(this.state.CustomOrders,this.state.receiptFilter),this.state.receiptSort).slice(this.state.customOrdersSkip,this.state.customOrdersTake + this.state.customOrdersSkip)}
                          skip={this.state.customOrdersSkip} take={this.state.customOrdersTake} total={this.state.CustomOrders.length} onPageChange={this.customOrdersPageChange}
                          onRowClick={this.customOrderRowClick} resizable={true} sortable={true} sort={this.state.receiptSort} onSortChange={(e) => { this.setState({ receiptSort: e.sort }); }}
                          pageable={{ info: true,type: 'numeric',pageSizes: true,previousNext: true }} pageSize={14}>
                          {/*filterable filter={this.state.receiptFilter} onFilterChange={(e) => { this.setState({ receiptFilter: e.filter }); }}*/}
                          {/*<GridToolbar>
                            <button title="Export to Excel" className="k-button k-primary" onClick={this.exportExcel}>Export to Excel</button>&nbsp;
                          </GridToolbar>*})
                          {/*Column field="CustomOrderID" title="Order Id" filter="numeric" width='1px' />*/}
                          <Column field="StoreLocationID" title="Location" width='200px' />
                          <Column field="CustomOrderStatus" title="Status" width='170px' />
                          <Column field="OrderCreationDate" title="Order Date" width='220px' filter='date' cell={cellDateTime} />
                          <Column field="OrderPaidDate" title="Paid Date" width='220px' filter='date' cell={cellDateTime} />{/*editor="date" format="{0:D}" /> format="{0:D}"*/}
                          <Column field="AmountOwing" title="Amount" width='110px' filter='numeric' cell={cellCurrency} />
                          <Column field="TipAmount" title="Tip" width='110px' filter='numeric' cell={cellCurrency} />
                          <Column field="ItemCount" title="Items" width='110px' filter='numeric' />
                        </TGrid>
                      </ExcelExport>
                    </div>
                  </TabStripTab>
                  {this.state.accountHasCredit === true ?
                    <TabStripTab title="Account Information">
                      <div className="editTab">
                        <div className="editTabLeft">
                          <div id="div1" className="editInside">
                            <h4>Account Details</h4>
                            <div id="div2" className="editField">
                              <span className="editFieldLabel">Current Balance</span>
                              <input value={this.moneyFormat(this.state.Amount)} name="Amount" onChange={evt => this.chgMoneyFldVal(evt)} className="editMoneyInput" />
                            </div>
                            <div id="div2" className="editField">
                              <span className="editFieldLabel">Transaction Count</span>
                              <input value={this.state.TransactionCount} name="TransactionCount" onChange={evt => this.chgFldVal(evt)} className="editNumShortInput" />
                            </div>
                            <div id="div2" className="editField">
                              <span className="editFieldLabel">Minus Transactions</span>
                              <input value={this.state.MinusTransactions} name="MinusTransactions" onChange={evt => this.chgFldVal(evt)} className="editNumShortInput" />
                            </div>
                            <div id="div2" className="editField">
                              <span className="editFieldLabel">Plus Transactions</span>
                              <input value={this.state.PlusTransactions} name="PlusTransactions" onChange={evt => this.chgFldVal(evt)} className="editNumShortInput" />
                            </div>
                            <div id="div2" className="editField">
                              <span className="editFieldLabel">Total Minus Amount</span>
                              <input value={this.moneyFormat(this.state.TotalMinusAmount)} name="TotalMinusAmount" onChange={evt => this.chgMoneyFldVal(evt)} className="editMoneyInput" />
                            </div>
                            <div id="div2" className="editField">
                              <span className="editFieldLabel">Total Plus Amount</span>
                              <input value={this.moneyFormat(this.state.TotalPlusAmount)} name="TotalPlusAmount" onChange={evt => this.chgMoneyFldVal(evt)} className="editMoneyInput" />
                            </div>
                            <div id="div2" className="editField">
                              <span className="editFieldLabel">Maximum Balance</span>
                              <input value={this.moneyFormat(this.state.MaximumAmount)} name="MaximumAmount" onChange={evt => this.chgMoneyFldVal(evt)} className="editMoneyInput" />
                            </div>
                          </div>
                        </div>
                        <div className="editTabRight">
                          <div id="div1" className="editInside">
                            <h4>Control</h4>
                            <div id="div2" className="editField">
                              <span className="editFieldLabel">Last Used On</span>
                              <input value={this.state.LastUsedOn < new Date(2000,1,1) ? "" : new Intl.DateTimeFormat("en-CA",{ dateStyle: "long",timeStyle: "short" }).format(this.state.LastUsedOn)} name="LastUsedOn" onChange={evt => this.chgFldVal(evt)} className="editInput" readOnly="{true}" />
                            </div>
                            <div className="editField">
                              <span className="editFieldLabel">Account Status</span>
                              <div className="editDDPos">
                                <DropDownList data={this.state.CustomerAccountStatusInfo} textField="ddName" dataItemKey="ddID" value={this.state.CustomerAccountStatusInfo.find(c => c.ddID === this.state.CustomerAccountStatus)} name="CustomerAccountStatus" onChange={evt => this.chgDDFldVal(evt)} className="editInputDD" />
                              </div>
                            </div>
                            <div className="editField">
                              <Button icon="plus-circle" className="smallButton" onClick={this.onGiveCreditAmount}>Give Credit</Button>
                            </div>
                            <div id="div2" className="editField">
                              <span className="editFieldLabel">Credit Amount</span>
                              <input value={this.moneyFormat(this.state.CreditAmount)} name="CreditAmount" onChange={evt => this.chgMoneyFldVal(evt)} className="editMoneyInput" />
                            </div>
                            <div className="editField">
                              <Button icon="dollar" className="smallButton" onClick={this.onUseCreditAmount}>Use Credit</Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </TabStripTab> : null}
                </TabStrip>
              </div>
              {/*----------------------- BOTTOM PANE ----------------------------------------------------------------------------------------------------------------------*/}
              <div id="splitterBottom" className="splitPaneContent">
                {this.state.selectedBottomTab === 0 ?
                  <div className="editTab">
                    <div className="editTabLeft">
                      <div id="div1" className="editInside">
                        <h4>Receipt Control</h4>
                        <div id="div2" className="editField">
                          <span className="editFieldLabel">Customer</span>
                          <input value={this.state.CustomerID} name="CustomerID" onChange={evt => this.chgFldVal(evt)} className="editInput" readOnly="{true}" />
                        </div>
                        <div id='div2' className='editField'>
                          <span className='editFieldLabel'>Store Location</span>
                          <input value={this.state.StoreLocationID} name="StoreLocationID" onChange={evt => this.chgFldVal(evt)} className="editInput" readOnly="{true}" />
                        </div>
                        <div id="div2" className="editField">
                          <span className="editFieldLabel">Order Status</span>
                          <input value={this.state.CustomOrderStatus} name="CustomOrderStatus" onChange={evt => this.chgFldVal(evt)} className="editInput" readOnly="{true}" />
                        </div>
                        <div id="div2" className="editField">
                          <span className="editFieldLabel">Order Type</span>
                          <input value={this.state.CustomOrderType} name="CustomOrderType" onChange={evt => this.chgFldVal(evt)} className="editInput" readOnly="{true}" />
                        </div>
                        <div id="div2" className="editField">
                          <span className="editFieldLabel">Order Creation Date</span>
                          <input value={this.state.OrderCreationDate < new Date(2000,1,1) ? "" : new Intl.DateTimeFormat("en-CA",{ dateStyle: "long",timeStyle: "short" }).format(this.state.OrderCreationDate)} name="OrderCreationDate" onChange={evt => this.chgFldVal(evt)} className="editInput" readOnly="{true}" />
                        </div>
                        <div id="div2" className="editField">
                          <span className="editFieldLabel">Order Paid Date</span>
                          <input value={this.state.OrderPaidDate < new Date(2000,1,1) ? "" : new Intl.DateTimeFormat("en-CA",{ dateStyle: "long",timeStyle: "short" }).format(this.state.OrderPaidDate)} name="OrderPaidDate" onChange={evt => this.chgFldVal(evt)} className="editInput" readOnly="{true}" />
                        </div>
                        {/*<div className="editField">*/}
                        {/*  <span className="editFieldLabel">Payment Method</span>*/}
                        {/*  <input value={this.state.PaymentMethodID} name="PaymentMethodID" onChange={evt => this.chgFldVal(evt)} className="editInput" readOnly="{true}" />*/}
                        {/*</div>*/}
                        <div id='div2' className='editField'>
                          <span className='editFieldLabel'>Paid Comment</span>
                          <textarea value={this.state.PaidComment} name='PaidComment' onChange={evt => this.chgFldVal(evt)} className='editTAInput' />
                        </div>
                        {/*<br />*/}
                        {/*<br />*/}
                        {/*<div id='div2' className='editField'>*/}
                        {/*  <span className='editFieldLabel'>Comments</span>*/}
                        {/*  <textarea value={this.state.OrderNotes} name='OrderNotes' onChange={evt => this.chgFldVal(evt)} className='editTAInput' />*/}
                        {/*</div>*/}
                        <br />
                        <h4>Delivery</h4>
                        <div id='div2' className='editField'>
                          <span className='editFieldLabel'>Address</span>
                          <textarea value={this.state.Address} name='Address' onChange={evt => this.chgFldVal(evt)} className='editTAInput' />
                        </div>
                        <br />
                        <br />
                        <div id='div2' className='editField'>
                          <span className='editFieldLabel'>Phone Number</span>
                          <MaskedTextBox value={MaskString(this.state.PhoneNumber,"(###) ###-####")} name='PhoneNumber' onChange={evt => this.chgFldValMask(evt,"(###) ###-####")} mask="(999) 000-0000" className='editPhoneInput' />
                        </div>
                      </div>
                    </div>
                    <div className="editTabRight">
                      <div id="div1" className="editInside">
                        <h4>Receipt Totals</h4>
                        <div id="div2" className="editField">
                          <span className="editFieldLabel">Total Amount</span>
                          <input value={this.moneyFormat(this.state.AmountPaid)} name="AmountPaid" onChange={evt => this.chgMoneyFldVal(evt)} className="editMoneyInput" readOnly="{true}" />
                        </div>
                        <div className="editField">
                          <span className="editFieldLabel">Total Tax1 Amount</span>
                          <input value={this.moneyFormat(this.state.TotalTaxAmount)} name="TotalTaxAmount" onChange={evt => this.chgMoneyFldVal(evt)} className="editMoneyInput" readOnly="{true}" />
                        </div>
                        <div className="editField">
                          <span className="editFieldLabel">Total Tax2 Amount</span>
                          <input value={this.moneyFormat(this.state.TotalTax2Amount)} name="TotalTax2Amount" onChange={evt => this.chgMoneyFldVal(evt)} className="editMoneyInput" readOnly="{true}" />
                        </div>
                        {/*<div id="div2" className="editField">*/}
                        {/*  <span className="editFieldLabel">Total Amount</span>*/}
                        {/*  <input value={this.moneyFormat(this.state.AmountPaid)} name="AmountPaid" onChange={evt => this.chgMoneyFldVal(evt)} className="editMoneyInput" readOnly="{true}" />*/}
                        {/*</div>*/}
                        <div className="editField">
                          <span className="editFieldLabel">Tip Amount</span>
                          <input value={this.moneyFormat(this.state.TipAmount)} name="TipAmount" onChange={evt => this.chgMoneyFldVal(evt)} className="editMoneyInput" readOnly="{true}" />
                        </div>
                        <div id="div2" className="editField">
                          <span className="editFieldLabel">Amount Paid</span>
                          <input value={this.moneyFormat(this.state.AmountPaidWithTip)} name="AmountPaidWithTip" onChange={evt => this.chgMoneyFldVal(evt)} className="editMoneyInput" readOnly="{true}" />
                        </div>
                        <br />
                        {/*<div id="div2" className="editField">*/}
                        {/*  <span className="editFieldLabel">Total Price</span>*/}
                        {/*  <input value={this.moneyFormat(this.state.TotalPrice)} name="TotalPrice" onChange={evt => this.chgMoneyFldVal(evt)} className="editMoneyInput" readOnly="{true}" />*/}
                        {/*</div>*/}
                        <div id="div2" className="editField">
                          <span className="editFieldLabel">Discount Amount</span>
                          <input value={this.moneyFormat(this.state.DiscountAmount)} name="DiscountAmount" onChange={evt => this.chgMoneyFldVal(evt)} className="editMoneyInput" readOnly="{true}" />
                        </div>
                        <div className="editField">
                          <span className="editFieldLabel">Credit Amount Used</span>
                          <input value={this.moneyFormat(this.state.CreditAmountUsed)} name="CreditAmountUsed" onChange={evt => this.chgMoneyFldVal(evt)} className="editMoneyInput" readOnly="{true}" />
                        </div>
                        <div className="editField">
                          <span className="editFieldLabel">Refund Amount</span>
                          <input value={this.moneyFormat(this.state.RefundAmount)} name="RefundAmount" onChange={evt => this.chgMoneyFldVal(evt)} className="editMoneyInput" readOnly="{true}" />
                        </div>
                      </div>
                    </div>
                  </div> : null}
              </div>
            </Splitter>
            {/* Side Pane */}
            <div id="splitterRight" className="splitPaneContent">
              {this.state.selectedTopTab === this.tabs.Receipts ?
                <TGrid style={{ position: 'absolute',top: 0,height: '100%' }} data={this.state.CustomOrderItems.slice(this.state.customOrderItemsSkip,this.state.customOrderItemsTake + this.state.customOrderItemsSkip)} selectedField="selected" sortable={{ allowUnsort: true,mode: 'multiple' }}
                  skip={this.state.customOrderItemsSkip} take={this.state.customOrderItemsTake} total={this.state.CustomOrderItems.length} onPageChange={this.selectionCustomOrderItemsChange}
                  onRowClick={this.rowStoreItemClick} selectedField="selected" resizable={true} sort={this.state.sortCustomOrderItems} onSortChange={(event) => { this.setState({ CustomOrderItems: this.getCustomOrderItemsSort(event.sort),customOrderItemsSort: event.sort }); }}
                  pageable={{ info: true,type: 'numeric',pageSizes: true,previousNext: true }}
                  pageSize={18}>
                  <Column field="selected" width="0px" headerSelectionValue={this.state.CustomOrderItems.findIndex(dataItem => dataItem.selected === false) === -1} />
                  <Column field="StoreItemID" title="Item Name" width='200px' />
                  <Column field="Quantity" title="Qty" width='60px' />
                  <Column field="TotalPrice" title="Price" cell={MoneyCell} width='120px' />
                </TGrid> : null}
            </div>
          </Splitter>
          {/* Help Popup */}
          <Popup offset={this.helpListPopupOffset} show={this.state.showHelpListPopup} popupClass={'popupHelp'} >
            <div id="helpBox">
              <div className="helpList">
                <ListView data={this.state.HelpPages} item={this.HelpPageFormat} header={this.HelpPageHeader} footer={this.HelpPageFooter} />
              </div>
              <br />
            </div>
          </Popup>
          <Popup offset={this.helpDetailOffset} show={this.state.showHelpDetail} popupClass={'popupHelpDetail'} onClick={this.closeHelpTime}>
            <div className="frameInfo" id="notifyBoxPU" dangerouslySetInnerHTML={{ __html: this.state.helpDetail }} onClick={this.closeHelpTime}></div>
          </Popup>
          {/*----- Message ------*/}
          <Popup offset={this.sendMessagePopupOffset} show={this.state.showSendAMessage} popupClass={'popupMessageDetail'}>
            <div id='div2' className='editTAHField'>
              <h4>Message</h4>
              <textarea value={this.state.SendMessage} name='SendMessage' onChange={evt => this.chgFldVal(evt)} className='editTAHField' />
            </div>
            <div className="bottomButton">
              <Button icon="tell-a-friend" className="medButton" onClick={this.onSendTheMessage}>Send The Message</Button>
            </div>
            <div className="bottomEdit">
              <h4>Notification Type</h4>
              {/*<div className='editDDPos'>*/}
              <DropDownList data={this.state.NotificationTypesDict} textField='ddName' dataItemKey='ddID' value={this.state.NotificationTypesDict.find(c => c.ddID === this.state.NotificationTypeID)} name='NotificationTypeID' onChange={evt => this.chgDDFldVal(evt)} className='editInputDD' />
              {/*  </div>*/}
            </div>
          </Popup>
          <Popup show={this.state.showChangePassword} offset={this.changePWOffset} popupClass={'popupMessageDetail'}>
            <h4>Change Password for Current User</h4>
            <br />
            <div id='div2' className='editField'>
              <span className='editFieldLabel'>First Name</span>
              <input value={this.state.FirstName} name='FirstName' onChange={evt => this.chgFldVal(evt)} readOnly={true} className='editInputRO' />
            </div>
            <div id='div2' className='editField'>
              <span className='editFieldLabel'>User Name</span>
              <input value={this.state.UserName} name='UserName' onChange={evt => this.chgFldVal(evt)} readOnly={true} className='editInputRO' />
            </div>
            <div id='div2' className='editField'>
              <span className='editFieldLabel'>New Password</span>
              <input value={this.state.newPassword} name='newPassword' onChange={evt => this.chgFldVal(evt)} className='editInput' />
            </div>
            <br />
            <div className="editField">
              <Button icon="tell-a-friend" className="medButton" onClick={this.onChangeThePassword}>Set New Password</Button>  
              <span> &nbsp; &nbsp; &nbsp; &nbsp;  &nbsp; &nbsp; </span>
              <Button id="cancelButton" icon="cancel" look="outline" className="leftButton" onClick={this.clearChangePasswordPopup} >Cancel</Button>
            </div>
          </Popup>
        </div>
        {SessionInfo.displayFooter === true ?
          <PageFooter L1='Home' IsApp='y' /> : null}
      </div >
    );
  }
}

export default withRouter(CustomerProfile);