//----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// PageFooter - Standard Page Footer - Version 1.041- July 29, 2022
//----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
import React, { } from 'react';
import { Link } from 'react-router-dom'
import { SessionInfo } from './App';

class PageFooter extends React.Component { 
  state = {   
    appDisplay: false, 
  };           
  componentDidMount() { 
    if (this.props.IsApp || SessionInfo.IsApp === true)
      this.setState({ appDisplay: true });
  }
  render() {
    return (
      <div id="footerCntr">
        <div id="footTopStrip"></div> {/* Blue Line */}
        <div className="footermenu">

          <p>&nbsp;&nbsp;Interact Suite - Version: {SessionInfo.appVersion}-{SessionInfo.debugProd}-{SessionInfo.Platform} - &copy; Copyright <a href="https://omnovos.com/" target="_blank" rel="noopener noreferrer"> omNovos Inc. 2023</a> - All Rights Reserved</p> 
        </div>
        <div id="footerRight">  
          <div className="twitterbox">
            <a href="https://www.facebook.com/OmNovosGlobal/" target="_blank" rel="noopener noreferrer"><img src={require("./images/sub/facebook.png")} alt="" className="loginImage" width="20px"/></a>
            <a href="https://www.linkedin.com/company/omnovos/?trk=company_logo" target="_blank" rel="noopener noreferrer"> <img src={require("./images/sub/linkedin.png")} alt="" className="loginImage" /></a>
            <a href="https://twitter.com/omNovos_Global" target="_blank" rel="noopener noreferrer"> <img src={require("./images/sub/twitter.png")} alt="" className="loginImage" /></a>
          </div>
          <div className="linkbox">                                                                                       
            <Link to="/AppMain"><img src={require("./images/Home2.png")} alt="" className="loginImage" /></Link>
            <Link to="/AboutPage"><img src={require("./images/Info2.png")} alt="" className="loginImage" /></Link>
            <Link to="/SettingsPage"><img src={require("./images/Gear.png")} alt="" className="loginImage" /></Link>
            <Link to="/ContactUs"><img src={require("./images/WriteMessage.png")} alt="" className="loginImage" /></Link>
          </div>
        </div>
      </div>
    )
  }
}
      
export default PageFooter;