//----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// SamplePage - Interact Suite Customer  - Detailed information about the Customer, Customer settings, and information regarding subscribed projects
//                      Version 1.007 - June 5, 2019
//----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
import React, { Component } from 'react';
import { withRouter } from './withRouter';
import { Button } from '@progress/kendo-react-buttons';
import { SessionInfo } from './App';
import { CallConfirmation, GetTableRow, UpdateRow, DeleteRow, displayMessage, GetDropdownData, GetTableData, GetTableSearch, CreateKeys, LogOffResetMsg, OMTrace } from './CommonCode.js'; 

class Confirmation extends Component {
  //------------------------------------------------------------------------------------------------------
  constructor(props) {                                 
    super(props);
    console.log(`Constructor Confirmation Props: ${JSON.stringify(props)}`);
    //console.log(`History: ${JSON.stringify(this.props.history)}`);
    //console.log(`location: ${JSON.stringify(this.props.history.location)}`);
    //console.log(`search: ${JSON.stringify(this.props.history.location.search)}`);
    this.ConfirmationStr = SessionInfo.search;
  }
  //--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------  
  // State
  state = {
    CustomerID: 0,
    //--------------------------------

    string: '',
    imgWidth: 200,

    selectedTopTab: 0,
    ConfirmationID: '',
  };           
  ConfirmationStr = '';
  inputReference = React.createRef();
  createKeys;
  loadExistingPage = false;
  lastSelectedPID = 0;
  compUpdate = true;
  clearScreen = false; 
     
  async componentDidMount() {                     
    console.log(`Component Did Mount - Confirmation: ${this.ConfirmationStr}, isConfirmationOnly: ${SessionInfo.isConfirmationOnly}`);
    //let query = useQuery();
    //console.log(`Component Did Mount - query: ${query}`);
    if (!this.ConfirmationStr)
      OMTrace(2, `Confirmation - Key NOT Provided`);
    else {
      if (this.ConfirmationStr.substring(0, 1) === "?")
        this.ConfirmationStr = this.ConfirmationStr.substring(1);
      SessionInfo.displayHeader = false;
      SessionInfo.currentPage = "Confirmation";
      OMTrace(7, `Confirmation - Key: ${this.ConfirmationStr}`)
      let Msg = await CallConfirmation(270, this.ConfirmationStr);
      if (Msg) {
        console.log("CallConfirmation Return - Msg: " + Msg);
      }
      else {
        console.log("CallConfirmation NULL Return");
      }
    }
    SessionInfo.isConfirmationOnly = false; // Set this to avoid repeated confirmation mounts
  }
  componentWillUnmount() {
    console.log("Component unmount");
  }
  shouldComponentUpdate() {
    //console.log("Should Update?");
    if (this.compUpdate === true)
      return true;
    else
      return false;
  }
  //--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------  
  CommandCell;
  setItemStateChanged = (changed) => {
    this.setState({ itemStateHasChanged: changed });
    if (changed === true) {
      SessionInfo.setShowSave = 1; // Show Save in Header  
      if (this.clearScreen === true) {
        this.clearScreen = false;
        SessionInfo.setShowNew = 1;
      }
    } else
      SessionInfo.setShowSave = 2; // Turn Off
  }
  chgFldVal(event) {
    this.setState({ [event.target.name]: event.target.value });
    this.setItemStateChanged(true);
  }
  chgMoneyFldVal(event) {
    //var stateCopy = Object.assign({}, this.state[event.target.name]);
    let newVal = event.target.value.replace(/[^\d.-]/g, '');
    this.setState({ [event.target.name]: newVal });
  }
  chgPercentFldVal(event) {
    //var stateCopy = Object.assign({}, this.state[event.target.name]);
    let newVal = event.target.value.replace(/[^\d.-]/g, '');
    //console.log("percent newval: " + newVal);
    newVal /= 100.0;
    this.setState({ [event.target.name]: newVal });
    this.setItemStateChanged(true);
  }
  chgDDFldVal = (event) => {
    this.setState({ [event.target.props.name]: event.target.value.ddID });
    this.setItemStateChanged(true);
  }
  chgCheckboxVal(event) {
    let setVal = true;
    if (this.state[event.target.name] === true)
      setVal = false;
    this.setState({ [event.target.name]: setVal });
    this.setItemStateChanged(true);
  }
  gotoRegister(event) {
    console.log("Register");
  }

  //------------------------- Field Edit ------------------------------------------------------------------ 

  //------------------------- Render ------------------------------------------------------------------
  render() {
    return (
      <div id="SamplePage" className="pageMain">
        <div id="mainCntr">
          <div className="editTab">
            <div className="editTabLeft">
              <div id="div1" className="editInside">
                <h4>Thank you for Registering with Interact Suite from omNovos</h4>
                <div id="div3" className="editField">
                  {/*<label htmlFor="result" style={{ fontSize: 'large', color: 'black', fontWeight: 'bold', }}>Result:</label>*/}
                  <label id="result" style={{ fontSize: 'large', color: 'black', fontWeight: 'bold', }} />
                  <br />
                  <br />
                  {/*<pre><code id="result"></code></pre> */}
                  <Button variant="contained" color="primary" onClick={this.gotoRegister}>Click Here to Confirm Registration</Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >
    );
  }
}

export default withRouter(Confirmation);