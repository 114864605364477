//----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// StoreItemDetail - Item Detail Screen
//              Version 1.041- July 29, 2022
//----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
import React,{ Component } from 'react';
import { withRouter } from './withRouter';
import { Buffer } from 'buffer';
import { Grid as TGrid,GridColumn as Column } from '@progress/kendo-react-grid';
import { TabStrip,TabStripTab,Splitter } from '@progress/kendo-react-layout';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { orderBy,filterBy } from '@progress/kendo-data-query';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { Button } from '@progress/kendo-react-buttons';
import { Popup } from '@progress/kendo-react-popup';
import { ListView,ListViewHeader,ListViewFooter } from '@progress/kendo-react-listview';
import { ExternalDropZone,Upload } from "@progress/kendo-react-upload";
import { MultiSelect } from '@progress/kendo-react-dropdowns';
import { Switch } from "@progress/kendo-react-inputs";
import { Avatar } from '@progress/kendo-react-layout';
import { SessionInfo } from './App';
import PageHeader from './PageHeader.js';
import PageFooter from './PageFooter.js';
import PageSide from './PageSide.js';
import { CallOM,GetTableRow,GetTableSearchRaw,UpdateRow,reportError,DeleteRowForce,GetFileExtension,displayMessage,displayError,getHelpTopics,getHelpDetail,GetDropdownData,GetDependentDropdownData,saveImageFileToServer,getDocumentType,getImageFileFromServer,displayWarning,displayNotify,notify,LogOffResetMsg,OMTrace,trace,CTYP } from './CommonCode.js';
import { DaysOfWeekList,DayOfWeekDropDownCell,TimePickerCell,MoneyCell,BoolCell,ImageCell,PricingTypeDropDownCell,SetPricingType,SetTimeOfDay,TimeOfDayDropDownCell,SetDayOfWeek,SetProductItemCategory,ProductItemCategoryDropDownCell,SetProductItemGroup,ProductItemGroupDropDownCell,SetProductTypes,ProductTypeDropDownCell,SetItemAssociationTypes,ItemAssociationTypeDropDownCell,EditGridCommandCell } from './CommonFormat.js';
import { ENAppCnfg,ENLayoutCnfg } from './CommonInfo.js';

import { ReactComponent as HelpAbout } from "./images/help-about.svg";

const UploadInput = (fieldRenderProps) => {
  const onChangeHandler = (event) => {
    fieldRenderProps.onChange({ value: event.newState });
  };
  const onRemoveHandler = (event) => {
    fieldRenderProps.onChange({ value: event.newState });
  };
  return (
    <Upload
      autoUpload={false}
      showActionButtons={false}
      files={fieldRenderProps.value}
      onAdd={onChangeHandler}
      onRemove={onRemoveHandler}
    />
  );
};
// Note 195 is 'made up' indicates FK 
//------ Object Definition For StoreItems -- Date: 2024-04-04-1:59 PM ------
const StoreItemsOID = 544619;
const StoreItemsO = [{ n: 'StoreItemID',t: 195,p: 100 },{ n: 'StoreLocationID',t: 195,p: 100 },{ n: 'ReferenceItemID',t: 195,p: 100 },{ n: 'AssociatedItemID',t: 195,p: 100 },{ n: 'ItemName',t: 21,p: 0 },{ n: 'ItemDescription',t: 21,p: 0 },{ n: 'ItemFullDescription',t: 29,p: 0 },{ n: 'ItemNote',t: 21,p: 0 },{ n: 'ProductItemCategoryID',t: 195,p: 100 },{ n: 'ProductItemGroupID',t: 195,p: 100 },{ n: 'ProductItemTypeID',t: 195,p: 100 },{ n: 'BrandID',t: 195,p: 100 },{ n: 'UPCNumber',t: 21,p: 0 },{ n: 'BarcodeNumber',t: 21,p: 0 },{ n: 'ItemStatusID',t: 195,p: 100 },{ n: 'PackagingTypeID',t: 195,p: 100 },{ n: 'ItemSizeCodeID',t: 195,p: 100 },{ n: 'TotalQuantity',t: 16,p: 0 },{ n: 'TotalNetWeight',t: 16,p: 0 },{ n: 'ReservedQuantity',t: 16,p: 0 },{ n: 'LockedQuantity',t: 16,p: 0 },{ n: 'AvailableQuantity',t: 16,p: 0 },{ n: 'DocumentID',t: 41,p: 0 },{ n: 'ImageID',t: 41,p: 0 },{ n: 'ItemPrice',t: 28,p: 0 },{ n: 'OverridePrice',t: 28,p: 0 },{ n: 'PromotionPrice',t: 28,p: 0 },{ n: 'Promotion2Price',t: 28,p: 0 },{ n: 'PromotionType',t: 201,p: 0 },{ n: 'StorePromotionID',t: 195,p: 100 },{ n: 'ShelfLifeDays',t: 24,p: 0 },{ n: 'ItemNetWeight',t: 16,p: 0 },{ n: 'PricingTypeID',t: 195,p: 100 },{ n: 'TaxableTypeID',t: 195,p: 100 },{ n: 'RateTypeID',t: 195,p: 100 },{ n: 'SeasonCodeID',t: 195,p: 100 },{ n: 'DayOfWeek',t: 201,p: 0 },{ n: 'DaysOfWeek',t: 15,p: 0 },{ n: 'TimeOfDayID',t: 195,p: 100 },{ n: 'WeightUnitsCodeID',t: 195,p: 100 },{ n: 'InventoryControlTypeID',t: 201,p: 0 },{ n: 'StorageControlID',t: 195,p: 100 },{ n: 'ItemControl',t: 15,p: 0 },{ n: 'IngredientsText',t: 21,p: 0 },{ n: 'UsageInstructions',t: 21,p: 0 },{ n: 'Calories',t: 16,p: 0 },{ n: 'StoreInventoryLocationID',t: 195,p: 100 },{ n: 'TotalReceivedQuantity',t: 16,p: 0 },{ n: 'TotalSoldQuantity',t: 16,p: 0 },{ n: 'TotalDisposedQuantity',t: 16,p: 0 },{ n: 'TotalLostQuantity',t: 16,p: 0 },{ n: 'AssociatedItemCount',t: 24,p: 0 },{ n: 'IsRecordActive',t: 22,p: 0 }];
//       Object Value Definition For StoreItems
const SIV = { 'StoreItemID': 0,'StoreLocationID': 0,'ReferenceItemID': 0,'AssociatedItemID': 0,'ItemName': '','ItemDescription': '','ItemFullDescription': '','ItemNote': '','ProductItemCategoryID': 0,'ProductItemGroupID': 0,'ProductItemTypeID': 0,'BrandID': 0,'UPCNumber': '','BarcodeNumber': '','ItemStatusID': 0,'PackagingTypeID': 0,'ItemSizeCodeID': 0,'TotalQuantity': 0,'TotalNetWeight': 0,'ReservedQuantity': 0,'LockedQuantity': 0,'AvailableQuantity': 0,'DocumentID': '','ImageID': '','ItemPrice': 0,'OverridePrice': 0,'PromotionPrice': 0,'Promotion2Price': 0,'PromotionType': 0,'StorePromotionID': 0,'ShelfLifeDays': 0,'ItemNetWeight': 0,'PricingTypeID': 0,'TaxableTypeID': 0,'RateTypeID': 0,'SeasonCodeID': 0,'DayOfWeek': 0,'DaysOfWeek': 0,'TimeOfDayID': 0,'WeightUnitsCodeID': 0,'InventoryControlTypeID': 0,'StorageControlID': 0,'ItemControl': 0,'IngredientsText': '','UsageInstructions': '','Calories': 0,'StoreInventoryLocationID': 0,'TotalReceivedQuantity': 0,'TotalSoldQuantity': 0,'TotalDisposedQuantity': 0,'TotalLostQuantity': 0,'AssociatedItemCount': 0,'IsRecordActive': false };
//       Object Index Definition For StoreItems
const SIX = { 'StoreItemID': 0,'StoreLocationID': 1,'ReferenceItemID': 2,'AssociatedItemID': 3,'ItemName': 4,'ItemDescription': 5,'ItemFullDescription': 6,'ItemNote': 7,'ProductItemCategoryID': 8,'ProductItemGroupID': 9,'ProductItemTypeID': 10,'BrandID': 11,'UPCNumber': 12,'BarcodeNumber': 13,'ItemStatusID': 14,'PackagingTypeID': 15,'ItemSizeCodeID': 16,'TotalQuantity': 17,'TotalNetWeight': 18,'ReservedQuantity': 19,'LockedQuantity': 20,'AvailableQuantity': 21,'DocumentID': 22,'ImageID': 23,'ItemPrice': 24,'OverridePrice': 25,'PromotionPrice': 26,'Promotion2Price': 27,'PromotionType': 28,'StorePromotionID': 29,'ShelfLifeDays': 30,'ItemNetWeight': 31,'PricingTypeID': 32,'TaxableTypeID': 33,'RateTypeID': 34,'SeasonCodeID': 35,'DayOfWeek': 36,'DaysOfWeek': 37,'TimeOfDayID': 38,'WeightUnitsCodeID': 39,'InventoryControlTypeID': 40,'StorageControlID': 41,'ItemControl': 42,'IngredientsText': 43,'UsageInstructions': 44,'Calories': 45,'StoreInventoryLocationID': 46,'TotalReceivedQuantity': 47,'TotalSoldQuantity': 48,'TotalDisposedQuantity': 49,'TotalLostQuantity': 50,'AssociatedItemCount': 51,'IsRecordActive': 52 };

//------ Encoded Values - DaysOfWeek ------
const DaysOfWeekFixedValues = [{ n: 'Monday',k: 1 },{ n: 'Tuesday',k: 2 },{ n: 'Wednesday',k: 3 },{ n: 'Thursday',k: 4 },{ n: 'Friday',k: 5 },{ n: 'Saturday',k: 6 },{ n: 'Sunday',k: 7 }];
//------ Encoded Values - ItemControl ------
const ItemControlFixedValues = [{ n: 'Diet Modifiable',k: 4 },{ n: 'Diet Vegetarian',k: 5 },{ n: 'Diet Vegan',k: 6 },{ n: 'Diet Nut Alergy',k: 7 },{ n: 'Diet Lactose',k: 8 },{ n: 'Diet Gluten',k: 9 },{ n: 'Diet Other',k: 12 }];
//------ Encoded Entries - ItemControl ------
const ItemControlList = ['Diet Modifiable','Diet Vegetarian','Diet Vegan','Diet Nut Alergy','Diet Lactose','Diet Gluten','Diet Other'];

const uploadRef = React.createRef();

class StoreItemDetail extends Component {
  //------------------------------------------------------------------------------------------------------
  constructor(props) {
    super(props);
    if (SessionInfo.session === '')
      props.history.push("/AppMain");
    if (SessionInfo.StoreItemDetail) { // Data saved for this session
      this.state = SessionInfo.StoreItemDetail;
      this.loadExistingPage = true;
    }
    else {
      this.loadExistingPage = false;
    }
    //------------- Edit StoreItem Grid ------------------------------
    this.enterInsertSI = this.enterInsertSI.bind(this); // Establish binding to StoreItemDetail (this)
    this.itemChangeSI = this.itemChangeSI.bind(this);
    this.enterEditSI = this.enterEditSI.bind(this);
    this.saveSI = this.saveSI.bind(this);
    this.cancelSI = this.cancelSI.bind(this);
    this.removeSI = this.removeSI.bind(this);
    //const fileCheck = this.fileCheck.bind(this);
    this.StoreItemCommandCell = props => (<EditGridCommandCell {...props} edit={this.enterEditSI} remove={this.removeSI} add={this.saveSI} discard={this.removeSI} update={this.saveSI} cancel={this.cancelSI} editField='inEdit' keyField='StoreItemID' nameField='ItemDescription' />);
    //------------- Edit ProductSIOptionsGroup Grid ------------------------------
    this.enterInsertProductSIOptionsGroup = this.enterInsertProductSIOptionsGroup.bind(this); // Establish binding to ProductOptionsGroupDetail (this)
    this.itemChangeProductSIOptionsGroup = this.itemChangeProductSIOptionsGroup.bind(this);
    this.enterEditProductSIOptionsGroup = this.enterEditProductSIOptionsGroup.bind(this);
    this.saveProductSIOptionsGroup = this.saveProductSIOptionsGroup.bind(this);
    this.cancelProductSIOptionsGroup = this.cancelProductSIOptionsGroup.bind(this);
    this.removeProductSIOptionsGroup = this.removeProductSIOptionsGroup.bind(this);
    this.ProductSIOptionsGroupCommandCell = props => (<EditGridCommandCell {...props} edit={this.enterEditProductSIOptionsGroup} remove={this.removeProductSIOptionsGroup} add={this.saveProductSIOptionsGroup} discard={this.removeProductSIOptionsGroup} update={this.saveProductSIOptionsGroup} cancel={this.cancelProductSIOptionsGroup} editField='inEdit' keyField='ProductOptionsGroupID' nameField='ProductOptionsGroupName' />);
    //------------- Edit RelatedItems Grid ------------------------------
    this.enterInsertRelatedItems = this.enterInsertRelatedItems.bind(this); // Establish binding to RelateItemsDetail (this)
    this.itemChangeRelatedItems = this.itemChangeRelatedItems.bind(this);
    this.enterEditRelatedItems = this.enterEditRelatedItems.bind(this);
    this.saveRelatedItems = this.saveRelatedItems.bind(this);
    this.cancelRelatedItems = this.cancelRelatedItems.bind(this);
    this.removeRelatedItems = this.removeRelatedItems.bind(this);
    this.RelatedItemCommandCell = props => (<EditGridCommandCell {...props} edit={this.enterEditRelatedItems} remove={this.removeRelatedItems} add={this.saveRelatedItems} discard={this.removeRelatedItems} update={this.saveRelatedItems} cancel={this.cancelRelatedItems} editField='inEdit' keyField='RelatedStoreItemID' nameField='AssociatedItem' />);
  }
  //--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------  
  // State
  state = {
    //------ Integer Values - StoreItems ------
    StoreItemID: 0,
    StoreLocationID: 0,
    ReferenceItemID: 0,
    AssociatedItemID: 0,
    ProductItemCategoryID: 0,
    ProductItemGroupID: 0,
    ProductItemTypeID: 0,
    ImageID: 0,
    BrandID: 0,
    ItemStatusID: 0,
    PackagingTypeID: 0,
    ItemSizeCodeID: 0,
    TotalQuantity: 0,
    TotalNetWeight: 0,
    ReservedQuantity: 0,
    LockedQuantity: 0,
    AvailableQuantity: 0,
    ItemPrice: 0,
    ItemPriceS: '',
    OverridePrice: 0,
    OverridePriceS: '',
    PromotionPrice: 0,
    PromotionPriceS: '',
    Promotion2Price: 0,
    Promotion2PriceS: '',
    PromotionType: 0,
    ShelfLifeDays: 0,
    ItemNetWeight: 0,
    PricingTypeID: 0,
    TaxableTypeID: 0,
    RateTypeID: 0,
    SeasonCodeID: 0,
    DayOfWeek: 0,
    DaysOfWeek: 0,
    TimeOfDayID: 0,
    WeightUnitsCodeID: 0,
    StorageControlID: 0,
    StoreInventoryLocationID: 0,
    TotalReceivedQuantity: 0,
    TotalSoldQuantity: 0,
    TotalDisposedQuantity: 0,
    TotalLostQuantity: 0,
    IsRecordActive: 0,
    ItemControl: 0,
    //------ Encoded Bit Values For ItemControl -------
    DietModifiable: false,
    DietVegetarian: false,
    DietVegan: false,
    DietNutAlergy: false,
    DietLactose: false,
    DietGluten: false,
    DietOther: false,
    Calories: 0,
    Calories: 0,
    //------ Integer Values - ProductOptionsGroups ------
    ProductOptionsGroupID: 0,
    IsRequired: 0,
    AllowMultiple: 0,
    MultipleCount: 0,
    //------ Integer Values - ProductItemOptions ------
    ProductItemOptionID: 0,
    Price: 0,
    Quantity: 0,
    Weight: 0,

    HelpPages: [],
    showHelpDetail: false,
    showHelpListPopup: false,

    string: '',
    //------ String Values - StoreItems ------
    ItemName: '',
    ItemDescription: '',
    ItemFullDescription: '',
    ItemNote: '',
    UPCNumber: '',
    BarcodeNumber: '',
    IngredientsText: '',
    UsageInstructions: '',
    //------ String Values - ProductOptionsGroups ------
    ProductOptionsGroupName: '',
    //------ String Values - ProductItemOptions ------
    ProductOptionName: '',

    selectedTopTab: 0,
    itemStateHasChanged: false,

    PricingMarkupPercent: 0,
    itemFilter: { logic: "and",filters: [] },
    itemSort: [{ "field": "ItemName","dir": "asc" }],
    ProductItemPhotoURL: undefined,

    StoreItems: [],
    storeItemsSkip: 0,
    storeItemsTake: 16,

    MainStoreItems: [],
    mainStoreItemsSkip: 0,
    mainStoreItemsTake: 18,
    mainStoreItemsCount: 0,

    relatedItemFilter: { logic: "and",filters: [] },
    relatedItemSort: [],

    RelatedItems: [],
    RelatedItemSkip: 0,
    relatedItemsTake: 16,

    DaysOfWeekVals: [],
    DaysOfWeekEntries: [],

    ProductItemCategories: [],
    ProductItemGroups: [],
    ProductItemTypes: [],
    ItemAssociationTypes: [],
    Brands: [],
    ItemStatusSet: [],
    PackagingTypes: [],
    PricingTypes: [],
    RateTypes: [],
    PromotionTypes: [],
    TaxableTypes: [],
    UOMWeights: [],
    StorageControls: [],
    StoreLocationID: 0,
    StoreLocations: [],
    SeasonCodesDict: [],
    DayOfWeekDict: [],
    DaysOfWeekVals: [],
    TimeOfDayDict: [],
    StorageControlsDict: [],
    StoreInventoryLocationsDict: [],
    InventoryControlTypeIDs: [],
    //-- ProductOptionsGroup Data, Save, Page and Filter ------
    ProductSIOptionsGroups: [],
    ProductSIOptionsGroupsSkip: 0,
    ProductSIOptionsGroupsTake: 8,
    saveProductSIOptionsGroups: [],
    ProductSIOptionsGroupFilter: { logic: "and",filters: [] },
    ProductSIOptionsGroupSort: [],
    //-- ProductOptionsGroup Data, Save, Page and Filter ------
    ProductOptionsGroupsAV: [],
    ProductOptionsGroupsAVSkip: 0,
    ProductOptionsGroupsAVTake: 8,
    saveProductOptionsGroupsAV: [],
    ProductOptionsGroupAVFilter: { logic: "and",filters: [] },
    ProductOptionsGroupAVSort: [],
    //-- ProductOptionsGroup Data, Save, Page and Filter ------
    ProductOptionsGroups: [],
    ProductOptionsGroupsSkip: 0,
    ProductOptionsGroupsTake: 8,
    saveProductOptionsGroups: [],
    ProductOptionsGroupFilter: { logic: "and",filters: [] },
    ProductOptionsGroupSort: [],
    ProductOptionPhoto: 0, // This is not set
    ProductOptionPhotoURL: undefined,
    //--
    fullTake: 16,
    halfTake: 8,
    files: [],
    UploadFile: undefined,
    ImageType: "",
    DocumentTypeID: 0,
    //--------------------------------
    LeftO: false,
    RightO: false,
    // Initial Settings for Splits - see selectTab
    horizontalPanes: [{ size: '70%',min: '20px',collapsible: false },{ min: '100px',collapsed: true,collapsible: true },{ size: '20%',collapsed: true,collapsible: true }], // Left, Middle, Right
    verticalPanes: [{ min: '300px' },{ size: '20%',collapsible: true }],  // Top, Bottom   
    //---------------------------------
  };
  helpListPopupOffset = { left: 150,top: 60 };
  helpDetailOffset = { left: 200,top: 120 };

  //StoreItemsOID = 544619;
  horizSplitOC = JSON.parse('[{"0":"[","collapsible":false,"collapsed":false,"resizable":true,"scrollable":true,"size":"70%"},{"0":"{","collapsible":false,"collapsed":false,"resizable":true,"scrollable":true}, { "collapsible": true, "collapsed": true, "resizable": true, "scrollable": true, "size": "20%" }]');
  horizSplitCC = JSON.parse('[{"0":"[","collapsible":false,"collapsed":false,"resizable":true,"scrollable":true,"size":"15%"},{"0":"{","collapsible":false,"collapsed":false,"resizable":true,"scrollable":true}, { "collapsible": true, "collapsed": true, "resizable": true, "scrollable": true, "size": "20%" }]');
  horizSplitCO = JSON.parse('[{"0":"[","collapsible":false,"collapsed":false,"resizable":true,"scrollable":true,"size":"50px"},{"0":"{","collapsible":false,"collapsed":false,"resizable":true,"scrollable":true}, { "collapsible": true, "collapsed": false, "resizable": true, "scrollable": true, "size": "20%" }]');
  horizSplitOO = JSON.parse('[{"0":"[","collapsible":false,"collapsed":false,"resizable":true,"scrollable":true,"size":"20%"},{"0":"{","collapsible":false,"collapsed":false,"resizable":true,"scrollable":true}, { "collapsible": true, "collapsed": false, "resizable": true, "scrollable": true, "size": "20%" }]');
  verticalPanesOpen = [{ min: '600px' },{ size: '30%',collapsed: false,collapsible: true }];  // Top, Open Bottom
  verticalPanesAll = [{ min: '600px' },{ size: '30%',collapsed: true,collapsible: true }];  // Top, Collapsed Bottom

  helpListPopupOffset = { left: 150,top: 60 };
  helpDetailOffset = { left: 200,top: 120 };

  StoreItemOID = 544619;
  ProductOGLinksOID = 546454;
  ProductOptionsGroupsOID = 545034;
  ProductItemOptionsOID = 545039;
  RelatedItemsOID = 547087;
  loadExistingPage = false;
  rolesUpdated = false;
  fileReady = false;
  file = undefined;
  takeLines = 16;
  compUpdate = true;
  clearScreen = false;
  lastCommandSave = true;
  autoAddCount = 0;
  isAutoAdd = false;

  tabs = {
    ItemInfo: 0,
    AdditionalInfo: 1,
    ProductOptions: 2,
    RelatedItems: 3
  }

  async componentDidMount() {
    try {
      if (!SessionInfo.session || SessionInfo.session === '') {
        console.log(`forceLogoff set in StoreItemDetail - go to Login`);
        this.props.navigate("/Login");
      } else {
        let windowHt = window.innerHeight;
        let takeLinesD = (windowHt - 200) / 48.0;
        this.takeLines = parseInt(takeLinesD);
        this.setState({ fullTake: this.takeLines });
        console.log("Window Height: " + windowHt + " Lines: " + this.takeLines);
        this.setState({ storeItemsTake: this.takeLines });
        this.setState({ relatedItemsTake: this.takeLines });
        let takeLinesHalf = this.takeLines / 2;
        takeLinesHalf = parseInt(takeLinesHalf);
        this.setState({ halfTake: takeLinesHalf });
        this.setState({ mainStoreItemsTake: this.takeLines });
        this.setState({ ProductSIOptionsGroupsTake: this.takeLines });
        this.setState({ ProductOptionsGroupsAVTake: this.takeLines });
        this.setState({ ProductOptionsGroupsTake: this.takeLines });
        this.setState({ ProductItemOptionsTake: this.takeLines });
        SessionInfo.currentPage = "StoreItemDetail";
        SessionInfo.currSaveFunc = this.saveRecord;
        SessionInfo.currDeleteFunc = this.deleteRecord;
        SessionInfo.currClearFunc = this.clearRecord; // Add Record
        SessionInfo.setShowNew = 1;
        SessionInfo.currAddGridRowFunc = undefined;
        SessionInfo.currExcelExportFunc = undefined;
        SessionInfo.currShowHelp = this.showHelp;
        SessionInfo.currExpandLPane = this.openLeftPane;
        SessionInfo.currCollapseLPane = this.closeLeftPane;
        SessionInfo.searchFunc = this.searchCurrent;
        SessionInfo.searchTitle = "Search Items";
        if (this.loadExistingPage === true)
          this.forceUpdate();
        else { // Load Dictionaries
          console.log("Start Dictionary Load");
          if (this.state.PricingTypes.length <= 1) {
            let CD = await GetDropdownData(1104167);
            //console.log("PricingTypes: " + JSON.stringify(CD.d));
            this.setState({ PricingTypes: CD.d });
            SetPricingType(CD.d);
          }
          else
            SetPricingType(this.state.PricingTypes); // May be replaced in ManagerPage  
          console.log("ProductItemCategories");
          if (this.state.ProductItemCategories.length <= 1 || SessionInfo.ProductItemCategoriesUpdate === true) {
            let CD = await GetDropdownData(1104137);
            //console.log("ProductItemCategories: " + JSON.stringify(CD.d));
            this.setState({ ProductItemCategories: CD.d });
            SetProductItemCategory(CD.d);
            SessionInfo.ProductItemCategoriesUpdate = false;
          }
          else
            SetProductItemCategory(this.state.ProductItemCategories); // May be replaced in ManagerPage  
          //console.log("ProductItemGroups");
          if (this.state.ProductItemGroups.length <= 2 || SessionInfo.ProductItemGroupUpdate === true) { // Note - Dependent Drop Down - requires special flag
            let CD = await GetDropdownData(1104119);
            //console.log("ProductItemGroups: " + JSON.stringify(CD.d));
            this.setState({ ProductItemGroups: CD.d });
            SetProductItemGroup(CD.d);
            SessionInfo.ProductItemGroupUpdate = false;
          }
          else
            SetProductItemGroup(this.state.ProductItemGroups); // May be replaced in ManagerPage  
          //console.log("ProductItemTypes");
          if (this.state.ProductItemTypes.length <= 1 || SessionInfo.ProductItemTypeUpdate === true) {
            let CD = await GetDropdownData(1104125);
            //console.log("ProductItemTypes: " + JSON.stringify(CD.d));
            this.setState({ ProductItemTypes: CD.d });
            SetProductTypes(CD.d);
            SessionInfo.ProductItemTypeUpdate = false;
          }
          else
            SetProductTypes(this.state.ProductItemTypes); // May be replaced in ManagerPage  
          //console.log("ProductItemTypes");
          if (this.state.ItemAssociationTypes.length <= 1 || SessionInfo.ItemAssociationTypeUpdate === true) {
            let CD = await GetDropdownData(547092);
            //console.log("ItemAssociationTypes: " + JSON.stringify(CD.d));
            this.setState({ ItemAssociationTypes: CD.d });
            SetItemAssociationTypes(CD.d);
            SessionInfo.ItemAssociationTypeUpdate = false;
          }
          else
            SetItemAssociationTypes(this.state.ItemAssociationTypes); // May be replaced in ManagerPage  
          if (this.state.Brands.length <= 1 || SessionInfo.BrandUpdate === true) {
            let CD = await GetDropdownData(541002);
            this.setState({ Brands: CD.d });
            SessionInfo.BrandUpdate = false;
          }
          //console.log("ItemStatus");
          if (this.state.ItemStatusSet.length <= 1 || SessionInfo.ItemStatusUpdate === true) {
            let CD = await GetDropdownData(1104159);
            this.setState({ ItemStatusSet: CD.d });
            SessionInfo.ItemStatusUpdate = false;
          }
          //console.log("Packagetypes");
          if (this.state.PackagingTypes.length <= 2 || SessionInfo.PackagingTypeUpdate === true) { // Note - Has Active/Nonactive record control 
            let CD = await GetDropdownData(519062);
            //console.log("Packging TYpes: " + JSON.stringify(CD.d));
            this.setState({ PackagingTypes: CD.d });
            SessionInfo.PackagingTypeUpdate = false;
          }
          //console.log("rates");
          if (this.state.RateTypes.length <= 1 || SessionInfo.RateTypeUpdate === true) {
            let CD = await GetDropdownData(545339);
            this.setState({ RateTypes: CD.d });
            SessionInfo.RateTypeUpdate = false;
          }
          if (this.state.InventoryControlTypeIDs.length <= 1) {
            let CD = await GetDropdownData(556138);
            this.setState({ InventoryControlTypeIDs: CD.d });
          }
          if (this.state.SeasonCodesDict.length <= 1) {
            let CD = await GetDropdownData(1120743);
            this.setState({ SeasonCodesDict: CD.d });
          }
          if (this.state.DayOfWeekDict.length <= 1) {
            let CD = await GetDropdownData(113509);
            SetDayOfWeek(CD.d);
            this.setState({ DayOfWeekDict: CD.d });
          }
          if (this.state.TimeOfDayDict.length <= 1) {
            let CD = await GetDropdownData(546466);
            SetTimeOfDay(CD.d);
            this.setState({ TimeOfDayDict: CD.d });
          }
          if (this.state.StorageControlsDict.length <= 1) {
            let CD = await GetDropdownData(539092);
            this.setState({ StorageControlsDict: CD.d });
          }
          if (this.state.PromotionTypes.length <= 1) {
            let CD = await GetDropdownData(545179);
            this.setState({ PromotionTypes: CD.d });
          }
          if (this.state.TaxableTypes.length <= 1) {
            let CD = await GetDropdownData(546870);
            this.setState({ TaxableTypes: CD.d });
          }
          //console.log("Weights");
          if (this.state.UOMWeights.length <= 1) {
            let CD = await GetDropdownData(512615);
            this.setState({ UOMWeights: CD.d });
          }
          //console.log("Storage");
          if (this.state.StorageControls.length <= 1) {
            let CD = await GetDropdownData(539092);
            this.setState({ StorageControls: CD.d });
          }
          //console.log("locations");
          if (this.state.StoreLocations.length <= 1) {
            let CD = await GetDropdownData(544596);
            this.setState({ StoreLocations: CD.d });
          }
          this.setState({ StoreLocationID: SessionInfo.StoreLocationID });
          this.setItemStateDone();
          console.log("Dictionary Load Complete");
        }
        //if (this.state.DayPeriods.length <= 1) {
        //  let CD = await GetDropdownData(555070);
        //  this.setState({ DayPeriods: CD.d });
        //}
        //------- Get StoreItems -------------------------------- 
        if (this.state.StoreItems.length <= 1) {
          console.log("Get StoreItemRecords");
          await this.getStoreItemRecords();
          //console.log(`StoreItemRecords: ${JSON.stringify(this.state.StoreItems)}`);
        }
        if (this.state.MainStoreItems.length <= 1) {
          console.log("Get Main StoreItemRecords");
          await this.getMainProductItems(this.state.ItemSelect);
          //console.log(`Main StoreItemRecords: ${JSON.stringify(this.state.StoreItems)}`);
        }
        SessionInfo.setGridRefresh = 1; // Show Grid Refresh  
        SessionInfo.currRefreshGridFunc = this.searchCurrent;
        SessionInfo.tabSelectFunc = this.selectTab;
        SessionInfo.setExcelExport = 1; // Show Export in Header 
        SessionInfo.currExcelExportFunc = this.exportExcel;
        SessionInfo.setGridAdd = 1; // Turn On Add in Header 
        SessionInfo.setShowDelete = 1; // Turn On Delete in Header 
        SessionInfo.currAddGridRowFunc = this.enterInsertSI;
        SessionInfo.setite
        SessionInfo.searchFunc = this.searchCurrent;
        if (SessionInfo.GoToTab >= 0) {
          await this.selectTab(SessionInfo.GoToTab);
          SessionInfo.GoToTab = 0;
        }
      }
    } catch (error) {
      console.log("Failure on Mount StoreItemDetail Error: " + error.message);
    }
  }
  componentWillUnmount() {
    if (SessionInfo.session !== '')
      SessionInfo.StoreItemDetail = this.state;
  }
  shouldComponentUpdate() {
    //console.log("Should Update?");
    if (this.compUpdate === true)
      return true;
    else
      return false;
  }
  setItemStateChanged = (changed) => {
    this.setState({ itemStateHasChanged: changed });
    if (changed === true) {
      SessionInfo.setShowSave = 1; // Show Save in Header  
      if (this.clearScreen === true) {
        this.clearScreen = false;
        SessionInfo.setShowNew = 1;
      }
    } else
      SessionInfo.setShowSave = 2; // Turn Off
  }
  setItemStateDone() {
    SessionInfo.setShowSave = 2; // Reset Save in Header 
    this.setState({ itemStateHasChanged: false });
  }
  onHorizontalLayoutChange = (event) => {
    //console.log("horizontal: " + JSON.stringify(updatedState));
    this.setState({ horizontalPanes: event.newState });
  }
  onNestedLayoutChange = (event) => {
    this.setState({ verticalPanes: event.newState });
  }
  // Convert Encoded Values For DaysOfWeek
  getDaysOfWeekEncoded = async () => {
    let DaysOfWeek = 0x0000000000000000;
    let VList = this.state.DaysOfWeekVals;
    if (VList.includes('Monday'))
      DaysOfWeek |= (0x001 << 1);
    if (VList.includes('Tuesday'))
      DaysOfWeek |= (0x001 << 2);
    if (VList.includes('Wednesday'))
      DaysOfWeek |= (0x001 << 3);
    if (VList.includes('Thursday'))
      DaysOfWeek |= (0x001 << 4);
    if (VList.includes('Friday'))
      DaysOfWeek |= (0x001 << 5);
    if (VList.includes('Saturday'))
      DaysOfWeek |= (0x001 << 6);
    if (VList.includes('Sunday'))
      DaysOfWeek |= (0x001 << 7);
    console.log(`DaysOfWeek: ${DaysOfWeek}`);
    await this.setState({ DaysOfWeek });
  }
  // Convert Encoded Values For ItemControl
  // Add to saveRecord  await getItemControlEncoded()
  //getItemControlEncoded = async () => {
  //  let ItemControl = 0x0000000000000000;
  //  if (this.state.DietModifiable == true)
  //    ItemControl |= (0x001 << 4);
  //  if (this.state.Vegetarian)
  //    ItemControl |= (0x001 << 5);
  //  if (VList.includes('Diet Vegan'))
  //    ItemControl |= (0x001 << 6);
  //  if (VList.includes('Diet Nutt Alergy'))
  //    ItemControl |= (0x001 << 7);
  //  if (VList.includes('Diet Lactose'))
  //    ItemControl |= (0x001 << 8);
  //  if (VList.includes('Diet Gluten'))
  //    ItemControl |= (0x001 << 9);
  //  if (VList.includes('Diet Other'))
  //    ItemControl |= (0x001 << 12);
  //  await this.setState({ ItemControl });
  //}
  //--------------- Standard Page Functions ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
  handleTopTabSelect = async (e) => { // Tab Selection
    await this.selectTab(e.selected);
  }
  // Select Current Tab
  selectTab = async (tabNumber) => {
    SessionInfo.searchFunc = this.searchCurrent;
    if (SessionInfo.clearSearch)
      SessionInfo.clearSearch();
    SessionInfo.searchTitle = "Search Items";
    let horizontalPanes = [{ size: '20%',min: '20px',collapsible: false },{ min: '300px',collapsible: false },{ size: '20%',collapsed: true,collapsible: true }]; // Left, Middle, Right
    if (this.state.StoreItemID > 0 || this.state.itemStateHasChanged == true) {
      console.log("Set Tab: " + tabNumber);
      this.setState({ selectedTopTab: tabNumber });
      if (tabNumber === this.tabs.ItemInfo) { // Product Item Information   
        SessionInfo.setGridAdd = 2; // Turn Off      
        SessionInfo.setShowSave = 1; // Show Save in Header 
        SessionInfo.setShowNew = 1;
        SessionInfo.setShowDelete = 1; // Show Delete in Header 
        SessionInfo.currAddGridRowFunc = this.enterInsertSI;
        SessionInfo.setGridRefresh = 1; // Show Grid Refresh  
        SessionInfo.currRefreshGridFunc = this.searchCurrent;
        SessionInfo.headerTitle = this.state.ItemName; // setTitle
        //console.log("Header Set");
      }
      else if (tabNumber === this.tabs.AdditionalInfo) { // Product Item Information   
        SessionInfo.setGridAdd = 2; // Turn Off      
        //SessionInfo.setShowSave = 1; // Show Save in Header 
        SessionInfo.setShowNew = 1;
        SessionInfo.setShowDelete = 1; // Show Delete in Header 
        SessionInfo.currAddGridRowFunc = this.enterInsertSI;
        SessionInfo.setGridRefresh = 1; // Show Grid Refresh  
        SessionInfo.currRefreshGridFunc = this.searchCurrent;
        SessionInfo.headerTitle = this.state.ItemName; // setTitle
        //console.log("Header Set");
      }
      else if (tabNumber === this.tabs.ProductOptions) { // Option Groups
        await this.getOptionsGroupsSI('');
        await this.getOptionsGroupsNotSI(''); // This has to be updated for each item
        SessionInfo.setGridAdd = 1;
        SessionInfo.setShowSave = 2; // Turn off Save in Header 
        SessionInfo.setShowNew = 2;
        SessionInfo.currAddGridRowFunc = this.enterInsertProductSIOptionsGroup;
        SessionInfo.setGridRefresh = 1; // Show Grid Refresh  
        SessionInfo.currRefreshGridFunc = this.searchCurrent;
        SessionInfo.setGridRefresh = 2; // Hide Grid Refresh   
        SessionInfo.searchTitle = "Search Options";
      }
      else if (tabNumber === this.tabs.RelatedItems) { // Option Groups
        console.log("Set Tab for RelatedItems");
        if (this.state.MainStoreItems.length <= 1) {
          await this.getMainProductItems(this.state.ItemSelect);
        }
        await this.getRelatedItems();
        SessionInfo.setGridAdd = 1;
        SessionInfo.setShowSave = 2; // Turn off Save in Header 
        SessionInfo.setShowNew = 2;
        SessionInfo.currAddGridRowFunc = this.enterInsertRelatedItems;
        SessionInfo.setGridRefresh = 1; // Show Grid Refresh  
        SessionInfo.currRefreshGridFunc = this.searchCurrent;
        SessionInfo.setGridRefresh = 2; // Hide Grid Refresh  
        SessionInfo.searchTitle = "Search Main Items";
      }
    }
    else {
      horizontalPanes = [{ size: '70%',min: '20px',collapsible: false },{ min: '100px',collapsed: true,collapsible: true },{ size: '20%',collapsed: true,collapsible: true }]; // Left, Middle, Right
      if (tabNumber === 0) { // Product Item 
        this.setState({ selectedTopTab: tabNumber });
        //SessionInfo.headerTitle = "Select an Item";
      }
      else
        displayWarning("A Product Item must be Selected");
      if (this.state.productOptionsGroupsAV === undefined || this.state.ProductOptionsGroupsAV.length <= 1)
        await this.getOptionsGroupsNotSI('');
    }
    this.setState({ selectedTopTab: tabNumber });
    this.setState({ horizontalPanes });
    this.setState({ verticalPanes: this.verticalPanesAll });
    console.log(`Set Tab Complete - tab: ${tabNumber}`);
  }
  searchCurrent = async (search) => {
    if (!search || typeof search !== 'string' || search === 'all' || search === 'ALL')
      search = "";
    if (search === undefined)
      search = "";
    this.setState({ storeItemsSkip: 0 });
    this.setState({ storeItemsTake: this.takeLines });
    this.setState({ itemFilter: { logic: "and",filters: [] } });
    this.setState({ itemSort: [{ "field": "ItemName","dir": "asc" }] });
    console.log("selectTab: " + this.selectedTopTab);
    displayMessage("Search for: " + search);
    let tabNumber = this.state.selectedTopTab;
    if (tabNumber === this.tabs.ItemInfo) { // Product Item Information  
      search += "*StoreLocationID=0";
      this.setState({ ItemSelect: search });
      await this.getStoreItemRecords(search);
    }
    else if (tabNumber === this.tabs.AdditionalInfo) { // Product Item Information  
      search += "*StoreLocationID=0";
      this.setState({ ItemSelect: search });
      await this.getStoreItemRecords(search);
    }
    else if (tabNumber === this.tabs.ProductOptions) { // Option Groups - Search available OptionGroups  
      this.setState({ ItemSelect: search });
      await this.getOptionsGroupsNotSI(search); // This has to be updated for each item
    }
    else if (tabNumber === this.tabs.RelatedItems) { //Related Items - Search list of Items    
      this.setState({ ItemSelect: search });
      await this.getMainProductItems(search);
    }
  }
  saveRecord = async () => {
    this.lastCommandSave = true;
    await this.getDaysOfWeekEncoded();
    await this.getItemControlBitEncoded();
    let curInsertRow = {};
    StoreItemsO.forEach((elem) => {
      if (elem.n in this.state) {
        if (elem.n !== "ImageID" && elem.n !== "DocumentID") {
          if (this.state[elem.n] === undefined || this.state[elem.n] === null) {
            if (elem.t === 34 || elem.t === 35)
              curInsertRow[elem.n] = new Date();
            else if (elem.t === 21 || elem.t === 29) // 22 is bool
              curInsertRow[elem.n] = '';
            else if (elem.t === 28 || elem.t === 201)
              curInsertRow[elem.n] = 0;
            else
              curInsertRow[elem.n] = 0;
          } else
            curInsertRow[elem.n] = this.state[elem.n];
        }
      }
    });
    curInsertRow["StoreLocationID"] = 0;
    //console.log("Save StoreItemDetail: " + JSON.stringify(curInsertRow));
    let itemID = parseInt(this.state.StoreItemID);
    let CD = await CallOM("UpdateStoreItem",0,itemID,13,curInsertRow); // Update StoreItem needs to return StoreItemID so if further edit is done on new it edits current
    //await this.getRecords();
    //console.log("updated record: " + JSON.stringify(copyState));
    // This is to insert the new item into edit grid of storeItems
    //let index = this.state.StoreItems.findIndex(p => p === curInsertRow || curInsertRow.StoreItemID && p.StoreItemID === curInsertRow.StoreItemID); // Note - for new line will find 0  
    //let index = this.state.StoreItems.findIndex(p => p.StoreItemID && p.StoreItemID === curInsertRow.StoreItemID); // Note - for new line will find 0                                     
    let index = this.state.StoreItems.findIndex(p => p.StoreItemID === curInsertRow.StoreItemID); // Note - for new line will find 0
    trace(`after insert - index: ${index}`);      
    let a = this.state.StoreItems.slice(); // creates the clone of the state
    if (index >= 0) {
      //let updated = Object.assign({},curInsertRow);
      //a[index] = updated;
      a[index] = curInsertRow;
      //this.setState({ StoreItems[index]: updated });
    } else { // Insert at the beginning
      a.unshift(curInsertRow);
    }          
    this.setState({ StoreItems: a });
    this.selectTab(0);
    this.setItemStateDone();
    SessionInfo.setShowNew = 1; // Clear
    if (this.isAutoAdd === true) {
      trace(`Auto Add`);
      this.clearRecord();
    }
    trace(`finish saveRecord - ${this.autoAddCount}`);
  }
  deleteRecord = async () => {
    this.lastCommandSave = false;
    this.autoAddCount = 0;
    this.isAutoAdd = false;
    await DeleteRowForce(544619,this.state.StoreItemID);
    this.setItemStateDone();
    this.clearRecord();
    this.searchCurrent();
  }
  clearRecord = async () => { // Note => functions do not bind their owner to this - if used clearRecord() - would have to bind this to the function
    //console.log("Clear Screen - state: " + JSON.stringify(this.state));  
    this.setState({ StoreItemID: 0 }); // Note - Clear PKey
    StoreItemsO.forEach((elem) => {
      let sobj = {};
      try {
        if (elem.t === 34 || elem.t === 35)
          sobj[elem.n] = new Date();
        else if (elem.t === 21 || elem.t === 19 || elem.t === 29) // String, Memo, Enum? 
          sobj[elem.n] = '';
        else if (elem.t === 28 || elem.t === 201) { // Money, Enum
          sobj[elem.n] = 0;
          let sobjS = {};
          sobjS[elem.n + 'S'] = '';
          this.setState(sobjS);
        } else if (elem.t === 19) { // Money 
          sobj[elem.n] = 0;
          sobj[elem.n] = '';
        } else if (elem.t === 195) { // Do not clear FK fields on first click
          if (SessionInfo.clearStage === 2) // 8Mar24 - This is a problem!!
            sobj[elem.n] = 0;
        } else
          sobj[elem.n] = 0;
        this.setState(sobj);
      } catch (error) {
        console.log(`Clear Item Field: ${elem.n} Error: ${error.message}`);
      }
    });
    await this.GetProductItemImage(1); // Blank Image
    this.setItemStateDone();
    let DList = [];
    console.log(`Clear Screen - StoreItemID: ${this.state.StoreItemID}, clearState: ${SessionInfo.clearStage}`);
    this.setState({ DaysOfWeekVals: DList });
    if (SessionInfo.clearStage === 1) {
      SessionInfo.setShowNew = 3; // Go to second stage clear
    } else
      SessionInfo.setShowNew = 1; // Clear
    if (this.state.horizontalPanes[0].size === '70%') { // NOTE - 16Mar23 - if that percent changes - this needs to change!
      this.closeLeftPane();
    }
    this.clearScreen = true;
    if (this.lastCommandSave === true) {
      if (this.isAutoAdd === false) {
        if (++this.autoAddCount >= 1)
          this.isAutoAdd = true;
      }
    }
    this.lastCommandSave = false;
  }
  //--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
  closeLeftPane = () => {
    //console.log("Close Left Pane");
    this.setState({ LeftO: false });
    if (this.state.RightO === false)
      this.setState({ horizontalPanes: this.horizSplitCC });
    else
      this.setState({ horizontalPanes: this.horizSplitCO });
    SessionInfo.setLPaneOpen = 1;
    SessionInfo.setLPaneClose = 2;
  }
  openLeftPane = () => {
    //console.log("Open Left Pane");
    this.setState({ LeftO: true });
    if (this.state.RightO === false)
      this.setState({ horizontalPanes: this.horizSplitOC });
    else
      this.setState({ horizontalPanes: this.horizSplitOO });
    SessionInfo.setLPaneClose = 1;
    SessionInfo.setLPaneOpen = 2;
  }
  closeRightPane = () => {
    console.log("Close Right Pane");
    this.setState({ RightO: false });
    if (this.currentPanel === this.Panel.ITEMS)
      SessionInfo.searchTitle = "Search Store Items";
    if (this.state.LeftO === false)
      this.setState({ horizontalPanes: this.horizSplitCC });
    else
      this.setState({ horizontalPanes: this.horizSplitOC });
    SessionInfo.setRPaneOpen = 1;
    SessionInfo.setRPaneClose = 2;
  }
  openRightPane = () => {
    console.log("Open Right Pane");
    this.setState({ RightO: true });
    if (this.currentPanel === this.Panel.ITEMS)
      SessionInfo.searchTitle = "Search Main Items";
    if (this.state.LeftO === false)
      this.setState({ horizontalPanes: this.horizSplitCO });
    else
      this.setState({ horizontalPanes: this.horizSplitOO });
    SessionInfo.setRPaneClose = 1;
    SessionInfo.setRPaneOpen = 2;
  }
  //--------------- End of Standard Page Functions ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------  
  //--------------- Page Setup and Load Functions ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
  //// Test using the Telerik Upload - not working
  //onAdd = (event) => {
  //  console.log('onAdd: ', event.affectedFiles);
  //  //console.log("on Add event: " + JSON.stringify(event));
  //  //console.log("on Add target: " + JSON.stringify(event.target));
  //  //console.log("on Add affectedFiles: " + JSON.stringify(event.affectedFiles));
  //  this.setState({ files: event.newState });
  //  //console.log("on Add new: " + JSON.stringify(event.newState));
  //  //ProductItemPhotoURL: undefined,                                   
  //  //this.state.ProductItemPhotoURL = event.newState.getRawFile;
  //  this.state.files.map(file => {
  //    //console.log(" file: " + JSON.stringify(file));
  //    let realFile = file.getRawFile();
  //    //console.log(" realfile: " + JSON.stringify(realFile));
  //    this.setState({ ProductItemPhotoURL: realFile });
  //  });
  //  //this.state.ProductItemPhotoURL = event.newState.UploadFileInfo.getRawFile();
  //  console.log("on Add file: " + JSON.stringify(this.state.ProductItemPhotoURL));
  //  //this.state.ProductItemPhotoURL = event.affectedFiles.getRawFile();
  //  //console.log("on Add file: " + JSON.stringify(this.state.ProductItemPhotoURL));
  //}
  onRemove = (event) => {
    console.log('onRemove: ',event.affectedFiles);

    this.setState({
      files: event.newState
    });
  }
  onProgress = (event) => {
    console.log('onProgress: ',event.affectedFiles,event.serverResponse);

    this.setState({
      files: event.newState
    });
  }
  onStatusChange = (event) => {
    console.log('onStatusChange: ',event.affectedFiles,event.serverResponse);
    let cnt = event.newState.length;
    event.newState[cnt - 1].status = 1;
    this.setState({ files: event.newState });
  }
  //--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
  formatMoney = (amount) => {
    let decimalCount = 2;
    const decimal = ".";
    const thousands = ",";
    try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
      const negativeSign = amount < 0 ? "-" : "";
      let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
      let j = (i.length > 3) ? i.length % 3 : 0;
      return negativeSign + (j ? i.substring(0,j) + thousands : '') + i.substring(j).replace(/(\d{3})(?=\d)/g,"$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
    } catch (e) {
      console.log(`Format Money Error: ${e}`)
    }
  }
  moneyFormat = (value) => new Intl.NumberFormat('en-US',{ style: 'currency',currency: 'USD' }).format(value);

  getStoreItemRecords = async (search) => { // Get the available Items
    if (!search)
      search = '';
    console.log("get store items search: " + search);
    let CD = await CallOM("GetProductItems",0,0,2,search);
    this.setState({ ItemSelect: "" });
    //console.log("Store Items: " + JSON.stringify(CD.d));
    if (CD.x.o === 0) {
      LogOffResetMsg(`Web Session Has Failed in ${SessionInfo.currentPage} - Session: ${CD.x.s}`);
      this.props.navigate("/Login");
    } else if (CD.x.o < 9500) {
      try {
        this.setState({ ItemSelect: search });
        const StoreItems = CD.d.rows.map(dataItem => Object.assign({ selected: false },dataItem));
        this.setState({ StoreItems });
        this.state.saveStoreItems = StoreItems;
      } catch (error) {
        OMTrace(2,`Failure in Store Item Load: ${error.message}`);
      }
    }
  }
  getMainProductItems = async (search) => { // Get the MainStore product items - Company
    if (!search)
      search = '';
    if (search[0] === '*') {
      if (search.indexOf("StoreLocationID") < 0)
        search += " and StoreLocationID=0";
    }
    else
      search += "*StoreLocationID=0";
    let CD = await CallOM("GetMainProductItemsForLocation",2,0,2,search,"StoreItemID,ItemName,ItemDescription,BarcodeNumber,ItemPrice");
    //console.log("Product Items: " + JSON.stringify(CD.d));
    try {
      const MainStoreItems = CD.d.rows.map(dataItem => Object.assign({ selected: false },dataItem));
      //console.log("MainStore Items: " + JSON.stringify(MainStoreItems));
      this.setState({ MainStoreItems });
      this.setState({ mainStoreItemsCount: MainStoreItems.length });
    } catch (error) {
      console.log("Error getMainProductItems: " + error.message);
    }
  }
  //------------------------- Edit StoreItem Grid ------------------------------------------------------------------  
  enterInsertSI() {
    console.log("Insert in Main Table");
    const dataItem = { inEdit: true };
    const allRecords = this.state.StoreItems.slice();
    allRecords.unshift(dataItem); // Add to the beginning
    this.updateStoreItems(allRecords,dataItem);
    this.setState({ StoreItems: allRecords });
  }
  enterEditSI(dataItem) {
    this.updateStoreItems(this.state.StoreItems,dataItem).inEdit = true;
    this.setState({ StoreItems: this.state.StoreItems.slice() });
  }
  saveSI(dataItem) {
    dataItem.inEdit = undefined;
    if (!dataItem.StoreItemID)
      dataItem.StoreItemID = 0;
    dataItem.StoreItemID = this.updateStoreItems(this.state.StoreItems,dataItem).StoreItemID;
    this.setState({ StoreItems: this.state.StoreItems.slice() });
    //console.log(`Grid Update StoreItem: ${dataItem.StoreItemID}, ${JSON.stringify(dataItem)}`);
    this.updateStoreItem(dataItem);
  }
  cancelSI(dataItem) {
    if (dataItem.StoreItemID && dataItem.StoreItemID !== 0) {
      let originalItem = this.state.saveStoreItems.find(p => p.StoreItemID === dataItem.StoreItemID)
      if (originalItem !== undefined) {
        if (originalItem.inEdit) originalItem.inEdit = false;
        this.updateStoreItems(this.state.StoreItems,originalItem);
      }
      else {
        this.setState({ StoreItems: this.state.saveStoreItems.slice() })
      }
    } else {
      this.updateStoreItems(this.state.StoreItems,dataItem,!dataItem.StoreItems); // remove false
    }
    this.setState({ StoreItems: this.state.StoreItems.slice() });
  }
  removeSI(dataItem) {
    dataItem.inEdit = undefined;
    let key = dataItem.StoreItemID;
    //console.log("removeSI: " + key);
    this.updateStoreItems(this.state.StoreItems,dataItem,true);
    this.state.saveStoreItems = this.state.StoreItems;
    DeleteRowForce(544619,key); // Delete in the StoreItems Table in OM
    this.setState({ StoreItems: this.state.StoreItems.slice() });
  }
  itemChangeSI(event) {
    const value = event.value;
    const name = event.field;
    if (!name) {
      return;
    }
    const updatedData = this.state.StoreItems.slice();
    const item = this.updateStoreItems(updatedData,event.dataItem);
    //console.log("itemChange - name: " + name + " value: " + JSON.stringify(value));
    if (!value.ddID)
      item[name] = value;
    else
      item[name] = value.ddID;
    this.setState({ StoreItems: updatedData });
  }
  updateStoreItems(data,item,remove) {  // data - is the entire data set (JSON), item - is the current line 
    let updated;
    //eslint-disable-next-line                   
    let index = data.findIndex(p => p === item || item.StoreItemID && p.StoreItemID === item.StoreItemID); // Note - for new line will find 0
    if (index >= 0) {
      //console.log("before update data: " + JSON.stringify(data[index]));
      updated = Object.assign({},item);
      //console.log("update data: " + JSON.stringify(data[index]));
      //console.log("updated: " + JSON.stringify(updated));
      data[index] = updated;
      //console.log("after update data: " + JSON.stringify(data[index]));
    }
    if (remove) {
      data = data.splice(index,1);
    }
    return data[index];
  }
  updateStoreItem = async (dataItem) => {
    //UpdateRow(544619, 0, this.state.StoreItems, dataItem, dataItem.StoreItemID, "StoreItemID"); // Save to OM
    let curInsertRow = {};
    StoreItemsO.forEach((elem) => {
      if (elem.n in dataItem) {
        if (elem.n !== "ImageID" && elem.n !== "Image" && elem.n !== "DocumentID");
        curInsertRow[elem.n] = dataItem[elem.n];
      }
    });
    console.log("Save StoreItemDetail: " + JSON.stringify(curInsertRow));
    //await UpdateRow(544619, 0, undefined, curInsertRow, curInsertRow.StoreItemID, "StoreItemID"); // Save to OM 
    let PKID = dataItem["StoreItemID"];
    console.log(`Update Row - PKID: ${PKID} Item: ${JSON.stringify(curInsertRow)}`);
    try {
      let CD = await CallOM("UpdateStoreItem",0,PKID,13,curInsertRow);
      if (PKID == 0) { // New Record
        PKID = CD.d.PKID;
        if (PKID > 0) {
          dataItem["StoreItemID"] = PKID;
          //  if (allData) // Update Grid PKID
          //    allData[0][keyName] = PKID;
        }
      }
    } catch (error) {
      reportError(1,"Update StoreItem Error: " + error.message);
    }
  }
  //-------------------------------
  storeItemRowClick = async (event) => {
    this.lastCommandSave = false;
    this.autoAddCount = 0;
    this.isAutoAdd = false;
    const current = this.state.StoreItems.findIndex(dataItem => dataItem === event.dataItem);
    console.log(`StoreItem Click - current: ${current}`);
    if (current >= 0) {
      this.state.StoreItems.forEach(item => item.selected = false);
      this.state.StoreItems[current].selected = true;
      if (this.state.itemStateHasChanged === true) {
        if (SessionInfo.onChangeAskSave === true) {
          let okay = window.confirm(`Save Current Changes to: ${this.state.ItemName}?`);
          if (okay === false) {
            displayMessage("Changes to the Current Item Abandoned");
          }
          else {
            await this.saveRecord();
            //console.log("Row Saved");
          }
        }
        else {
          let okay = window.confirm(`Abandon Current Changes to: ${this.state.ItemName}?`);
          if (okay === false) {
            displayMessage("Row must be Saved First");
            return;
          }
          displayMessage("Changes to the Current Item Abandoned");
        }
      }
      SessionInfo.SelectStoreLocationID = this.state.StoreLocationID;
      SessionInfo.StoreItemID = this.state.StoreItems[current].StoreItemID;
      console.log(` Get StoreItemID: ${SessionInfo.StoreItemID}`);
      let CD = await GetTableRow(544619,SessionInfo.StoreItemID);
      this.compUpdate = false;
      //console.log("StoreItem Get: " + JSON.stringify(CD.d));
      let currentImageID = 0;
      this.setState({ StoreLocationItemID: SessionInfo.StoreItemID });
      var row = CD.d.row;
      StoreItemsO.forEach((elem) => {
        if (elem.n in row) {
          //console.log(`Set ${elem.n}: ${row[elem.n]}`);
          let sobj = {};
          try {
            if (elem.t === 34 || elem.t === 35) { // Date, DateTime
              //sobj[elem.n] = new Date(row[elem.n]); // For use with DateTime Control
              sobj[elem.n] = row[elem.n];
              SIV[elem.n] = new Date(row[elem.n]);
              //} else if (elem.t === 28 || elem.t === 195 || elem.t === 201) { // Money, FK-Translated, Enum  - NOTE - 18JUL23 - depends if fetch record is translated or not
            } else if (elem.t === 28 || elem.t === 201) { // Money, FK-Translated, Enum
              sobj[elem.n] = row[elem.n];
              let sobjS = {};
              sobjS[elem.n + 'S'] = this.moneyFormat(row[elem.n]);
              //console.log(`Set Money Field: ${elem.n + 'S'}, Value: ${ sobjS[elem.n + 'S']}`);
              this.setState(sobjS);
              SIV[elem.n] = row[elem.n];
            } else if (elem.t === 15) { // Encoded
              //------ Assign Encoded Values ------
              //console.log(`element 15 - name: ${elem.n}`); 

              if (elem.n === 'DaysOfWeek') {
                // Assignment for DaysOfWeek
                let EncodeVal = row[elem.n];
                DaysOfWeekFixedValues.forEach((ent) => {
                  if ((EncodeVal & (0x001 << ent.k)) === (0x001 << ent.k))
                    AList.push(ent.n);
                });
              }
              if (elem.n === 'ItemControl') {
                // Assignment for ItemControl
                this.setItemControlBitEncoded(row[elem.n]);
                //  let EncodeVal = row[elem.n];
                //  ItemControlFixedValues.forEach((ent) => {
                //    if ((EncodeVal & (0x001 << ent.k)) === (0x001 << ent.k))
                //      AList.push(ent.n);
                //  });
              }
            } else {
              sobj[elem.n] = row[elem.n];
              SIV[elem.n] = row[elem.n];
            }
            //console.log(`sobj ${JSON.stringify(sobj)}, t: ${elem.t}, val: ${SIV[elem.n]}`);
            this.setState(sobj);
            // Add item specific code here
            if (elem.n === 'ImageID')
              currentImageID = row[elem.n];
            SessionInfo.setShowNew = 1;
          }
          catch (error) {
            console.log(`Set StoreItemsO Field: ${elem.n}, Error: ${error.message}`);
          }
        }
      });
      SessionInfo.headerTitle = this.state.ItemName; // setTitle       
      console.log(` GetProductItemImage: ${this.state.ImageID}, currentImageID: ${currentImageID}`);
      if (currentImageID !== 0)
        await this.GetProductItemImage(currentImageID);
      else
        await this.GetProductItemImage(1); // Blank Image
      this.setItemStateDone();
      if (this.state.selectedTopTab === this.tabs.ProductOptions) {
        await this.getOptionsGroupsSI('');
        await this.getOptionsGroupsNotSI(''); // This has to be updated for each item
      }
      else if (this.state.selectedTopTab === this.tabs.RelatedItems) {
        await this.getRelatedItems();
      }
      else {
        //console.log("Tab0 Size: " + this.state.horizontalPanes[0].size);
        if (this.state.horizontalPanes[0].size === '70%') { // NOTE - 16Mar23 - if that percent changes - this needs to change!
          this.closeLeftPane();
        }
        //this.selectTab(this.tabs.ItemInfo);
      }
      this.compUpdate = true;
      this.forceUpdate();
    }
    console.log("Finished ItemSelect ");
  }
  // Convert Encoded Bit-Values For ItemControl
  // ** Add to saveRecord  await this.getItemControlBitEncoded()
  getItemControlBitEncoded = async () => {
    let ItemControl = 0x0000000000000000;
    if (this.state.DietModifiable === true)
      ItemControl |= (0x001 << 4);
    if (this.state.DietVegetarian === true)
      ItemControl |= (0x001 << 5);
    if (this.state.DietVegan === true)
      ItemControl |= (0x001 << 6);
    if (this.state.DietNutAlergy === true)
      ItemControl |= (0x001 << 7);
    if (this.state.DietLactose === true)
      ItemControl |= (0x001 << 8);
    if (this.state.DietGluten === true)
      ItemControl |= (0x001 << 9);
    if (this.state.DietOther === true)
      ItemControl |= (0x001 << 12);
    await this.setState({ ItemControl });
  }
  // Set State Variables for Encoded Bit-Values For ItemControl
  // ** Add to getRecord  await this.setItemControlBitEncoded(ItemControlVals)
  setItemControlBitEncoded = async (ItemControlVals) => {
    if ((ItemControlVals & (0x001 << 4)) === (0x001 << 4))
      this.setState({ DietModifiable: true });
    else
      this.setState({ DietModifiable: false });
    if ((ItemControlVals & (0x001 << 5)) === (0x001 << 5))
      this.setState({ DietVegetarian: true });
    else
      this.setState({ DietVegetarian: false });
    if ((ItemControlVals & (0x001 << 6)) === (0x001 << 6))
      this.setState({ DietVegan: true });
    else
      this.setState({ DietVegan: false });
    if ((ItemControlVals & (0x001 << 7)) === (0x001 << 7))
      this.setState({ DietNutAlergy: true });
    else
      this.setState({ DietNutAlergy: false });
    if ((ItemControlVals & (0x001 << 8)) === (0x001 << 8))
      this.setState({ DietLactose: true });
    else
      this.setState({ DietLactose: false });
    if ((ItemControlVals & (0x001 << 9)) === (0x001 << 9))
      this.setState({ DietGluten: true });
    else
      this.setState({ DietGluten: false });
    if ((ItemControlVals & (0x001 << 12)) === (0x001 << 12))
      this.setState({ DietOther: true });
    else
      this.setState({ DietOther: false });
  }
  clearItemControlBitEncoded = async () => {
    this.setState({ DietModifiable: false });
    this.setState({ DietVegetarian: false });
    this.setState({ DietVegan: false });
    this.setState({ DietNutAlergy: false });
    this.setState({ DietLactose: false });
    this.setState({ DietGluten: false });
    this.setState({ DietOther: false });
  }
  //-------- Help -----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
  showHelp = async (key) => {
    if (this.state.showHelpListPopup == true) { // Close Help
      this.setState({ showHelpListPopup: false });
      this.setState({ showHelpDetail: false });
    } else {
      if (!key)
        key = '';
      this.setState({ showHelpDetail: false });
      let HelpPages = await getHelpTopics(key);
      //console.log("caller HelpPages: " + JSON.stringify(HelpPages));
      this.setState({ HelpPages });
      this.setState({ showHelpListPopup: true });
    }
  }
  closeHelpList = () => {
    this.setState({ showHelpListPopup: false });
  }
  onHelpItemClick = async (item) => {
    let helpDetail = await getHelpDetail(item.HelpPageID);
    this.setState({ helpDetail });
    this.setState({ showHelpDetail: true });
  }
  closeHelpTime = async () => {
    this.setState({ showHelpDetail: false });
  }
  onObjectHelp = async (objectID) => {
    let helpDetail = await getHelpDetail(objectID,2);
    this.setState({ helpDetail });
    this.setState({ showHelpDetail: true });
  }
  closeHelpTime = async () => {
    this.setState({ showHelpDetail: false });
  }
  // Help List Layout
  HelpPageHeader = () => {
    return (
      <ListViewHeader className='listHelpHeader' onClick={(e) => this.closeHelpList()} >
        <div className='listHeaderLeft' onClick={(e) => this.closeHelpList()}>
          <span className="listHelpTitle">{SessionInfo.HelpTitle}</span>
        </div>
        <div className='listHeaderRight' onClick={(e) => this.closeHelpList()}>
          <Button id="cancelButton" icon="cancel" look="outline" className="medButton" onClick={this.closeHelpList} >EXIT HELP</Button>
        </div>
      </ListViewHeader>
    );
  }
  HelpPageFormat = props => {
    let item = props.dataItem;
    return (
      <div>
        <div className="listHelpRow" onClick={(e) => this.onHelpItemClick(item)}>
          <div className="listColA">
            <span className="listHelpItemTitle" >{item.HelpTitle}&nbsp;&nbsp;&nbsp;</span>
            <div dangerouslySetInnerHTML={{ __html: item.HelpContent }}></div>
          </div>
        </div>
      </div>
    );
  }
  HelpPageFooter = () => {
    //const [state, onCheckout] = React.useContext(StateContext);
    return (
      <ListViewFooter className='listHelpFooter' onClick={(e) => this.closeHelpList()} >
        <div className='listHeaderRight' onClick={(e) => this.closeHelpList()}>
          <Button id="cancelButton" icon="cancel" look="outline" className="medButton" onClick={this.closeHelpList} >EXIT HELP</Button>
        </div>
      </ListViewFooter>
    );
  }
  ShowFieldHelp = async (key) => {
    console.log(`Field Help - Key: ${key}`);
    this.onObjectHelp(key);
    //this.showHelp(key);
    //console.log(`Field Help: ${JSON.stringify(item)}`);
  }
  //------ End of Help -------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
  //------------------------- Image Management ------------------------------
  onSelectImageFile = async (event) => {
    event.stopPropagation();
    event.preventDefault();
    //console.log("StoreItemID: " + this.state.StoreItemID);
    //if (this.state.StoreItemID === 0 && this.InsertRecordType != 1)
    if (this.state.StoreItemID === 0)
      displayWarning("Product Item must be Selected or Added First");
    else {
      this.file = event.target.files[0];
      //console.log("File: " + JSON.stringify(file));
      //this.setState({ ContractFileName: file.name });
      let reader = new window.FileReader();
      this.fileReady = false;
      try {
        reader.readAsArrayBuffer(this.file);
        reader.onloadend = async () => {
          await this.convertToBuffer(reader);
          this.fileReady = true;
          displayMessage("Image: " + this.file.name + " Loaded");
          this.DocumentLoaded = true;
          this.DocumentSaved = false;
        }
        this.fileCheck();
        event.target.value = null;
      }
      catch (error) {
        console.log("FileReader Error: " + error);
        displayError("Error on File Read");
        return;
      }
    }
  }
  fileCheck = async () => {
    if (this.fileReady === true) {
      let ImageType = GetFileExtension(this.file.name);
      //console.log("ImageType: " + ImageType);
      ImageType = ImageType.toLowerCase();
      this.setState({ ImageType });
      let ix = SessionInfo.DocumentTypes.findIndex(c => c.ddName === ImageType);
      if (ix >= 0) {
        this.setState({ DocumentTypeID: SessionInfo.DocumentTypes[ix].ddID });
        let iVal = await this.saveImageToServer(this.StoreItemOID,this.state.StoreItemID);
        displayMessage(`Image saved for '${this.state.ItemName}', ItemID: ${this.state.StoreItemID}`);
        //setTimeout(this.retrieveItemImage, 1000, iVal);
      }
      else
        displayWarning("Unknown file type: " + ImageType);
      return;
    }
    setTimeout(this.fileCheck,200);
  }
  //onAddStoreImage = async (event) => {
  //  const fileObj = event.affectedFiles[0].getRawFile();
  //  console.log('onAddLoc name: ' + event.affectedFiles[0].name);
  //  console.log('onAddLoc ext: ' + event.affectedFiles[0].extension);
  //  console.log('onAddLoc size: ' + event.affectedFiles[0].size);
  //  console.log('onAddLoc status: ' + event.affectedFiles[0].status);
  //  console.log(fileObj);

  //  this.ImageType = event.affectedFiles[0].extension;
  //  try {
  //    let reader = new FileReader();
  //    //reader.readAsBinaryString(fileObj); // not sure what this does - does not work
  //    reader.readAsArrayBuffer(fileObj);
  //    reader.onloadend = async () => {
  //      this.ImageFile = await Buffer.from(reader.result);
  //      //set this buffer -using es6 syntax
  //      //let image = new Blob([reader], { type: 'image/jpeg' });
  //      //console.log('onAddLoc Image: ' + this.ImageFile);
  //      //console.log('onAddLoc Image Lth: ' + this.ImageFile.length);
  //      //this.ImageFile = cc;
  //      this.storeImage = fileObj; // File Object
  //      let imageURL = URL.createObjectURL(fileObj);
  //      console.log(`imageURL: ${imageURL}`);
  //      this.setState({ StoreLocationPhotoURL: imageURL });
  //      this.fileReady = true;
  //      this.DocumentLoaded = true;
  //      this.DocumentSaved = false;
  //      await this.fileItemUpload();
  //      console.log('finish onloadend');
  //    }
  //    this.fileStoreCheck();
  //    event.target.value = null;
  //  }
  //  catch (error) {
  //    console.log("FileReader Store Error: " + error);
  //    displayError("Error on Image File Read");
  //    return;
  //  }
  //  // 
  //  console.log('finish addLocImage');
  //};
  addStoreItemImage = async (event) => {
    try {
      const fileObj = event.affectedFiles[0].getRawFile();
      let imageType = getDocumentType(event.affectedFiles[0].extension,"Store Item");
      let reader = new FileReader();
      reader.readAsArrayBuffer(fileObj);
      reader.onloadend = async () => {
        let imageFile = await Buffer.from(reader.result);
        this.setState({ ProductItemPhotoURL: URL.createObjectURL(fileObj) });
        let iVal = await saveImageFileToServer(imageFile,imageType,this.StoreItemOID,this.state.StoreItemID);
        this.setState({ ImageID: iVal });
      }
      event.target.value = null;
    }
    catch (error) {
      console.log(`addStoreItemImage Error: ${error}`);
    }
  };
  retrieveItemImage = async (iVal) => {
    console.log("Retrieve Image: " + iVal);
    await this.GetProductItemImage(iVal);
  }
  retrieveOptionImage = async (iVal) => {
    console.log("Retrieve Image: " + iVal);
    await this.GetProductOptionImage(iVal);
  }
  convertToBuffer = async (reader) => { // File is converted to a buffer to prepare for uploading to OM
    const UploadFile = await Buffer.from(reader.result);
    //set this buffer -using es6 syntax
    this.setState({ UploadFile });
    //console.log("converToBuffer - Image uploadfile Length: " + UploadFile.length);
    // Display the image
    let image = new Blob([UploadFile],{ type: 'image/jpeg' });
    let imageURL = URL.createObjectURL(image);
    this.setState({ ProductItemPhotoURL: imageURL });
  };
  saveImageToServer = async (tableObjID,itemID) => {
    //console.log('saveImageToServer: ');
    if (this.isSavingDocument) {
      displayMessage("Document Save in Progress");
      return;
    }
    else if (!this.state.UploadFile)
      displayError('A file must be selected');
    else {
      this.isSavingDocument = true;
      //displayMessage('Saving Image for ' + this.state.ProductItemCategory);
      //console.log("Save Image " + JSON.stringify(this.state.UploadFile));
      let CD = await CallOM("SaveProductImage",tableObjID,itemID,20,this.state.UploadFile,this.state.ImageType); // 17
      console.log("Saved Image " + this.state.UploadFile.length);
      this.isSavingDocument = false;
      let ival = CD.d;
      return ival;
    }
  }
  GetProductItemImage = async (imageID) => {
    const imageURL = await getImageFileFromServer(imageID);
    this.setState({ ProductItemPhotoURL: imageURL });
  }
  storeItemsPageChange = (event) => {
    this.setState({ storeItemsSkip: event.page.skip,storeItemsTake: event.page.take });
  }
  //------------------------- End Edit StoreItem Grid --------------------------------------------------------------------------------

  //-------------------------- ProductOptionsGroups ---------------------------------------------------------------------------------- 
  getProductOptionsGroups = async (search) => {
    //search = "*StoreItemID=" + this.state.StoreItemID;
    console.log(`ProductOptionsGroups search: ${search}`)
    let CD = await GetTableSearchRaw(this.ProductOptionsGroupsOID,search,"ProductOptionsGroupID,ProductOptionsGroupName,IsRequired,AllowMultiple,MultipleCount,SortOrder,IsRecordActive");  // NOTE - could use *DSPO
    try {
      const ProductOptionsGroups = CD.d.rows.map(dataItem => Object.assign({ selected: false },dataItem));
      //console.log("ProductOptionsGroups " + JSON.stringify(ProductOptionsGroups));
      this.setState({ ProductOptionsGroups });
      this.state.saveProductOptionsGroups = ProductOptionsGroups;
    } catch (error) {
      displayError("getProductOptionsGroup: " + error.message);
    }
  }
  ProductOptionsGroupRowClick = async (event) => {
    const current = this.state.ProductOptionsGroups.findIndex(dataItem => dataItem === event.dataItem);
    if (current >= 0) {
      try {
        let ProductOptionsGroupID = this.state.ProductOptionsGroups[current].ProductOptionsGroupID;
        let CD = await GetTableRow(this.ProductOptionsGroupsOID,ProductOptionsGroupID);
        this.setState({ ProductOptionsGroupID: ProductOptionsGroupID });
        for (var prop in CD.d.row) {
          //console.log(`Set Prop: ${prop}`);
          if (prop in this.state) {
            let obj = {};
            obj[prop] = CD.d.row[prop];
            if (prop.indexOf("Date") >= 0) {
              obj[prop] = new Date(CD.d.row[prop]); //new Date()
            }
            this.setState(obj);
            //console.log(`Set State: ${JSON.stringify(obj)}`);
          }
        }
        this.getProductItemOptions("");
        SessionInfo.currAddGridRowFunc = this.enterInsertProductItemOption;
        this.getProductItemOptions();
        //this.setState({ selectedTopTab: 3 }); // Product Item  Tab
      }
      catch (error) {
        displayError("ProductOptionsGroupRowClick: " + error);
      }
    }
  }
  GetProductOptionImage = async (event) => {
    const imageURL = await getImageFileFromServer(this.state.PersonPhotoID);
    this.setState({ ProductOptionPhotoURL: imageURL });
  }
  ProductOptionsGroupsPageChange = (event) => {
    this.setState({ ProductOptionsGroupsSkip: event.page.skip,ProductOptionsGroupsTake: event.page.take });
  }
  ProductSIOptionsGroupsPageChange = (event) => {
    this.setState({ ProductSIOptionsGroupsSkip: event.page.skip,ProductSIOptionsGroupsTake: event.page.take });
  }
  ProductOptionsGroupsAVPageChange = (event) => {
    this.setState({ ProductOptionsGroupsAVSkip: event.page.skip,ProductOptionsGroupsAVTake: event.page.take });
  }
  //------------------------- Edit ProductSIOptionsGroup - Product Options for a particular StoreItem - Grid ------------------------------------------------------------------
  ProductOptionsGroupRowSIClick = async (event) => {
    const current = this.state.ProductSIOptionsGroups.findIndex(dataItem => dataItem === event.dataItem);
    if (current >= 0) {
      try { // Note - not using an SI specific ProductOptionsGroupID here
        let ProductOptionsGroupID = this.state.ProductSIOptionsGroups[current].ProductOptionsGroupID;
        let CD = await GetTableRow(this.ProductOptionsGroupsOID,ProductOptionsGroupID);
        this.setState({ ProductOptionsGroupID: ProductOptionsGroupID });
        for (var prop in CD.d.row) {
          //console.log(`Set Prop: ${prop}`);
          if (prop in this.state) {
            let obj = {};
            obj[prop] = CD.d.row[prop];
            if (prop.indexOf("Date") >= 0) {
              obj[prop] = new Date(CD.d.row[prop]); //new Date()
            }
            this.setState(obj);
            //console.log(`Set State: ${JSON.stringify(obj)}`);
          }
        }
        this.getProductItemOptions("");
        SessionInfo.currAddGridRowFunc = this.enterInsertProductItemOption;
        this.getProductItemOptions();
        //this.setState({ selectedTopTab: 3 }); // Product Item  Tab
      }
      catch (error) {
        displayError("ProductOptionsGroupRowClick: " + error);
      }
    }
  }
  getOptionsGroupsSI = async (search) => {
    console.log(`getOptionsGroupsSI`)
    let CD = await CallOM("GetProductOptionsForSI",0,this.state.StoreItemID);
    try {
      const ProductSIOptionsGroups = CD.d.rows.map(dataItem => Object.assign({ selected: false },dataItem));
      //console.log("ProductSIOptionsGroups " + JSON.stringify(ProductSIOptionsGroups));
      this.setState({ ProductSIOptionsGroups });
      this.state.saveProductSIOptionsGroups = ProductSIOptionsGroups;
    } catch (error) {
      displayError("getProductOptionsGroupSI: " + error.message);
    }
  }
  ProductSIOptionsGroupCommandCell;
  enterInsertProductSIOptionsGroup() {
    const dataItem = { StoreItemID: this.state.StoreItemID,MultipleCount: 0,inEdit: true };
    //console.log(`insert Group DataItem: ${JSON.stringify(dataItem)}`);
    this.setState({ ProductSIOptionsGroupName: "" });
    const allRecords = this.state.ProductSIOptionsGroups.slice();
    allRecords.unshift(dataItem); // Add to the beginning
    this.updateProductSIOptionsGroups(allRecords,dataItem);
    this.setState({ ProductSIOptionsGroups: allRecords });
  }
  enterEditProductSIOptionsGroup(dataItem) {
    console.log(`enter SIOG Row - Group DataItem: ${JSON.stringify(dataItem)}`);
    this.updateProductSIOptionsGroups(this.state.ProductSIOptionsGroups,dataItem).inEdit = true;
    this.setState({ ProductSIOptionsGroups: this.state.ProductSIOptionsGroups.slice() });
  }
  saveProductSIOptionsGroup(dataItem) {
    dataItem.inEdit = undefined;
    if (dataItem.ProductOptionsGroupID === undefined)
      dataItem.ProductOptionsGroupID = 0;
    //console.log("Save");
    dataItem.ProductOptionsGroupID = this.updateProductSIOptionsGroups(this.state.ProductSIOptionsGroups,dataItem).ProductOptionsGroupID;  // Save to OM
    this.setState({ ProductSIOptionsGroups: this.state.ProductSIOptionsGroups.slice() });
    //console.log(`Update Row - Group DataItem: ${JSON.stringify(dataItem)}`);
    UpdateRow(this.ProductOptionsGroupsOID,0,this.state.ProductSIOptionsGroups,dataItem,dataItem.ProductOptionsGroupID,"ProductOptionsGroupID");
  }
  cancelProductSIOptionsGroup(dataItem) {
    if (dataItem.ProductOptionsGroupID) {
      let originalItem = this.state.ProductSIOptionsGroups.find(p => p.ProductOptionsGroupID === dataItem.ProductOptionsGroupID)
      if (originalItem.inEdit) originalItem.inEdit = false;
      this.updateProductSIOptionsGroups(this.state.ProductSIOptionsGroups,originalItem);
    } else {
      this.updateProductSIOptionsGroups(this.state.ProductSIOptionsGroups,dataItem,!dataItem.ProductOptionsGroupID); // remove false
    }
    this.setState({ ProductSIOptionsGroups: this.state.ProductSIOptionsGroups.slice() });
  }
  removeProductSIOptionsGroup(dataItem) {
    dataItem.inEdit = undefined;
    let key = dataItem.ProductOptionsGroupID;
    this.updateProductSIOptionsGroups(this.state.ProductSIOptionsGroups,dataItem,true);
    this.state.saveProductSIOptionsGroups = this.state.ProductSIOptionsGroups;
    DeleteRowForce(this.ProductOptionsGroupsOID,key); // Delete in the ProductSIOptionsGroups Table in OM
    this.setState({ ProductSIOptionsGroups: this.state.ProductSIOptionsGroups.slice() });
  }
  itemChangeProductSIOptionsGroup(event) {
    const value = event.value;
    const name = event.field;
    if (!name) {
      return;
    }
    const updatedData = this.state.ProductSIOptionsGroups.slice();
    const item = this.updateProductSIOptionsGroups(updatedData,event.dataItem);
    if (!value.ddID)
      item[name] = value;
    else
      item[name] = value.ddID;
    this.setState({ ProductSIOptionsGroups: updatedData });
  }
  updateProductSIOptionsGroups(data,item,remove) {  // data - is the entire data set (JSON), item - is the current line 
    let updated;
    //eslint-disable-next-line                   
    let index = data.findIndex(p => p === item || item.ProductOptionsGroupID && p.ProductOptionsGroupID === item.ProductOptionsGroupID); // Note - for new line will find 0
    if (index >= 0) {
      updated = Object.assign({},item);
      data[index] = updated;
    }
    if (remove) {
      data = data.splice(index,1);
    }
    return data[index];
  }
  //------------------------- End Edit ProductSIOptionsGroup Grid ------------------------------------------------------------------
  //------------------------- Edit ProductAVOptionsGroup  ------------------------------------------------------------------  
  ProductOptionsGroupRowAVClick = async (event) => {
    console.log(`AVClick - StoreItemID: ${this.state.StoreItemID} `)
    if (this.state.StoreItemID > 0) {
      try {
        const current = this.state.ProductOptionsGroupsAV.findIndex(dataItem => dataItem === event.dataItem);
        if (current >= 0) {
          let ProductOptionsGroupID = this.state.ProductOptionsGroupsAV[current].ProductOptionsGroupID;
          let ProductOptionsGroupName = this.state.ProductOptionsGroupsAV[current].ProductOptionsGroupName;
          let CD = await CallOM("CheckStoreItemHasOption",this.state.StoreItemID,ProductOptionsGroupID,0);
          let RetVal = CD.d;
          if (RetVal === 1)
            displayWarning(`Option already exists for ${this.state.ItemName}`);
          else {
            let CD = await CallOM("AddOptionToStoreItem",this.state.StoreItemID,ProductOptionsGroupID,0);
            displayMessage(`Option ${ProductOptionsGroupName} Assigned to ${this.state.ItemName}`);
            await this.getOptionsGroupsSI('');
          }
        }
      }
      catch (error) {
        displayError("ProductOptionsGroupRowClick: " + error);
      }
    }
    else
      displayWarning("A Product must be Selected First");
  }
  getOptionsGroupsNotSI = async (search) => {
    console.log(`getOptionsGroupsNotSI`)
    let CD = await CallOM("GetProductOptionsNotInSI",0,this.state.StoreItemID,2,search);
    try {
      const ProductOptionsGroupsAV = CD.d.rows.map(dataItem => Object.assign({ selected: false },dataItem));
      //console.log("GetProductOptionsNotInSI " + JSON.stringify(ProductOptionsGroupsAV));
      this.setState({ ProductOptionsGroupsAV });
      this.state.saveProductOptionsGroupsAV = ProductOptionsGroupsAV;
    } catch (error) {
      displayError("getProductOptionsGroupNotInSI: " + error.message);
    }
  }

  //-------------------------- ProductItemOptions ----------------------------------------------------------------------------------
  getProductItemOptions = async (search) => {
    search = "*ProductOptionsGroupID=" + this.state.ProductOptionsGroupID;
    console.log(`ProductItemOptionsTable search: ${search}`)
    let CD = await GetTableSearchRaw(this.ProductItemOptionsOID,search,"ProductItemOptionID,ProductOptionName,Price,SortOrder,IsRecordActive");
    try {
      const ProductItemOptions = CD.d.rows.map(dataItem => Object.assign({ selected: false },dataItem));
      this.setState({ ProductItemOptions });
      this.state.saveProductItemOptions = ProductItemOptions;
    } catch (error) {
      displayError("getProductItemOption: " + error.message);
    }
  }
  ProductItemOptionRowClick = async (event) => {
    const current = this.state.ProductItemOptions.findIndex(dataItem => dataItem === event.dataItem);
    if (current >= 0) {
      try {
        let ProductItemOptionID = this.state.ProductItemOptions[current].ProductItemOptionID;
        let CD = await GetTableRow(this.ProductItemOptionsOID,ProductItemOptionID);
        this.setState({ ProductItemOptionID: ProductItemOptionID });
        for (var prop in CD.d.row) {
          if (prop in this.state) {
            let obj = {};
            obj[prop] = CD.d.row[prop];
            if (prop.indexOf("Date") >= 0) {
              obj[prop] = new Date(CD.d.row[prop]); //new Date()
            }
            this.setState(obj);
          }
        }
        this.setState({ selectedTopTab: 3 }); // Store Item 
      }
      catch (error) {
        displayError("ProductItemOptionRowClick: " + error);
      }
    }
  }
  ProductItemOptionsPageChange = (event) => {
    this.setState({ ProductItemOptionsSkip: event.page.skip,ProductItemOptionsTake: event.page.take });
  }

  handleSubmit = (event) => {
    event.preventDefault();
  }
  chgFldVal(event) {
    var stateCopy = Object.assign({},this.state[event.target.name]);
    stateCopy = event.target.value;
    this.setState({ [event.target.name]: stateCopy });
    this.setItemStateChanged(true);
  }
  setQuantity(event) {
    this.setState({ LockedQuantity: 0 });
    this.setState({ AvailableQuantity: event.target.value });
  }
  async chgMoneyFldVal(event) {
    //var stateCopy = Object.assign({}, this.state[event.target.name]);   
    //console.log(event);
    let newVal = event.target.value.replace(/[^\d.-]/g,''); // Matches any single single character not in the bracket [] and replaces with ''  - https://www.infobyip.com/regularexpressioncalculator.php
    //console.log(`money newval: ${newVal}, target: ${this.state[event.target.name]},S: ${this.state[event.target.name + 'S']}, name: ${event.target.name}`);
    if (this.state[event.target.name + 'S'].toString() === '0' && newVal > 9) {
      newVal /= 10;
    }
    //this.setState({ [event.target.name + 'S']: this.moneyFormat(newVal) });
    this.setState({ [event.target.name + 'S']: newVal });
    await this.setState({ [event.target.name]: newVal });
    //console.log(`updated target: ${this.state[event.target.name]}, str: ${this.state[event.target.name + 'S']}`); 
    this.setItemStateChanged(true);
  }
  async focusMoneyFldVal(event) {
    //console.log(event.target);
    //console.log(`money target: ${this.state[event.target.name]}`);
    this.setState({ [event.target.name + 'S']: this.state[event.target.name] });
    //console.log(`money target: ${this.state[event.target.name + 'S']}`);
  }
  async blurMoneyFldVal(event) {
    //console.log(`money target: ${this.state[event.target.name]}`);
    this.setState({ [event.target.name + 'S']: this.moneyFormat(this.state[event.target.name]) });
    //console.log(`money target: ${this.state[event.target.name + 'S']}`);
  }
  chgDDFldVal = (event) => {
    //console.log(event.target.props);
    //trace(`DDFld - name: ${event.target.name}, value: ${event.target.value.ddID}`); 
    this.setState({ [event.target.props.name]: event.target.value.ddID });
    this.setItemStateChanged(true);
  }
  onChangeDaysOfWeek = (event) => {
    this.setState({ DaysOfWeekVals: [...event.target.value] });
    console.log(`DaysOfWeek: ${JSON.stringify(event.target.value)}`);
    this.setItemStateChanged(true);
  }
  chgCheckboxVal(event) {
    var stateCopy = this.state[event.target.name];
    stateCopy = !stateCopy;
    this.setState({ [event.target.name]: stateCopy });
    this.setItemStateChanged(true);
    //console.log("after - target: " + event.target.name + " value: " + this.state[event.target.name] + " SelectProductItemCategories: " + this.state.SelectProductItemCategories);
  }

  //------------------------- Related Items ------------------------------------------------------------------------------------------ 
  RelatedItemRowClick = async (event) => {
  }
  //----------------------------------------------------------------------------------------------------------------------------------
  //-------------------------- RelatedItems ---------------------------------------------------------------------------------- 
  getRelatedItems = async () => {
    //search = "*StoreItemID=" + this.state.StoreItemID;
    console.log(`RelatedItems fetch`)
    let CD = await CallOM("GetRelatedItemsList",0,this.state.StoreItemID,0);
    try {
      const RelatedItems = CD.d.rows.map(dataItem => Object.assign({ selected: false },dataItem));
      console.log("RelatedItems " + JSON.stringify(RelatedItems));
      this.setState({ RelatedItems });
      this.state.saveRelatedItems = RelatedItems;
    } catch (error) {
      displayError("getRelatedItems: " + error.message);
    }
  }
  RelatedItemsRowClick = async (event) => {
    const current = this.state.RelatedItems.findIndex(dataItem => dataItem === event.dataItem);
    if (current >= 0) {
      try {
        let RelatedItemsID = this.state.RelatedItems[current].RelatedItemsID;
        let CD = await GetTableRow(this.RelatedItemsOID,RelatedItemsID);
        this.setState({ RelatedItemsID: RelatedItemsID });
        for (var prop in CD.d.row) {
          //console.log(`Set Prop: ${prop}`);
          if (prop in this.state) {
            let obj = {};
            obj[prop] = CD.d.row[prop];
            if (prop.indexOf("Date") >= 0) {
              obj[prop] = new Date(CD.d.row[prop]); //new Date()
            }
            this.setState(obj);
            //console.log(`Set State: ${JSON.stringify(obj)}`);
          }
        }
        this.getProductItemOptions("");
        SessionInfo.currAddGridRowFunc = this.enterInsertProductItemOption;
        this.getProductItemOptions();
        //this.setState({ selectedTopTab: 3 }); // Product Item  Tab
      }
      catch (error) {
        displayError("RelatedItemsRowClick: " + error);
      }
    }
  }
  GetRelatedItemsImage = async (event) => {
    const imageURL = await getImageFileFromServer(this.state.ProductOptionPhoto);
    this.setState({ ProductOptionPhotoURL: imageURL });
  }
  RelatedItemsPageChange = (event) => {
    this.setState({ RelatedItemsSkip: event.page.skip,RelatedItemsTake: event.page.take });
  }
  RelatedItemsPageChange = (event) => {
    this.setState({ ProductSIOptionsGroupsSkip: event.page.skip,ProductSIOptionsGroupsTake: event.page.take });
  }
  RelatedItemsPageChange = (event) => {
    this.setState({ RelatedItemsAVSkip: event.page.skip,RelatedItemsAVTake: event.page.take });
  }
  //------------------------- Edit RelatedItems - Related Items for a particular StoreItem - Grid ------------------------------------------------------------------
  RelatedItemsClick = async (event) => {
    const current = this.state.RelatedItems.findIndex(dataItem => dataItem === event.dataItem);
    if (current >= 0) {
      try { // Note - not using an SI specific RelatedItemsID here
        let RelatedItemsID = this.state.RelatedItems[current].RelatedItemsID;
        let CD = await GetTableRow(this.RelatedItemsOID,RelatedItemsID);
        this.setState({ RelatedItemsID: RelatedItemsID });
        for (var prop in CD.d.row) {
          //console.log(`Set Prop: ${prop}`);
          if (prop in this.state) {
            let obj = {};
            obj[prop] = CD.d.row[prop];
            if (prop.indexOf("Date") >= 0) {
              obj[prop] = new Date(CD.d.row[prop]); //new Date()
            }
            this.setState(obj);
            //console.log(`Set State: ${JSON.stringify(obj)}`);
          }
        }
        //this.getProductItemOptions("");
        //SessionInfo.currAddGridRowFunc = this.enterInsertProductItemOption;
        //this.getProductItemOptions();
        //this.setState({ selectedTopTab: 3 }); // Product Item  Tab
      }
      catch (error) {
        displayError("RelatedItemsRowClick: " + error);
      }
    }
  }
  RelatedItemsCommandCell;
  enterInsertRelatedItems() {
    const dataItem = { StoreItemID: this.state.StoreItemID,MultipleCount: 0,inEdit: true };
    console.log(`insert Related Item: ${JSON.stringify(dataItem)}`);
    this.setState({ AssociatedItemID: "" });
    const allRecords = this.state.RelatedItems.slice();
    allRecords.unshift(dataItem); // Add to the beginning
    this.updateRelatedItems(allRecords,dataItem);
    this.setState({ RelatedItems: allRecords });
  }
  enterEditRelatedItems(dataItem) {
    console.log(`enter Row - Group DataItem: ${JSON.stringify(dataItem)}`);
    this.updateRelatedItems(this.state.RelatedItems,dataItem).inEdit = true;
    this.setState({ RelatedItems: this.state.RelatedItems.slice() });
  }
  saveRelatedItems(dataItem) {
    dataItem.inEdit = undefined;
    if (dataItem.RelatedItemsID === undefined)
      dataItem.RelatedItemsID = 0;
    //console.log("Save");
    dataItem.RelatedItemsID = this.updateRelatedItems(this.state.RelatedItems,dataItem).RelatedItemsID;  // Save to OM
    this.setState({ RelatedItems: this.state.RelatedItems.slice() });
    //console.log(`Update Row - Group DataItem: ${JSON.stringify(dataItem)}`);
    UpdateRow(this.RelatedItemsOID,0,this.state.RelatedItems,dataItem,dataItem.RelatedItemsID,"RelatedItemsID");
  }
  cancelRelatedItems(dataItem) {
    if (dataItem.RelatedItemsID) {
      let originalItem = this.state.RelatedItems.find(p => p.RelatedItemsID === dataItem.RelatedItemsID)
      if (originalItem.inEdit) originalItem.inEdit = false;
      this.updateRelatedItems(this.state.RelatedItems,originalItem);
    } else {
      this.updateRelatedItems(this.state.RelatedItems,dataItem,!dataItem.RelatedItemsID); // remove false
    }
    this.setState({ RelatedItems: this.state.RelatedItems.slice() });
  }
  removeRelatedItems(dataItem) {
    dataItem.inEdit = undefined;
    let key = dataItem.RelatedItemsID;
    this.updateRelatedItems(this.state.RelatedItems,dataItem,true);
    this.state.saveRelatedItems = this.state.RelatedItems;
    DeleteRowForce(this.RelatedItemsOID,key); // Delete in the RelatedItems Table in OM
    this.setState({ RelatedItems: this.state.RelatedItems.slice() });
  }
  itemChangeRelatedItems(event) {
    const value = event.value;
    const name = event.field;
    if (!name) {
      return;
    }
    const updatedData = this.state.RelatedItems.slice();
    const item = this.updateRelatedItems(updatedData,event.dataItem);
    if (!value.ddID)
      item[name] = value;
    else
      item[name] = value.ddID;
    this.setState({ RelatedItems: updatedData });
  }
  updateRelatedItems(data,item,remove) {  // data - is the entire data set (JSON), item - is the current line 
    let updated;
    //eslint-disable-next-line                   
    let index = data.findIndex(p => p === item || item.RelatedItemsID && p.RelatedItemsID === item.RelatedItemsID); // Note - for new line will find 0
    if (index >= 0) {
      updated = Object.assign({},item);
      data[index] = updated;
    }
    if (remove) {
      data = data.splice(index,1);
    }
    return data[index];
  }
  //------------------------- End Edit RelatedItems Grid ------------------------------------------------------------------
  //------------------------- Main Items ------------------------------------------------------------------  
  mainStoreItemClick = async (event) => { // Copy ProductItem to Related Items
    if (!this.state.MainStoreItems)
      await this.saveRecord();
    const current = this.state.MainStoreItems.findIndex(dataItem => dataItem === event.dataItem);
    this.state.MainStoreItems.forEach(item => item.selected = false);
    this.state.MainStoreItems[current].selected = true;
    let associatedStoreItemID = this.state.MainStoreItems[current].StoreItemID;
    let item = JSON.parse(JSON.stringify(this.state.MainStoreItems[current])); // Object Copy - Value copy
    console.log("item: " + JSON.stringify(item));
    let index = this.state.RelatedItems.findIndex(p => p.AssociatedStoreItemID === associatedStoreItemID); // Look to see if already copied
    //console.log("Select PKID: " + PKID + " index: " + index);
    if (index < 0) {
      //this.enterInsertRelatedItems();
      const dataItem = { StoreItemID: this.state.StoreItemID,MultipleCount: 0,inEdit: true };
      console.log(`insert Related Item: ${JSON.stringify(dataItem)}`);
      console.log(`insert Store Item: ${this.state.StoreItemID}, Associated: ${associatedStoreItemID}`);
      //this.setState({ AssociatedItemID: item["ItemName"] });
      //const allRecords = this.state.RelatedItems.slice();
      //allRecords.unshift(dataItem); // Add to the beginning
      //this.updateRelatedItems(allRecords, dataItem);
      //this.setState({ RelatedItems: allRecords });
      //console.log("Save StoreItems - StoreLocationID: " + item.StoreLocationID + " StoreItemID: " + item.StoreItemID + " Ref: " + item.ReferenceItemID);
      let CD = await CallOM("AddRelatedStoreItem",0,this.state.StoreItemID,associatedStoreItemID);
      let RetVal = CD.d;
      console.log(`Retval: ${RetVal}`);
      if (RetVal === 1) {
        //await UpdateRow(544619, 0, this.state.StoreItems, item, 0, "StoreItemID"); Save Record - this does not work as did not load entire record 
        await this.getRelatedItems();
      }
    }
    else
      displayWarning(`Item Copy for ${this.state.StoreItems[index].ItemName} Failed`);
  }
  sortChange = (event) => {
    this.setState({ products: this.GetProducts(event.sort),sort: event.sort });
  }
  getMainProductItemsSort = (sort) => {
    return orderBy(this.state.MainStoreItems,sort);
  }
  mainStoreItemsPageChange = (event) => {
    this.setState({ mainStoreItemsSkip: event.page.skip,mainStoreItemsTake: event.page.take });
  }
  //-------------------------------------------------------------------------------------------------------
  //------------------------- Field Edit ------------------------------------------------------------------ 
  _export;
  exportExcel = () => {
    this._export.save();
  }

  render() {
    try {
      return (
        <div id="StoreItemDetail" className="pageMain">
          <PageSide />
          <PageHeader L1='Home' Select='3' PageTitle='Product Catalog' IsApp='y' ItemName='Item' />
          <div id="mainCntr">
            <Splitter style={{ height: '100%',border: 'none' }} panes={this.state.horizontalPanes} orientation={'horizontal'} onChange={this.onHorizontalLayoutChange}>
              <div id="splitterLeft" className="splitPaneContent">
                <ExcelExport data={this.state.StoreItems} ref={(exporter) => { this._export = exporter; }}>
                  {/*<TGrid style={{ position: 'absolute',top: 0,height: '100%' }} data={orderBy(filterBy(this.state.StoreItems,this.state.itemFilter),this.state.itemSort).slice(this.state.storeItemsSkip,this.state.storeItemsTake + this.state.storeItemsSkip)}*/}
                  <TGrid style={{ position: 'absolute',top: 0,height: '100%' }} data={orderBy(this.state.StoreItems,this.state.itemSort).slice(this.state.storeItemsSkip,this.state.storeItemsTake + this.state.storeItemsSkip)}
                    selectedField="selected" editField="inEdit" onItemChange={this.itemChangeSI}
                    skip={this.state.storeItemsSkip} take={this.state.storeItemsTake} total={this.state.StoreItems.length} onPageChange={this.storeItemsPageChange}
                    onRowClick={this.storeItemRowClick}
                    resizable={true} sortable={true} sort={this.state.itemSort} onSortChange={(e) => { this.setState({ itemSort: e.sort }); }}
                    pageable={{ info: true,type: 'numeric',pageSizes: true,previousNext: true }} pageSize={8}>
                    <Column field="ItemName" title="Item Name" width='200px' />
                    <Column field="ItemDescription" title="Description" width='180px' />
                    {SessionInfo.CInfo.HasBarcode === true ?
                      <Column field="BarcodeNumber" title="Barcode" width='130px' /> : null}
                    <Column field="TimeOfDayID" title="Time of Day" cell={TimeOfDayDropDownCell} width='110px' />
                    <Column field="DayOfWeek" title="Day of Week" cell={DayOfWeekDropDownCell} width='110px' />
                    <Column field="ItemPrice" title="Item Price" filter='numeric' cell={MoneyCell} width='110px' />
                    <Column field="ProductItemCategoryID" title="Category" cell={ProductItemCategoryDropDownCell} width='110px' />
                    <Column field="ProductItemGroupID" title="Group" cell={ProductItemGroupDropDownCell} width='110px' />
                    <Column field="PricingTypeID" title="Pricing Type" cell={PricingTypeDropDownCell} width='110px' />
                    <Column field="Image" cell={ImageCell} title="." width='50px' />
                    <Column cell={this.StoreItemCommandCell} width="150px" />
                  </TGrid>
                </ExcelExport>
              </div>
              <Splitter style={{ height: '100%',border: 'none' }} panes={this.state.verticalPanes} orientation={'vertical'} onChange={this.onNestedLayoutChange}>
                <div id="splitterRight" className="splitPaneContent">
                  <TabStrip selected={this.state.selectedTopTab} name="Product Description" onSelect={this.handleTopTabSelect} animation={true} className="tabStrip">
                    <TabStripTab title="Item Information">
                      <div className="editTab">
                        <div className="editTabLeft">
                          <div id="div1" className="editInside">
                            <h4>Item Description</h4>
                            {(SessionInfo.PermLevel >= 499) ?
                              <>
                                <br />
                                <div id='div2' className='editField'>
                                  <span className='editFieldLabel'>Store Item ID</span>
                                  <input value={this.state.StoreItemID} name='StoreItemID' onChange={evt => this.chgFldVal(evt)} readOnly={true} className='editNumInputRO' />
                                </div>
                              </> : null}
                            <br />
                            <div className="editField">
                              <span className="editFieldLabel">Item Name</span>
                              <input value={this.state.ItemName} name="ItemName" onChange={evt => this.chgFldVal(evt)} className="editInput" />
                            </div>
                            {SessionInfo.CInfo.HasBarcode === true ?
                              <div className="editField">
                                <span className="editFieldLabel">Barcode Number</span>
                                <input value={this.state.BarcodeNumber} name="BarcodeNumber" onChange={evt => this.chgFldVal(evt)} className="editInputMedium" />
                              </div> : null}
                            <div id="div2" className="editField">
                              <span className="editFieldLabel">Item Price</span>
                              <input value={this.state.ItemPriceS} name="ItemPrice" onChange={evt => this.chgMoneyFldVal(evt)} onFocus={evt => this.focusMoneyFldVal(evt)} onBlur={evt => this.blurMoneyFldVal(evt)} className="editMoneyInput" />
                            </div>
                            <div className="editField">
                              <span className="editFieldLabel">Rate Type</span>
                              <div className="editDDPos">
                                <DropDownList data={this.state.RateTypes} textField="ddName" dataItemKey="ddID" value={this.state.RateTypes.find(c => c.ddID === this.state.RateTypeID)} name="RateTypeID" onChange={evt => this.chgDDFldVal(evt)} className="editInputDD" />
                              </div>
                            </div>
                            <div className="editField">
                              <span className="editFieldLabel">Item Status</span>
                              <div className="editDDPos">
                                <DropDownList data={this.state.ItemStatusSet} textField="ddName" dataItemKey="ddID" value={this.state.ItemStatusSet.find(c => c.ddID === this.state.ItemStatusID)} name="ItemStatusID" onChange={evt => this.chgDDFldVal(evt)} className="editInputDD" />
                              </div>
                            </div>
                            <div id='div2' className="editTAField">
                              <span className="editFieldLabel">Item Description</span>
                              <textarea value={this.state.ItemDescription} name="ItemDescription" onChange={evt => this.chgFldVal(evt)} className="editTAInput" />
                            </div>
                            <div className="editField">
                              <span className="editFieldLabel">Item Note</span>
                              <input value={this.state.ItemNote} name="ItemNote" onChange={evt => this.chgFldVal(evt)} className="editInput" />
                            </div>
                            <br />

                            <h4>Item Classification</h4>
                            <br />
                            <div className="editField">
                              <HelpAbout fill={SessionInfo.activeColor} alt="" id="ShowHelp" title="Show Help for Category" className="showFieldHelp" onClick={evt => this.ShowFieldHelp(1104100)} />
                              <span className="editFieldLabel">Item Category</span>
                              <div className="editDDPos">
                                <DropDownList data={this.state.ProductItemCategories} textField="ddName" dataItemKey="ddID" value={this.state.ProductItemCategories.find(c => c.ddID === this.state.ProductItemCategoryID)} name="ProductItemCategoryID" onChange={evt => this.chgDDFldVal(evt)} className="editInputDD" />
                              </div>
                            </div>
                            <div className="editField">
                              <HelpAbout fill={SessionInfo.activeColor} alt="" id="ShowHelp" title="Show Help for Group" className="showFieldHelp" onClick={evt => this.ShowFieldHelp(1104094)} />
                              <span className="editFieldLabel">Item Group</span>
                              <div className="editDDPos">
                                <DropDownList data={this.state.ProductItemGroups} textField="ddName" dataItemKey="ddID" value={this.state.ProductItemGroups.find(c => c.ddID === this.state.ProductItemGroupID)} name="ProductItemGroupID" onChange={evt => this.chgDDFldVal(evt)} className="editInputDD" />
                              </div>
                            </div>
                            <div className="editField">
                              <HelpAbout fill={SessionInfo.activeColor} alt="" id="ShowHelp" title="Show Help for Type" className="showFieldHelp" onClick={evt => this.ShowFieldHelp(1104096)} />
                              <span className="editFieldLabel">Item Type</span>
                              <div className="editDDPos">
                                <DropDownList data={this.state.ProductItemTypes} textField="ddName" dataItemKey="ddID" value={this.state.ProductItemTypes.find(c => c.ddID === this.state.ProductItemTypeID)} name="ProductItemTypeID" onChange={evt => this.chgDDFldVal(evt)} className="editInputDD" />
                              </div>
                            </div>
                            <br />
                            <br />
                            <div className="editField">
                              <span className='editFieldLabel'>Season Code</span>
                              <div className='editDDPos'>
                                <DropDownList data={this.state.SeasonCodesDict} textField='ddName' dataItemKey='ddID' value={this.state.SeasonCodesDict.find(c => c.ddID === this.state.SeasonCodeID)} name='SeasonCodeID' onChange={evt => this.chgDDFldVal(evt)} className='editInputDD' />
                              </div>
                            </div>
                            <div className="editField">
                              <span className="editFieldLabel">Time Of Day</span>
                              <div className="editDDPos">
                                <DropDownList data={this.state.TimeOfDayDict} textField="ddName" dataItemKey="ddID" value={this.state.TimeOfDayDict.find(c => c.ddID === this.state.TimeOfDayID)} name="TimeOfDayID" onChange={evt => this.chgDDFldVal(evt)} className="editInputDD" />
                              </div>
                            </div>
                            <div className="editField">
                              <span className="editFieldLabel">Day of Week</span>
                              <div className="editDDPos">
                                <DropDownList data={this.state.DayOfWeekDict} textField="ddName" dataItemKey="ddID" value={this.state.DayOfWeekDict.find(c => c.ddID === this.state.DayOfWeek)} name="DayOfWeek" onChange={evt => this.chgDDFldVal(evt)} className="editInputDD" />
                              </div>
                            </div>
                            {SessionInfo.CInfo.HasDaysOfWeek === true ?
                              <div className="editTAField">
                                <span className="editFieldLabel">Days of Week</span>
                                <div className="editDDPos">
                                  <MultiSelect data={DaysOfWeekList} value={this.state.DaysOfWeekVals} name="DaysOfWeek" onChange={this.onChangeDaysOfWeek} className="editInputDD" />
                                </div>
                              </div> : null}
                            <br />
                            <div>
                              <h4>Detailed Information</h4>
                              <br />
                              <div id='div2' className="editTAHField">
                                <span className="editFieldLabel">Full Description</span>
                                <textarea value={this.state.ItemFullDescription} name="ItemFullDescription" onChange={evt => this.chgFldVal(evt)} className="editTAInputHigh" />
                              </div>
                              {(SessionInfo.AppCfg & ENAppCnfg.HasIngredients) > 0 ?
                                <div id='div2' className='editTAHField'>
                                  <span className='editFieldLabel'>Ingredients</span>
                                  <textarea value={this.state.IngredientsText} name='IngredientsText' onChange={evt => this.chgFldVal(evt)} className='editTAInputHigh' />
                                </div> : null}
                            </div>
                          </div>
                        </div>
                        <div className="editTabRight">
                          <div style={{ position: "relative",top: "5px",border: "none" }}>
                            <h4>Item Control Information</h4>
                            <br />
                            <div className="editField">
                              <span className="editFieldLabel">Pricing Type</span>
                              <div className="editDDPos">
                                <DropDownList data={this.state.PricingTypes} textField="ddName" dataItemKey="ddID" value={this.state.PricingTypes.find(c => c.ddID === this.state.PricingTypeID)} name="PricingTypeID" onChange={evt => this.chgDDFldVal(evt)} className="editInputDD" />
                              </div>
                            </div>
                            <div id='div2' className='editField'>
                              <span className='editFieldLabel'>Override Price</span>
                              <input value={this.state.OverridePriceS} name='OverridePrice' onChange={evt => this.chgMoneyFldVal(evt)} onFocus={evt => this.focusMoneyFldVal(evt)} onBlur={evt => this.blurMoneyFldVal(evt)} className='editMoneyInput' />
                            </div>
                            <div id='div2' className='editField'>
                              <span className='editFieldLabel'>Promotion Price</span>
                              <input value={this.state.PromotionPriceS} name='PromotionPrice' onChange={evt => this.chgMoneyFldVal(evt)} onFocus={evt => this.focusMoneyFldVal(evt)} onBlur={evt => this.blurMoneyFldVal(evt)} className='editMoneyInput' />
                            </div>
                            <div className="editField">
                              <span className="editFieldLabel">Promotion Type</span>
                              <div className="editDDPos">
                                <DropDownList data={this.state.PromotionTypes} textField="ddName" dataItemKey="ddID" value={this.state.PromotionTypes.find(c => c.ddID === this.state.PromotionType)} name="PromotionType" onChange={evt => this.chgDDFldVal(evt)} className="editInputDD" />
                              </div>
                            </div>
                            <div className="editField">
                              <span className="editFieldLabel">Taxable Type</span>
                              <div className="editDDPos">
                                <DropDownList data={this.state.TaxableTypes} textField="ddName" dataItemKey="ddID" value={this.state.TaxableTypes.find(c => c.ddID === this.state.TaxableTypeID)} name="TaxableTypeID" onChange={evt => this.chgDDFldVal(evt)} className="editInputDD" />
                              </div>
                            </div>
                          </div>
                          <br />
                          <h4>Diet Control Information</h4>
                          <br />
                          <div id="div2" className="editField">
                            <Switch checked={this.state.DietModifiable} name="DietModifiable" onChange={evt => this.chgCheckboxVal(evt)} /><span className="editFieldLabel"> &nbsp; Is Modifiable for Diet</span>
                          </div>
                          <div id="div2" className="editField">
                            <Switch checked={this.state.DietVegetarian} name="DietVegetarian" onChange={evt => this.chgCheckboxVal(evt)} /><span className="editFieldLabel"> &nbsp; Is Vegetarian</span>
                          </div>
                          <div id="div2" className="editField">
                            <Switch checked={this.state.DietVegan} name="DietVegan" onChange={evt => this.chgCheckboxVal(evt)} /><span className="editFieldLabel"> &nbsp; Is Vegan</span>
                          </div>
                          <div id="div2" className="editField">
                            <Switch checked={this.state.DietNutAlergy} name="DietNutAlergy" onChange={evt => this.chgCheckboxVal(evt)} /><span className="editFieldLabel"> &nbsp; No Nuts</span>
                          </div>
                          <div id="div2" className="editField">
                            <Switch checked={this.state.DietLactose} name="DietLactose" onChange={evt => this.chgCheckboxVal(evt)} /><span className="editFieldLabel"> &nbsp; No Lactose</span>
                          </div>
                          <div id="div2" className="editField">
                            <Switch checked={this.state.DietGluten} name="DietGluten" onChange={evt => this.chgCheckboxVal(evt)} /><span className="editFieldLabel"> &nbsp; Gluten Free</span>
                          </div>
                          <div id="div2" className="editField">
                            <Switch checked={this.state.DietOther} name="DietOther" onChange={evt => this.chgCheckboxVal(evt)} /><span className="editFieldLabel"> &nbsp; Other Restrictions</span>
                          </div>
                          <br />
                          <div id='div2' className='editField'>
                            <span className='editFieldLabel'>Calories</span>
                            <input value={this.state.Calories} name='Calories' onChange={evt => this.chgFldVal(evt)} className='editNumInput' readOnly='{true}' />
                          </div>
                          {/*<div style={{ position: "relative", top: "20px", border: "none" }}>*/}
                          <br />
                          <div id="div1" className="editPicField">
                            <h4>Product Image</h4>
                            <div className="framePic">
                              <Avatar rounded='large' size='large' type='image' className='avatarFramePic'>
                                <img src={this.state.ProductItemPhotoURL} height={280} width={360} style={{ objectFit: 'fill',}} />
                              </Avatar>
                            </div>
                            <div className="framePicUpload">
                              {/*<ExternalDropZone*/}
                              {/*  uploadRef={uploadRef}*/}
                              {/*  customHint={<span>Drop the Item Image Here</span>}*/}
                              {/*  customNote={<span>The Image should have an aspect ratio of 4:3</span>}*/}
                              {/*  style={{ backgroundColor: "antiquewhite",height: "10px",width: "370px" }}*/}
                              {/*/>*/}
                              <div style={{ position: "relative",top: "0px",width: "370px",border: "solid 2px grey" }}>
                                <Upload
                                  ref={uploadRef}
                                  batch={false}
                                  multiple={true}
                                  files={this.state.files}
                                  onAdd={this.addStoreItemImage}
                                  onRemove={this.onRemove}
                                  onStatusChange={this.onStatusChange}
                                  saveUrl={null}
                                  defaultFiles={[]}
                                  withCredentials={false}
                                />
                              </div>
                            </div>
                          </div>
                          <div>
                            <br />
                            {(SessionInfo.AppCfg & ENAppCnfg.HasIngredients) > 0 ?
                              <div id='div2' className='editTAHField'>
                                <span className='editFieldLabel'>Usage Instructions</span>
                                <textarea value={this.state.UsageInstructions} name='UsageInstructions' onChange={evt => this.chgFldVal(evt)} className='editTAInputHigh' />
                              </div> : null}
                          </div>
                        </div>
                      </div>
                    </TabStripTab>
                    <TabStripTab title="Additional Information">
                      <div className="editTab">
                        <div className="editTabLeft">
                          <div id="div1" className="editInside">
                            <h4>Item Inventory Information</h4>
                            <br />
                            <div className="editField">
                              <span className="editFieldLabel">Barcode Number</span>
                              <input value={this.state.BarcodeNumber} name="BarcodeNumber" onChange={evt => this.chgFldVal(evt)} className="editInputMedium" />
                            </div>
                            <div id="div2" className="editField">
                              <span className="editFieldLabel">UPC Number</span>
                              <input value={this.state.UPCNumber} name="UPCNumber" onChange={evt => this.chgFldVal(evt)} className="editInputMedium" />
                            </div>
                            <div id="div2" className="editField">
                              <span className="editFieldLabel">Shelf Life Days</span>
                              <input value={this.state.ShelfLifeDays} name="ShelfLifeDays" onChange={evt => this.chgFldVal(evt)} className="editNumShortInput" />
                            </div>
                            <div id='div2' className='editField'>
                              <span className='editFieldLabel'>Item Net Weight</span>
                              <input value={this.state.ItemNetWeight} name='ItemNetWeight' onChange={evt => this.chgFldVal(evt)} className='editNumInput' />
                            </div>
                            <div id='div2' className='editField'>
                              <span className='editFieldLabel'>Storage Control</span>
                              <div className='editDDPos'>
                                <DropDownList data={this.state.StorageControlsDict} textField='ddName' dataItemKey='ddID' value={this.state.StorageControlsDict.find(c => c.ddID === this.state.StorageControlID)} name='StorageControlID' onChange={evt => this.chgDDFldVal(evt)} className='editInputDD' />
                              </div>
                            </div>
                            <div id='div2' className='editField'>
                              <span className='editFieldLabel'>Inventory Control</span>
                              <div className='editDDPos'>
                                <DropDownList data={this.state.InventoryControlTypeIDs} textField='ddName' dataItemKey='ddID' value={this.state.InventoryControlTypeIDs.find(c => c.ddID === this.state.InventoryControlTypeID)} name='InventoryControlTypeID' onChange={evt => this.chgDDFldVal(evt)} className='editInputDD' />
                              </div>
                            </div>
                            <div id='div2' className='editField'>
                              <span className='editFieldLabel'>Total Quantity</span>
                              <input value={this.state.TotalQuantity} name='TotalQuantity' onChange={evt => this.chgFldVal(evt)} onBlur={evt => this.setQuantity(evt)} className='editNumInput' />
                            </div>
                            <div id='div2' className='editField'>
                              <span className='editFieldLabel'>Locked Quantity</span>
                              <input value={this.state.LockedQuantity} name='LockedQuantity' onChange={evt => this.chgFldVal(evt)} className='editNumInputRO' readOnly={true} />
                            </div>
                            <div id='div2' className='editField'>
                              <span className='editFieldLabel'>Available Quantity</span>
                              <input value={this.state.AvailableQuantity} name='AvailableQuantity' onChange={evt => this.chgFldVal(evt)} className='editNumInputRO' readOnly={true} />
                            </div>
                            <div id='div2' className='editField'>
                              <span className='editFieldLabel'>Received Quantity</span>
                              <input value={this.state.TotalReceivedQuantity} name='TotalReceivedQuantity' onChange={evt => this.chgFldVal(evt)} className='editNumInputRO' readOnly={true} />
                            </div>
                            <div id='div2' className='editField'>
                              <span className='editFieldLabel'>Total Sold Quantity</span>
                              <input value={this.state.TotalSoldQuantity} name='TotalSoldQuantity' onChange={evt => this.chgFldVal(evt)} className='editNumInputRO' readOnly={true} />
                            </div>
                          </div>
                        </div>
                        <div className="editTabRight">
                          <div style={{ position: "relative",top: "5px",border: "none" }}>
                            <h4>Item Content and Usage</h4>
                            <br />
                            <div id='div2' className='editTAField'>
                              <span className='editFieldLabel'>Ingredients</span>
                              <textarea value={this.state.IngredientsText} name='IngredientsText' onChange={evt => this.chgFldVal(evt)} className='editTAInput' />
                            </div>
                            <div id='div2' className='editTAField'>
                              <span className='editFieldLabel'>Usage Instructions</span>
                              <textarea value={this.state.UsageInstructions} name='UsageInstructions' onChange={evt => this.chgFldVal(evt)} className='editTAInput' />
                            </div>
                            <br />
                            <h4>Item Order Information</h4>
                            <br />
                            <div className="editField">
                              <span className="editFieldLabel">Brand</span>
                              <div className="editDDPos">
                                <DropDownList data={this.state.Brands} textField="ddName" dataItemKey="ddID" value={this.state.Brands.find(c => c.ddID === this.state.BrandID)} name="BrandID" onChange={evt => this.chgDDFldVal(evt)} className="editInputDD" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </TabStripTab>
                    <TabStripTab title="Product Options">
                      <div className="editTab">
                        <div className="editTabLeftFixed">
                          <TGrid style={{ position: 'absolute',top: 0,height: '100%' }}
                            data={this.state.ProductSIOptionsGroups.slice(this.state.ProductSIOptionsGroupsSkip,this.state.ProductSIOptionsGroupsTake + this.state.ProductSIOptionsGroupsSkip)}
                            onItemChange={this.itemChangeProductSIOptionsGroup} resizable={true} selectedField="selected" editField="inEdit"
                            onRowClick={this.ProductOptionsGroupRowSIClick} resizable={true}
                            skip={this.state.ProductSIOptionsGroupsSkip} take={this.state.ProductSIOptionsGroupsTake} total={this.state.ProductSIOptionsGroups.length} onPageChange={this.ProductSIOptionsGroupsPageChange} pageable={{ info: true,type: 'numeric',pageSizes: true,previousNext: true }} pageSize={6}>
                            <Column field="ProductOptionsGroupName" title="Product Options Groups" width='300px' editor="text" />
                            <Column field="IsRequired" cell={BoolCell} title="Required" width='100px' />
                            <Column field="AllowMultiple" cell={BoolCell} title="Multiple" width='100px' />
                            <Column field="MultipleCount" title="Max Count" width='110px' filter='numeric' />
                            <Column field="SortOrder" title="Sort" width='70px' filter='numeric' />
                            <Column cell={this.ProductSIOptionsGroupCommandCell} width="260px" />
                          </TGrid>
                        </div>
                        <div className="editTabMidRightNarrow" style={{ width: '100px' }}>
                          <div id="div1" className="editInside">
                            <div id="div1" className="headerTitleWide">
                              <Tooltip position="top" anchorElement="pointer">
                                <div title="Click on any Item in the Grid to Copy the Item to the Current Store">
                                  <h4>Available Product Options &nbsp;</h4>
                                </div>
                              </Tooltip>
                              <h6>Click on Option to Copy to Current Product</h6>
                            </div>
                            <br />
                            <div id="div1" className="headerGrid">
                              <TGrid style={{ position: 'absolute',top: 0,height: '100%' }} data={this.state.ProductOptionsGroupsAV.slice(this.state.ProductOptionsGroupsAVSkip,this.state.ProductOptionsGroupsAVTake + this.state.ProductOptionsGroupsAVSkip)}
                                onRowClick={this.ProductOptionsGroupRowAVClick} resizable={true}
                                skip={this.state.ProductOptionsGroupsAVSkip} take={this.state.ProductOptionsGroupsAVTake} total={this.state.ProductOptionsGroupsAV.length} onPageChange={this.ProductOptionsGroupsAVPageChange} pageable={{ info: true,type: 'numeric',pageSizes: true,previousNext: true }} pageSize={6}>
                                <Column field="ProductOptionsGroupName" title="Product Options Groups" width='300px' editor="text" />
                              </TGrid>
                            </div>
                          </div>
                        </div>
                      </div>
                    </TabStripTab>
                    <TabStripTab title="Related Items">
                      <div className="editTab">
                        <div className="editTabLeftFixed">
                          <TGrid style={{ position: 'absolute',top: 0,height: '100%' }} data={orderBy(filterBy(this.state.RelatedItems,this.state.relatedItemFilter),this.state.relatedItemSort).slice(this.state.RelatedItemSkip,this.state.relatedItemsTake + this.state.RelatedItemSkip)}
                            selectedField="selected" editField="inEdit" onItemChange={this.itemChangeRelatedItems}
                            skip={this.state.RelatedItemSkip} take={this.state.relatedItemsTake} total={this.state.RelatedItems.length} onPageChange={this.RelatedItemsPageChange}
                            onRowClick={this.RelatedItemRowClick}
                            resizable={true} sortable={true} sort={this.state.relatedItemSort} onSortChange={(e) => { this.setState({ relatedItemSort: e.sort }); }}
                            pageable={{ info: true,type: 'numeric',pageSizes: true,previousNext: true }} pageSize={8}>
                            <Column field="AssociatedItem" title="Associated Item" width='180px' />
                            <Column field="AssociatedItemCount" title="Count" width='80px' />
                            <Column field="ProductItemCategoryID" title="Category" cell={ProductItemCategoryDropDownCell} width='100px' />
                            <Column field="ProductItemGroupID" title="Group" cell={ProductItemGroupDropDownCell} width='80px' />
                            <Column field="ProductItemTypeID" title="Type" cell={ProductTypeDropDownCell} width='80px' />
                            <Column field="ItemAssociationType" title="Relation" cell={ItemAssociationTypeDropDownCell} width='160px' />
                            {/*<Column field="ItemPrice" title="Item Price" filter='numeric' cell={MoneyCell} width='80px' />*/}
                            {/*<Column field="Image" cell={ImageCell} title="." width='75px' />*/}
                            <Column cell={this.RelatedItemCommandCell} width="160px" />
                          </TGrid>
                        </div>
                        <div className="editTabMidRightNarrow" style={{ width: '100px' }}>
                          <div id="div1" className="editInside">
                            <div id="div1" className="headerTitleWide">
                              <Tooltip position="top" anchorElement="pointer">
                                <div title="Click on any Item in the Grid to Copy the Item to the Current Store">
                                  <h4>Company Main Item List</h4>
                                </div>
                              </Tooltip>
                              <h6>Click on Item to Set as Related Item</h6>
                            </div>
                            <br />
                            <div id="div1" className="headerGrid">
                              <TGrid style={{ position: 'absolute',top: 0,height: '100%' }} data={this.state.MainStoreItems.slice(this.state.mainStoreItemsSkip,this.state.mainStoreItemsTake + this.state.mainStoreItemsSkip)} selectedField="selected" sortable={{ allowUnsort: true,mode: 'multiple' }}
                                skip={this.state.mainStoreItemsSkip} take={this.state.mainStoreItemsTake} total={this.state.MainStoreItems.length} onPageChange={this.mainStoreItemsPageChange}
                                onRowClick={this.mainStoreItemClick} selectedField="selected" resizable={true} sort={this.state.sortMainStoreItems} onSortChange={(event) => { this.setState({ MainStoreItems: this.getMainProductItemsSort(event.sort),sortMainStoreItems: event.sort }); }}
                                pageable={{ info: true,type: 'numeric',pageSizes: true,previousNext: true }}
                                pageSize={18}>
                                <Column field="selected" width="0px" headerSelectionValue={this.state.MainStoreItems.findIndex(dataItem => dataItem.selected === false) === -1} />
                                <Column field="ItemName" title="Item Name" width='180px' />
                                <Column field="ItemDescription" title="Description" width='180px' />
                                <Column field="ItemPrice" title="Price" cell={MoneyCell} width='120px' />
                              </TGrid>
                            </div>
                          </div>
                        </div>
                      </div>
                    </TabStripTab>
                  </TabStrip>
                </div>
                <div id="splitterBottom" className="splitPaneContent">
                </div>
              </Splitter>
            </Splitter>
            <Popup offset={this.helpListPopupOffset} show={this.state.showHelpListPopup} popupClass={'popupHelp'} >
              <div id="helpBox">
                <div className="helpList">
                  <ListView data={this.state.HelpPages} item={this.HelpPageFormat} header={this.HelpPageHeader} footer={this.HelpPageFooter} />
                </div>
                <br />
              </div>
            </Popup>
            <Popup offset={this.helpDetailOffset} show={this.state.showHelpDetail} popupClass={'popupHelpDetail'} onClick={this.closeHelpTime}>
              <div className="frameInfo" id="notifyBoxPU" dangerouslySetInnerHTML={{ __html: this.state.helpDetail }} onClick={this.closeHelpTime}></div>
            </Popup>
          </div>
          {SessionInfo.displayFooter === true ?
            <PageFooter L1='Home' /> : null}
        </div>
      );
    }
    catch (error) {
      notify("Failure in StoreItem render: " + error.message);
      ////this.props.navigate("/AppMain");
      //notify("Session Timeout - Login Required");
    }
    return (
      <div id="StoreItemDetail" className="pageMain">
        <PageHeader L1='Home' Select='5' PageTitle='Workflow Descriptions' IsApp='y' ItemName='Workflow' />
        <div id="mainCntr">
        </div>
        {SessionInfo.displayFooter === true ?
          <PageFooter L1='Home' /> : null}
      </div>
    );
  }
}

export default withRouter(StoreItemDetail);