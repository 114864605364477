import React, { } from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { SessionInfo } from './App';
import { Popup } from '@progress/kendo-react-popup';
import { ListView,ListViewHeader,ListViewFooter } from '@progress/kendo-react-listview';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import PageHeader from './PageHeader.js';
import PageFooter from './PageFooter.js';
import PageSide from './PageSide.js';
import { withRouter } from './withRouter';
import { CallOM,UpdateRow,notify,displayMessage,displayError,displayNotify,getHelpTopics,getHelpDetail,LogOffResetMsg,OMTrace,displayWarning,TYP,CTYP } from './CommonCode.js';
                                        
const QuestionTypesDD = [{ ddName: 'Bug',ddID: 0 },{ ddName: 'Enhancement',ddID: 1 },{ ddName: 'Help',ddID: 2 },{ ddName: 'Issue',ddID: 3 },{ ddName: 'Question',ddID: 4 },{ ddName: 'Request',ddID: 5 }];

class ContactUs extends React.Component {
  //constructor(props) {
  //  super(props);
  //}
  state = {       
    QuestionTypeID: 0, 
    //OMUserID: 0,
    string: '',
    Name: '',   
    EmailAddress: '',
    MessageDetail: '',
    IPAddress: '',

    selectedTopTab: 0,
    itemStateHasChanged: false,
    showHelpDetail: false,
    showHelpListPopup: false,
    HelpPages: [],
    QuestionTypes: [],
  };
  helpListPopupOffset = { left: 150, top: 60 };
  helpDetailOffset = { left: 200, top: 120 };

  currentType = '';
  refreshCount = 6;
  compUpdate = true;
  clearScreen = false; 

  componentDidMount() {
    this.setState({ Name: SessionInfo.Name });
    this.setState({ EmailAddress: SessionInfo.logonEmailAddress });
    SessionInfo.currShowHelp = this.showHelp;
    if (!SessionInfo.session)
      return;
    SessionInfo.currentPage = "ContactUs";          
    this.setState({ QuestionTypes: QuestionTypesDD });
    if (SessionInfo.Name === '')
      this.setState({ Name: SessionInfo.PersonInfo.FirstName + ' ' + SessionInfo.PersonInfo.LastName });
    else
      this.setState({ Name: SessionInfo.Name });
    if (SessionInfo.logonEmailAddress === '')
      this.setState({ EmailAddress: SessionInfo.logonName }); 
    else
      this.setState({ EmailAddress: SessionInfo.logonEmailAddress }); 
    //this.setState({ OMUserID: SessionInfo.userID });
  }
  componentDidUpdate() {
    //let value = this.context;
  }
  componentWillUnmount() {
    //let value = this.context; 
  }
  shouldComponentUpdate() {
    //console.log("Should Update?");
    if (this.compUpdate === true)
      return true;
    else
      return false;
  }
  submitMessage = async () => { // Get Transaction Receipt
    try {          
      let QuestionTypeID = 0;
      let copyState = Object.assign({}, this.state);
      let type = 0;
      for (let prop in copyState) {
        console.log("Prop: " + prop + " type: " + type + " val: " + copyState[prop]);
        if (prop === "string") {
          type = 1;
          copyState[prop] = undefined;
        }
        else if (prop === "selectedTopTab")
          type = 2;                   
        else if (type === 0) {
          QuestionTypeID = copyState[prop];
        }
        else if (type === 1) {
          //const QuestionTypesDD = [{ ddName: 'Bug',ddID: 0 },{ ddName: 'Enhancement',ddID: 1 },{ ddName: 'Help',ddID: 2 },{ ddName: 'Issue',ddID: 3 },{ ddName: 'Question',ddID: 4 },{ ddName: 'Request',ddID: 5 }];
        }
        else if (type === 2) {
          copyState[prop] = undefined;
        }
      }
      copyState.OMUserID = SessionInfo.OMUserID;  
      //console.log("copystate: " + JSON.stringify(copyState));
      await UpdateRow(544162,0,undefined,copyState,copyState.QuestionTypeID,"QuestionTypeID"); // Save to OM  
      SessionInfo.tempLogon = false;
      notify(`Thank you for your (${QuestionTypeID}) Input`);
      type = 0;
      for (let prop in this.state) {
        if (prop === "string")
          type = 1;
        else if (prop === "selectedTopTab")
          type = 2;
        else if (type === 0)
          this.state[prop] = 0;
        else if (type === 1)
          this.state[prop] = '';
      }
      this.forceUpdate();
    } //try
    catch (error) {
      console.log(error);
    } //catch
  } //submitMessage 

  //------ Help Information -------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
  showHelp = async (key) => {
    if (this.state.showHelpListPopup == true) { // Close Help
      this.setState({ showHelpListPopup: false });
      this.setState({ showHelpDetail: false });
    } else {
      if (!key)
        key = '';
      this.setState({ showHelpDetail: false });
      let HelpPages = await getHelpTopics(key);
      //console.log("caller HelpPages: " + JSON.stringify(HelpPages));
      this.setState({ HelpPages });
      this.setState({ showHelpListPopup: true });
    }
  }
  closeHelpList = () => {
    this.setState({ showHelpListPopup: false });
  }
  onHelpItemClick = async (item) => {
    let helpDetail = await getHelpDetail(item.HelpPageID);
    this.setState({ helpDetail });
    this.setState({ showHelpDetail: true });
  }
  closeHelpTime = async () => {
    this.setState({ showHelpDetail: false });
  }
  onObjectHelp = async (objectID) => {
    let helpDetail = await getHelpDetail(objectID,2);
    this.setState({ helpDetail });
    this.setState({ showHelpDetail: true });
  }
  closeHelpTime = async () => {
    this.setState({ showHelpDetail: false });
  }
  // Help List Layout
  HelpPageHeader = () => {
    return (
      <ListViewHeader className='listHelpHeader' onClick={(e) => this.closeHelpList()} >
        <div className='listHeaderLeft' onClick={(e) => this.closeHelpList()}>
          <span className="listHelpTitle">{SessionInfo.HelpTitle}</span>
        </div>
        <div className='listHeaderRight' onClick={(e) => this.closeHelpList()}>
          <Button id="cancelButton" icon="cancel" look="outline" className="medButton" onClick={this.closeHelpList} >EXIT HELP</Button>
        </div>
      </ListViewHeader>
    );
  }
  HelpPageFormat = props => {
    let item = props.dataItem;
    return (
      <div>
        <div className="listHelpRow" onClick={(e) => this.onHelpItemClick(item)}>
          <div className="listColA">
            <span className="listHelpItemTitle" >{item.HelpTitle}&nbsp;&nbsp;&nbsp;</span>
            <div dangerouslySetInnerHTML={{ __html: item.HelpContent }}></div>
          </div>
        </div>
      </div>
    );
  }
  HelpPageFooter = () => {
    //const [state, onCheckout] = React.useContext(StateContext);
    return (
      <ListViewFooter className='listHelpFooter' onClick={(e) => this.closeHelpList()} >
        <div className='listHeaderRight' onClick={(e) => this.closeHelpList()}>
          <Button id="cancelButton" icon="cancel" look="outline" className="medButton" onClick={this.closeHelpList} >EXIT HELP</Button>
        </div>
      </ListViewFooter>
    );
  }
  ShowFieldHelp = async (key) => {
    console.log(`Field Help - Key: ${key}`);
    this.onObjectHelp(key);
    //this.showHelp(key);
    //console.log(`Field Help: ${JSON.stringify(item)}`);
  }
  //------ End of Help ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- 
  setItemStateChanged = (changed) => {
    this.setState({ itemStateHasChanged: changed });
    if (changed === true) {
      SessionInfo.setShowSave = 1; // Show Save in Header  
      if (this.clearScreen === true) {
        this.clearScreen = false;
        SessionInfo.setShowNew = 1;
      }
    } else
      SessionInfo.setShowSave = 2; // Turn Off
  }
  chgFldVal(event) {
    this.setState({ [event.target.name]: event.target.value });
    this.setItemStateChanged(true);
  }
  chgDDFldVal = (event) => {
    this.setState({ [event.target.props.name]: event.target.value.ddID });
    this.setItemStateChanged(true);
  }          
  render() {
    return (                            
      <div id="ContactUs" className="pageMain">  
        <PageSide/>
        <PageHeader L1='Home' PageTitle='Contact Us' />
        <div id="mainCntr">
          <div className="contactEdit" >
            {/*<img src={require("./images/sub/contact.png")} alt="" className="headImage" />
          <br />*/}
            <h6><span style={{ fontSize: 22,fontWeight: 'bold',color: 'blue' }}>Contact Us</span></h6>
            <br/>
            <p><span style={{ fontSize: 16, fontWeight: 'bold', color: 'black' }}>Please let us know what you think, any problems you might have encountered, or ideas for improvement</span></p>
            <br />
            <div className="contactBox">
              <div id="div2" className="editField">
                <label style={{ width: '120px' }}>Contact Type &nbsp; &nbsp;</label>
                <div className="editDDPos">
                  <DropDownList data={this.state.QuestionTypes} textField='ddName' dataItemKey='ddID' value={this.state.QuestionTypes.find(c => c.ddID === this.state.QuestionTypeID)} name='QuestionTypeID' onChange={evt => this.chgDDFldVal(evt)} className='editInputDD' />
                </div>
              </div>
              <div id="div2" className="editField">
                <label>Your Message</label>
                <br />
                <textarea name="MessageDetail" cols="" rows="30" id="contactBox" value={this.state.MessageDetail} onChange={evt => this.chgFldVal(evt)}></textarea>
                <br/>
                <Button id="sendMsgButton" icon="redo" look="outline" className="largeButton" onClick={this.submitMessage} >&nbsp;Send Message &nbsp;</Button>
              </div>
            </div>
          </div>
        </div>
        <Popup offset={this.helpListPopupOffset} show={this.state.showHelpListPopup} popupClass={'popupHelp'} >
          <div id="helpBox">
            <div className="helpList">
              <ListView data={this.state.HelpPages} item={this.HelpPageFormat} header={this.HelpPageHeader} footer={this.HelpPageFooter} />
            </div>
            <br />
          </div>
        </Popup>
        <Popup offset={this.helpDetailOffset} show={this.state.showHelpDetail} popupClass={'popupHelpDetail'} onClick={this.closeHelpTime}>
          <div className="frameInfo" id="notifyBoxPU" dangerouslySetInnerHTML={{ __html: this.state.helpDetail }} onClick={this.closeHelpTime}></div>
        </Popup>
        {SessionInfo.displayFooter === true ?
          <PageFooter L1='Home' /> : null}
      </div >
    );
  }
}

export default withRouter(ContactUs)