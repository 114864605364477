//----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ManagerPage - The basic Data Management Page for the CheckoutManager Application
//              Version 1.042 - February 17, 2023
//----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------  
import React, { Component } from 'react';
import { Buffer } from 'buffer';
import { withRouter } from './withRouter';
import { Grid as TGrid, GridColumn as Column, GridDetailRow, GridToolbar } from '@progress/kendo-react-grid';
import { TabStrip, TabStripTab, Splitter } from '@progress/kendo-react-layout';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Spreadsheet } from "@progress/kendo-react-spreadsheet";
import { TelerikReportViewer } from '@progress/telerik-react-report-viewer';
import { RadialGauge } from '@progress/kendo-react-gauges';
import { TreeView } from '@progress/kendo-react-treeview';
import { NumericTextBox, TextArea } from '@progress/kendo-react-inputs';
import { Popup } from '@progress/kendo-react-popup';
import { ListView, ListViewHeader, ListViewFooter } from '@progress/kendo-react-listview';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { Switch } from "@progress/kendo-react-inputs";
import { DateInput, TimePicker, DateTimePicker } from '@progress/kendo-react-dateinputs';
import { process, orderBy, filterBy } from '@progress/kendo-data-query';
import { Tooltip } from '@progress/kendo-react-tooltip';
//import { IntlProvider, load, LocalizationProvider, loadMessages } from '@progress/kendo-react-intl';  
import { ExternalDropZone, Upload } from "@progress/kendo-react-upload";
import { Button } from '@progress/kendo-react-buttons';
import { Scheduler, TimelineView, DayView, WeekView, MonthView, AgendaView } from '@progress/kendo-react-scheduler';
import { Form, Field } from '@progress/kendo-react-form';
//import { Input } from '@progress/kendo-react-inputs';
import { timezoneNames } from '@progress/kendo-date-math';
import { Avatar } from '@progress/kendo-react-layout';
import accessOMAPI,{CallOM,CallOMP,GetTableRow,GetTableRowTrans,GetTableData,GetTableFilter,GetTableSearch,GetTableSearchRaw,GetTableRowRaw,GetFileExtension,UpdateRow,DeleteRowTry,DeleteRowForce,displayMessage,getHelpTopics,getHelpDetail, saveImageFileToServer, getDocumentType, getImageFileFromServer, displayError, GetDropdownData, GetDependentDropdownData, displayWarning, LogOffResetMsg, OMTrace, CTYP } from './CommonCode.js';
import { DayOfWeekDropDownCell,SetDayOfWeek,ServiceTypeDropDownCell,SetServiceType,TimeOfDayDropDownCell,SetTimeOfDay,TimeOverrideDropDownCell,SetTimeOverrideTypes,TimePickerCell,PercentCell,MoneyCell,BoolCell,ImageCell,cellDateTime,cellDate,cellCurrency,PricingTypeDropDownCell,RateTypeDropDownCell,ProductItemGroupDropDownCell,NotificationTypeDropDownCell,SetNotificationTypes,StoreLocationDropDownCell,SetStoreLocations,SetLocations,SetPricingType,SetRateType,SublocationTypesDropDownCell,SetSublocationTypes,EditGridCommandCell,DeleteGridCommandCell,SetProductItemCategory,ProductItemCategoryDropDownCell,SetProductItemGroup,SetProductTypes,ProductTypeDropDownCell } from './CommonFormat.js';
import { SchedControl, getTaskID, Iframe } from './CommonControls.js';
import PageHeader from './PageHeader.js';
import PageFooter from './PageFooter.js';
import PageSide from './PageSide.js';
import { SessionInfo } from './App';                    

import { ReactComponent as ButtonAddRow } from "./images/button-add-row.svg";
import { ReactComponent as ButtonSearch } from "./images/search.svg";
import { ReactComponent as HelpAbout } from "./images/help-about.svg";
const tree = [{
  text: 'Application Setup', expanded: true, items: [
    { text: 'Support Tables' }, { text: 'Application Settings' }, { text: 'Promotions' }]
}, {
  text: 'Security and Control', items: [
    { text: 'Notification Logs' }, { text: 'User Management' }, { text: 'Roles Assignment' }]
  }];

const ServiceTypesOID = 555748;
const AppNotificationsOID = 545857;
const AppNotificationsO = [{ n: 'AppNotificationID',t: 24,p: 0 },{ n: 'NotificationTypeID',t: 24,p: 0 },{ n: 'NotificationMessage',t: 21,p: 0 },{ n: 'MCMessageID',t: 24,p: 0 },{ n: 'MessageID',t: 24,p: 0 },{ n: 'StoreLocationID',t: 24,p: 0 },{ n: 'CustomerID',t: 24,p: 0 },{ n: 'StoreGroupID',t: 24,p: 0 },{ n: 'NotificationDateTime',t: 35,p: 0 },{ n: 'ExpiryDateTime',t: 35,p: 0 },{ n: 'MessageRead',t: 22,p: 0 },{ n: 'OMUserID',t: 24,p: 0 }];
//------ Object Definition For StorePromotions -- Date: 2023-09-12-2:47 PM ------
const StorePromotionsOID = 544701;
const StorePromotionsO = [{ n: 'StorePromotionID',t: 195,p: 100 },{ n: 'StorePromotionName',t: 21,p: 0 },{ n: 'PromotionDescription',t: 21,p: 0 },{ n: 'StoreLocationID',t: 195,p: 100 },{ n: 'StoreGroupID',t: 195,p: 100 },{ n: 'StoreItemID',t: 195,p: 100 },{ n: 'ProductItemCategoryID',t: 195,p: 100 },{ n: 'ProductItemGroupID',t: 195,p: 100 },{ n: 'ProductItemTypeID',t: 195,p: 100 },{ n: 'PromoStoreItemID',t: 195,p: 100 },{ n: 'PromoItemCategoryID',t: 24,p: 0 },{ n: 'DaysForDiscount',t: 24,p: 0 },{ n: 'DiscountAmount',t: 28,p: 0 },{ n: 'DiscountPercentage',t: 23,p: 8 },{ n: 'PromotionPrice',t: 28,p: 0 },{ n: 'PromotionType',t: 201,p: 0 },{ n: 'StartDateTime',t: 35,p: 0 },{ n: 'EndDateTime',t: 35,p: 0 },{ n: 'ImageID',t: 41,p: 0 },{ n: 'PromotionIsActive',t: 22,p: 0 },{ n: 'MaxUseCount',t: 24,p: 0 },{ n: 'UsedCount',t: 24,p: 0 }];
//       Object Value Definition For StorePromotions
const SPV = { 'StorePromotionID': 0,'StorePromotionName': '','PromotionDescription': '','StoreLocationID': 0,'StoreGroupID': 0,'StoreItemID': 0,'ProductItemCategoryID': 0,'ProductItemGroupID': 0,'ProductItemTypeID': 0,'PromoStoreItemID': 0,'PromoItemCategoryID': 0,'DaysForDiscount': 0,'DiscountAmount': 0,'DiscountPercentage': 0,'PromotionPrice': 0,'PromotionType': 0,'StartDateTime': new Date(),'EndDateTime': new Date(),'ImageID': '','PromotionIsActive': false,'MaxUseCount': 0,'UsedCount': 0 };
//       Object Index Definition For StorePromotions
const SPX = { 'StorePromotionID': 0,'StorePromotionName': 1,'PromotionDescription': 2,'StoreLocationID': 3,'StoreGroupID': 4,'StoreItemID': 5,'ProductItemCategoryID': 6,'ProductItemGroupID': 7,'ProductItemTypeID': 8,'PromoStoreItemID': 9,'PromoItemCategoryID': 10,'DaysForDiscount': 11,'DiscountAmount': 12,'DiscountPercentage': 13,'PromotionPrice': 14,'PromotionType': 15,'StartDateTime': 16,'EndDateTime': 17,'ImageID': 18,'PromotionIsActive': 19,'MaxUseCount': 20,'UsedCount': 21 };

const ProcessCmd = {
  AdjustHours: 17,
  AdjustPrices: 10,
  CopyItemsToLocations: 6,
  UpdateLocationItems: 7,
  FillHoursGrid: 3,
  FillItemGrid: 1,
  FillLocationGrid: 2,
  SetPrices: 11,
  SetPromotion: 15,
  SetHours: 18
}                    
var TaskData = [];
const uploadRef = React.createRef();
const uploadPromoRef = React.createRef();
var viewer = React.createRef();
const spreadsheet = React.createRef();   
//const spreadsheet = React.useRef(null);
const sData = [{
  name: "Food Order",
  rows: [{
    cells: [{
      value: "ID",
      background: "rgb(167,214,255)",
      textAlign: "center",
      color: "rgb(0,62,117)"
    },{
      value: "Product",
      background: "rgb(167,214,255)",
      textAlign: "center",
      color: "rgb(0,62,117)"
    }]
  },{
    cells: [{
      value: 216321,
      textAlign: "center",
      background: "rgb(229,243,255)",
      color: "rgb(0,62,117)"
    },{
      value: "Calzone",
      background: "rgb(229,243,255)",
      color: "rgb(0,62,117)"
    }]
  },{
    cells: [{
      value: 546897,
      textAlign: "center",
      background: "rgb(229,243,255)",
      color: "rgb(0,62,117)"
    },{
      value: "Margarita",
      background: "rgb(229,243,255)",
      color: "rgb(0,62,117)"
    }]
  },{
    cells: [{
      value: 456231,
      textAlign: "center",
      background: "rgb(229,243,255)",
      color: "rgb(0,62,117)"
    },{
      value: "Pollo Formaggio",
      background: "rgb(229,243,255)",
      color: "rgb(0,62,117)"
    }]
  },{
    cells: [{
      value: 455873,
      textAlign: "center",
      background: "rgb(229,243,255)",
      color: "rgb(0,62,117)"
    },{
      value: "Greek Salad",
      background: "rgb(229,243,255)",
      color: "rgb(0,62,117)"
    }]
  },{
    cells: [{
      value: 456892,
      textAlign: "center",
      background: "rgb(229,243,255)",
      color: "rgb(0,62,117)"
    },{
      value: "Spinach and Blue Cheese",
      background: "rgb(229,243,255)",
      color: "rgb(0,62,117)"
    }]
  }],
  columns: [{
    width: 100
  },{
    width: 200
  }]
}];

class ManagerPage extends React.Component {
  constructor(props) {
    super(props);
    //SessionInfo.History = props.history;
    if (SessionInfo.session === '')
      props.history.push("/");
    if (SessionInfo.ManagerPage) { // Data saved for this session
      this.state = SessionInfo.ManagerPage;
      this.loadExistingPage = true;
      SessionInfo.GoToTab = this.selectedTopTab;
    }
    //------------- Edit ProductCategory Grid ------------------------------
    this.enterInsertProductCategory = this.enterInsertProductCategory.bind(this); // Establish binding to ProductCategoryDetail (this)
    this.itemChangeProductCategory = this.itemChangeProductCategory.bind(this);
    this.enterEditProductCategory = this.enterEditProductCategory.bind(this);
    this.saveProductCategory = this.saveProductCategory.bind(this);
    this.cancelProductCategory = this.cancelProductCategory.bind(this);
    this.removeProductCategory = this.removeProductCategory.bind(this);
    this.ProductCategoryCommandCell = props => (<EditGridCommandCell {...props} edit={this.enterEditProductCategory} remove={this.removeProductCategory} add={this.saveProductCategory} discard={this.removeProductCategory} update={this.saveProductCategory} cancel={this.cancelProductCategory} editField='inEdit' keyField='ProductItemCategoryID' nameField='ProductItemCategory' />);
    //------------- Edit ProductGroup Grid ------------------------------
    this.enterInsertProductGroup = this.enterInsertProductGroup.bind(this); // Establish binding to ProductGroupDetail (this)
    this.itemChangeProductGroup = this.itemChangeProductGroup.bind(this);
    this.enterEditProductGroup = this.enterEditProductGroup.bind(this);
    this.saveProductGroup = this.saveProductGroup.bind(this);
    this.cancelProductGroup = this.cancelProductGroup.bind(this);
    this.removeProductGroup = this.removeProductGroup.bind(this);
    this.ProductGroupCommandCell = props => (<EditGridCommandCell {...props} edit={this.enterEditProductGroup} remove={this.removeProductGroup} add={this.saveProductGroup} discard={this.removeProductGroup} update={this.saveProductGroup} cancel={this.cancelProductGroup} editField='inEdit' keyField='ProductItemGroupID' nameField='ProductItemGroup' />);
    //------------- Edit ProductType Grid ------------------------------
    this.enterInsertProductType = this.enterInsertProductType.bind(this); // Establish binding to ProductTypeDetail (this)
    this.itemChangeProductType = this.itemChangeProductType.bind(this);
    this.enterEditProductType = this.enterEditProductType.bind(this);
    this.saveProductType = this.saveProductType.bind(this);
    this.cancelProductType = this.cancelProductType.bind(this);
    this.removeProductType = this.removeProductType.bind(this);
    this.ProductTypeCommandCell = props => (<EditGridCommandCell {...props} edit={this.enterEditProductType} remove={this.removeProductType} add={this.saveProductType} discard={this.removeProductType} update={this.saveProductType} cancel={this.cancelProductType} editField='inEdit' keyField='ProductItemTypeID' nameField='ProductItemType' />);
    //------------- Edit PricingType Grid ------------------------------
    this.enterInsertPricingType = this.enterInsertPricingType.bind(this); // Establish binding to PricingTypeDetail (this)
    this.itemChangePricingType = this.itemChangePricingType.bind(this);
    this.enterEditPricingType = this.enterEditPricingType.bind(this);
    this.savePricingType = this.savePricingType.bind(this);
    this.cancelPricingType = this.cancelPricingType.bind(this);
    this.removePricingType = this.removePricingType.bind(this);
    this.PricingTypeCommandCell = props => (<EditGridCommandCell {...props} edit={this.enterEditPricingType} remove={this.removePricingType} add={this.savePricingType} discard={this.removePricingType} update={this.savePricingType} cancel={this.cancelPricingType} editField='inEdit' keyField='PricingTypeID' nameField='PricingType' />);
    //------------- Edit RateType Grid ------------------------------
    this.enterInsertRateType = this.enterInsertRateType.bind(this); // Establish binding to RateTypeDetail (this)
    this.itemChangeRateType = this.itemChangeRateType.bind(this);
    this.enterEditRateType = this.enterEditRateType.bind(this);
    this.saveRateType = this.saveRateType.bind(this);
    this.cancelRateType = this.cancelRateType.bind(this);
    this.removeRateType = this.removeRateType.bind(this);
    this.RateTypeCommandCell = props => (<EditGridCommandCell {...props} edit={this.enterEditRateType} remove={this.removeRateType} add={this.saveRateType} discard={this.removeRateType} update={this.saveRateType} cancel={this.cancelRateType} editField='inEdit' keyField='RateTypeID' nameField='RateType' />);
    //------------- Edit AppNotification Grid ------------------------------
    this.enterInsertAppNotification = this.enterInsertAppNotification.bind(this); // Establish binding to AppNotificationDetail (this)
    this.itemChangeAppNotification = this.itemChangeAppNotification.bind(this);
    this.enterEditAppNotification = this.enterEditAppNotification.bind(this);
    this.saveAppNotification = this.saveAppNotification.bind(this);
    this.cancelAppNotification = this.cancelAppNotification.bind(this);
    this.removeAppNotification = this.removeAppNotification.bind(this);
    this.AppNotificationCommandCell = props => (<DeleteGridCommandCell {...props} edit={this.enterEditAppNotification} remove={this.removeAppNotification} add={this.saveAppNotification} discard={this.removeAppNotification} update={this.saveAppNotification} cancel={this.cancelAppNotification} editField='inEdit' keyField='StoreItemID' nameField='ItemDescription' />);
    //------------- Edit StorePromotion Grid ------------------------------
    this.enterInsertStorePromotion = this.enterInsertStorePromotion.bind(this); // Establish binding to StorePromotionDetail (this)
    this.itemChangeStorePromotion = this.itemChangeStorePromotion.bind(this);
    this.enterEditStorePromotion = this.enterEditStorePromotion.bind(this);
    this.saveStorePromotion = this.saveStorePromotion.bind(this);
    this.cancelStorePromotion = this.cancelStorePromotion.bind(this);
    this.removeStorePromotion = this.removeStorePromotion.bind(this);
    this.StorePromotionCommandCell = props => (<EditGridCommandCell {...props} edit={this.enterEditStorePromotion} remove={this.removeStorePromotion} add={this.saveStorePromotion} discard={this.removeStorePromotion} update={this.saveStorePromotion} cancel={this.cancelStorePromotion} editField='inEdit' keyField='StorePromotionID' nameField='StorePromotionName' />);
    //--------------------------------------------------------
    //------------- Edit ProductOptionsGroup Grid ------------------------------
    this.enterInsertProductOptionsGroup = this.enterInsertProductOptionsGroup.bind(this); // Establish binding to ProductOptionsGroupDetail (this)
    this.itemChangeProductOptionsGroup = this.itemChangeProductOptionsGroup.bind(this);
    this.enterEditProductOptionsGroup = this.enterEditProductOptionsGroup.bind(this);
    this.saveProductOptionsGroup = this.saveProductOptionsGroup.bind(this);
    this.cancelProductOptionsGroup = this.cancelProductOptionsGroup.bind(this);
    this.removeProductOptionsGroup = this.removeProductOptionsGroup.bind(this);
    this.ProductOptionsGroupCommandCell = props => (<EditGridCommandCell {...props} edit={this.enterEditProductOptionsGroup} remove={this.removeProductOptionsGroup} add={this.saveProductOptionsGroup} discard={this.removeProductOptionsGroup} update={this.saveProductOptionsGroup} cancel={this.cancelProductOptionsGroup} editField='inEdit' keyField='ProductOptionsGroupID' nameField='ProductOptionsGroupName' />);
    //------------- Edit ProductItemOption Grid ------------------------------
    this.enterInsertProductItemOption = this.enterInsertProductItemOption.bind(this); // Establish binding to ProductItemOptionDetail (this)
    this.itemChangeProductItemOption = this.itemChangeProductItemOption.bind(this);
    this.enterEditProductItemOption = this.enterEditProductItemOption.bind(this);
    this.saveProductItemOption = this.saveProductItemOption.bind(this);
    this.cancelProductItemOption = this.cancelProductItemOption.bind(this);
    this.removeProductItemOption = this.removeProductItemOption.bind(this);
    //this.ProductItemOptionCommandCell = EditGridCommandCell(enterEditProductItemOption, removeProductItemOption, saveProductItemOption, cancelProductItemOption, "inEdit", "ProductOptionsGroupName", "ProductItemOptionID");
    this.ProductItemOptionCommandCell = props => (<EditGridCommandCell {...props} edit={this.enterEditProductItemOption} remove={this.removeProductItemOption} add={this.saveProductItemOption} discard={this.removeProductItemOption} update={this.saveProductItemOption} cancel={this.cancelProductItemOption} editField='inEdit' keyField='ProductItemOptionID' nameField='ProductOptionName' />);
    //------------- Edit SeasonCode Grid Bindings ------------------------------
    this.enterInsertSeasonCode = this.enterInsertSeasonCode.bind(this); // Establish binding to SeasonCodeDetail (this)
    this.itemChangeSeasonCode = this.itemChangeSeasonCode.bind(this);
    this.enterEditSeasonCode = this.enterEditSeasonCode.bind(this);
    this.saveSeasonCode = this.saveSeasonCode.bind(this);
    this.cancelSeasonCode = this.cancelSeasonCode.bind(this);
    this.removeSeasonCode = this.removeSeasonCode.bind(this);
    //this.SeasonCodeCommandCell = EditGridCommandCell(enterEditSeasonCode, removeSeasonCode, saveSeasonCode, cancelSeasonCode, "inEdit", "ItemName", "SeasonCodeID");
    this.SeasonCodeCommandCell = props => (<EditGridCommandCell {...props} edit={this.enterEditSeasonCode} remove={this.removeSeasonCode} add={this.saveSeasonCode} discard={this.removeSeasonCode} update={this.saveSeasonCode} cancel={this.cancelSeasonCode} editField='inEdit' keyField='SeasonCodeID' nameField='SeasonCode' />);
    //------------- Edit TimeOfDay Grid Bindings ------------------------------
    this.enterInsertTimeOfDay = this.enterInsertTimeOfDay.bind(this); // Establish binding to TimeOfDayDetail (this)
    this.itemChangeTimeOfDay = this.itemChangeTimeOfDay.bind(this);
    this.enterEditTimeOfDay = this.enterEditTimeOfDay.bind(this);
    this.saveTimeOfDay = this.saveTimeOfDay.bind(this);
    this.cancelTimeOfDay = this.cancelTimeOfDay.bind(this);
    this.removeTimeOfDay = this.removeTimeOfDay.bind(this);
    //this.TimeOfDayCommandCell = EditGridCommandCell(enterEditTimeOfDay, removeTimeOfDay, saveTimeOfDay, cancelTimeOfDay, "inEdit", "ItemName", "TimeOfDayID");
    this.TimeOfDayCommandCell = props => (<EditGridCommandCell {...props} edit={this.enterEditTimeOfDay} remove={this.removeTimeOfDay} add={this.saveTimeOfDay} discard={this.removeTimeOfDay} update={this.saveTimeOfDay} cancel={this.cancelTimeOfDay} editField='inEdit' keyField='TimeOfDayID' nameField='TimeOfDay' />);
    //------------- Edit TaxableType Grid Bindings ------------------------------
    this.enterInsertTaxableType = this.enterInsertTaxableType.bind(this); // Establish binding to TaxableTypeDetail (this)
    this.itemChangeTaxableType = this.itemChangeTaxableType.bind(this);
    this.enterEditTaxableType = this.enterEditTaxableType.bind(this);
    this.saveTaxableType = this.saveTaxableType.bind(this);
    this.cancelTaxableType = this.cancelTaxableType.bind(this);
    this.removeTaxableType = this.removeTaxableType.bind(this);
    //this.TaxableTypeCommandCell = EditGridCommandCell(enterEditTaxableType, removeTaxableType, saveTaxableType, cancelTaxableType, "inEdit", "ItemName", "TaxableTypeID");
    this.TaxableTypeCommandCell = props => (<EditGridCommandCell {...props} edit={this.enterEditTaxableType} remove={this.removeTaxableType} add={this.saveTaxableType} discard={this.removeTaxableType} update={this.saveTaxableType} cancel={this.cancelTaxableType} editField='inEdit' keyField='TaxableTypeID' nameField='TaxableType' />);
    //------------- Edit ServiceType Grid Bindings ------------------------------
    this.enterEditServiceType = this.enterEditServiceType.bind(this); // Establish binding to TaxableTypeDetail (this)
    this.itemChangeServiceType = this.itemChangeServiceType.bind(this);
    this.enterEditServiceType = this.enterEditServiceType.bind(this);
    this.saveServiceType = this.saveServiceType.bind(this);
    this.cancelServiceType = this.cancelServiceType.bind(this);
    this.removeServiceType = this.removeServiceType.bind(this);
    this.ServiceTypeCommandCell = props => (<EditGridCommandCell {...props} edit={this.enterEditServiceType} remove={this.removeServiceType} add={this.saveServiceType} discard={this.removeServiceType} update={this.saveServiceType} cancel={this.cancelServiceType} editField='inEdit' keyField='ServiceTypeID' nameField='ServiceType' />);
  }
  state = {
    AppNotificationID: 0,
    NotificationTypeID: 0,
    MCMessageID: 0,
    MessageID: 0,
    CustomerID: 0,
    StoreGroupID: 0,
    NotificationDateTime: 0,
    ExpiryDateTime: 0,
    OMUserID: 0,
    MessageRead: 0,
    ImageID: 0,
    ProductItemCategoryID: 0,
    IsRecordActive: 0,
    AppEntityID: 0,
    AppGroupID: 0,

    StartDateTime: 0,
    EndDateTime: 0,

    ItemPrice: 0,
    ItemPriceS: '',
    StartTime: 0,
    EndTime: 0,
    OverrideStart: 0,
    OverrideEnd: 0,
    DiscountAmount: 0,
    DiscountAmountS: '',
    PromotionPrice: 0,
    PromotionPriceS: '',
    DiscountPercentage: 0,
    DiscountPercentageS: '',
    AdjustmentPercentage: 0,
    AdjustmentPercentageS: '',
    AdjustmentAmount: 0,
    AdjustmentAmountS: '',

    string: '',
    NotificationMessage: '',
    ProductItemCategory: '',
    Description: '',
    PromotionIsActive: false,

    selectedTopTab: 0,
    itemStateHasChanged: false,
    //style: { width: 1000, height: 1000 },
    horizontalPanes: [{ collapsible: false,min: '65%',collapsed: false },{ collapsible: true,collapsed: true }], // Left/Right
    verticalPanes: [{ size: '80%' },{ min: '200px',collapsible: true,collapsed: true }],  // Top/Bottom
    bottomDisplay: 0,

    searchValue: '',
    MessagesValue: '',
    currentTime: '',
    currentItemName: '',
    currentItemDescription: '',
    ProductOptionsGroupName: '',
    // Dictionary Arrays   
    NotificationTypes: [],
    SublocationID: 0,
    StoreLocations: [],
    SublocationTypes: [],
    StoreGroups: [],
    Locations: [],
    ProcessCommands: [],
    StoreLocationsDict: [],
    StoreItemsDict: [],
    ProductItemCategories: [],
    ProductItemGroups: [],
    ProductItemTypes: [],
    Brands: [],
    ItemStatusSet: [],
    PackagingTypes: [],
    PricingTypesDict: [],
    RateTypesDict: [],
    PromotionTypes: [],
    TimeOfDaysDict: [],
    TaxableTypes: [],
    UOMWeights: [],
    StorageControls: [],
    StoreLocationID: 0,
    StoreLocations: [],
    SeasonCodesDict: [],
    DayOfWeekDict: [],
    TimeOfDayDict: [],
    TimeOverrideTypes: [],
    StorageControlsDict: [],
    StoreInventoryLocationsDict: [],

    HelpPages: [],
    showHelpDetail: false,
    showHelpListPopup: false,
    infoMessage: '',
    detailMessage: '',

    CompaniesDict: [],
    StoreGroupsDict: [],
    //-- ProductCategory Data, Save, Page and Filter ------
    ProductCategorys: [],
    ProductCategorysSkip: 0,
    ProductCategorysTake: 8,
    saveProductCategorys: [],
    ProductCategoryFilter: { logic: "and",filters: [] },
    ProductCategorysort: [],
    ProductCategoryPhotoURL: undefined,
    //-- ProductGroup Data, Save, Page and Filter ------
    ProductGroups: [],
    ProductGroupsSkip: 0,
    ProductGroupsTake: 8,
    saveProductGroups: [],
    ProductGroupFilter: { logic: "and",filters: [] },
    ProductGroupsort: [],
    //-- ProductType Data, Save, Page and Filter ------
    ProductTypes: [],
    ProductTypesSkip: 0,
    ProductTypesTake: 8,
    saveProductTypes: [],
    ProductTypeFilter: { logic: "and",filters: [] },
    ProductTypesort: [],
    //-- PricingType Data, Save, Page and Filter ------
    PricingTypes: [],
    PricingTypesSkip: 0,
    PricingTypesTake: 8,
    savePricingTypes: [],
    PricingTypeFilter: { logic: "and",filters: [] },
    PricingTypesort: [],
    //-- RateType Data, Save, Page and Filter ------
    RateTypes: [],
    RateTypesSkip: 0,
    RateTypesTake: 8,
    saveRateTypes: [],
    RateTypeFilter: { logic: "and",filters: [] },
    RateTypesort: [],
    //-- AppNotification Data, Save, Page and Filter ------
    AppNotifications: [],
    AppNotificationsSkip: 0,
    AppNotificationsTake: 8,
    saveAppNotifications: [],
    AppNotificationFilter: { logic: "and",filters: [] },
    AppNotificationsort: [],
    //-- StorePromotion Data, Save, Page and Filter ------
    StorePromotionID: 0,
    StorePromotions: [],
    StorePromotionsSkip: 0,
    StorePromotionsTake: 8,
    saveStorePromotions: [],
    StorePromotionFilter: { logic: "and",filters: [] },
    StorePromotionSort: [],
    // Promotions Data
    Promotions: [],
    promotionsCount: 0,
    PromotionPhotoURL: '',
    //------ ServiceTypes Data, Save, Page and Filter ------
    ServiceTypes: [],
    ServiceTypesSkip: 0,
    ServiceTypesTake: 8,
    saveServiceTypes: [],
    ServiceTypesFilter: { logic: "and",filters: [] },
    ServiceTypesSort: [],
    //------ SeasonCodes Data, Save, Page and Filter ------
    SeasonCodes: [],
    SeasonCodesSkip: 0,
    SeasonCodesTake: 8,
    saveSeasonCodes: [],
    SeasonCodesFilter: { logic: "and",filters: [] },
    SeasonCodesSort: [],
    //------ TaxableTypes Data, Save, Page and Filter ------
    TaxableTypes: [],
    TaxableTypesSkip: 0,
    TaxableTypesTake: 8,
    saveTaxableTypes: [],
    TaxableTypesFilter: { logic: "and",filters: [] },
    TaxableTypesSort: [],
    //------ TimeOfDays Data, Save, Page and Filter ------
    TimeOfDays: [],
    TimeOfDaysSkip: 0,
    TimeOfDaysTake: 8,
    saveTimeOfDays: [],
    TimeOfDaysFilter: { logic: "and",filters: [] },
    TimeOfDaysSort: [],
    //-- XXItem Data, Save, Page and Filter ------
    XXItems: [],
    XXItemsSkip: 0,
    XXItemsTake: 8,
    saveXXItems: [],
    XXItemFilter: { logic: "and",filters: [] },
    XXItemsort: [],
    //-- ProductItemOption Data, Save, Page and Filter ------
    ProductItemOptions: [],
    ProductItemOptionsSkip: 0,
    ProductItemOptionsTake: 8,
    saveProductItemOptions: [],
    ProductItemOptionFilter: { logic: "and",filters: [] },
    ProductItemOptionSort: [],
    //-- ProductOptionsGroup Data, Save, Page and Filter ------
    ProductSIOptionsGroups: [],
    ProductSIOptionsGroupsSkip: 0,
    ProductSIOptionsGroupsTake: 8,
    saveProductSIOptionsGroups: [],
    ProductSIOptionsGroupFilter: { logic: "and",filters: [] },
    ProductSIOptionsGroupSort: [],
    //ProductOptionPhotoURL: undefined,
    //-- ProductOptionsGroup Data, Save, Page and Filter ------
    ProductOptionsGroupsAV: [],
    ProductOptionsGroupsAVSkip: 0,
    ProductOptionsGroupsAVTake: 8,
    saveProductOptionsGroupsAV: [],
    ProductOptionsGroupAVFilter: { logic: "and",filters: [] },
    ProductOptionsGroupAVSort: [],
    //-- ProductOptionsGroup Data, Save, Page and Filter ------
    ProductOptionsGroups: [],
    ProductOptionsGroupsSkip: 0,
    ProductOptionsGroupsTake: 8,
    saveProductOptionsGroups: [],
    ProductOptionsGroupFilter: { logic: "and",filters: [] },
    ProductOptionsGroupSort: [],
    ProductOptionPhotoURL: undefined,
    //------- StoreLocations ----------
    StoreLocationsData: [],
    storeLocationsSkip: 0,
    storeLocationsTake: 8,
    storeLocationsCount: 0,

    HoursOfOperation: [],
    SchedulerData: [],
    //------- StoreItems ----------
    StoreItems: [],
    storeItemsSkip: 0,
    storeItemsTake: 16,

    itemFilter: { logic: "and",filters: [] },
    itemSort: [],
    //------- Selection Fields ------------
    ProductItemCategoryIDSel: 0,
    ProductItemGroupIDSel: 0,
    ProductItemTypeIDSel: 0,
    BrandIDSel: 0,
    ShelfLifeDaysSel: 0,
    ItemPriceSel: 0,
    ItemStatusIDSel: 0,
    PackagingTypeIDSel: 0,
    PricingTypeIDSel: 0,
    RateTypeIDSel: 0,
    TimeOverrideTypeSel: 0,

    StoreLocationIDSel: 0,
    StoreGroupIDSel: 0,
    CompanyIDSel: 0,
    DayOfWeekSel: 0,

    ProcessCommand: 0,
    ActionName: '',

    ActiveGroupColor: "#3e80ed",
    //-------------------

    SelectItemCategory: false,
    SelectItemGroup: false,
    SelectItemType: false,
    SelectBrand: false,
    SelectItemStatus: false,
    SelectSeasonCode: false,
    SelectDayOfWeek: false,
    SelectTimeOfDay: false,
    SelectPricingType: false,
    SelectRateType: false,
    SelectLocation: false,
    SelectStoreGroup: false,
    SelectCompany: false,
    SelectStorageControl: false,
    SelectDayOfWeekH: false,

    //-- Main Store Items --
    MainStoreItems: [],
    mainStoreItemsSkip: 0,
    mainStoreItemsTake: 18,
    mainStoreItemsCount: 0,
    // Spreadsheet
    PeriodType: 0,
    PeriodBack: 0,
    TxDate: new Date(),
    PeriodStartDate: new Date(),
    allLocations: false,
    //--
    files: [],
    SetPricingTypes: [],
    UploadFile: undefined,
    ImageType: "",
    DocumentTypeID: 0,     
  };
  vertSplitC = JSON.parse('[{ "size": "100%" }, { "min": "200px", "collapsible": true, "collapsed": true }]');  // Top/Bottom
  vertSplitO = JSON.parse('[{ "size": "60%" }, { "min": "200px", "collapsible": true, "collapsed": false }]');  // Top/Bottom 
  vertSplitOH = JSON.parse('[{ "size": "20%" }, { "min": "200px", "collapsible": true, "collapsed": false }]');  // Top/Bottom
  helpListPopupOffset = { left: 150,top: 60 };
  helpDetailOffset = { left: 200,top: 120 };

  ProductCategoryOID = 1104137;
  ProductGroupOID = 1104119;
  ProductTypeOID = 1104125;
  PackagingTypeOID = 1104125;
  PricingTypeOID = 1104167;
  RateTypeOID = 545339;
  AppNotificationOID = 545857;
  StorePromotionsOID = 544701;
  XXItemOID = 544619;
  StoreItemOID = 544619;
  ProductOGLinksOID = 546454;
  ProductOptionsGroupsOID = 545034;
  ProductItemOptionsOID = 545039;
  SeasonCodeOID = 1120743;
  TimeOfDayOID = 546466;
  TaxableTypeOID = 546870;
  refreshCount = 6;
  InsertRecordType = 0;
  DocumentLoaded = false;
  DocumentSaved = false;
  isSavingDocument = false;
  fileReady = false;
  file = undefined;
  compUpdate = true;
  takeLines = 8;
  halfTakeLines = 8;
  activeColor = "#3e80ed";
  searchAlt = 0;
  extendedSearch = "";
  saveSearch = "";
  taskData = [];
  token = "T9D393637492";
  clearScreen = false; 
  GroupOptionAdd = 0; // Controls default add for Group Options

  PeriodsFixedValues = [{ ddName: 'Hour',ddID: 12 },{ ddName: 'Day',ddID: 0 },{ ddName: 'Week',ddID: 1 },{ ddName: 'Month',ddID: 4 }];

  //subInteract = "https://manage.interactsuite.app/?" + this.token + "#token20001"; //"https://interactsuite.app";
  //subInteract = "https://interactmenu.com/?" + this.token + "#token20001"; //"https://interactsuite.app";
  // token: qXjk/PT0NOxKDd9Jnss8qOfQL7T8fbbXFJ5QmD5dbIQPSCCobNaOu6DHpshUkzuLIj4bS9YPRuk+gdRc9AObrzuhUFYxcqfnHRtxXXiaSFmFPFmTOJ5IIdpWhZJlCX9E"
  tabs = {
    ProductCategories: 0,
    ProductGroups: 1,
    ProductTypes: 2,
    PricingTypes: 3,
    RateTypes: 4,
    SeasonCodes: 5,
    TimeOfDays: 6,
    TaxableTypes: 7,
    ServiceTypes: 8,
    Notifications: 9,
    Promotions: 10,
    ProcessActions: 11,
    OptionGroups: 12,
    Scheduler: 13,
    SpreadSheet: 14,
    ReportViewer: 15,
  }

  async componentDidMount() {
    if (!SessionInfo.session || SessionInfo.session === '') {
      console.log(`forceLogoff set in ManagerPage - go to Login`);
      this.props.navigate("/Login");
    }
    let windowHt = window.innerHeight;
    let takeLinesD = (windowHt - 200) / 54.0;
    this.takeLines = parseInt(takeLinesD);
    this.setState({ ProductCategorysTake: this.takeLines })
    this.setState({ ProductGroupsTake: this.takeLines })
    this.setState({ ProductTypesTake: this.takeLines })
    this.setState({ PricingTypesTake: this.takeLines })
    this.setState({ RateTypesTake: this.takeLines })
    this.setState({ AppNotificationsTake: this.takeLines })
    this.setState({ StorePromotionsTake: this.takeLines })
    this.setState({ SublocationsTake: this.takeLines })
    this.setState({ ProductSIOptionsGroupsTake: this.takeLines })
    this.setState({ ProductOptionsGroupsTake: this.takeLines })
    this.halfTakeLines = this.takeLines / 2;
    this.setState({ ProductItemOptionsTake: this.halfTakeLines })
    this.setState({ mainStoreItemsTake: this.halfTakeLines });
    SessionInfo.searchTitle = "Search Categories";
    SessionInfo.currentPage = "ManagerPage";
    SessionInfo.searchFunc = this.searchCurrent;
    SessionInfo.tabSelectFunc = this.selectTab;
    SessionInfo.currSaveFunc = this.saveRecord;
    SessionInfo.currDeleteFunc = this.deleteRecord;
    SessionInfo.currClearFunc = this.clearRecord;
    SessionInfo.currRefreshGridFunc = this.refreshGrid;
    SessionInfo.currAddGridRowFunc = undefined;
    SessionInfo.currExcelExportFunc = undefined;
    SessionInfo.currShowHelp = this.showHelp;
    try {
      if (SessionInfo.loggedOn === true) {
        if (SessionInfo.GoToTab >= 0) {
          await this.selectTab(SessionInfo.GoToTab);
          //this.setState({ selectedTopTab: SessionInfo.GoToTab });
          SessionInfo.GoToTab = 0;
        }
      }
      if (this.loadExistingPage === true)
        this.forceUpdate();
      else { // Load Dictionaries
        console.log("Start Dictionary Load");
        if (SessionInfo.forceLogoff === true) // Note - Monitor not started at this point - NEED - ping test to ensure still logged on
          this.props.navigate("/Login");
        //if (this.state.StorageControls.length <= 1) {
        // Execute this everytime to ensure connectivity before proceeding with the page
        let CD = await GetDropdownData(539092);
        if (SessionInfo.forceLogoff === true)
          this.props.navigate("/Login");
        this.setState({ StorageControls: CD.d });
        //}
        console.log(`Load ProductItemCategories: ${SessionInfo.ProductItemCategoriesUpdate}`);
        if (this.state.ProductItemCategories.length <= 1 || SessionInfo.ProductItemCategoriesUpdate === true) {
          let CD = await GetDropdownData(1104137);
          console.log("ProductItemCategories: " + JSON.stringify(CD.d));
          this.setState({ ProductItemCategories: CD.d });
          SetProductItemCategory(CD.d);
          SessionInfo.ProductItemCategoriesUpdate = false;
        }
        if (SessionInfo.forceLogoff === true)
          this.props.navigate("/Login");
        //console.log("ProductItemGroups");
        if (this.state.ProductItemGroups.length <= 2 || SessionInfo.ProductItemGroupUpdate === true) { // Note - Dependent Drop Down - requires special flag
          let CD = await GetDropdownData(1104119);
          console.log("ProductItemGroups: " + JSON.stringify(CD.d));
          this.setState({ ProductItemGroups: CD.d });
          SetProductItemGroup(CD.d);
          SessionInfo.ProductItemGroupUpdate = false;
        }
        //console.log("ProductItemTypes");
        if (this.state.ProductItemTypes.length <= 1 || SessionInfo.ProductItemTypeUpdate === true) {
          let CD = await GetDropdownData(1104125);
          this.setState({ ProductItemTypes: CD.d });
          SetProductTypes(CD.d);
          SessionInfo.ProductItemTypeUpdate = false;
        }
        if (this.state.Brands.length <= 1 || SessionInfo.BrandUpdate === true) {
          let CD = await GetDropdownData(541002);
          this.setState({ Brands: CD.d });
          SessionInfo.BrandUpdate = false;
        }
        //console.log("ItemStatus");
        if (this.state.ItemStatusSet.length <= 1 || SessionInfo.ItemStatusUpdate === true) {
          let CD = await GetDropdownData(1104159);
          this.setState({ ItemStatusSet: CD.d });
          SessionInfo.ItemStatusUpdate = false;
        }
        //console.log("Packagetypes");
        if (this.state.PackagingTypes.length <= 2 || SessionInfo.PackagingTypeUpdate === true) { // Note - Has Active/Nonactive record control 
          let CD = await GetDropdownData(519062);
          this.setState({ PackagingTypes: CD.d });
          SessionInfo.PackagingTypeUpdate = false;
        }
        if (this.state.PricingTypesDict.length <= 1 || SessionInfo.PricingTypeUpdate === true) {
          let CD = await GetDropdownData(1104167);
          this.setState({ PricingTypesDict: CD.d });
          SessionInfo.PricingTypeUpdate = false;
          SetPricingType(CD.d);
        }
        else
          SetPricingType(this.state.PricingTypesDict); // May be replaced in ManagerPage  
        console.log("rates");
        if (this.state.RateTypesDict.length <= 1 || SessionInfo.RateTypeUpdate === true) {
          let CD = await GetDropdownData(545339);
          this.setState({ RateTypesDict: CD.d });
          SessionInfo.RateTypeUpdate = false;
          SetRateType(CD.d);
        }
        else
          SetRateType(this.state.RateTypesDict); // May be replaced in ManagerPage  
        if (this.state.StoreLocationsDict.length <= 1) {
          let CD = await GetDropdownData(544596);
          this.setState({ StoreLocationsDict: CD.d });
          SetStoreLocations(CD.d);
        }
        if (this.state.ProcessCommands.length <= 1) {
          let CD = await GetDropdownData(547556);
          //console.log("ProcessCommands: " + JSON.stringify(CD.d));
          this.setState({ ProcessCommands: CD.d });
        }
        if (this.state.StoreItemsDict.length <= 1) {
          let CD = await GetDependentDropdownData(544619,this.state.StoreLocationID,544598);
          this.setState({ StoreItemsDict: CD.d });
        }
        if (this.state.SeasonCodesDict.length <= 1) {
          let CD = await GetDropdownData(1120743);
          this.setState({ SeasonCodesDict: CD.d });
        }
        if (this.state.DayOfWeekDict.length <= 1) {
          let CD = await GetDropdownData(113509);
          this.setState({ DayOfWeekDict: CD.d });
        }
        if (this.state.TimeOfDayDict.length <= 1) {
          let CD = await GetDropdownData(546466);
          this.setState({ TimeOfDayDict: CD.d });
        }
        if (this.state.CompaniesDict.length <= 1) {
          let CD = await GetDropdownData(488610);
          this.setState({ CompaniesDict: CD.d });
        }
        console.log("StoreGroups");
        if (this.state.StoreGroupsDict.length <= 1) {
          let CD = await GetDropdownData(544709);
          this.setState({ StoreGroupsDict: CD.d });
        }
        if (this.state.PromotionTypes.length <= 1) {
          let CD = await GetDropdownData(545179);
          this.setState({ PromotionTypes: CD.d });
        }
        if (this.state.TimeOfDaysDict.length <= 1) {
          let CD = await GetDropdownData(546466);
          this.setState({ TimeOfDaysDict: CD.d });
          SetTimeOfDay(CD.d);
        }
        if (this.state.DayOfWeekDict.length <= 1) {
          let CD = await GetDropdownData(517980);
          this.setState({ DayOfWeekDict: CD.d });
          SetDayOfWeeks(CD.d);
        }
        // 6Feb23 - this is why DICTS need a different Name!
        //if (this.state.TaxableTypes.length <= 1) {
        //  let CD = await GetDropdownData(546870);
        //  this.setState({ TaxableTypes: CD.d });
        //}
        //console.log("Weights");
        if (this.state.UOMWeights.length <= 1) {
          let CD = await GetDropdownData(512615);
          this.setState({ UOMWeights: CD.d });
        }
        //console.log("Storage");
        //console.log("locations");
        if (this.state.StoreLocations.length <= 1) {
          let CD = await GetDropdownData(544596);
          this.setState({ StoreLocations: CD.d });
        }
        if (this.state.TimeOverrideTypes.length <= 1) {
          let CD = await GetDropdownData(547665);
          this.setState({ TimeOverrideTypes: CD.d });
          SetTimeOverrideTypes(CD.d);
        }
        this.setState({ StoreLocationID: SessionInfo.StoreLocationID });
        this.setItemStateChanged(false);
        console.log("Dictionary Load Complete");
      }
      //---- Scheduler Data Load - for first month ---- 
      // Note - 24Nov23 - this necessary to initialize the first month for the SchedControl - initialization needs to be part of the control
      let parms = [new Date(),"month"]; // Note, just load the current month
      let CD = await CallOM("GetTasks",0,0,9,parms); // TaskList Returned for Scheduler  
      //let CD = await CallOM("GetTasks",0,0,0); // TaskList Returned for Scheduler
      await getTaskID(); // Initialize TaskID for create
      console.log("W Tasks: " + JSON.stringify(CD.d));
      let taskData = CD.d.rows;
      console.log("taskData: " + JSON.stringify(taskData));
      let TaskData = taskData.map(dataItem => (
        {
          TaskID: dataItem.COTaskID,
          OwnerID: 2,
          Title: dataItem.Title,
          Description: dataItem.Description,
          Start: new Date(dataItem.StartDateTime),
          End: new Date(dataItem.EndDateTime),
          isAllDay: dataItem.isAllDay,
          RecurrenceRule: dataItem.RecurrenceRule,
          RecurrenceId: dataItem.RecurrenceID,
          RecurrenceExceptions: dataItem.RecurrenceException,
          TaskCommandID: dataItem.TaskCommandID,
          Parameters: dataItem.Parameters,
        }
      ));
      console.log(`TaskData: ${JSON.stringify(TaskData)}`);
      this.setState({ SchedulerData: TaskData });
      // Start Monitor
      this.monitorProcess()
    }
    catch (error) {
      displayError("Manager DidMount: " + error);
    }
  }
  componentDidUpdate() {
    //let value = this.context;
  }
  componentWillUnmount() {
    if (SessionInfo.session !== '')
      SessionInfo.ManagerPage = this.state;
  }
  setTimer() {
    if (SessionInfo.loggedOn === true)
      this.timeout = setTimeout(this.monitorProcess.bind(this),1000); // in milliseconds  
  }
  //----- Timer Process - Application Control Logic -------------------------------------------------
  monitorProcess() {
    //console.log("monitor proc");
    if (SessionInfo.forceLogoff === true)
      this.props.navigate("/Login");
    this.setTimer();
  }
  //--------------- Standard Page Functions ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------         
  searchCurrent = async (search) => {
    console.log(`==> search: ${search}`);
    if (!search || typeof search !== 'string' || search === 'all' || search === 'ALL')
      search = "";
    console.log(`Manager search: ${search}, Tab: ${this.state.selectedTopTab}`);
    this.setState({ bottomDisplay: 0 });
    if (this.state.selectedTopTab === this.tabs.ProductCategories) { // Product Categories
      displayMessage("Search for Product Categories");
      this.setState({ ProductCategorysSkip: 0 });
      this.setState({ ProductCategorysTake: 8 })
      await this.getProductCategorys(search);
    }
    else if (this.state.selectedTopTab === this.tabs.ProductGroups) { // Product Groups
      displayMessage("Search for Product Groups");
      this.setState({ ProductGroupsSkip: 0 });
      this.setState({ ProductGroupsTake: this.takeLines })
      await this.getProductGroups(search);
    }
    else if (this.state.selectedTopTab === this.tabs.ProductTypes) { // ProductType
      displayMessage("Search for ProductType");
      this.setState({ ProductTypeSkip: 0 });
      this.setState({ ProductTypeTake: this.takeLines })
      await this.getProductTypes(search);
    }
    else if (this.state.selectedTopTab === this.tabs.PricingTypes) { // PricingTypes
      displayMessage("Search for PricingTypes");
      this.setState({ PricingTypesSkip: 0 });
      this.setState({ PricingTypesTake: this.takeLines })
      await this.getPricingTypes(search);
    }
    else if (this.state.selectedTopTab === this.tabs.RateTypes) { // RateTypes
      displayMessage("Search for RateTypes");
      this.setState({ RateTypesSkip: 0 });
      this.setState({ RateTypesTake: this.takeLines })
      await this.getRateTypes(search);
    }
    else if (this.state.selectedTopTab === this.tabs.SeasonCodes) { // Search SeasonCodes
      displayMessage("Search for SeasonCodes");
      this.setState({ mainSeasonCodesSkip: 0 });
      this.setState({ mainSeasonCodesTake: this.takeLines })
      await this.getSeasonCodes(search);
    }
    //------ TimeOfDays Search ------
    else if (this.state.selectedTopTab === this.tabs.TimeOfDays) { // Search TimeOfDays
      displayMessage("Search for TimeOfDays");
      this.setState({ mainTimeOfDaysSkip: 0 });
      this.setState({ mainTimeOfDaysTake: this.takeLines })
      await this.getTimeOfDays(search);
    }
    //------ TaxableTypes Search ------
    else if (this.state.selectedTopTab === this.tabs.TaxableTypes) { // Search TaxableTypes
      displayMessage("Search for TaxableTypes");
      this.setState({ mainTaxableTypesSkip: 0 });
      this.setState({ mainTaxableTypesTake: this.takeLines })
      await this.getTaxableTypes(search);
    }
    else if (this.state.selectedTopTab === this.tabs.ServiceTypes) // TabNumber
    { // Search ServiceTypes
      displayMessage(`Search for ServiceTypes`);
      this.setState({ mainServiceTypesSkip: 0 });
      this.setState({ mainServiceTypesTake: this.takeLines });
      await this.getServiceTypes(search);
    }
    else if (this.state.selectedTopTab === this.tabs.Notifications) { // AppNotifications 
      displayMessage("Search for AppNotifications");
      this.setState({ AppNotificationsSkip: 0 });
      this.setState({ AppNotificationsTake: this.takeLines })
      await this.getAppNotifications(search);
    }
    else if (this.state.selectedTopTab === this.tabs.Promotions) {
      displayMessage("Search for Promotions");
      this.setState({ StorePromotionsSkip: 0 });
      this.setState({ StorePromotionsTake: this.takeLines })
      await this.getStorePromotions(search);
    }
    else if (this.state.selectedTopTab === this.tabs.ProcessActions) { // Search ProcessActions 
      //-------------------------------------------------------------------------------------------------------------   
      // Only DO THIS For Process Actions TAB
      let ExtSel = "";
      let LocSrch = false;
      console.log("search SelectItemCategory=" + this.state.SelectItemCategory + " ItemCategoryID=" + this.state.ProductItemCategoryID);
      if (this.state.ActionID !== 2 && this.state.ActionID !== 3) { // StoreItems
        if (this.state.SelectItemCategory && this.state.ProductItemCategoryIDSel > 0) {
          ExtSel = "& ProductItemCategoryID=" + this.state.ProductItemCategoryIDSel + " ";
        }
        if (this.state.SelectItemGroup && this.state.ProductItemGroupIDSel > 0) {
          ExtSel += "& ProductItemGroupID=" + this.state.ProductItemGroupIDSel + " ";
        }
        if (this.state.SelectItemType && this.state.ProductItemTypeIDSel > 0) {
          ExtSel += "& ProductItemTypeID=" + this.state.ProductItemTypeIDSel + " ";
        }
        if (this.state.SelectBrand && this.state.BrandIDSel > 0) {
          ExtSel += "& BrandID=" + this.state.BrandIDSel + " ";
        }
        if (this.state.SelectItemStatus && this.state.ItemStatusIDSel > 0) {
          ExtSel += "& ItemStatusID=" + this.state.ItemStatusIDSel + " ";
        }
        if (this.state.SelectSeasonCode && this.state.PackagingTypeIDSel > 0) {
          ExtSel += "& PackagingTypeID=" + this.state.PackagingTypeIDSel + " ";
        }
        if (this.state.SelectDayOfWeek && this.state.DayOfWeekSel > 0) {
          ExtSel += "& DayOfWeek=" + this.state.DayOfWeekSel + " ";
        }
        if (this.state.SelectTimeOfDay && this.state.TimeOfDayIDSel > 0) {
          ExtSel += "& TimeOfDayID=" + this.state.TimeOfDayIDSel + " ";
        }
        if (this.state.SelectPricingType && this.state.PricingTypeIDSel > 0) {
          ExtSel += "& PricingTypeID=" + this.state.PricingTypeIDSel + " ";
        }
        if (this.state.SelectRateType && this.state.RateTypeIDSel > 0) {
          ExtSel += "& RateTypeID=" + this.state.RateTypeIDSel + " ";
        }
      }
      //-------------------------------------------------------------------------
      // Apply to location
      let toLocationID = 0;
      if (this.state.ActionID === ProcessCmd.CopyItemsToLocations || this.state.ActionID === ProcessCmd.UpdateLocationItems) {
        if (this.state.SelectLocation && this.state.StoreLocationIDSel > 0)
          toLocationID = this.state.StoreLocationIDSel;
      } else {
        if (this.state.SelectLocation && this.state.StoreLocationIDSel > 0) {
          ExtSel += "& StoreLocationID=" + this.state.StoreLocationIDSel + " ";
          LocSrch = true;
        }
        if (this.state.SelectStoreGroup && this.state.StoreGroupIDSel > 0) {
          ExtSel += "& StoreGroupID=" + this.state.StoreGroupIDSel + " ";
          LocSrch = true;
        }
      }
      //if (this.state.SelectCompany && this.state.CompanyIDSel > 0) {
      //  ExtSel += "& CompanyID=" + this.state.CompanyIDSel + " ";
      //  LocSrch = true;
      //}
      // Apply to hours           
      console.log(`SelectDayOfWeekH: ${this.state.SelectDayOfWeekH} DayOfWeekHSel: ${this.state.DayOfWeekHSel}`);
      if (this.state.SelectDayOfWeekH && this.state.DayOfWeekHSel >= 0) {
        if (this.state.DayOfWeekHSel !== 12) { // Not Everyday
          if (this.state.DayOfWeekHSel === 11) { // Weekend
            ExtSel += "& (DayOfWeek=6 or DayOfWeek=7) ";
          }
          else if (this.state.DayOfWeekHSel === 10) { // WeekDay
            ExtSel += "& (DayOfWeek>0 and DayOfWeek<6) ";
          }
          else if (this.state.DayOfWeekHSel === 13) { // BusinessDay
            ExtSel += "& (DayOfWeek>0 and DayOfWeek<6) ";
          }
          else
            ExtSel += "& DayOfWeek=" + this.state.DayOfWeekHSel + " ";
        }
      }
      //displayMessage(`Item, Location or Hour Selection: ${ExtSel}, search: ${search}`);
      console.log(`==> Item, Location or Hour Selection: ${ExtSel}, search: ${search} ActionID: ${this.state.ActionID}`);
      //if (search.substring(0, 1) === '`')
      //  search += '|';
      //-------------------------------------------------------------------------------------------------------------------------------------------------------------------------
      if (this.state.ActionID === ProcessCmd.FillItemGrid || this.state.ActionID === ProcessCmd.AdjustPrices || this.state.ActionID === ProcessCmd.SetPrices) { // StoreItems
        if (ExtSel.length > 0) {
          ExtSel = ExtSel.replace(/&/g,'and');
          console.log("Extended Item search: " + ExtSel);
          if (LocSrch === false)
            ExtSel = "*StoreLocationID=0 " + ExtSel;
        }
        else if (LocSrch === false)
          ExtSel = "*StoreLocationID=0";
        //this.setState({ bottomDisplay: 0 });  
        if (ExtSel.indexOf('and') === 0)
          ExtSel = "*" + ExtSel.substring(4);
        search += ExtSel;
        console.log(`--> Extended Item search: ${search}`);
        this.extendedSearch = search;
        await this.getStoreItemRecords(search);
      }
      else if (this.state.ActionID === ProcessCmd.FillLocationGrid) { // StoreLocations
        if (ExtSel.length > 0) {
          ExtSel = ExtSel.replace(/&/g,'and');
          search = ExtSel;
        }
        if (search.indexOf('and') === 0)
          search = "*" + search.substring(4);
        console.log(`--> Extended Location search: ${search}`);
        this.extendedSearch = search;
        await this.getStoreLocations(search);
      }
      else if (this.state.ActionID === ProcessCmd.FillHoursGrid) { // HoursOfOperation 
        console.log("Hours search ExtSel: " + ExtSel);
        if (ExtSel.length > 0) {
          ExtSel = ExtSel.replace(/&/g,'and');
          //console.log("Extended Hours search: " + ExtSel);
          if (search.indexOf('`') === 0)
            search = search + '{' + ExtSel.substring(4);
          else
            search = search + ' ' + ExtSel;
        }
        if (search.indexOf('and') === 0)
          search = "*" + search.substring(4);
        else if (search.indexOf('*') !== 0 && search.indexOf('`') !== 0)
          search = "*StoreLocationID>0 " + search;
        console.log(`--> Extended Hours search: ${search}`);
        this.extendedSearch = search;
        await this.getHoursOfOperation(search);
        //console.log(`and: ${search.indexOf('and')}`);
      }
      else {
        if (ExtSel.length > 0) {
          ExtSel = ExtSel.replace(/&/g,'and');
          //console.log("Extended Item search: " + ExtSel);
          if (LocSrch === false)
            search += "*StoreLocationID=0 " + ExtSel;
          else
            search = ExtSel;
        }
        else if (LocSrch === false)
          search += "*StoreLocationID=0";
        //this.setState({ bottomDisplay: 0 });  
        if (search.indexOf('and') === 0)
          search = "*" + search.substring(4);
        console.log(`--> Extended Alt search: ${search}`);
        this.extendedSearch = search;
      }
    } //----------------------------------------------------------------------------------------------------------------------------   
    else if (this.state.ActionID === ProcessCmd.CopyItemsToLocations) { // CopyItems
      //  if (this.state.SelectLocation && this.state.StoreLocationIDSel > 0) {
      //    toLocationID = this.state.StoreLocationIDSel;
      //    let CD = await CallOM("CopyStoreItemsToStore",0,toLocationID,2,search);
      //  }
      //  else
      //    displayMessage("A location must be selected for Copy Store Items");
    }
    else if (this.state.ActionID === ProcessCmd.UpdateLocationItems) { // UpdateItems  
      //  if (this.state.SelectLocation && this.state.StoreLocationIDSel > 0) {
      //    toLocationID = this.state.StoreLocationIDSel;
      //    let CD = await CallOM("UpdateStoreItems",0,toLocationID,2,search);
      //  }
      //  else
      //    displayMessage("A location must be selected for Update Store Items");
    }
    else if (this.state.selectedTopTab === this.tabs.OptionGroups) { // Search OptionGroups   
      displayMessage("Search for OptionGroups");
      if (this.searchAlt === 0) {
        this.setState({ ProductOptionsGroupsSkip: 0 });
        this.setState({ ProductOptionsGroupsTake: this.takeLines });
        await this.getProductItemOptions(search);
      }
      else { // Search Main Items   
        this.setState({ MainProductItemsSkip: 0 });
        this.setState({ MainProductItemsTake: this.halfTakeLines });
        await this.getMainProductItems(search);
      }
    }
    else if (this.state.selectedTopTab === 99) { // XXItems
      displayMessage("Search for XXItems");
      this.setState({ mainXXItemsSkip: 0 });
      this.setState({ mainXXItemsTake: this.takeLines })
      await this.getXXItems(search);
    }
    this.saveSearch = search;
  }
  //-------------------------------------------------------------------------------------------------------------
  //----- Tab Selection -----   
  handleTopTabSelect = async (e) => { // Tab Selection
    await this.selectTab(e.selected);
  }
  selectTab = async (tabNumber) => { // Note - Load Dictionaries based on Tab
    console.log("Tab Select in Manager - Tab: " + tabNumber);
    this.searchAlt = 0;
    this.setState({ ActionID: 0 });
    let horizontalPanes = [{ collapsible: false,min: '65%',collapsed: false },{ collapsible: true,collapsed: true }]; // Left/Right
    try {
      if (SessionInfo.clearSearch)
        SessionInfo.clearSearch();
      this.setState({ selectedTopTab: tabNumber });
      this.setState({ bottomDisplay: 0 });
      SessionInfo.setShowSave = 2; // Turn off Save in Header 
      SessionInfo.setGridAdd = 2;
      SessionInfo.setShowNew = 2;
      SessionInfo.setExcelExport = 2;
      SessionInfo.setGridRefresh = 2;
      if (tabNumber === this.tabs.ProductCategories) { // Product Categories  
        SessionInfo.searchTitle = "Search Categories";
        if (this.state.ProductCategorys.length <= 1)
          await this.getProductCategorys('');
        SessionInfo.setGridAdd = 1;
        //SessionInfo.setExcelExport = 1;
        //SessionInfo.currExcelExportFunc = this.exportProductCategorys;
        SessionInfo.setGridRefresh = 1;
        SessionInfo.currAddGridRowFunc = this.enterInsertProductCategory;
        SessionInfo.currRefreshGridFunc = this.getProductCategorys;
        SessionInfo.headerTitle = "Product Categories";
      }
      else if (tabNumber === this.tabs.ProductGroups) { // Product Group 
        SessionInfo.searchTitle = "Search Groups";
        if (this.state.ProductGroups.length <= 1)
          await this.getProductGroups('');
        SessionInfo.setGridAdd = 1;
        //SessionInfo.setExcelExport = 1;
        SessionInfo.setGridRefresh = 1;
        SessionInfo.currAddGridRowFunc = this.enterInsertProductGroup;
        SessionInfo.currRefreshGridFunc = this.getProductGroups;
        SessionInfo.headerTitle = "Product Groups";
      }
      else if (tabNumber === this.tabs.ProductTypes) { // Product Types 
        displayMessage("Search for ProductType");
        SessionInfo.searchTitle = "Search Product Type";
        if (this.state.ProductTypes.length <= 1)
          await this.getProductTypes('');
        SessionInfo.setGridAdd = 1;
        //SessionInfo.setExcelExport = 1;
        //SessionInfo.currExcelExportFunc = this.exportProductTypes;
        SessionInfo.setGridRefresh = 1;
        SessionInfo.currAddGridRowFunc = this.enterInsertProductType;
        SessionInfo.currRefreshGridFunc = this.getProductTypes;
        SessionInfo.headerTitle = "Product Types";
      }
      else if (tabNumber === this.tabs.PricingTypes) { // PricingTypes   
        SessionInfo.searchTitle = "Search Pricing Types";
        if (this.state.PricingTypes.length <= 1)
          await this.getPricingTypes('');
        SessionInfo.setGridAdd = 1;
        //SessionInfo.setExcelExport = 1;
        //SessionInfo.currExcelExportFunc = this.exportPricingTypes;
        SessionInfo.setGridRefresh = 1;
        SessionInfo.currAddGridRowFunc = this.enterInsertPricingType;
        SessionInfo.currRefreshGridFunc = this.getPricingType;
        SessionInfo.headerTitle = "Pricing Types";
      }
      else if (tabNumber === this.tabs.RateTypes) { // RateTypes   
        displayMessage("Search for RateTypes");
        SessionInfo.searchTitle = "Search RateTypes";
        console.log("show Rate Types: " + JSON.stringify(this.state.RateTypes));
        if (this.state.RateTypes.length <= 1)
          await this.getRateTypes('');
        SessionInfo.setGridAdd = 1;
        //SessionInfo.setExcelExport = 1;
        //SessionInfo.currExcelExportFunc = this.exportRateTypes;
        SessionInfo.setGridRefresh = 1;
        SessionInfo.currAddGridRowFunc = this.enterInsertRateType;
        SessionInfo.currRefreshGridFunc = this.getRateTypes;
        SessionInfo.headerTitle = "Rate Types";
      }
      //------ SeasonCodes Tab Select ------
      else if (tabNumber === this.tabs.SeasonCodes) { // SeasonCodes   
        SessionInfo.searchTitle = "Search Season Codes";
        SessionInfo.currAddGridRowFunc = this.enterInsertSeasonCode;
        SessionInfo.setGridAdd = 1;
        if (this.state.SeasonCodes.length <= 1)
          await this.getSeasonCodes('');
        this.setState({ bottomDisplay: this.state.SeasonCodes });
        //SessionInfo.setExcelExport = 1; // Show Export in Header 
        //SessionInfo.currExcelExportFunc = this.exportSeasonCodes;
        SessionInfo.setGridRefresh = 1; // Show RefreshGrid in Header 
        SessionInfo.currRefreshGridFunc = this.getSeasonCodes;
      }
      //------ TimeOfDays Tab Select ------
      else if (tabNumber === this.tabs.TimeOfDays) { // TimeOfDays   
        SessionInfo.searchTitle = "Search Times of Day";
        SessionInfo.currAddGridRowFunc = this.enterInsertTimeOfDay;
        SessionInfo.setGridAdd = 1;
        if (this.state.TimeOfDays.length <= 1)
          await this.getTimeOfDays('');
        this.setState({ bottomDisplay: this.state.TimeOfDays });
        //SessionInfo.setExcelExport = 1; // Show Export in Header 
        //SessionInfo.currExcelExportFunc = this.exportTimeOfDays;
        SessionInfo.setGridRefresh = 1; // Show RefreshGrid in Header 
        SessionInfo.currRefreshGridFunc = this.getTimeOfDays;
      }
      //------ TaxableTypes Tab Select ------
      else if (tabNumber === this.tabs.TaxableTypes) { // TaxableTypes      
        SessionInfo.searchTitle = "Search Taxable Types";
        console.log(`Taxable Types Tab - length: ${this.state.TaxableTypes.length}`);
        console.log(`TaxableTypes: ${JSON.stringify(this.state.TaxableTypes)}`);
        SessionInfo.currAddGridRowFunc = this.enterInsertTaxableType;
        SessionInfo.setGridAdd = 1;
        if (this.state.TaxableTypes.length <= 1)
          await this.getTaxableTypes('');
        this.setState({ bottomDisplay: this.state.TaxableTypesTabNumber });
        //SessionInfo.setExcelExport = 1; // Show Export in Header 
        //SessionInfo.currExcelExportFunc = this.exportTaxableTypes;
        SessionInfo.setGridRefresh = 1; // Show RefreshGrid in Header 
        SessionInfo.currRefreshGridFunc = this.getTaxableTypes;
      }
      //------ ServiceTypes Tab Select ------
      else if (tabNumber === this.tabs.ServiceTypes) { // ServiceTypes   
        SessionInfo.searchTitle = "Search Service Types";
        SessionInfo.currAddGridRowFunc = this.enterInsertServiceType;
        SessionInfo.setGridAdd = 1;
        if (this.state.ServiceTypes.length <= 1)
          await this.getServiceTypes('');
        this.setState({ bottomDisplay: this.state.ServiceTypes }); // TabNumber
        //SessionInfo.setExcelExport = 1; // Show Export in Header 
        //SessionInfo.currExcelExportFunc = this.exportServiceTypes;
        SessionInfo.setGridRefresh = 1; // Show RefreshGrid in Header 
        SessionInfo.currRefreshGridFunc = this.getServiceTypes;
      }
      else if (tabNumber === this.tabs.Notifications) { // AppNotifications  
        horizontalPanes = [{ collapsible: false,size: '55%',min: '55%',collapsed: false },{ collapsible: true,collapsed: false }]; // Left/Right
        //SessionInfo.currAddGridRowFunc = this.enterInsertAppNotification;
        displayMessage("Search for AppNotifications");
        SessionInfo.searchTitle = "Search Notifications";
        if (this.state.NotificationTypes.length <= 1) {
          let CD = await GetDropdownData(178051); // NotificationTypes
          this.setState({ NotificationTypes: CD.d });
          SetNotificationTypes(CD.d);
          SessionInfo.setGridAdd = 1;
        }
        //console.log("StoreLocations - lth: " + this.state.StoreLocations.length);
        if (this.state.StoreLocations.length <= 1) {
          let CD = await GetDropdownData(544596); // StoreLocations
          this.setState({ StoreLocations: CD.d });
          //console.log("StoreLocations: " + JSON.stringify(CD.d));
          SetStoreLocations(CD.d);
        }
        console.log("StoreGroups - lth: " + this.state.StoreGroups.length);
        if (this.state.StoreGroups.length <= 1) {
          let CD = await GetDropdownData(544709); // StoreGroups
          this.setState({ StoreGroups: CD.d });
        }
        //console.log("AppNotifications - lth: " + this.state.AppNotifications.length);
        if (this.state.AppNotifications.length <= 1)
          await this.getAppNotifications('');
        this.setState({ bottomDisplay: 2 });
        SessionInfo.headerTitle = "Notifications";
      }
      else if (tabNumber === this.tabs.Promotions) {
        horizontalPanes = [{ collapsible: false,size: '60%',min: '30%',collapsed: false },{ collapsible: true,collapsed: false }]; // Left/Right  
        //this.setState({ verticalPanes: this.vertSplitC });
        SessionInfo.headerTitle = "Manage Promotions";
        SessionInfo.setShowSave = 1; // Show Save in Header 
        SessionInfo.setShowNew = 1;
        SessionInfo.setGridAdd = 1;
        SessionInfo.currAddGridRowFunc = this.enterInsertStorePromotion;
        SessionInfo.setGridRefresh = 1; // Grid Refresh in Header    
        SessionInfo.currRefreshGridFunc = this.getStorePromotions;
        SessionInfo.setExcelExport = 1;
        SessionInfo.currExcelExportFunc = this.exportPromotionsExcel;
        SessionInfo.searchTitle = "Search Promotions";
        this.setState({ RightO: false });
        //this.closeLeftPane();
        this.setState({ verticalPanes: this.vertSplitO });
        if (this.state.promotionsCount <= 1)
          await this.getStorePromotions('');
      }
      else if (tabNumber === this.tabs.ProcessActions) {
        SessionInfo.headerTitle = "Process Commands";
        SessionInfo.setMessageOpen = 1;
        SessionInfo.searchTitle = "Augment Search";
        horizontalPanes = [{ collapsible: false,size: '40%',min: '30%',collapsed: false },{ collapsible: true,collapsed: false }]; // Left/Right  
        this.setState({ verticalPanes: this.vertSplitOH });
        console.log("StoreLocations - lth: " + this.state.StoreLocations.length);
        if (this.state.StoreLocations.length <= 1) {
          let CD = await GetDropdownData(544596); // StoreLocations
          this.setState({ StoreLocations: CD.d });
          console.log("StoreLocations: " + JSON.stringify(CD.d));
          SetStoreLocations(CD.d);
        }
      }
      else if (tabNumber === this.tabs.OptionGroups) {
        horizontalPanes = [{ collapsible: false,size: '50%',min: '30%',collapsed: false },{ collapsible: true,collapsed: false }]; // Left/Right
        this.setState({ verticalPanes: this.vertSplitO });
        console.log("Get OptionGroups");
        SessionInfo.headerTitle = "Manage Product Options";
        if (this.state.productOptionsGroups === undefined || this.state.ProductOptionsGroups.length <= 1) {
          await this.getProductOptionsGroups('');
        }
        SessionInfo.setGridAdd = 1;
        SessionInfo.searchTitle = "Search Options";
        SessionInfo.currAddGridRowFunc = this.enterInsertProductOptionsGroup;
        SessionInfo.setGridRefresh = 1; // Show Grid Refresh  
        SessionInfo.currRefreshGridFunc = this.searchCurrent;
        SessionInfo.setGridRefresh = 2; // Hide Grid Refresh    
        SessionInfo.searchFunc = this.getProductOptionsGroups;
        if (this.state.MainStoreItems.length <= 1) {
          await this.getMainProductItems(this.state.ItemSelect);
        }
      }
      else if (tabNumber === this.tabs.Scheduler) {
        // Note - init for Scheduler Tasks is done in componentDidMount - otherwise it starts blank
        //  let parms = [new Date(),"month"];
        //  let CD = await CallOM("GetTasks",0,0,9,parms); // TaskList Returned for Scheduler  
        //  await getTaskID(); // Initialize TaskID for Create
        //  console.log("W Tasks: " + JSON.stringify(CD.d));
        //  let taskData = CD.d.rows;
        //  console.log("taskData: " + JSON.stringify(taskData));
        //  let TaskData = taskData.map(dataItem => (
        //    {
        //      TaskID: dataItem.COTaskID,
        //      OwnerID: 2,
        //      Title: dataItem.Title,
        //      Description: dataItem.Description,
        //      Start: new Date(dataItem.StartDateTime),
        //      End: new Date(dataItem.EndDateTime),
        //      isAllDay: dataItem.isAllDay,
        //      RecurrenceRule: dataItem.RecurrenceRule,
        //      RecurrenceId: dataItem.RecurrenceID,
        //      RecurrenceExceptions: dataItem.RecurrenceException,
        //      TaskCommandID: dataItem.TaskCommandID,
        //      Parameters: dataItem.Parameters,
        //    }
        //  ));
        //  console.log(`TaskData: ${JSON.stringify(TaskData)}`);
        //  this.setState({ SchedulerData: TaskData });  
        //  console.log(`Sched TaskData: ${JSON.stringify(TaskData)}`);
      }
      else if (tabNumber === this.tabs.SpreadSheet) { //   
        this.setState({ selectedTopTab: tabNumber });
        SessionInfo.setGridRefresh = 2; // Grid Refresh in orderID  
        SessionInfo.setExcelExport = 2;
        SessionInfo.setShowSave = 1; // Show Save in orderID 
        SessionInfo.setShowNew = 1;
        this.setState({ verticalPanes: this.vertSplitC });
        this.closeLeftPane();
        this.closeRightPane();
        this.currentTab = tabNumber;
      }
      else if (tabNumber === this.tabs.ReportViewer) { //   
        this.setState({ selectedTopTab: tabNumber });
        SessionInfo.setGridRefresh = 2; // Grid Refresh in orderID  
        SessionInfo.setExcelExport = 2;
        SessionInfo.setShowSave = 1; // Show Save in orderID 
        SessionInfo.setShowNew = 1;
        this.setState({ verticalPanes: this.vertSplitC });
        this.closeLeftPane();
        this.closeRightPane();
        this.currentTab = tabNumber;
      }
      else if (tabNumber === 99) { // XXItems
        if (this.state.XXItems.length <= 1)
          await this.getXXItems('');
      } else {
        console.log(`Invalid tabNumber: ${tabNumber}`);
      }
      this.setState({ horizontalPanes });
    }
    catch (ex) {
      displayError("Error in Tab Select: " + ex);
    }
  }
  //--------------------------------------------------------------------------
  setSearchButton = async (event) => { // Copy ProductItem to Related Items  
    SessionInfo.searchTitle = "Search Main Items";
    this.searchAlt = 2;
  }
  saveRecord = async () => {
    console.log("Save Record Tab: " + JSON.stringify(this.state.selectedTopTab));
    //export async function UpdateRow(tableID, control, allData, item, key, keyName) { // SaveRow
    if (this.state.selectedTopTab === this.tabs.Promotions) { // Promotions 
      let propState = {};
      StorePromotionsO.forEach((elem) => {
        if (elem.n in this.state) {
          propState[elem.n] = this.state[elem.n];
        }
      });
      console.log("Save Promotion Record: " + JSON.stringify(propState));
      //await UpdateRow(544701, 0, undefined, propState, this.state.StorePromotionID, "StorePromotionID"); // Save StorePromotion to 
      await CallOM("UpdatePromotion",0,this.state.StorePromotionID,13,propState);
      await this.getStorePromotions("");
    }
    this.setItemStateChanged(false);
  }
  deleteRecord = async () => {
    if (this.state.selectedTopTab === this.tabs.Promotions) { // Promotions 
      let CD = await DeleteRowTry(544596,this.state.StoreLocationID);
      //console.log("Delete Msg Lvl: " + CD.x.o);
      this.setItemStateChanged(false);
    }
  }
  clearRecord = async () => { // Note => functions do not bind their own this - if used clearRecord() - would have to bind this to the function
    //console.log("Clear Screen - Tab: " + this.state.selectedTopTab);  
    let fields = [];
    if (this.state.selectedTopTab === this.tabs.Promotions) { // Promotions 
      this.setState({ PromotionPhotoURL: undefined });
      fields = StorePromotionsO;
    }
    fields.forEach((elem) => {
      let sobj = {};
      try {
        if (elem.t === 34 || elem.t === 35)
          sobj[elem.n] = new Date();
        else if (elem.t === 21 || elem.t === 22)
          sobj[elem.n] = '';
        else
          sobj[elem.n] = 0;
        this.setState(sobj);
      } catch (error) {
        console.log("Clear Promotion Field: " + elem.n + " Error: " + error.message);
      }
    });                       
    this.clearScreen = true;
    this.setItemStateChanged(false);
  }
  refreshGrid = async () => { // Note => functions do not bind their own this - if used clearRecord() - would have to bind this to the function
    //console.log("Clear Screen - state: " + JSON.stringify(this.state)); 
    let fields = [];
    if (this.state.selectedTopTab === this.tabs.Promotions) { // Promotions 
      this.getStorePromotions('');
    }
  }
  //--------------- End of Standard Page Functions ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
  //--------------- Page Setup and Load Functions ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

  //------ Help Information -------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
  showHelp = async (key) => {
    if (this.state.showHelpListPopup == true) { // Close Help
      this.setState({ showHelpListPopup: false });
      this.setState({ showHelpDetail: false });
    } else {
      if (!key)
        key = '';
      this.setState({ showHelpDetail: false });
      let HelpPages = await getHelpTopics(key);
      //console.log("caller HelpPages: " + JSON.stringify(HelpPages));
      this.setState({ HelpPages });
      this.setState({ showHelpListPopup: true });
    }
  }
  closeHelpList = () => {
    this.setState({ showHelpListPopup: false });
  }
  onHelpItemClick = async (item) => {
    let helpDetail = await getHelpDetail(item.HelpPageID);
    this.setState({ helpDetail });
    this.setState({ showHelpDetail: true });
  }
  closeHelpTime = async () => {
    this.setState({ showHelpDetail: false });
  }
  onObjectHelp = async (objectID) => {
    let helpDetail = await getHelpDetail(objectID,2);
    this.setState({ helpDetail });
    this.setState({ showHelpDetail: true });
  }
  closeHelpTime = async () => {
    this.setState({ showHelpDetail: false });
  }
  // Help List Layout
  HelpPageHeader = () => {
    return (
      <ListViewHeader className='listHelpHeader' onClick={(e) => this.closeHelpList()} >
        <div className='listHeaderLeft' onClick={(e) => this.closeHelpList()}>
          <span className="listHelpTitle">{SessionInfo.HelpTitle}</span>
        </div>
        <div className='listHeaderRight' onClick={(e) => this.closeHelpList()}>
          <Button id="cancelButton" icon="cancel" look="outline" className="medButton" onClick={this.closeHelpList} >EXIT HELP</Button>
        </div>
      </ListViewHeader>
    );
  }
  HelpPageFormat = props => {
    let item = props.dataItem;
    return (
      <div>
        <div className="listHelpRow" onClick={(e) => this.onHelpItemClick(item)}>
          <div className="listColA">
            <span className="listHelpItemTitle" >{item.HelpTitle}&nbsp;&nbsp;&nbsp;</span>
            <div dangerouslySetInnerHTML={{ __html: item.HelpContent }}></div>
          </div>
        </div>
      </div>
    );
  }
  HelpPageFooter = () => {
    //const [state, onCheckout] = React.useContext(StateContext);
    return (
      <ListViewFooter className='listHelpFooter' onClick={(e) => this.closeHelpList()} >
        <div className='listHeaderRight' onClick={(e) => this.closeHelpList()}>
          <Button id="cancelButton" icon="cancel" look="outline" className="medButton" onClick={this.closeHelpList} >EXIT HELP</Button>
        </div>
      </ListViewFooter>
    );
  }
  ShowFieldHelp = async (key) => {
    console.log(`Field Help - Key: ${key}`);
    this.onObjectHelp(key);
    //this.showHelp(key);
    //console.log(`Field Help: ${JSON.stringify(item)}`);
  }
  //------ End of Help -------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
  //------------------------- Main Items ------------------------------------------------------------------
  getMainProductItems = async (search) => { // Get the MainStore product items - Company
    if (!search)
      search = '';
    if (search[0] === '*') {
      if (search.indexOf("StoreLocationID") < 0)
        search += " and StoreLocationID=0";
    }
    else
      search += "*StoreLocationID=0";
    let CD = await CallOM("GetMainProductItemsForLocation",2,0,2,search,"StoreItemID,ItemName,ItemDescription,BarcodeNumber,ItemPrice");
    //console.log("Product Items: " + JSON.stringify(CD.d));
    //this.setState({ StoreItems: CD.d });
    try {
      const MainStoreItems = CD.d.rows.map(dataItem => Object.assign({ selected: false },dataItem));
      //console.log("MainStore Items: " + JSON.stringify(MainStoreItems));
      this.setState({ MainStoreItems });
      this.setState({ mainStoreItemsCount: MainStoreItems.length });
    } catch (error) {
      console.log("Error getMainProductItems: " + error.message);
    }
  }
  mainStoreItemClick = async (event) => { // Copy ProductItem to Related Items
    if (!this.state.MainStoreItems)
      await this.saveRecord();
    const current = this.state.MainStoreItems.findIndex(dataItem => dataItem === event.dataItem);
    this.state.MainStoreItems.forEach(item => item.selected = false);
    this.state.MainStoreItems[current].selected = true;
    let optionStoreItemID = this.state.MainStoreItems[current].StoreItemID;
    let item = JSON.parse(JSON.stringify(this.state.MainStoreItems[current])); // Object Copy - Value copy
    console.log("item: " + JSON.stringify(item));
    let index = this.state.ProductItemOptions.findIndex(p => p.StoreItemID === optionStoreItemID); // Look to see if already copied
    //console.log("Select PKID: " + PKID + " index: " + index);
    if (index < 0) {
      const dataItem = { StoreItemID: this.state.StoreItemID,MultipleCount: 0,inEdit: true };
      console.log(`insert Option Item: ${JSON.stringify(dataItem)}`);
      console.log(`insert Store Item: ${this.state.StoreItemID}, Option: ${optionStoreItemID}`);
      let CD = await CallOM("AddStoreItemToOptionItems",0,this.state.ProductOptionsGroupID,optionStoreItemID);
      let RetVal = CD.d;
      console.log(`Retval: ${RetVal}`);
      if (RetVal === 1) {
        //await UpdateRow(544619, 0, this.state.StoreItems, item, 0, "StoreItemID"); Save Record - this does not work as did not load entire record 
        await this.getProductItemOptions();
      }
    }
    else
      displayWarning(`Item Copy for ${this.state.StoreItems[index].ItemName} Failed`);
  }
  sortChange = (event) => {
    this.setState({ products: this.GetProducts(event.sort),sort: event.sort });
  }
  getMainProductItemsSort = (sort) => {
    return orderBy(this.state.MainStoreItems,sort);
  }
  mainStoreItemsPageChange = (event) => {
    this.setState({ mainStoreItemsSkip: event.page.skip,mainStoreItemsTake: event.page.take });
  }
  //-------------------------------------------------------------------------------------------------------
  //-------------------------- ProductCategorys ----------------------------------------------------------------------------------
  getProductCategorys = async (search) => {
    if (!search)
      search = '';
    console.log(`search Categories - search: ${search}`);
    let CD = await CallOM("GetProductItemCategories",0,0,2,search);
    try {
      const ProductCategorys = CD.d.rows.map(dataItem => Object.assign({ selected: false },dataItem));
      //console.log("ProductCategorys: " + JSON.stringify(ProductCategorys));
      this.setState({ ProductCategorys });
      this.state.saveProductCategorys = ProductCategorys;
      SetProductItemCategory(ProductCategorys);
    } catch (error) {
      displayError("getProductCategorys: " + error.message);
    }
  }
  ProductCategoryRowClick = async (event) => {
    //console.log("ProductCategorys: " + JSON.stringify(this.state.ProductCategorys));
    //console.log("dataItem: " + JSON.stringify(event.dataItem));
    const current = this.state.ProductCategorys.findIndex(dataItem => dataItem === event.dataItem);
    if (current >= 0) {
      try {
        this.state.ProductCategorys.forEach(item => item.selected = false);
        this.state.ProductCategorys[current].selected = true;
        let ProductItemCategoryID = this.state.ProductCategorys[current].ProductItemCategoryID;
        this.setState({ ProductItemCategoryID });
        let CD = await GetTableRow(this.ProductCategoryOID,ProductItemCategoryID);
        //console.log("ProductCategorys Click " + JSON.stringify(CD.d));
        for (var prop in CD.d.row) {
          if (prop in this.state) {
            let obj = {};
            obj[prop] = CD.d.row[prop];
            if (prop.indexOf("Date") >= 0) {
              obj[prop] = new Date(CD.d.row[prop]); //new Date()
            }
            else if (prop === "ImageID") {
              this.state.ImageID = CD.d.row[prop];
            }
            this.setState(obj);
          }
        }
        SessionInfo.headerTitle = "Category: " + this.state.ProductItemCategory;
        this.setState({ currentItemName: this.state.ProductItemCategory });
        this.setState({ currentItemDescription: this.state.Description });
        if (this.state.ImageID !== 0)
          await this.GetProductCategoryImage(this.state.ImageID);
        else
          await this.GetProductCategoryImage(2);
        this.setItemStateChanged(false);
      }
      catch (error) {
        displayError("ProductCategoryRowClick: " + error);
      }
    }
  }
  GetProductCategoryImage = async (imageID) => {
    const imageURL = await getImageFileFromServer(imageID);
    this.setState({ ProductCategoryPhotoURL: imageURL });
  }
  ProductCategorysPageChange = (event) => {
    this.setState({ ProductCategorysSkip: event.page.skip,ProductCategorysTake: event.page.take });
  }
  //------------------------- Edit ProductCategory Grid ------------------------------------------------------------------
  ProductCategoryCommandCell;
  enterInsertProductCategory() {
    const dataItem = { inEdit: true };
    const allRecords = this.state.ProductCategorys.slice();
    allRecords.unshift(dataItem); // Add to the beginning
    this.updateProductCategorys(allRecords,dataItem);
    this.setState({ ProductCategorys: allRecords });
    this.InsertRecordType = 1;
    this.GetProductCategoryImage(1); // Blank Image
  }
  enterEditProductCategory(dataItem) {
    this.updateProductCategorys(this.state.ProductCategorys,dataItem).inEdit = true;
    this.setState({ ProductCategorys: this.state.ProductCategorys.slice() });
  }
  async saveProductCategory(dataItem) {
    dataItem.inEdit = undefined;
    if (dataItem.ProductItemCategoryID === undefined)
      dataItem.ProductItemCategoryID = 0;
    dataItem.ProductItemCategoryID = this.updateProductCategorys(this.state.ProductCategorys,dataItem).ProductItemCategoryID;  // Save to OM   
    console.log("saveProductCategory - ID: " + dataItem.ProductItemCategoryID);
    this.setState({ ProductCategorys: this.state.ProductCategorys.slice() });
    await UpdateRow(this.ProductCategoryOID,0,this.state.ProductCategorys,dataItem,dataItem.ProductItemCategoryID,"ProductItemCategoryID");  // Update StoreInfoView  
    if (this.DocumentLoaded === true && this.DocumentSaved === false) {
      await this.saveImageToServer(this.ProductCategoryOID,dataItem.ProductItemCategoryID);
    }
    let CD = await GetDropdownData(1104137);
    this.setState({ ProductItemCategories: CD.d });
    SetProductItemCategory(CD.d);
    if (SessionInfo.forceLogoff === true)
    this.InsertRecordType = 0;
    SessionInfo.ProductItemCategoriesUpdate = true;
  }
  cancelProductCategory(dataItem) {
    if (dataItem.ProductItemCategoryID) {
      let originalItem = this.state.ProductCategorys.find(p => p.ProductItemCategoryID === dataItem.ProductItemCategoryID)
      if (originalItem.inEdit) originalItem.inEdit = false;
      this.updateProductCategorys(this.state.ProductCategorys,originalItem);
    } else {
      this.updateProductCategorys(this.state.ProductCategorys,dataItem,!dataItem.ProductItemCategoryID); // remove false
    }
    this.setState({ ProductCategorys: this.state.ProductCategorys.slice() });
    this.InsertRecordType = 0;
  }
  removeProductCategory(dataItem) {
    dataItem.inEdit = undefined;
    let key = dataItem.ProductItemCategoryID;
    this.updateProductCategorys(this.state.ProductCategorys,dataItem,true);
    this.state.saveProductCategorys = this.state.ProductCategorys;
    DeleteRowTry(this.ProductCategoryOID,key); // Delete in the ProductCategorys Table in OM
    this.setState({ ProductCategorys: this.state.ProductCategorys.slice() });
    this.InsertRecordType = 0;
  }
  itemChangeProductCategory(event) {
    const value = event.value;
    const name = event.field;
    if (!name) {
      return;
    }
    const updatedData = this.state.ProductCategorys.slice();
    const item = this.updateProductCategorys(updatedData,event.dataItem);
    if (!value.ddID)
      item[name] = value;
    else
      item[name] = value.ddID;
    this.setState({ ProductCategorys: updatedData });
  }
  updateProductCategorys(data,item,remove) {  // data - is the entire data set (JSON), item - is the current line 
    let updated;
    //eslint-disable-next-line                   
    let index = data.findIndex(p => p === item || item.ProductItemCategoryID && p.ProductItemCategoryID === item.ProductItemCategoryID); // Note - for new line will find 0  
    if (index >= 0) {
      updated = Object.assign({},item);
      data[index] = updated;
    }
    if (remove) {
      data = data.splice(index,1);
    }
    return data[index];
  }
  //------------------------- End Edit ProductCategory Grid ------------------------------------------------------------------  
  //-------------------------- ProductGroups ----------------------------------------------------------------------------------
  getProductGroups = async (search) => {
    if (!search)
      search = '';
    //search = search + "*StoreLocationID=" + this.state.StoreLocationID;
    let CD = await GetTableSearchRaw(this.ProductGroupOID,search,"ProductItemGroupID,ProductItemGroup,Description,MenuLayoutID,SortOrder,IsRecordActive");
    try {
      const ProductGroups = CD.d.rows.map(dataItem => Object.assign({ selected: false },dataItem));
      this.setState({ ProductGroups });
      this.state.saveProductGroups = ProductGroups;
    } catch (error) {
      displayError("getProductGroup: " + error.message);
    }
  }
  ProductGroupRowClick = async (event) => {
    const current = this.state.ProductGroups.findIndex(dataItem => dataItem === event.dataItem);
    if (current >= 0) {
      try {
        this.state.ProductGroups.forEach(item => item.selected = false);
        this.state.ProductGroups[current].selected = true;
        let ProductItemGroupID = this.state.ProductGroups[current].ProductItemGroupID;
        this.setState({ ProductItemGroupID });
        let CD = await GetTableRow(this.ProductGroupOID,ProductItemGroupID);
        for (var prop in CD.d.row) {
          if (prop in this.state) {
            let obj = {};
            obj[prop] = CD.d.row[prop];
            if (prop.indexOf("Date") >= 0) {
              obj[prop] = new Date(CD.d.row[prop]); //new Date()
            }
            this.setState(obj);
          }
        }
        this.setItemStateChanged(false);
      }
      catch (error) {
        displayError("ProductGroupRowClick: " + error);
      }
    }
  }
  ProductGroupsPageChange = (event) => {
    this.setState({ ProductGroupsSkip: event.page.skip,ProductGroupsTake: event.page.take });
  }
  //------------------------- Edit ProductGroup Grid ------------------------------------------------------------------ 
  ProductGroupCommandCell;
  enterInsertProductGroup() {
    const dataItem = { inEdit: true };
    const allRecords = this.state.ProductGroups.slice();
    allRecords.unshift(dataItem); // Add to the beginning
    this.updateProductGroups(allRecords,dataItem);
    this.setState({ ProductGroups: allRecords });
    this.InsertRecordType = 2;
  }
  enterEditProductGroup(dataItem) {
    this.updateProductGroups(this.state.ProductGroups,dataItem).inEdit = true;
    this.setState({ ProductGroups: this.state.ProductGroups.slice() });
  }
  saveProductGroup(dataItem) {
    dataItem.inEdit = undefined;
    if (dataItem.ProductItemGroupID === undefined)
      dataItem.ProductItemGroupID = 0;
    console.log("Save");
    dataItem.ProductItemGroupID = this.updateProductGroups(this.state.ProductGroups,dataItem).ProductItemGroupID;  // Save to OM
    this.setState({ ProductGroups: this.state.ProductGroups.slice() });
    //console.log("Update Row - locationID: " + dataItem.StoreLocationID);
    UpdateRow(this.ProductGroupOID,0,this.state.ProductGroups,dataItem,dataItem.ProductItemGroupID,"ProductItemGroupID");
    this.InsertRecordType = 0;
    SessionInfo.ProductItemGroupUpdate = true;
  }
  cancelProductGroup(dataItem) {
    if (dataItem.ProductItemGroupID) {
      let originalItem = this.state.ProductGroups.find(p => p.ProductItemGroupID === dataItem.ProductItemGroupID)
      if (originalItem.inEdit) originalItem.inEdit = false;
      this.updateProductGroups(this.state.ProductGroups,originalItem);
    } else {
      this.updateProductGroups(this.state.ProductGroups,dataItem,!dataItem.ProductItemGroupID); // remove false
    }
    this.setState({ ProductGroups: this.state.ProductGroups.slice() });
    this.InsertRecordType = 0;
  }
  removeProductGroup(dataItem) {
    dataItem.inEdit = undefined;
    let key = dataItem.ProductItemGroupID;
    this.updateProductGroups(this.state.ProductGroups,dataItem,true);
    this.state.saveProductGroups = this.state.ProductGroups;
    DeleteRowTry(this.ProductGroupOID,key); // Delete in the ProductGroups Table in OM
    this.setState({ ProductGroups: this.state.ProductGroups.slice() });
    SessionInfo.ProductItemGroupUpdate = true;
  }
  itemChangeProductGroup(event) {
    const value = event.value;
    const name = event.field;
    if (!name) {
      return;
    }
    const updatedData = this.state.ProductGroups.slice();
    const item = this.updateProductGroups(updatedData,event.dataItem);
    if (!value.ddID)
      item[name] = value;
    else
      item[name] = value.ddID;
    this.setState({ ProductGroups: updatedData });
  }
  updateProductGroups(data,item,remove) {  // data - is the entire data set (JSON), item - is the current line 
    let updated;
    //eslint-disable-next-line                   
    let index = data.findIndex(p => p === item || item.ProductItemGroupID && p.ProductItemGroupID === item.ProductItemGroupID); // Note - for new line will find 0
    if (index >= 0) {
      updated = Object.assign({},item);
      data[index] = updated;
    }
    if (remove) {
      data = data.splice(index,1);
    }
    return data[index];
  }
  //------------------------- End Edit ProductGroup Grid ------------------------------------------------------------------  
  //-------------------------- ProductTypes ----------------------------------------------------------------------------------
  getProductTypes = async (search) => {
    if (!search)
      search = '';
    let CD = await GetTableSearchRaw(this.ProductTypeOID,search,"ProductItemTypeID,ProductItemType,Description,IsRecordActive");
    try {
      const ProductTypes = CD.d.rows.map(dataItem => Object.assign({ selected: false },dataItem));
      this.setState({ ProductTypes });
      this.state.saveProductTypes = ProductTypes;
    } catch (error) {
      displayError("getProductType: " + error.message);
    }
  }
  ProductTypeRowClick = async (event) => {
    const current = this.state.ProductTypes.findIndex(dataItem => dataItem === event.dataItem);
    if (current >= 0) {
      try {
        this.state.ProductTypes.forEach(item => item.selected = false);
        this.state.ProductTypes[current].selected = true;
        let ProductItemTypeID = this.state.ProductTypes[current].ProductItemTypeID;
        this.setState({ ProductItemTypeID });
        let CD = await GetTableRow(this.ProductTypeOID,ProductItemTypeID);
        for (var prop in CD.d.row) {
          if (prop in this.state) {
            let obj = {};
            obj[prop] = CD.d.row[prop];
            if (prop.indexOf("Date") >= 0) {
              obj[prop] = new Date(CD.d.row[prop]); //new Date()
            }
            this.setState(obj);
          }
        }
        this.setItemStateChanged(false);
      }
      catch (error) {
        displayError("ProductTypeRowClick: " + error);
      }
    }
  }
  ProductTypesPageChange = (event) => {
    this.setState({ ProductTypesSkip: event.page.skip,ProductTypesTake: event.page.take });
  }
  //------------------------- Edit ProductType Grid ------------------------------------------------------------------  
  ProductTypeCommandCell;
  enterInsertProductType() {
    const dataItem = { inEdit: true };
    const allRecords = this.state.ProductTypes.slice();
    allRecords.unshift(dataItem); // Add to the beginning
    this.updateProductTypes(allRecords,dataItem);
    this.setState({ ProductTypes: allRecords });
    this.InsertRecordType = 3;
  }
  enterEditProductType(dataItem) {
    this.updateProductTypes(this.state.ProductTypes,dataItem).inEdit = true;
    this.setState({ ProductTypes: this.state.ProductTypes.slice() });
  }
  saveProductType(dataItem) {
    dataItem.inEdit = undefined;
    if (dataItem.ProductItemTypeID === undefined)
      dataItem.ProductItemTypeID = 0;
    //console.log("Save");
    dataItem.ProductItemTypeID = this.updateProductTypes(this.state.ProductTypes,dataItem).ProductItemTypeID;  // Save to OM
    this.setState({ ProductTypes: this.state.ProductTypes.slice() });
    //console.log("Update Row - locationID: " + dataItem.StoreLocationID); 
    UpdateRow(this.ProductTypeOID,0,this.state.ProductTypes,dataItem,dataItem.ProductItemTypeID,"ProductItemTypeID");  // tableID, control, allData, item, key, keyName 
    SessionInfo.ProductItemTypeUpdate = true;
    this.InsertRecordType = 0;
    SessionInfo.ProductItemTypeUpdate = true;
  }
  cancelProductType(dataItem) {
    if (dataItem.ProductItemTypeID) {
      let originalItem = this.state.ProductTypes.find(p => p.ProductItemTypeID === dataItem.ProductItemTypeID)
      if (originalItem.inEdit) originalItem.inEdit = false;
      this.updateProductTypes(this.state.ProductTypes,originalItem);
    } else {
      this.updateProductTypes(this.state.ProductTypes,dataItem,!dataItem.ProductItemTypeID); // remove false
    }
    this.setState({ ProductTypes: this.state.ProductTypes.slice() });
    this.InsertRecordType = 0;
  }
  removeProductType(dataItem) {
    dataItem.inEdit = undefined;
    let key = dataItem.ProductItemTypeID;
    this.updateProductTypes(this.state.ProductTypes,dataItem,true);
    this.state.saveProductTypes = this.state.ProductTypes;
    DeleteRowTry(this.ProductTypeOID,key); // Delete in the ProductTypes Table in OM
    this.setState({ ProductTypes: this.state.ProductTypes.slice() });
  }
  itemChangeProductType(event) {
    const value = event.value;
    const name = event.field;
    if (!name) {
      return;
    }
    const updatedData = this.state.ProductTypes.slice();
    const item = this.updateProductTypes(updatedData,event.dataItem);
    if (!value.ddID)
      item[name] = value;
    else
      item[name] = value.ddID;
    this.setState({ ProductTypes: updatedData });
  }
  updateProductTypes(data,item,remove) {  // data - is the entire data set (JSON), item - is the current line 
    let updated;
    //eslint-disable-next-line                   
    let index = data.findIndex(p => p === item || item.ProductItemTypeID && p.ProductItemTypeID === item.ProductItemTypeID); // Note - for new line will find 0
    if (index >= 0) {
      updated = Object.assign({},item);
      data[index] = updated;
    }
    if (remove) {
      data = data.splice(index,1);
    }
    return data[index];
  }
  //------------------------- End Edit ProductType Grid ------------------------------------------------------------------   
  //-------------------------- PricingTypes ----------------------------------------------------------------------------------
  getPricingTypes = async (search) => {
    if (!search)
      search = '';
    let CD = await GetTableSearchRaw(this.PricingTypeOID,search,"PricingTypeID,PricingType,Description,MarkupValue,MarkupPercent,SetPriceType,IsRecordActive");
    try {
      const PricingTypes = CD.d.rows.map(dataItem => Object.assign({ selected: false },dataItem));
      console.log("get Pricing Types: " + JSON.stringify(PricingTypes));
      this.setState({ PricingTypes });
      this.state.savePricingTypes = PricingTypes;
    } catch (error) {
      displayError("getPricingType: " + error.message);
    }
  }
  PricingTypeRowClick = async (event) => {
    const current = this.state.PricingTypes.findIndex(dataItem => dataItem === event.dataItem);
    if (current >= 0) {
      try {
        this.state.PricingTypes.forEach(item => item.selected = false);
        this.state.PricingTypes[current].selected = true;
        let PricingTypeID = this.state.PricingTypes[current].PricingTypeID;
        this.setState({ PricingTypeID });
        let CD = await GetTableRow(this.PricingTypeOID,PricingTypeID);
        for (var prop in CD.d.row) {
          if (prop in this.state) {
            let obj = {};
            obj[prop] = CD.d.row[prop];
            if (prop.indexOf("Date") >= 0) {
              obj[prop] = new Date(CD.d.row[prop]); //new Date()
            }
            this.setState(obj);
          }
        }
        this.setItemStateChanged(false);
      }
      catch (error) {
        displayError("PricingTypeRowClick: " + error);
      }
    }
  }
  PricingTypesPageChange = (event) => {
    this.setState({ PricingTypesSkip: event.page.skip,PricingTypesTake: event.page.take });
  }
  //------------------------- Edit PricingType Grid ------------------------------------------------------------------ 
  PricingTypeCommandCell;
  enterInsertPricingType() {
    const dataItem = { inEdit: true };
    const allRecords = this.state.PricingTypes.slice();
    allRecords.unshift(dataItem); // Add to the beginning
    this.updatePricingTypes(allRecords,dataItem);
    this.setState({ PricingTypes: allRecords });
    this.InsertRecordType = 4;
  }
  enterEditPricingType(dataItem) {
    this.updatePricingTypes(this.state.PricingTypes,dataItem).inEdit = true;
    this.setState({ PricingTypes: this.state.PricingTypes.slice() });
  }
  savePricingType(dataItem) {
    dataItem.inEdit = undefined;
    if (dataItem.PricingTypeID === undefined)
      dataItem.PricingTypeID = 0;
    //console.log("Save");
    dataItem.PricingTypeID = this.updatePricingTypes(this.state.PricingTypes,dataItem).PricingTypeID;  // Save to OM
    this.setState({ PricingTypes: this.state.PricingTypes.slice() });
    //console.log("Update Row - locationID: " + dataItem.StoreLocationID); 
    UpdateRow(this.PricingTypeOID,0,this.state.PricingTypes,dataItem,dataItem.PricingTypeID,"PricingTypeID");
    this.InsertRecordType = 0;
    SessionInfo.PricingTypeUpdate = true;
  }
  cancelPricingType(dataItem) {
    if (dataItem.PricingTypeID) {
      let originalItem = this.state.PricingTypes.find(p => p.PricingTypeID === dataItem.PricingTypeID)
      if (originalItem.inEdit) originalItem.inEdit = false;
      this.updatePricingTypes(this.state.PricingTypes,originalItem);
    } else {
      this.updatePricingTypes(this.state.PricingTypes,dataItem,!dataItem.PricingTypeID); // remove false
    }
    this.setState({ PricingTypes: this.state.PricingTypes.slice() });
    this.InsertRecordType = 0;
  }
  removePricingType(dataItem) {
    dataItem.inEdit = undefined;
    let key = dataItem.PricingTypeID;
    this.updatePricingTypes(this.state.PricingTypes,dataItem,true);
    this.state.savePricingTypes = this.state.PricingTypes;
    DeleteRowTry(this.PricingTypeOID,key); // Delete in the PricingTypes Table in OM
    this.setState({ PricingTypes: this.state.PricingTypes.slice() });
  }
  itemChangePricingType(event) {
    const value = event.value;
    const name = event.field;
    if (!name) {
      return;
    }
    const updatedData = this.state.PricingTypes.slice();
    const item = this.updatePricingTypes(updatedData,event.dataItem);
    if (!value.ddID)
      item[name] = value;
    else
      item[name] = value.ddID;
    this.setState({ PricingTypes: updatedData });
  }
  updatePricingTypes(data,item,remove) {  // data - is the entire data set (JSON), item - is the current line 
    let updated;
    //eslint-disable-next-line                   
    let index = data.findIndex(p => p === item || item.PricingTypeID && p.PricingTypeID === item.PricingTypeID); // Note - for new line will find 0
    if (index >= 0) {
      updated = Object.assign({},item);
      data[index] = updated;
    }
    if (remove) {
      data = data.splice(index,1);
    }
    return data[index];
  }
  //------------------------- End Edit PricingType Grid ------------------------------------------------------------------  
  //-------------------------- RateTypes ----------------------------------------------------------------------------------
  getRateTypes = async (search) => {
    if (!search)
      search = '';
    //search = search + "*StoreLocationID=" + this.state.StoreLocationID;
    let CD = await GetTableSearchRaw(this.RateTypeOID,search,"RateTypeID,RateType,Description,ShortDescription");
    try {
      const RateTypes = CD.d.rows.map(dataItem => Object.assign({ selected: false },dataItem));
      console.log("get Rate Types: " + JSON.stringify(RateTypes));
      this.setState({ RateTypes });
      this.state.saveRateTypes = RateTypes;
    } catch (error) {
      displayError("getRateType: " + error.message);
    }
  }
  RateTypeRowClick = async (event) => {
    const current = this.state.RateTypes.findIndex(dataItem => dataItem === event.dataItem);
    if (current >= 0) {
      try {
        this.state.RateTypes.forEach(item => item.selected = false);
        this.state.RateTypes[current].selected = true;
        let RateTypeID = this.state.RateTypes[current].RateTypeID;
        this.setState({ RateTypeID });
        let CD = await GetTableRow(this.RateTypeOID,RateTypeID);
        for (var prop in CD.d.row) {
          if (prop in this.state) {
            let obj = {};
            obj[prop] = CD.d.row[prop];
            if (prop.indexOf("Date") >= 0) {
              obj[prop] = new Date(CD.d.row[prop]); //new Date()
            }
            this.setState(obj);
          }
        }
        this.setItemStateChanged(false);
      }
      catch (error) {
        displayError("RateTypeRowClick: " + error);
      }
    }
  }
  RateTypesPageChange = (event) => {
    this.setState({ RateTypesSkip: event.page.skip,RateTypesTake: event.page.take });
  }
  //------------------------- Edit RateType Grid ------------------------------------------------------------------ 
  RateTypeCommandCell;
  enterInsertRateType() {
    const dataItem = { inEdit: true };
    const allRecords = this.state.RateTypes.slice();
    allRecords.unshift(dataItem); // Add to the beginning
    this.updateRateTypes(allRecords,dataItem);
    this.setState({ RateTypes: allRecords });
    this.InsertRecordType = 5;
  }
  enterEditRateType(dataItem) {
    this.updateRateTypes(this.state.RateTypes,dataItem).inEdit = true;
    this.setState({ RateTypes: this.state.RateTypes.slice() });
  }
  saveRateType(dataItem) {
    dataItem.inEdit = undefined;
    if (dataItem.RateTypeID === undefined)
      dataItem.RateTypeID = 0;
    //console.log("Save");
    dataItem.RateTypeID = this.updateRateTypes(this.state.RateTypes,dataItem).RateTypeID;  // Save to OM
    this.setState({ RateTypes: this.state.RateTypes.slice() });
    //console.log("Update Row - locationID: " + dataItem.StoreLocationID);  
    UpdateRow(this.RateTypeOID,0,this.state.RateTypes,dataItem,dataItem.RateTypeID,"RateTypeID");
    this.InsertRecordType = 0;
    SessionInfo.RateTypeUpdate = true;
  }
  cancelRateType(dataItem) {
    if (dataItem.RateTypeID) {
      let originalItem = this.state.RateTypes.find(p => p.RateTypeID === dataItem.RateTypeID)
      if (originalItem.inEdit) originalItem.inEdit = false;
      this.updateRateTypes(this.state.RateTypes,originalItem);
    } else {
      this.updateRateTypes(this.state.RateTypes,dataItem,!dataItem.RateTypeID); // remove false
    }
    this.setState({ RateTypes: this.state.RateTypes.slice() });
  }
  removeRateType(dataItem) {
    dataItem.inEdit = undefined;
    let key = dataItem.RateTypeID;
    this.updateRateTypes(this.state.RateTypes,dataItem,true);
    this.state.saveRateTypes = this.state.RateTypes;
    DeleteRowTry(this.RateTypeOID,key); // Delete in the RateTypes Table in OM
    this.setState({ RateTypes: this.state.RateTypes.slice() });
    this.InsertRecordType = 0;
  }
  itemChangeRateType(event) {
    const value = event.value;
    const name = event.field;
    if (!name) {
      return;
    }
    const updatedData = this.state.RateTypes.slice();
    const item = this.updateRateTypes(updatedData,event.dataItem);
    if (!value.ddID)
      item[name] = value;
    else
      item[name] = value.ddID;
    this.setState({ RateTypes: updatedData });
  }
  updateRateTypes(data,item,remove) {  // data - is the entire data set (JSON), item - is the current line 
    let updated;
    //eslint-disable-next-line                   
    let index = data.findIndex(p => p === item || item.RateTypeID && p.RateTypeID === item.RateTypeID); // Note - for new line will find 0
    if (index >= 0) {
      updated = Object.assign({},item);
      data[index] = updated;
    }
    if (remove) {
      data = data.splice(index,1);
    }
    return data[index];
  }
  //------------------------- End Edit RateType Grid ------------------------------------------------------------------   
  //-------------------------- SeasonCodes ---------------------------------------------------------------------------------- 
  getSeasonCodes = async (search) => {
    if (!search)
      search = '';
    //search = search + "*StoreLocationID=" + this.state.StoreLocationID;
    let CD = await GetTableSearchRaw(this.SeasonCodeOID,search,"SeasonCodeID,SeasonCode,Description,StartDate,EndDate,IsRecordActive");
    try {
      const SeasonCodes = CD.d.rows.map(dataItem => Object.assign({ selected: false },dataItem));
      this.setState({ SeasonCodes });
      this.state.saveSeasonCodes = SeasonCodes;
    } catch (error) {
      displayError("getSeasonCode: " + error.message);
    }
  }
  SeasonCodesSingleRowClick = async (event) => {
    const current = this.state.StoreItems.findIndex(dataItem => dataItem === event.dataItem);
    if (current >= 0) {
      try {
        SessionInfo.SelectStoreLocationID = this.state.StoreLocationID;
        SessionInfo.StoreItemID = this.state.StoreItems[current].StoreItemID;
        let CD = await GetTableRow(this.SeasonCodeOID,SessionInfo.StoreItemID);
        this.setState({ StoreLocationItemID: SessionInfo.StoreItemID });
        for (var prop in CD.d.row) {
          if (prop in this.state) {
            let obj = {};
            obj[prop] = CD.d.row[prop];
            if (prop.indexOf("Date") >= 0) {
              obj[prop] = new Date(CD.d.row[prop]); //new Date()
            }
            this.setState(obj);
          }
        }
        this.setItemStateChanged(false);
        this.setState({ selectedTopTab: 3 });
      }
      catch (error) {
        displayError("SeasonCodeRowClick: " + error);
      }
    }
  }
  SeasonCodesPageChange = (event) => {
    this.setState({ SeasonCodesSkip: event.page.skip,SeasonCodesTake: event.page.take });
  }
  //------------------------- Edit SeasonCode Grid ------------------------------------------------------------------
  SeasonCodeHeaderSelectionChange = (event) => {
    const checked = event.syntheticEvent.target.checked;
    this.state.SeasonCodes.forEach(item => item.selected = checked);
    this.forceUpdate();
  }
  SeasonCodeSelectionChange = (event) => {
    event.dataItem.selected = !event.dataItem.selected;
    this.forceUpdate();
  }
  SeasonCodeCommandCell;
  enterInsertSeasonCode() {
    const dataItem = { inEdit: true };
    const allRecords = this.state.SeasonCodes.slice();
    allRecords.unshift(dataItem); // Add to the beginning
    this.updateSeasonCodes(allRecords,dataItem);
    this.setState({ SeasonCodes: allRecords });
  }
  enterEditSeasonCode(dataItem) {
    //console.log(`Edit SeasonCodes: ${this.state.SeasonCodes}`);
    this.updateSeasonCodes(this.state.SeasonCodes,dataItem).inEdit = true;
    this.setState({ SeasonCodes: this.state.SeasonCodes.slice() });
  }
  saveSeasonCode(dataItem) {
    dataItem.inEdit = undefined;
    if (dataItem.SeasonCodeID === undefined)
      dataItem.SeasonCodeID = 0;
    //console.log("Save");
    dataItem.SeasonCodeID = this.updateSeasonCodes(this.state.SeasonCodes,dataItem).SeasonCodeID;  // Save to OM
    this.setState({ SeasonCodes: this.state.SeasonCodes.slice() });
    //console.log("Update Row - locationID: " + dataItem.StoreLocationID);
    // Save to OM
    UpdateRow(this.SeasonCodeOID,0,this.state.SeasonCodes,dataItem,dataItem.SeasonCodeID,"SeasonCodeID");
  }
  cancelSeasonCode(dataItem) {
    if (dataItem.SeasonCodeID) {
      let originalItem = this.state.SeasonCodes.find(p => p.SeasonCodeID === dataItem.SeasonCodeID)
      if (originalItem.inEdit) originalItem.inEdit = false;
      this.updateSeasonCodes(this.state.SeasonCodes,originalItem);
    } else {
      this.updateSeasonCodes(this.state.SeasonCodes,dataItem,!dataItem.SeasonCodeID);
    }
    this.setState({ SeasonCodes: this.state.SeasonCodes.slice() });
  }
  removeSeasonCode(dataItem) {
    dataItem.inEdit = undefined;
    let key = dataItem.SeasonCodeID;
    this.updateSeasonCodes(this.state.SeasonCodes,dataItem,true);
    this.updateSeasonCodes(this.state.saveSeasonCodes,dataItem,true);
    DeleteRowTry(this.SeasonCodeOID,key); // Delete in the SeasonCodes Table in OM 
    this.setState({ SeasonCodes: this.state.SeasonCodes.slice() });
  }
  itemChangeSeasonCode(event) {
    const value = event.value;
    const name = event.field;
    if (!name) {
      return;
    }
    const updatedData = this.state.SeasonCodes.slice();
    const item = this.updateSeasonCodes(updatedData,event.dataItem);
    if (!value.ddID)
      item[name] = value;
    else
      item[name] = value.ddID;
    this.setState({ SeasonCodes: updatedData });
  }
  updateSeasonCodes(data,item,remove) {  // data - is the entire data set (JSON), item - is the current line 
    let updated;
    //eslint-disable-next-line
    let index = data.findIndex(p => p === item || item.SeasonCodeID && p.SeasonCodeID === item.SeasonCodeID);
    if (index >= 0) {
      updated = Object.assign({},item);
      data[index] = updated;
    }
    if (remove) {
      data = data.splice(index,1);
    }
    return data[index];
  }
  //------------------------- End Edit SeasonCodes Grid ------------------------------------------------------------------

  //-------------------------- AppNotifications ----------------------------------------------------------------------------------
  getAppNotifications = async (search) => {
    if (!search)
      search = '';
    //search = search + "*StoreLocationID=" + this.state.StoreLocationID;
    let CD = await GetTableSearchRaw(this.AppNotificationOID,search,"AppNotificationID,NotificationMessage,NotificationTypeID,StoreLocationID");
    try {
      const AppNotifications = CD.d.rows.map(dataItem => Object.assign({ selected: false },dataItem));
      this.setState({ AppNotifications });
      this.state.saveAppNotifications = AppNotifications;
    } catch (error) {
      displayError("getAppNotification: " + error.message);
    }
  }
  AppNotificationRowClick = async (event) => {
    const current = this.state.AppNotifications.findIndex(dataItem => dataItem === event.dataItem);
    //console.log("Notification Click " + JSON.stringify(current));
    if (current >= 0) {
      try {
        this.state.AppNotifications.forEach(item => item.selected = false);
        this.state.AppNotifications[current].selected = true;
        await this.setState({ AppNotificationID: this.state.AppNotifications[current].AppNotificationID });;
        let CD = await GetTableRow(this.AppNotificationOID,this.state.AppNotificationID); // Returns d.row
        //console.log("CD " + JSON.stringify(CD.d));
        AppNotificationsO.forEach((elem) => {
          //console.log("elem " + elem.n); 
          if (elem.n in CD.d.row) {
            //console.log("Set " + elem.n + ": " + CD.d.row[elem.n]); 
            let sobj = {};
            try {
              if (elem.t === 34 || elem.t === 35)
                sobj[elem.n] = new Date(CD.d.row[elem.n]);
              else
                sobj[elem.n] = CD.d.row[elem.n];
              //console.log("sobj " + JSON.stringify(sobj));
              this.setState(sobj);
            } catch (error) {
              console.log("Set AppNotifications Field: " + elem.n + " Error: " + error.message);
            }
          }
        });                               
      }
      catch (error) {
        displayError("AppNotificationRowClick: " + error);
      }
    }
  }
  AppNotificationsPageChange = (event) => {
    this.setState({ AppNotificationsSkip: event.page.skip,AppNotificationsTake: event.page.take });
  }
  //------------------------- Edit AppNotification Grid ------------------------------------------------------------------  
  AppNotificationCommandCell;
  enterInsertAppNotification() {
    const dataItem = { inEdit: true };
    const allRecords = this.state.AppNotifications.slice();
    allRecords.unshift(dataItem); // Add to the beginning
    this.updateAppNotifications(allRecords,dataItem);
    this.setState({ AppNotifications: allRecords });
    this.InsertRecordType = 6;
  }
  enterEditAppNotification(dataItem) {
    this.updateAppNotifications(this.state.AppNotifications,dataItem).inEdit = true;
    this.setState({ AppNotifications: this.state.AppNotifications.slice() });
  }
  saveAppNotification(dataItem) {
    dataItem.inEdit = undefined;
    if (dataItem.AppNotificationID === undefined)
      dataItem.AppNotificationID = 0;
    //console.log("Save");
    dataItem.AppNotificationID = this.updateAppNotifications(this.state.AppNotifications,dataItem).AppNotificationID;  // Save to OM
    this.setState({ AppNotifications: this.state.AppNotifications.slice() });
    //console.log("Update Row - locationID: " + dataItem.StoreLocationID);   
    UpdateRow(this.AppNotificationOID,0,this.state.AppNotifications,dataItem,dataItem.AppNotificationID,"AppNotificationID");
    this.InsertRecordType = 0;
  }
  cancelAppNotification(dataItem) {
    if (dataItem.AppNotificationID) {
      let originalItem = this.state.AppNotifications.find(p => p.AppNotificationID === dataItem.AppNotificationID)
      if (originalItem.inEdit) originalItem.inEdit = false;
      this.updateAppNotifications(this.state.AppNotifications,originalItem);
    } else {
      this.updateAppNotifications(this.state.AppNotifications,dataItem,!dataItem.AppNotificationID); // remove false
    }
    this.setState({ AppNotifications: this.state.AppNotifications.slice() });
    this.InsertRecordType = 0;
  }
  removeAppNotification(dataItem) {
    dataItem.inEdit = undefined;
    let key = dataItem.AppNotificationID;
    this.updateAppNotifications(this.state.AppNotifications,dataItem,true);
    this.state.saveAppNotifications = this.state.AppNotifications;
    DeleteRowTry(this.AppNotificationOID,key); // Delete in the AppNotifications Table in OM
    this.setState({ AppNotifications: this.state.AppNotifications.slice() });
  }
  itemChangeAppNotification(event) {
    const value = event.value;
    const name = event.field;
    if (!name) {
      return;
    }
    const updatedData = this.state.AppNotifications.slice();
    const item = this.updateAppNotifications(updatedData,event.dataItem);
    if (!value.ddID)
      item[name] = value;
    else
      item[name] = value.ddID;
    this.setState({ AppNotifications: updatedData });
  }
  updateAppNotifications(data,item,remove) {  // data - is the entire data set (JSON), item - is the current line 
    let updated;
    //eslint-disable-next-line                   
    let index = data.findIndex(p => p === item || item.AppNotificationID && p.AppNotificationID === item.AppNotificationID); // Note - for new line will find 0
    if (index >= 0) {
      updated = Object.assign({},item);
      data[index] = updated;
    }
    if (remove) {
      data = data.splice(index,1);
    }
    return data[index];
  }
  //------------------------- End Edit AppNotification Grid ------------------------------------------------------------------     
  //-------------------------- StorePromotions ----------------------------------------------------------------------------------
  getStorePromotions = async (search) => {
    if (!search)
      search = '';
    search = search + "*StoreLocationID=0";
    let CD = await GetTableSearch(this.StorePromotionsOID,search,"StorePromotionID,StorePromotionName,PromotionType,ProductItemCategoryID,StartDateTime,EndDateTime,DiscountPercentage,DiscountAmount");
    try {
      //console.log(`Promotions CD: ${JSON.stringify(CD)}`);
      const StorePromotions = CD.d.rows.map(dataItem => Object.assign({ selected: false },dataItem));
      this.setState({ StorePromotions });
      this.state.saveStorePromotions = StorePromotions;
    } catch (error) {
      displayError("getStorePromotion: " + error.message);
    }
  }
  StorePromotionRowClick = async (event) => { // GetPromotions
    const current = this.state.StorePromotions.findIndex(dataItem => dataItem === event.dataItem);
    if (current >= 0) {
      this.state.StorePromotions.forEach(item => item.selected = false);
      this.state.StorePromotions[current].selected = true;
      let StorePromotionID = this.state.StorePromotions[current].StorePromotionID;
      this.setState({ StorePromotionID });
      let currentImageID = 0;
      let CD = await GetTableRowRaw(544701,StorePromotionID);
      if (CD && CD.d && CD.d.row) {
        this.compUpdate = false;
        //console.log("after Get: " + JSON.stringify(CD.d));
        // Retrieved Value Assignment for: StorePromotions
        let row = CD.d.row;
        StorePromotionsO.forEach(elem => {
          if (elem.n in row) {
            //console.log(`Set ${elem.n}: ${row[elem.n]}`);
            let sobj = {};
            try {
              if (elem.t === 34 || elem.t === 35) { // Date, DateTime
                sobj[elem.n] = new Date(row[elem.n]); // For use with DateTime Control
                //sobj[elem.n] = row[elem.n];
                SPV[elem.n] = new Date(row[elem.n]);
              } else if (elem.t === 21 || elem.t === 195 || elem.t === 201) { // String, FK-Translated, Enum
                sobj[elem.n] = row[elem.n];
                let sobjS = {};
                sobjS[elem.n + 'S'] = row[elem.n];
                //console.log(`Set String Field: ${elem.n + 'S'}, Value: ${ sobjS[elem.n + 'S']}`);
                this.setState(sobjS); // Set the String shadow var
                SPV[elem.n] = row[elem.n];
              } else if (elem.t === 22) { // Bool
                let val = row[elem.n].toString().charAt();
                if (val === '1' || val === 't' || val === 'T') {
                  sobj[elem.n] = true;
                  SPV[elem.n] = true;
                } else {
                  sobj[elem.n] = false;
                  SPV[elem.n] = false;
                }
              } else if (elem.t === 23) { // Decimal
                if (elem.n.indexOf('Percent') >= 0 || elem.n.indexOf('Markup') >= 0) {
                  sobj[elem.n] = row[elem.n];
                  let sobjS = {};
                  sobjS[elem.n + 'S'] = this.moneyFormat(row[elem.n]);
                  this.setState(sobjS); // Set the String shadow var
                  SPV[elem.n] = row[elem.n];
                } else { // Decimal
                  sobj[elem.n] = row[elem.n];
                  SPV[elem.n] = row[elem.n];
                }
              } else if (elem.t === 28) { // Money
                sobj[elem.n] = row[elem.n];
                let sobjS = {};
                sobjS[elem.n + 'S'] = this.moneyFormat(row[elem.n]);
                //console.log(`Set Money Field: ${elem.n + 'S'}, Value: ${ sobjS[elem.n + 'S']}`);
                this.setState(sobjS); // Set the String shadow var
                SPV[elem.n] = row[elem.n];
                console.log(`sobj ${JSON.stringify(sobj)}, ${JSON.stringify(sobjS)}, t: ${elem.t}, val: ${SPV[elem.n]}`);
              } else if (elem.t === 41) { // Media - Image or Document
                sobj[elem.n] = row[elem.n];
                SPV[elem.n] = row[elem.n];
                if (elem.n === 'ImageID')
                  currentImageID = row[elem.n];
              } else if (elem.t === 15) { // Encoded
                //------ Assign Encoded Values ------

                let AList = [];

                let sobjV = {};
                sobjV[`${elem.n}Vals`] = AList; // Set the Vals Array
                this.setState(sobjV); // Set the Vals var
                sobj[elem.n] = row[elem.n];
                //obj[elem.n] = row[elem.n];
                SPV[elem.n] = row[elem.n];
                console.log(`Encoded sobj ${JSON.stringify(sobj)}, t: ${elem.t}, val: ${SPV[elem.n]}`);
              } else { // Default - Number
                sobj[elem.n] = row[elem.n];
                SPV[elem.n] = row[elem.n];
              }
              //console.log(`sobj ${JSON.stringify(sobj)}, t: ${elem.t}, val: ${SPV[elem.n]}`);
              if (this.state[elem.n] === undefined)
                console.log(`=== ${elem.n} is not defined in state ===`);
              this.setState(sobj);
              // Add item specific code here
              //if (elem.n === 'ImageID')
              //  ImageID = row[elem.n];
            }
            catch (error) {
              console.log(`Set StorePromotionsO Field: ${elem.n}, Error: ${error.message}`);
            }
          }
        });
        await this.GetPromotionImage(currentImageID);
        this.setItemStateChanged(false);
        this.compUpdate = true;
        this.forceUpdate();
      }
      else {
        LogOffResetMsg(`Web Session Has Failed in ${SessionInfo.currentPage} - Session: ${CD.x.s}`);
        this.props.navigate("/Login");
      }
    }
  }
  //-----------------------
  GetPromotionImage = async (imageID) => {
    //console.log("GetPromotionImage ID: " + imageID);
    const imageURL = await getImageFileFromServer(imageID,4);
    //console.log("Set PromotionPhotoURL");
    this.setState({ PromotionPhotoURL: imageURL });
    //console.log("After Set PromotionPhotoURL");
  }
  StorePromotionsPageChange = (event) => {
    this.setState({ StorePromotionsSkip: event.page.skip,StorePromotionsTake: event.page.take });
  }
  _exportPromotions;
  exportPromotionsExcel = () => {
    this._exportPromotions.save();
  }
  //------------------------- Edit StorePromotion Grid ------------------------------------------------------------------ 
  StorePromotionCommandCell;
  enterInsertStorePromotion() {
    console.log("Insert Store Promotion Line");
    const dataItem = { inEdit: true };
    const allRecords = this.state.StorePromotions.slice();
    allRecords.unshift(dataItem); // Add to the beginning
    this.updateStorePromotions(allRecords,dataItem);
    this.setState({ StorePromotions: allRecords });
    this.InsertRecordType = 7;
    console.log(`StorePromotions Count: ${this.state.StorePromotions.length}`);
  }
  enterEditStorePromotion(dataItem) {
    this.updateStorePromotions(this.state.StorePromotions,dataItem).inEdit = true;
    this.setState({ StorePromotions: this.state.StorePromotions.slice() });
  }
  saveStorePromotion(dataItem) {
    dataItem.inEdit = undefined;
    if (dataItem.StorePromotionID === undefined)
      dataItem.StorePromotionID = 0;
    //console.log("Save");
    dataItem.StorePromotionID = this.updateStorePromotions(this.state.StorePromotions,dataItem).StorePromotionID;  // Save to OM
    this.setState({ StorePromotions: this.state.StorePromotions.slice() });
    //console.log("Update Row - locationID: " + dataItem.StoreLocationID); 
    UpdateRow(this.StorePromotionsOID,0,this.state.StorePromotions,dataItem,dataItem.StorePromotionID,"StorePromotionID");
    this.InsertRecordType = 0;
    SessionInfo.StorePromotionUpdate = true;
  }
  cancelStorePromotion(dataItem) {
    if (dataItem.StorePromotionID) {
      let originalItem = this.state.StorePromotions.find(p => p.StorePromotionID === dataItem.StorePromotionID)
      if (originalItem.inEdit) originalItem.inEdit = false;
      this.updateStorePromotions(this.state.StorePromotions,originalItem);
    } else {
      this.updateStorePromotions(this.state.StorePromotions,dataItem,!dataItem.StorePromotionID); // remove false
    }
    this.setState({ StorePromotions: this.state.StorePromotions.slice() });
  }
  removeStorePromotion(dataItem) {
    dataItem.inEdit = undefined;
    let key = dataItem.StorePromotionID;
    this.updateStorePromotions(this.state.StorePromotions,dataItem,true);
    this.state.saveStorePromotions = this.state.StorePromotions;
    DeleteRowTry(this.StorePromotionsOID,key); // Delete in the StorePromotions Table in OM
    this.setState({ StorePromotions: this.state.StorePromotions.slice() });
    this.InsertRecordType = 0;
  }
  itemChangeStorePromotion(event) {
    const value = event.value;
    const name = event.field;
    if (!name) {
      return;
    }
    const updatedData = this.state.StorePromotions.slice();
    const item = this.updateStorePromotions(updatedData,event.dataItem);
    if (!value.ddID)
      item[name] = value;
    else
      item[name] = value.ddID;
    this.setState({ StorePromotions: updatedData });
  }
  updateStorePromotions(data,item,remove) {  // data - is the entire data set (JSON), item - is the current line 
    let updated;
    //eslint-disable-next-line                   
    let index = data.findIndex(p => p === item || item.StorePromotionID && p.StorePromotionID === item.StorePromotionID); // Note - for new line will find 0
    if (index >= 0) {
      updated = Object.assign({},item);
      data[index] = updated;
    }
    if (remove) {
      data = data.splice(index,1);
    }
    return data[index];
  }
  //------------------------- End Edit StorePromotion Grid ------------------------------------------------------------------

  //-------------------------- ProductOptionsGroups ---------------------------------------------------------------------------------- 
  getProductOptionsGroups = async (search) => {
    //search = "*StoreItemID=" + this.state.StoreItemID;
    console.log(`ProductOptionsGroups search: ${search}`)
    let CD = await GetTableSearchRaw(this.ProductOptionsGroupsOID,search,"ProductOptionsGroupID,ProductOptionsGroupName,IsRequired,AllowMultiple,MultipleCount,SortOrder,IsRecordActive");  // NOTE - could use *DSPO
    try {
      const ProductOptionsGroups = CD.d.rows.map(dataItem => Object.assign({ selected: false },dataItem));
      //console.log("ProductOptionsGroups " + JSON.stringify(ProductOptionsGroups));
      this.setState({ ProductOptionsGroups });
      this.state.saveProductOptionsGroups = ProductOptionsGroups;
    } catch (error) {
      displayError("getProductOptionsGroup: " + error.message);
    }
  }
  ProductOptionsGroupRowClick = async (event) => {
    const current = this.state.ProductOptionsGroups.findIndex(dataItem => dataItem === event.dataItem);
    if (current >= 0) {
      try {
        let ProductOptionsGroupID = this.state.ProductOptionsGroups[current].ProductOptionsGroupID;
        this.setState({ ProductOptionsGroupName: this.state.ProductOptionsGroups[current].ProductOptionsGroupName });
        let CD = await GetTableRow(this.ProductOptionsGroupsOID,ProductOptionsGroupID);
        this.setState({ ProductOptionsGroupID: ProductOptionsGroupID });
        for (var prop in CD.d.row) {
          //console.log(`Set Prop: ${prop}`);
          if (prop in this.state) {
            let obj = {};
            obj[prop] = CD.d.row[prop];
            if (prop.indexOf("Date") >= 0) {
              obj[prop] = new Date(CD.d.row[prop]); //new Date()
            }
            this.setState(obj);
            //console.log(`Set State: ${JSON.stringify(obj)}`);
          }
        }
        await this.getProductItemOptions("",ProductOptionsGroupID);
        SessionInfo.currAddGridRowFunc = this.enterInsertProductItemOption;
        //this.getProductItemOptions();
      }
      catch (error) {
        displayError("ProductOptionsGroupRowClick: " + error);
      }
    }
  }
  GetProductOptionImage = async (imageID) => {
    const imageURL = await getImageFileFromServer(imageID);
    this.setState({ ProductOptionPhotoURL: imageURL });
  }
  ProductOptionsGroupsPageChange = (event) => {
    this.setState({ ProductOptionsGroupsSkip: event.page.skip,ProductOptionsGroupsTake: event.page.take });
  }
  ProductSIOptionsGroupsPageChange = (event) => {
    this.setState({ ProductSIOptionsGroupsSkip: event.page.skip,ProductSIOptionsGroupsTake: event.page.take });
  }
  ProductOptionsGroupsAVPageChange = (event) => {
    this.setState({ ProductOptionsGroupsAVSkip: event.page.skip,ProductOptionsGroupsAVTake: event.page.take });
  }
  //------------------------------------------------------------------------------------------------------------------ 
  //------------------------- Edit ProductOptionsGroup Grid ------------------------------------------------------------------ 
  ProductOptionsGroupCommandCell;
  enterInsertProductOptionsGroup() {
    const dataItem = { StoreItemID: this.state.StoreItemID,MultipleCount: 0,inEdit: true };
    //console.log(`insert Group DataItem: ${JSON.stringify(dataItem)}`);
    this.setState({ ProductOptionsGroupName: "" });
    const allRecords = this.state.ProductOptionsGroups.slice();
    allRecords.unshift(dataItem); // Add to the beginning
    this.updateProductOptionsGroups(allRecords,dataItem);
    this.setState({ ProductOptionsGroups: allRecords });
  }
  enterEditProductOptionsGroup(dataItem) {
    console.log(`enter Row - Group DataItem: ${JSON.stringify(dataItem)}`);
    this.updateProductOptionsGroups(this.state.ProductOptionsGroups,dataItem).inEdit = true;
    this.setState({ ProductOptionsGroups: this.state.ProductOptionsGroups.slice() });
  }
  saveProductOptionsGroup(dataItem) {
    dataItem.inEdit = undefined;
    if (dataItem.ProductOptionsGroupID === undefined)
      dataItem.ProductOptionsGroupID = 0;
    //console.log("Save");
    dataItem.ProductOptionsGroupID = this.updateProductOptionsGroups(this.state.ProductOptionsGroups,dataItem).ProductOptionsGroupID;  // Save to OM
    this.setState({ ProductOptionsGroups: this.state.ProductOptionsGroups.slice() });
    //console.log(`Update Row - Group DataItem: ${JSON.stringify(dataItem)}`);
    UpdateRow(this.ProductOptionsGroupsOID,0,this.state.ProductOptionsGroups,dataItem,dataItem.ProductOptionsGroupID,"ProductOptionsGroupID");
  }
  cancelProductOptionsGroup(dataItem) {
    if (dataItem.ProductOptionsGroupID) {
      let originalItem = this.state.ProductOptionsGroups.find(p => p.ProductOptionsGroupID === dataItem.ProductOptionsGroupID)
      if (originalItem.inEdit) originalItem.inEdit = false;
      this.updateProductOptionsGroups(this.state.ProductOptionsGroups,originalItem);
    } else {
      this.updateProductOptionsGroups(this.state.ProductOptionsGroups,dataItem,!dataItem.ProductOptionsGroupID); // remove false
    }
    this.setState({ ProductOptionsGroups: this.state.ProductOptionsGroups.slice() });
  }
  removeProductOptionsGroup(dataItem) {
    dataItem.inEdit = undefined;
    let key = dataItem.ProductOptionsGroupID;
    this.updateProductOptionsGroups(this.state.ProductOptionsGroups,dataItem,true);
    this.state.saveProductOptionsGroups = this.state.ProductOptionsGroups;
    DeleteRowForce(this.ProductOptionsGroupsOID,key); // Delete in the ProductOptionsGroups Table in OM
    this.setState({ ProductOptionsGroups: this.state.ProductOptionsGroups.slice() });
  }
  itemChangeProductOptionsGroup(event) {
    const value = event.value;
    const name = event.field;
    if (!name) {
      return;
    }
    const updatedData = this.state.ProductOptionsGroups.slice();
    const item = this.updateProductOptionsGroups(updatedData,event.dataItem);
    if (!value.ddID)
      item[name] = value;
    else
      item[name] = value.ddID;
    this.setState({ ProductOptionsGroups: updatedData });
  }
  updateProductOptionsGroups(data,item,remove) {  // data - is the entire data set (JSON), item - is the current line 
    let updated;
    //eslint-disable-next-line                   
    let index = data.findIndex(p => p === item || item.ProductOptionsGroupID && p.ProductOptionsGroupID === item.ProductOptionsGroupID); // Note - for new line will find 0
    if (index >= 0) {
      updated = Object.assign({},item);
      data[index] = updated;
    }
    if (remove) {
      data = data.splice(index,1);
    }
    return data[index];
  }
  //------------------------- Edit ProductItemOption - ProductOptionsGroup Grid ------------------------------------------------------------------ 
  ProductItemOptionCommandCell;
  enterInsertProductItemOption() {
    if (this.state.ProductOptionsGroupName === '')
      displayWarning("Option Group must be selected first");
    else {
      if (this.GroupOptionAdd == 0) {
        this.addNewProductItemOption();
        this.GroupOptionAdd = 1;
        this.setState({ ActiveGroupColor: "Green" });
      } else if (this.GroupOptionAdd == 1) {
        this.GroupOptionAdd = 2;
        this.setState({ ActiveGroupColor: "Red" });
      } else {
        this.GroupOptionAdd = 0;
        this.setState({ ActiveGroupColor: SessionInfo.activeColor });
      }
    }
  }
  addNewProductItemOption() {
    const dataItem = { ProductOptionsGroupID: this.state.ProductOptionsGroupID,Price: 0,inEdit: true };
    console.log(`Insert Option DataItem: ${JSON.stringify(dataItem)}`);
    const allRecords = this.state.ProductItemOptions.slice();
    allRecords.unshift(dataItem); // Add to the beginning
    this.updateProductItemOptions(allRecords,dataItem);
    this.setState({ ProductItemOptions: allRecords });
  }
  enterEditProductItemOption(dataItem) {
    this.updateProductItemOptions(this.state.ProductItemOptions,dataItem).inEdit = true;
    this.setState({ ProductItemOptions: this.state.ProductItemOptions.slice() });
  }
  saveProductItemOption(dataItem) {
    dataItem.inEdit = undefined;
    if (dataItem.ProductItemOptionID === undefined)
      dataItem.ProductItemOptionID = 0;
    //console.log("Save");
    dataItem.ProductItemOptionID = this.updateProductItemOptions(this.state.ProductItemOptions,dataItem).ProductItemOptionID;  // Save to OM
    this.setState({ ProductItemOptions: this.state.ProductItemOptions.slice() });
    //console.log("Update Row - locationID: " + dataItem.StoreLocationID);  
    UpdateRow(this.ProductItemOptionsOID,0,this.state.ProductItemOptions,dataItem,dataItem.ProductItemOptionID,"ProductItemOptionID");
    if (this.GroupOptionAdd == 2) {
      this.addNewProductItemOption();
    } else {
      this.GroupOptionAdd = 0;
      this.setState({ ActiveGroupColor: SessionInfo.activeColor });
    }
  }
  cancelProductItemOption(dataItem) {
    if (dataItem.ProductItemOptionID) {
      let originalItem = this.state.ProductItemOptions.find(p => p.ProductItemOptionID === dataItem.ProductItemOptionID)
      if (originalItem.inEdit) originalItem.inEdit = false;
      this.updateProductItemOptions(this.state.ProductItemOptions,originalItem);
    } else {
      this.updateProductItemOptions(this.state.ProductItemOptions,dataItem,!dataItem.ProductItemOptionID); // remove false
    }
    this.setState({ ProductItemOptions: this.state.ProductItemOptions.slice() });
  }
  removeProductItemOption(dataItem) {
    dataItem.inEdit = undefined;
    let key = dataItem.ProductItemOptionID;
    this.updateProductItemOptions(this.state.ProductItemOptions,dataItem,true);
    this.state.saveProductItemOptions = this.state.ProductItemOptions;
    DeleteRowTry(this.ProductItemOptionsOID,key); // Delete in the ProductItemOptions Table in OM
    this.setState({ ProductItemOptions: this.state.ProductItemOptions.slice() });
  }
  itemChangeProductItemOption(event) {
    const value = event.value;
    const name = event.field;
    if (!name) {
      return;
    }
    const updatedData = this.state.ProductItemOptions.slice();
    const item = this.updateProductItemOptions(updatedData,event.dataItem);
    if (!value.ddID)
      item[name] = value;
    else
      item[name] = value.ddID;
    this.setState({ ProductItemOptions: updatedData });
  }
  updateProductItemOptions(data,item,remove) {  // data - is the entire data set (JSON), item - is the current line 
    let updated;
    //eslint-disable-next-line                   
    let index = data.findIndex(p => p === item || item.ProductItemOptionID && p.ProductItemOptionID === item.ProductItemOptionID); // Note - for new line will find 0
    if (index >= 0) {
      updated = Object.assign({},item);
      data[index] = updated;
    }
    if (remove) {
      data = data.splice(index,1);
    }
    return data[index];
  }
  //------------------------- End Edit ProductItemOption Grid ------------------------------------------------------------------ 
  //-------------------------- ProductItemOptions ----------------------------------------------------------------------------------
  getProductItemOptions = async (search,productOptionsGroupID) => {
    if (!search || search === "") {
      if (productOptionsGroupID)
        search = "*ProductOptionsGroupID=" + productOptionsGroupID;
      else
        search = "*ProductOptionsGroupID=" + this.state.ProductOptionsGroupID;
    }
    console.log(`ProductItemOptionsTable search: ${search}`)
    let CD = await GetTableSearchRaw(this.ProductItemOptionsOID,search,"ProductItemOptionID,ProductOptionName,Price,SortOrder,IsRecordActive");
    try {
      const ProductItemOptions = CD.d.rows.map(dataItem => Object.assign({ selected: false },dataItem));
      this.setState({ ProductItemOptions });
      this.state.saveProductItemOptions = ProductItemOptions;
    } catch (error) {
      displayError("getProductItemOption: " + error.message);
    }
  }
  ProductItemOptionRowClick = async (event) => {
    const current = this.state.ProductItemOptions.findIndex(dataItem => dataItem === event.dataItem);
    if (current >= 0) {
      //  try {
      //    let ProductItemOptionID = this.state.ProductItemOptions[current].ProductItemOptionID;
      //    let CD = await GetTableRow(this.ProductItemOptionsOID, ProductItemOptionID);
      //    this.setState({ ProductItemOptionID: ProductItemOptionID });
      //    for (var prop in CD.d.row) {
      //      if (prop in this.state) {
      //        let obj = {};
      //        obj[prop] = CD.d.row[prop];
      //        if (prop.indexOf("Date") >= 0) {
      //          obj[prop] = new Date(CD.d.row[prop]); //new Date()
      //        }
      //        this.setState(obj);
      //      }
      //    }
      //    //this.setState({ selectedTopTab: 3 }); // Store Item 
      //  }
      //  catch (error) {
      //    displayError("ProductItemOptionRowClick: " + error);
      //  }
    }
  }
  ProductItemOptionsPageChange = (event) => {
    this.setState({ ProductItemOptionsSkip: event.page.skip,ProductItemOptionsTake: event.page.take });
  }

  handleSubmit = (event) => {
    event.preventDefault();
  }
  //------------------------- Field Edit ------------------------------------------------------------------

  //-------------------------- TimeOfDays ---------------------------------------------------------------------------------- 
  getTimeOfDays = async (search) => {
    if (!search)
      search = '';
    //search = search + "*StoreLocationID=" + this.state.StoreLocationID;
    //let CD = await GetTableSearchRaw(this.TimeOfDayOID, search, "TimeOfDayID,TimeOfDay,Description,StartTime,EndTime,IsRecordActive");
    let CD = await CallOM("GetTimeOfDays",0,0,2,search);
    try {
      const TimeOfDays = CD.d.rows.map(dataItem => Object.assign({ selected: false },dataItem));
      this.setState({ TimeOfDays });
      this.state.saveTimeOfDays = TimeOfDays;
    } catch (error) {
      displayError("getTimeOfDay: " + error.message);
    }
  }
  TimeOfDaysSingleRowClick = async (event) => {
    const current = this.state.StoreItems.findIndex(dataItem => dataItem === event.dataItem);
    if (current >= 0) {
      try {
        SessionInfo.SelectStoreLocationID = this.state.StoreLocationID;
        SessionInfo.StoreItemID = this.state.StoreItems[current].StoreItemID;
        let CD = await GetTableRow(this.TimeOfDayOID,SessionInfo.StoreItemID);
        this.setState({ StoreLocationItemID: SessionInfo.StoreItemID });
        for (var prop in CD.d.row) {
          if (prop in this.state) {
            let obj = {};
            obj[prop] = CD.d.row[prop];
            if (prop.indexOf("Date") >= 0) {
              obj[prop] = new Date(CD.d.row[prop]); //new Date()
            }
            this.setState(obj);
          }
        }
        this.setItemStateChanged(false);
        this.setState({ selectedTopTab: 3 });
      }
      catch (error) {
        displayError("TimeOfDayRowClick: " + error);
      }
    }
  }
  TimeOfDaysPageChange = (event) => {
    this.setState({ TimeOfDaysSkip: event.page.skip,TimeOfDaysTake: event.page.take });
  }
  //------------------------- Edit TimeOfDay Grid ------------------------------------------------------------------
  TimeOfDayHeaderSelectionChange = (event) => {
    const checked = event.syntheticEvent.target.checked;
    this.state.TimeOfDays.forEach(item => item.selected = checked);
    this.forceUpdate();
  }
  TimeOfDaySelectionChange = (event) => {
    event.dataItem.selected = !event.dataItem.selected;
    this.forceUpdate();
  }
  TimeOfDayCommandCell;
  enterInsertTimeOfDay() {
    const dataItem = { inEdit: true };
    const allRecords = this.state.TimeOfDays.slice();
    allRecords.unshift(dataItem); // Add to the beginning
    this.updateTimeOfDays(allRecords,dataItem);
    this.setState({ TimeOfDays: allRecords });
  }
  enterEditTimeOfDay(dataItem) {
    this.updateTimeOfDays(this.state.TimeOfDays,dataItem).inEdit = true;
    this.setState({ TimeOfDays: this.state.TimeOfDays.slice() });
  }
  async saveTimeOfDay(dataItem) {
    console.log(`Update Row - ID: ${dataItem.TimeOfDayID}`);
    dataItem.inEdit = undefined;
    if (dataItem.TimeOfDayID === undefined)
      dataItem.TimeOfDayID = 0;
    //console.log("Save");
    dataItem.TimeOfDayID = this.updateTimeOfDays(this.state.TimeOfDays,dataItem).TimeOfDayID;  // Save to OM
    this.setState({ TimeOfDays: this.state.TimeOfDays.slice() });
    console.log(`Update Row - TimeOfDay: ${JSON.stringify(dataItem)}`);
    if (dataItem.StartTime instanceof Date) {
      let newStartTime = dataItem.StartTime.getTime() - SessionInfo.timeOffset * 60000;
      dataItem.StartTime = new Date(newStartTime);
    }
    if (dataItem.EndTime instanceof Date) {
      let newEndTime = dataItem.EndTime.getTime() - SessionInfo.timeOffset * 60000;
      dataItem.EndTime = new Date(newEndTime);
    }
    console.log(`UPDATED Row - TimeOfDay: ${JSON.stringify(dataItem)}`);
    //UpdateRow(this.TimeOfDayOID, 0, this.state.TimeOfDays, dataItem, dataItem.TimeOfDayID, "TimeOfDayID");
    let CD = await CallOM("UpdateTimeOfDays",0,dataItem.TimeOfDayID,CTYP.DICTNO,dataItem,0); // 17
  }
  cancelTimeOfDay(dataItem) {
    if (dataItem.TimeOfDayID) {
      let originalItem = this.state.TimeOfDays.find(p => p.TimeOfDayID === dataItem.TimeOfDayID)
      if (originalItem.inEdit) originalItem.inEdit = false;
      this.updateTimeOfDays(this.state.TimeOfDays,originalItem);
    } else {
      this.updateTimeOfDays(this.state.TimeOfDays,dataItem,!dataItem.TimeOfDayID);
    }
    this.setState({ TimeOfDays: this.state.TimeOfDays.slice() });
  }
  removeTimeOfDay(dataItem) {
    dataItem.inEdit = undefined;
    let key = dataItem.TimeOfDayID;
    this.updateTimeOfDays(this.state.TimeOfDays,dataItem,true);
    this.updateTimeOfDays(this.state.saveTimeOfDays,dataItem,true);
    DeleteRowTry(this.TimeOfDayOID,key); // Delete in the TimeOfDays Table in OM 
    this.setState({ TimeOfDays: this.state.TimeOfDays.slice() });
  }
  itemChangeTimeOfDay(event) {
    const value = event.value;
    const name = event.field;
    if (!name) {
      return;
    }
    const updatedData = this.state.TimeOfDays.slice();
    const item = this.updateTimeOfDays(updatedData,event.dataItem);
    if (!value.ddID)
      item[name] = value;
    else
      item[name] = value.ddID;
    this.setState({ TimeOfDays: updatedData });
  }
  updateTimeOfDays(data,item,remove) {  // data - is the entire data set (JSON), item - is the current line 
    let updated;
    //eslint-disable-next-line
    let index = data.findIndex(p => p === item || item.TimeOfDayID && p.TimeOfDayID === item.TimeOfDayID);
    if (index >= 0) {
      updated = Object.assign({},item);
      data[index] = updated;
    }
    if (remove) {
      data = data.splice(index,1);
    }
    return data[index];
  }
  //------------------------- End Edit TimeOfDays Grid ------------------------------------------------------------------

  //-------------------------- TaxableTypes ---------------------------------------------------------------------------------- 
  getTaxableTypes = async (search) => {
    if (!search)
      search = '';
    //search = search + "*StoreLocationID=" + this.state.StoreLocationID;
    let CD = await GetTableSearchRaw(this.TaxableTypeOID,search,"TaxableTypeID,TaxableType,Description,IncludeTax1,IncludeTax2,IncludeTax3");
    try {
      const TaxableTypes = CD.d.rows.map(dataItem => Object.assign({ selected: false },dataItem));
      console.log(`TaxableTypes: ${JSON.stringify(TaxableTypes)}`);
      this.setState({ TaxableTypes });
      this.state.saveTaxableTypes = TaxableTypes;
    } catch (error) {
      displayError("getTaxableType: " + error.message);
    }
  }
  TaxableTypesSingleRowClick = async (event) => {
    const current = this.state.StoreItems.findIndex(dataItem => dataItem === event.dataItem);
    if (current >= 0) {
      try {
        SessionInfo.SelectStoreLocationID = this.state.StoreLocationID;
        SessionInfo.StoreItemID = this.state.StoreItems[current].StoreItemID;
        let CD = await GetTableRow(this.TaxableTypeOID,SessionInfo.StoreItemID);
        this.setState({ StoreLocationItemID: SessionInfo.StoreItemID });
        for (var prop in CD.d.row) {
          if (prop in this.state) {
            let obj = {};
            obj[prop] = CD.d.row[prop];
            if (prop.indexOf("Date") >= 0) {
              obj[prop] = new Date(CD.d.row[prop]); //new Date()
            }
            this.setState(obj);
          }
        }
        this.setItemStateChanged(false);
        this.setState({ selectedTopTab: 3 });
      }
      catch (error) {
        displayError("TaxableTypeRowClick: " + error);
      }
    }
  }
  TaxableTypesPageChange = (event) => {
    this.setState({ TaxableTypesSkip: event.page.skip,TaxableTypesTake: event.page.take });
  }
  //------------------------- Edit TaxableType Grid ------------------------------------------------------------------
  TaxableTypeHeaderSelectionChange = (event) => {
    const checked = event.syntheticEvent.target.checked;
    this.state.TaxableTypes.forEach(item => item.selected = checked);
    this.forceUpdate();
  }
  TaxableTypeSelectionChange = (event) => {
    event.dataItem.selected = !event.dataItem.selected;
    this.forceUpdate();
  }
  TaxableTypeCommandCell;
  enterInsertTaxableType() {
    const dataItem = { inEdit: true };
    const allRecords = this.state.TaxableTypes.slice();
    allRecords.unshift(dataItem); // Add to the beginning
    this.updateTaxableTypes(allRecords,dataItem);
    this.setState({ TaxableTypes: allRecords });
  }
  enterEditTaxableType(dataItem) {
    console.log(`Edit TaxableTypes: ${JSON.stringify(this.state.TaxableTypes)}`);
    this.updateTaxableTypes(this.state.TaxableTypes,dataItem).inEdit = true;
    this.setState({ TaxableTypes: this.state.TaxableTypes.slice() });
  }
  saveTaxableType(dataItem) {
    dataItem.inEdit = undefined;
    if (dataItem.TaxableTypeID === undefined)
      dataItem.TaxableTypeID = 0;
    console.log("Save TaxableType");
    dataItem.TaxableTypeID = this.updateTaxableTypes(this.state.TaxableTypes,dataItem).TaxableTypeID;  // Save to OM
    this.setState({ TaxableTypes: this.state.TaxableTypes.slice() });
    //console.log("Update Row - locationID: " + dataItem.StoreLocationID);
    UpdateRow(this.TaxableTypeOID,0,this.state.TaxableTypes,dataItem,dataItem.TaxableTypeID,"TaxableTypeID");
  }
  cancelTaxableType(dataItem) {
    if (dataItem.TaxableTypeID) {
      let originalItem = this.state.TaxableTypes.find(p => p.TaxableTypeID === dataItem.TaxableTypeID)
      if (originalItem.inEdit) originalItem.inEdit = false;
      this.updateTaxableTypes(this.state.TaxableTypes,originalItem);
    } else {
      this.updateTaxableTypes(this.state.TaxableTypes,dataItem,!dataItem.TaxableTypeID);
    }
    this.setState({ TaxableTypes: this.state.TaxableTypes.slice() });
  }
  removeTaxableType(dataItem) {
    dataItem.inEdit = undefined;
    let key = dataItem.TaxableTypeID;
    this.updateTaxableTypes(this.state.TaxableTypes,dataItem,true);
    this.updateTaxableTypes(this.state.saveTaxableTypes,dataItem,true);
    DeleteRowTry(this.TaxableTypeOID,key); // Delete in the TaxableTypes Table in OM 
    this.setState({ TaxableTypes: this.state.TaxableTypes.slice() });
  }
  itemChangeTaxableType(event) {
    const value = event.value;
    const name = event.field;
    if (!name) {
      return;
    }
    const updatedData = this.state.TaxableTypes.slice();
    const item = this.updateTaxableTypes(updatedData,event.dataItem);
    if (!value.ddID)
      item[name] = value;
    else
      item[name] = value.ddID;
    this.setState({ TaxableTypes: updatedData });
  }
  updateTaxableTypes(data,item,remove) {  // data - is the entire data set (JSON), item - is the current line 
    let updated;
    //eslint-disable-next-line
    let index = data.findIndex(p => p === item || item.TaxableTypeID && p.TaxableTypeID === item.TaxableTypeID);
    if (index >= 0) {
      updated = Object.assign({},item);
      data[index] = updated;
    }
    if (remove) {
      data = data.splice(index,1);
    }
    return data[index];
  }
  //------------------------- End Edit TaxableTypes Grid ------------------------------------------------------------------

  //-------------------------- ServiceTypes ---------------------------------------------------------------------------------- 
  getServiceTypes = async (search) => {
    if (!search)
      search = '';
    let CD = await GetTableSearchRaw(ServiceTypesOID,search,"ServiceTypeID,ServiceType,Description,IsActive"); // Note - ColumnList have EditOrder attr or use *DISP/*EDIT for ColumnList
    try {
      const ServiceTypes = CD.d.rows.map(dataItem => Object.assign({ selected: false },dataItem));
      this.setState({ ServiceTypes });
      this.state.saveServiceTypes = ServiceTypes;
    } catch (error) {
      displayError("getServiceType: " + error.message);
    }
  }
  ServiceTypesSingleRowClick = async (event) => {
    const current = this.state.StoreItems.findIndex(dataItem => dataItem === event.dataItem);
    if (current >= 0) {
      try {
        SessionInfo.SelectStoreLocationID = this.state.StoreLocationID;
        SessionInfo.StoreItemID = this.state.StoreItems[current].StoreItemID;
        let CD = await GetTableRow(ServiceTypesOID,SessionInfo.StoreItemID);
        this.setState({ StoreLocationItemID: SessionInfo.StoreItemID });
        for (var prop in CD.d.row) {
          if (prop in this.state) {
            let obj = {};
            obj[prop] = CD.d.row[prop];
            if (prop.indexOf("Date") >= 0) {
              obj[prop] = new Date(CD.d.row[prop]); //new Date()
            }
            this.setState(obj);
          }
        }
        this.setItemStateChanged(false);
        this.setState({ selectedTopTab: 3 });
      }
      catch (error) {
        displayError("ServiceTypeRowClick: " + error);
      }
    }
  }
  ServiceTypesPageChange = (event) => {
    this.setState({ ServiceTypesSkip: event.page.skip,ServiceTypesTake: event.page.take });
  }
  //------------------------- Edit ServiceType Grid ------------------------------------------------------------------
  ServiceTypeHeaderSelectionChange = (event) => {
    const checked = event.syntheticEvent.target.checked;
    this.state.ServiceTypes.forEach(item => item.selected = checked);
    this.forceUpdate();
  }
  ServiceTypeSelectionChange = (event) => {
    event.dataItem.selected = !event.dataItem.selected;
    this.forceUpdate();
  }
  ServiceTypeCommandCell;
  enterInsertServiceType = () => {
    const dataItem = { inEdit: true };
    const allRecords = this.state.ServiceTypes.slice();
    allRecords.unshift(dataItem); // Add to the beginning
    this.updateServiceType(allRecords,dataItem);
    this.setState({ ServiceTypes: allRecords });
  }
  enterEditServiceType = (dataItem) => {
    this.updateServiceType(this.state.ServiceTypes,dataItem).inEdit = true;
    this.setState({ ServiceTypes: this.state.ServiceTypes.slice() });
  }
  saveServiceType = (dataItem) => {
    dataItem.inEdit = undefined;
    if (dataItem.ServiceTypeID === undefined)
      dataItem.ServiceTypeID = 0;
    //console.log("Save ServiceType");
    dataItem.ServiceTypeID = this.updateServiceType(this.state.ServiceTypes,dataItem).ServiceTypeID;  // Save to OM
    this.setState({ ServiceTypes: this.state.ServiceTypes.slice() });
    //console.log("Update Row - ServiceTypeID: " + dataItem.ServiceTypeID);
    UpdateRow(ServiceTypesOID,0,this.state.ServiceTypes,dataItem,dataItem.ServiceTypeID,"ServiceTypeID");
  }
  cancelServiceType = (dataItem) => {
    if (dataItem.ServiceTypeID) {
      let originalItem = this.state.ServiceTypes.find(p => p.ServiceTypeID === dataItem.ServiceTypeID)
      if (originalItem.inEdit) originalItem.inEdit = false;
      this.updateServiceType(this.state.ServiceTypes,originalItem);
    } else {
      this.updateServiceType(this.state.ServiceTypes,dataItem,!dataItem.ServiceTypeID);
    }
    this.setState({ ServiceTypes: this.state.ServiceTypes.slice() });
  }
  removeServiceType = (dataItem) => {
    dataItem.inEdit = undefined;
    let key = dataItem.ServiceTypeID;
    this.updateServiceType(this.state.ServiceTypes,dataItem,true);
    this.updateServiceType(this.state.saveServiceTypes,dataItem,true);
    DeleteRow(ServiceTypesOID,key); // Delete in the ServiceTypes Table in OM 
    this.setState({ ServiceTypes: this.state.ServiceTypes.slice() });
  }
  itemChangeServiceType = (event) => {
    const value = event.value;
    const name = event.field;
    if (!name) {
      return;
    }
    const updatedData = this.state.ServiceTypes.slice();
    const item = this.updateServiceType(updatedData,event.dataItem);
    if (!value.ddID)
      item[name] = value;
    else
      item[name] = value.ddID;
    this.setState({ ServiceTypes: updatedData });
  }
  updateServiceType = (data,item,remove) => {  // data - is the entire data set (JSON), item - is the current line 
    let updated;
    //eslint-disable-next-line
    let index = data.findIndex(p => p === item || item.ServiceTypeID && p.ServiceTypeID === item.ServiceTypeID);
    if (index >= 0) {
      updated = Object.assign({},item);
      data[index] = updated;
    }
    if (remove) {
      data = data.splice(index,1);
    }
    return data[index];
  }
  //------------------------- End Edit ServiceTypes Grid ------------------------------------------------------------------

  //------------------------------------------------------------------------------------------------------------- 
  getStoreItemRecords = async (search) => { // Get the available Items
    if (!search)
      search = '';
    console.log("get store items search: " + search);
    let CD = await CallOM("GetProductItems",0,0,2,search);
    this.setState({ ItemSelect: "" });
    //console.log("Store Items: " + JSON.stringify(CD.d));
    if (CD.x.o === 0) {
      LogOffResetMsg(`Web Session Has Failed in ${SessionInfo.currentPage} - Session: ${CD.x.s}`);
      this.props.navigate("/Login");
    } else if (CD.x.o < 9500) {
      try {
        this.setState({ ItemSelect: search });
        const StoreItems = CD.d.rows.map(dataItem => Object.assign({ selected: false },dataItem));
        this.setState({ StoreItems });
        this.state.saveStoreItems = StoreItems;
        let cnt = StoreItems.length;
        if (cnt === 0)
          this.setState({ infoMessage: `  No Items Found for Search` });
        else if (cnt === 1)
          this.setState({ infoMessage: `  ${cnt} Item Retrieved for Search` });
        else
          this.setState({ infoMessage: `  ${cnt} Items Retrieved for Search` });
        this.setState({ detailMessage: `    Search detail: '${search}'` });
      } catch (error) {
        OMTrace(2,`Failure in Store Item Load: ${error.message}`);
      }
    }
  }
  //---
  percentFormat = (value) => new Intl.NumberFormat('en-US',{ style: 'percent',minimumFractionDigits: 2,maximumFractionDigits: 2 }).format(value);
  moneyFormat = (value) => new Intl.NumberFormat('en-US',{ style: 'currency',currency: 'USD' }).format(value);
  //percentFormat = this.frmt === true ? (value) => new Intl.NumberFormat('en-US', { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value) : (value) => value;
  //moneyFormat = (value) => new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
  dateTimeFormat = (value) => new Intl.DateTimeFormat('en-CA',{ dateStyle: 'long',timeStyle: 'short' }).format(value);
  dateTimeSFormat = (value) => new Intl.DateTimeFormat('en-CA',{ month: 'short',day: 'numeric',hour: 'numeric',minute: '2-digit' }).format(Date.parse(value));

  onHorizontalLayoutChange = (event) => {
    this.setState({ horizontalPanes: event.newState });
  }
  onVerticalLayoutChange = (event) => {
    this.setState({ verticalPanes: event.newState });
  }
  loadSpreadsheet = async (event) => {
    console.log(`Call GetSalesForSpreadsheet - Location: ${this.state.StoreLocationID}`);
    let storeLocationID = this.state.StoreLocationID;
    if (!storeLocationID || this.state.allLocations === true)
      storeLocationID = 0;
    let CD = await CallOM("GetSalesForSpreadsheet",storeLocationID,this.state.PeriodType,0,"","",this.state.PeriodBack);
    //console.log(`Spreadsheet Return: ${JSON.stringify(CD)}`);
    if (CD.x.o === 0) {
      LogOffResetMsg(`Web Session Has Failed in ${SessionInfo.currentPage} - Session: ${CD.x.s}`);
      this.props.navigate("/Login");
    } else if (CD.x.o < 9500) {
      try {
        //console.log(`Spreadsheet Ret: ${JSON.stringify(CD)}`);
        let sheet = CD.d;
        //console.log(`Spreadsheet: ${sheet}`);
        let sheetInfo = JSON.parse(sheet);         
        //console.log(`Spreadsheet: ${JSON.stringify(sheetInfo)}`);
        if (spreadsheet.current) {
          //console.log(`Load Sheet`);
          spreadsheet.current.fromJSON({ sheets: sheetInfo });
        }
        //let CD = ...  NOTE - declaring CD in try block caused CD in outblock to be inaccessable
        let CD2 = await CallOM("GetPeriodDate",0,this.state.PeriodType,0,"","",this.state.PeriodBack);
        console.log(`PeriodStartDate: ${JSON.stringify(CD2)}`);
        let PeriodStartDate = new Date(CD2.x.f);       
        console.log(`PeriodStartDate: ${CD2.d}, ${PeriodStartDate}`);
        this.setState({ PeriodStartDate });
      } catch (error) {
        console.log(`Failure in Spreadsheet Load: ${error.message}`);
      }
    }
  }
  //------------------------
  // 23Nov23 - I don't know what these are for?
  onAdd = (event) => {
    console.log('onAdd: ',JSON.stringify(event.affectedFiles));
    console.log('onAdd: ',JSON.stringify(event.newState));
    this.setState({
      files: event.newState
    });
  }
  onRemove = (event) => {
    console.log('onRemove: ',event.affectedFiles);
    this.setState({
      files: event.newState
    });
  }
  onProgress = (event) => {
    console.log('onProgress: ',event.affectedFiles,event.serverResponse);
    this.setState({
      files: event.newState
    });
  }
  onStatusChange = (event) => {
    console.log('onStatusChange: ',event.affectedFiles,event.serverResponse);
    this.setState({
      files: event.newState
    });
  }
  //saveProductCategoryImageToServer = async (event) => {
  //  await this.saveImageToServer(this.ProductCategoryOID, this.state.ProductItemCategoryID);
  //}
  //-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------  
  getStoreLocations = async (search) => { // Get the available StoreLocations
    let CD;
    console.log("getStoreLocations - search: " + search);
    if (!search)
      CD = await GetTableData(545207); // Get StoreInfoView
    else
      CD = await GetTableSearch(545207,search); // Search StoreInfoView - returns different results
    if (CD.x.o >= 9000 && CD.x.o < 9500) {
      try {
        const StoreLocationsData = CD.d.rows;
        //console.log(`Store Locations: ${JSON.stringify(StoreLocationsData)}`);
        this.setState({ StoreLocationsData });
        this.setState({ storeLocationsCount: StoreLocationsData.length });
        SetStoreLocations(StoreLocationsData);
      } catch (error) {
        console.log(error.message);
      }
    }
    else {
      displayError("Unable to retrieve Locations");
      this.setState({ StoreLocationsData: [] });
    }
  }
  getHoursOfOperation = async (search) => {
    if (!search)
      search = '';
    console.log("Hours of Operation Search: " + search);
    //search = search + "*StoreLocationID=" + this.state.CurrentStoreLocationID;
    let CD = await CallOM("GetHoursOfOperation",0,0,2,search);
    //console.log("Hours of Operation: " + JSON.stringify(CD.d));
    try {
      const HoursOfOperation = CD.d.rows.map(dataItem => Object.assign({ selected: false },dataItem));
      this.setState({ HoursOfOperation });
      this.setState({ hoursOfOperationCount: HoursOfOperation.length });
      this.state.saveHoursOfOperation = HoursOfOperation;
    } catch (error) {
      console.log(error.message);
    }
  }
  //--------------- Field Change Values --------------------------------  
  setItemStateChanged = (changed) => {
    this.setState({ itemStateHasChanged: changed });
    if (changed === true) {
      SessionInfo.setShowSave = 1; // Show Save in Header  
      if (this.clearScreen === true) {
        this.clearScreen = false;
        SessionInfo.setShowNew = 1;
      }
    } else
      SessionInfo.setShowSave = 2; // Turn Off
  }
  chgFldVal(event) {
    var stateCopy = Object.assign({},this.state[event.target.name]);
    stateCopy = event.target.value;
    this.setState({ [event.target.name]: stateCopy });
    this.setItemStateChanged(true);
  }      
  async chgMoneyFldVal(event) {
    let newVal = event.target.value.replace(/[^\d.-]/g,''); // Matches any single single character not in the bracket [] and replaces with ''  - https://www.infobyip.com/regularexpressioncalculator.php
    let tstVal = +newVal;
    if (Number.isNaN(tstVal))
      newVal = event.target.value.replace(/-/g,''); // Remove the - sign
    this.setState({ [event.target.name + 'S']: newVal });
    await this.setState({ [event.target.name]: newVal / 1.0 });
    this.setItemStateChanged(true);
  }
  chgFldValMon(event) {
    this.setState({ [event.target.name]: event.target.value });
    this.setItemStateChanged(true);
    //this.monitorCounter = 11;
  }
  chgDDFldValMon = (event) => {
    this.setState({ [event.target.name]: event.target.value.ddID });
    this.setItemStateChanged(true);
    //this.monitorCounter = 11;
  }
  focusMoneyFldVal = async (event) => {
    //console.log(`money focus: ${this.state[event.target.name]} name: ${event.target.name}`);
    if (this.state[event.target.name] === 0)
      this.setState({ [event.target.name + 'S']: '' });
    else
      this.setState({ [event.target.name + 'S']: this.state[event.target.name] });
    console.log(`money target: ${this.state[event.target.name + 'S']}`);
  }
  blurMoneyFldVal = async (event) => {
    //console.log(`money blur: ${this.state[event.target.name]} name: ${event.target.name}`);
    //console.log(`     blur money format: ${this.moneyFormat(this.state[event.target.name])} `);
    this.setState({ [event.target.name + 'S']: this.moneyFormat(this.state[event.target.name]) });
    //console.log(`money target: ${this.state[event.target.name + 'S']}`);
  }
  chgPercentFldVal(event) {
    //var stateCopy = Object.assign({}, this.state[event.target.name]);
    let newVal = event.target.value.replace(/[^\d.-]/g,'');
    //console.log("percent newval: " + newVal);
    let perVal = newVal / 100.0;
    if (Number.isNaN(perVal)) {
      newVal = event.target.value.replace(/[^\d.]/g,'');
      perVal = newVal / 100.0;
    }
    //console.log(`percent newval: ${perVal}`);
    this.setState({ [event.target.name + 'S']: newVal });
    this.setState({ [event.target.name]: perVal });
    this.setItemStateChanged(true);
  }
  async focusPercentFldVal(event) {
    //console.log(`percent focus: ${this.state[event.target.name]} name: ${event.target.name}`);
    this.setState({ [event.target.name + 'S']: this.state[event.target.name] * 100.0 });
    //console.log(`money target: ${this.state[event.target.name + 'S']}`);
  }
  async blurPercentFldVal(event) {
    //console.log(`percent blur: ${this.state[event.target.name]} name: ${event.target.name}`);
    this.setState({ [event.target.name + 'S']: this.percentFormat(this.state[event.target.name]) });
    //console.log(`money target: ${this.state[event.target.name + 'S']}`);
  }

  chgDDFldVal = (event) => {
    this.setState({ [event.target.props.name]: event.target.value.ddID });
    this.setState({ itemStateHasChanged: true });
  }
  chgCMDDDFldVal = (event) => {
    this.setState({ infoMessage: '' });
    this.setState({ detailMessage: '' });
    this.setState({ [event.target.name]: event.target.value.ddID });
    this.setState({ itemStateHasChanged: true });
    this.setProcessAction(event.target.value);
  }
  //Date.prototype.addDays = function (d) { return new Date(this.valueOf() + 864E5 * d); }; 
  blurDaysForDiscount = async (event) => {
    let days = event.target.value;
    console.log(`days=${days}`);
    let disDate = this.state.StartDateTime.valueOf();
    console.log(`disDate=${disDate}`);
    //let newDate = disDate.addDays(days);
    let newDate = new Date(disDate + 864E5 * days);
    console.log(`newDate=${newDate}`);
    this.setState({ EndDateTime: newDate });
  }
  blurEndDate = (event) => {

  }   
  chgCheckboxVal(event) {
    let setVal = true;
    if (this.state[event.target.name] === true)
      setVal = false;
    this.setState({ [event.target.name]: setVal });
    this.setItemStateChanged(true);
  }
  //chgCheckboxVal(event) {
  //  var stateCopy = this.state[event.target.name];
  //  stateCopy = !stateCopy;
  //  this.setState({ [event.target.name]: stateCopy });
  //  //console.log("after - target: " + event.target.name + " value: " + this.state[event.target.name] + " SelectProductItemCategories: " + this.state.SelectProductItemCategories);
  //}
  //-------------------------------------------------------------------
  onItemClick = async (event) => {
    console.log("Event - name: " + JSON.stringify(event.item));
    if (event.item.text === "Notifications") {
      event.item.selected = true;
      event.item.selected = !event.item.selected;
    }
    this.forceUpdate();
  }
  //-------------------------------------------------------------------
  onExpandChange = (event) => {
    event.item.expanded = !event.item.expanded;
    this.forceUpdate();
  }
  //---------------------- Process Action Commands ---------------------------------------------
  setProcessAction = async (dd) => {
    this.setState({ ActionID: dd.ddID });
    this.setState({ ActionName: dd.ddName });
  }
  onPerformCommand = async () => {
    let CD;
    let toLocationID = 0;
    if (this.state.ProcessCommand === 0) {
      displayMessage("A Process Command must be selected.");
    }
    else {
      displayMessage(`Process Command ${this.state.ProcessCommand} selected.`);
      const ProcessCmd = {
        AdjustHours: 17,
        AdjustPrices: 10,
        CopyItemsToLocations: 6,
        UpdateLocationItems: 7,
        FillHoursGrid: 3,
        FillItemGrid: 1,
        FillLocationGrid: 2,
        SetPrices: 11,
        SetPromotion: 15,
        SetHours: 18
      }
      switch (this.state.ProcessCommand) {
        case ProcessCmd.FillItemGrid:
          await this.searchCurrent();
          break;
        case ProcessCmd.FillLocationGrid:
          await this.searchCurrent();
          break;
        case ProcessCmd.FillHoursGrid:
          await this.searchCurrent();
          break;
        case ProcessCmd.CopyItemsToLocations:
          console.log(`Copy Items to Locations: ${toLocationID}, search: ${this.saveSearch}`);
          if (this.state.SelectLocation && this.state.StoreLocationIDSel > 0 && this.saveSearch.length > 0) {
            toLocationID = this.state.StoreLocationIDSel;
            let CD = await CallOM("CopyStoreItemsToStore",0,toLocationID,2,this.saveSearch);
            let cnt = CD.d;
            if (cnt === 0)
              this.setState({ infoMessage: `  No Items were copied` });
            else if (cnt === 1)
              this.setState({ infoMessage: `  ${cnt} Item was copied` });
            else
              this.setState({ infoMessage: `  ${cnt} were copied` });
          }
          else {
            displayWarning("A location must be selected for Copy Store Items");
            this.setState({ infoMessage: `  A location must be selected for Copy Store Items` });
          }
          break;
        case ProcessCmd.UpdateLocationItems:
          console.log(`Update Items to Locations: ${toLocationID}, search: ${this.saveSearch}`);
          if (this.state.SelectLocation && this.state.StoreLocationIDSel > 0 && this.saveSearch.length > 0) {
            toLocationID = this.state.StoreLocationIDSel;
            let CD = await CallOM("UpdateStoreItems",0,toLocationID,2,this.saveSearch);
            let RetVal = CD.d;
            if (cnt === 0)
              this.setState({ infoMessage: `  No Items were updated` });
            else if (cnt === 1)
              this.setState({ infoMessage: `  ${cnt} Item was updated` });
            else
              this.setState({ infoMessage: `  ${cnt} were updated` });
          }
          else {
            displayWarning("A location must be selected for Update Store Items");
            this.setState({ infoMessage: `  A location must be selected for Update Store Items` });
          }
          break;
        case ProcessCmd.AdjustPrices: // AdjustPrices
          console.log(`search current in AdjustPrices`);
          await this.searchCurrent();
          console.log(`AdjustPrices - search: ${this.extendedSearch}, Price: ${this.state.AdjustmentAmount}, Percent: ${this.state.AdjustmentPercentage}`);
          CD = await CallOMP("AdjustPrices",this.extendedSearch,parseFloat(this.state.AdjustmentPercentage + 0.001),parseFloat(this.state.AdjustmentAmount + 0.001));
          if (CD.x.o === 0 || CD.x.o > 9500) {
            this.props.navigate("/Login");
          } else {
            this.setState({ infoMessage: `  Adjust Prices: ${CD.m}` });
          }
          break;
        case ProcessCmd.SetPrices: // SetPrices    
          console.log(`search current in SetPrices`);
          await this.searchCurrent();
          console.log(`SetPrices - search: ${this.extendedSearch}, Price: ${this.state.ItemPrice}, Promotion: ${this.state.PromotionPrice}`);
          CD = await CallOMP("SetPrices",this.extendedSearch,parseFloat(this.state.ItemPrice + 0.001),parseFloat(this.state.PromotionPrice + 0.001));
          if (CD.x.o === 0 || CD.x.o > 9500) {
            this.props.navigate("/Login");
          } else {
            this.setState({ infoMessage: `  Adjust Prices: ${CD.m}` });
          }
          break;
        case ProcessCmd.SetPromotion: // SetPromotion  
          console.log(`search current in SetPromotion`);
          await this.searchCurrent();
          CD = await CallOMP("SetPromotion",this.extendedSearch);
          if (CD.x.o === 0 || CD.x.o > 9500) {
            this.props.navigate("/Login");
          } else {
            this.setState({ infoMessage: `  Adjust Prices: ${CD.m}` });
          }
          break;
        case ProcessCmd.AdjustHours: // AdjustHours  
          console.log(`search current in AdjustHours`);
          await this.searchCurrent();
          console.log(`AdjustHours - search: ${this.extendedSearch}, Start: ${this.state.OverrideStart}, End: ${this.state.OverrideEnd}`);
          CD = await CallOMP("AdjustHours",this.extendedSearch,parseDate(this.state.OverrideStart),parseDate(this.state.OverrideEnd),this.state.OverrideType);
          if (CD.x.o === 0 || CD.x.o > 9500) {
            this.props.navigate("/Login");
          } else {
            this.setState({ infoMessage: `  Adjust Hours: ${CD.m}` });
          }
          break;
        case ProcessCmd.SetHours: // Set Hours for Selection   
          console.log(`search current in SetHours`);
          await this.searchCurrent();
          console.log(`SetHours - search: ${this.extendedSearch}, Start: ${this.state.OverrideStart}, End: ${this.state.OverrideEnd}`);
          CD = await CallOMP("SetHours",this.extendedSearch,parseDate(this.state.OverrideStart),parseDate(this.state.OverrideEnd));
          if (CD.x.o === 0 || CD.x.o > 9500) {
            this.props.navigate("/Login");
          } else {
            this.setState({ infoMessage: `  Set Hours: ${CD.m}` });
          }
          break;
      }
    }
    //    if (this.state.ItemSelect === "") {
    //    displayWarning("Items must be selected prior to Item Copy.");
    //    displayMessage("Use Search Box, then Select and Check Attributes, then Press Search");
    //  }
    //  else { // Note - See searchCurrent for Search String Creation (ItemSelect)
    //    displayMessage("Copy Items (" + this.state.ItemSelect + ") to " + this.state.StoreLocations.find(c => c.ddID === this.state.StoreLocationID).ddName);
    //    let CD = await CallOM("CopyItemsToLocation", 544619, this.state.StoreLocationID, 2, this.state.ItemSelect);
    //    if (CD.x.o === 0)
    //      LogOffResetMsg("Web Session Has Failed in " + SessionInfo.currentPage + " - Session: " + CD.x.s);
    //    else if (CD.x.o < 9500) {
    //      try {
    //        displayMessage(CD.m);
    //      } catch (error) {
    //        console.log(error.message);
    //      }
    //    }
    //  }
  }
  onAdjustPrice = async () => {
    if (this.state.PricingMarkupPercent === 0)
      displayWarning("A PricingMarkupPercent must be Set.")
    else if (this.state.ItemSelect === "") {
      displayWarning("Items must be selected prior to Pricing Adjustment.");
      displayMessage("Use Search Box, then Select and Check Attributes, then Press Search");
    }
    else {
      displayMessage("AdjustItems Items (" + this.state.ItemSelect + ") by " + this.state.PricingMarkupPercent);
      let CD = await CallOM("AdjustPrices",544619,this.state.PricingMarkupPercent * 1000,2,this.state.ItemSelect);
      if (CD.x.o === 0) {
        LogOffResetMsg(`Web Session Has Failed in ${SessionInfo.currentPage} - Session: ${CD.x.s}`);
        this.props.navigate("/Login");
      } else if (CD.x.o < 9500) {
        try {
          displayMessage(CD.m);
        } catch (error) {
          console.log(error.message);
        }
      }
    }
  }
  onSetPromotion = async () => {
    if (this.state.SelectPromotionType === 0)
      displayWarning("A PromotionType must be selected.")
    else if (this.state.ItemSelect === "") {
      displayWarning("Items must be selected prior to Promotion Assignment.");
      displayMessage("Use Search Box, then Select and Check Attributes, then Press Search");
    }
    else {
      displayMessage("Set Promotion for (" + this.state.ItemSelect + ") to " + this.state.StorePromotions.find(c => c.ddID === this.state.SelectPromotionType).ddName);
      let CD = await CallOM("SetPromotions",544619,this.state.SelectPromotionType,2,this.state.ItemSelect);
      if (CD.x.o === 0) {
        LogOffResetMsg(`Web Session Has Failed in ${SessionInfo.currentPage} - Session: ${CD.x.s}`);
        this.props.navigate("/Login");
      } else if (CD.x.o < 9500) {
        try {
          displayMessage(CD.m);
        } catch (error) {
          console.log(error.message);
        }
      }
    }
  }
  //------------------------- Document Control ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
  //onSelectImageFile = async (event) => {
  //  event.stopPropagation();
  //  event.preventDefault();
  //  //console.log("ProductItemCategoryID: " + this.state.ProductItemCategoryID);
  //  //if (this.state.ProductItemCategoryID === 0 && this.InsertRecordType != 1)
  //  if (this.state.ProductItemCategoryID === 0)
  //    displayWarning("Product Category must be Selected or Added First");
  //  else {
  //    const file = event.target.files[0];
  //    //console.log("File: " + JSON.stringify(file));
  //    //this.setState({ ContractFileName: file.name });
  //    let reader = new window.FileReader();
  //    try {
  //      reader.readAsArrayBuffer(file);
  //      reader.onloadend = () => this.convertToBuffer(reader);
  //      displayMessage("Image: " + file.name + " Loaded");
  //      this.DocumentLoaded = true;
  //      this.DocumentSaved = false;
  //    }
  //    catch (error) {
  //      console.log("FileReader Error: " + error);
  //      displayError("Error on File Read");
  //      return;
  //    } //catch      
  //    let ImageType = GetFileExtension(file.name);
  //    //console.log("ImageType: " + ImageType);
  //    ImageType = ImageType.toLowerCase();
  //    this.setState({ ImageType });
  //    let ix = this.state.DocumentTypes.findIndex(c => c.ddName === ImageType);
  //    if (ix >= 0) {
  //      this.setState({ DocumentTypeID: this.state.DocumentTypes[ix].ddID });
  //      this.saveImageToServer(this.ProductCategoryOID, this.state.ProductItemCategoryID);
  //      displayMessage(`Image saved for ${this.state.ProductItemCategory}`);
  //      event.target.value = null;
  //    }
  //    else
  //      displayWarning("Unknown file type: " + ImageType);
  //  }
  //}

  //------------------------- Image Management ------------------------------
  onSelectImageFile = async (event) => {
    event.stopPropagation();
    event.preventDefault();
    //console.log("StoreItemID: " + this.state.StoreItemID);
    //if (this.state.StoreItemID === 0 && this.InsertRecordType != 1)
    if (this.state.ProductItemCategoryID === 0)
      displayWarning("Product Category must be Selected or Added First");
    else {
      this.file = event.target.files[0];
      console.log("File: " + JSON.stringify(this.file));
      //this.setState({ ContractFileName: file.name });
      let reader = new window.FileReader();
      this.fileReady = false;
      try {
        reader.readAsArrayBuffer(this.file);
        reader.onloadend = async () => {
          await this.convertToBuffer(reader);
          this.fileReady = true;
          displayMessage("Image: " + this.file.name + " Loaded");
          this.DocumentLoaded = true;
          this.DocumentSaved = false;
        }
        this.fileCheck();
        event.target.value = null;
      }
      catch (error) {
        console.log("FileReader Error: " + error);
        displayError("Error on File Read");
        return;
      }
    }
  }
  fileCheck = async () => {
    if (this.fileReady === true) {
      let ImageType = GetFileExtension(this.file.name);
      console.log("fileCheck ImageType: " + ImageType);
      ImageType = ImageType.toLowerCase();
      this.setState({ ImageType });
      let ix = SessionInfo.DocumentTypes.findIndex(c => c.ddName === ImageType);
      if (ix >= 0) {
        this.setState({ DocumentTypeID: SessionInfo.DocumentTypes[ix].ddID });
        let iVal = await this.saveImageToServer(this.ProductCategoryOID,this.state.ProductItemCategoryID); // Note - ProductCategoryTable
        displayMessage(`Image saved for '${this.state.currentItemName}'`);
        setTimeout(this.retrieveImage,1000,iVal);
      }
      else
        displayWarning("Unknown file type: " + ImageType);
      return;
    }
    setTimeout(this.fileCheck,200);
  }
  //
  //onAddCategoryImage = async (event) => {
  //  const fileObj = event.affectedFiles[0].getRawFile();
  //  //console.log('onAddLoc name: ' + event.affectedFiles[0].name);
  //  //console.log('onAddLoc ext: ' + event.affectedFiles[0].extension);
  //  //console.log('onAddLoc size: ' + event.affectedFiles[0].size);
  //  //console.log('onAddLoc status: ' + event.affectedFiles[0].status);
  //  //console.log(fileObj);

  //  this.ImageType = event.affectedFiles[0].extension;
  //  try {
  //    let reader = new FileReader();
  //    //reader.readAsBinaryString(fileObj); // not sure what this does - does not work
  //    reader.readAsArrayBuffer(fileObj);
  //    reader.onloadend = async () => {
  //      this.ImageFile = await Buffer.from(reader.result);
  //      //set this buffer -using es6 syntax
  //      //let image = new Blob([reader], { type: 'image/jpeg' });
  //      //console.log('onAddLoc Image: ' + this.ImageFile);
  //      //console.log('onAddLoc Image Lth: ' + this.ImageFile.length);
  //      //this.ImageFile = cc;
  //      this.storeImage = fileObj; // File Object
  //      let imageURL = URL.createObjectURL(fileObj);
  //      console.log(`imageURL: ${imageURL}`);
  //      this.setState({ CategoryPhotoURL: imageURL });
  //      this.fileReady = true;
  //      this.DocumentLoaded = true;
  //      this.DocumentSaved = false;
  //      await this.fileCategoryUpload();
  //      console.log('finish onloadend');
  //    }
  //    this.fileStoreCheck();
  //    event.target.value = null;
  //  }
  //  catch (error) {
  //    console.log("FileReader Store Error: " + error);
  //    displayError("Error on Image File Read");
  //    return;
  //  }
  //  // 
  //  console.log('finish addLocImage');
  //};
  addCategoryImage = async (event) => {
    try {
      console.log(`load category Image`);
      const fileObj = event.affectedFiles[0].getRawFile();
      let imageType = getDocumentType(event.affectedFiles[0].extension,"Category Image");
      let reader = new FileReader();
      reader.readAsArrayBuffer(fileObj);
      reader.onloadend = async () => {
        console.log(`load category Image End - Call SaveImageFileToServer`);
        let imageFile = await Buffer.from(reader.result);
        this.setState({ ProductCategoryPhotoURL: URL.createObjectURL(fileObj) });
        let iVal = await saveImageFileToServer(imageFile,imageType,this.ProductCategoryOID,this.state.ProductItemCategoryID);
        this.setState({ ImageID: iVal });
      }
      event.target.value = null;
    }
    catch (error) {
      console.log(`addCategoryImage Error: ${error}`);
    }
  }
  // --- Image ----
  addPromoImage = async (event) => {
    try {
      const fileObj = event.affectedFiles[0].getRawFile();
      let imageType = getDocumentType(event.affectedFiles[0].extension,"Promotion Image");
      let reader = new FileReader();
      reader.readAsArrayBuffer(fileObj);
      reader.onloadend = async () => {
        let imageFile = await Buffer.from(reader.result);
        this.setState({ PromotionPhotoURL: URL.createObjectURL(fileObj) });
        let iVal = await saveImageFileToServer(imageFile,imageType,this.StorePromotionsOID,this.state.StorePromotionID);
        this.setState({ ImageID: iVal });
      }
      event.target.value = null;
    }
    catch (error) {
      console.log(`addPromoImage Error: ${error}`);
    }
  };
  onRemove = (event) => {
    console.log('onRemove: ',event.affectedFiles);
    this.setState({
      files: event.newState
    });
  }
  onProgress = (event) => {
    console.log('onProgress: ',event.affectedFiles,event.serverResponse);
    this.setState({
      files: event.newState
    });
  }
  ShowActionHelp = async (key) => {
    key += 1000;
    console.log(`Action Help - Key: ${key}`);
    this.onObjectHelp(key);
    //this.showHelp(key);
    //console.log(`Field Help: ${JSON.stringify(item)}`);
  }
  onStatusChange = (event) => {
    //console.log('onStatusChange: ', event.affectedFiles, event.serverResponse);
    //console.log(`onStatusChange newState: ${JSON.stringify(event.newState)}`);
    //console.log(event.newState);
    let cnt = event.newState.length;
    event.newState[cnt - 1].status = 1;
    //console.log(event.newState);
    this.setState({ files: event.newState });
  }
  fileStoreCheck = async () => {
    if (this.file.name) {
      if (this.fileReady === true) {
        let ImageType = GetFileExtension(this.file.name);
        console.log(`fileStoreCheck - ImageType: "${ImageType} - file.name: ${this.file.name}`);
        ImageType = ImageType.toLowerCase();
        this.setState({ ImageType });
        let ix = SessionInfo.DocumentTypes.findIndex(c => c.ddName === ImageType);
        if (ix >= 0) {
          this.setState({ DocumentTypeID: SessionInfo.DocumentTypes[ix].ddID });
          let iVal = await this.saveImageToServer(this.StoreLocationOID,this.state.StoreLocationID); // Note StoreLocationsTable
          displayMessage(`Image saved for Store '${this.state.StoreName}'`);
          setTimeout(this.retrieveImage,1000,iVal);
        }
        else
          displayWarning("Unknown file type: " + ImageType);
        return;
      }
      setTimeout(this.fileStoreCheck,200);
    }
  }
  retrieveImage = async (iVal) => {
    console.log("Retrieve Image: " + iVal);
    await this.GetProductCategoryImage(iVal);
  }
  convertToBuffer = async (reader) => { // File is converted to a buffer to prepare for uploading to OM
    const UploadFile = await Buffer.from(reader.result);
    //set this buffer -using es6 syntax
    this.setState({ UploadFile });
    //console.log("converToBuffer - Image uploadfile Length: " + UploadFile.length);
    // Display the image
    let image = new Blob([UploadFile],{ type: 'image/jpeg' });
    let imageURL = URL.createObjectURL(image);
    this.setState({ ProductItemPhotoURL: imageURL });
  };
  saveImageToServer = async (tableObjID,itemID) => {
    console.log(`saveImageToServer: ${tableObjID}, ID: ${itemID}`);
    if (this.isSavingDocument) {
      displayMessage("Document Save in Progress");
      return;
    }
    else if (!this.state.UploadFile)
      displayError('An Image file must be selected first');
    else {
      this.isSavingDocument = true;
      //displayMessage('Saving Image for ' + this.state.ProductItemCategory);
      let CD = await CallOM("SaveProductImage",tableObjID,itemID,20,this.state.UploadFile,this.state.ImageType); // NOTE - Saves to tableObjID collection
      let ival = CD.d;
      console.log(`Saved Image ${this.state.UploadFile.length} - ID: ${CD.d}`);
      this.isSavingDocument = false;
      this.DocumentLoaded = false;
      this.DocumentSaved = true;
      return ival;
    }
  }
  // Excel Export Support - one copy add to just before render
  _export;
  exportExcel = () => {
    this._export.save();
  }
  //-------------------------------------------------------------------------------------------------------------
  render() {
    return (
      <div>
        <div id="ManagerPage" className="pageMain">
          <PageSide />
          <PageHeader L1='Home' Select='5' PageTitle='Interact - Manager ' IsApp='y' ItemName='Category' />
          <div id="mainCntr">
            <Splitter style={{ height: '100%',border: 'none' }} panes={this.state.horizontalPanes} orientation={'horizontal'} onChange={this.onHorizontalLayoutChange}>
              <div id="splitterLeft" className="splitPaneContent">
                <TabStrip selected={this.state.selectedTopTab} onSelect={this.handleTopTabSelect} className="tabStrip">

                  <TabStripTab title="Menu Categories">
                    <div className="editTab">
                      <ExcelExport data={this.state.ProductCategorys} ref={(exporter) => { this._export = exporter; }}>
                        <TGrid style={{ position: 'absolute',top: 0,height: '100%' }} data={this.state.ProductCategorys.slice(this.state.ProductCategorysSkip,this.state.ProductCategorysTake + this.state.ProductCategorysSkip)} onItemChange={this.itemChangeProductCategory}
                          onRowClick={this.ProductCategoryRowClick} resizable={true} selectedField="selected" editField="inEdit"
                          skip={this.state.ProductCategorysSkip} take={this.state.ProductCategorysTake} total={this.state.ProductCategorys.length} onPageChange={this.ProductCategorysPageChange} pageable={{ info: true,type: 'numeric',pageSizes: true,previousNext: true }} pageSize={6}>
                          <Column field="ProductItemCategory" title="Item Category" width='170px' />
                          <Column field="Description" title="Description" width='350px' />                                 
                          <Column field="ProductItemGroupID" title="Product Group" cell={ProductItemGroupDropDownCell} width='160px' />
                          <Column field="Image" cell={ImageCell} title="." width='50px' />
                          <Column field="SortOrder" title="Sort" width='70px' filter='numeric' />
                          <Column field="IsRecordActive" cell={BoolCell} title="Active" width='70px' />
                          <Column cell={this.ProductCategoryCommandCell} width="200px" />
                        </TGrid>
                      </ExcelExport>
                      <div className="editTabRightNarrow">
                        <div id="div1" className="editPicField">
                          <h4>Category Image</h4>
                          <div className="framePic">
                            <Avatar rounded='large' size='large' type='image' className='avatarFramePic'>
                              <img src={this.state.ProductCategoryPhotoURL} height={280} width={360} style={{ objectFit: 'fill',}} />
                            </Avatar>
                          </div>
                          <div className="framePicUpload">
                            <div style={{ position: "relative",top: "0px",width: "370px",border: "solid 2px grey" }}>
                              <Upload
                                ref={uploadRef}
                                batch={false}
                                multiple={true}
                                files={this.state.files}
                                onAdd={this.addCategoryImage}
                                onRemove={this.onRemove}
                                onStatusChange={this.onStatusChange}
                                saveUrl={null}
                                defaultFiles={[]}
                                withCredentials={false}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </TabStripTab>
                  <TabStripTab title="Menu Groups">
                    <div className="editTab">
                      <TGrid style={{ position: 'absolute',top: 0,height: '100%' }} data={this.state.ProductGroups.slice(this.state.ProductGroupsSkip,this.state.ProductGroupsTake + this.state.ProductGroupsSkip)} onItemChange={this.itemChangeProductGroup}
                        onRowClick={this.ProductGroupRowClick} resizable={true} selectedField="selected" editField="inEdit"
                        skip={this.state.ProductGroupsSkip} take={this.state.ProductGroupsTake} total={this.state.ProductGroups.length} onPageChange={this.ProductGroupsPageChange} pageable={{ info: true,type: 'numeric',pageSizes: true,previousNext: true }} pageSize={6}>
                        <Column field="ProductItemGroup" title="Item Name" width='200px' />
                        <Column field="Description" title="Description" width='350px' />
                        <Column field="MenuLayoutID" title="Menu" width='70px' filter='numeric' />
                        <Column field="SortOrder" title="Sort" width='70px' filter='numeric' />
                        <Column field="IsRecordActive" cell={BoolCell} title="Active" width='70px' />
                        <Column cell={this.ProductGroupCommandCell} width="260px" />
                      </TGrid>
                    </div>
                  </TabStripTab>
                  <TabStripTab title="Product Types">
                    <div className="editTab">
                      <TGrid style={{ position: 'absolute',top: 0,height: '100%' }} data={this.state.ProductTypes.slice(this.state.ProductTypesSkip,this.state.ProductTypesTake + this.state.ProductTypesSkip)} onItemChange={this.itemChangeProductType}
                        onRowClick={this.ProductTypeRowClick} resizable={true} selectedField="selected" editField="inEdit"
                        skip={this.state.ProductTypesSkip} take={this.state.ProductTypesTake} total={this.state.ProductTypes.length} onPageChange={this.ProductTypesPageChange} pageable={{ info: true,type: 'numeric',pageSizes: true,previousNext: true }} pageSize={6}>
                        <Column field="ProductItemType" title="Product Type" width='200px' editor="text" />
                        <Column field="Description" title="Description" width='350px' editor="text" />
                        <Column field="IsRecordActive" cell={BoolCell} title="Record Active" width='180px' />
                        <Column cell={this.ProductTypeCommandCell} width="260px" />
                      </TGrid>
                    </div>
                  </TabStripTab>
                  <TabStripTab title="Pricing Types">
                    <div className="editTab">
                      <TGrid style={{ position: 'absolute',top: 0,height: '100%' }} data={this.state.PricingTypes.slice(this.state.PricingTypesSkip,this.state.PricingTypesTake + this.state.PricingTypesSkip)} onItemChange={this.itemChangePricingType}
                        onRowClick={this.PricingTypeRowClick} resizable={true} selectedField="selected" editField="inEdit"
                        skip={this.state.PricingTypesSkip} take={this.state.PricingTypesTake} total={this.state.PricingTypes.length} onPageChange={this.PricingTypesPageChange} pageable={{ info: true,type: 'numeric',pageSizes: true,previousNext: true }} pageSize={6}>
                        <Column field="PricingType" title="Pricing Type" width='200px' editor="text" />
                        <Column field="Description" title="Description" width='300px' editor="text" />
                        <Column field="MarkupValue" title="Markup $" filter='numeric' cell={MoneyCell} width='120px' />
                        <Column field="MarkupPercent" title="Markup %" filter='numeric' cell={PercentCell} width='120px' />
                        <Column field="PricingTypeID" title="Set Price Form" cell={PricingTypeDropDownCell} width='150px' />
                        <Column field="IsRecordActive" cell={BoolCell} title="Active" width='70px' />
                        <Column cell={this.PricingTypeCommandCell} width="260px" />
                      </TGrid>
                    </div>
                  </TabStripTab>
                  <TabStripTab title="Rate Types">
                    <div className="editTab">
                      <TGrid style={{ position: 'absolute',top: 0,height: '100%' }} data={this.state.RateTypes.slice(this.state.RateTypesSkip,this.state.RateTypesTake + this.state.RateTypesSkip)} onItemChange={this.itemChangeRateType}
                        onRowClick={this.RateTypeRowClick} resizable={true} selectedField="selected" editField="inEdit"
                        skip={this.state.RateTypesSkip} take={this.state.RateTypesTake} total={this.state.RateTypes.length} onPageChange={this.RateTypesPageChange} pageable={{ info: true,type: 'numeric',pageSizes: true,previousNext: true }} pageSize={6}>
                        <Column field="RateType" title="RateType" width='200px' editor="text" />
                        <Column field="Description" title="Description" width='350px' editor="text" />
                        <Column field="ShortDescription" title="Short Description" width='350px' editor="text" />
                        <Column field="IsRecordActive" cell={BoolCell} title="Active" width='70px' />
                        <Column cell={this.RateTypeCommandCell} width="260px" />
                      </TGrid>
                    </div>
                  </TabStripTab>
                  <TabStripTab title="Seasons">
                    <div className="editTab">
                      <TGrid style={{ position: 'absolute',top: 0,height: '100%' }} onItemChange={this.itemChangeSeasonCode} editField="inEdit"
                        data={orderBy(filterBy(this.state.SeasonCodes,this.state.SeasonCodesFilter),this.state.SeasonCodesSort).slice(this.state.SeasonCodesSkip,this.state.SeasonCodesTake + this.state.SeasonCodesSkip)}
                        skip={this.state.SeasonCodesSkip} take={this.state.SeasonCodesTake} total={this.state.SeasonCodes.length} onPageChange={this.SeasonCodesPageChange} pageable={{ info: true,type: 'numeric',pageSizes: true,previousNext: true }} pageSize={6}>
                        <Column field="SeasonCode" title="Season Code Name" width='200px' editor="text" />
                        <Column field="Description" title="Description" width='200px' editor="text" />
                        <Column field="StartDate" title="Start Date" cell={cellDate} width='180px' editable={true} />
                        <Column field="EndDate" title="End Date" cell={cellDate} width='180px' editable={true} />
                        <Column field="IsRecordActive" cell={BoolCell} title="Active Record" width='70px' />
                        <Column cell={this.SeasonCodeCommandCell} width="200px" />
                      </TGrid>
                    </div>
                  </TabStripTab>
                  <TabStripTab title="Time Of Day">
                    <div className="editTab">
                      {/*------ TimeOfDays Grid Definition ------*/}
                      <TGrid style={{ position: 'absolute',top: 0,height: '100%' }} onItemChange={this.itemChangeTimeOfDay} editField="inEdit"
                        data={orderBy(filterBy(this.state.TimeOfDays,this.state.TimeOfDaysFilter),this.state.TimeOfDaysSort).slice(this.state.TimeOfDaysSkip,this.state.TimeOfDaysTake + this.state.TimeOfDaysSkip)}
                        skip={this.state.TimeOfDaysSkip} take={this.state.TimeOfDaysTake} total={this.state.TimeOfDays.length} onPageChange={this.TimeOfDaysPageChange} pageable={{ info: true,type: 'numeric',pageSizes: true,previousNext: true }} pageSize={6}>
                        <Column field="TimeOfDay" title="Time Of Day Name" width='200px' editor="text" />
                        <Column field="Description" title="Description" width='200px' editor="text" />
                        <Column field="StartTime" title="Start" cell={TimePickerCell} width='180px' editable={true} />
                        <Column field="EndTime" title="End" cell={TimePickerCell} width='180px' editable={true} />
                        <Column field="IsRecordActive" cell={BoolCell} title="Active Record" width='70px' />
                        <Column cell={this.TimeOfDayCommandCell} width="200px" />
                      </TGrid>
                    </div>
                  </TabStripTab>
                  <TabStripTab title="Tax Types">
                    <div className="editTab">
                      <TGrid style={{ position: 'absolute',top: 0,height: '100%' }} onItemChange={this.itemChangeTaxableType} editField="inEdit"
                        data={orderBy(filterBy(this.state.TaxableTypes,this.state.TaxableTypesFilter),this.state.TaxableTypesSort).slice(this.state.TaxableTypesSkip,this.state.TaxableTypesTake + this.state.TaxableTypesSkip)}
                        skip={this.state.TaxableTypesSkip} take={this.state.TaxableTypesTake} total={this.state.TaxableTypes.length} onPageChange={this.TaxableTypesPageChange} pageable={{ info: true,type: 'numeric',pageSizes: true,previousNext: true }} pageSize={6}>
                        <Column field="TaxableType" title="Taxable Type Name" width='200px' editor="text" />
                        <Column field="Description" title="Description" width='200px' editor="text" />
                        <Column field="IncludeTax1" cell={BoolCell} title="Tax1" width='70px' />
                        <Column field="IncludeTax2" cell={BoolCell} title="Tax2" width='70px' />
                        <Column field="IncludeTax3" cell={BoolCell} title="Tax3" width='70px' />
                        <Column cell={this.TaxableTypeCommandCell} width="200px" />
                      </TGrid>
                    </div>
                  </TabStripTab>
                  <TabStripTab title="Service Types">
                    <div className="editTab">
                      <TGrid style={{ position: 'absolute',top: 0,height: '100%' }} onItemChange={this.itemChangeServiceType} editField="inEdit"
                        data={orderBy(filterBy(this.state.ServiceTypes,this.state.ServiceTypesFilter),this.state.ServiceTypesSort).slice(this.state.ServiceTypesSkip,this.state.ServiceTypesTake + this.state.ServiceTypesSkip)}
                        skip={this.state.ServiceTypesSkip} take={this.state.ServiceTypesTake} total={this.state.ServiceTypes.length} onPageChange={this.ServiceTypesPageChange} pageable={{ info: true,type: 'numeric',pageSizes: true,previousNext: true }} pageSize={6}>
                        <Column field="ServiceType" title="Service Type" width='200px' editor="text" />
                        <Column field="Description" title="Description" width='200px' editor="text" />
                        <Column field="TimeOfDayID" title="Time Of Day" cell={TimeOfDayDropDownCell} width='160px' />
                        <Column field="DayOfWeekID" title="Day Of Week" cell={DayOfWeekDropDownCell} width='160px' />
                        <Column field="IsActive" cell={BoolCell} title="Is Active" width='120px' />
                        <Column cell={this.ServiceTypeCommandCell} width="200px" />
                      </TGrid>
                    </div>
                  </TabStripTab>
                  <TabStripTab title="Notifications">
                    <div className="editTab">
                      <TGrid style={{ position: 'absolute',top: 0,height: '100%' }} data={this.state.AppNotifications.slice(this.state.AppNotificationsSkip,this.state.AppNotificationsTake + this.state.AppNotificationsSkip)} onItemChange={this.itemChangeAppNotification}
                        onRowClick={this.AppNotificationRowClick} resizable={true} selectedField="selected" editField="inEdit"
                        skip={this.state.AppNotificationsSkip} take={this.state.AppNotificationsTake} total={this.state.AppNotifications.length} onPageChange={this.AppNotificationsPageChange} pageable={{ info: true,type: 'numeric',pageSizes: true,previousNext: true }} pageSize={6}>
                        <Column field="NotificationMessage" title="Notification Message" width='350px' editor="text" />
                        <Column field="NotificationTypeID" title="Notification Type" cell={NotificationTypeDropDownCell} width='200px' editor="text" />
                        <Column field="StoreLocationID" title="Store Location" cell={StoreLocationDropDownCell} width='200px' editable={false} />
                        <Column cell={this.AppNotificationCommandCell} width="260px" />
                      </TGrid>
                    </div>
                  </TabStripTab>
                  {/*------ Promotions Grid Definition ------*/}
                  <TabStripTab title="Promotions">
                    <div className="editTab">
                      <ExcelExport data={this.state.StorePromotions} ref={(exporter) => { this._exportPromotions = exporter; }}>   {/* filterable */}
                        <TGrid style={{ position: 'absolute',top: 0,height: '100%' }} data={orderBy(filterBy(this.state.StorePromotions,this.state.StorePromotionFilter),this.state.StorePromotionSort).slice(this.state.StorePromotionsSkip,this.state.StorePromotionsTake + this.state.StorePromotionsSkip)}
                          skip={this.state.StorePromotionsSkip} take={this.state.StorePromotionsTake} total={this.state.StorePromotions.length} onPageChange={this.promoChange} onRowClick={this.StorePromotionRowClick} selectedField="selected" resizable={true}
                          sortable={true} sort={this.state.StorePromotionSort} onSortChange={(e) => { this.setState({ StorePromotionSort: e.sort }); }} filter={this.state.StorePromotionFilter} onFilterChange={(e) => { this.setState({ StorePromotionFilter: e.filter }); }}
                          pageable={{ info: true,type: 'numeric',pageSizes: true,previousNext: true }} pageSize={6}>
                          <Column field="StorePromotionName" title="Name" width='160px' />
                          <Column field="PromotionType" title="Promo Type" width='120px' />
                          <Column field="ProductItemCategoryID" title="Product Category" width='120px' />
                          <Column field="StartDateTime" title="Start Date" width='200px' filter='date' cell={cellDateTime} />{/*editor="date" format="{0:D}" /> format="{0:D}"*/}
                          <Column field="EndDateTime" title="End Date" width='200px' filter='date' cell={cellDateTime} />{/*editor="date" format="{0:D}" /> format="{0:D}"*/}
                          <Column field="DiscountPercentage" title="Percentage" width='100px' filter='numeric' />
                          <Column field="DiscountAmount" title="Discount" filter='numeric' cell={MoneyCell} width='100px' />
                        </TGrid>
                      </ExcelExport>
                    </div>Fsa
                  </TabStripTab>
                  {/*------ Manage Product Items ------*/}
                  <TabStripTab title="Process Commands">
                    <div className="editTab">
                      <div className="editTabLeft">
                        <div id="div1" className="editInside">
                          <h4>Items Selection Criteria</h4>
                          <br />
                          <div className="editField">
                            <span className="editFieldLabel">Item Category</span>
                            <div className="editDDPos">
                              <input type="checkbox" checked={this.state.SelectItemCategory} name="SelectItemCategory" onChange={evt => this.chgCheckboxVal(evt)} />
                              <DropDownList data={this.state.ProductItemCategories} textField="ddName" dataItemKey="ddID" value={this.state.ProductItemCategories.find(c => c.ddID === this.state.ProductItemCategoryIDSel)} name="ProductItemCategoryIDSel" onChange={evt => this.chgDDFldVal(evt)} className="editInputDDShort" />
                            </div>
                          </div>
                          <div className="editField">
                            <span className="editFieldLabel">Item Group</span>
                            <div className="editDDPos">
                              <input type="checkbox" checked={this.state.SelectItemGroup} name="SelectItemGroup" onChange={evt => this.chgCheckboxVal(evt)} />
                              <DropDownList data={this.state.ProductItemGroups} textField="ddName" dataItemKey="ddID" value={this.state.ProductItemGroups.find(c => c.ddID === this.state.ProductItemGroupIDSel)} name="ProductItemGroupIDSel" onChange={evt => this.chgDDFldVal(evt)} className="editInputDDShort" />
                            </div>
                          </div>
                          <div className="editField">
                            <span className="editFieldLabel">Item Type</span>
                            <div className="editDDPos">
                              <input type="checkbox" checked={this.state.SelectItemType} name="SelectItemType" onChange={evt => this.chgCheckboxVal(evt)} />
                              <DropDownList data={this.state.ProductItemTypes} textField="ddName" dataItemKey="ddID" value={this.state.ProductItemTypes.find(c => c.ddID === this.state.ProductItemTypeIDSel)} name="ProductItemTypeIDSel" onChange={evt => this.chgDDFldVal(evt)} className="editInputDDShort" />
                            </div>
                          </div>
                          <div className="editField">
                            <span className="editFieldLabel">Brand</span>
                            <div className="editDDPos">
                              <input type="checkbox" checked={this.state.SelectBrand} name="SelectBrand" onChange={evt => this.chgCheckboxVal(evt)} />
                              <DropDownList data={this.state.Brands} textField="ddName" dataItemKey="ddID" value={this.state.Brands.find(c => c.ddID === this.state.BrandIDSel)} name="BrandIDSel" onChange={evt => this.chgDDFldVal(evt)} className="editInputDDShort" />
                            </div>
                          </div>
                          <div className="editField">
                            <span className="editFieldLabel">Item Status</span>
                            <div className="editDDPos">
                              <input type="checkbox" checked={this.state.SelectItemStatus} name="SelectItemStatus" onChange={evt => this.chgCheckboxVal(evt)} />
                              <DropDownList data={this.state.ItemStatusSet} textField="ddName" dataItemKey="ddID" value={this.state.ItemStatusSet.find(c => c.ddID === this.state.ItemStatusIDSel)} name="ItemStatusIDSel" onChange={evt => this.chgDDFldVal(evt)} className="editInputDDShort" />
                            </div>
                          </div>
                          <div className="editField">
                            <span className="editFieldLabel">Season Code</span>
                            <div className='editDDPos'>
                              <input type="checkbox" checked={this.state.SelectSeasonCode} name="SelectSeasonCode" onChange={evt => this.chgCheckboxVal(evt)} />
                              <DropDownList data={this.state.SeasonCodesDict} textField='ddName' dataItemKey='ddID' value={this.state.SeasonCodesDict.find(c => c.ddID === this.state.SeasonCodeIDSel)} name='SeasonCodeIDSel' onChange={evt => this.chgDDFldVal(evt)} className='editInputDDShort' />
                            </div>
                          </div>
                          <div className="editField">
                            <span className="editFieldLabel">Day of Week</span>
                            <div className="editDDPos">
                              <input type="checkbox" checked={this.state.SelectDayOfWeek} name="SelectDayOfWeek" onChange={evt => this.chgCheckboxVal(evt)} />
                              <DropDownList data={this.state.DayOfWeekDict} textField="ddName" dataItemKey="ddID" value={this.state.DayOfWeekDict.find(c => c.ddID === this.state.DayOfWeekSel)} name="DayOfWeekSel" onChange={evt => this.chgDDFldVal(evt)} className="editInputDDShort" />
                            </div>
                          </div>
                          <div className="editField">
                            <span className="editFieldLabel">Time Of Day</span>
                            <div className="editDDPos">
                              <input type="checkbox" checked={this.state.SelectTimeOfDate} name="SelectTimeOfDay" onChange={evt => this.chgCheckboxVal(evt)} />
                              <DropDownList data={this.state.TimeOfDayDict} textField="ddName" dataItemKey="ddID" value={this.state.TimeOfDayDict.find(c => c.ddID === this.state.TimeOfDayIDSel)} name="TimeOfDayIDSel" onChange={evt => this.chgDDFldVal(evt)} className="editInputDDShort" />
                            </div>
                          </div>
                          <div className="editField">
                            <span className="editFieldLabel">Pricing Type</span>
                            <div className="editDDPos">
                              <input type="checkbox" checked={this.state.SelectPricingType} name="SelectPricingType" onChange={evt => this.chgCheckboxVal(evt)} />
                              <DropDownList data={this.state.PricingTypesDict} textField="ddName" dataItemKey="ddID" value={this.state.PricingTypesDict.find(c => c.ddID === this.state.PricingTypeIDSel)} name="PricingTypeIDSel" onChange={evt => this.chgDDFldVal(evt)} className="editInputDDShort" />
                            </div>
                          </div>
                          <div className="editField">
                            <span className="editFieldLabel">Rate Type</span>
                            <div className="editDDPos">
                              <input type="checkbox" checked={this.state.SelectRateType} name="SelectRateType" onChange={evt => this.chgCheckboxVal(evt)} />
                              <DropDownList data={this.state.RateTypesDict} textField="ddName" dataItemKey="ddID" value={this.state.RateTypesDict.find(c => c.ddID === this.state.RateTypeIDSel)} name="RateTypeIDSel" onChange={evt => this.chgDDFldVal(evt)} className="editInputDDShort" />
                            </div>
                          </div>
                          <br />
                          <h4>Apply to Locations</h4>
                          <br />
                          <div className="editField">
                            <span className="editFieldLabel">Select Location</span>
                            <div className="editDDPos">
                              <input type="checkbox" checked={this.state.SelectLocation} name="SelectLocation" onChange={evt => this.chgCheckboxVal(evt)} />
                              <DropDownList data={this.state.StoreLocations} textField="ddName" dataItemKey="ddID" value={this.state.StoreLocations.find(c => c.ddID === this.state.StoreLocationIDSel)} name="StoreLocationIDSel" onChange={evt => this.chgDDFldVal(evt)} className="editInputDDShort" />
                            </div>
                          </div>
                          <div className="editField">
                            <span className="editFieldLabel">Store Group</span>
                            <div className="editDDPos">
                              <input type="checkbox" checked={this.state.SelectStoreGroup} name="SelectStoreGroup" onChange={evt => this.chgCheckboxVal(evt)} />
                              <DropDownList data={this.state.StoreGroupsDict} textField="ddName" dataItemKey="ddID" value={this.state.StoreGroupsDict.find(c => c.ddID === this.state.StoreGroupIDSel)} name="StoreGroupIDSel" onChange={evt => this.chgDDFldVal(evt)} className="editInputDDShort" />
                            </div>
                          </div>
                          {/*<div className="editField">*/}
                          {/*  <span className="editFieldLabel">Company</span>*/}
                          {/*  <div className="editDDPos">*/}
                          {/*    <input type="checkbox" checked={this.state.SelectCompany} name="SelectCompany" onChange={evt => this.chgCheckboxVal(evt)} />*/}
                          {/*    <DropDownList data={this.state.CompaniesDict} textField="ddName" dataItemKey="ddID" value={this.state.CompaniesDict.find(c => c.ddID === this.state.CompanyIDSel)} name="CompanyIDSel" onChange={evt => this.chgDDFldVal(evt)} className="editInputDDShort" />*/}
                          {/*  </div>*/}
                          {/*</div>*/}
                          <br />
                          <h4>Hours Selection</h4>
                          <div className="editField">
                            <span className="editFieldLabel">Day of Week</span>
                            <div className="editDDPos">
                              <input type="checkbox" checked={this.state.SelectDayOfWeekH} name="SelectDayOfWeekH" onChange={evt => this.chgCheckboxVal(evt)} />
                              <DropDownList data={this.state.DayOfWeekDict} textField="ddName" dataItemKey="ddID" value={this.state.DayOfWeekDict.find(c => c.ddID === this.state.DayOfWeekHSel)} name="DayOfWeekHSel" onChange={evt => this.chgDDFldVal(evt)} className="editInputDDShort" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="editTabRight">
                        <div id="div1" className="editInside">
                          <h4>Select Process Action</h4>
                          <br />
                          <div className="editField">
                            <DropDownList data={this.state.ProcessCommands} textField="ddName" dataItemKey="ddID" value={this.state.ProcessCommands.find(c => c.ddID === this.state.ProcessCommand)} name="ProcessCommand" onChange={evt => this.chgCMDDDFldVal(evt)} className="editInputDD" style={{ marginRight: 30 }} />
                          </div>
                          <br />

                          <div className="editField">
                            <Button icon="refresh" color="primary" onClick={this.onPerformCommand}>Perform Command</Button>
                          </div>
                          <br />

                        </div>
                      </div>
                    </div>
                  </TabStripTab>
                  {/*------ Options ------*/}
                  <TabStripTab title="Option Groups">
                    <div className="editTab">
                      <div className="editTabLeftFixed">
                        <div id="div1" className="headerTitleWide">
                          <Tooltip position="top" anchorElement="pointer">
                            <div title="Click on Add-Line to Add New Option Item">
                              <h4>Product Option Groups &nbsp;</h4>
                            </div>
                          </Tooltip>
                          <h6>Click on an Option Group to display Option Items</h6>
                        </div>
                        <br />
                        <div id="div1" className="headerGrid">
                          <TGrid style={{ position: 'absolute',top: 0,height: '100%' }}
                            data={orderBy(filterBy(this.state.ProductOptionsGroups,this.state.ProductOptionGroupFilter),this.state.ProductOptionsGroupSort).slice(this.state.ProductOptionsGroupsSkip,this.state.ProductOptionsGroupsTake + this.state.ProductOptionsGroupsSkip)}
                            onItemChange={this.itemChangeProductOptionsGroup} resizable={true} selectedField="selected" editField="inEdit"
                            onRowClick={this.ProductOptionsGroupRowClick}
                            sortable={true} sort={this.state.ProductOptionsGroupSort} onSortChange={(e) => { this.setState({ ProductOptionsGroupSort: e.sort }); }}
                            skip={this.state.ProductOptionsGroupsSkip} take={this.state.ProductOptionsGroupsTake} total={this.state.ProductOptionsGroups.length} onPageChange={this.ProductOptionsGroupsPageChange} pageable={{ info: true,type: 'numeric',pageSizes: true,previousNext: true }} pageSize={6}>
                            <Column field="ProductOptionsGroupName" title="Product Options Groups" width='300px' editor="text" />
                            <Column field="IsRequired" cell={BoolCell} title="Required" width='90px' />
                            <Column field="AllowMultiple" cell={BoolCell} title="Multiple" width='90px' />
                            <Column field="MultipleCount" title="Count" width='100px' filter='numeric' />
                            <Column field="SortOrder" title="Sort" width='60px' filter='numeric' />
                            <Column field="IsRecordActive" cell={BoolCell} title="Active" width='70px' />
                            <Column cell={this.ProductOptionsGroupCommandCell} width="230px" />
                          </TGrid>
                        </div>
                      </div>
                    </div>
                  </TabStripTab>
                  <TabStripTab title="Schedule">
                    <div className="editTabWholeLow">
                      {/*<SchedControl TaskData={this.state.SchedulerData} LocationID={1} />  Note - SchedControl is defined in CommonControls */}
                      <SchedControl TaskData={this.state.SchedulerData} /> {/* Note - SchedControl is defined in CommonControls */}
                    </div>
                  </TabStripTab>
                  <TabStripTab title="Spreadsheet">
                    <div id="div1" className="editTabLeftNarrowTop">
                      <h4>Period Selection</h4>
                      <div className="editField">
                        <span className="editFieldLabel">Period(s) Back</span>
                        <NumericTextBox name="PeriodBack" value={this.state.PeriodBack} min={0} max={60} step={1} onChange={evt => this.chgFldValMon(evt)} width={100} className="NumTxtExtShortInput" />
                      </div>
                      <div id='div2' className='editFieldShort'>
                        <span className='editFieldLabel'>Period Type</span>
                        <div className='editDDPos'>
                          <DropDownList data={this.PeriodsFixedValues} textField='ddName' dataItemKey='ddID' value={this.PeriodsFixedValues.find(c => c.ddID === this.state.PeriodType)} name='PeriodType' onChange={evt => this.chgDDFldValMon(evt)} className='editInputDDSh' />
                        </div>
                      </div>
                    </div>
                    <div id="div1" className="editTabLeftNarrowTopR1">
                      <h4>Current Period</h4>
                      <div id="div2" className="editField">
                        <span className="editFieldLabel">Period Date</span>
                        <br />
                        <DateTimePicker value={new Date(this.state.PeriodStartDate)} name="TxDate" className="editDateInputShort" readOnly="{true}" />
                      </div>
                    </div>
                    <div id="div1" className="editTabLeftNarrowTopR2">
                      <h4>Locations</h4>
                      <div id='div2' className='editField'>
                        <span className='editFieldLabel'>Store Location</span>
                        <div className='editDDPos'>
                          <DropDownList data={this.state.StoreLocationsDict} textField='ddName' dataItemKey='ddID' value={this.state.StoreLocationsDict.find(c => c.ddID === this.state.StoreLocationID)} name='StoreLocationID' onChange={evt => this.chgDDFldVal(evt)} className='editInputDD' />
                        </div>
                      </div>
                      <div id="div2" className="editField">
                        <Switch checked={this.state.allLocations} name="allLocations" onChange={evt => this.chgCheckboxVal(evt)} />
                        <span className="editFieldLabel"> &nbsp; All Locations</span>
                      </div>
                    </div>
                    <div id="div1" className="editTabLeftNarrowTopR3">
                      <div className="editField">
                        <Button icon="tell-a-friend" className="medButton" onClick={this.loadSpreadsheet}>Load Spreadsheet</Button>
                      </div>
                    </div>
                    <div className="editTabWholeLowSpread">
                      <Spreadsheet ref={spreadsheet} style={{ width: "100%",height: 680,}} />
                    </div>
                  </TabStripTab>
                  <TabStripTab title="Report Viewer">
                    <div className="editTabWholeLow">
                      <TelerikReportViewer
                        ref={el => viewer = el}
                        reportServer={{ url: 'https://mytelerikreportserver:83/',username:'Michael.blanchet@gmail.com',password:'Seymour6#6'}}
                        reportSource={{report:'Samples/Dashboard.trdp',parameters: {} }}
                        viewerContainerStyle={{position:'absolute',left:'5px',ight:'5px',bottom:'5px',overflow:'hidden',clear:'both',fontFamily:'ms sans serif'}}
                        viewMode="INTERACTIVE"
                        scaleMode="SPECIFIC"
                        scale={1.0}
                        enableAccessibility={false} />
                      {/*<TelerikReportViewer
                        ref={el => viewer = el}
                        serviceUrl="http://localhost:59655/api/reports/"
                        //templateUrl="http://localhost:59655/api/reports/resources/templates/telerikReportViewerTemplate.html/"
                        reportSource={{
                          report: 'ReportBook.trbp',
                          parameters: {}
                        }}
                        viewerContainerStyle={{
                          position: 'absolute',
                          left: '5px',
                          right: '5px',
                          top: '40px',
                          bottom: '5px',
                          overflow: 'hidden',
                          clear: 'both',
                          fontFamily: 'ms sans serif'
                        }}
                        viewMode="INTERACTIVE"
                        scaleMode="SPECIFIC"
                        scale={1.0}
                        enableAccessibility={false} />*/}
                      <button id="refresh-button" onClick={() => viewer.refreshReport()}>Refresh</button>
                      <span>&nbsp; &nbsp;</span>
                      <button onClick={() => viewer.commands.print.exec()}>Print</button>
                    </div>
                  </TabStripTab>
                </TabStrip>
              </div>

              <Splitter style={{ height: '100%',border: 'none' }} panes={this.state.verticalPanes} orientation={'vertical'} onChange={this.onVerticalLayoutChange}>
                <div id="splitterRight" className="splitPaneContent" >  {/*onMouseDown={e => e.preventDefault()}*/}
                  {this.state.selectedTopTab === this.tabs.Notifications ?
                    <div className="editPane">  {/* Notifications */}
                      <div className="editTabWholeLow">
                        <div id="div1" className="editInside">
                          <h4>Message</h4>
                          <br />
                          <span className="editFieldLabel">Notification Message</span>
                          <div id="div2" className="editField">
                            <textarea value={this.state.NotificationMessage} name="NotificationMessage" onChange={evt => this.chgFldVal(evt)} className="editTAInputFull" />
                          </div>
                          <br />
                          <br />
                          <br />
                          <div id="div3" className="editField">
                            <span className="editFieldLabel">Notification Type</span>
                            <div className="editDDPos">
                              <DropDownList data={this.state.NotificationTypes} textField="ddName" dataItemKey="ddID" value={this.state.NotificationTypes.find(c => c.ddID === this.state.NotificationTypeID)} name="NotificationTypeID" onChange={evt => this.chgDDFldVal(evt)} className="editInputDD" />
                            </div>
                          </div>
                          <h4>Send To Information</h4>
                          <br />
                          <div id="div2" className="editField">
                            <span className="editFieldLabel">Store Location</span>
                            <div className="editDDPos">
                              <DropDownList data={this.state.StoreLocations} textField="ddName" dataItemKey="ddID" value={this.state.StoreLocations.find(c => c.ddID === this.state.StoreLocationID)} name="StoreLocationID" onChange={evt => this.chgDDFldVal(evt)} className="editInputDD" />
                            </div>
                          </div>
                          <div id="div2" className="editField">
                            <span className="editFieldLabel">Store Group</span>
                            <div className="editDDPos">
                              <DropDownList data={this.state.StoreGroups} textField="ddName" dataItemKey="ddID" value={this.state.StoreGroups.find(c => c.ddID === this.state.StoreGroupID)} name="StoreGroupID" onChange={evt => this.chgDDFldVal(evt)} className="editInputDD" />
                            </div>
                          </div>
                          {/*<div id="div2" className="editField">
                          <span className="editFieldLabel">Customer</span>
                          <div className="editDDPos">
                            <DropDownList data={this.state.Customers} textField="ddName" dataItemKey="ddID" value={this.state.Customers.find(c => c.ddID === this.state.CustomerID)} name="CustomerID" onChange={evt => this.chgDDFldVal(evt)} className="editInputDD" />
                          </div>
                        </div>*/}
                          <div id="div2" className="editField">
                            <span className="editFieldLabel">Notification Date</span>
                            <div className="editDDPos">
                              <DateTimePicker value={new Date(this.state.NotificationDateTime)} name="NotificationDateTime" onChange={evt => this.chgFldVal(evt)} className="editDateInput" />
                            </div>
                          </div>
                          <div id="div2" className="editField">
                            <span className="editFieldLabel">Expiry Date Time</span>
                            <div className="editDDPos">
                              <DateTimePicker value={new Date(this.state.ExpiryDateTime)} name="ExpiryDateTime" onChange={evt => this.chgFldVal(evt)} className="editDateInput" />
                            </div>
                          </div>
                          <div id='div2' className='editField'>
                            <span className='editFieldLabel'>Message Read &nbsp; &nbsp; </span>
                            <input type='checkbox' checked={this.state.MessageRead} name='MessageRead' onChange={evt => this.chgCheckboxVal(evt)} />
                          </div>
                        </div>
                      </div>
                    </div> : null}
                  {this.state.selectedTopTab === this.tabs.OptionGroups ?
                    <div className="editTab">
                      <div className="editTabLeftNarrow">
                        <div id="div1" className="editInside">
                          <div id="div1" className="headerTitleWide">
                            <Tooltip position="top" anchorElement="pointer">
                              <div title="Click on any Item in the Grid to Copy the Item to the Current Store">
                                <h4>Product Options for the Group: {this.state.ProductOptionsGroupName}&nbsp;</h4>
                              </div>
                            </Tooltip>
                            <Tooltip position="top" anchorElement="pointer">
                              <div title="Click on Add-Line Button to Add New Option Item">
                                <ButtonAddRow alt="" fill={this.state.ActiveGroupColor} id="GridAdd" title={`Add a New Grid Row for ${this.state.ProductOptionsGroupName}`} className="functionImage" onClick={this.enterInsertProductItemOption} />
                              </div>
                            </Tooltip>
                          </div>
                          <br />
                          <br />
                        </div>
                        <div id="div1" className="headerGrid">
                          <TGrid style={{ position: 'absolute',top: 0,height: '100%' }} data={orderBy(filterBy(this.state.ProductItemOptions,this.state.ProductItemOptionFilter),this.state.ProductItemOptionSort).slice(this.state.ProductItemOptionsSkip,this.state.ProductItemOptionsTake + this.state.ProductItemOptionsSkip)}
                            onItemChange={this.itemChangeProductItemOption} editField="inEdit"
                            onRowClick={this.ProductItemOptionRowClick} resizable={true}
                            sortable={true} sort={this.state.ProductItemOptionSort} onSortChange={(e) => { this.setState({ ProductItemOptionSort: e.sort }); }}
                            skip={this.state.ProductItemOptionsSkip} take={this.state.ProductItemOptionsTake} total={this.state.ProductItemOptions.length} onPageChange={this.ProductItemOptionsPageChange} pageable={{ info: true,type: 'numeric',pageSizes: true,previousNext: true }} pageSize={6}>
                            <Column field="ProductOptionName" title="Product Option Name" width='350px' editor="text" />
                            <Column field="Price" title="Item Price" cell={MoneyCell} width='170px' />
                            <Column field="SortOrder" title="Sort" width='70px' filter='numeric' />
                            <Column field="IsRecordActive" cell={BoolCell} title="Active" width='70px' />
                            <Column cell={this.ProductItemOptionCommandCell} width="260px" />
                          </TGrid>
                        </div>
                      </div>
                    </div> : null}
                  {this.state.selectedTopTab === this.tabs.Promotions ?
                    <div className="editPane">  {/* Promotions */}
                      <div className="editTabLeft">
                        <div id="div1" className="editInside">
                          <h4>Promotion Info</h4>
                          <div id='div2' className='editField'>
                            <span className='editFieldLabel'>Promotion Name</span>
                            <input value={this.state.StorePromotionName} name="StorePromotionName" onChange={evt => this.chgFldVal(evt)} className="editInput" />
                          </div>
                          <div id='div2' className='editField'>
                            <span className='editFieldLabel'>Promo Description</span>
                            <textarea value={this.state.PromotionDescription} name='PromotionDescription' onChange={evt => this.chgFldVal(evt)} className='editTAInput' />
                          </div>
                          <br />
                          <br />
                          <div id='div2' className='editField'>
                            <span className='editFieldLabel'>Store Location</span>
                            <div className='editDDPos'>
                              <DropDownList data={this.state.StoreLocationsDict} textField='ddName' dataItemKey='ddID' value={this.state.StoreLocationsDict.find(c => c.ddID === this.state.StoreLocationID)} name='StoreLocationID' onChange={evt => this.chgDDFldVal(evt)} className='editInputDD' />
                            </div>
                          </div>
                          <div id='div2' className='editField'>
                            <span className='editFieldLabel'>Promotion Type</span>
                            <div className='editDDPos'>
                              <DropDownList data={this.state.PromotionTypes} textField='ddName' dataItemKey='ddID' value={this.state.PromotionTypes.find(c => c.ddID === this.state.PromotionType)} name='PromotionType' onChange={evt => this.chgDDFldVal(evt)} className='editInputDD' />
                            </div>
                          </div>
                          <div id='div2' className='editField'>
                            <span className='editFieldLabel'>Discount Amount</span>
                            <input value={this.state.DiscountAmountS} name='DiscountAmount' onChange={evt => this.chgMoneyFldVal(evt)} onFocus={evt => this.focusMoneyFldVal(evt)} onBlur={evt => this.blurMoneyFldVal(evt)} className='editMoneyInput' />
                          </div>
                          <div id='div2' className='editField'>
                            <span className='editFieldLabel'>Discount Percentage</span>
                            <input value={this.state.DiscountPercentageS} name='DiscountPercentage' onChange={evt => this.chgPercentFldVal(evt)} onFocus={evt => this.focusPercentFldVal(evt)} onBlur={evt => this.blurPercentFldVal(evt)} className='editNumShortInput' />
                          </div>
                          <div id='div2' className='editField'>
                            <span className='editFieldLabel'>Promotion Price</span>
                            <input value={this.state.PromotionPriceS} name='PromotionPrice' onChange={evt => this.chgMoneyFldVal(evt)} onFocus={evt => this.focusMoneyFldVal(evt)} onBlur={evt => this.blurMoneyFldVal(evt)} className='editMoneyInput' />
                          </div>
                          <div id='div2' className='editField'>
                            <span className='editFieldLabel'>Active Promotion   </span>
                            <input type='checkbox' checked={this.state.PromotionIsActive} name='PromotionIsActive' onChange={evt => this.chgCheckboxVal(evt)} />
                          </div>
                        </div>
                      </div>
                      <div className="editTabRight">
                        <div id="div1" className="editInside">
                          <h4>Promotion Period</h4>
                          <div id='div2' className='editField'>
                            <span className='editFieldLabel'>Start Date/Time</span>
                            <div className='editDDPos'>
                              <DateTimePicker value={new Date(this.state.StartDateTime)} name='StartDateTime' onChange={evt => this.chgFldVal(evt)} className='editDateInput' readOnly={false} />
                            </div>
                          </div>
                          <div id='div2' className='editField'>
                            <span className='editFieldLabel'>Days For Discount</span>
                            <input value={this.state.DaysForDiscount} name='DaysForDiscount' onChange={evt => this.chgFldVal(evt)} onBlur={evt => this.blurDaysForDiscount(evt)} className='editNumShortInput' />
                          </div>
                          <div id='div2' className='editField'>
                            <span className='editFieldLabel'>End Date/Time</span>
                            <div className='editDDPos'>
                              <DateTimePicker value={new Date(this.state.EndDateTime)} name='EndDateTime' onChange={evt => this.chgFldVal(evt)} onBlur={evt => this.blurEndDate(evt)} className='editDateInput' readOnly={false} />
                            </div>
                          </div>
                          <h4>Apply Promotion To</h4>
                          <br />
                          {/*<div id='div2' className='editField'>
                          <span className='editFieldLabel'>Store Group</span>
                          <div className='editDDPos'>
                            <DropDownList data={this.state.StoreGroups} textField='ddName' dataItemKey='ddID' value={this.state.StoreGroups.find(c => c.ddID === this.state.StoreGroupID)} name='StoreGroupID' onChange={evt => this.chgDDFldVal(evt)} className='editInputDD' />
                          </div>
                        </div>  */}
                          <div id='div2' className='editField'>
                            <span className='editFieldLabel'>Store Item</span>
                            <div className='editDDPos'>
                              <DropDownList data={this.state.StoreItemsDict} textField='ddName' dataItemKey='ddID' value={this.state.StoreItemsDict.find(c => c.ddID === this.state.StoreItemID)} name='StoreItemID' onChange={evt => this.chgDDFldVal(evt)} className='editInputDD' />
                            </div>
                          </div>
                          <div id='div2' className='editField'>
                            <span className='editFieldLabel'>Product Category</span>
                            <div className='editDDPos'>
                              <DropDownList data={this.state.ProductItemCategories} textField='ddName' dataItemKey='ddID' value={this.state.ProductItemCategories.find(c => c.ddID === this.state.ProductItemCategoryID)} name='ProductItemCategoryID' onChange={evt => this.chgDDFldVal(evt)} className='editInputDD' />
                            </div>
                          </div>
                          <div id='div2' className='editField'>
                            <span className='editFieldLabel'>Product Group</span>
                            <div className='editDDPos'>
                              <DropDownList data={this.state.ProductItemGroups} textField='ddName' dataItemKey='ddID' value={this.state.ProductItemGroups.find(c => c.ddID === this.state.ProductItemGroupID)} name='ProductItemGroupID' onChange={evt => this.chgDDFldVal(evt)} className='editInputDD' />
                            </div>
                          </div>
                          <div id='div2' className='editField'>
                            <span className='editFieldLabel'>Product Item Type</span>
                            <div className='editDDPos'>
                              <DropDownList data={this.state.ProductItemTypes} textField='ddName' dataItemKey='ddID' value={this.state.ProductItemTypes.find(c => c.ddID === this.state.ProductItemTypeID)} name='ProductItemTypeID' onChange={evt => this.chgDDFldVal(evt)} className='editInputDD' />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> : null}
                  {this.state.selectedTopTab === this.tabs.ProcessActions ?
                    <div className="editPane">
                      <div>
                        <HelpAbout fill={SessionInfo.activeColor} alt="" id="ShowHelp" title="Show Help for Process Command" className="showActionHelp" onClick={evt => this.ShowActionHelp(this.state.ActionID)} />
                        {this.state.ActionID > 0 ?
                          <div className="actionName">{this.state.ActionName} ({this.state.ActionID})</div> : null}
                      </div>
                      <div className="editInsideSub1">
                        <label className="infoMessage"> &nbsp; {this.state.infoMessage}</label>
                        <br />
                        {SessionInfo.PermLevel >= 494 && this.state.detailMessage.length > 0 ?
                          <label className="detailMessage"> &nbsp;  &nbsp; {this.state.detailMessage} Perm: {SessionInfo.PermLevel}</label> : null}
                      </div>
                      {this.state.ActionID === ProcessCmd.FillItemGrid ?
                        <div className="editInsideSub2"> {/* FillItemGrid */}
                        </div> : null}
                      {this.state.ActionID === ProcessCmd.FillLocationGrid ?
                        <div className="editInsideSub2">  {/* FillLocationGrid */}
                        </div> : null}
                      {this.state.ActionID === ProcessCmd.FillHoursGrid ?
                        <div id="div4" className="editInsideSub2">  {/* FillHoursGrid */}
                        </div> : null}
                      {this.state.ActionID === ProcessCmd.CopyItemsToLocations ?
                        <div id="div4" className="editInsideSub2">  {/* CopyItemsToLocations */}
                        {/*  <h1>Copy Items XXXXXXXXX</h1>*/}
                        </div> : null}
                      {this.state.ActionID === ProcessCmd.UpdateLocationItems ?
                        <div id="div4" className="editInsideSub2">  { }
                        {/*  <h1>Update Items XXXXXXXXX</h1>*/}
                        </div> : null}
                      {this.state.ActionID === ProcessCmd.AdjustPrices ?
                        <div id="div4" className="editInsideSub2">  {/* AdjustPrices */}

                          <div id='div2' className='editField'>
                            <span className='editFieldLabel'>Adjust Percentage</span>
                            <input value={this.state.AdjustmentPercentageS} name="AdjustmentPercentage" onChange={evt => this.chgPercentFldVal(evt)} onFocus={evt => this.focusPercentFldVal(evt)} onBlur={evt => this.blurPercentFldVal(evt)} className="editNumShortInput" readOnly={false} />
                          </div>
                          <div id="div2" className="editField">
                            <span className="editFieldLabel">Adjust Price</span>
                            <input value={this.state.AdjustmentAmountS} name="AdjustmentAmount" onChange={evt => this.chgMoneyFldVal(evt)} onFocus={evt => this.focusMoneyFldVal(evt)} onBlur={evt => this.blurMoneyFldVal(evt)} className="editMoneyInput" readOnly={false} />
                          </div>

                        </div> : null}
                      {this.state.ActionID === ProcessCmd.SetPrices ?
                        <div id="div4" className="editInsideSub2">  {/* SetPrices */}
                          <div id="div2" className="editField">
                            <span className="editFieldLabel">Item Price</span>
                            <input value={this.state.ItemPriceS} name="ItemPrice" onChange={evt => this.chgMoneyFldVal(evt)} onFocus={evt => this.focusMoneyFldVal(evt)} onBlur={evt => this.blurMoneyFldVal(evt)} className="editMoneyInput" readOnly={false} />
                          </div>
                          <div id="div2" className="editField">
                            <span className="editFieldLabel">Promotion Price</span>
                            <input value={this.moneyFormat(this.state.PromotionPriceA)} name="PromotionPrice" onChange={evt => this.chgMoneyFldVal(evt)} onFocus={evt => this.focusMoneyFldVal(evt)} onBlur={evt => this.blurMoneyFldVal(evt)} className="editMoneyInput" readOnly={false} />
                          </div>
                        </div> : null}
                      {this.state.ActionID === ProcessCmd.SetPromotion ?
                        <div id="div4" className="editInsideSub2">  {/* SetPromotion */}
                        </div> : null}
                      {this.state.ActionID === ProcessCmd.AdjustHours ?
                        <div id="div4" className="editInsideSub2">  {/* AdjustHours */}
                          <div id='div2' className='editField'>
                            <span className='editFieldLabel'>Override Start</span>
                            <div className='editDDPos'>
                              <TimePicker value={new Date(this.state.OverrideStart)} name='OverrideStart' onChange={evt => this.chgFldVal(evt)} className='editDateInput' readOnly={false} />
                            </div>
                          </div>
                          <div id='div2' className='editField'>
                            <span className='editFieldLabel'>Override End</span>
                            <div className='editDDPos'>
                              <TimePicker value={new Date(this.state.OverrideEnd)} name='OverrideEnd' onChange={evt => this.chgFldVal(evt)} className='editDateInput' readOnly={false} />
                            </div>
                          </div>
                          <div className="editField">
                            <span className="editFieldLabel">Override Type</span>
                            <div className="editDDPos">
                              <DropDownList data={this.state.TimeOverrideTypes} textField="ddName" dataItemKey="ddID" value={this.state.TimeOverrideTypes.find(c => c.ddID === this.state.TimeOverrideTypeSel)} name="TimeOverrideTypeSel" onChange={evt => this.chgDDFldVal(evt)} className="editInputDDShort" />
                            </div>
                          </div>
                        </div> : null}
                      {this.state.ActionID === ProcessCmd.SetHours ?
                        <div id="div4" className="editInsideSub2">  {/* Set Hours for Selection */}
                          <div id='div2' className='editField'>
                            <span className='editFieldLabel'>Override Start</span>
                            <div className='editDDPos'>
                              <TimePicker value={new Date(this.state.StartTime)} name='StartTime' onChange={evt => this.chgFldVal(evt)} className='editDateInput' readOnly={false} />
                            </div>
                          </div>
                          <div id='div2' className='editField'>
                            <span className='editFieldLabel'>Override End</span>
                            <div className='editDDPos'>
                              <TimePicker value={new Date(this.state.EndTime)} name='EndTime' onChange={evt => this.chgFldVal(evt)} className='editDateInput' readOnly={false} />
                            </div>
                          </div>
                        </div> : null}

                    </div> : null}
                </div>
                <div id="splitterBottom">
                  {this.state.selectedTopTab === this.tabs.Promotions ?
                    <div className="editPane">  {/* Promotions */}
                      <div className="editTabLeft">
                        <div id="div1" className="editInside">
                          <div className="framePromoPic">
                            <Avatar rounded='large' size='large' type='image' className='avatarPromoPic'>
                              <img src={this.state.PromotionPhotoURL} alt="" height={200} width={400} style={{ objectFit: 'fill',}} />
                            </Avatar>
                          </div>
                          <div className="framePromoPicUpload">
                            {/*<ExternalDropZone*/}
                            {/*  uploadRef={uploadPromoRef}*/}
                            {/*  customHint={<span>Drop the Promotion Image Here</span>}*/}
                            {/*  customNote={<span>The Image should have an aspect ratio of 4:3</span>}*/}
                            {/*  style={{backgroundColor: "antiquewhite", height: "10px", width: "400px"}}*/}
                            {/*/>*/}
                            <div style={{ position: "relative",top: "0px",width: "400px",border: "solid 2px grey" }}>
                              <Upload
                                ref={uploadPromoRef}
                                batch={false}
                                multiple={true}
                                files={this.state.files}
                                onAdd={this.addPromoImage}
                                onRemove={this.onRemove}
                                onStatusChange={this.onStatusChange}
                                saveUrl={null}
                                defaultFiles={[]}
                                withCredentials={false}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> : null}

                  {this.state.selectedTopTab === this.tabs.ProcessActions ?
                    <div className="editPane">
                      {(this.state.ActionID === ProcessCmd.FillItemGrid || this.state.ActionID === ProcessCmd.CopyItemsToLocations || this.state.ActionID === ProcessCmd.UpdateLocationItems) ?
                        <div className="editPane"> {/* FillItemGrid */}
                          <ExcelExport data={this.state.StoreItems} ref={(exporter) => { this._export = exporter; }}>
                            <TGrid style={{ position: 'absolute',top: 0,height: '100%' }} data={orderBy(filterBy(this.state.StoreItems,this.state.itemFilter),this.state.itemSort).slice(this.state.storeItemsSkip,this.state.storeItemsTake + this.state.storeItemsSkip)}
                              skip={this.state.storeItemsSkip} take={this.state.storeItemsTake} total={this.state.StoreItems.length} onPageChange={this.storeItemsPageChange}
                              resizable={true} sortable={true} sort={this.state.itemSort} onSortChange={(e) => { this.setState({ itemSort: e.sort }); }}
                              pageable={{ info: true,type: 'numeric',pageSizes: true,previousNext: true }} pageSize={8}>
                              <Column field="ItemName" title="Item Name" width='200px' />
                              <Column field="ItemDescription" title="Description" width='300px' />
                              <Column field="ProductItemCategoryID" title="Category" cell={ProductItemCategoryDropDownCell} width='110px' />
                              <Column field="ProductItemGroupID" title="Group" cell={ProductItemGroupDropDownCell} width='110px' />
                              <Column field="ProductItemTypeID" title="Item Type" cell={ProductTypeDropDownCell} width='110px' />
                              <Column field="ItemPrice" title="Item Price" filter='numeric' cell={MoneyCell} width='100px' />
                              <Column field="Image" cell={ImageCell} title="." width='50px' />
                              {/*  <Column cell={this.StoreItemCommandCell} width="260px" />*/}
                            </TGrid>
                          </ExcelExport>
                        </div> : null}
                      {this.state.ActionID === ProcessCmd.FillLocationGrid ?
                        <div className="editPane">  {/* FillLocationGrid */}
                          <ExcelExport data={this.state.StoreItems} ref={(exporter) => { this._export = exporter; }}>
                            <TGrid style={{ position: 'absolute',top: 0,height: '100%' }} data={this.state.StoreLocationsData} selectedField="selected" resizable={true} sortable={true} pageable={true} pageSize={20}>
                              <Column field="StoreName" title="Store Name" width="200px" />
                              <Column field="CityName" title="City" width="140px" />
                              <Column field="Address" title="Address" width="300px" />
                            </TGrid>
                          </ExcelExport>
                        </div> : null}
                      {this.state.ActionID === ProcessCmd.FillHoursGrid ?
                        <div id="div4" className="editInsideTab">  {/* FillHoursGrid */}
                          <TGrid style={{ position: 'absolute',top: 0,height: '100%' }} data={this.state.HoursOfOperation} resizable={true} pageSize={8}>
                            <Column field="selected" width='1px' headerSelectionValue={this.state.HoursOfOperation.findIndex(dataItem => dataItem.selected === false) === -1} />
                            <Column field="StoreLocationID" title="Store Location" cell={StoreLocationDropDownCell} width='130px' />
                            <Column field="DayOfWeek" title="Day of Week" cell={DayOfWeekDropDownCell} width='130px' />
                            <Column field="StartTime" title="Start Time" cell={TimePickerCell} width='150px' />
                            <Column field="EndTime" title="End Time" cell={TimePickerCell} width='150px' />
                            <Column field="TimeOverrideType" title="Time Override Type" cell={TimeOverrideDropDownCell} width='130px' />
                            <Column field="OverrideStart" title="Override Start" cell={TimePickerCell} width='150px' />
                            <Column field="OoverrideEnd" title="Override End" cell={TimePickerCell} width='150px' />
                            <Column cell={this.HOOItemCommandCell} width="180px" />
                          </TGrid>
                        </div> : null}
                      {this.state.ActionID === ProcessCmd.CopyItemsToLocations ?
                        <div id="div4" className="editInsideTab">  {/* CopyItemsToLocations */}
                        </div> : null}
                      {this.state.ActionID === ProcessCmd.AdjustPrices ?
                        <div id="div4" className="editInsideTab">  {/* AdjustPrices */}
                          <div className="editPane"> {/* FillItemGrid */}
                            <ExcelExport data={this.state.StoreItems} ref={(exporter) => { this._export = exporter; }}>
                              <TGrid style={{ position: 'absolute',top: 0,height: '100%' }} data={orderBy(filterBy(this.state.StoreItems,this.state.itemFilter),this.state.itemSort).slice(this.state.storeItemsSkip,this.state.storeItemsTake + this.state.storeItemsSkip)}
                                skip={this.state.storeItemsSkip} take={this.state.storeItemsTake} total={this.state.StoreItems.length} onPageChange={this.storeItemsPageChange}
                                resizable={true} sortable={true} sort={this.state.itemSort} onSortChange={(e) => { this.setState({ itemSort: e.sort }); }}
                                pageable={{ info: true,type: 'numeric',pageSizes: true,previousNext: true }} pageSize={8}>
                                <Column field="ItemName" title="Item Name" width='200px' />
                                <Column field="ItemDescription" title="Description" width='300px' />
                                <Column field="ProductItemCategoryID" title="Category" cell={ProductItemCategoryDropDownCell} width='110px' />
                                <Column field="ProductItemGroupID" title="Group" cell={ProductItemGroupDropDownCell} width='110px' />
                                <Column field="ProductItemTypeID" title="Item Type" cell={ProductTypeDropDownCell} width='110px' />
                                <Column field="ItemPrice" title="Item Price" filter='numeric' cell={MoneyCell} width='100px' />
                                <Column field="Image" cell={ImageCell} title="." width='50px' />
                                {/*  <Column cell={this.StoreItemCommandCell} width="260px" />*/}
                              </TGrid>
                            </ExcelExport>
                          </div> : null}
                        </div> : null}
                      {this.state.ActionID === ProcessCmd.SetPrices ?
                        <div id="div4" className="editInsideTab">  {/* SetPrices */}
                          <div className="editPane"> {/* FillItemGrid */}
                            <ExcelExport data={this.state.StoreItems} ref={(exporter) => { this._export = exporter; }}>
                              <TGrid style={{ position: 'absolute',top: 0,height: '100%' }} data={orderBy(filterBy(this.state.StoreItems,this.state.itemFilter),this.state.itemSort).slice(this.state.storeItemsSkip,this.state.storeItemsTake + this.state.storeItemsSkip)}
                                skip={this.state.storeItemsSkip} take={this.state.storeItemsTake} total={this.state.StoreItems.length} onPageChange={this.storeItemsPageChange}
                                resizable={true} sortable={true} sort={this.state.itemSort} onSortChange={(e) => { this.setState({ itemSort: e.sort }); }}
                                pageable={{ info: true,type: 'numeric',pageSizes: true,previousNext: true }} pageSize={8}>
                                <Column field="ItemName" title="Item Name" width='200px' />
                                <Column field="ItemDescription" title="Description" width='300px' />
                                <Column field="ProductItemCategoryID" title="Category" cell={ProductItemCategoryDropDownCell} width='110px' />
                                <Column field="ProductItemGroupID" title="Group" cell={ProductItemGroupDropDownCell} width='110px' />
                                <Column field="ProductItemTypeID" title="Item Type" cell={ProductTypeDropDownCell} width='110px' />
                                <Column field="ItemPrice" title="Item Price" filter='numeric' cell={MoneyCell} width='100px' />
                                <Column field="Image" cell={ImageCell} title="." width='50px' />
                                {/*  <Column cell={this.StoreItemCommandCell} width="260px" />*/}
                              </TGrid>
                            </ExcelExport>
                          </div> : null}
                        </div> : null}
                      {this.state.ActionID === ProcessCmd.SetPromotion ?
                        <div id="div4" className="editInsideTab">  {/* SetPromotion */}
                        </div> : null}
                      {this.state.ActionID === ProcessCmd.AdjustHours ?
                        <div id="div4" className="editInsideTab">  {/* AdjustHours */}
                          <TGrid style={{ position: 'absolute',top: 0,height: '100%' }} data={this.state.HoursOfOperation} resizable={true} pageSize={8}>
                            <Column field="selected" width='1px' headerSelectionValue={this.state.HoursOfOperation.findIndex(dataItem => dataItem.selected === false) === -1} />
                            <Column field="StoreLocationID" title="Store Location" cell={StoreLocationDropDownCell} width='130px' />
                            <Column field="DayOfWeek" title="Day of Week" cell={DayOfWeekDropDownCell} width='130px' />
                            <Column field="StartTime" title="Start Time" cell={TimePickerCell} width='150px' />
                            <Column field="EndTime" title="End Time" cell={TimePickerCell} width='150px' />
                            <Column field="TimeOverrideType" title="Time Override Type" cell={TimeOverrideDropDownCell} width='130px' />
                            <Column field="OverrideStart" title="Override Start" cell={TimePickerCell} width='150px' />
                            <Column field="OoverrideEnd" title="Override End" cell={TimePickerCell} width='150px' />
                            <Column cell={this.HOOItemCommandCell} width="180px" />
                          </TGrid>
                        </div> : null}
                      {this.state.ActionID === ProcessCmd.SetHours ?
                        <div id="div4" className="editInsideTab">  {/* Set Hours for Selection */}
                          <TGrid style={{ position: 'absolute',top: 0,height: '100%' }} data={this.state.HoursOfOperation} resizable={true} pageSize={8}>
                            <Column field="selected" width='1px' headerSelectionValue={this.state.HoursOfOperation.findIndex(dataItem => dataItem.selected === false) === -1} />
                            <Column field="StoreLocationID" title="Store Location" cell={StoreLocationDropDownCell} width='130px' />
                            <Column field="DayOfWeek" title="Day of Week" cell={DayOfWeekDropDownCell} width='130px' />
                            <Column field="StartTime" title="Start Time" cell={TimePickerCell} width='150px' />
                            <Column field="EndTime" title="End Time" cell={TimePickerCell} width='150px' />
                            <Column field="TimeOverrideType" title="Time Override Type" cell={TimeOverrideDropDownCell} width='130px' />
                            <Column field="OverrideStart" title="Override Start" cell={TimePickerCell} width='150px' />
                            <Column field="OoverrideEnd" title="Override End" cell={TimePickerCell} width='150px' />
                            <Column cell={this.HOOItemCommandCell} width="180px" />
                          </TGrid>
                        </div> : null}

                    </div> : null}

                  {this.state.selectedTopTab === this.tabs.OptionGroups ?
                    <div className="editPane">  {/* MainItems for Option Items */}
                      <div className="editTabLeftNarrow">
                        <div id="div1" className="editInside">
                          <div id="div1" className="headerTitleWide">
                            <Tooltip position="top" anchorElement="pointer">
                              <div title="Click on any Item in the Grid to Copy the Item to the Set of Options">
                                <div id="searchBtnDiv">
                                </div>
                                <h4>Main Item List</h4>
                              </div>
                            </Tooltip>
                            <h6>Click on Item to Copy the Item to the Set of Options
                              <ButtonSearch alt="" fill={SessionInfo.activeColor} id="ClearSearch" title={`Search the Main Items for a Product`} className="functionImage" onClick={this.setSearchButton} />
                            </h6>
                          </div>
                          <br />
                          <br />
                          <br />
                          <div id="div1" className="headerGrid">
                            <TGrid style={{ position: 'absolute',top: 0,height: '100%' }} data={this.state.MainStoreItems.slice(this.state.mainStoreItemsSkip,this.state.mainStoreItemsTake + this.state.mainStoreItemsSkip)} selectedField="selected" sortable={{ allowUnsort: true,mode: 'multiple' }}
                              skip={this.state.mainStoreItemsSkip} take={this.state.mainStoreItemsTake} total={this.state.MainStoreItems.length} onPageChange={this.mainStoreItemsPageChange}
                              onRowClick={this.mainStoreItemClick} selectedField="selected" resizable={true} sort={this.state.sortMainStoreItems} onSortChange={(event) => { this.setState({ MainStoreItems: this.getMainProductItemsSort(event.sort),sortMainStoreItems: event.sort }); }}
                              pageable={{ info: true,type: 'numeric',pageSizes: true,previousNext: true }}
                              pageSize={18}>
                              <Column field="selected" width="0px" headerSelectionValue={this.state.MainStoreItems.findIndex(dataItem => dataItem.selected === false) === -1} />
                              <Column field="ItemName" title="Item Name" width='200px' />
                              <Column field="ItemDescription" title="Description" width='450px' />
                              <Column field="ItemPrice" title="Price" cell={MoneyCell} width='120px' />
                            </TGrid>
                          </div>
                        </div>
                      </div>
                    </div> : null}
                </div>
              </Splitter>
            </Splitter>
          </div>
          <Popup offset={this.helpListPopupOffset} show={this.state.showHelpListPopup} popupClass={'popupHelp'} >
            <div id="helpBox">
              <div className="helpList">
                <ListView data={this.state.HelpPages} item={this.HelpPageFormat} header={this.HelpPageHeader} footer={this.HelpPageFooter} />
              </div>
              <br />
            </div>
          </Popup>
          <Popup offset={this.helpDetailOffset} show={this.state.showHelpDetail} popupClass={'popupHelpDetail'} onClick={this.closeHelpTime}>
            <div className="helpDetail" id="notifyBoxPU" dangerouslySetInnerHTML={{ __html: this.state.helpDetail }} onClick={this.closeHelpTime}></div>
          </Popup>
          {SessionInfo.displayFooter === true ?
            <PageFooter L1='Home' /> : null}
        </div >
      </div >
    );
  }
}

export default withRouter(ManagerPage)