//----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// CheckoutManager - Checkout Application Manager - InteractSuite
//                   See appVersion below for current version - Updated Main Version: 1.04 - 23Jan25
//----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// Access omAPI at ucsinventory.com  - see URI in CommonCode
//----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// Development Notes
// Note - for Photo Management: https://react-photo-album.com/
//----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// Problem resolution Notes
//----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ./src/images / search.svg(./node_modules/@svgr/webpack/lib ? -prettier, -svgo!./ src / images / search.svg)
// C: \checkout\checkoutmgr\node_modules\jsdom\lib\api.js: 139
// referrer: req.getHeader("referer") ?? undefined
//  SyntaxError: Unexpected token '?'
// one solution - remove " ?? undefined" in api.js - updated Node to 20.3.0 - node --version  or nvm current
// replace jsdom with backlevel version  and   replace parse5 with backlevel version  and then  npm audit fix --force
//--------------------------------------
//----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
import React, { useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate, Link } from 'react-router-dom' // Superset of react-router
import './optiview9.css';                                 
import './App.css';
import AppMain from './AppMain'
import AboutPage from './AboutPage'
import SettingsPage from './SettingsPage'
import StoreLocationDetail from './StoreLocationDetail'
import OrderManagement from './OrderManagement'
import KitchenManagement from './KitchenManagement'
import StoreItemDetail from './StoreItemDetail' 
import CustomerProfile from './CustomerProfile'
import ManagerPage from './ManagerPage' 
import ContactUs from './ContactUs'
import Confirmation from './Confirmation'
import Login from './Login'             
// Common Key Repository for the Session along with Common variables                   
export const SessionInfo = {
  session: '',
  sequenceid: 100001,
  logonName: '',
  message: '',
  pathname: '',
  search: '',
  hash: '',
  URI: '',
  href: '',
  hostname: '',
  protocol: '',
  token: '',
  props: undefined,
  keyLogon: false,
  gotoAutoPage: '',
  gotoTab: 0,
  hasCompanyCode: false,
  registrationCode: "AAAA", // In EntityConfiguration  
  AppProductID: 270,
  AppSiteID: 2000008,
}

export default class App extends React.Component {
  constructor(props) {                                  
    super(props);
    console.log(`Constructor URL Props: ${JSON.stringify(props)}`);
    console.log(`Constructor pathname: ${window.location.pathname}`);
    console.log(`Constructor search: ${window.location.search}`);
    console.log(`Constructor hash: ${window.location.hash}`);
    // href, hostname, protocol, port, assign() - will assign a new document 
    SessionInfo.href = window.location.href;
    SessionInfo.hostname = window.location.hostname;
    SessionInfo.protocol = window.location.protocol;
    if (window.location.pathname !== "/AppMain") {
      window.location.pathname = "/AppMain";
      console.log(`Checkout Manager - new pathname: ${window.location.pathname}`);
    }
    SessionInfo.pathname = window.location.pathname;
    SessionInfo.host = window.location.host;
    SessionInfo.search = window.location.search;
    SessionInfo.hash = window.location.hash;
    SessionInfo.token = '';
    if (SessionInfo.hash === "#register10002")
      SessionInfo.isConfirmationOnly = true;
    else if (SessionInfo.hash === "#token20001")
      SessionInfo.token = window.location.search;
    console.log(`Constructor Props: ${JSON.stringify(props)}`);
    //console.log(`Constructor location: ${JSON.stringify(props.location)}`);
    if (this.props.history) {
      console.log(`History: ${JSON.stringify(this.props.history)}`);
      //console.log(`location: ${JSON.stringify(this.props.hisy.location)}`); // changed 26Oct21
      console.log(`location: ${JSON.stringify(this.props.history.location)}`);
      console.log(`search: ${JSON.stringify(this.props.history.location.search)}`);
      this.ConfirmationStr = this.props.history.location.search;
    }
    SessionInfo.session = '';
    SessionInfo.sequenceid = 100001;
    SessionInfo.StartDate = new Date();
    SessionInfo.timeOffset = SessionInfo.StartDate.getTimezoneOffset(); // DefaultPST
    console.log(`StartDate: ${SessionInfo.StartDate}, Offset: ${SessionInfo.timeOffset}`);
    SessionInfo.traceDebug = true;
    // Package update: npm update --legacy-peer-deps
    //---- Debug/Production -------------------------------------------------------------------
    SessionInfo.debug = false; // Note - debug=true connects to local server - false to 137.135.60.70
    SessionInfo.debugProd = SessionInfo.debug ? "D" : "P";
    SessionInfo.appVersion = 1.719;  // January 22, 2025 - Telerik 9.3.1
    //SessionInfo.appVersion = 1.718;  // January 10, 2025 - Fix StoreItem Entry, Hours of Operation
    //SessionInfo.appVersion = 1.717;  // December 10, 2024 - Telerik 9.1.0 - fix refresh items
    //SessionInfo.appVersion = 1.716;  // November 15, 2024 - Telerik 9.0.0 - fix for dropdowns props.name
    //SessionInfo.appVersion = 1.715;  // October 24, 2024 - Telerik 8.5.0 
    //SessionInfo.appVersion = 1.714;  // October 3, 2024 - Telerik 8.4.0 - bug fixes
    //SessionInfo.appVersion = 1.713;  // August 20, 2024 - Telerik 8.2.2 - bug fixes
    //SessionInfo.appVersion = 1.712;  // August 20, 2024 - Telerik 8.2.2 - bug fixes
    //SessionInfo.appVersion = 1.711;  // August 1, 2024 - Support for 8 Menus  
    //SessionInfo.appVersion = 1.699;  // July 22, 2024 - HoursOfOperation, Add Location  
    //SessionInfo.appVersion = 1.698;  // July 17, 2024 - Bug Fixes   
    //SessionInfo.appVersion = 1.697;  // July 5, 2024 - Telerik 8.1.1, OptiView9   
    //SessionInfo.appVersion = 1.696;  // June 19, 2024 - Checkout Control
    //SessionInfo.appVersion = 1.695;  // June 14, 2024 - Bug fixes
    //SessionInfo.appVersion = 1.694;  // June 6, 2024 - Additional Colors, Formats
    //SessionInfo.appVersion = 1.693;  // June 5, 2024 - Branding Color, Additional Controls
    //SessionInfo.appVersion = 1.692;  // May 30, 2024 - Additional Fonts Added
    //SessionInfo.appVersion = 1.691;  // May 22, 2024 - Optiview8.css, Format updates
    //SessionInfo.appVersion = 1.689;  // May 17, 2024 - Upgrade Telerik 8.0.0 (Telerik labels problem) - Options setup
    //SessionInfo.appVersion = 1.688;  // May 10, 2024 - Bug fixes, multi-menu updates, refresh all store items
    //SessionInfo.appVersion = 1.687;  // May 7, 2024 - Update logon dict - add domain name
    //SessionInfo.appVersion = 1.686;  // April 25, 2024 - Create logon token for user in Accounts
    //SessionInfo.appVersion = 1.685;  // April 15, 2024 - Update to 7.4.0 Telerik
    //SessionInfo.appVersion = 1.684;  // April 8, 2024 - Support for Inventory management of items
    //SessionInfo.appVersion = 1.683;  // April 1, 2024
    //SessionInfo.appVersion = 1.682;  // March 22, 2024 - Resolution of Permissioning Issues - Change password - Add in Developer control
    //SessionInfo.appVersion = 1.681;  // March 19, 2024 - Branding - Font Type and Font Size with Sample for InteractMenu - Telerik 7.3.0 
    //SessionInfo.appVersion = 1.679;  // March 11, 2024 - Configuration Fix 
    //SessionInfo.appVersion = 1.678;  // March 7, 2024 - Bug Fixes 
    //SessionInfo.appVersion = 1.678;  // March 7, 2024 - Auto Save Button on Change, Convert Encoded to switches 
    //SessionInfo.appVersion = 1.677;  // March 5, 2024 - Manage ApplicationTypeID, entityCode 
    //SessionInfo.appVersion = 1.676;  // February 29, 2024 - Telerik Update 7.2.3 
    //SessionInfo.appVersion = 1.675;  // February 2, 2024 - Report Display
    //SessionInfo.appVersion = 1.674;  // January 26, 2024 - phone number, search fixes 
    //SessionInfo.appVersion = 1.673;  // January 19, 2024 - Telerik Update 7.0.2  
    //SessionInfo.appVersion = 1.672;  // December 13, 2023 - Telerik Update 7.0.1 
    //SessionInfo.appVersion = 1.671;  // December 12, 2023 - Delivery, Pickup 
    //SessionInfo.appVersion = 1.663;  // November 24, 2023 - Reservations, Table Wait, Table Management, Order Type                        
    //SessionInfo.appVersion = 1.662;  // November 15, 2023 - Bug fixes, logon for Fogo
    //SessionInfo.appVersion = 1.661;  // November 9, 2023 - Update for Demo - Options Layout, Timer on Kitchen
    //SessionInfo.appVersion = 1.66;  // November 7, 2023 - Update to Kendo 6.1.0 - Implement Store Dashboard, start of Spreadsheet load
    //SessionInfo.appVersion = 1.65;  // October 16, 2023 - Sales summary and Dashboard activation
    //SessionInfo.appVersion = 1.64;  // October 4, 2023 - Test code for TCPIP OM transer in conjuction with updated OMAPI
    //SessionInfo.appVersion = 1.63;  // October 3, 2023 - Kitchen Display automatic update of orders based on changing status
    //SessionInfo.appVersion = 1.62;  // September 29, 2023 - Manager Processes - Clean up processing and UI, Help Formatting, Update Telerik 5.19.0
    //SessionInfo.appVersion = 1.61;  // September 21, 2023 - Manager Processes - Clean up processing and UI, Help Formatting, Update Telerik 5.18.0
    //SessionInfo.appVersion = 1.59;  // September 15, 2023 - Promotions input, Store Items Dietary                                
    //SessionInfo.appVersion = 1.58;  // August 31, 2023 - Menu Control and Variable Pricing
    //SessionInfo.appVersion = 1.57;  // August 21, 2023 - Demo Test - Telerik Updated - 5.16.1 - and React Updated - 18.2.0
    //SessionInfo.appVersion = 1.56;  // August 3, 2023 - Cleanup code after demo 
    //SessionInfo.appVersion = 1.55;  // July 27, 2023 - Resolve Safari problem with navigator.userAgentData.platform 
    //SessionInfo.appVersion = 1.54;  // July 25, 2023 - Further Fogo Update - Bug Fixes
    //SessionInfo.appVersion = 1.53;  // July 23, 2023 - Clone AppEntity, Fogo Update - Simplify Input - Double Clear, 5 Menu Options
    //SessionInfo.appVersion = 1.52;  // July 14, 2023 - Table Layout using Drawing Control & SVG
    //SessionInfo.appVersion = 1.51;  // July 7, 2023 - Kitchen Management - Workflow - Submitted, Active, Ready
    //SessionInfo.appVersion = 1.49;  // June 30, 2023 - Kitchen Management - Workflow - Submitted, Active, Ready
    //SessionInfo.appVersion = 1.48;  // June 23, 2023 - Kitchen Management - 3 Columns - Submitted, InProgress, Ready
    //SessionInfo.appVersion = 1.47;  // June 12, 2023 - Product Option Fixes - Update Telerik 5.14.1
    //SessionInfo.appVersion = 1.46;  // June 5, 2023 - Product Option Fixes
    //SessionInfo.appVersion = 1.45;  // May 18, 2023 - Registration Fix
    //SessionInfo.appVersion = 1.44;  // May 16, 2023 - Cleanup and prep for demo
    //SessionInfo.appVersion = 1.43;  // May 12, 2023 - Cleanup and prep for demo
    //SessionInfo.appVersion = 1.42;  // May 5, 2023 - Table Management and walk-in Reservations
    //SessionInfo.appVersion = 1.41;  // May 1, 2023 - Update to 5.13.1 Telerik, Fix promotions
    //SessionInfo.appVersion = 1.39;  // April 28, 2023 - Messages, Support for Landing Page and Location Page
    //SessionInfo.appVersion = 1.38;  // April 14, 2023 - Update Current Items, Adjust search, Heading Cleanup - responsive web, Help, Accounts & permissions, 
    //SessionInfo.appVersion = 1.37;  // April 7, 2023 - Roles and Permissions, Logon
    //SessionInfo.appVersion = 1.36;  // March 31, 2023 - Telerik Update 5.12.0, react router 6.9.0, optiview6.css - Remove MaterialUI! replace login & register
    //SessionInfo.appVersion = 1.34/1.35;  // March 22, 2023 - Demo Version bug fixes
    //SessionInfo.appVersion = 1.33;  // March 17, 2023 - Menu customization
    //SessionInfo.appVersion = 1.32;  // March 15, 2023 - Setup extensions for self provisioning
    //SessionInfo.appVersion = 1.31;  // March 3, 2023 - Setup extensions for self provisioning
    //SessionInfo.appVersion = 1.29;  // February 28, 2023 - Buffer issue - Remove sjcl which was causing a polyfill issue
    //SessionInfo.appVersion = 1.28;  // February 17, 2023 - Buffer issue - Restore January 27th node_modules + current @Progress
    //SessionInfo.appVersion = 1.27;  // February 16, 2023 - Update to version 5.11 of Telerik - Settings
    //SessionInfo.appVersion = 1.26;  // February 10, 2023 - TimePicker, Time Entry, Logoff on Error 
    //SessionInfo.appVersion = 1.25;  // February 3, 2023 - Kitchen Orders 
    //SessionInfo.appVersion = 1.24;  // January 26, 2023 - Kitchen Orders 
    //SessionInfo.appVersion = 1.23;  // January 26, 2023 - Kitchen Orders 
    //SessionInfo.appVersion = 1.23;  // January 16, 2023 - Kitchen and Order Management
    //SessionInfo.appVersion = 1.22;  // December 16, 2022 - Cleanup of code
    //SessionInfo.appVersion = 1.21;  // December 5, 2022 - Reference InteractMenu.com in Iframe demo
    //SessionInfo.appVersion = 1.19;  // November 22, 2022 - Update Management Functions
    //SessionInfo.appVersion = 1.18;  // November 21, 2022 - Update Telerik to 5.9.0
    //SessionInfo.appVersion = 1.17;  // November 18, 2022 - Update Telerik to 5.9.0
    //SessionInfo.appVersion = 1.16;  // November 18, 2022 - Replacement of all image upload, general cleanup, location QR Code - unable to update to 5.9.0
    //SessionInfo.appVersion = 1.15;  // October 25, 2022 - Telerik update to 5.8.0 - Update all libraries - remove style.css
    //SessionInfo.appVersion = 1.14;  // September 21, 2022 - Telerik update
    //SessionInfo.appVersion = 1.13;  // Aug 28, 2022 - Telerik update, Sunday problem fixed
    //SessionInfo.appVersion = 1.12;  // Aug 1, 2022 - Telerik upload
    //SessionInfo.appVersion = 1.11;  // July 29, 2022 - Update Telerik (5.5.0) and latest libraries and update style sheet
    //SessionInfo.appVersion = 1.02;  // March 3, 2022 - Bug fixes with images, etc.
    //SessionInfo.appVersion = 1.01;  // February 22, 2022 - Bug fixes after Telerik update
    //SessionInfo.appVersion = 0.99;  // February 2, 2022 - Update to latest Telerik 5.0,1
    //SessionInfo.appVersion = 0.98;  // November 27, 2021 - Update to latest Telerik 4.12.0
    //SessionInfo.appVersion = 0.97;  // October 26, 2021 - Token Logon
    //SessionInfo.appVersion = 0.96;  // October 22, 2021 - Include IFrame
    //SessionInfo.appVersion = 0.95;  // October 21, 2021 - Scheduler Functionality - Switch to InteractSuite
    //SessionInfo.appVersion = 0.94;  // October 8, 2021 - Scheduler Functionality
    //SessionInfo.appVersion = 0.93;  // September 28, 2021 - Manager Functionality
    //SessionInfo.appVersion = 0.92;  // August 24, 2021 - Manager Functionality
    //SessionInfo.appVersion = 0.91;  // August 13, 2021 - Telerik Update to 4.8.0 - Manager Functionality
    //SessionInfo.appVersion = 0.89;  // August 7, 2021 Clean up & Manager Commands
    //SessionInfo.appVersion = 0.88;  // July 29, Process Commands
    //SessionInfo.appVersion = 0.87;  // July 16, 2021 Related Items, Combos, Search, Kitchen Order Layout
    //SessionInfo.appVersion = 0.86;  // July 14, 2021 Related Items, Combos, Search
    //SessionInfo.appVersion = 0.85;  // July 10, 2021 Related Items, Combos
    //SessionInfo.appVersion = 0.83;  // July 7, 2021 Restructuring, Related Items, Kitchen Management 
    //SessionInfo.appVersion = 0.82;  // June 24, 2021 Telerik Upgrade - Support for Related products, Combo Products and Promotions   
    //SessionInfo.appVersion = 0.81;  // June 18, 2021 Bug Fixes, Registration
    //SessionInfo.appVersion = 0.79;  // June 15, 2021 Bug Fixes, Registration
    //SessionInfo.appVersion = 0.78;  // June 12, 2021 Bug Fixes
    //SessionInfo.appVersion = 0.77;  // May 30, 2021 Header Improvements, Bug Fixes
    //SessionInfo.appVersion = 0.76;  // March 29, 2021 Kitchen Manager Design
    //SessionInfo.appVersion = 0.75;  // March 23, 2021 Kitchen Manager Design
    //SessionInfo.appVersion = 0.74;  // March 15, 2021 Kitchen TileView
    //SessionInfo.appVersion = 0.73;  // March 4, 2021 - Search Issues, Amanda Changes
    //SessionInfo.appVersion = 0.72;  // Feb 19, 2021 - Grid Highlight Selection, Cleanup Logic, Remove Header Selection
    //SessionInfo.appVersion = 0.71;  // Feb 16, 2021 - Sales Information, Kitchen
    //SessionInfo.appVersion = 0.69;  // Feb 05, 2021 - Bug Fixes, Search, Format Changes
    //SessionInfo.appVersion = 0.68;  // Feb 04, 2021 - New Server  
    //SessionInfo.appVersion = 0.67;  // Feb 03, 2021 - Fix Notify  
    //SessionInfo.appVersion = 0.66;  // Feb 01, 2021 - Cleanup, Kitchen Management   
    //SessionInfo.appVersion = 0.65;  // Jan 28th, 2021 - button Div issue  
    //SessionInfo.appVersion = 0.64;  // Jan 25th, 2021 - omAPI 5.0  
    //SessionInfo.appVersion = 0.63;  // Jan 22th, 2021 - SVG color
    //SessionInfo.appVersion = 0.62;  // Jan 21th, 2021 - Change to svg icons
    //SessionInfo.appVersion = 0.61;  // Jan 15th, 2021 - Kitchen Management - TileManager - Upgrade to 4.3 Telerik
    //SessionInfo.appVersion = 0.59;  // Nov 11th, 2020 - SideBar, Header Rework, Avatar, Theme - OptiView1.css
    //SessionInfo.appVersion = 0.58;  // Nov 3rd, 2020 - Promotions
    //SessionInfo.appVersion = 0.57;  // Nov 1st, 2020 - Options - Many to Many and Assignment to StoreItems 
    //SessionInfo.appVersion = 0.56;  // Oct 30th, 2020 - Options - Many to Many and Assignment to StoreItems 
    //SessionInfo.appVersion = 0.55;  // Oct 28th, 2020 - Clean up Pages, Save Image 
    //SessionInfo.appVersion = 0.54;  // Oct 22th, 2020 - Landing Page 
    //SessionInfo.appVersion = 0.53;  // Oct 21th, 2020 - Adjustments to Products
    //SessionInfo.appVersion = 0.52;  // Oct 20th, 2020 - Bug fixes based on testing
    //SessionInfo.appVersion = 0.51;  // Oct 19th, 2020 - Bug fixes based on testing
    //SessionInfo.appVersion = 0.50;  // Oct 5th, 2020 - Application Improvements
    //SessionInfo.appVersion = 0.49;  // Oct 2nd, 2020 - Update to support .Net Core 3.1 OMApi
    //SessionInfo.appVersion = 0.48;  // Sept 21st, 2020 - Update to support multi-tenant
    //SessionInfo.appVersion = 0.47;  // Sept 3rd, 2020 - Copy Items, Current List Minus Store Items, Changes to Customer
    //SessionInfo.appVersion = 0.46;  // August 28th, 2020 - Register, Pricing Types, Location Fix
    //SessionInfo.appVersion = 0.45;  // August 27th, 2020 - Registration
    //SessionInfo.appVersion = 0.44;  // August 18th, 2020 - Update for Demo Olly Fresco
    //SessionInfo.appVersion = 0.43;  // August 5th, 2020 - Email confirmation
    //SessionInfo.appVersion = 0.42;  // July 24th, 2020 - Permissions, Support for Level 2 Olly Fresco
    //SessionInfo.appVersion = 0.41;  // July 22th, 2020 - Permissions, Support for Level 2 Olly Fresco
    //SessionInfo.appVersion = 0.39;  // July 14th, 2020 - Database update - further kitchen display 
    //SessionInfo.appVersion = 0.38;  // Jul1 8th, 2020 - Add Option Group assignment - support for many to many, Kitchen display 
    //SessionInfo.appVersion = 0.37;  // June 22rd, 2020 - Add Option Group assignment - support for many to many, Kitchen display 
    //SessionInfo.appVersion = 0.36;  // June 22rd, 2020 - Add Option Group assignment - support for many to many, Kitchen display 
    //SessionInfo.appVersion = 0.35;  // June 18th, 2020 - Update Options Groups and Item
    //SessionInfo.appVersion = 0.34;  // May 29th, 2020 - Port Change for ucsinventory 
    //SessionInfo.appVersion = 0.33;  // May 21st, 2020 - Promotion Update
    //SessionInfo.appVersion = 0.32;  // May 13th, 2020 - Update Stress Test Interface - add kitchen process - add support tables
    //SessionInfo.appVersion = 0.31;  // May 12th, 2020 - Add Stress Test Interface  
    //SessionInfo.appVersion = 0.29;  // May 10th, 2020 - Update to new OMObjectLW     
    //SessionInfo.appVersion = 0.28;  // May 4th, 2020 - Ordering, Product Options
    //SessionInfo.appVersion = 0.27;  // May 4th, 2020 - Ordering, Product Options
    //SessionInfo.appVersion = 0.26;  //April 23, 2020 - StoreItems Promotion, Customer Roles
    //SessionInfo.appVersion = 0.25;  // Demo Version - Dashboard Home, Manager Page, Charts
    //SessionInfo.appVersion = 0.24;  // March 31, 2020 - Restructured StoreLocations, Updated Header - Expand/Collapse, Grid Add, Excel Export, Notifications and Promotions, DateTime Picker fields
    //SessionInfo.appVersion = 0.23;  // March 28, 2020 - Lookup Tables for StoreItems, Restructured StoreLocations with Lookup table and side product table
    //SessionInfo.appVersion = 0.22;  // March 18, 2020 - AutoLogoff, Table access update, Search      
    //SessionInfo.appVersion = 0.21;  // March 13, 2020 - Individual Item Copy, Profile Picture               
    //SessionInfo.appVersion = 0.20; // March 11, 2020 - Store Item Copy to Store with pricing and extensions
    //SessionInfo.appVersion = 0.19; // March 5, 2020 - HoursOfOperation Edit, Date/Time, MultiSelect, Filter and Sort on Grids 
    //SessionInfo.appVersion = 0.18; // February 26, 2020 - Search, Product Items, Copy from Main to Store
    //SessionInfo.appVersion = 0.17; // February 21, 2020 - StoreItem Select, Drop downs loaded, Grid Date Format, Translated and Raw row get
    //SessionInfo.appVersion = 0.16; // February 19, 2020 - StoreItem Details, Edit Grid, Edit Money                    
    //SessionInfo.appVersion = 0.15; // February 11, 2020 - StoreItems and Search
    //SessionInfo.appVersion = 0.14; // February 8, 2020 - Search in StoreDetail, StoreLocations, Customers        
    //SessionInfo.appVersion = 0.13; // February 6, 2020 - Store Items in StoreLocations - start of pick and choose ProductItems
    //SessionInfo.appVersion = 0.12; // VERSION - February 4, 2020 - Initial application with StoreLocations and Customers 
    // Server Access
    SessionInfo.URI = 'http://localhost:2000/api/omUI';  // Development - NOTE - on http and Port: 2000 - 8Feb20 - somehow the 2000 is getting translated to 43348??  
    //SessionInfo.URI = 'http://127.0.0.1:2000/api/omUI';  // Development - NOTE - on http and Port: 2000 - 8Feb20 - somehow the 2000 is getting translated to 43348??  
    //SessionInfo.URI = 'http://localhost:433/api/omUI';  // Development - NOTE - on http and Port: 433 - this does not work
    SessionInfo.Platform = 'D';
    if (!SessionInfo.debug) {  
      //SessionInfo.URI = 'https://omapi.interactsuite.app:44343/api/omUI'; //Production 
      SessionInfo.URI = 'https://omapi.interactmenu.com:44343/api/omUI'; // Production 
      SessionInfo.Platform = 'P';
    }
    SessionInfo.commErrorCount = 0;
    SessionInfo.defaultAppEntityID = 3000488;
    SessionInfo.AppEntityID = 0;
    SessionInfo.entityCode = '';
    SessionInfo.appEntityName = "Demo Interact";
    console.log(`${SessionInfo.appEntityName} Manager - Version: ${SessionInfo.appVersion}-${SessionInfo.Platform}`); 
    // Logged In Information  
    SessionInfo.logonName = ''; 
    SessionInfo.loggedOn = false;
    SessionInfo.forceLogoff = false;
    SessionInfo.accessFailCount = 0; // Used to set forceLogoff in some cases
    SessionInfo.userID = 0;
    SessionInfo.Name = '';
    SessionInfo.logonEmailAddress = '';  // 13Feb23 - change to EmailAddress
    SessionInfo.CustomerID = 0;
    SessionInfo.PersonPhotoID = 0;
    SessionInfo.stayLoggedOn = false; // Store cookie token
    // on Logoff
    SessionInfo.refreshCustomerProfile = true;
    //------ Logged In Information ---------------------------------------------
    //SessionInfo.logonType = 0;  // 1-logon, 3-Register, 4-token logon, 5-Google Logon, 9-default logon
    //SessionInfo.defaultLogon = false;
    //SessionInfo.OMUserID = 0;
    //SessionInfo.UserName = ''; 
    //SessionInfo.ResetOrder = false;
    //------ Permission Information ---------------------------------------------
    SessionInfo.RoleID = 0;
    SessionInfo.PermLevel = 0; // 490-497
    SessionInfo.ApplicationTypeID = 0; // This will determine what functionality is available - ultimately controlled via permissions
    //------ Configuration Information ---------------------------------------------
    SessionInfo.ECV = [];  // Entity Config values
    SessionInfo.AppCfg = 0; // Application Config Control 
    // Control Header
    SessionInfo.message = "";
    SessionInfo.notifyMessage = "";
    SessionInfo.flagLevel = 0;
    SessionInfo.isApp = false;
    SessionInfo.History = undefined;
    SessionInfo.registerCounter = 0;
    SessionInfo.isSavingDocument = false;
    SessionInfo.DocumentTypes = [{ ddName: "xxx", ddID: 0 },{ ddName: "jpg", ddID: 1 }, { ddName: "jpeg", ddID: 2 }, { ddName: "png", ddID: 3 }, { ddName: "gif", ddID: 4 }, { ddName: "webp", ddID: 5 }];
    SessionInfo.tempLogon = false;
    SessionInfo.headerTitle = 'Title';
    SessionInfo.itemName = 'Item';
    SessionInfo.currentPage = '';   
    SessionInfo.displayHeader = true;
    SessionInfo.slimHeader = true;
    SessionInfo.displayFooter = false; // Note - if this is set to false - need to set '--main-bottom' in app.css to 0
    SessionInfo.displayLanding = false;
    SessionInfo.onChangeAskSave = true; 
    // Control Information   
    SessionInfo.menuAction = undefined;
    SessionInfo.setShowNotify = 0;
    SessionInfo.setShowSave = 0;
    SessionInfo.setShowNew = 0;  // Clear Fields - Plus Sign - see currClearFunc
    //SessionInfo.twoStageClear = false; // If true, clear text fields first
    SessionInfo.clearStage = 0;
    SessionInfo.setShowDelete = 0;
    SessionInfo.setGridRefresh = 0;
    SessionInfo.setGridAdd = 0;
    SessionInfo.setLPaneClose = 0;
    SessionInfo.setLPaneOpen = 0;
    SessionInfo.setRPaneClose = 0;
    SessionInfo.setRPaneOpen = 0;
    SessionInfo.setMessageOpen = 0;
    SessionInfo.setPopupMessageOpen = 0;
    SessionInfo.searchTitle = "Search";
    SessionInfo.searchTitle1 = "";
    SessionInfo.searchTitle2 = "";
    SessionInfo.searchChoice = 0;
    SessionInfo.HelpTitle = " Interact     Manager Help ";
    SessionInfo.clearSearch = false;
    SessionInfo.orderIDDone = 0;
    SessionInfo.orderIDComplete = 0;
    SessionInfo.CurrentTableID = 0;
    SessionInfo.CurrentLocationID = 0;
    SessionInfo.ShowSwitchSearch = 0;
    // Page specific Functions
    SessionInfo.ForceShowMessageBox = true; // Show message box in header at startup
    SessionInfo.currSaveFunc = undefined;
    SessionInfo.currDeleteFunc = undefined;
    SessionInfo.currClearFunc = undefined;
    SessionInfo.currRefreshGridFunc = undefined;
    SessionInfo.currAddGridRowFunc = undefined;
    SessionInfo.currExcelExportFunc = undefined;
    SessionInfo.currShowHelp = undefined;
    SessionInfo.currExpandLPane = undefined;
    SessionInfo.currCollapseLPane = undefined;
    SessionInfo.currExpandRPane = undefined;
    SessionInfo.currCollapseRPane = undefined;
    SessionInfo.searchFunc = undefined;
    SessionInfo.tabSelectFunc = undefined;
    SessionInfo.clearSearch = undefined; // ClearfSearch Func in Header
    SessionInfo.MenuSelect = '';
    SessionInfo.GoToTab = 0;
    SessionInfo.currentItem = undefined;
    SessionInfo.currentTileLayoutSave = undefined;
    SessionInfo.AccountShowCurrent = false;
    // Page State Storage 
    SessionInfo.AppMainPage = undefined;
    SessionInfo.StoreLocationDetail = undefined; 
    SessionInfo.OrderManagement = undefined;
    SessionInfo.StoreItemDetail = undefined; 
    SessionInfo.CustomerProfile = undefined;
    SessionInfo.SettingsPage = undefined;
    SessionInfo.ManagerPage = undefined;
    SessionInfo.HeaderPage = undefined;
    SessionInfo.KitchenManagement = undefined;
    SessionInfo.PageSide = undefined;
    SessionInfo.AboutPage = undefined;
    // DropDown Updates           
    SessionInfo.StoreItemsUpdate = true;
    SessionInfo.StoreLocationsUpdate = true;
    SessionInfo.ProductItemCategoriesUpdate = true;
    SessionInfo.ProductItemGroupUpdate = true;    
    SessionInfo.ProductItemTypeUpdate = true;
    SessionInfo.ItemAssociationTypeUpdate = true;
    SessionInfo.RateTypeUpdate = true;
    SessionInfo.PackagingTypeUpdate = true;
    SessionInfo.StorePromotionUpdate = true; 
    SessionInfo.BrandUpdate = true;
    SessionInfo.ItemStatusUpdate = true;
    SessionInfo.PricingTypeUpdate = true;
    SessionInfo.RateTypeUpdate = true;
    SessionInfo.SublocationUpdate = true;
    SessionInfo.ItemStatusUpdate = true;

    SessionInfo.StoreGroupUpdate = true;
    SessionInfo.LocationTypesUpdate = true;
    SessionInfo.TaxRulesUpdate = true;
    SessionInfo.ProvinceStatesUpdate = true;
    // Settings
    SessionInfo.activeColor = "#3e80ed"; 
    // Key Selections
    SessionInfo.OrderLocationID = 0;
    SessionInfo.KitchenLocationID = 0;
    SessionInfo.CurrentSchedDate = new Date();
    SessionInfo.orderTiles = [];
    // Entity Configuration Values go in here - Note - this is NOT the same for the Menu App
    SessionInfo.GeoFenceMeters = 0;
    //SessionInfo.Config = { // Configuration Info - Version 1.01 - Source: Mgr
    //  HasScanAndPay: false,
    //  HasOrderAndPay: false,
    //  ScanPayIndividualItems: false,
    //  HasIngredients: false,
    //  HasOrderToTable: false,
    //  HasDelivery: false,
    //  HasTip: false,
    //  HasItemLookup: false,
    //  HasRecipeLookup: false,
    //  HasStorePromotion: false,
    //  HasAccountInfo: false,
    //  HasGiftCards: false,
    //  HasCoupons: false,
    //  HasShoppingList: false,
    //  HasCategoryPage: false,
    //  HasPartialPayment: false,
    //  HasSeats: false,
    //  HasGuestInvite: false,
    //  HasDiscounts: false,
    //  HasCredit: false,
    //  HasReturns: false,
    //  HasTax1: false,
    //  HasTax2: false,
    //  HasTax3: false,
    // Note - These values are currently hard coded
    SessionInfo.CInfo = {
      HasBarcode: true,
      HasDaysOfWeek: false,
    }
    SessionInfo.PersonInfo = {
      // Customer Info - Version 1.01 - Source: Menu
      CustomerID: 0,
      FirstName: undefined,
      AdditionalName: '',
      LastName: '',
      Address: '',
      City: '',
      ProvinceStateID: 0,
      CountryID: 0,
      PostalCode: '',
      DateOfBirth: undefined,
      Gender: '',
      PhoneNumber: '',
      PhoneNumber2: '',
      EmailAddress: '',
      EmailReceipts: false,
      EmailPromotions: false,
      SMSReminders: false,
      SoundOn: false,
      PersonPhotoID: 0,
      RoleID: 0,
      TotalPurchaseAmount: 0,
      MaximumPurchaseAmount: 0,
      TodayPurchaseAmount: 0,
      LastTimeActive: undefined,
      LastLogonDate: undefined,
    };
    SessionInfo.MenuTree = undefined;
    SessionInfo.PersonPhotoURL = undefined;  
    SessionInfo.Cnfg = {
      HasBarcodes: true,
    }
  }
  // Local
  ConfirmationStr = '';
  //state = {
  //  loading: true,
  //}
  componentDidMount() {
    //this.setState({ loading: false });
    document.title = "InteractSuite Manager";
    console.log(`InteractSuite Manager Loaded`);
  }
  loggedIn() {
    return true;
  }
  requireAuth(nextState, replace) {
    if (SessionInfo.session === '') {
      replace({ pathname: '/Login' });
    }
  }
  // <Route path="/:id" children={<Confirmation />} />
  render() {
    return (
      <BrowserRouter>
        <Routes>
          <Route exact path='/' element={<AppMain />} />
          <Route exact path='/AppMain' element={<AppMain />} />
          <Route exact path='/Login' element={<Login />} />
          <Route exact path='/AboutPage' element={<AboutPage />} onEnter={this.requireAuth} />
          <Route exact path='/SettingsPage' element={<SettingsPage />} onEnter={this.requireAuth} />  
          <Route exact path='/Confirmation' element={<Confirmation />} /> 
          <Route path='/StoreLocationDetail' element={<StoreLocationDetail />} onEnter={this.requireAuth} />
          <Route path='/OrderManagement' element={<OrderManagement />} onEnter={this.requireAuth} />
          <Route path='/KitchenManagement' element={<KitchenManagement />} onEnter={this.requireAuth} />
          <Route path='/CustomerProfile' element={<CustomerProfile />} onEnter={this.requireAuth} />
          <Route path='/StoreItemDetail' element={<StoreItemDetail />} onEnter={this.requireAuth} />
          <Route path='/ManagerPage' element={<ManagerPage />} onEnter={this.requireAuth} />
          <Route path='/ContactUs' element={<ContactUs />} onEnter={this.requireAuth} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </BrowserRouter>
    )
  }  
}      