//----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// CommonCode - Code to support working with React in conjunction with Telerik Controls and the omUIapi and general helper functions
//              Version 1.043 - July 11, 2023
// Note - this is the InteractManager - Manager Version of CommonCode
//----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
import React from 'react';
import { useNavigate } from 'react-router-dom';                                                                                                                                
import { SessionInfo } from "./App";
import { ENAppCnfg,ENLayoutCnfg } from './CommonInfo.js';
import { useGoogleLogin } from 'react-use-googlelogin';  
//const sjcl = require('sjcl'); // Not sure about this - required for codec sub      
//var keypair = require("keypair");
const GoogleAuthContext = React.createContext();
// https://developer.mozilla.org/en-US/docs/Web/HTTP/CORS
//
//const BlankImage = "/9j/4AAQSkZJRgABAQEAYABgAAD/2wBDAAgGBgcGBQgHBwcJCQgKDBQNDAsLDBkSEw8UHRofHh0aHBwgJC4nICIsIxwcKDcpLDAxNDQ0Hyc5PTgyPC4zNDL/2wBDAQkJCQwLDBgNDRgyIRwhMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjL/wAARCAAZABkDASIAAhEBAxEB/8QAHwAAAQUBAQEBAQEAAAAAAAAAAAECAwQFBgcICQoL/8QAtRAAAgEDAwIEAwUFBAQAAAF9AQIDAAQRBRIhMUEGE1FhByJxFDKBkaEII0KxwRVS0fAkM2JyggkKFhcYGRolJicoKSo0NTY3ODk6Q0RFRkdISUpTVFVWV1hZWmNkZWZnaGlqc3R1dnd4eXqDhIWGh4iJipKTlJWWl5iZmqKjpKWmp6ipqrKztLW2t7i5usLDxMXGx8jJytLT1NXW19jZ2uHi4+Tl5ufo6erx8vP09fb3+Pn6/8QAHwEAAwEBAQEBAQEBAQAAAAAAAAECAwQFBgcICQoL/8QAtREAAgECBAQDBAcFBAQAAQJ3AAECAxEEBSExBhJBUQdhcRMiMoEIFEKRobHBCSMzUvAVYnLRChYkNOEl8RcYGRomJygpKjU2Nzg5OkNERUZHSElKU1RVVldYWVpjZGVmZ2hpanN0dXZ3eHl6goOEhYaHiImKkpOUlZaXmJmaoqOkpaanqKmqsrO0tba3uLm6wsPExcbHyMnK0tPU1dbX2Nna4uPk5ebn6Onq8vP09fb3+Pn6/9oADAMBAAIRAxEAPwD32iiigAooooAKKKKACiiigD//2Q==";
const BlankImage = "/9j/4AAQSkZJRgABAQEAYABgAAD/2wBDAAgGBgcGBQgHBwcJCQgKDBQNDAsLDBkSEw8UHRofHh0aHBwgJC4nICIsIxwcKDcpLDAxNDQ0Hyc5PTgyPC4zNDL/2wBDAQkJCQwLDBgNDRgyIRwhMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjL/wAARCAAZABkDASIAAhEBAxEB/8QAHwAAAQUBAQEBAQEAAAAAAAAAAAECAwQFBgcICQoL/8QAtRAAAgEDAwIEAwUFBAQAAAF9AQIDAAQRBRIhMUEGE1FhByJxFDKBkaEII0KxwRVS0fAkM2JyggkKFhcYGRolJicoKSo0NTY3ODk6Q0RFRkdISUpTVFVWV1hZWmNkZWZnaGlqc3R1dnd4eXqDhIWGh4iJipKTlJWWl5iZmqKjpKWmp6ipqrKztLW2t7i5usLDxMXGx8jJytLT1NXW19jZ2uHi4+Tl5ufo6erx8vP09fb3+Pn6/8QAHwEAAwEBAQEBAQEBAQAAAAAAAAECAwQFBgcICQoL/8QAtREAAgECBAQDBAcFBAQAAQJ3AAECAxEEBSExBhJBUQdhcRMiMoEIFEKRobHBCSMzUvAVYnLRChYkNOEl8RcYGRomJygpKjU2Nzg5OkNERUZHSElKU1RVVldYWVpjZGVmZ2hpanN0dXZ3eHl6goOEhYaHiImKkpOUlZaXmJmaoqOkpaanqKmqsrO0tba3uLm6wsPExcbHyMnK0tPU1dbX2Nna4uPk5ebn6Onq8vP09fb3+Pn6/9oADAMBAAIRAxEAPwD32iiigAooooAKKKKACiiigD//2Q==";
//
// Note - control parm goes to y: used for parameter parsing - ActiveParm1, callParm goes to p - ActiveParm2 
export default async function accessOMAPI(operation, control, tableID, key, rdata, modexMethod, optionColumns, callParm, pageID) {
  let CD;
  let result;
  let httpMethod;                  
  let SessionID = SessionInfo.session;
  if (SessionInfo.sequenceid < 100001) {
    console.log(`Bad Call Sequence: ${SessionInfo.sequenceid} - set to 100001`);
    SessionInfo.sequenceid = 100001;
  }
  else
    SessionInfo.sequenceid += 1;
  if (Number.isInteger(tableID) === false) {
    console.log(`*NOT INTEGER* TableID: ${tableID} - Operation: ${operation}, Method: ${modexMethod}`);
    return;
  }
  else if (Number.isInteger(key) === false) {
    key = parseInt(key, 0);
    if (Number.isInteger(key) === false) {     
      console.log(`*NOT INTEGER* Key: ${key} - Operation: ${operation}, Method: ${modexMethod}`);
      return;
    }
  }      
  if (!modexMethod)
    modexMethod = '';
  if (!optionColumns)
    optionColumns = '';
  if (!callParm)
    callParm = 0;
  if (!pageID)
    pageID = 0;
  //console.log("accessOMAPI SessionID: " + SessionID + " SequenceID: " + SessionInfo.sequenceid + " key: " + key + " Operation: " + operation + " control: " + control + " callParm: " + callParm);
  try { //--------------------------------------------------------------------------------------------------- 
    if (operation < 100) {
      const RestHeader = {"Content-Type": "application/json; charset=utf-8"};
      httpMethod = "GET";
      let getCD = { x: { s: SessionID, q: SessionInfo.sequenceid, t: tableID, k: key, i: pageID, o: operation, y: control, p: callParm, f: modexMethod, m: optionColumns }, d: {} };
      if (!rdata)
        rdata = {};
      getCD.d = rdata;
      //console.log(`GetCD: ${JSON.stringify(getCD)}`); 
      //console.log(`Get URI: ${JSON.stringify(SessionInfo.URI)}`);
      const getData = encodeURI(SessionInfo.URI + '?CDataStr=' + JSON.stringify(getCD));  //  + '?CDataStr={x:{s:' + SessionID + ',q:' + SequenceID + ',t:' + tableID + ',k:' + key + ',y:0,o:' + operation + ',m:""},d:{}}');
      result = await fetch(getData, { mode: "cors", headers: RestHeader });  // HTTP GET  , {request-no-cors}
      //console.log("Get Result: " + JSON.stringify(result));
    } //----------------------------------------------------------------------------------------------------
    else {
      if (operation >= 100 && operation < 200) {
        httpMethod = "POST";
        operation -= 100;
      }
      else if (operation >= 200 && operation < 300) {
        httpMethod = "PUT";
        operation -= 200;
      }
      else if (operation >= 300 && operation < 400) {
        httpMethod = "PATCH";
        operation -= 300;
      }
      else if (operation >= 400 && operation < 500) {
        httpMethod = "DELETE";
        operation -= 400;
      }
      else {
        httpMethod = "UNKNOWN";
      }
      let updata = { x: { s: SessionID, q: SessionInfo.sequenceid, t: tableID, k: key, i: pageID, o: operation, y: control, p: callParm, f: modexMethod, m: optionColumns }, d: {} };
      if (rdata === undefined)
        rdata = {};  
      updata.d = rdata;
      let upstr = JSON.stringify(updata);
      //console.log("Access: '" + httpMethod + "' Command: " + upstr);
      const RestHeader = {
        "Content-Type": "application/json; charset=utf-8",
        "Content-Length": upstr.length
      };                    
      //console.log("Update Header: " + JSON.stringify(RestHeader)); 
      //console.log("Put URI: " + JSON.stringify(SessionInfo.URI));
      result = await fetch(SessionInfo.URI, {
        method: httpMethod, // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, cors, *same-origin
        headers: RestHeader,
        body: upstr // body data type must match "Content-Type" header
      });
    }//---------------------------------------------------------------------------------------------------
    //console.log("result: " + JSON.stringify(result)); //- this displays {}
    CD = await result.json();                             
    //console.log(httpMethod + " post CD: " + JSON.stringify(CD)); // GET post CD:
    //if (CD === undefined || CD === null) { // undefined for 61 ping                 
    if (!CD) { // undefined for 61 ping
      if (SessionInfo.session && SessionInfo.session != '' && operation !== 20) { // Session and not Trace
        console.log(`OMAPI operation: ${operation} returned NULL CD (${SessionInfo.commErrorCount})`);
        if (++SessionInfo.commErrorCount > 5)
          LogOffResetMsg("Logoff - Unable To Access Server");
        else
          displayError(`Unable to Access Server on Operation: ${operation}`);
      } else {
        console.log(`Invalid CD on Operation: ${operation}`);
      }
      CD = { x: { s: SessionID, q: SessionInfo.sequenceid, t: 0, k: 0, i: 0, o: 9991, y: 0, f: '', m: '' }, d: {} }; // Failure
    }
    else {
      //console.log(`OMAPI operation: ${operation} returned CD (${JSON.stringify(CD.x)}), d: ${JSON.stringify(CD.d)}`); // Display full packet on return
      if (operation < 100 || operation > 110) { // NOT Signon/Register
        displayCDMessage(CD);
        //.log("o: " + CD.x.o);              
        //if (CD.x.o > 9800) // Error Level in OMAPI
        if (CD.x.o > 9980) // Error Level in OMAPI
          LogOffReset(`LogOff Error: ${CD.x.o} operation: ${operation}`);
      }        
      //else
      //  displayCDMessage(CD);
      SessionInfo.session = CD.x.s;
      if (SessionInfo.commErrorCount > 0) {
        displayNotify("Access to Server Restored");
        SessionInfo.commErrorCount = 0;
      }
    } 
    //console.log("SessionInfo.commErrorCount: " + SessionInfo.commErrorCount);
  } catch (error) { //****** COMM ERROR ***************
    console.log(`Fail accessOMAPI: ${httpMethod} Op: ${operation}(${ SessionInfo.commErrorCount }) Error: ${error.message}`);
    if (++SessionInfo.commErrorCount > 5)
      LogOffResetMsg("Lost Access to Server");
    else
      displayError(`Unable to Access Server (${SessionInfo.commErrorCount})`);
    CD = { x: { s: SessionID,q: SessionInfo.sequenceid,t: 0,k: 0,i: 0,o: 9992,y: 0,f: '',m: '' },d: {} }; // Failure
    console.log(`SessionInfo.forceLogoff in accessOMAPI - set TRUE`);
    SessionInfo.forceLogoff = true;
  }
  return CD;
}
function CreateLogonDict(keyValue,entityCode,userName,password) { // For Interact-Manager
  let product = 270; // Checkout
  let site = 2000008;
  let entity = SessionInfo.defaultAppEntityID; // default 0, 3000487, 3000488
  let dt = new Date();
  let timeAdjust = -dt.getTimezoneOffset();
  if (SessionInfo.debug === true)
    timeAdjust = 0;
  let manufacturer = window.navigator.language; //window.navigator.DeviceInfo.manufacturer; 
  if (!manufacturer)
    manufacturer = "browser";
  console.log(`CreateLogonDict - geolocation: ${JSON.stringify(window.navigator.geolocation)}, Manufacturer: ${manufacturer}`); // Current Location  
  //let deviceName = navigator.userAgentData.platform; // window.navigator.platform; // Device Name (Motz's iPhone)
  //if (!deviceName)
  let deviceName = "";
  //else
  //  deviceName = deviceName.toString();
  //let platform = navigator.userAgentData.platform; // window.navigator.appName; // Platform (Android)
  //if (!platform)   
  let platform = "React";
  let idiom = "MgrApp"; // Idiom: Manager - See LogonToOM in OM
  let Timezone = window.navigator.timezone;
  if (!Timezone)
    Timezone = "unknown";
  let deviceType = window.navigator.language; // Device Type (Physical)
  if (!deviceType)
    deviceType = "unknown";
  let currentTime = new Date();
  if (!currentTime)
    currentTime = "Unknown";
  SessionInfo.Width = window.innerWidth;
  SessionInfo.Height = window.innerHeight;
  console.log(`timezone: ${Timezone}, deviceType: ${deviceType}, widxht: ${SessionInfo.Width}x${SessionInfo.Height}, AppEntityID: ${SessionInfo.AppEntityID}, path: ${SessionInfo.host}`); // Current Location
  let ldata = {
    UserName: userName,Password: password,AppProductID: SessionInfo.AppProductID.toString(),AppSiteID: SessionInfo.AppSiteID.toString(),AppEntityID: SessionInfo.AppEntityID.toString(),RegCode: SessionInfo.registrationCode,DomainName: SessionInfo.host,TimeAdjust: timeAdjust.toString(),KeyValue: keyValue,Manufacturer: manufacturer,OptiVersion: SessionInfo.appVersion.toString(),Platform: platform,Idiom: idiom,SystemName: deviceName,Width: SessionInfo.Width.toString(),Height: SessionInfo.Height.toString()
  }; // Name: deviceName}; // Platform: platform, Idiom: idiom, OptiVersion: SessionInfo.appVersion,DeviceType: deviceType.toString(), TimeZone: Timezone.toString(), DaylightSavings: "N", Time: currentTime.toString()};
  return ldata;
}
// Log on, signon, sign on, Login, log in
export async function LogOn(entityCode,userName,password) {
  let ldata = CreateLogonDict("",entityCode,userName,password);
  if (SessionInfo.session === undefined)
    SessionInfo.session = 0;
  console.log(" LogOn ldata: " + JSON.stringify(ldata));
  const CD = await accessOMAPI(101,0,0,0,ldata); // LogonToOM in OMAccess - Note: Logon to OM causes LogonCheckCustomer in CheckoutMdx to be executed
  if (!CD) {
    console.log('Logon Failed - Communication Problem');
    notify('Logon Failed - Communication Problem');
  }
  else {
    console.log(" LogOn CD: " + JSON.stringify(CD));
    if (CD.x.o < 9500) { // Successful logon
      SessionInfo.session = CD.x.s;
      if (SessionInfo.sequenceid > CD.x.q) {
        // Report a problem
        console.log(`Bad Return Sequence ${CD.x.q}, Current Sequence: ${SessionInfo.sequenceid}`);
      }
      else
        SessionInfo.sequenceid = CD.x.q;
      if (SessionInfo.sequenceid < 100001) {
        console.log(`Bad Sequence - Return Sequence ${CD.x.q}, Current Sequence: ${SessionInfo.sequenceid}`);
        SessionInfo.sequenceid = 100001;
      }
      SessionInfo.AppEntityID = CD.x.t;
      SessionInfo.logonName = userName;
      SessionInfo.logonEmailAddress = userName; // Should return email address from logon (and phonenumber)
      SessionInfo.entityCode = entityCode;
      SessionInfo.userID = CD.x.k;
      let CSuccess = await SetConfigInfo(CD.d);
      if (CSuccess === false || SessionInfo.PermLevel < 493) { // Note - Cannot be signed on to manager
        console.log(`Manager Logon Failed - perm level: ${SessionInfo.PermLevel}, id: ${SessionInfo.userID}`);
        return 'Logon Failed - Permission Issue';
      } else {
        await GetCustomerInfo();
        if (CD.x.s !== undefined && SessionInfo.userID > 100000)
          SessionInfo.loggedOn = true;
        console.log(`Login - Set Session ID: ${CD.x.s} set: ${SessionInfo.session} Role: ${SessionInfo.RoleID} PermLvl: ${SessionInfo.PermLevel}`);
        return "ok";
      }
    }
    else
      return CD.x.m;
  }
}
export async function KeyLogOn(token) {
  console.log(`Mgr KeyLogon - Token: ${token}`);
  let ldata = CreateLogonDict(token,"","","");
  console.log(`KeyLogon - ldata: ${JSON.stringify(ldata)}`);
  const CD = await accessOMAPI(104, 0, 0, 0, ldata);
  if (!CD) {
    notify('Key Logon Failed - Communication Problem');
  }
  else {
    console.log("---> Key LogOn return CD: " + JSON.stringify(CD));
    if (CD.x.o < 9500) { // Successful logon
      SessionInfo.session = CD.x.s;
      if (SessionInfo.sequenceid > CD.x.q) {
        // Report a problem    
        console.log(`Bad Return Sequence ${CD.x.q}, Current Sequence: ${SessionInfo.sequenceid}`);
        //SessionInfo.sequenceid = CD.x.q;
      }
      else
        SessionInfo.sequenceid = CD.x.q;
      if (SessionInfo.sequenceid < 100001) {
        console.log(`Bad Sequence - Return Sequence ${CD.x.q}, Current Sequence: ${SessionInfo.sequenceid}`);
        SessionInfo.sequenceid = 100001;
      }                                    
      SessionInfo.logonName = getDisplayName();
      SessionInfo.userID = CD.x.k;
      let CSuccess = await SetConfigInfo(CD.d);
      if (CSuccess === false || SessionInfo.PermLevel < 493) { // Note - Cannot be signed on to manager
        return 'Logon Failed - Permission Issue';
      } else {
        await GetCustomerInfo();
        if (CD.x.s !== undefined && SessionInfo.userID > 100000)
          SessionInfo.loggedOn = true;
        console.log(`Key Login - Set Session ID: ${CD.x.s} set: ${SessionInfo.session} Role: ${SessionInfo.RoleID} PermLvl: ${SessionInfo.PermLevel}`);
        return "ok";
      }
    }
    else
      return CD.x.m;
  }
}
export async function AddressLogOn() {      
  console.log("Address Log On");
  //const accounts = await getEthAccounts();
  //console.log("Accounts: " + accounts[0]);
  //if (accounts && accounts.length > 0) {
  //  const CD = await accessOMAPI(105, 0, 0, accounts[0]);
  //  if (!CD) {
  //    //console.log('Logon Failed - Communication Problem');
  //    notify('Token Logon Failed - Communication Problem');
  //  }
  //  else {
  //    console.log("Key LogOn CD: " + JSON.stringify(CD));
  //    if (CD.x.o < 9500) { // Successful logon
  //      SessionInfo.session = CD.x.s;
  //      SessionInfo.sequenceid = 1;
  //      SessionInfo.logonName = "Temp";
  //      SessionInfo.userID = CD.x.k;
  //      return "ok";
  //    }
  //    else
  //      return CD.x.m;
  //  }
  //}
  //else
  //  displayError("MetaMask must be set up and Logged On");
}
async function SetConfigInfo(list) {
  if (list) {
    try {
      SessionInfo.RoleID = list.RoleID;
      SessionInfo.PermLevel = SessionInfo.RoleID + 220; // Determine Perm Bit       
      console.log(`Logon - OM Config: ${JSON.stringify(list)}, PermLevel: ${SessionInfo.PermLevel}, RoleID: ${SessionInfo.RoleID}`);
      let PermPass = await CheckPermission(SessionInfo.PermLevel);
      console.log(`PermPass: ${PermPass} for PermLevel: ${SessionInfo.PermLevel}`);
      if (PermPass == false) {
        OMTrace(`Permission Verification Failed - User: ${SessionInfo.userID}, ${SessionInfo.logonName}, Role: ${SessionInfo.RoleID}`);
        SessionInfo.PermLevel = 0;
        return false; // "Permission Verification Failed";
      }
      //console.log(`Logon Parms List: ${JSON.stringify(list)}`);
      if (list["ALKey"]) {
        if (SessionInfo.stayLoggedOn === true) {
          SessionInfo.CheckoutToken = list["ALKey"];
          setCookie('IManagerID',SessionInfo.CheckoutToken,180);
        }
      }
      if (list["LastLogonDate"])
        SessionInfo.PersonInfo.LastLogonDate = list["LastLogonDate"];
      if (list["RoleID"])
        SessionInfo.PersonInfo.RoleID = parseInt(list["RoleID"]);
      if (list["GeoFenceMeters"])
        SessionInfo.GeoFenceMeters = parseInt(list["GeoFenceMeters"]);
    }
    catch (Ex) {
      await OMTrace(2,`Failure in SetConfig  ${Ex.Message}`);
    }
    return true;
  }
}
function getDisplayName() {
  let nameChk = SessionInfo.PersonInfo.FirstName;
  if (!nameChk || nameChk.length < 3)
    nameChk = SessionInfo.PersonInfo.EmailAddress;
  if (!nameChk || nameChk.length < 3)
    nameChk = SessionInfo.logonEmailAddress;
  if (!nameChk || nameChk.length < 3)
    nameChk = SessionInfo.logonName;
  if (nameChk) {
    if (nameChk.length > 17) {
      let ix = nameChk.indexOf('@');
      if (ix > 2)
        nameChk = nameChk.substring(0,ix);
      if (nameChk.length > 17) {
        let ix = nameChk.indexOf('.');
        if (ix > 2)
          nameChk = nameChk.substring(0,ix);
        if (nameChk.length > 17) {
          nameChk = nameChk.substring(0,17);
        }
      }
    }
  }
  return nameChk;
}
// Access css variables
export function setCSSVariable(varName, varValue) {
  document.documentElement.style.setProperty(varName, varValue);
}
export function getCSSVariable(varName) {
  return getComputedStyle(document.documentElement).getPropertyValue(varName);
}
export async function GetCustomerInfo() {
  let CD = await CallOM("GetCustomerInfo", 0, 0, 0);
  //console.log(" Customer Information: " + JSON.stringify(CD));
  //console.log(" Session data: " + JSON.stringify(SessionInfo.PersonInfo));
  if (CD !== undefined) {
    //this.setState({ CustomerID: Key });
    for (var prop in CD.d) {
      if (prop in SessionInfo.PersonInfo) {
        //console.log(`Set ${prop}: ${CD.d[prop]}`);
        let obj = {};
        if (prop.indexOf("Date") >= 0) {
          SessionInfo.PersonInfo[prop] = new Date(CD.d[prop]); //new Date()
        }
        else
          SessionInfo.PersonInfo[prop] = CD.d[prop];
      }
    }      
    SessionInfo.PersonPhotoID = SessionInfo.PersonInfo["PersonPhotoID"];
    SessionInfo.CustomerID = SessionInfo.PersonInfo.CustomerID;
  }
  console.log(`Get Customer - photoID: ${SessionInfo.PersonPhotoID}, CustomerID: ${SessionInfo.CustomerID}`);
}
//-----------------------------------------------------------------------------------------------------------------------------------------
export const GoogleAuthProvider = ({ children }) => {
  const googleAuth = useGoogleLogin({
    clientId: process.env.GOOGLE_CLIENT_ID, // Your clientID from Google.
  })

  return (
    <GoogleAuthContext.Provider value={googleAuth}>
      {children}
    </GoogleAuthContext.Provider>
  )
}
export const useGoogleAuth = () => React.useContext(GoogleAuthContext);

export const LogoutButton = () => {
  const { signOut } = useGoogleAuth();

  return (
    <button onClick={signOut}>Logout</button>
  );
};
// 18May23 - this is the new Register from Menu - needs to be tested
export async function UserRegister(firstName,lastName,email,phoneNumber,password,confirmPassword,regCode,imageURL,regType) {
  if (password !== confirmPassword) {
    console.log(`password issue - password: ${password}, confirm: ${confirmPassword}, firstName: ${firstName}, lastName: ${lastName}`);
    return ("pa")
  }
  let token = '';
  let timeAdj = new Date().getTimezoneOffset();
  let TimeAdj = timeAdj.toString();
  let ldata = {FirstName: firstName,LastName: lastName,UserName: email,PhoneNumber: phoneNumber,Password: password,ConfirmPassword: confirmPassword,Token: token,Image: imageURL,AppProductID: "270",AppSiteID: "2000008",AppEntityID: `${SessionInfo.defaultAppEntityID}`,RegistrationCode: regCode,RegType: regType,TimeAdjust: TimeAdj };

  if (SessionInfo.session === undefined)
    SessionInfo.session = 0;
  console.log(" register ldata: " + JSON.stringify(ldata));
  const CD = await accessOMAPI(103,0,0,0,ldata);
  if (!CD)
    return "fail";
  console.log(" UserRegister CD: " + JSON.stringify(CD));
  if (CD.x.o < 9500) { // Successful logon
    SessionInfo.session = CD.x.s;
    SessionInfo.logonType = 3;  // 1-logon, 3-Register, 4-token logon, 5-Google Logon, 9-default logon
    SessionInfo.defaultLogon = false;
    if (SessionInfo.sequenceid > CD.x.q) {
      // Report a problem   
      if (SessionInfo.sequenceid > 100)
        console.log(`Bad Return Sequence ${CD.x.q}, Current Sequence: ${SessionInfo.sequenceid}`);
      //SessionInfo.sequenceid = CD.x.q;
    }
    else
      SessionInfo.sequenceid = CD.x.q;
    if (SessionInfo.sequenceid < 100001) {
      if (SessionInfo.sequenceid > 100)
        console.log(`Bad Sequence - Return Sequence ${CD.x.q}, Current Sequence: ${SessionInfo.sequenceid}`);
      SessionInfo.sequenceid = 100001;
    }
    let CSuccess = await SetConfigInfo(CD.d);
    if (CSuccess === false || SessionInfo.PermLevel < 493) { // Note - Cannot be signed on to manager 
      console.log(`Manager Register Logon Failed - perm level: ${SessionInfo.PermLevel}, id: ${SessionInfo.userID}`);
      return 'Logon Failed - Permission Issue';
    } else {
      SessionInfo.logonEmailAddress = email; // Should return email address from logon (and phonenumber)
      SessionInfo.logonName = getDisplayName();
      SessionInfo.OMUserID = CD.x.k;
      if (CD.x.s !== undefined && SessionInfo.OMUserID > 100000)
        SessionInfo.loggedOn = true;
      await GetCustomerInfo(); // Logon
      SessionInfo.commErrorCount = 0;
      console.log("Set Session ID: " + CD.x.s + " set: " + SessionInfo.session);
      return "ok";
    }
  }
  else
    return CD.x.m;
}
// Note: 18May23 - this is the old register
//export default LogoutButton;                  
//export async function UserRegister(firstName, lastName, userName, password, confirmPassword) {
//  if (password !== confirmPassword) {
//    return ("pa")
//  }     
//  let token = '';
//  //try {
//  //  const accounts = await getEthAccounts(); //bring in user's metamask account walletAddresses 
//  //  console.log("Register - Account 0: " + accounts[0]);
//  //  if (accounts[0])
//  //    token = accounts[0];
//  //}
//  //catch {
//  //  console.log("Error on metamask access");
//  //} 
//  let ldata = { FirstName: firstName, LastName: lastName, UserName: userName, Password: password, ConfirmPassword: confirmPassword, Token: token, AppProductID: 270 };
//  if (SessionInfo.session === undefined)
//    SessionInfo.session = 0;
//  console.log(" register ldata: " + JSON.stringify(ldata));
//  const CD = await accessOMAPI(103, 0, 0, 0, ldata);
//  console.log(" UserRegister CD: " + JSON.stringify(CD));
//  if (CD.x.o < 9500) { // Successful logon
//    SessionInfo.session = CD.x.s;
//    if (SessionInfo.sequenceid > CD.x.q) {
//      // Report a problem    
//      console.log(`Bad Return Sequence ${CD.x.q}, Current Sequence: ${SessionInfo.sequenceid}`);
//      //SessionInfo.sequenceid = CD.x.q;
//    }
//    else
//      SessionInfo.sequenceid = CD.x.q;
//    if (SessionInfo.sequenceid < 100001) {
//      console.log(`Bad Sequence - Return Sequence ${CD.x.q}, Current Sequence: ${SessionInfo.sequenceid}`);
//      SessionInfo.sequenceid = 100001;
//    }                 
//    SessionInfo.logonName = userName;
//    SessionInfo.logonEmailAddress = userName; // Should return email address from logon (and phonenumber)
//    SessionInfo.userID = CD.x.k;
//    SetConfigInfo(CD.d);
//    if (CD.x.s !== undefined && SessionInfo.userID > 100000)
//      SessionInfo.loggedOn = true;
//    SessionInfo.commErrorCount = 0;
//    console.log("Set Session ID: " + CD.x.s + " set: " + SessionInfo.session);
//    return "ok";
//  }
//  else
//    return CD.x.m;
//}
export async function CallConfirmation(AppProductID, ConfirmationKey) {
  console.log(" Confirmation Key: " + JSON.stringify(ConfirmationKey));
  try {
    const CD = await accessOMAPI(108, 2, AppProductID, 1, ConfirmationKey);
    console.log(" Confirmation CD: " + JSON.stringify(CD));
    if (CD.x.o < 9500) { // Successful Confirmation
      SessionInfo.session = CD.x.s;
      if (SessionInfo.sequenceid > CD.x.q) {
        // Report a problem    
        console.log(`Bad Return Sequence ${CD.x.q}, Current Sequence: ${SessionInfo.sequenceid}`);
        //SessionInfo.sequenceid = CD.x.q;
      }
      else
        SessionInfo.sequenceid = CD.x.q;
      if (SessionInfo.sequenceid < 100001) {
        console.log(`Bad Sequence - Return Sequence ${CD.x.q}, Current Sequence: ${SessionInfo.sequenceid}`);
        SessionInfo.sequenceid = 100001;
      }
      SessionInfo.logonName = "";
      SessionInfo.userID = CD.x.k;
      SessionInfo.logonEmailAddress = "";
      //if (CD.x.s !== undefined && SessionInfo.userID > 100000)
      //  SessionInfo.loggedOn = true;
      SessionInfo.commErrorCount = 0;
      console.log("Confirmation ID: " + CD.x.s + " set: " + SessionInfo.session);
      return "ok";
    }
    else
      return CD.x.m;
  }
  catch (ex) {
    console.log(`Confirmation exception:  - ex: ${ex}`);
  }
}
export async function CheckPermission(bit) { // Check if User has a particular permission bit - NOTE - 12May23 - This needs to be updated!
  console.log(`Check PermBit - bit: ${bit}`);
  if (!bit)
    bit = 0;
  try {
    const CD = await accessOMAPI(51,0,0,bit); // Bit in x.k position
    if (CD) {
      console.log(`Check PermBit - bit: ${bit} - CD: ${JSON.stringify(CD)}`);
      if (CD.x.y === 1) // Check case 51: CheckLWPerm in OMAPI - return test in .y
        return true;
      console.log(`Fail on y==1: y: ${CD.x.y}`);
    }
  }
  catch (ex) {
    console.log(`Fail in CheckPermission ${ex}`);
  }
  return false;
}
export async function QueryPing() { // Ping Server - Note that is call PingApp in SupportAccess
  let setForceLogoff = false;
  try {
    if (SessionInfo.session && SessionInfo.loggedOn === true) {
      const CD = await accessOMAPI(61,0,0,0,"0|0"); // Lat|Long
      if (CD) {
        if (CD.x.o > 9950 || CD.x.p != 1) {
          console.log(`Query Ping Failed ${SessionInfo.accessFailCount} - CD:${JSON.stringify(CD)}`);
          if (SessionInfo.accessFailCount++ > 0) {
            console.log(`SessionInfo.forceLogoff in QueryPing - set TRUE`);
            setForceLogoff = true;
          }
        } else { // Otherwise successful
          SessionInfo.accessFailCount = 0;
        }
      } else {
        console.log(`Query Ping Failed - CD:${JSON.stringify(CD)}`);
        if (SessionInfo.accessFailCount++ > 1) {
          console.log(`SessionInfo.forceLogoff in QueryPing - set TRUE`);
          setForceLogoff = true;
        }
      }
    } else
      setForceLogoff = true;
  }
  catch (ex) {
    console.log(`QueryPing Failure - ex: ${ex}`);
    SessionInfo.forceLogoff = true;
  }
  if (setForceLogoff === true) {
    console.log(`SessionInfo.forceLogoff in QueryPing - set TRUE`);
    SessionInfo.forceLogoff = true;
  }
  return;
}
export async function LogOff() {
  SessionInfo.loggedOn = false;
  SessionInfo.message = ""; // This will clear the messages in the Header message display - Header copies this value into the Display Box
  displayMessage(`${SessionInfo.logonName} - Signed Out`);
  await accessOMAPI(102,0,0,0); // OM Logoff          
  console.log("Clear Session in Logoff");
  SessionInfo.session = '';
  SessionInfo.sequenceid = 100000;       
  // Logged In Information  
  SessionInfo.logonName = '';
  SessionInfo.userID = 0;      
  SessionInfo.logonEmailAddress = '';
  SessionInfo.loggedOn = false;
  SessionInfo.commErrorCount = 0;
  SessionInfo.Name = '';
  SessionInfo.notifyMessage = '';
  SessionInfo.flagLevel = 0;
  SessionInfo.currentPage = '';
  // Control Information     
  SessionInfo.notifyMessage = "";                       
  SessionInfo.flagLevel = 0;      
  SessionInfo.isApp = false;
  SessionInfo.refreshCustomerProfile = true;
  //SessionInfo.History = undefined; Bad - don't do this
  console.log("Session History: " + JSON.stringify(SessionInfo.History));
  SessionInfo.registerCounter = 0;  
  SessionInfo.displayHeader = true;
  //SessionInfo.displayFooter = true;  
  SessionInfo.currentPage = '';
  // Functions
  SessionInfo.currentPage = '';
  SessionInfo.currSaveFunc = 0;
  SessionInfo.currDeleteFunc = 0;
  SessionInfo.currClearFunc = 0;
  SessionInfo.searchFunc = undefined;
  SessionInfo.contractAccess = undefined; 
  SessionInfo.currRefreshGridFunc = undefined;
  SessionInfo.currAddGridRowFunc = undefined;
  SessionInfo.currExcelExportFunc = undefined;
  SessionInfo.currExpandLPane = undefined;
  SessionInfo.currCollapseLPane = undefined;
  SessionInfo.currExpandRPane = undefined;
  SessionInfo.currCollapseRPane = undefined;
  // Page Session Info                   
  SessionInfo.CustomerProfile = undefined;
  SessionInfo.StoreLocations = undefined;
  SessionInfo.StoreLocationDetail = undefined;
  SessionInfo.SettingsPage = undefined;  
  SessionInfo.AppMainPage = undefined;
  SessionInfo.StoreLocationDetail = undefined;
  SessionInfo.StoreItemDetail = undefined;
  SessionInfo.KitchenManagement = undefined;
  SessionInfo.SettingsPage = undefined;
  SessionInfo.ManagerPage = undefined;
  SessionInfo.message = "";
  SessionInfo.notifyMessage = "";
  // Key Information
  console.log("Session Cleared - set SessionInfo.forceLogoff"); 
  SessionInfo.forceLogoff = true; // Force to \Login
}
// Log off, logoff, sign out, sign off, signoff, force logoff, force sign off, 
export async function LogOffReset(msg,pprops) {
  console.log(`Logoff Reset - msg: ${msg}`);     
  await LogOff(); // Reset values
  console.log(`SessionInfo.forceLogoff in LogoffReset - set TRUE`);
  SessionInfo.forceLogoff = true;  // Force to \Login
  if (SessionInfo.props) {
    console.log(`navigate to Login Page`);
    SessionInfo.props.navigate("/Login");
  }
}
export function LogOffResetMsg(message) { 
  console.log("LogOffReset: " + message);
  displayError(message);
  LogOffReset(message);
}
export async function TestREST() {
  console.log("Test REST API");
  const CD = await accessOMAPI(70, 0, 0, 0);
  if (!CD)
    console.log("Unable to get CD");
  else
    console.log("CD Returned: " + JSON.stringify(CD));
}
export function reportError(level, message) {
  if (level > 2)
    LogOffResetMsg("Severe Error: " + message);
  else {
    let levelMsg = "Warning (" + SessionInfo.commErrorCount + "):";
    if (level > 1)
      levelMsg = "Rep Error: "
    if (++SessionInfo.commErrorCount > 5)
      LogOffResetMsg(message);
    else
      console.log(levelMsg + message)
  }
}
//-------------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------------
export function setCookie(cName,cValue,expDays) { // writecookie, savecookie
  let date = new Date();
  date.setTime(date.getTime() + (expDays * 24 * 60 * 60 * 1000));
  //const expires = "" + date.toUTCString();
  console.log(`setCookie - ${cName}=${cValue}; expires=${date.toUTCString()}; secure; samesite=strict; path=/`)
  document.cookie = `${cName}=${cValue}; expires=${date.toUTCString()}; secure; samesite=strict; path=/`;
}
export function getCookie(cName) {
  const name = cName + "=";
  const cDecoded = decodeURIComponent(document.cookie); // to be sure it is not encoded
  const cArr = cDecoded.split('; ');
  let res;
  cArr.forEach(val => {
    if (val.indexOf(name) === 0) res = val.substring(name.length);
  })
  return res;
}
export function deleteCookie(cName) { // removecookie
  console.log(`Delete Cookie: ${cName}`);
  document.cookie = `${cName}=x; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
}
//-------------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
export async function GetTableRow(tableID, key) {
  let CD;
  try {
    CD = await accessOMAPI(2, 0, tableID, key); // Get Stanadrd Row
  } catch (error) {
    reportError(1,"GetTableRow Error: " + error.message);
  }
  return CD;
}
export async function GetTableRowTrans(tableID, key) {
  let CD;
  try {
    CD = await accessOMAPI(2, 2, tableID, key); // 2 - Get Translated Row
  } catch (error) {
    reportError(1, "GetTableRowTrans Error: " + error.message);
  }
  return CD;
}
export async function GetTableRowRaw(tableID, key) {
  let CD;
  try {
    CD = await accessOMAPI(2, 1, tableID, key); // No Translate - 0 is standard
  } catch (error) {
    reportError(1, "GetTableRowRaw Error: " + error.message);
  }
  return CD;
}
export async function addContactInfo(item) {             
  let PKID = 0;
  try {                                                                          
    const CD = await accessOMAPI(201, 0, 544162, 0, item);
    //console.log("addContactInfo - CD: " + JSON.stringify(CD));
    item["QuestionID"] = CD.d.PKID;
    PKID = CD.d.PKID;
  } catch (error) {
    reportError(1, "addContactInfo Error: " + error.message);
  }
  return PKID;
}
export async function GetTableValues(tableID,keyName,key,fieldNames) { // Note: Assign CD.d.rows to the table data          
  return await accessOMAPI(21, 0, tableID, key, fieldNames, keyName);
}
export async function GetTableData(tableID, fieldNames) { // Note: Assign CD.d.rows to the table data  
  let CD;
  try {                                     
    CD = await accessOMAPI(1,0,tableID,0,undefined,'',fieldNames);
    //if (SessionInfo.debug === true)
    //  console.log("--> return TableData (" + tableID + "): " + JSON.stringify(CD));
  } catch (error) {
    reportError(1, "GetTableData Error: " + error.message);
  }
  return CD;
}
export async function GetTableDataRaw(tableID) { // Note: Assign CD.d.rows to the table data 
  let CD;
  try {
    CD = await accessOMAPI(1,1,tableID,0);
    //if (SessionInfo.debug === true)
    //  console.log("--> return TableDataRaw (" + tableID + "): " + JSON.stringify(CD));
  } catch (error) {
    reportError(1, "GetTableDataRaw Error: " + error.message);
  }
  return CD;
}
export async function GetTableFilter(tableID, filterCond) { // Note: Assign CD.d.rows to the table data
  //console.log("GetTableFilter: " + tableID + " - filterCond: " + filterCond);           
  let CD;
  try {
    CD = await accessOMAPI(3, 0, tableID, 0, filterCond);
    //if (SessionInfo.debug === true)
    //  console.log("--> return TableFilter (" + tableID + "): " + JSON.stringify(CD));
  } catch (error) {
    reportError(1, "GetTableFilter Error: " + error.message);
  }
  return CD;
}
export async function GetTableSearch(tableID, searchCond, columns) { // Note: Assign CD.d.rows to the table data
  if (!searchCond)
    searchCond = '';
  else if (searchCond[0] !== "*")
    searchCond = "`" + searchCond;  // ` related search - search attached tables   
  if (SessionInfo.debug === true)
    console.log("--> TableSearch: " + JSON.stringify(searchCond));
  let CD;
  try {
    if (!(columns))
      CD = await accessOMAPI(4,2,tableID,0,searchCond); // Translate All values (ActiveParm1=2) 
    else
      CD = await accessOMAPI(4,2,tableID,0,searchCond,'',columns); // Translate All values (2) 
    //if (SessionInfo.debug === true)
    //  console.log("--> return TableSearch (" + tableID + "): " + JSON.stringify(CD));
  } catch (error) {
    reportError(1, "GetTableSearch Error: " + error.message);
  }
  return CD;
}
export async function GetTableSearchRaw(tableID, searchCond, columns, isSub) { // GetTableSearch in Mdx - Note: Assign CD.d.rows to the table data  Note: Can use: *DISP, *DSPO, *EDIT, *EDTO in place of column names
  if (!searchCond)
    searchCond = '';
  else if (searchCond[0] !== "*" && isSub)
    searchCond = "`" + searchCond;
  //if (SessionInfo.debug === true)
    console.log("--> TableSearchRaw: " + JSON.stringify(searchCond));
  const CD = await accessOMAPI(4, 1, tableID, 0, searchCond, '', columns); // NOTranslate values - For Edit Grid 
  //if (SessionInfo.debug === true)
  //  console.log("--> return TableSearchRaw (" + tableID + "): " + JSON.stringify(CD));
  return CD;
}
export async function UpdateRow(tableID,control,allData,item,key,keyName) { // SaveRow
  let CD;
  let PKID = item[keyName];   
  //console.log(`Update Row - tableID: ${tableID} - key: ${key} Item: ${JSON.stringify(item)}`);
  try {
    CD = await accessOMAPI(201,control,tableID,key,item);
    if (PKID == 0) { // New Record
      PKID = CD.d.PKID;
      if (PKID > 0) {
        item[keyName] = PKID;
        if (allData) // Update Grid PKID
          allData[0][keyName] = PKID;
      }
    }
  } catch (error) {
    reportError(1, "Update Row Error: " + error.message);
  }
} 
export async function DeleteRowTry(tableID, key) { // DeleteTableRow
  let CD;
  try {
    console.log(`DeleteRow table: ${tableID}, key: ${key}`); 
    CD = await accessOMAPI(401, 0, tableID, key);
    console.log("DeleteRow post CD: " + JSON.stringify(CD)); 
  } catch (error) {
    reportError(1, "Delete Row Error: " + error.message);
  }
  return CD;
}
export async function DeleteRowForce(tableID,key) { // DeleteTableRow
  let CD;
  try {
    console.log(`DeleteRowForce table: ${tableID}, key: ${key}`);
    CD = await accessOMAPI(402,0,tableID,key);
    console.log(`DeleteRow post CD: ${JSON.stringify(CD)}`);
  } catch (error) {
    reportError(1, "Delete Row Force Error: " + error.message);
  }
  return CD;
} 
//------------------ Support Access ------------------
export async function GetDropdownData(tableID) {
  let CD;
  try {                                        
    CD = await accessOMAPI(11,0,tableID,1); // See GetLookupList in Support
    if (CD.d === undefined || CD.d === null)
      CD.d = [{ ddName: '', ddID: 0 }];
  } catch (error) {
    reportError(1, "Get Dropdown Info: " + error.message);
  }
  return CD;
}
export async function GetDependentDropdownData(tableID,key,dependentOnObjectID) {
  let CD;
  try {
    //console.log("Get Dependent Dropdown table: " + tableID + " Key: " + key);
    CD = await accessOMAPI(13,0,tableID,key,dependentOnObjectID);        
    if (CD.d === undefined || CD.d === null)
      CD.d = [{ ddName: '', ddID: 0 }];
  } catch (error) {
    reportError(1, "Get Dependent Dropdown Info: " + error.message);
  }
  return CD;
} 
// Create a Key Pair
export async function CreateKeys() { // Create Key Pair
//  let pair = keypair(512); //3072); Key Pair Library
//  let PublicKey = pair["public"];
//  PublicKey = PublicKey.substring(31, 101);
//  this.setState({ PublicKey });           
//  let PrivateKey = pair["private"];
//  PrivateKey = PrivateKey.substring(32);
//  PrivateKey = PrivateKey.substring(0, PrivateKey.length - 31);
//  await this.setState({ PrivateKey });
//  await this.saveRecord();
}
//--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

export async function CallOM(modexMethod, tableID, key, control, rdata, columns, callParm, pageID)
{ // Note - Control MUST be set - see OMProxy
          //case 0: None - Supports Column Specification
          //case 1: // Int
          //case 2: // String
          //case 3: // Decimal
          //case 10: // List of Object Values
          //case 11: // List of int Values
          //case 12: // List of string Values
          //case 13: // Dictionary of name-object Values
          //case 14: // Dictionary of name-string Values
          //case 15: // List of Dictionary of name-string Values
          //case 16: // Int Array
          //case 17: // String Array
          //case 18; // Byte Array
          //case 19: // Byte Tuple
          //case 20: // Byte Tuple  
          //case 21: // Bool Array
          //case 23: // Dictionary of int-string Values - not used
          //case 24: // List<Dictionary<int,string>> - not used 
          //case 31: // WorkTaskS
          //case 50: // Type Parameter Array
  // Note - callParm corresponds to ActiveParm2
  //      - control corresponds to ActiveParm1 - but is used to control the type of parameter in rdata
  // Note - columns - array of strings - if integer is passed convert to string
  if (rdata === undefined)
    rdata = '';
  if (!control)
    control = 0;
  else if (control === 0)
    console.log("ERROR: parm control is 0");
  //console.log("rdata typeof: " + typeof (rdata)); 
  if (SessionInfo.debug) {
    if (rdata.length > 200)
      console.log(`CALLOM method: ${modexMethod}, tableID:${tableID}, key:${key}, control:${control}, rdata-lth:${rdata.length}, typeof:${typeof (rdata)}, columns:${columns}, Parm2:${callParm}, pageID:${pageID}`);
    else
      console.log(`CALLOM method: ${modexMethod},tableID:${tableID}, key:${key}, control:${control}, rdata:${rdata}, typeof:${typeof (rdata)}, columns:${columns}, Parm2:${callParm}, pageID:${pageID}`);
  }
  //console.log("CallOM callParm: " + callParm);
  modexMethod = "CheckoutMdx_" + modexMethod;
  return await accessOMAPI(291, control, tableID, key, rdata, modexMethod, columns, callParm, pageID);
  //return CD;
}
export async function CallOMP(modexMethod, rdata1, rdata2, rdata3, rdata4, rdata5, rdata6, rdata7, rdata8, rdata9) {
  let control = 9; // List of parameter values
  let tableID = 0;
  let key = 0;
  let parms = [];
  console.log(`CallOMP - rdata1: ${rdata1}, rdata2: ${rdata2}, rdata3: ${rdata3}`);
  if (rdata1)
    parms.push(rdata1);
  if (rdata2)
    parms.push(rdata2);
  if (rdata3)
    parms.push(rdata3);
  if (rdata4)
    parms.push(rdata4);
  if (rdata5)
    parms.push(rdata5);
  if (rdata6)
    parms.push(rdata6);
  if (rdata7)
    parms.push(rdata7);
  if (rdata8)
    parms.push(rdata8);
  if (rdata9)
    parms.push(rdata9);                                        
  modexMethod = "CheckoutMdx_" + modexMethod;
  console.log(`CallOMP - cnt: ${parms.length}, MM: ${modexMethod}`);
  return await accessOMAPI(291, control, tableID, key, parms, modexMethod,"", 9);
}
// Call Parameter Type
export const CTYP = { NONE: 0, INT: 1, STR: 2, DEC: 3, DBL: 4, DATE: 5, BOOL: 6, LONG: 7, LSTOV: 10, LSTIV: 11, LSTSV: 12, DICTNO: 13, DICTNS: 14, LSTDICTNS: 15, INTA: 16, STRA: 17, BYTA: 18, BT: 19, IMGO: 20, BOOLA: 21, WTSK: 31, TypParm: 50 }
//case 0: None - Supports Column Specification
//case 1: // Int
//case 2: // String
//case 3: // Decimal
//case 10: // List of Object Values
//case 11: // List of int Values
//case 12: // List of string Values
//case 13: // Dictionary of name-object Values
//case 14: // Dictionary of name-string Values
//case 15: // List of Dictionary of name-string Values
//case 16: // Int Array
//case 17: // String Array
//case 18; // Byte Array
//case 19: // Byte Tuple
//case 20: // Image Object
//case 21: // Bool Array
//case 23: // Dictionary of int-string Values - not used
//case 24: // List<Dictionary<int,string>> - not used 
//case 31: // WorkTaskS
//case 50: // Type Parameter Array
// Parameter Type
export const TYP = { INT: 1, STR: 2, DEC: 3, DBL: 4, DT: 5, BL: 6, IAR: 8 };
export async function CallOMT(modexMethod, typ1, rdata1, typ2, rdata2, typ3, rdata3, typ4, rdata4, typ5, rdata5, typ6, rdata6, typ7, rdata7, typ8, rdata8, typ9, rdata9) {
  let control = 50; // List of type/parameter pairs 1-int, 2-string, 3-decimal, 4-double, 5-datetime, 6-bool, 8-integer array
  let tableID = 0;
  let key = 0;
  let parms = [];
  console.log(`CallOMT - method: ${modexMethod} rdata1: ${rdata1}, rdata2: ${rdata2}, rdata3: ${rdata3}`);
  if (rdata1 !== undefined) {
    parms.push(typ1);
    parms.push(rdata1);
  }
  if (rdata2 !== undefined) {
    parms.push(typ2);
    parms.push(rdata2);
    if (rdata3 !== undefined) {
      parms.push(typ3);
      parms.push(rdata3);
      if (rdata4 !== undefined) {
        parms.push(typ4);
        parms.push(rdata4);
        if (rdata5 !== undefined) {
          parms.push(typ5);
          parms.push(rdata5);
          if (rdata6 !== undefined) {
            parms.push(typ6);
            parms.push(rdata6);
            if (rdata7 !== undefined) {
              parms.push(typ7);
              parms.push(rdata7);
              if (rdata8 !== undefined) {
                parms.push(typ8);
                parms.push(rdata8);
                if (rdata9 !== undefined) {
                  parms.push(typ9);
                  parms.push(rdata9);
                }
              }
            }
          }
        }
      }
    }
  }
  modexMethod = "CheckoutMdx_" + modexMethod;
  if (SessionInfo.debug)
    console.log(`CallOMT - cnt: ${parms.length}, MM: ${modexMethod}, parms: ${JSON.stringify(parms)}`);
  return await accessOMAPI(291, control, tableID, key, parms, modexMethod, "", 50);
}
//---- Image I/O ------------------
export async function getImageFileFromServer(imageID,size) {
  // image size: 1-Tiny, 2-Small, 3-Medium, 4-Large, 5-Fill Size
  //console.log(`getImageFileFromServer ID: ${imageID}, size: ${size}`);
  if (imageID <= 1) {
    console.log(`Return Blank Image: ${imageID}`);
    return imagePrep(BlankImage);
  }
  if (!size)
    size = 0;
  console.log(`Get Image ID: ${imageID}`);
  let CD = await CallOM("GetImage",0,imageID,0,'','',size,10); // 10 is pageid?
  //console.log(`getImageFileFromServer CD: ${JSON.stringify(CD)}`);
  return imagePrep(CD.d.Item2);
}
export function imagePrep(imageRaw) { // Image Preparation
  const byteCharacters = window.atob(imageRaw);
  const byteNumbers = new Array(byteCharacters.length);
  console.log(`imagePrep byteNumbers lth: ${byteCharacters.length}`);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  let image = new Blob([byteArray],{ type: 'image/jpeg' }); // Convert to jpeg
  return URL.createObjectURL(image);
}
export function getDocumentType(extension,imageName) {
  let imageType = extension.toLowerCase();
  if (imageType.substring(0, 1) === '.')
    imageType = imageType.substring(1);
  let ix = SessionInfo.DocumentTypes.findIndex(c => c.ddName === imageType);
  if (ix <= 0) {
    displayWarning(`Unknown Image Type: ${imageType} for ${imageName}`);
    ix = 0;
  }
  return imageType;
  //return { type: imageType, indx: ix };
}
export async function saveImageFileToServer(documentFile, imageType, tableObjID, keyID, imageOID) {
  console.log(`saveImageFileToServer:  tableObjID: ${tableObjID}, Key: ${keyID}, imageOID: ${imageOID}, imageType: ${imageType} `);
  if (SessionInfo.isSavingDocument) {
    displayMessage("Document Save in Progress");
    return;
  }
  else if (!documentFile)
    displayError('A file must be selected for Document Upload');
  else {
    let iType = 2;
    switch (imageType) {
      case 'jpg':
        iType = 2;
        break;
      case 'bmp':
        iType = 1;
        break;
      case 'jpeg':
        iType = 2;
        break;
      case 'png':
        iType = 3;
        break;
      case 'gif':
        iType = 4;
        break;
      case 'wmf':
        iType = 5;
        break;
      case 'tiff':
        iType = 6;
        break;
      case 'icon':
        iType = 7;
        break;
      case 'webp': // Not currently support in 4.8
        iType = 8;
        break;
    }
    SessionInfo.isSavingDocument = true;
    if (!imageOID)
      imageOID = 0;
    console.log(`Saving Image for keyID: ${keyID}, ImageType: ${imageType}`);
    //console.log("Save Image " + JSON.stringify(this.UploadFile));
    let CD = await CallOM("SaveImage", tableObjID, keyID, 20, documentFile, iType.toString(), imageOID); // 17
    console.log(`Saved Image ${documentFile.length}`);
    SessionInfo.isSavingDocument = false;
    let ival = CD.d;
    return ival;
  }
}
function componentToHex(c) {
  var hex = c.toString(16);
  return hex.length == 1 ? "0" + hex : hex;
}
//export function rgbToHex(r, g, b) {
//  return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
//}
export function rgbToHex(r,g,b) {
  return "#" + (1 << 24 | r << 16 | g << 8 | b).toString(16).slice(1);
}
export function hexToRgb(hex) {
  hex = hex.trim();
  if (hex.length <= 7) {
    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    console.log(`result: ${result}`);
    return result ? {
      r: parseInt(result[1],16),
      g: parseInt(result[2],16),
      b: parseInt(result[3],16),
      o: 1.0
    } : null;
  } else {
    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    console.log(`long result: ${result}`);
    return result ? {
      r: parseInt(result[1],16),
      g: parseInt(result[2],16),
      b: parseInt(result[3],16),
      o: (parseInt(result[4],16) / 255)
    } : null;
  }
}
//----- Trace to OM -----------------------------
export async function OMTrace(level, message) {
  let rc = -1;
  if (message) {
    console.log(`==> OMTrace: ${message}`);
    let CD = await accessOMAPI(120, 420, level, 0, "", message);
    if (CD)
      rc = CD.x.o;
  }
  return rc;
}
//----- Trace ----------------------------------- 
export async function trace(message,control) {
  try {
    if (!control || control == 0) {
      if (SessionInfo.traceDebug)
        console.log(message)
    }
    else if (control > 2) {
      if (control >= 3)
        console.warn(`warning: ${message} - control: ${control}`);
      else if (control >= 4)
        console.error(`error: ${message} - control: ${control}`);
      else
        console.log(message);
    }
    else if (control >= 10) {
      let xcntrl = control - 10;
      if (xcntrl >= 3)
        console.warn(`warning: ${message} - control: ${control}`);
      else if (xcntrl >= 4)
        console.error(`error: ${message} - control: ${control}`);
      else
        console.log(message);
      OMTrace(xcntrl,message);
    }
    else { // 1 or 2
      if (control > 1 || SessionInfo.traceDebug)
        console.log(message);
    }
  }
  catch (ex) {
    console.log(`Error in Trace: ${ex}`);
  }
}
//--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
export const ConsoleLog = ({ children }) => {
  console.log(children);
  return false;
};
export function displayHex(mesg, value) {
  try {
    let hexStr = toHex(value);
    console.log(mesg + ": " + hexStr);
  }
  catch (error) {
    console.log(error);
  }
}
// Display Message(s) returned from OM
export function displayCDMessage(CD) {
  if (CD.x.m !== '')
    console.log(`display CD Message: '${CD.x.m}' - o: ${CD.x.o}`);
  if (CD.x.o >= 9000) {
    if (CD.x.o >= 9800 && CD.x.o != 9951) { // Error - Red
      displayError(CD.x.m);
      SessionInfo.flagLevel = 3;
    }
    else if (CD.x.o >= 9500) { // Warning - Yellow
      displayWarning(CD.x.m);
      SessionInfo.flagLevel = 2;
    }
    else { // Message - Green 
      SessionInfo.flagLevel = 1;
      if (CD.x.o < 9005 || SessionInfo.debug === true)
        displayMessage(CD.x.m);
    }
    if (SessionInfo.session !== '')
      CD.x.m = ''; // Only erase the message if currently in session
  }
  else {
    if (CD.x.m !== "")
      SessionInfo.flagLevel = 0;
  }
}
export function displayMessage(message) {
  if (message !== undefined && message.length > 0) {
    try { // Add message to the Context message which is displayed by PageHeader 
      if (message === "GREEN") {
        SessionInfo.flagLevel = 1;
        //console.log("Message: " + message + " flag: " + SessionInfo.flagLevel);
      }
      else if (message === "YELLOW")
        SessionInfo.flagLevel = 2;
      else if (message === "RED")
        SessionInfo.flagLevel = 3; 
      else {
        if (message.length > 240)
          message = message.substring(0, 240) + " ...";
        if (SessionInfo.message === "")
          SessionInfo.message = message;
        else {
          if (SessionInfo.message.length > 2200)
            SessionInfo.message = message + '<br/>' + SessionInfo.message.substring(0, 2100) + " ...";
          else
            SessionInfo.message = message + '<br/>' + SessionInfo.message;
        }
        if (SessionInfo.debug === true)
          console.log("--> " + message);
      }
    }
    catch (error) {
      reportError(1, "displayMessage Error: " + error.message);
    }
  }
}  
export function notify(message) {
  SessionInfo.notifyMessage = message;
  displayMessage(message);
}
export async function askQuestion(message) {
  SessionInfo.question = message;
}
function apiFunctionWrapper(query) {
  return new Promise((resolve, reject) => {
    apiFunction(query, (successResponse) => {
      resolve(successResponse);
    }, (errorResponse) => {
      reject(errorResponse)
    });
  });
}
function apiFunction(query, successCallback, errorCallback) {
  if (query === "bad query") {
    errorCallback("problem with the query");
  }
  successCallback("Your query was <" + query + ">");
}  
export function displayNotify(message) {
  try {
    if (message.length > 0) {
      displayMessage("<span style='color:#ed1cd1;font-size:14pt;font-weight:bold'>" + message + "</span>");
      SessionInfo.flagLevel = 1; 
      SessionInfo.notifyMessage = message;
    }
  }
  catch (error) {
    console.log("Error in displayNotify: " + error);
  }
}
export function displayWarning(message) {
  try {
    if (message.length > 0) {
      displayMessage("<span style='color:#BF8D02'>" + message + "</span>");
      SessionInfo.flagLevel = 2;
    }
  }
  catch (error) {
    console.log("displayWarning: " + error);
  }
}          
export function displayError(message) { // OMTrace
  try {
    if (message.length > 0) {
      displayMessage("<span style='color:#ff1a75;font-size:10pt;font-weight:bold'>" + message + "</span>");
      SessionInfo.flagLevel = 3;          
    }
  }
  catch (error) {
    console.log("displayError: " + error);
  }
}
export function displaySevereError(message) { // OMTrace
  try {
    if (message.length > 0) {
      displayMessage("<span style='color:#ff0000;font-size:10pt;font-weight:bold'>" + message + "</span>");
      SessionInfo.flagLevel = 4;
      SessionInfo.setPopupMessageOpen = 1; // Open Main Display
    }
  }
  catch (error) {
    console.log("displayError: " + error);
  }
}
export async function getHelpTopics(search,key,context)  {
  let HelpPages = [];
  try {
    if (!search)
      search = '';
    if (!key)
      key = 0;
    if (!context)
      context = 12;
    //console.log(`Get Help Pages - type: ${context}, key: ${key}, search: ${search}`);
    let CD = await CallOM("GetHelpPages",context,key,CTYP.STR,search);
    //console.log(`Get Help Return - CD: ${CD.x.o}`);
    if (CD.x.o === 0)
      await LogOffResetMsg(`Web Session Has Failed in ${SessionInfo.currentPage} - Session: ${CD.x.s}`,1);
    else if (CD.x.o < 9500) {
      if (CD && CD.d) {
        try {
          //console.log("Help Items: " + JSON.stringify(CD));
          HelpPages = CD.d.rows.map(dataItem => Object.assign({ isFavorite: false,selected: false },dataItem));
          //console.log("HelpPages: " + JSON.stringify(HelpPages));
        } catch (error) {
          await OMTrace(2,`Failure in getHelpTopics - Error: ${error}`);
        }
      }
    }
  } catch (error) {
    console.log(`getHelp error: ${error}`);
    await OMTrace(2,`Failure (2) in getHelpTopics - Error: ${error}`);
  }
  return HelpPages;
}
// Help Detail
export async function getHelpDetail(key,type,modifier) {
  if (!type)
    type = 1; // 1-get by HelpID, 2-get by ObjectID 
  if (!modifier)
    modifier = 1; // 0-No Modifier, 1-basic help mods, 2-styling mods
  console.log(`Help Item: ${key}, type: ${type}`);
  let CD = await CallOM("GetHelpPage",type,modifier,1,key);
  console.log(`Get Return - o: ${CD.x.o}`);
  let helpDetail = "x";
  if (CD.x.o === 0)
    await LogOffResetMsg(`Web Session Has Failed in ${SessionInfo.currentPage} - Session: ${CD.x.s}`,1);
  else if (CD.x.o < 9500) {
    if (CD && CD.d) {
      helpDetail = CD.d;
    }
  }
  return (helpDetail);
}
//--------------------------------------------------------------------------------------------------------------------------------------------
//export async function SaveToIPFS() { // save File on IPFS - onSaveToIPFS = async (event) => {  // Save File to IPFS
//  //event.preventDefault();
//  const accounts = await getEthAccounts(); //bring in user's metamask account walletAddresses 
//  this.setCurrentParticipantID(accounts[0]);
//  if (this.state.ownerBalance === 0)
//    displayError('The contract must be Initialized First - Press: Init Contract Info');
//  else if (accounts[0] !== this.state.ownerAddress) {
//    console.log("Account0: " + accounts[0] + " ownerAddress: " + this.state.ownerAddress + " Current Role: " + this.state.currentRole + " isCurrOwner: " + this.state.isCurrOwner);
//    displayError('Only the Contract Owner can Save a Document');
//  }
//  else {
//    let isInitial = await CFContract.methods.isStepInitial.call({ from: accounts[0] });
//    if (this.state.isSavingDocument) {
//      displayMessage("Document Save in Progress");
//      return;
//    }
//    else if (isInitial === false)
//      displayError("Can only Save a File when in Initial State");
//    else if (!this.state.documentClear)
//      displayError('A file must be selected');
//    else if (!this.state.DocumentKey)
//      displayError('A Document Key Must be Provided');
//    else if (this.state.DocumentKey.length < 8)
//      displayError('The Document Key Must be at least 8 Characters');
//    else {
//      this.state.isSavingDocument = true;
//      displayMessage('Saving Document');
//      let ownerAddress = await CFContract.methods.getOwnerAddress().call({ from: accounts[0] });
//      if (ownerAddress === '') {
//        this.setState({ ownerAddress: accounts[0] });
//        this.state.ownerAddress = accounts[0];
//      }
//      else
//        await this.setState({ ownerAddress });
//      this.state.walletAddresses[0] = this.state.ownerAddress;
//      const key = sjcl.random.randomWords(4);
//      let saltStr = sjcl.codec.base64.fromBits(key);
//      //console.log("saltStr:  " + saltStr);
//      let lth = saltStr.length;
//      //console.log("saltStr lth:  " + lth);
//      if (lth > 16)
//        saltStr = saltStr.toString().substring(0, 16);
//      //console.log("saltStr: " + saltStr);
//      //console.log("document length: " + this.state.documentClear.length);
//      let bytes = new Uint8Array(this.state.documentClear); // Fr-1
//      //console.log("bytes length: " + bytes.length);
//      let bits = toBitArrayCodec(bytes); // Fr-2
//      //console.log("bits length: " + bits.length);
//      let base64bits = sjcl.codec.base64.fromBits(bits); // Fr-3 - Base64 Encode Document  
//      //console.log("base64bits - length: " + base64bits.length);
//      //this.saveTextFile(base64bits, 'before.txt');
//      //-----------------------------------------------------------------------------------
//      // Test decrypt and decode - Keep 
//      //let docBuf = sjcl.codec.base64.toBits(base64bits); // Reduce size of document  
//      //console.log("docBuf-3: " + docBuf); // Bits   
//      //let byteBuf = this.fromBitArrayCodec(docBuf);
//      //console.log("docBuf-2: " + byteBuf);
//      //let byteBuf2 = Uint8Array.from(byteBuf);       
//      //docBuf = new TextDecoder("utf-8").decode(byteBuf2.buffer);
//      //console.log("docBuf-1: " + docBuf);
//      //-----------------------------------------------------------------------------------
//      //let enObj = sjcl.encrypt(this.state.DocumentKey, base64bits, { count: 2048, salt: saltStr, ks: 256 }); 
//      //console.log("before encrypt dockey Length: " + base64bits.length); // Bits  
//      let encDocumentStr = sjcl.encrypt(this.state.DocumentKey, base64bits, { salt: saltStr, ks: 256 }); //, { count: 2048, salt: saltStr, ks: 256 }); 
//      //console.log("encrypted document Length: " + encDocumentStr.length); // Bits  
//      //--------------------------------------------------------  
//      //let salt = "jrEp464459dE2Tyls="; //                                                                                         
//      //var out = sjcl.encrypt("password", "The quick brown fox jumps over the lazy dog", { count: 2048, salt: salt, ks: 256 });
//      //console.log("Encrypt Object: " + enObj);
//      //let encObj = JSON.parse(out);
//      //displayMessage("encrypt: " + encObj.ct);
//      //out = sjcl.decrypt("password", out);
//      //displayMessage("original: " + out);
//      //------------------------------------------------------------
//      let encObj = JSON.parse(encDocumentStr);
//      let encBuffer = encObj.ct;
//      //console.log("Encrypted: " + buffer);
//      //console.log("Plain: " + this.state.buffer);
//      //out = sjcl.decrypt("password", this.state.DocumentKey);
//      //displayMessage("encrypted: " + out);

//      this.setState({ DocumentNonce: encObj.iv }); // Nonce
//      this.setState({ DocumentSalt: saltStr });      // Salt 
//      let ContractDocumentType = GetFileExtension(this.state.ContractFileName);
//      this.setState({ ContractDocumentType });
//      //encObj.ct = '';
//      encDocumentStr = JSON.stringify(encObj);
//      this.setState({ ipfsEncDocument: encDocumentStr });
//      //console.log("Encrypt Str: " + encObjStr);
//      //---------------------------------------------------

//      //save document to IPFS,return its hash#, and set hash# to state
//      //https://github.com/ipfs/interface-ipfs-core/blob/master/SPEC/FILES.md#add  
//      await ipfs.add(Buffer.from(encBuffer), (ipfsError, IPFSDocumentAddress) => {
//        if (ipfsError)
//          console.log("IPFS Error: " + ipfsError);
//        console.log("IPFS Doc Address: " + IPFSDocumentAddress);
//        this.setState({ IPFSDocumentAddress: IPFSDocumentAddress[0].hash });
//        this.saveDocumentInContract(); // Save the Document and supporting information in the Contract
//        // Init Contract will also save the document hash (address in IPFS)
//        if (this.state.CFProjectStatusID >= 4)
//          displayMessage("Encrypted Document (" + ContractDocumentType + ") saved on IPFS and in the Contract");
//      }); //await ipfs.add 
//      if (this.state.CFProjectStatusID >= 4 && ContractDocumentType === 'pdf') {
//        // Display the file
//        var blob = new Blob([this.state.documentClear], { type: 'application/pdf' }); //"text/plain;charset=utf-8" });
//        var url = URL.createObjectURL(blob);
//        var a = document.createElement('a');
//        a.download = "loadFile.pdf";
//        a.href = url;
//        a.click();
//      }
//      this.state.isSavingDocument = true;
//    }
//  }
//}; //onSaveToIPFS  
//---------- General Functions --------------------------------------------
export function toHex(str) {
  var arr1 = [];
  for (var n = 0, l = str.length; n < l; n++) {
    var hex = Number(str.charCodeAt(n)).toString(16);
    if (hex.length === 1)
      hex = '0' + hex;
    arr1.push(hex);
  }
  return arr1.join('');
}

export function MaskString(str,mask) {
  let mlth = mask.length;
  if (!str)
    str = '                   '.substring(0,mlth);
  //console.log(`mask str: ${str}`);
  let mpos = 0;
  let spos = 0;
  let retstr = '';
  while (mpos < mlth) {
    if (mask[mpos] === '#')
      retstr += str[spos++];
    else
      retstr += mask[mpos];
    mpos++;
  }
  //console.log(`masked str: ${retstr}`);
  return retstr;
}
export function UnmaskString(str,mask) {
  let mpos = 0;
  let spos = 0;
  let slth = str.length;
  let mlth = mask.length;
  let retstr = '';
  while (mpos < mlth) {
    if (mask[mpos] === '#')
      retstr += str[mpos];
    mpos++;
    //console.log(`unmstr: ${retstr}, spos: ${spos}, mpos: ${mpos}`);
  }
  //console.log(`unmasked str: ${retstr}, mask: ${mask}, slth: ${slth}, mlth: ${mlth}`);
  return retstr;
}   

export function GetFileExtension(filename) {
  //console.log("filename: " + filename);
  var a = filename.split(".");         
  //onsole.log("a: " + JSON.stringify(a));
  if (a.length <= 1 || (a[0] === "" && a.length === 2)) {
    return "";
  }
  return a.pop();
}
export function saveTextFile(docBuff, filename) {
  var blob = new Blob([docBuff], { type: 'text/plain;charset=utf-8' });
  var url = URL.createObjectURL(blob);
  var a = document.createElement('a');
  a.download = filename;
  a.href = url;
  a.click();
}
export function convertDataURIToBinary(dataURI) {
  const BASE64_MARKER = ';base64,';
  var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
  var base64 = dataURI.substring(base64Index);
  var raw = window.atob(base64);
  var rawLength = raw.length;
  var array = new Uint8Array(new ArrayBuffer(rawLength));

  for (var i = 0; i < rawLength; i++) {
    array[i] = raw.charCodeAt(i);
  }
  return array;
}
//-----
function escapeHTML(str) {
  return str.replace(/[&<>"'\/]/g,function (char) {
    switch (char) {
      case '&':
        return '&amp;';
      case '<':
        return '&lt;';
      case '>':
        return '&gt;';
      case '"':
        return '&quot;';
      case '\\':
      return '&#39;';
      case '/':
        return '&#x2F;';
      default:
        return char;
    }
  });
}
// Encoding involves converting special characters into their corresponding HTML entities, which are not executable by a web browser
//function encodeInput(input) {
//  const encoded = document.createElement('div');
//  encoded.innerText = input;
//  return encoded.innerHTML;
//}
// HTTP-only cookies are cookies that can only be accessed by the web server, not by client-side JavaScript code. This prevents attackers from stealing sensitive information, such as login credentials, by injecting malicious code into your webpages.
// Example document.cookie = "sessionID=12345; HttpOnly";
//-------------------------------------------------------------------------------------------------  
// Requires sjcl library - which has problems!
//export function toBitArrayCodec(bytes) {
//  var out = [], i, tmp = 0;
//  for (i = 0; i < bytes.length; i++) {
//    tmp = (tmp << 8) | bytes[i];
//    if ((i & 3) === 3) {
//      out.push(tmp);
//      tmp = 0;
//    }
//  }
//  if (i & 3) {
//    out.push(sjcl.bitArray.partial(8 * (i & 3), tmp));
//  }
//  return out;
//}
//// Convert from a bitArray to an array of bytes.
//export function fromBitArrayCodec(arr) {
//  let out = [];
//  let bl = sjcl.bitArray.bitLength(arr), i, tmp;
//  for (i = 0; i < bl / 8; i++) {
//    if ((i & 3) === 0) {
//      tmp = arr[i / 4];
//    }
//    out.push(tmp >>> 24);
//    tmp <<= 8;
//  }
//  return out; //.buffer; // Convert to Array Buffer
//}
                 