//----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// StoreLocations - The Details associated with a Store
//              Version 1.061 - November 25, 2023
//----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
import React,{ Component } from 'react';
import { withRouter } from './withRouter';
import { Buffer } from 'buffer';
import { Grid as TGrid,GridColumn as Column } from '@progress/kendo-react-grid';
import { TabStrip,TabStripTab,Splitter } from '@progress/kendo-react-layout';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { RadialGauge } from '@progress/kendo-react-gauges';
import { Chart,ChartTitle,ChartLegend,ChartSeries,ChartSeriesItem,ChartSeriesLabels,ChartCategoryAxis,ChartCategoryAxisItem } from '@progress/kendo-react-charts';
import "hammerjs";
import { DateTimePicker } from '@progress/kendo-react-dateinputs';
import { Popup } from '@progress/kendo-react-popup';
import { Button } from '@progress/kendo-react-buttons';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { orderBy,filterBy } from '@progress/kendo-data-query';
import { ListView,ListViewHeader,ListViewFooter } from '@progress/kendo-react-listview';
import { Switch,MaskedTextBox } from "@progress/kendo-react-inputs";
import { NumericTextBox } from '@progress/kendo-react-inputs';
import { MultiSelect } from '@progress/kendo-react-dropdowns';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { QRCode } from "@progress/kendo-react-barcodes";
import { Avatar } from '@progress/kendo-react-layout';
import { ExternalDropZone,Upload } from "@progress/kendo-react-upload";
import { ResSchedControl,getResTaskID,Iframe } from './CommonControls.js';
import { Draggable,Icon,Typography,SvgIcon,} from "@progress/kendo-react-common";
import { arrowsMoveIcon } from "@progress/kendo-svg-icons";
import { Surface } from "@progress/kendo-drawing";
import { ListBox,ListBoxToolbar,processListBoxData,processListBoxDragAndDrop,} from "@progress/kendo-react-listbox";

import { restaurantDisplay } from "./RestaurantDisplay.js";

import { SessionInfo } from './App';
import { GetMonth } from './CommonFormat.js';
import PageHeader from './PageHeader.js';
import PageFooter from './PageFooter.js';
import PageSide from './PageSide.js';
import { CallOM,OMTrace,GetTableRow,GetTableRowRaw,GetTableRowTrans,GetTableData,GetTableSearch,GetTableSearchRaw,UpdateRow,DeleteRowTry,DeleteRowForce,displayMessage,displayError,displayNotify,getHelpTopics,getHelpDetail,GetDropdownData,GetDependentDropdownData,saveImageFileToServer,getDocumentType,getImageFileFromServer,displayWarning,LogOffResetMsg,MaskString,UnmaskString,LogOffReset,CTYP } from './CommonCode.js';
import { timeDisplay,DayOfWeekDropDownCell,TimeOverrideDropDownCell,DaysOfWeekList,SetSeasonCodes,ServiceTypeDropDownCell,SetServiceTypes,SetTimeOverrideTypes,TimePickerCell,MoneyCell,PercentCell,BoolCell,cellDateTime,HourMinCell,cellCurrency,cellCurrencyAmt,cellCurrencyAmtOwe,SetTimeOfDay,TimeOfDayDropDownCell,SetDayOfWeek,DayOfWeekDD,PricingTypeDropDownCell,SetPricingType,SetProductItemCategory,SetProductItemGroups,ProductItemCategoryDropDownCell,SetProductItemGroup,ProductItemGroupDropDownCell,SetProductType,ProductTypeDropDownCell,SetLocations,SublocationTypesDropDownCell,SetSublocationTypes,LocationDropDownCell,EditGridCommandCell,LocationSizesDropDownCell,SetLocationSizes,LocationStatusSetDropDownCell,SetLocationStatusSet } from './CommonFormat.js';

import { LoadTableInformation,SaveTableInformation,getAmenities } from './AppCommon.js';

import { ReactComponent as HelpAbout } from "./images/help-about.svg";

//===========================================--------------------------------------------------   
var TaskData = [];
//=========================================-------------------------------------------------

//------ Object Definition For StoreItems -- Date: 2024-04-04-1:59 PM ------
const StoreItemsOID = 544619;
const StoreItemsO = [{ n: 'StoreItemID',t: 195,p: 100 },{ n: 'StoreLocationID',t: 195,p: 100 },{ n: 'ReferenceItemID',t: 195,p: 100 },{ n: 'AssociatedItemID',t: 195,p: 100 },{ n: 'ItemName',t: 21,p: 0 },{ n: 'ItemDescription',t: 21,p: 0 },{ n: 'ItemFullDescription',t: 29,p: 0 },{ n: 'ItemNote',t: 21,p: 0 },{ n: 'ProductItemCategoryID',t: 195,p: 100 },{ n: 'ProductItemGroupID',t: 195,p: 100 },{ n: 'ProductItemTypeID',t: 195,p: 100 },{ n: 'BrandID',t: 195,p: 100 },{ n: 'UPCNumber',t: 21,p: 0 },{ n: 'BarcodeNumber',t: 21,p: 0 },{ n: 'ItemStatusID',t: 195,p: 100 },{ n: 'PackagingTypeID',t: 195,p: 100 },{ n: 'ItemSizeCodeID',t: 195,p: 100 },{ n: 'TotalQuantity',t: 16,p: 0 },{ n: 'TotalNetWeight',t: 16,p: 0 },{ n: 'ReservedQuantity',t: 16,p: 0 },{ n: 'LockedQuantity',t: 16,p: 0 },{ n: 'AvailableQuantity',t: 16,p: 0 },{ n: 'DocumentID',t: 41,p: 0 },{ n: 'ImageID',t: 41,p: 0 },{ n: 'ItemPrice',t: 28,p: 0 },{ n: 'OverridePrice',t: 28,p: 0 },{ n: 'PromotionPrice',t: 28,p: 0 },{ n: 'Promotion2Price',t: 28,p: 0 },{ n: 'PromotionType',t: 201,p: 0 },{ n: 'StorePromotionID',t: 195,p: 100 },{ n: 'ShelfLifeDays',t: 24,p: 0 },{ n: 'ItemNetWeight',t: 16,p: 0 },{ n: 'PricingTypeID',t: 195,p: 100 },{ n: 'TaxableTypeID',t: 195,p: 100 },{ n: 'RateTypeID',t: 195,p: 100 },{ n: 'SeasonCodeID',t: 195,p: 100 },{ n: 'DayOfWeek',t: 201,p: 0 },{ n: 'DaysOfWeek',t: 15,p: 0 },{ n: 'TimeOfDayID',t: 195,p: 100 },{ n: 'WeightUnitsCodeID',t: 195,p: 100 },{ n: 'InventoryControlTypeID',t: 201,p: 0 },{ n: 'StorageControlID',t: 195,p: 100 },{ n: 'ItemControl',t: 15,p: 0 },{ n: 'IngredientsText',t: 21,p: 0 },{ n: 'UsageInstructions',t: 21,p: 0 },{ n: 'Calories',t: 16,p: 0 },{ n: 'StoreInventoryLocationID',t: 195,p: 100 },{ n: 'TotalReceivedQuantity',t: 16,p: 0 },{ n: 'TotalSoldQuantity',t: 16,p: 0 },{ n: 'TotalDisposedQuantity',t: 16,p: 0 },{ n: 'TotalLostQuantity',t: 16,p: 0 },{ n: 'AssociatedItemCount',t: 24,p: 0 },{ n: 'IsRecordActive',t: 22,p: 0 }];
//       Object Value Definition For StoreItems
const SIV = { 'StoreItemID': 0,'StoreLocationID': 0,'ReferenceItemID': 0,'AssociatedItemID': 0,'ItemName': '','ItemDescription': '','ItemFullDescription': '','ItemNote': '','ProductItemCategoryID': 0,'ProductItemGroupID': 0,'ProductItemTypeID': 0,'BrandID': 0,'UPCNumber': '','BarcodeNumber': '','ItemStatusID': 0,'PackagingTypeID': 0,'ItemSizeCodeID': 0,'TotalQuantity': 0,'TotalNetWeight': 0,'ReservedQuantity': 0,'LockedQuantity': 0,'AvailableQuantity': 0,'DocumentID': '','ImageID': '','ItemPrice': 0,'OverridePrice': 0,'PromotionPrice': 0,'Promotion2Price': 0,'PromotionType': 0,'StorePromotionID': 0,'ShelfLifeDays': 0,'ItemNetWeight': 0,'PricingTypeID': 0,'TaxableTypeID': 0,'RateTypeID': 0,'SeasonCodeID': 0,'DayOfWeek': 0,'DaysOfWeek': 0,'TimeOfDayID': 0,'WeightUnitsCodeID': 0,'InventoryControlTypeID': 0,'StorageControlID': 0,'ItemControl': 0,'IngredientsText': '','UsageInstructions': '','Calories': 0,'StoreInventoryLocationID': 0,'TotalReceivedQuantity': 0,'TotalSoldQuantity': 0,'TotalDisposedQuantity': 0,'TotalLostQuantity': 0,'AssociatedItemCount': 0,'IsRecordActive': false };
//       Object Index Definition For StoreItems
const SIX = { 'StoreItemID': 0,'StoreLocationID': 1,'ReferenceItemID': 2,'AssociatedItemID': 3,'ItemName': 4,'ItemDescription': 5,'ItemFullDescription': 6,'ItemNote': 7,'ProductItemCategoryID': 8,'ProductItemGroupID': 9,'ProductItemTypeID': 10,'BrandID': 11,'UPCNumber': 12,'BarcodeNumber': 13,'ItemStatusID': 14,'PackagingTypeID': 15,'ItemSizeCodeID': 16,'TotalQuantity': 17,'TotalNetWeight': 18,'ReservedQuantity': 19,'LockedQuantity': 20,'AvailableQuantity': 21,'DocumentID': 22,'ImageID': 23,'ItemPrice': 24,'OverridePrice': 25,'PromotionPrice': 26,'Promotion2Price': 27,'PromotionType': 28,'StorePromotionID': 29,'ShelfLifeDays': 30,'ItemNetWeight': 31,'PricingTypeID': 32,'TaxableTypeID': 33,'RateTypeID': 34,'SeasonCodeID': 35,'DayOfWeek': 36,'DaysOfWeek': 37,'TimeOfDayID': 38,'WeightUnitsCodeID': 39,'InventoryControlTypeID': 40,'StorageControlID': 41,'ItemControl': 42,'IngredientsText': 43,'UsageInstructions': 44,'Calories': 45,'StoreInventoryLocationID': 46,'TotalReceivedQuantity': 47,'TotalSoldQuantity': 48,'TotalDisposedQuantity': 49,'TotalLostQuantity': 50,'AssociatedItemCount': 51,'IsRecordActive': 52 };

//------ Object Definition For CustomOrders -- Date: 2023-11-30-10:40 AM ------
const CustomOrdersOID = 545072;
const CustomOrdersO = [{ n: 'CustomOrderID',t: 195,p: 100 },{ n: 'StoreLocationID',t: 195,p: 100 },{ n: 'CustomerID',t: 195,p: 100 },{ n: 'CustomOrderParentID',t: 195,p: 100 },{ n: 'CreatedBy',t: 195,p: 100 },{ n: 'CustomOrderType',t: 201,p: 0 },{ n: 'RequestDateTime',t: 35,p: 0 },{ n: 'ScheduledPrepDateTime',t: 35,p: 0 },{ n: 'PickupDeliveryDateTime',t: 35,p: 0 },{ n: 'Persons',t: 120,p: 0 },{ n: 'OMUserID',t: 195,p: 100 },{ n: 'TotalTaxAmount',t: 28,p: 0 },{ n: 'TotalTax2Amount',t: 28,p: 0 },{ n: 'TotalTax3Amount',t: 28,p: 0 },{ n: 'AmountPaid',t: 28,p: 0 },{ n: 'DiscountAmount',t: 28,p: 0 },{ n: 'CreditAmountUsed',t: 28,p: 0 },{ n: 'RefundAmount',t: 16,p: 0 },{ n: 'UserName',t: 21,p: 0 },{ n: 'AmountPaidWithTip',t: 28,p: 0 },{ n: 'AddressID',t: 195,p: 100 },{ n: 'PhoneNumber',t: 21,p: 0 },{ n: 'ItemCount',t: 16,p: 0 },{ n: 'OptionCount',t: 24,p: 0 },{ n: 'OptionsPrice',t: 28,p: 0 },{ n: 'TotalQuantity',t: 16,p: 0 },{ n: 'OrderCreationDate',t: 35,p: 0 },{ n: 'OrderPaidDate',t: 35,p: 0 },{ n: 'Rating',t: 16,p: 0 },{ n: 'Persons',t: 120,p: 0 },{ n: 'OMUserID',t: 195,p: 100 },{ n: 'OrderNotes',t: 21,p: 0 },{ n: 'RefundTaxAmount',t: 28,p: 0 },{ n: 'PaymentAuthCode',t: 21,p: 0 },{ n: 'TransactionID',t: 24,p: 0 },{ n: 'UserName',t: 21,p: 0 },{ n: 'OrderSourceID',t: 195,p: 100 },{ n: 'POSCheckNumber',t: 21,p: 0 },{ n: 'ThirdPartyOrderNumber',t: 21,p: 0 },{ n: 'VerifiedDate',t: 35,p: 0 },{ n: 'LastModifiedOn',t: 35,p: 0 },{ n: 'OrderPreparationTypeID',t: 195,p: 100 },{ n: 'ReturnCycle',t: 24,p: 0 },{ n: 'CompletedReturnCycle',t: 24,p: 0 },{ n: 'SublocationID',t: 195,p: 100 },{ n: 'SeatNumber',t: 24,p: 0 },{ n: 'SeatName',t: 21,p: 0 },{ n: 'IndividualItems',t: 22,p: 0 },{ n: 'RecordProcessed',t: 22,p: 0 },{ n: 'ActiveCounted',t: 22,p: 0 },{ n: 'PaymentInProcess',t: 22,p: 0 },{ n: 'PaymentComplete',t: 22,p: 0 }];
//       Object Value Definition For CustomOrders
const COV = { 'CustomOrderID': 0,'StoreLocationID': 0,'CustomerID': 0,'CustomOrderParentID': 0,'CreatedBy': 0,'CustomOrderType': 0,'RequestDateTime': new Date(),'ScheduledPrepDateTime': new Date(),'PickupDeliveryDateTime': new Date(),'Persons': '','OMUserID': 0,'TotalTaxAmount': 0,'TotalTax2Amount': 0,'TotalTax3Amount': 0,'AmountPaid': 0,'DiscountAmount': 0,'CreditAmountUsed': 0,'RefundAmount': 0,'UserName': '','AmountPaidWithTip': 0,'AddressID': 0,'PhoneNumber': '','ItemCount': 0,'OptionCount': 0,'OptionsPrice': 0,'TotalQuantity': 0,'OrderCreationDate': new Date(),'OrderPaidDate': new Date(),'Rating': 0,'Persons': '','OMUserID': 0,'OrderNotes': '','RefundTaxAmount': 0,'PaymentAuthCode': '','TransactionID': 0,'UserName': '','OrderSourceID': 0,'POSCheckNumber': '','ThirdPartyOrderNumber': '','VerifiedDate': new Date(),'LastModifiedOn': new Date(),'OrderPreparationTypeID': 0,'ReturnCycle': 0,'CompletedReturnCycle': 0,'SublocationID': 0,'SeatNumber': 0,'SeatName': '','IndividualItems': false,'RecordProcessed': false,'ActiveCounted': false,'PaymentInProcess': false,'PaymentComplete': false };
//       Object Index Definition For CustomOrders
const COX = { 'CustomOrderID': 0,'StoreLocationID': 1,'CustomerID': 2,'CustomOrderParentID': 3,'CreatedBy': 4,'CustomOrderType': 5,'RequestDateTime': 6,'ScheduledPrepDateTime': 7,'PickupDeliveryDateTime': 8,'Persons': 9,'OMUserID': 10,'TotalTaxAmount': 11,'TotalTax2Amount': 12,'TotalTax3Amount': 13,'AmountPaid': 14,'DiscountAmount': 15,'CreditAmountUsed': 16,'RefundAmount': 17,'UserName': 18,'AmountPaidWithTip': 19,'AddressID': 20,'PhoneNumber': 21,'ItemCount': 22,'OptionCount': 23,'OptionsPrice': 24,'TotalQuantity': 25,'OrderCreationDate': 26,'OrderPaidDate': 27,'Rating': 28,'Persons': 29,'OMUserID': 30,'OrderNotes': 31,'RefundTaxAmount': 32,'PaymentAuthCode': 33,'TransactionID': 34,'UserName': 35,'OrderSourceID': 36,'POSCheckNumber': 37,'ThirdPartyOrderNumber': 38,'VerifiedDate': 39,'LastModifiedOn': 40,'OrderPreparationTypeID': 41,'ReturnCycle': 42,'CompletedReturnCycle': 43,'SublocationID': 44,'SeatNumber': 45,'SeatName': 46,'IndividualItems': 47,'RecordProcessed': 48,'ActiveCounted': 49,'PaymentInProcess': 50,'PaymentComplete': 51 };

//------ Object Definition For StorePromotions -- Date: 2023-09-12-2:47 PM ------
const StorePromotionsOID = 544701;
const StorePromotionsO = [{ n: 'StorePromotionID',t: 195,p: 100 },{ n: 'StorePromotionName',t: 21,p: 0 },{ n: 'PromotionDescription',t: 21,p: 0 },{ n: 'StoreLocationID',t: 195,p: 100 },{ n: 'StoreGroupID',t: 195,p: 100 },{ n: 'StoreItemID',t: 195,p: 100 },{ n: 'ProductItemCategoryID',t: 195,p: 100 },{ n: 'ProductItemGroupID',t: 195,p: 100 },{ n: 'ProductItemTypeID',t: 195,p: 100 },{ n: 'PromoStoreItemID',t: 195,p: 100 },{ n: 'PromoItemCategoryID',t: 24,p: 0 },{ n: 'DaysForDiscount',t: 24,p: 0 },{ n: 'DiscountAmount',t: 28,p: 0 },{ n: 'DiscountPercentage',t: 23,p: 8 },{ n: 'PromotionPrice',t: 28,p: 0 },{ n: 'PromotionType',t: 201,p: 0 },{ n: 'StartDateTime',t: 35,p: 0 },{ n: 'EndDateTime',t: 35,p: 0 },{ n: 'ImageID',t: 41,p: 0 },{ n: 'PromotionIsActive',t: 22,p: 0 },{ n: 'MaxUseCount',t: 24,p: 0 },{ n: 'UsedCount',t: 24,p: 0 }];
//       Object Value Definition For StorePromotions
const SPV = { 'StorePromotionID': 0,'StorePromotionName': '','PromotionDescription': '','StoreLocationID': 0,'StoreGroupID': 0,'StoreItemID': 0,'ProductItemCategoryID': 0,'ProductItemGroupID': 0,'ProductItemTypeID': 0,'PromoStoreItemID': 0,'PromoItemCategoryID': 0,'DaysForDiscount': 0,'DiscountAmount': 0,'DiscountPercentage': 0,'PromotionPrice': 0,'PromotionType': 0,'StartDateTime': new Date(),'EndDateTime': new Date(),'ImageID': '','PromotionIsActive': false,'MaxUseCount': 0,'UsedCount': 0 };
//       Object Index Definition For StorePromotions
const SPX = { 'StorePromotionID': 0,'StorePromotionName': 1,'PromotionDescription': 2,'StoreLocationID': 3,'StoreGroupID': 4,'StoreItemID': 5,'ProductItemCategoryID': 6,'ProductItemGroupID': 7,'ProductItemTypeID': 8,'PromoStoreItemID': 9,'PromoItemCategoryID': 10,'DaysForDiscount': 11,'DiscountAmount': 12,'DiscountPercentage': 13,'PromotionPrice': 14,'PromotionType': 15,'StartDateTime': 16,'EndDateTime': 17,'ImageID': 18,'PromotionIsActive': 19,'MaxUseCount': 20,'UsedCount': 21 };

//------ Object Definition For StoreLocations -- Date: 2023-09-12-4:44 PM ------
const StoreLocationsOID = 544596;
const StoreLocationsO = [{ n: 'StoreLocationID',t: 195,p: 100 },{ n: 'StoreName',t: 21,p: 0 },{ n: 'Description',t: 21,p: 0 },{ n: 'CompanyID',t: 195,p: 100 },{ n: 'LocationID',t: 195,p: 100 },{ n: 'StoreLocationControl',t: 15,p: 0 },{ n: 'AreaSize',t: 16,p: 0 },{ n: 'ContactID',t: 195,p: 100 },{ n: 'StoreGroupID',t: 195,p: 100 },{ n: 'TaxRate',t: 16,p: 0 },{ n: 'TaxRate2',t: 16,p: 0 },{ n: 'TaxRate3',t: 16,p: 0 },{ n: 'TaxRuleID',t: 195,p: 100 },{ n: 'MaximumPurchaseAmount',t: 28,p: 0 },{ n: 'StandardMarkupPercent',t: 16,p: 8 },{ n: 'ExtendedMarkupPercent',t: 16,p: 8 },{ n: 'SetPriceType',t: 201,p: 0 },{ n: 'SpecialtyList',t: 21,p: 0 },{ n: 'MarketingMessage',t: 21,p: 0 },{ n: 'PromotionalMessage',t: 21,p: 0 },{ n: 'ImageID',t: 41,p: 0 },{ n: 'StoreAmenities',t: 15,p: 0 },{ n: 'DayPeriods',t: 15,p: 0 },{ n: 'TipRate1',t: 16,p: 0 },{ n: 'TipRate2',t: 16,p: 0 },{ n: 'TipRate3',t: 16,p: 0 },{ n: 'CurrentInStoreWaitTime',t: 24,p: 0 },{ n: 'CurrentPickupWaitTime',t: 24,p: 0 },{ n: 'CurrentOrdersInQueue',t: 24,p: 0 },{ n: 'CurrentDeliveryWaitTime',t: 24,p: 0 }];
//       Object Value Definition For StoreLocations
const SLV = { 'StoreLocationID': 0,'StoreName': '','Description': '','CompanyID': 0,'LocationID': 0,'StoreLocationControl': 0,'AreaSize': 0,'ContactID': 0,'StoreGroupID': 0,'TaxRate': 0,'TaxRate2': 0,'TaxRate3': 0,'TaxRuleID': 0,'MaximumPurchaseAmount': 0,'StandardMarkupPercent': 0,'ExtendedMarkupPercent': 0,'SetPriceType': 0,'SpecialtyList': '','MarketingMessage': '','PromotionalMessage': '','ImageID': '','StoreAmenities': 0,'DayPeriods': 0,'TipRate1': 0,'TipRate2': 0,'TipRate3': 0,'CurrentInStoreWaitTime': 0,'CurrentPickupWaitTime': 0,'CurrentOrdersInQueue': 0,'CurrentDeliveryWaitTime': 0 };
//       Object Index Definition For StoreLocations
const SLX = { 'StoreLocationID': 0,'StoreName': 1,'Description': 2,'CompanyID': 3,'LocationID': 4,'StoreLocationControl': 5,'AreaSize': 6,'ContactID': 7,'StoreGroupID': 8,'TaxRate': 9,'TaxRate2': 10,'TaxRate3': 11,'TaxRuleID': 12,'MaximumPurchaseAmount': 13,'StandardMarkupPercent': 14,'ExtendedMarkupPercent': 15,'SetPriceType': 16,'SpecialtyList': 17,'MarketingMessage': 18,'PromotionalMessage': 19,'ImageID': 20,'StoreAmenities': 21,'DayPeriods': 22,'TipRate1': 23,'TipRate2': 24,'TipRate3': 25,'CurrentInStoreWaitTime': 26,'CurrentPickupWaitTime': 27,'CurrentOrdersInQueue': 28,'CurrentDeliveryWaitTime': 29 };

//------ Object Definition For Sublocations -- Date: 2023-07-07-11:13 AM ------
const SublocationsOID = 517732;
const SublocationsO = [{ n: 'SublocationID',t: 195,p: 100 },{ n: 'Sublocation',t: 21,p: 0 },{ n: 'Description',t: 21,p: 0 },{ n: 'SublocationTypeID',t: 195,p: 100 },{ n: 'LocationID',t: 195,p: 100 },{ n: 'InventoryLocationID',t: 195,p: 100 },{ n: 'ProductItemCategoryID',t: 195,p: 100 },{ n: 'LocationStatusID',t: 195,p: 100 },{ n: 'SortOrder',t: 24,p: 0 },{ n: 'IconID',t: 195,p: 100 },{ n: 'ColorID',t: 195,p: 100 },{ n: 'PositionX',t: 16,p: 0 },{ n: 'PositionY',t: 16,p: 0 },{ n: 'PositionZ',t: 16,p: 0 },{ n: 'LocationWidth',t: 16,p: 0 },{ n: 'LocationLength',t: 16,p: 0 },{ n: 'LocationHeight',t: 16,p: 0 },{ n: 'LocationSizeID',t: 195,p: 100 },{ n: 'LinkToSublocationID',t: 195,p: 100 },{ n: 'AssignToScheduler',t: 22,p: 0 },{ n: 'IsRecordActive',t: 22,p: 0 }];
//       Object Value Definition For Sublocations
const SV = { 'SublocationID': 0,'Sublocation': '','Description': '','SublocationTypeID': 0,'LocationID': 0,'InventoryLocationID': 0,'ProductItemCategoryID': 0,'LocationStatusID': 0,'SortOrder': 0,'IconID': 0,'ColorID': 0,'PositionX': 0,'PositionY': 0,'PositionZ': 0,'LocationWidth': 0,'LocationLength': 0,'LocationHeight': 0,'LocationSizeID': 0,'LinkToSublocationID': 0,'AssignToScheduler': false,'IsRecordActive': false };
//       Object Index Definition For Sublocations
const SX = { 'SublocationID': 0,'Sublocation': 1,'Description': 2,'SublocationTypeID': 3,'LocationID': 4,'InventoryLocationID': 5,'ProductItemCategoryID': 6,'LocationStatusID': 7,'SortOrder': 8,'IconID': 9,'ColorID': 10,'PositionX': 11,'PositionY': 12,'PositionZ': 13,'LocationWidth': 14,'LocationLength': 15,'LocationHeight': 16,'LocationSizeID': 17,'LinkToSublocationID': 18,'AssignToScheduler': 19,'IsRecordActive': 20 };
//
const HelpPagesOID = 178428;
const HelpPagesO = [{ n: 'HelpPageID',t: 24,p: 0 },{ n: 'ObjectID',t: 24,p: 0 },{ n: 'ContextObjectID',t: 24,p: 0 },{ n: 'InstanceID',t: 24,p: 0 },{ n: 'HelpTopic',t: 21,p: 0 },{ n: 'SearchTags',t: 21,p: 0 },{ n: 'OntologyDescription',t: 21,p: 0 },{ n: 'ControlFlags',t: 27,p: 0 },{ n: 'SortOrder',t: 24,p: 0 },{ n: 'HelpTitle',t: 21,p: 0 },{ n: 'HelpWidth',t: 24,p: 0 },{ n: 'HelpHeight',t: 24,p: 0 },{ n: 'Length',t: 24,p: 0 },{ n: 'HelpContent',t: 29,p: 0 }];
//       Object Value Definition For HelpPages
const HPV = { 'HelpPageID': 0,'ObjectID': 0,'ContextObjectID': 0,'InstanceID': 0,'HelpTopic': '','SearchTags': '','OntologyDescription': '','ControlFlags': 0,'SortOrder': 0,'HelpTitle': '','HelpWidth': 0,'HelpHeight': 0,'Length': 0,'HelpContent': '' };
//       Object Index Definition For HelpPages
const HPX = { 'HelpPageID': 0,'ObjectID': 1,'ContextObjectID': 2,'InstanceID': 3,'HelpTopic': 4,'SearchTags': 5,'OntologyDescription': 6,'ControlFlags': 7,'SortOrder': 8,'HelpTitle': 9,'HelpWidth': 10,'HelpHeight': 11,'Length': 12,'HelpContent': 13 };

//------ Object Definition For PricingOverrides -- Date: 2023-08-24-12:00 PM ------
const PricingOverridesOID = 555697;
const PricingOverridesO = [{ n: 'PricingOverrideID',t: 195,p: 100 },{ n: 'OverrideName',t: 21,p: 0 },{ n: 'SeasonCodeID',t: 195,p: 100 },{ n: 'DayOfWeek',t: 201,p: 0 },{ n: 'TimeOfDayID',t: 195,p: 100 },{ n: 'PriceAdjustmentPercent',t: 16,p: 8 },{ n: 'PricingTypeID',t: 195,p: 100 },{ n: 'StoreLocationID',t: 195,p: 100 }];
//       Object Value Definition For PricingOverrides
const POV = { 'PricingOverrideID': 0,'OverrideName': '','SeasonCodeID': 0,'DayOfWeek': 0,'TimeOfDayID': 0,'PriceAdjustmentPercent': 0,'PricingTypeID': 0,'StoreLocationID': 0 };
//       Object Index Definition For PricingOverrides
const POX = { 'PricingOverrideID': 0,'OverrideName': 1,'SeasonCodeID': 2,'DayOfWeek': 3,'TimeOfDayID': 4,'PriceAdjustmentPercent': 5,'PricingTypeID': 6,'StoreLocationID': 7 };
//
//------ Encoded Values - StoreLocationControl ------
const StoreLocationControlFixedValues = [{ n: 'Store Is Active',k: 1 },{ n: 'Store Is Open',k: 2 },{ n: 'Store Has Scan',k: 3 },{ n: 'Store Has Order',k: 4 },{ n: 'Order Is Open',k: 5 }];
//------ Encoded Entries - StoreLocationControl ------
const StoreLocationControlList = ['Store Is Active','Store Is Open','Store Has Scan','Store Has Order','Order Is Open'];
//------ Encoded Values - StoreAmenities ------
const StoreAmenitiesFixedValues = [{ n: 'Wifi Available',k: 1 },{ n: 'Coffee',k: 2 },{ n: 'Specialty Coffee',k: 3 },{ n: 'Salads',k: 5 },{ n: 'Soups',k: 6 },{ n: 'Hot Meals',k: 7 },{ n: 'Desserts',k: 9 },{ n: 'Wine',k: 10 },{ n: 'Beer',k: 11 }];
//------ Encoded Entries - StoreAmenities ------
const StoreAmenitiesList = ['Wifi Available','Coffee','Specialty Coffee','Salads','Soups','Hot Meals','Desserts','Wine','Beer'];
const Amenities = [{ n: 'Wifi Available',k: 1 },{ n: 'Coffee',k: 2 },{ n: 'Specialty Coffee',k: 3 },{ n: 'Salads',k: 5 },{ n: 'Soups',k: 6 },{ n: 'Hot Meals',k: 7 },{ n: 'Desserts',k: 9 },{ n: 'Wine',k: 10 },{ n: 'Cold Beer',k: 11 }];

//------ Object Definition For Locations -- Date: 2023-09-13-3:07 PM ------
const LocationsOID = 509178;
const LocationsO = [{ n: 'LocationID',t: 195,p: 100 },{ n: 'LocationName',t: 21,p: 0 },{ n: 'Description',t: 21,p: 0 },{ n: 'CompanyID',t: 195,p: 100 },{ n: 'LocationTypeID',t: 195,p: 100 },{ n: 'UnitNumber',t: 21,p: 0 },{ n: 'Address',t: 21,p: 0 },{ n: 'CityName',t: 21,p: 0 },{ n: 'ProvinceStateID',t: 195,p: 100 },{ n: 'CountryID',t: 195,p: 100 },{ n: 'PostalCode',t: 21,p: 0 },{ n: 'PhoneNumber',t: 21,p: 0 },{ n: 'EmailAddress',t: 21,p: 0 },{ n: 'ContactID',t: 195,p: 100 },{ n: 'AccountID',t: 195,p: 100 },{ n: 'DUNSNumber',t: 21,p: 0 },{ n: 'GeoLocation',t: 21,p: 0 },{ n: 'TimeZoneID',t: 195,p: 100 },{ n: 'TimeAdjust',t: 24,p: 0 },{ n: 'ControlFlags',t: 27,p: 0 }];
//       Object Value Definition For Locations
const LV = { 'LocationID': 0,'LocationName': '','Description': '','CompanyID': 0,'LocationTypeID': 0,'UnitNumber': '','Address': '','CityName': '','ProvinceStateID': 0,'CountryID': 0,'PostalCode': '','PhoneNumber': '','EmailAddress': '','ContactID': 0,'AccountID': 0,'DUNSNumber': '','GeoLocation': '','TimeZoneID': 0,'TimeAdjust': 0,'ControlFlags': 0 };
//       Object Index Definition For Locations
const LX = { 'LocationID': 0,'LocationName': 1,'Description': 2,'CompanyID': 3,'LocationTypeID': 4,'UnitNumber': 5,'Address': 6,'CityName': 7,'ProvinceStateID': 8,'CountryID': 9,'PostalCode': 10,'PhoneNumber': 11,'EmailAddress': 12,'ContactID': 13,'AccountID': 14,'DUNSNumber': 15,'GeoLocation': 16,'TimeZoneID': 17,'TimeAdjust': 18,'ControlFlags': 19 };
// Retrieved Value Assignment for: Sublocations
//------ Encoded Values - DayPeriods ------
const DayPeriodsFixedValues = [{ n: 'Breakfast',k: 1 },{ n: 'Lunch',k: 2 },{ n: 'Brunch',k: 3 },{ n: 'Happy Hour',k: 4 },{ n: 'Dinner',k: 5 },{ n: 'Morning',k: 6 },{ n: 'Afternoon',k: 7 },{ n: 'Evening',k: 8 },{ n: 'Late Night',k: 9 },{ n: 'All Day',k: 10 },{ n: 'Special Occasion',k: 11 }];
//------ Encoded Entries - DayPeriods ------
const DayPeriodsList = ['Breakfast','Lunch','Brunch','Happy Hour','Dinner','Morning','Afternoon','Evening','Late Night','All Day','Special Occasion'];
const dateFormat = (value) => new Intl.DateTimeFormat('en-CA',{ dateStyle: 'long',timeStyle: 'short' }).format(Date.parse(value));

const uploadRef = React.createRef();
const uploadPromoRef = React.createRef();

const seriesData = [1,2,3,5];

class StoreLocations extends Component {
  //------------------------------------------------------------------------------------------------------
  constructor(props) {
    super(props);
    if (!SessionInfo.session)
      return;
    //console.log("StoreLocationDetail Constructor");
    if (SessionInfo.StoreLocationDetail) { // Data saved for this page and session
      console.log("Restore StoreLocationDetail state");
      this.state = SessionInfo.StoreLocationDetail;
      this.loadExistingPage = true;
    }
    //console.log("StoreLocationDetail Constructor - 2");
    //------------- Edit StoreItem Grid ------------------------------
    this.enterInsertSI = this.enterInsertSI.bind(this);
    this.itemChangeSI = this.itemChangeSI.bind(this);
    this.enterEditSI = this.enterEditSI.bind(this);
    this.saveSI = this.saveSI.bind(this);
    this.cancelSI = this.cancelSI.bind(this);
    this.removeSI = this.removeSI.bind(this);
    this.StoreItemCommandCell = props => (<EditGridCommandCell {...props} edit={this.enterEditSI} remove={this.removeSI} add={this.saveSI} discard={this.removeSI} update={this.saveSI} cancel={this.cancelSI} editField='inEdit' keyField='StoreItemID' nameField='ItemDescription' />);
    //------------- Edit HoursOfOperation Grid ------------------------------
    this.enterInsertHOO = this.enterInsertHOO.bind(this);
    this.itemChangeHOO = this.itemChangeHOO.bind(this);
    this.enterEditHOO = this.enterEditHOO.bind(this);
    this.saveHOO = this.saveHOO.bind(this);
    this.cancelHOO = this.cancelHOO.bind(this);
    this.removeHOO = this.removeHOO.bind(this);
    this.HOOItemCommandCell = props => (<EditGridCommandCell {...props} edit={this.enterEditHOO} remove={this.removeHOO} add={this.saveHOO} discard={this.removeHOO} update={this.saveHOO} cancel={this.cancelHOO} editField='inEdit' keyField='HoursOfOpID' HoursOfOpID='DayOfWeek' />);
    //------------- Edit Sublocation Grid Bindings ------------------------------
    this.enterInsertSublocation = this.enterInsertSublocation.bind(this); // Establish binding to SublocationDetail (this)
    this.itemChangeSublocation = this.itemChangeSublocation.bind(this);
    this.enterEditSublocation = this.enterEditSublocation.bind(this);
    this.saveSublocation = this.saveSublocation.bind(this);
    this.cancelSublocation = this.cancelSublocation.bind(this);
    this.removeSublocation = this.removeSublocation.bind(this);
    this.SublocationCommandCell = props => (<EditGridCommandCell {...props} edit={this.enterEditSublocation} remove={this.removeSublocation} add={this.saveSublocation} discard={this.removeSublocation} update={this.saveSublocation} cancel={this.cancelSublocation} editField='inEdit' keyField='SublocationID' nameField='Sublocation' />);
    //------------- Edit PricingOverride Grid Bindings ------------------------------
    this.enterInsertPricingOverride = this.enterInsertPricingOverride.bind(this); // Establish binding to PricingOverrideDetail (this)
    this.itemChangePricingOverride = this.itemChangePricingOverride.bind(this);
    this.enterEditPricingOverride = this.enterEditPricingOverride.bind(this);
    this.savePricingOverride = this.savePricingOverride.bind(this);
    this.cancelPricingOverride = this.cancelPricingOverride.bind(this);
    this.removePricingOverride = this.removePricingOverride.bind(this);
    this.PricingOverrideCommandCell = props => (<EditGridCommandCell {...props} edit={this.enterEditPricingOverride} remove={this.removePricingOverride} add={this.savePricingOverride} discard={this.removePricingOverride} update={this.savePricingOverride} cancel={this.cancelPricingOverride} editField='inEdit' keyField='PricingOverrideID' nameField='SeasonCode' />);
  }
  //--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------  
  state = {
    StoreLocationID: 0,
    CompanyID: 0,
    LocationID: 0,
    CurrentlyOpen: 0,
    AreaSize: 0,
    ContactID: 0,
    StoreGroupID: 0,
    TaxRate: 0,
    TaxRate2: 0,
    TaxRate3: 0,
    TaxRuleID: 0,
    DayPeriods: 0,
    TipRate1: 0,
    TipRate2: 0,
    TipRate3: 0,
    StoreAmenities: 0,
    MaximumPurchaseAmount: 0,
    MaximumPurchaseAmountS: '',
    StandardMarkupPercent: 0,
    ExtendedMarkupPercent: 0,
    CurrentInStoreWaitTime: 1,
    CurrentPickupWaitTime: 1,
    CurrentDeliveryWaitTime: 1,
    LocationTypeID: 0,

    LocationTypeID: 0,
    ProvinceStateID: 0,
    CountryID: 0,
    //-------------------------------- 
    StoreItemID: 0,
    ReferenceItemID: 0,
    StorePromotionID: 0,
    DiscountPercentage: 0,
    ProductItemCategoryID: 0,
    ProductItemGroupID: 0,
    ProductItemTypeID: 0,
    ShelfLifeDays: 0,
    ItemPrice: 0,
    ItemStatusID: 0,
    PackagingTypeID: 0,
    PricingTypeID: 0,
    RateTypeID: 0,
    PromoStoreItemID: 0,
    PromoItemCategoryID: 0,
    PromotionType: 0,
    //--------------------------------
    CustomOrderID: 0,
    TotalQuantity: 0,
    TotalNetWeight: 0,
    TotalPrice: 0,
    AmountOwing: 0,
    AmountPaid: 0,
    TotalAmount: 0,
    AmountPaidWithTip: 0,
    AmountPaidWithTipS: '',
    DiscountAmount: 0,
    DiscountAmountS: '',
    CreditAmountUsed: 0,
    CreditAmountUsedS: '',
    RefundAmount: 0,
    RefundAmountS: '',
    TipAmount: 0,
    TipAmountS: '',
    TotalTaxAmount: 0,
    TotalTaxAmountS: '',
    TotalTax2Amount: 0,
    TotalTax2AmountS: '',
    TotalTax3Amount: 0,
    TotalTax3AmountS: '',
    TotalDiscountAmount: 0,
    TotalDiscountAmountS: '',
    Price: 0,
    PriceS: '',
    PromotionPrice: 0,
    PromotionPriceS: '',
    TotalCost: 0,
    TotalCostS: '',
    TotalTime: 0,
    StartDateTime: 0,
    EndDateTime: 0,
    OrderCreationDate: 0,
    OrderPaidDate: 0,
    PaymentMethodID: 0,
    IndividualItems: 0,
    PaymentVerifiedID: 0,
    DaysForDiscount: 0,
    PromotionIsActive: 0,
    ImageID: 0,
    AccountID: 0,
    DUNSNumber: '',
    TimeZoneID: 0,
    TimeAdjust: 0,
    //------ End of Field Values --------------------------------------------------------------------------------------------------------------------------
    string: '',

    StoreName: '',
    Description: '',
    SpecialtyList: '',
    MarketingMessage: '',
    PromotionalMessage: '',
    StoreImage: '',

    LocationName: '',
    UnitNumber: '',
    Address: '',
    CityName: '',
    PostalCode: '',
    PhoneNumber: '',
    EmailAddress: '',
    GeoLocation: '',

    FirstName: '',
    AdditionalName: '',
    LastName: '',
    AlternateName: '',
    Organization: '',
    BusinessPhone: '',
    ContractAddress: '',
    //--------------------------------  
    CustomerID: '',
    CustomOrderStatus: '',
    CustomOrderType: '',

    ItemName: '',
    ItemDescription: '',
    ItemFullDescription: '',
    UPCNumber: '',
    BarcodeNumber: '',
    Comments: '',
    //--------------------------------
    PaidComment: '',
    OrderNotes: '',
    StorePromotionName: '',
    PromotionDescription: '',
    //------ Integer Values - HelpPages ------
    HelpPageID: 0,
    ObjectID: 0,
    ContextObjectID: 0,
    InstanceID: 0,
    ControlFlags: 0,
    SortOrder: 0,
    HelpWidth: 0,
    HelpHeight: 0,
    Length: 0,
    LocationSizeID: 0,

    //------ String Values - HelpPages ------
    HelpTopic: '',
    SearchTags: '',
    OntologyDescription: '',
    HelpTitle: '',
    HelpContent: '',
    //--------------------------------

    selectedTopTab: 0,
    selectedSubTab: 0,
    //--------------------------------
    itemStateHasChanged: false,
    ScheduledPrepDateTime: undefined,
    SubTotalPrice: 0,
    StoreLocationPhotoURL: undefined,
    CurrentOrdersInQueue: 0,
    SetPriceType: 0,
    StoreLocationControl: 0,
    IsRecordActive: false,

    CustomerName: "",
    isMainSearch: true,

    HelpPages: [],
    showHelpDetail: false,
    showHelpListPopup: false,

    StoreLocationsData: [],
    storeLocationsSkip: 0,
    storeLocationsTake: 8,
    storeLocationsCount: 0,

    MainStoreItems: [],
    mainStoreItemsSkip: 0,
    mainStoreItemsTake: 18,
    mainStoreItemsCount: 0,

    CustomOrders: [],
    customOrdersSkip: 0,
    customOrdersTake: 8,
    customOrdersCount: 0,
    customOrdersFilter: { logic: "and",filters: [] },
    customOrdersSort: [],
    saveCustomOrders: [],

    CustomOrderItems: [],
    customOrderItemsSkip: 0,
    customOrderItemsTake: 18,
    customOrderItemsCount: 0,
    customOrderItemsFilter: { logic: "and",filters: [] },
    customOrderItemsSort: [],

    CustomOrderItemOptions: [],
    customOrderItemOptionsSkip: 0,
    customOrderItemOptionsTake: 18,
    customOrderItemOptionsCount: 0,
    customOrderItemOptionsFilter: { logic: "and",filters: [] },
    customOrderItemOptionsSort: [],

    StoreItems: [],
    storeItemsSkip: 0,
    storeItemsTake: 8,
    storeItemsCount: 0,
    itemSort: [{ "field": "ItemName","dir": "asc" }],

    InventoryControlTypeIDs: [],
    SeasonCodesDict: [],
    DayOfWeekDict: [],
    TimeOfDayDict: [],
    LocationStatusSetDict: [],

    HoursOfOperation: [],
    hoursOfOperationCount: 0,
    DaysOfWeek: [],
    saveHoursOfOperation: [],
    // Reservations
    ReservationData: [],
    //------ ServiceTypes Data, Save, Page and Filter ------
    ServiceTypes: [],
    // Promotions Data
    Promotions: [],
    promoSkip: 0,
    promoTake: 8,
    promotionsCount: 0,
    promoFilter: { logic: "and",filters: [] },
    promoSort: [],
    PromotionPhotoURL: undefined,
    //------ PricingOverrides Data, Save, Page and Filter ------
    PricingOverrides: [],
    PricingOverridesSkip: 0,
    PricingOverridesTake: 18, //this.takeLines,
    PricingOverridesCount: 0,
    savePricingOverrides: [],
    PricingOverridesFilter: { logic: "and",filters: [] },
    PricingOverridesSort: [],
    PricingOverridesTabNumber: 0,
    //--
    StoreAmenitiesVals: [],
    DayPeriodsVals: [],
    StoreLocationControlVals: [],
    StoreGroupsDict: [],
    ProvinceStatesDict: [],
    Currencies: [],
    ProjectTypes: [], //[{ text: 'Invoice Tracking 2', id: 1 }, { text: 'Supply-Chain 2', id: 2 }],
    Contacts: [],
    LocationTypesDict: [],
    TaxRulesDict: [],
    SublocationTypesDict: [],
    SublocationStatus: [],

    //--------------------------------
    CustomOrdersPending: [],
    CustomOrderItemsPending: [],
    CustomOrdersAccepted: [],
    CustomOrdersReady: [],
    CustomOrdersFulfilled: [],
    CustomOrdersRejected: [],
    TimeOverrideTypes: [],
    //--------------------------------
    ProductItemCategoriesDict: [],
    ProductItemGroupsDict: [],
    ProductItemTypesDict: [],
    ItemStatusSetDict: [],
    PromotionTypesDict: [],
    PackagingTypesDict: [],
    PricingTypesDict: [],
    RateTypesDict: [],
    PaymentMethods: [],
    //------ Sublocations Data, Save, Page and Filter ------
    Sublocations: [],
    SublocationsSkip: 0,
    SublocationsTake: 16,
    SublocationsFilter: { logic: "and",filters: [] },
    SublocationsSort: [],
    //------ Dictionary Arrays ------
    StorePromotions: [],
    StoreLocationsDict: [],
    StoreItemsDict: [],
    Locations: [],
    LocationSizesDict: [],
    SublocationsDict: [],
    SublocationTypesDict: [],
    SublocationStatus: [],
    //--
    TableName: '',
    showQRCode: false,
    showItemQRCode: false,
    fullTake: 16,
    halfTake: 8,
    files: [],  // Used in Upload Control
    UploadFile: undefined,
    ImageType: "",
    DocumentTypeID: 0,
    //------ Dashboard ----    
    activeUsers: 0,
    orderCount: 0,
    itemCount: 0,
    totalPeriodSales: 0,
    TotalTaxAmount: 0,
    TipAmount: 0,
    OptionCount: 0,
    OptionsPrice: 0,
    customerCount: 0,
    totalTransactionCount: 0,

    PeriodType: 0,
    PeriodBack: 0,
    TxDate: new Date(),

    categories: [],
    data1: [0,0,0,0,0,0,0,0,0,0,0,0,0,0],
    dataName: [],
    //---------------------------------
    // Waitlist and reservations
    TableCount: 9,
    TablesInUse: 0,
    TablesDirty: 0,
    TablesReserved: 0,
    TablesAvailable: 0,
    WaitlistCount: 0,
    AverageWaitTime: 0,
    MaxWaitTime: 0,
    WaitList: [],
    SeatedList: [],
    draggedItem: {},
    //---------------------------------
    // Designer
    position: {
      x: 50,
      y: 50,
    },
    initial: null,
    pressed: false,
    dragged: false,
    //--------------------------------
    LeftO: false,
    RightO: false,
    horizontalPanes: [{ size: '20%',min: '20px',collapsible: false },{ min: '300px',collapsible: false },{ size: '20%',collapsed: true,collapsible: true }], // Left, Middle, Right
    verticalPanes: [{ min: '300px' },{ size: '50%',collapsed: true,collapsible: true }],  // Top, Bottom    
    //horizontalSubPanes: [{ size: '50%', min: '500px', collapsible: false, scrollable: true }, {  min: '400px', collapsible: false, scrollable: true }], // Left, Right
  };
  horizSplitOC = JSON.parse('[{"0":"[","collapsible":false,"collapsed":false,"resizable":true,"scrollable":true,"size":"20%"},{"0":"{","collapsible":false,"collapsed":false,"resizable":true,"scrollable":true}, { "collapsible": true, "collapsed": true, "resizable": true, "scrollable": true, "size": "20%" }]');
  horizSplitCC = JSON.parse('[{"0":"[","collapsible":false,"collapsed":false,"resizable":true,"scrollable":true,"size":"50px"},{"0":"{","collapsible":false,"collapsed":false,"resizable":true,"scrollable":true}, { "collapsible": true, "collapsed": true, "resizable": true, "scrollable": true, "size": "20%" }]');
  horizSplitCO = JSON.parse('[{"0":"[","collapsible":false,"collapsed":false,"resizable":true,"scrollable":true,"size":"50px"},{"0":"{","collapsible":false,"collapsed":false,"resizable":true,"scrollable":true}, { "collapsible": true, "collapsed": false, "resizable": true, "scrollable": true, "size": "20%" }]');
  horizSplitOO = JSON.parse('[{"0":"[","collapsible":false,"collapsed":false,"resizable":true,"scrollable":true,"size":"20%"},{"0":"{","collapsible":false,"collapsed":false,"resizable":true,"scrollable":true}, { "collapsible": true, "collapsed": false, "resizable": true, "scrollable": true, "size": "20%" }]');

  // Values
  CurrentStoreLocationID = 0;
  CurrentStoreLocationIndex = 0;
  hasOptions = false; // selectedTopTab values (tabs)
  clearScreen = true;
  compUpdate = true;
  showQRPopupOffset = { left: 40,top: 100 };
  showLocQRPopupOffset = { left: 40,top: 100 };
  QRCodeLocation = "https://www.telerik.com/kendo-react-ui";
  QRCodeItem = "";
  subInteract = ""; // https://interactmenu.com/?tableid=2&locationid=1&token=%27rwXvl7IzPpzMtm9t+YsGSHDyL5xa7StbwH7RsksFg8dpATO8lnPji8vWtH7ROq2ohOT/u1b6Rnr2KEYg1oDXKipO4zJBTHMj0RLfSgEgrKG9PMqCQ+LA/2MUro5pke4EMnYwuLFx2vKe7lvBAmE9nzwlUqS1xfO9t6Sd+GwM1OQ=%27#logon40004"
  //-------- Upload Variables --------
  ImageFile = undefined;
  ImageType = "";
  fileReady = false;
  UploadFile = undefined;
  saveSublocations = [];
  //
  PeriodsFixedValues = [{ ddName: 'Hour',ddID: 12 },{ ddName: 'Day',ddID: 0 },{ ddName: 'Week',ddID: 1 },{ ddName: 'Month',ddID: 4 }];
  //---- Maximums for each gauge ----
  maxCurrentOrders = 1000;
  xCO1 = 250; xCO2 = 500; xCO3 = 750;
  colorRCO = ['#eeffcc','#ddff99','#ccff66','#bbff33'];
  maxTipValue = 2000.0;
  xTP1 = 500.0; xTP2 = 1000.0; xTP3 = 1500.0;
  colorRTP = ['#ccffcc','#99ff99','#66ff66','#33ff33'];
  maxTotalTaxes = 2000.0;
  xTT1 = 500.0; xTT2 = 1000.0; xTT3 = 1500.0;
  colorRTT = ['#cce6ff','#99ceff','#66b5ff','#339cff'];
  maxTotalSales = 20000.0;
  xTS1 = 5000.0; xTS2 = 10000.0; xTS3 = 15000.0;
  colorRTS = ['#ecb3ff','#df80ff','#d24dff','#c61aff'];
  maxMenuItems = 10000;
  xMI1 = 2500; xMI2 = 5000; xMI3 = 7500;
  colorRMI = ['#ccd9ff','#99b3ff','#668cff','#3366ff'];
  maxOptionCount = 10000;
  xOC1 = 2500; xOC2 = 5000; xOC3 = 7500;
  colorROC = ['#e6ccff','#cc99ff','#b366ff','#9933ff'];
  maxOptionValue = 2000.0;
  xOV1 = 500.0; xOV2 = 1000.0; xOV3 = 1500.0;
  colorROV = ['#eeffcc','#eeffcc','#ccff66','#bbff33'];
  maxActiveCustomers = 200;
  xAC1 = 50; xAC2 = 100; xAC3 = 150;
  colorRAC = ['#99ddff','#99ffcc','#ff99ff','#e600e6'];
  //---- Colours ----
  tickColor = '#008000';
  labelColor = 'blue';
  colorRng1 = ['#0033cc','#ff00ff','#ccffcc','#00ff00'];
  //----- Difference ClassNames ---------------------------------
  ClassNames = [{ n: "ItemName",c: "editInput" },{ n: "ItemDescription",c: "editTAInput" },{ n: "ItemStatusID",c: "editInputDD" },{ n: "SeasonCodeID",c: "editInputDD" },{ n: "TimeOfDayID",c: "editInputDD" },{ n: "DayOfWeek",c: "editInputDD" },{ n: "ItemPrice",c: "editMoneyInput" },{ n: "PromotionPrice",c: "editMoneyInput" },{ n: "RateTypeID",c: "editInputDD" },{ n: "ProductItemCategoryID",c: "editInputDD" },{ n: "ProductItemGroupID",c: "editInputDD" },{ n: "ProductItemTypeID",c: "editInputDD" },{ n: "PromotionType",c: "editInputDD" }];

  //----- End of Difference ClassNames ---------------------------------
  helpListPopupOffset = { left: 150,top: 60 };
  helpDetailOffset = { left: 200,top: 120 };
  taskData = [];

  currentTab = 0;
  Tabs = {
    STOREINFO: 0,
    HOURS: 1,
    ITEMS: 2,
    RECEIPTS: 3,
    PROMOS: 4,
    SUBLOCATIONS: 5,
    TABLELAYOUT: 6,
    //PricingOverrides: 7,
    TableReservations: 7,
    MANAGE: 8,
    MENUAPP: 9
  }

  vertSplitC = JSON.parse('[{"collapsible":false,"collapsed":false,"resizable":true,"scrollable":true,"min":"300px"},{"collapsible":true,"collapsed":true,"resizable":true,"scrollable":true,"size":"50%"}]');
  vertSplitO = JSON.parse('[{"collapsible":false,"collapsed":false,"resizable":true,"scrollable":true,"min":"300px"},{"collapsible":true,"collapsed":false,"resizable":true,"scrollable":true,"size":"50%"}]');
  vertSplitOL = JSON.parse('[{"collapsible":false,"collapsed":false,"resizable":true,"scrollable":true,"min":"300px"},{"collapsible":true,"collapsed":false,"resizable":true,"scrollable":true,"size":"67%"}]');
  vertSplitOS = JSON.parse('[{"collapsible":false,"collapsed":false,"resizable":true,"scrollable":true,"min":"300px"},{"collapsible":true,"collapsed":false,"resizable":true,"scrollable":true,"size":"40%"}]');
  loadExistingPage = false;
  popupAnchor = null;
  popupOffset = { left: 250,top: 70 };
  lastTab = 0;
  updateSublocationsFlag = false;
  //------------------------------
  // Drawing                     
  _element = null;
  _surface = null;
  //------------------------------

  async componentDidMount() {
    //console.log("Mount - load Existing: " + this.loadExistingPage);
    if (!SessionInfo.session || SessionInfo.forceLogoff === true) {
      this.props.navigate("/Login");
    }
    // Note - this is taken care of in the Constructor
    //if (SessionInfo.StoreLocationDetail) { // Data saved for this session 
    //  console.log("on Mount Restore StoreLocationDetail state");
    //  this.state = SessionInfo.StoreLocationDetail;
    //  this.loadExistingPage = true;
    //}
    //else {
    //  this.loadExistingPage = false;
    //}
    let windowHt = window.innerHeight;
    let takeLinesD = (windowHt - 200) / 48.0;
    let takeLines = parseInt(takeLinesD);
    this.setState({ fullTake: takeLines });
    this.setState({ storeLocationsTake: takeLines });
    this.setState({ mainStoreItemsTake: takeLines });
    this.setState({ customOrderItemsTake: takeLines });
    this.setState({ SublocationsTake: takeLines });
    takeLines = takeLines / 2;
    takeLines = parseInt(takeLines);
    this.setState({ halfTake: takeLines });
    //console.log("takeLine 1/2: " + takeLines);
    this.setState({ customOrdersTake: takeLines });
    this.setState({ storeItemsTake: takeLines });
    this.setState({ promoTake: takeLines });
    //console.log("mount 2 ");
    SessionInfo.searchTitle = "Search Locations";
    SessionInfo.currentPage = "StoreLocationDetail";
    //console.log("mount 3 ");
    if (this.state.storeLocationsCount <= 1) {
      await this.getStoreLocations();
    }
    //console.log("mount 4 ");
    SessionInfo.currSaveFunc = this.saveRecord;
    SessionInfo.currDeleteFunc = this.deleteRecord;
    SessionInfo.currClearFunc = this.clearRecord;
    SessionInfo.currRefreshGridFunc = this.refreshGrid;
    SessionInfo.currAddGridRowFunc = undefined;
    SessionInfo.currExcelExportFunc = undefined;
    SessionInfo.currShowHelp = this.showHelp;
    SessionInfo.currExpandLPane = this.openLeftPane;
    SessionInfo.currCollapseLPane = this.closeLeftPane;
    SessionInfo.currExpandRPane = this.openRightPane;
    SessionInfo.currCollapseRPane = this.closeRightPane;
    SessionInfo.searchFunc = this.searchCurrent;
    SessionInfo.tabSelectFunc = this.selectTab;
    SessionInfo.setGridRefresh = 1; // Show Grid Refresh
    console.log(`set Grid Refresh On`);
    //console.log("Mount - document style " + this.loadExistingPage);
    document.documentElement.style.setProperty('--prompt-left','130px');
    if (this.loadExistingPage === true) {
      if (this.state.StoreLocationID > 0) {
        SessionInfo.headerTitle = this.state.StoreName;
      }
      this.forceUpdate();
    }
    else {
      this.openLeftPane();
      this.initializeLocation();
    }
    if (SessionInfo.GoToTab >= 0) {
      await this.selectTab(SessionInfo.GoToTab);
      SessionInfo.GoToTab = 0;
    }
    else
      this.setState({ verticalPanes: this.vertSplitC });
    this.currentTab = this.Tabs.STOREINFO;
    this.CurrentStoreLocationID = this.state.StoreLocationID; // Fixed Key for Session 
    if (this.CurrentStoreLocationID > 0) {
      this.runMonitorProcess = true;
      this.monitorProcess();
    }
  }
  componentWillUnmount() {
    //console.log("Store Location Will UnMount"); 
    if (this.lastTab == this.Tabs.TABLELAYOUT) {
      SaveTableInformation(); // Auto Save Table Layout information when unmount if on TableLayout Tab
      this.lastTab = 0;
      if (this._surface != null) {
        console.log(`Destroy Surface`);
        this._surface.destroy();
        this._surface = null;
      }
    }
    if (SessionInfo.session !== '')
      SessionInfo.StoreLocationDetail = this.state;
    SessionInfo.ShowSwitchSearch = 0;
    SessionInfo.searchChoice = 0;
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.runMonitorProcess = false;
  }
  shouldComponentUpdate() {
    if (this.compUpdate === true)
      return true;
    else
      return false;
  }
  //--------------- Monitor Process ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
  runMonitorProcess = true;
  isMonitorRunning = false;
  monitorCounter = 20;
  inMonitor = false;
  setTimer() {
    if (SessionInfo.loggedOn === true) {
      this.isMonitorRunning = true;
      this.timeout = setTimeout(this.monitorProcess.bind(this),1000); // 1 second  
    } else {
      console.log(`----> Stop Monitor Process`);
      this.runMonitorProcess = false;
      this.isMonitorRunning = false;
    }
  }
  //----- Timer Process - Application Control Logic -------------------------------------------------
  monitorProcess = async () => {
    if (SessionInfo.forceLogoff === true || SessionInfo.loggedOn === false) {
      console.log(`forceLogoff set in StoreLocationDetail - go to Login`);
      this.props.navigate("/Login");
    } else {
      if (this.runMonitorProcess === true) {
        if (this.monitorCounter++ >= 10) {
          try {
            if (this.inMonitor === false) {
              this.inMonitor = true;
              this.monitorCounter = 0;
              if (this.state.selectedTopTab === this.Tabs.MANAGE) {
                // Perform Dashboard Processing - Get latest Stats         
                console.log(`===> Monitor - ${this.state.selectedTopTab} - Manage: ${this.Tabs.MANAGE}`);
                let CD;
                console.log(`===> Get Current Stats - PeriodType: ${this.state.PeriodType}, PeriodBack: ${this.state.PeriodBack}`);
                CD = await CallOM("GetCurrentStats",this.CurrentStoreLocationID,this.state.PeriodType,0,"","",this.state.PeriodBack); // Get Current Statistics in a List structure - see GetCurrentStats  
                if (CD) {
                  console.log(`current Daily Stats: ${JSON.stringify(CD)}`);
                  if (CD.d) {
                    let TxDate = new Date(CD.d[0]);
                    this.setState({ TxDate });
                    let itemCount = CD.d[1];
                    let orderCount = CD.d[2];
                    let totalPeriodSales = CD.d[3];
                    let TotalTaxAmount = CD.d[4];
                    let TipAmount = CD.d[5];
                    let OptionCount = CD.d[6];
                    let OptionsPrice = CD.d[7];
                    let customerCount = CD.d[8];
                    this.setState({ itemCount });
                    this.setState({ orderCount });
                    this.setState({ totalPeriodSales });
                    this.setState({ TotalTaxAmount });
                    this.setState({ TipAmount });
                    this.setState({ TipAmount });
                    this.setState({ OptionCount });
                    this.setState({ OptionsPrice });
                    await this.setState({ customerCount });
                  }
                  else
                    SessionInfo.forceLogoff = true;
                }
                else
                  SessionInfo.forceLogoff = true;
                console.log(`===> Get GetSalesByPeriodForLocation - PeriodType: ${this.state.PeriodType}, PeriodBack: ${this.state.PeriodBack}, Location: ${this.CurrentStoreLocationID}`);
                CD = await CallOM("GetSalesByPeriodForLocation",this.CurrentStoreLocationID,this.state.PeriodType,0,"","",this.state.PeriodBack); // Get Current Statistics in a List structure - see GetCurrentStats  
                if (CD) {
                  console.log(`GetSales Return`);
                  console.log(`current Time Stats: ${JSON.stringify(CD)}`);
                  let PT = this.state.PeriodType;
                  if (CD.d) {
                    let rows = CD.d.rows;
                    this.setState({ storeCount: rows.length });
                    console.log(`GetSales rows: ${JSON.stringify(rows)}`);
                    let row = rows[0];
                    let categories = [];
                    for (let ix = 12; ix >= 0; ix--) {
                      let curDate = new Date(row[ix])
                      if (PT == 12) // Hourly
                        categories.push(GetMonth(curDate.getMonth()) + ' ' + curDate.getDate() + ' ' + curDate.getHours() + ':00');
                      else if (PT == 4) // Monthly
                        categories.push(GetMonth(curDate.getMonth()));
                      else
                        categories.push(GetMonth(curDate.getMonth()) + ' ' + curDate.getDate());
                    }
                    this.setState({ categories });
                    console.log(`categories: ${JSON.stringify(categories)}`);
                    let TxDate = new Date(CD.d[0]);
                    // Get Data for each location
                    let data = [];
                    let cnt = 10;
                    this.compUpdate = false;
                    row = rows[1];
                    console.log(`data row: ${JSON.stringify(row)}`);
                    data = [];
                    // Note - When using numeric keys, the values are returned in the keys' numerical order
                    // So reverse the values
                    for (let jx = 12; jx >= 0; jx--) {
                      data.push(parseInt(row[jx.toString()]));
                      this.setState({ data1: data });
                    }
                    this.compUpdate = true;
                  }
                }
              }
            }
          } catch (ex) {
            console.log(`Failure in appmain monitor - error ${ex.message}`);
          }
          finally {
            this.inMonitor = false;
          }
        }
        this.setTimer();
      }
    }
  }
  //--------------- Standard Page Functions ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- 
  //---- Top Tab -----------------------------------
  handleTopTabSelect = async (e) => { // Tab Selection
    await this.selectTab(e.selected);
  }
  selectTab = async (tabNumber) => {
    console.log("selectTab - TabN=" + tabNumber);
    SessionInfo.ShowSwitchSearch = 0;
    if (SessionInfo.clearSearch)
      SessionInfo.clearSearch();
    this.clearQRPopup();
    if (this.CurrentStoreLocationID > 0) {
      if (this.lastTab == this.Tabs.TABLELAYOUT) {
        console.log(`selectTab: ${tabNumber} SaveTableInfo`);
        SaveTableInformation(); // Auto Save Table Layout information when switch off tab 
        if (tabNumber != this.Tabs.SUBLOCATIONS && this._surface != null) {
          console.log(`Destroy Surface`);
          this._surface.destroy();
          this._surface = null;
        }
      } else if (this.lastTab === this.Tabs.SUBLOCATIONS) { // Reload Table Info from OM
        //await LoadTableInformation(this.CurrentStoreLocationID);
      }
      this.lastTab = tabNumber;
      await this.setState({ selectedTopTab: tabNumber });
      SessionInfo.setExcelExport = 2;
      SessionInfo.setGridAdd = 2;
      this.currentTab = tabNumber;
      if (tabNumber === this.Tabs.STOREINFO) {
        SessionInfo.setShowSave = 1; // Show Save in Header 
        SessionInfo.setShowNew = 1;
        SessionInfo.setGridRefresh = 1; // Grid Refresh in Header 
        await this.setState({ RightO: false });
        SessionInfo.searchTitle = "Search Locations";
        this.closeLeftPane();
        this.setState({ verticalPanes: this.vertSplitC });
      }
      else if (tabNumber === this.Tabs.HOURS) { // Hours of Operation   
        SessionInfo.setShowSave = 2; // Hide Save in Header 
        SessionInfo.setShowNew = 2;
        SessionInfo.setGridAdd = 1;
        SessionInfo.setGridRefresh = 1; // Grid Refresh in Header 
        SessionInfo.currExcelExportFunc = this.exportHoursExcel;
        SessionInfo.currAddGridRowFunc = this.enterInsertHOO;
        SessionInfo.setExcelExport = 1;
        await this.setState({ RightO: false });
        this.closeLeftPane();
        this.setState({ verticalPanes: this.vertSplitC });
        //if (this.state.hoursOfOperationCounth <= 1)
        await this.getHoursOfOperation();
        //if (this.state.StoreLocationPhotoURL == undefined) {
        //console.log(" GetStoreImage: " + this.state.ImageID);
        if (this.state.ImageID !== 0)
          await this.GetStoreImage(this.state.ImageID);
        else
          await this.GetStoreImage(1); // Blank Image  
        this.setState({ verticalPanes: this.vertSplitO });
        //  }
      }
      else if (tabNumber === this.Tabs.ITEMS) { // StoreItems  
        SessionInfo.ShowSwitchSearch = 1;
        SessionInfo.setShowSave = 1; // Enable Save in Header 
        SessionInfo.setShowNew = 2;
        SessionInfo.setShowDelete = 1; // Show Delete in Header 
        SessionInfo.setGridRefresh = 1; // Grid Refresh in Header  
        SessionInfo.currRefreshGridFunc = this.refreshGrid; // this.getStoreItems;
        SessionInfo.setExcelExport = 1;
        SessionInfo.currExcelExportFunc = this.exportStoreItemsExcel;
        SessionInfo.searchTitle = "Search Main Items";
        SessionInfo.searchTitle1 = "Search Main Items";
        SessionInfo.searchTitle2 = "Search Store Items";
        await this.closeLeftPane();
        await this.openRightPane();
        this.setState({ verticalPanes: this.vertSplitO });
        //console.log("StoreItems Lth: " + this.state.StoreItems.length);
        //if (this.state.StoreItems.length <= 1)
        await this.getStoreItems('');
        if (this.state.mainStoreItemsCount <= 1)
          await this.getProductItems('');
      }
      else if (tabNumber === this.Tabs.RECEIPTS) { // CustomOrders - Receipts 
        SessionInfo.setShowSave = 2; // Hide Save in Header 
        SessionInfo.setShowNew = 2;
        SessionInfo.setGridRefresh = 1; // Grid Refresh in Header 
        SessionInfo.currRefreshGridFunc = this.refreshGrid; // this.getReceipts;
        SessionInfo.setExcelExport = 1;
        SessionInfo.currExcelExportFunc = this.exportCustomOrdersExcel;
        SessionInfo.searchTitle = "Search Receipts";
        this.closeRightPane();
        this.closeLeftPane();
        this.setState({ verticalPanes: this.vertSplitO });
        if (this.state.customOrdersCount <= 1)
          await this.getCustomOrders('');
      }
      else if (tabNumber === this.Tabs.PROMOS) { // Promotions    
        SessionInfo.setShowSave = 1; // Show Save in Header 
        SessionInfo.setShowNew = 1;
        SessionInfo.setGridRefresh = 1; // Grid Refresh in Header 
        SessionInfo.currRefreshGridFunc = this.getPromotions;
        SessionInfo.setExcelExport = 1;
        SessionInfo.currExcelExportFunc = this.exportPromotionsExcel;
        SessionInfo.searchTitle = "Search Promotions";
        this.setState({ RightO: false });
        this.closeLeftPane();
        this.setState({ verticalPanes: this.vertSplitO });
        if (this.state.promotionsCount <= 1)
          await this.getPromotions('');
      }
      else if (tabNumber === this.Tabs.SUBLOCATIONS) { // Sublocations  
        SessionInfo.currAddGridRowFunc = this.enterInsertSublocation;
        SessionInfo.setGridAdd = 1;
        SessionInfo.searchTitle = "Search Sublocations";
        console.log(`select subloc - this.state.Sublocations.length: ${this.state.Sublocations.length}`);
        if (this.state.Sublocations.length <= 1)
          await this.getSublocations('');
        this.setState({ bottomDisplay: this.state.SublocationsTabNumber });
        SessionInfo.setExcelExport = 1; // Show Export in Header 
        SessionInfo.currExcelExportFunc = this.exportSublocationsExcel;
        SessionInfo.setGridRefresh = 1; // Show RefreshGrid in Header
        SessionInfo.currRefreshGridFunc = this.refreshGrid; // this.getSublocations;
        SessionInfo.headerTitle = "Store Sub-Locations";
        //console.log("Locations - lth: " + this.state.Locations.length);
        if (!this.state.Locations || this.state.Locations.length <= 1) {
          let CD = await GetDropdownData(509178); // Locations
          this.setState({ Locations: CD.d });
          //console.log("Locations: " + JSON.stringify(CD.d));
          SetLocations(CD.d);
        }
        //console.log("SublocationTypesDict - lth: " + this.state.SublocationTypesDict.length);
        if (this.state.SublocationTypesDict.length <= 1) {
          let CD = await GetDropdownData(546259); // SublocationTypesDict
          this.setState({ SublocationTypesDict: CD.d });
          console.log("SublocationTypesDict: " + JSON.stringify(CD.d));
          SetSublocationTypes(CD.d);
        }
        if (this.state.LocationSizesDict.length <= 1) {
          let CD = await GetDropdownData(555605);
          this.setState({ LocationSizesDict: CD.d });
          console.log(`--> LocationSizes: ${JSON.stringify(CD.d)}`);
          SetLocationSizes(CD.d);
        }
        if (this.state.LocationStatusSetDict.length <= 1) {
          let CD = await GetDropdownData(555625);
          this.setState({ LocationStatusSetDict: CD.d });
          console.log(`--> LocationStatus: ${JSON.stringify(CD.d)}`);
          SetLocationStatusSet(CD.d);
        }
      }
      //------ PricingOverrides Tab Select ------
      //else if (tabNumber === this.Tabs.PricingOverrides) { // PricingOverrides  
      //  SessionInfo.currAddGridRowFunc = this.enterInsertPricingOverride;
      //  SessionInfo.setGridAdd = 1;
      //  if (this.state.PricingOverrides.length <= 1)
      //    await this.getPricingOverrides('');
      //  this.setState({ bottomDisplay: this.state.PricingOverrides }); // TabNumber
      //  SessionInfo.setExcelExport = 1; // Show Export in Header 
      //  SessionInfo.currExcelExportFunc = this.exportPricingOverrides;
      //  SessionInfo.setGridRefresh = 1; // Show RefreshGrid in Header 
      //  SessionInfo.currRefreshGridFunc = this.exportPricingOverrides;
      //  if (this.state.SeasonCodesDict.length <= 1) {
      //    let CD = await GetDropdownData(1120743);
      //    this.setState({ SeasonCodesDict: CD.d });
      //    console.log("SeasonCodesDict: " + JSON.stringify(CD.d));
      //    SetSeasonCodes(CD.d);
      //  }
      //}
      else if (tabNumber === this.Tabs.TableReservations) {
        //---- Waitlist Information ----               
        this.setState({ verticalPanes: this.vertSplitOL });
        SessionInfo.setGridRefresh = 2; // Hide Grid Refresh in Header 
        let CD = await CallOM("GetWaitListInfo",this.CurrentStoreLocationID,0,0); // TaskList Returned for Scheduler  
        console.log(`Return WaitList - CD: ${JSON.stringify(CD)}`);
        if (CD && CD.d) {
          let row = CD.d;
          this.setState({ TableCount: row.TableCount });
          this.setState({ TablesInUse: row.TablesInUse });
          this.setState({ TablesDirty: row.TablesDirty });
          this.setState({ TablesReserved: row.TablesReserved });
          this.setState({ TablesAvailable: row.TablesAvailable });
          this.setState({ WaitListCount: row.WaitListCount });
          this.setState({ AverageWaitTime: row.AverageWaitTime });
          this.setState({ MaxWaitTime: row.MaxWaitTime });
        }
        CD = await CallOM("GetWaitList",this.CurrentStoreLocationID,1,0); // TaskList Returned for Scheduler  
        console.log(`Return WaitList - CD: ${JSON.stringify(CD)}`);
        if (CD && CD.d) {
          this.setState({ WaitList: CD.d.rows });
        }
        //let WaitList = [{ name: "Steven White",selected: true,},{ name: "Nancy King",selected: false,},{ name: "Nancy Davolio",selected: false,},{ name: "Robert Davolio",selected: false,},{ name: "Michael Leverling",elected: false,}];
        //this.setState({ WaitList });
        console.log(`TableReservations - TableCount: ${this.state.TableCount}, TablesAvailable: ${this.state.TablesAvailable}`);
        //---- Get Scheduler Reservation Data ----      
        await this.loadReservationSched();
      }
      else if (tabNumber == this.Tabs.TABLELAYOUT) {
        //console.log(`restaurantDisplay`);   
        SessionInfo.setGridRefresh = 1; // Display Grid Refresh in Header 
        restaurantDisplay(this.createSurface()); // Note - see restaurantDisplay in RestaurantDisplay.js
      }
      else if (tabNumber === this.Tabs.MANAGE) { // Management  
        SessionInfo.setGridRefresh = 2; // Hide Grid Refresh in Header 
        if (this.CurrentStoreLocationID > 0) {
          if (this.isMonitorRunning === false)
            this.monitorProcess();
          this.setState({ verticalPanes: this.vertSplitOS });
        }
      }
      else if (tabNumber === this.Tabs.MENUAPP) { // Show Menu App in an IFrame
        this.setState({ verticalPanes: this.vertSplitC });
        SessionInfo.setGridRefresh = 2; // Hide Grid Refresh in Header 
        if (this.state.StoreLocationsData.length > 0) {
          //console.log(`StoreLocation Info: ${JSON.stringify(this.state.StoreLocationsData[this.CurrentStoreLocationIndex])}`);
          console.log(`CheckoutToken: ${SessionInfo.CheckoutToken}, LocationID: ${this.CurrentStoreLocationID}, LocationIndex: ${this.CurrentStoreLocationIndex}`);
          await this.getSublocationsForStoreLocation(this.CurrentStoreLocationID);
          let menuAppStr;
          if (this.saveSublocations.length === 0)
            displayError("No Locations Defined for Store - at least one required");
          else {
            let currentSublocationID = this.saveSublocations[0].SublocationID;
            for (let ix = 0; ix < this.saveSublocations.length; ix++) {
              //console.log(`SublocationTypeID: ${JSON.stringify(this.saveSublocations[ix])}`);
              if (this.saveSublocations[ix].SublocationTypeID === 1) {
                currentSublocationID = this.saveSublocations[ix].SublocationID;
                break;
              }
            }
            if (SessionInfo.debug === true)
              menuAppStr = `http://localhost:3001/?tableid=${currentSublocationID}&locationid=${this.CurrentStoreLocationID}&token=${SessionInfo.CheckoutToken}#logon40004`;
            else
              menuAppStr = `https://admin.interactsuite.app/?tableid=${currentSublocationID}&locationid=${this.CurrentStoreLocationID}&token=${SessionInfo.CheckoutToken}#logon40004`;
            console.log(`menuAppStr: ${menuAppStr}`);
            this.subInteract = menuAppStr;
          }
        }
        // Set LocationID to current Location
      }
      else
        this.setState({ verticalPanes: this.vertSplitC });
    }
    else {
      if (tabNumber === this.Tabs.STOREINFO) {
        this.setState({ selectedTopTab: tabNumber });
        SessionInfo.setGridRefresh = 1; // Hide Grid Refresh in Header 
        SessionInfo.setExcelExport = 2;
        SessionInfo.setGridAdd = 2;
        SessionInfo.setShowNew = 1;
        this.currentTab = this.Tabs.INFO;
        this.setState({ RightO: false });
        SessionInfo.searchTitle = "Search Locations";
        //this.closeLeftPane();
        this.setState({ verticalPanes: this.vertSplitC });
      }
      else {
        displayWarning("Store Location must be Selected");
        this.setState({ selectedTopTab: this.Tabs.STOREINFO });
      }
    }
    this.forceUpdate();
  }
  loadReservationSched = async () => {
    let parms = [new Date(),"month"];
    let CD = await CallOM("GetCurrentReservationTasks",this.CurrentStoreLocationID,0,9,parms); // TaskList Returned for Scheduler   
    await getResTaskID(); // Initialize TaskID for create
    console.log(`Reservations: ${JSON.stringify(CD.d)}`);
    let taskData = CD.d.rows;
    //console.log("taskData: " + JSON.stringify(this.taskData));
    TaskData = taskData.map(dataItem => (
      {
        TaskID: dataItem.ReservationID,
        OwnerID: 2,
        Title: `Reservation For: ${dataItem.CustomerID}`,
        Description: dateFormat(dataItem.ReservationDateTime),
        Start: new Date(dataItem.ReservationDateTime),
        End: new Date(dataItem.EndDateTime),
        isAllDay: false,
        RecurrenceRule: null,
        RecurrenceId: null,
        RecurrenceExceptions: null,
      }
    ));
    console.log(`TaskData: ${JSON.stringify(TaskData)}`);
    this.setState({ ReservationData: TaskData });
    this.forceUpdate();
  }
  // Reservations WaitList Handling
  handleItemClick = (event,data,connectedData) => {
    console.log(`data: ${data}, connectd: ${connectedData}, dataItem: ${JSON.stringify(event.dataItem)}, native: ${JSON.stringify(event.nativeEvent)}`);
    this.setState({
      [data]: this.state[data].map((item) => {
        if (item.name === event.dataItem.name) {
          item["selected"] = !item["selected"];
        } else if (!event.nativeEvent.ctrlKey) {
          item["selected"] = false;
        }
        console.log(`return item: ${JSON.stringify(item)}`);
        return item;
      }),
      [connectedData]: this.state[connectedData].map((item) => { item["selected"] = false; return item; }),
    });
  };
  handleToolBarClick = (e) => {
    let toolName = e.toolName || "";
    let result = processListBoxData(this.state.WaitList,this.state.SeatedList,toolName,"selected");
    this.setState({ WaitList: result.listBoxOneData,SeatedList: result.listBoxTwoData,});
  };
  handleDragStart = (e) => {
    this.setState({ draggedItem: e.dataItem,});
  };
  handleDrop = (e) => {
    let result = processListBoxDragAndDrop(this.state.WaitList,this.state.SeatedList,this.state.draggedItem,e.dataItem,"name");
    this.setState({ WaitList: result.listBoxOneData,SeatedList: result.listBoxTwoData,});
  };
  //---- Table Layout - Drawing Surface -----------------------------------
  createSurface() {
    // Create a drawing surface                  
    console.log(`--> Create Surface`);
    // _element is the div that the diagram is displayed in
    if (this._element)
      this._surface = Surface.create(this._element,{ width: 1200,height: 600 });
    console.log(`surface created`);
    return this._surface;
  }
  //---- Sub Tab -----------------------------------
  handleSubTabSelect = async (e) => { // Sub Tab for Kitchen - not used 
    this.setState({ selectedSubTab: e.selected });
    if (e.selected === 0) { // Pending
      this.getCustomOrdersStatus(2)
    }
    else if (e.selected === 1) { // Accepted  
      this.getCustomOrdersStatus(3)
    }
    else if (e.selected === 2) { // In Progress Orders 
      this.getCustomOrdersStatus(5)
    }
    else if (e.selected === 3) { // Fulfilled  
      this.getCustomOrdersStatus(6)
    }
    else if (e.selected === 4) { // Rejected  
      this.getCustomOrdersStatus(10)
    }
  }
  searchCurrent = async (search) => {
    if (!search || typeof search !== 'string' || search === 'all' || search === 'ALL')
      search = "";
    if (this.currentTab === this.Tabs.ITEMS) { // Store Items
      this.saveSearch = search;
      if (SessionInfo.searchChoice === 0) {
        displayMessage("Search for Main Items");
        this.setState({ storeItemsSkip: 0 });
        this.setState({ storeItemsTake: this.state.halfTake });
        await this.getProductItems(search);
      }
      else {
        displayMessage("Search for Store Items");
        this.setState({ storeItemsSkip: 0 });
        this.setState({ storeItemsTake: this.state.halfTake });
        await this.getStoreItems(search);
        await this.getStoreItems(search);
      }
    }
    else if (this.currentTab === this.Tabs.RECEIPTS) {
      displayMessage("Search CustomOrderItems");
      this.setState({ customOrdersSkip: 0 });
      this.setState({ customOrdersTake: this.state.halfTake })
      this.setState({ customOrdersFilter: { logic: "and",filters: [] } });
      this.setState({ customOrdersSort: [] });
      await this.getCustomOrders(search);
    }
    else if (this.currentTab === this.Tabs.PROMOS) {
      displayMessage("Search Promotions");
      this.setState({ promoSkip: 0 });
      this.setState({ promoTake: this.state.halfTake })
      this.setState({ promoFilter: { logic: "and",filters: [] } });
      this.setState({ promoSort: [] });
      await this.getPromotions(search);
    }
    else if (this.currentTab === this.Tabs.SUBLOCATIONS) {
      displayMessage("Search for Sublocations");
      this.setState({ SublocationsSkip: 0 });
      this.setState({ SublocationsTake: this.state.fullTake })
      await this.getSublocations(search);
    }
    else { // Search Locations
      this.setState({ storeLocationsSkip: 0 });
      this.setState({ storeLocationsTake: this.state.fullTake });
      await this.getStoreLocations(search);
    }
  }

  saveRecord = async () => {
    await this.getStoreLocationControlEncoded()
    await this.getStoreAmenitiesEncoded();
    await this.getDayPeriodsEncoded();
    //let copyState = Object.assign({}, this.state);
    //let type = 0;
    //for (var prop in copyState) {
    //  if (prop === "string") {
    //    type = 1;
    //    copyState[prop] = undefined;
    //  }
    //  else if (prop === "selectedTopTab")
    //    type = 2;
    //  else if (type === 0) {
    //  }
    //  if (type === 2) {
    //    copyState[prop] = undefined;
    //  }
    //}
    //console.log("Save Record Tab: " + JSON.stringify(this.state.selectedTopTab));
    //export async function UpdateRow(tableID, control, allData, item, key, keyName) { // SaveRow
    if (this.state.selectedTopTab === this.Tabs.ITEMS) { // Store Item
      let propState = {};
      StoreItemsO.forEach((elem) => {
        if (elem.n in this.state) {
          propState[elem.n] = this.state[elem.n];
        }
      });
      //console.log("Save Record: " + JSON.stringify(propState));
      await UpdateRow(544619,0,undefined,propState,this.state.StoreItemID,"StoreItemID"); // Save StoreItem to OM
    }
    else if (this.state.selectedTopTab === this.Tabs.RECEIPTS) { // Store Receipts - do nothing
    }
    else if (this.state.selectedTopTab === this.Tabs.PROMOS) { // Promotions   
      //let propState = [];
      //StorePromotionsO.forEach((elem) => {
      //  if (elem.n in this.state) {
      //    let sobj = {};
      //    sobj[elem.n] = this.state[elem.n];
      //    propState.push(sobj);
      //  }
      //});  
      let propState = {};
      StorePromotionsO.forEach((elem) => {
        if (elem.n in this.state) {
          propState[elem.n] = this.state[elem.n];
        }
      });
      //console.log("Save Promotion Record: " + JSON.stringify(propState));
      await CallOM("UpdatePromotion",0,this.state.StorePromotionID,13,propState);
      await this.getPromotions("");
    }
    else if (this.state.selectedTopTab === this.Tabs.SUBLOCATIONS) { // SubLocations 
    }
    else if (this.state.selectedTopTab === this.Tabs.TABLELAYOUT) {
      console.log(`refresh TableLayout - update: ${this.updateSublocationsFlag}`);
      if (this.updateSublocationsFlag === true) {
        console.log(`Refresh Table Layout - updateSublocationsFlag set`);
        this.updateSublocationsFlag = false;
        await LoadTableInformation(this.CurrentStoreLocationID);
      }
      restaurantDisplay(this.createSurface()); // Note - see restaurantDisplay in RestaurantDisplay.js
    }
    else { // Update or Add the actual StoreInformation record
      let propState = {};
      //console.log("Save StoreLocation");
      StoreLocationsO.forEach((elem) => {
        if (elem.n in this.state) {
          propState[elem.n] = this.state[elem.n];
        }
      });
      LocationsO.forEach((elem) => {
        if (elem.n in this.state) {
          propState[elem.n] = this.state[elem.n];
        }
      });
      //console.log("Save StoreLocation: " + JSON.stringify(propState));
      await CallOM("UpdateStoreLocation",0,this.state.StoreLocationID,13,propState);
      this.getStoreLocations('');
      this.refreshGrid();
      //await UpdateRow(544596, 0, undefined, copyState, copyState.StoreLocationID, "StoreLocationID"); // Same StoreLocation Info to OM     
      //await UpdateRow(509178, 0, undefined, copyState, copyState.LocationID, "LocationID"); // Same StoreLocation Info to OM 
    }
    this.setItemStateChanged(false);
    SessionInfo.setShowNew = 1; // Clear
  }
  clearRecord = async () => { // Note => functions do not bind their own this - if used clearRecord() - would have to bind this to the function
    //console.log("Clear Screen - Tab: " + this.state.selectedTopTab);  
    let fields = [];
    if (this.state.selectedTopTab === this.Tabs.STOREINFO) { // Store Items 
      SessionInfo.setShowSave = 2; // Hide Save in Header 
      SessionInfo.setShowNew = 2;
      fields = StoreLocationsO;
      LocationsO.forEach((elem) => {
        if (elem.n in this.state) {
          let sobj = {};
          try {
            if (elem.t === 34 || elem.t === 35)
              sobj[elem.n] = new Date();
            else if (elem.t === 21 || elem.t === 22)
              sobj[elem.n] = '';
            else
              sobj[elem.n] = 0;
            this.setState(sobj);
          } catch (error) {
            console.log("Clear Promotion Field: " + elem.n + " Error: " + error.message);
          }
        }
      });
    }
    else if (this.state.selectedTopTab === this.Tabs.ITEMS) { // Store Items 
      fields = StoreItemsO;
    }
    else if (this.state.selectedTopTab === this.Tabs.RECEIPTS) { // CustomOrderItems 
      fields = CustomOrdersO;
    }
    else if (this.state.selectedTopTab === this.Tabs.PROMOS) { // Promotions 
      this.setState({ PromotionPhotoURL: undefined });
      fields = StorePromotionsO;
    }
    else if (this.state.selectedTopTab === this.Tabs.SUBLOCATIONS) { // SubLocations 
    }
    fields.forEach((elem) => {
      let sobj = {};
      try {
        if (elem.t === 34 || elem.t === 35)
          sobj[elem.n] = new Date();
        else if (elem.t === 21 || elem.t === 22)
          sobj[elem.n] = '';
        else
          sobj[elem.n] = 0;
        this.setState(sobj);
      } catch (error) {
        console.log("Clear Promotion Field: " + elem.n + " Error: " + error.message);
      }
    });

    this.setState({ CFProjectParticipants: [] });
    this.clearScreen = true;
    this.setItemStateChanged(false);
  }
  refreshGrid = async () => { // Note => functions do not bind their own this - if used clearRecord() - would have to bind this to the function
    let fields = [];
    let tabNumber = this.state.selectedTopTab;
    console.log(`refreshGrid - tabNumber: ${tabNumber}`);
    if (tabNumber === this.Tabs.STOREINFO) { // Store Info
      this.getStoreLocations('');
    }
    else if (tabNumber === this.Tabs.HOURS) { // Hours of Operation  
      await this.getHoursOfOperation();
    }
    else if (tabNumber === this.Tabs.ITEMS) { // Store Items 
      this.getStoreItems('');
    }
    else if (tabNumber === this.Tabs.RECEIPTS) { // CustomOrders - Receipts
      this.getCustomOrders('');
    }
    else if (tabNumber === this.Tabs.PROMOS) { // Promotions 
      this.getPromotions('');
    }
    else if (tabNumber === this.Tabs.SUBLOCATIONS) { // SubLocations 
      this.getSublocations('');
    }
    else if (tabNumber === this.Tabs.TABLELAYOUT) {
      console.log(`refresh TableLayout - update: ${this.updateSublocationsFlag}`);
      if (this.updateSublocationsFlag === true) {
        console.log(`Refresh Table Layout - updateSublocationsFlag set`);
        this.updateSublocationsFlag = false;
        await LoadTableInformation(this.CurrentStoreLocationID);
      }
      restaurantDisplay(this.createSurface()); // Note - see restaurantDisplay in RestaurantDisplay.js
    }
  }
  clearTables = () => {
    this.setState({ storeLocationsCount: 0 });
    this.setState({ storeLocationsSkip: 0 });
    this.setState({ storeLocationsTake: this.state.fullTake });
    this.setState({ mainStoreItemsCount: 0 });
    this.setState({ mainStoreItemsSkip: 0 });
    this.setState({ mainStoreItemsTake: this.state.fullTake });
    this.setState({ customOrdersCount: 0 });
    this.setState({ customOrdersSkip: 0 });
    this.setState({ customOrdersTake: this.state.halfTake });
    this.setState({ customOrderItemsCount: 0 });
    this.setState({ customOrderItemsSkip: 0 });
    this.setState({ customOrderItemsTake: this.state.fullTake });
    this.setState({ storeItemsCount: 0 });
    this.setState({ storeItemsSkip: 0 });
    this.setState({ storeItemsTake: this.state.halfTake });
    this.setState({ promotionsCount: 0 });
    this.setState({ promoSkip: 0 });
    this.setState({ promoTake: this.state.halfTake });
    this.forceUpdate();
  }
  defaultItemType = { text: "Select Type ..." };
  defaultCFProjectControlTypeID = { text: "Select Type ..." };

  closeLeftPane = () => {
    //console.log("Close Left Pane");
    this.setState({ LeftO: false });
    if (this.state.RightO === false)
      this.setState({ horizontalPanes: this.horizSplitCC });
    else
      this.setState({ horizontalPanes: this.horizSplitCO });
    SessionInfo.setLPaneOpen = 1;
    SessionInfo.setLPaneClose = 2;
  }
  openLeftPane = () => {
    //console.log("Open Left Pane");
    this.setState({ LeftO: true });
    if (this.state.RightO === false)
      this.setState({ horizontalPanes: this.horizSplitOC });
    else
      this.setState({ horizontalPanes: this.horizSplitOO });
    SessionInfo.setLPaneClose = 1;
    SessionInfo.setLPaneOpen = 2;
  }
  closeRightPane = () => {
    console.log("Close Right Pane - Change Search Focus");
    this.setState({ RightO: false });
    if (this.currentTab === this.Tabs.ITEMS)
      SessionInfo.searchTitle = "Search Store Items";
    if (this.state.LeftO === false)
      this.setState({ horizontalPanes: this.horizSplitCC });
    else
      this.setState({ horizontalPanes: this.horizSplitOC });
    SessionInfo.setRPaneOpen = 1;
    SessionInfo.setRPaneClose = 2;
  }
  openRightPane = () => {
    console.log("Open Right Pane");
    this.setState({ RightO: true });
    if (this.currentTab === this.Tabs.ITEMS)
      SessionInfo.searchTitle = "Search Main Items";
    if (this.state.LeftO === false)
      this.setState({ horizontalPanes: this.horizSplitCO });
    else
      this.setState({ horizontalPanes: this.horizSplitOO });
    SessionInfo.setRPaneClose = 1;
    SessionInfo.setRPaneOpen = 2;
  }
  //--------------- End of Standard Page Functions ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
  //--------------- Page Setup and Load Functions ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
  async initializeLocation() {
    console.log(`initialize location: ${this.CurrentStoreLocationID}`);
    this.setState({ verticalPanes: this.vertSplitC });
    if (this.CurrentStoreLocationID > 0) { // SelectLocationID is used to Select the Location coming into StoreLocations
      await this.getAllStoreLocationDetail(); // This will set SelectLocationID = 0
      this.state.StoreLocationID = this.CurrentStoreLocationID;
      this.CurrentStoreLocationID = 0;
    }
    if (this.CurrentStoreLocationID === 0 && this.CurrentStoreLocationID !== this.state.StoreLocationID) {
      this.CurrentStoreLocationID = this.state.StoreLocationID;
      SessionInfo.headerTitle = this.state.StoreName;
      SessionInfo.itemName = "Item";
      this.state.RightO = false;
      this.setState({ selectedTopTab: this.Tabs.STOREINFO });
      this.clearTables();
      //----------- Get dictionaries for this Location -----------
      let CD = await GetDependentDropdownData(544619,this.state.StoreLocationID,544598); // StoreItems for this Location
      this.setState({ StoreItemsDict: CD.d });
      SessionInfo.StoreItemsUpdate = false;
      SessionInfo.ProvinceStatesUpdate = true;
      CD = await CallOM("GetContacts",0,this.CurrentStoreLocationID,0); // Contacts for this Location
      //console.log("Contacts: " + JSON.stringify(CD.d));
      this.setState({ Contacts: CD.d });
      //----------- Get general dictionaries -----------
      if (this.state.StoreGroupsDict.length <= 1 || SessionInfo.StoreGroupUpdate === true) {
        CD = await GetDropdownData(544709); // Store Groups
        //console.log("StoreGroupsDict Codes: " + JSON.stringify(CD.d));
        this.setState({ StoreGroupsDict: CD.d });
        SessionInfo.StoreGroupUpdate = false;
      }
      if (this.state.LocationTypesDict.length <= 1 || SessionInfo.LocationTypesUpdate === true) {
        CD = await GetDropdownData(489042); // Location Types
        this.setState({ LocationTypesDict: CD.d });
        SessionInfo.LocationTypesUpdate = false;
      }
      if (this.state.TaxRulesDict.length <= 1 || SessionInfo.TaxRulesUpdate === true) {
        CD = await GetDropdownData(546862);
        this.setState({ TaxRulesDict: CD.d });
        SessionInfo.TaxRulesUpdate = false;
      }
      if (this.state.ProvinceStatesDict.length <= 1 || SessionInfo.ProvinceStatesUpdate === true) {
        CD = await GetDependentDropdownData(489030,124,163266); // ProvinceState - Depend on Canada 124
        //CD = await GetDropdownData(489030);
        //console.log(`Province State: ${JSON.stringify(CD.d)}`);
        this.setState({ ProvinceStatesDict: CD.d });
      }
      //----------- StoreItem Dictionaries ---------------------------------
      //console.log(`SessionInfo.ProductItemCategoriesUpdate: ${SessionInfo.ProductItemCategoriesUpdate}, lth: ${this.state.ProductItemCategoriesDict.length}`);
      if (this.state.ProductItemCategoriesDict.length <= 1 || SessionInfo.ProductItemCategoriesUpdate === true) {
        let CD = await GetDropdownData(1104137);
        //console.log(`ProductItemCategoriesDict: ${JSON.stringify(CD)}`);
        this.setState({ ProductItemCategoriesDict: CD.d });
        //this.ItemCategoriesDD = CD.d;
        SetProductItemCategory(CD.d);
        SessionInfo.ProductItemCategoriesUpdate = false;
      }
      //console.log(`ProductItemCategoriesDict length: ${this.state.ProductItemCategoriesDict.length}`); - Note State not updated yet
      if (this.state.ProductItemGroupsDict.length <= 1 || SessionInfo.ProductItemGroupsUpdate === true) {
        let CD = await GetDropdownData(1104119);
        this.setState({ ProductItemGroupsDict: CD.d });
        SetProductItemGroups(CD.d);
        SessionInfo.ProductItemGroupsUpdate = false;
      }
      if (this.state.ProductItemTypesDict.length <= 1 || SessionInfo.ProductItemTypesUpdate === true) {
        let CD = await GetDropdownData(1104125);
        this.setState({ ProductItemTypesDict: CD.d });
        //SetProductItemTypes(CD.d);
        SessionInfo.ProductItemTypesUpdate = false;
      }
      if (this.state.ItemStatusSetDict.length <= 1 || SessionInfo.ItemStatusSetUpdate === true) {
        let CD = await GetDropdownData(1104159);
        this.setState({ ItemStatusSetDict: CD.d });
        //SetItemStatusSet(CD.d);
        SessionInfo.ItemStatusSetUpdate = false;
      }
      if (this.state.PackagingTypesDict.length <= 1 || SessionInfo.PackagingTypesUpdate === true) {
        let CD = await GetDropdownData(519062);
        this.setState({ PackagingTypesDict: CD.d });
        //SetPackagingTypes(CD.d);
        SessionInfo.PackagingTypesUpdate = false;
      }
      //if (this.state.ItemSizeCodesDict.length <= 1 || SessionInfo.ItemSizeCodesUpdate === true) {
      //  let CD = await GetDropdownData(1104143);
      //  this.setState({ ItemSizeCodesDict: CD.d });
      //  //SetItemSizeCodes(CD.d);
      //  SessionInfo.ItemSizeCodesUpdate = false;
      //}
      if (this.state.PromotionTypesDict.length <= 1) {
        let CD = await GetDropdownData(545179);
        this.setState({ PromotionTypesDict: CD.d });
        //SetPromotionTypes(CD.d);
      }
      //if (this.state.StorePromotionsDict.length <= 1 || SessionInfo.StorePromotionsUpdate === true) {
      //  let CD = await GetDropdownData(544701);
      //  this.setState({ StorePromotionsDict: CD.d });
      //  //SetStorePromotions(CD.d);
      //  SessionInfo.StorePromotionsUpdate = false;
      //}
      //if (this.state.PricingTypesDict.length <= 1 || SessionInfo.PricingTypesUpdate === true) {
      //  let CD = await GetDropdownData(1104167);
      //  this.setState({ PricingTypesDict: CD.d });
      //  //SetPricingTypes(CD.d);
      //  SessionInfo.PricingTypesUpdate = false;
      //}
      //if (this.state.TaxableTypesDict.length <= 1 || SessionInfo.TaxableTypesUpdate === true) {
      //  let CD = await GetDropdownData(546870);
      //  this.setState({ TaxableTypesDict: CD.d });
      //  //SetTaxableTypes(CD.d);
      //  SessionInfo.TaxableTypesUpdate = false;
      //}
      if (this.state.RateTypesDict.length <= 1 || SessionInfo.RateTypesUpdate === true) {
        let CD = await GetDropdownData(545339);
        this.setState({ RateTypesDict: CD.d });
        //SetRateTypes(CD.d);
        SessionInfo.RateTypesUpdate = false;
      }
      if (this.state.InventoryControlTypeIDs.length <= 1) {
        let CD = await GetDropdownData(556138);
        this.setState({ InventoryControlTypeIDs: CD.d });
      }
      if (this.state.SeasonCodesDict.length <= 1 || SessionInfo.SeasonCodesUpdate === true) {
        let CD = await GetDropdownData(1120743);
        this.setState({ SeasonCodesDict: CD.d });
        //SetSeasonCodes(CD.d);
        SessionInfo.SeasonCodesUpdate = false;
      }
      if (this.state.DayOfWeekDict.length <= 1) {
        let CD = await GetDropdownData(113509);
        this.setState({ DayOfWeekDict: CD.d });
        SetDayOfWeek(CD.d);
      }
      this.setState({ DaysOfWeek: DayOfWeekDD });
      if (this.state.TimeOfDayDict.length <= 1 || SessionInfo.TimeOfDayUpdate === true) {
        let CD = await GetDropdownData(546466);
        this.setState({ TimeOfDayDict: CD.d });
        //SetTimeOfDays(CD.d);
        SessionInfo.TimeOfDayUpdate = false;
      }
      if (this.state.PaymentMethods.length <= 1) {
        let CD = await GetDropdownData(544793);
        this.setState({ PaymentMethods: CD.d });
      }
      //------ Initialization Code - ----------
      if (this.state.StoreLocationsDict.length <= 1) {
        let CD = await GetDropdownData(544596);
        this.setState({ StoreLocationsDict: CD.d });
      }
      if (this.state.TimeOverrideTypes.length <= 1) {
        let CD = await GetDropdownData(547665);
        //console.log(`TimeOverrideTypes: ${JSON.stringify(CD.d)}`);
        this.setState({ TimeOverrideTypes: CD.d });
        SetTimeOverrideTypes(CD.d);
      }
      if (this.state.ServiceTypes.length <= 1) {
        let CD = await GetDropdownData(555748);
        //console.log(`ServiceTypes: ${JSON.stringify(CD.d)}`);
        this.setState({ ServiceTypes: CD.d });
        SetServiceTypes(CD.d);
      }
      await LoadTableInformation(this.CurrentStoreLocationID);
      await this.loadReservationSched(); // Need to preload schedule data for some reason???
    }
    if (this.state.MainStoreItems.lenth === 0) {
      await this.getProductItems('');
    }
    //SessionInfo.StoreLocationDetail = this.state; 
    //console.log("complete initialize location");
  }
  //-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------  
  getStoreLocations = async (search) => { // Get the available StoreLocations
    let CD;
    console.log(`getStoreLocations - search: ${search}`);
    if (!search)
      CD = await GetTableData(545207); // Get StoreInfoView
    else
      CD = await GetTableSearch(545207,search); // Search StoreInfoView - returns different results
    if (CD.x.o >= 9000 && CD.x.o < 9500) {
      try {
        const StoreLocationsData = CD.d.rows;
        //console.log(`--> Store Locations: ${JSON.stringify(StoreLocationsData)}`);
        this.setState({ StoreLocationsData });
        this.setState({ storeLocationsCount: StoreLocationsData.length });
      } catch (error) {
        console.log(error.message);
      }
    }
    else {
      displayError("Unable to retrieve Locations");
      this.setState({ StoreLocationsData: [] });
    }
  }
  storeLocationRowClick = async (event) => {
    //displayMessage("Row Click");
    const current = this.state.StoreLocationsData.findIndex(dataItem => dataItem === event.dataItem);
    //let last = current;
    this.state.StoreLocationsData.forEach(item => item.selected = false);
    //const select = !event.dataItem.selected;
    //for (let i = Math.min(last, current); i <= Math.max(last, current); i++) {
    //  this.state.StoreLocationsData[i].selected = select;
    //}
    this.state.StoreLocationsData[current].selected = true;
    if (this.state.itemStateHasChanged === true) {
      if (SessionInfo.onChangeAskSave === true) {
        let okay = window.confirm(`Save Current Changes to: ${this.state.StoreName}?`);
        if (okay === false) {
          displayMessage("Changes to the Current Location Abandoned");
        }
        else {
          await this.saveRecord();
          //console.log("Row Saved");
        }
      }
      else {
        let okay = window.confirm(`Abandon Current Changes to: ${this.state.StoreName}?`);
        if (okay === false) {
          displayMessage("Row must be Saved First");
          return;
        }
        displayMessage("Changes to the Current Location Abandoned");
      }
    }
    let PKID = this.state.StoreLocationsData[current].StoreLocationID;
    console.log(`Select StoreLocationID: ${this.CurrentStoreLocationID} PKID: ${PKID}`);
    if (this.CurrentStoreLocationID != PKID) {
      this.CurrentStoreLocationID = PKID;
      this.CurrentStoreLocationIndex = current;
      this.initializeLocation();
      this.setState({ Sublocations: [] });
      this.closeLeftPane();
      SessionInfo.setShowSave = 1; // Show Save in Header 
      SessionInfo.setShowNew = 1;
      this.setState({ StoreLocationPhotoURL: undefined });
      this.setState({ verticalPanes: this.vertSplitC });
    }
  }
  getStoreItems = async (search) => { // Get StoreItems for this Location
    if (!search)
      search = '';
    search = search + "*StoreLocationID=" + this.CurrentStoreLocationID;
    let CD = await CallOM("GetProductItemsForLocation",1,this.CurrentStoreLocationID,0,"",search); // Note - Retrieve Translated
    //this.setState({ StoreItems: CD.d });     
    //.log("Store Items d : " + JSON.stringify(CD.d));
    try {
      const StoreItems = CD.d.rows.map(dataItem => Object.assign({ selected: false },dataItem));
      //console.log("Store Items: " + JSON.stringify(StoreItems));
      this.setState({ StoreItems });
    } catch (error) {
      console.log(error.message);
    }
  }
  getProductItems = async (search) => { // Get the MainStore product items - Company
    if (!search)
      search = '';
    if (search[0] === '*')
      search = search + " and StoreLocationID=0";
    else
      search = search + "*StoreLocationID=0";
    let CD = await CallOM("GetMainProductItemsForLocation",1,this.CurrentStoreLocationID,2,search,"StoreItemID,ItemName,ItemDescription,ItemPrice,ProductItemCategoryID");
    //console.log("Product Items: " + JSON.stringify(CD.d));
    try {
      const MainStoreItems = CD.d.rows.map(dataItem => Object.assign({ selected: false },dataItem));
      //console.log("MainStore Items: " + JSON.stringify(MainStoreItems));
      this.setState({ MainStoreItems });
      this.setState({ mainStoreItemsCount: MainStoreItems.length });
    } catch (error) {
      console.log("Error GetProductItems: " + error.message);
    }
  }
  getHoursOfOperation = async () => {
    let CD = await CallOM("GetHoursOfOperation",544608,this.CurrentStoreLocationID);
    //console.log("Hours of Operation: " + JSON.stringify(CD.d));
    try {
      const HoursOfOperation = CD.d.rows.map(dataItem => Object.assign({ selected: false },dataItem));
      this.setState({ HoursOfOperation });
      this.setState({ hoursOfOperationCount: HoursOfOperation.length });
      this.state.saveHoursOfOperation = HoursOfOperation;
    } catch (error) {
      console.log(error.message);
    }
  }
  formatMoney = (amount) => {
    let decimalCount = 2;
    const decimal = ".";
    const thousands = ",";
    try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

      const negativeSign = amount < 0 ? "-" : "";

      let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
      let j = (i.length > 3) ? i.length % 3 : 0;

      return negativeSign + (j ? i.substring(0,j) + thousands : '') + i.substring(j).replace(/(\d{3})(?=\d)/g,"$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
    } catch (e) {
      console.log(e)
    }
  }
  frmt = true;
  percentFormat = this.frmt === true ? (value) => new Intl.NumberFormat('en-US',{ style: 'percent',minimumFractionDigits: 2,maximumFractionDigits: 2 }).format(value) : (value) => value;
  moneyFormat = (value) => new Intl.NumberFormat('en-US',{ style: 'currency',currency: 'USD' }).format(value);
  dateTimeFormat = (value) => new Intl.DateTimeFormat('en-CA',{ dateStyle: 'long',timeStyle: 'short' }).format(value)
  dateTimeSFormat = (value) => new Intl.DateTimeFormat('en-CA',{ month: 'short',day: 'numeric',hour: 'numeric',minute: '2-digit' }).format(Date.parse(value))
  //
  getCustomOrders = async (search) => {
    if (!search)
      search = '';
    console.log("Get CustomOrders - locationID: " + this.CurrentStoreLocationID);
    let CD = await GetTableSearch(545072,search + "*StoreLocationID=" + this.CurrentStoreLocationID,"CustomerID,CustomOrderStatus,OrderCreationDate,OrderPaidDate,TipAmount,AmountPaidWithTip,AmountOwing,ItemCount");
    //console.log("CustomOrders: " + JSON.stringify(CD.d));
    try {
      const CustomOrders = CD.d.rows.map(dataItem => Object.assign({ selected: false },dataItem));
      //console.log("CustomOrders: " + CustomOrders);
      this.setState({ CustomOrders });
    } catch (error) {
      console.log("Failure in getCustomOrders - Error: " + error.message);
    }
  }
  getCustomOrdersStatus = async (statusID) => {
    console.log("Get CustomOrdersStatus - locationID: " + this.CurrentStoreLocationID);
    let CD = await GetTableSearch(545072,"*StoreLocationID=" + this.CurrentStoreLocationID + " and CustomOrderStatus=" + statusID + " and TotalPrice>0","CustomerID,CustomOrderStatus,OrderCreationDate,OrderPaidDate,TotalPrice,ItemCount");
    try {
      const CustomOrders = CD.d.rows.map(dataItem => Object.assign({ selected: false },dataItem));
      //console.log("CustomOrders: " + JSON.stringify(CustomOrders));
      if (statusID === 2)
        this.setState({ CustomOrdersPending: CustomOrders });
      else if (statusID === 3)
        this.setState({ CustomOrdersAccepted: CustomOrders });
      else if (statusID === 5)
        this.setState({ CustomOrdersInProgress: CustomOrders });
      else if (statusID === 6)
        this.setState({ CustomOrdersReady: CustomOrders });
      else if (statusID === 10)
        this.setState({ CustomOrdersRejected: CustomOrders });
    } catch (error) {
      console.log("Failure in getCustomOrdersStatus - Error: " + error.message);
    }
  }
  getCustomOrdersCategory = async (categoryID) => {
    let CD = await await CallOM("GetCustomOrdersCategory",this.state.StoreLocationID,categoryID,0);
    //console.log("CustomOrdersCategory: " + JSON.stringify(CD.d));
    try {
      const CustomOrders = CD.d.rows.map(dataItem => Object.assign({ selected: false },dataItem));
      console.log("CustomOrdersCategory: " + CustomOrders);
      this.setState({ CustomOrdersCategory: CustomOrders });
    } catch (error) {
      console.log("Failure in getCustomOrdersCategory - Error: " + error.message);
    }
  }
  getCustomOrderItems = async (search) => {
    if (!search)
      search = '';
    console.log(`Get CustomOrderItems - CustomOrderID=${COV.CustomOrderID}`);
    let CD = await await CallOM("GetCustomOrderItemsShort",0,COV.CustomOrderID,0);
    console.log("CustomOrderItems: " + JSON.stringify(CD.d));
    try {
      const CustomOrderItems = CD.d.rows; //.map(dataItem => Object.assign({ selected: false }, dataItem));
      console.log("CustomOrderItems: " + JSON.stringify(CustomOrderItems));
      this.setState({ customOrderItemsCount: CustomOrderItems.length });
      await this.setState({ CustomOrderItems });
    } catch (error) {
      console.log("Failure in getCustomOrderItems - Error: " + error.message);
    }
  }
  getCustomOrderItemsStatus = async (statusID) => {
    console.log("Get CustomOrderItemsStatus - locationID: " + this.CurrentStoreLocationID + ", CustomOrderID=" + this.state.CustomOrderID);
    let CD = await GetTableSearch(545113,"*CustomOrderID=" + this.state.CustomOrderID,"StoreItemID,Quantity,TotalPrice");
    //.log("CustomOrderItems: " + JSON.stringify(CD.d));
    try {
      const CustomOrderItems = CD.d.rows.map(dataItem => Object.assign({ selected: false },dataItem));
      //console.log("CustomOrderItems: " + CustomOrderItems);
      this.setState({ CustomOrderItemsPending: CustomOrderItems });
      //this.setState({ customOrderItemsCount: CustomOrderItems.length });
    } catch (error) {
      console.log("Failure in getCustomOrderItemsStatus - Error: " + error.message);
    }
  }

  getPromotions = async (search) => {
    if (!search)
      search = '';
    let CD = await GetTableSearch(544701,search + `*StoreLocationID=${this.CurrentStoreLocationID}`,"StorePromotionID,StorePromotionName,PromotionType,ProductItemCategoryID,StartDateTime,EndDateTime,DiscountPercentage,DiscountAmount");
    //console.log("Promotions: " + JSON.stringify(CD.d));
    try {
      const Promotions = CD.d.rows.map(dataItem => Object.assign({ selected: false },dataItem));
      //console.log("Promotions: " + JSON.stringify(CD.d));
      this.setState({ Promotions });
      this.setState({ promotionsCount: Promotions.length });
    } catch (error) {
      console.log("Failure in getPromotions - Error: " + error.message);
    }
  }

  getAllStoreLocationDetail = async () => {
    console.log(`StoreLocationID: ${this.CurrentStoreLocationID}`);
    //console.log("StoreLocationDetail getLocation - StoreLocationID: " + this.CurrentStoreLocationID);
    displayMessage("GREEN");
    await this.getStoreLocationRecord(this.CurrentStoreLocationID);
    this.loadExistingPage = false;
  }
  getStoreLocationRecord = async (Key) => {
    let CD = await CallOM("GetStoreLocation",0,Key,0);
    //console.log("after GetStoreLocation: " + JSON.stringify(CD.d)); 
    if (!CD.d) {
      LogOffResetMsg(`Web Session Has Failed in ${SessionInfo.currentPage} - Session: ${CD.x.s}`);
      this.props.navigate("/Login");
    }
    let currentImageID = 0;
    this.compUpdate = false;
    this.setState({ StoreLocationID: Key });
    let row = CD.d;
    StoreLocationsO.forEach(elem => {
      if (elem.n in row) {
        //console.log(`Set ${elem.n}: ${row[elem.n]}`);
        let sobj = {};
        try {
          if (elem.t === 34 || elem.t === 35) { // Date, DateTime
            //sobj[elem.n] = new Date(row[elem.n]); // For use with DateTime Control
            sobj[elem.n] = row[elem.n];
            SLV[elem.n] = new Date(row[elem.n]);
          } else if (elem.t === 21 || elem.t === 195 || elem.t === 201) { // String, FK-Translated, Enum
            sobj[elem.n] = row[elem.n];
            let sobjS = {};
            sobjS[elem.n + 'S'] = row[elem.n];
            //console.log(`Set String Field: ${elem.n + 'S'}, Value: ${ sobjS[elem.n + 'S']}`);
            this.setState(sobjS); // Set the String shadow var
            SLV[elem.n] = row[elem.n];
          } else if (elem.t === 28) { // Money
            sobj[elem.n] = row[elem.n];
            let sobjS = {};
            sobjS[elem.n + 'S'] = this.moneyFormat(row[elem.n]);
            //console.log(`Set Money Field: ${elem.n + 'S'}, Value: ${ sobjS[elem.n + 'S']}`);
            this.setState(sobjS); // Set the String shadow var
            SLV[elem.n] = row[elem.n];
          } else if (elem.t === 41) { // Media - Image or Document
            sobj[elem.n] = row[elem.n];
            SLV[elem.n] = row[elem.n];
            if (elem.n === 'ImageID')
              currentImageID = row[elem.n];
          } else if (elem.t === 15) { // Encoded
            //------ Assign Encoded Values ------
            let AList = [];
            if ("StoreLocationControl" === elem.n) {
              let EncodeVal = row[elem.n];
              StoreLocationControlFixedValues.forEach((ent) => {
                if ((EncodeVal & (0x001 << ent.k)) === (0x001 << ent.k))
                  AList.push(ent.n);
              });
              console.log("StoreLocationControl AList: " + JSON.stringify(AList));
            } else if ("StoreAmenities" === elem.n) {
              let EncodeVal = row[elem.n];
              StoreAmenitiesFixedValues.forEach((ent) => {
                if ((EncodeVal & (0x001 << ent.k)) === (0x001 << ent.k))
                  AList.push(ent.n);
              });
              console.log("StoreAmenities AList: " + JSON.stringify(AList));
            } else if ("DayPeriods" === elem.n) {
              let EncodeVal = row[elem.n];
              DayPeriodsFixedValues.forEach((ent) => {
                if ((EncodeVal & (0x001 << ent.k)) === (0x001 << ent.k))
                  AList.push(ent.n);
              });
              console.log("DayPeriods AList: " + JSON.stringify(AList));
            }
            let sobjV = {};
            sobjV[`${elem.n}Vals`] = AList; // Set the Vals Array
            this.setState(sobjV); // Set the Vals var
            sobj[elem.n] = row[elem.n];
            SLV[elem.n] = row[elem.n];
            console.log(`sobj ${JSON.stringify(sobj)}, t: ${elem.t}, val: ${SLV[elem.n]}`);
          } else {
            sobj[elem.n] = row[elem.n];
            SLV[elem.n] = row[elem.n];
          }
          //console.log(`sobj ${JSON.stringify(sobj)}, t: ${elem.t}, val: ${SLV[elem.n]}`);
          if (this.state[elem.n] === undefined)
            console.log(`${elem.n} is undefined`);
          this.setState(sobj);
          // Add item specific code here
          //if (elem.n === 'ImageID')
          //  ImageID = row[elem.n];
        }
        catch (error) {
          console.log(`Set StoreLocationsO Field: ${elem.n}, Error: ${error.message}`);
        }
      }
    });
    // Retrieved Value Assignment for: Locations
    LocationsO.forEach(elem => {
      if (elem.n in row) {
        //console.log(`Set ${elem.n}: ${row[elem.n]}`);
        let sobj = {};
        try {
          if (elem.t === 34 || elem.t === 35) { // Date, DateTime
            sobj[elem.n] = new Date(row[elem.n]); // For use with DateTime Control
            //sobj[elem.n] = row[elem.n];
            LV[elem.n] = new Date(row[elem.n]);
          } else if (elem.t === 21 || elem.t === 195 || elem.t === 201) { // String, FK-Translated, Enum
            sobj[elem.n] = row[elem.n];
            let sobjS = {};
            sobjS[elem.n + 'S'] = row[elem.n];
            //console.log(`Set String Field: ${elem.n + 'S'}, Value: ${ sobjS[elem.n + 'S']}`);
            this.setState(sobjS); // Set the String shadow var
            LV[elem.n] = row[elem.n];
          } else if (elem.t === 22) { // Bool
            let val = row[elem.n].toString().charAt();
            if (val === '1' || val === 't' || val === 'T') {
              sobj[elem.n] = true;
              LV[elem.n] = true;
            } else {
              sobj[elem.n] = false;
              LV[elem.n] = false;
            }
          } else if (elem.t === 23) { // Decimal
            if (elem.n.indexOf('Percent') >= 0 || elem.n.indexOf('Markup') >= 0) {
              sobj[elem.n] = row[elem.n];
              let sobjS = {};
              sobjS[elem.n + 'S'] = this.moneyFormat(row[elem.n]);
              this.setState(sobjS); // Set the String shadow var
              LV[elem.n] = row[elem.n];
            } else { // Decimal
              sobj[elem.n] = row[elem.n];
              LV[elem.n] = row[elem.n];
            }
          } else if (elem.t === 28) { // Money
            sobj[elem.n] = row[elem.n];
            let sobjS = {};
            sobjS[elem.n + 'S'] = this.moneyFormat(row[elem.n]);
            //console.log(`Set Money Field: ${elem.n + 'S'}, Value: ${ sobjS[elem.n + 'S']}`);
            this.setState(sobjS); // Set the String shadow var
            LV[elem.n] = row[elem.n];
          } else if (elem.t === 41) { // Media - Image or Document
            sobj[elem.n] = row[elem.n];
            LV[elem.n] = row[elem.n];
            if (elem.n === 'ImageID')
              currentImageID = row[elem.n];
          } else if (elem.t === 15) { // Encoded
            //------ Assign Encoded Values ------

            let AList = [];

            let sobjV = {};
            sobjV[`${elem.n}Vals`] = AList; // Set the Vals Array
            this.setState(sobjV); // Set the Vals var
            sobj[elem.n] = row[elem.n];
            //obj[elem.n] = row[elem.n];
            LV[elem.n] = row[elem.n];
            console.log(`Encoded sobj ${JSON.stringify(sobj)}, t: ${elem.t}, val: ${LV[elem.n]}`);
          } else { // Default - Number
            sobj[elem.n] = row[elem.n];
            LV[elem.n] = row[elem.n];
          }
          //console.log(`sobj ${JSON.stringify(sobj)}, t: ${elem.t}, val: ${LV[elem.n]}`);
          if (this.state[elem.n] === undefined)
            console.log(`=== ${elem.n} is not defined in state ===`);
          this.setState(sobj);
          // Add item specific code here
          //if (elem.n === 'ImageID')
          //  ImageID = row[elem.n];
        }
        catch (error) {
          console.log(`Set LocationsO Field: ${elem.n}, Error: ${error.message}`);
        }
      }
    });
    //console.log("Amenities: " + this.state.StoreAmenities);
    //let AList = [];
    //Amenities.forEach((elem) => {
    //  if ((this.state.StoreAmenities & (0x001 << elem.k)) === (0x001 << elem.k))
    //    AList.push(elem.n);
    //});
    ////console.log("Amenities AList: " + JSON.stringify(AList));
    //this.setState({ StoreAmenitiesVals: AList });
    console.log(`Load StoreLocation Record Complete`);
    this.compUpdate = true;
    this.forceUpdate();
    this.setItemStateChanged(false);
  }
  //-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
  setQRLocation = async (event) => {
    this.QRCodeLocation = `https://interactmenu.com/?tableid=${SessionInfo.CurrentTableID}&locationid=${SessionInfo.CurrentLocationID}`;
    console.log(`QRCodeLocation: ${this.QRCodeLocation}`);
    this.setState({ showQRCode: true });
  }
  clearQRPopup = async () => {
    this.setState({ showQRCode: false });
  }
  onDisplayItemQR = async (event) => {
    this.QRCodeItem = this.state.BarcodeNumber;
    if (this.state.BarcodeNumber === '')
      this.QRCodeItem = this.state.StoreItemNumber;
    console.log(`QRCodeItem: ${this.QRCodeItem}`);
    this.setState({ showItemLocQRCode: true });
  }
  clearLocQRPopup = async () => {
    this.setState({ showItemLocQRCode: false });
  }
  //-------- Help -----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
  showHelp = async (key) => {
    if (this.state.showHelpListPopup == true) { // Close Help
      this.setState({ showHelpListPopup: false });
      this.setState({ showHelpDetail: false });
    } else {
      if (!key)
        key = '';
      this.setState({ showHelpDetail: false });
      let HelpPages = await getHelpTopics(key);
      //console.log("caller HelpPages: " + JSON.stringify(HelpPages));
      this.setState({ HelpPages });
      this.setState({ showHelpListPopup: true });
    }
  }
  closeHelpList = () => {
    this.setState({ showHelpListPopup: false });
  }
  onHelpItemClick = async (item) => {
    let helpDetail = await getHelpDetail(item.HelpPageID);
    this.setState({ helpDetail });
    this.setState({ showHelpDetail: true });
  }
  closeHelpTime = async () => {
    this.setState({ showHelpDetail: false });
  }
  onObjectHelp = async (objectID) => {
    let helpDetail = await getHelpDetail(objectID,2);
    this.setState({ helpDetail });
    this.setState({ showHelpDetail: true });
  }
  closeHelpTime = async () => {
    this.setState({ showHelpDetail: false });
  }
  // Help List Layout
  HelpPageHeader = () => {
    return (
      <ListViewHeader className='listHelpHeader' onClick={(e) => this.closeHelpList()} >
        <div className='listHeaderLeft' onClick={(e) => this.closeHelpList()}>
          <span className="listHelpTitle">{SessionInfo.HelpTitle}</span>
        </div>
        <div className='listHeaderRight' onClick={(e) => this.closeHelpList()}>
          <Button id="cancelButton" icon="cancel" look="outline" className="medButton" onClick={this.closeHelpList} >EXIT HELP</Button>
        </div>
      </ListViewHeader>
    );
  }
  HelpPageFormat = props => {
    let item = props.dataItem;
    return (
      <div>
        <div className="listHelpRow" onClick={(e) => this.onHelpItemClick(item)}>
          <div className="listColA">
            <span className="listHelpItemTitle" >{item.HelpTitle}&nbsp;&nbsp;&nbsp;</span>
            <div dangerouslySetInnerHTML={{ __html: item.HelpContent }}></div>
          </div>
        </div>
      </div>
    );
  }
  HelpPageFooter = () => {
    //const [state, onCheckout] = React.useContext(StateContext);
    return (
      <ListViewFooter className='listHelpFooter' onClick={(e) => this.closeHelpList()} >
        <div className='listHeaderRight' onClick={(e) => this.closeHelpList()}>
          <Button id="cancelButton" icon="cancel" look="outline" className="medButton" onClick={this.closeHelpList} >EXIT HELP</Button>
        </div>
      </ListViewFooter>
    );
  }
  ShowFieldHelp = async (key) => {
    console.log(`Field Help - Key: ${key}`);
    this.onObjectHelp(key);
    //this.showHelp(key);
    //console.log(`Field Help: ${JSON.stringify(item)}`);
  }
  //-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
  deleteRecord = async () => {
    let CD = await DeleteRowTry(544596,this.state.StoreLocationID);
    this.setItemStateChanged(false);
  }
  //-------------------- End of Page Edit -----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

  //------------------------- Select Invite----------------------------------------------------------------------
  selectionHoursOfOpChange = (event) => {
    event.dataItem.selected = !event.dataItem.selected;
    this.forceUpdate();
  }
  //------------------------- Select StoreItem from Store Database ---------------------------------------------------
  storeItemRowClick = async (event) => {
    const current = this.state.StoreItems.findIndex(dataItem => dataItem === event.dataItem);
    //console.log("StoreItem Click - current: " + current);
    if (current >= 0) {
      this.state.StoreItems.forEach(item => item.selected = false);
      this.state.StoreItems[current].selected = true;
      SessionInfo.SelectStoreLocationID = this.state.StoreLocationID;
      SessionInfo.StoreItemID = this.state.StoreItems[current].StoreItemID;
      //this.props.history.push("/StoreItemDetail")      
      let CD = await GetTableRow(544619,SessionInfo.StoreItemID);
      this.compUpdate = false;
      //console.log("after StoreItem Get: " + JSON.stringify(CD.d));
      this.setState({ StoreLocationItemID: SessionInfo.StoreItemID });
      var row = CD.d.row;
      this.populateStoreItem(row);
      this.compUpdate = true;
      CD = await CallOM("StoreItemDiff",0,SessionInfo.StoreItemID,0);
      if (CD.d) {
        console.log(`For: ${SessionInfo.StoreItemID} - Diffs: ${JSON.stringify(CD.d)}`);
        this.processItemClasses(CD.d);
      }
      this.forceUpdate();
    }
  }
  populateStoreItem = async (row) => {
    try {
      let currentImageID = 0;
      StoreItemsO.forEach((elem) => {
        if (elem.n in row) {
          //console.log("Set " + elem.n + ": " + row[elem.n]);
          let sobj = {};
          try {
            if (elem.t === 34 || elem.t === 35)
              sobj[elem.n] = new Date(row[elem.n]);
            else if (elem.t === 28) {
              sobj[elem.n] = row[elem.n];
              let sobjS = {};
              sobjS[elem.n + 'S'] = this.moneyFormat(row[elem.n]);
              //console.log("Set StoreItem Field: " + elem.n + " Value: " + sobjS[elem.n + 'S']);
              this.setState(sobjS);
            } else if (elem.t === 41) {
              if (elem.n == "ImageID") {
                currentImageID = row[elem.n];
                //console.log(`ImageID-t: ${elem.t}`);
              }
            } else {
              sobj[elem.n] = row[elem.n];
            }
            //console.log("sobj " + JSON.stringify(sobj));
            this.setState(sobj);
          } catch (error) {
            console.log(`Set StoreItem Field: ${elem.n} Error: ${error.message}`);
          }
        }
      });
    }
    catch (error) {
      console.log(`set error: ${error.message}`);
    }
    this.setItemStateChanged(false);
  }
  onRefreshStoreItem = async () => {
    console.log("Refresh StoreItems - StoreLocationID: " + this.state.StoreLocationID + " StoreItemID: " + this.state.StoreItemID + " Ref: " + this.state.ReferenceItemID);
    let CD = await CallOM("CopyStoreItemToStore",this.state.StoreLocationID,SessionInfo.StoreItemID,0);
    let RetVal = CD.d;
    if (RetVal === 1) {
      // Refetch StoreItem
      let CD = await GetTableRow(544619,SessionInfo.StoreItemID);
      this.compUpdate = false;
      //console.log("after StoreItem Get: " + JSON.stringify(CD.d));
      this.setState({ StoreLocationItemID: SessionInfo.StoreItemID });
      var row = CD.d.row;
      this.populateStoreItem(row);
      this.compUpdate = true;
      CD = await CallOM("StoreItemDiff",0,SessionInfo.StoreItemID,0);
      if (CD.d) {
        console.log(`For: ${SessionInfo.StoreItemID} - Diffs: ${JSON.stringify(CD.d)}`);
        this.processItemClasses(CD.d);
      }
      // Reload StoreItems grid
      await this.getStoreItems(); // Refresh StoreItems for this location
    }
    else
      displayWarning(`Item Copy for ${this.state.StoreItemID} Failed`);
  }
  onRefreshAllStoreItems = async () => {
    console.log(`Refresh ALL StoreItems - StoreLocationID: ${this.state.StoreLocationID}, search: ${this.saveSearch}`);
    let CD = await CallOM("RefreshCatalogueItemsToStore",0,this.state.StoreLocationID,2,this.saveSearch);
    let cnt = CD.d;
    console.log(`Refresh Item Cnt: ${cnt}`);
    if (cnt === 0)
      displayMessage(`  No Items were copied for: ${this.saveSearch}`);
    else if (cnt === 1)
      displayMessage(`  ${cnt} Item was copied for: ${this.saveSearch}`);
    else
      displayMessage(`  ${cnt} were Items copied for: ${this.saveSearch}`);
    // Reload StoreItems grid
    await this.getStoreItems(); // Refresh StoreItems for this location
  }
  processItemClasses = (diffs) => {
    this.ClassNames.forEach(elem => {
      //if (elem.n in diffs) {
      if (diffs.find(n => n === elem.n)) {
        console.log(`diff found: ${elem.n}`);
        if (elem.c.indexOf("Red") < 0) {
          elem.c = elem.c + "Red";
        }
      } else {
        if (elem.c.indexOf("Red") > 0) {
          let lth = elem.c.length;
          elem.c = elem.c.substring(0,lth - 3);
        }
      }
    });
  }
  storeItemsPageChange = (event) => {
    this.setState({ storeItemsSkip: event.page.skip,storeItemsTake: event.page.take });
  }
  //----------------------------------------------------------------------------------
  // Convert Encoded Values For StoreLocationControl
  // Add to saveRecord  await getStoreLocationControlEncoded()
  getStoreLocationControlEncoded = async () => {
    let StoreLocationControl = 0x0000000000000000;
    let VList = this.state.StoreLocationControlVals;
    if (VList.includes('Store Is Active'))
      StoreLocationControl |= (0x001 << 1);
    if (VList.includes('Store Is Open'))
      StoreLocationControl |= (0x001 << 2);
    if (VList.includes('Store Has Scan'))
      StoreLocationControl |= (0x001 << 3);
    if (VList.includes('Store Has Order'))
      StoreLocationControl |= (0x001 << 4);
    if (VList.includes('Order Is Open'))
      StoreLocationControl |= (0x001 << 5);
    await this.setState({ StoreLocationControl });
  }
  // Convert Encoded Values For StoreAmenities
  // Add to saveRecord  await getStoreAmenitiesEncoded()
  getStoreAmenitiesEncoded = async () => {
    let StoreAmenities = 0x0000000000000000;
    let VList = this.state.StoreAmenitiesVals;
    if (VList.includes('Wifi Available'))
      StoreAmenities |= (0x001 << 1);
    if (VList.includes('Coffee'))
      StoreAmenities |= (0x001 << 2);
    if (VList.includes('Specialty Coffee'))
      StoreAmenities |= (0x001 << 3);
    if (VList.includes('Salads'))
      StoreAmenities |= (0x001 << 5);
    if (VList.includes('Soups'))
      StoreAmenities |= (0x001 << 6);
    if (VList.includes('Hot Meals'))
      StoreAmenities |= (0x001 << 7);
    if (VList.includes('Desserts'))
      StoreAmenities |= (0x001 << 9);
    if (VList.includes('Wine'))
      StoreAmenities |= (0x001 << 10);
    if (VList.includes('Beer'))
      StoreAmenities |= (0x001 << 11);
    await this.setState({ StoreAmenities });
  }
  // Convert Encoded Values For DayPeriods
  // Add to saveRecord  await getDayPeriodsEncoded()
  getDayPeriodsEncoded = async () => {
    let DayPeriods = 0x0000000000000000;
    let VList = this.state.DayPeriodsVals;
    if (VList.includes('Breakfast'))
      DayPeriods |= (0x001 << 1);
    if (VList.includes('Lunch'))
      DayPeriods |= (0x001 << 2);
    if (VList.includes('Brunch'))
      DayPeriods |= (0x001 << 3);
    if (VList.includes('Happy Hour'))
      DayPeriods |= (0x001 << 4);
    if (VList.includes('Dinner'))
      DayPeriods |= (0x001 << 5);
    if (VList.includes('Morning'))
      DayPeriods |= (0x001 << 6);
    if (VList.includes('Afternoon'))
      DayPeriods |= (0x001 << 7);
    if (VList.includes('Evening'))
      DayPeriods |= (0x001 << 8);
    if (VList.includes('Late Night'))
      DayPeriods |= (0x001 << 9);
    if (VList.includes('All Day'))
      DayPeriods |= (0x001 << 10);
    if (VList.includes('Special Occasion'))
      DayPeriods |= (0x001 << 11);
    await this.setState({ DayPeriods });
  }
  //------------------------- Select Receipts Row ---------------------------------------------------
  customOrderRowClick = async (event) => {
    const current = this.state.CustomOrders.findIndex(dataItem => dataItem === event.dataItem);
    //console.log("CustomOrder Click - current: " + current);
    if (current >= 0) {
      SessionInfo.SelectCustomOrderID = this.state.CustomOrderID;
      this.state.CustomOrders.forEach(item => item.selected = false);
      this.state.CustomOrders[current].selected = true;
      SessionInfo.CustomOrderID = this.state.CustomOrders[current].CustomOrderID;
      let CD = await GetTableRowTrans(545072,SessionInfo.CustomOrderID);
      this.compUpdate = false;
      //console.log("Get CustomOrder: " + JSON.stringify(CD.d));
      let row = CD.d.row;
      // Retrieved Value Assignment for: CustomOrders
      CustomOrdersO.forEach(elem => {
        if (elem.n in row) {
          //console.log(`Set ${elem.n}: ${row[elem.n]}`);
          let sobj = {};
          try {
            if (elem.t === 34 || elem.t === 35) { // Date, DateTime
              sobj[elem.n] = new Date(row[elem.n]); // For use with DateTime Control
              //sobj[elem.n] = row[elem.n];
              COV[elem.n] = new Date(row[elem.n]);
            } else if (elem.t === 21 || elem.t === 195 || elem.t === 201) { // String, FK-Translated, Enum
              sobj[elem.n] = row[elem.n];
              let sobjS = {};
              sobjS[elem.n + 'S'] = row[elem.n];
              //console.log(`Set String Field: ${elem.n + 'S'}, Value: ${ sobjS[elem.n + 'S']}`);
              this.setState(sobjS); // Set the String shadow var
              COV[elem.n] = row[elem.n];
            } else if (elem.t === 22) { // Bool
              let val = row[elem.n].toString().charAt();
              if (val === '1' || val === 't' || val === 'T') {
                sobj[elem.n] = true;
                COV[elem.n] = true;
              } else {
                sobj[elem.n] = false;
                COV[elem.n] = false;
              }
            } else if (elem.t === 28) { // Money
              sobj[elem.n] = row[elem.n];
              let sobjS = {};
              sobjS[elem.n + 'S'] = this.moneyFormat(row[elem.n]);
              //console.log(`Set Money Field: ${elem.n + 'S'}, Value: ${ sobjS[elem.n + 'S']}`);
              this.setState(sobjS); // Set the String shadow var
              COV[elem.n] = row[elem.n];
            } else if (elem.t === 41) { // Media - Image or Document
              sobj[elem.n] = row[elem.n];
              COV[elem.n] = row[elem.n];
              if (elem.n === 'ImageID')
                currentImageID = row[elem.n];
            } else if (elem.t === 15) { // Encoded
              //------ Assign Encoded Values ------
              let AList = [];
              let sobjV = {};
              sobjV[`${elem.n}Vals`] = AList; // Set the Vals Array
              this.setState(sobjV); // Set the Vals var
              sobj[elem.n] = row[elem.n];
              //obj[elem.n] = row[elem.n];
              COV[elem.n] = row[elem.n];
              console.log(`Encoded sobj ${JSON.stringify(sobj)}, t: ${elem.t}, val: ${COV[elem.n]}`);
            } else { // Default - Number
              sobj[elem.n] = row[elem.n];
              COV[elem.n] = row[elem.n];
            }
            //console.log(`sobj ${JSON.stringify(sobj)}, t: ${elem.t}, val: ${COV[elem.n]}`);
            if (this.state[elem.n] === undefined)
              console.log(`=== ${elem.n} is not defined in state ===`);
            this.setState(sobj);
            // Add item specific code here
            //if (elem.n === 'ImageID')
            //  ImageID = row[elem.n];
          }
          catch (error) {
            console.log(`Set CustomOrdersO Field: ${elem.n}, Error: ${error.message}`);
          }
        }
      });
      this.setItemStateChanged(false);
      await this.getCustomOrderItems();
      this.closeLeftPane();
      this.openRightPane();
      this.compUpdate = true;
      console.log(`customOrderRowClick Complete`);
    }
  }
  customOrdersPageChange = (event) => {
    this.setState({ customOrdersSkip: event.page.skip,customOrdersTake: event.page.take });
  }
  //--------------------------------------------------------------
  promoRowClick = async (event) => { // GetPromotions
    const current = this.state.Promotions.findIndex(dataItem => dataItem === event.dataItem);
    if (current >= 0) {
      this.state.Promotions.forEach(item => item.selected = false);
      this.state.Promotions[current].selected = true;
      let StorePromotionID = this.state.Promotions[current].StorePromotionID;
      this.setState({ StorePromotionID });
      let currentImageID = 0;
      let CD = await GetTableRowRaw(544701,StorePromotionID);
      if (CD && CD.d && CD.d.row) {
        this.compUpdate = false;
        //console.log("after Get: " + JSON.stringify(CD.d)); 
        let row = CD.d.row;
        // Retrieved Value Assignment for: StorePromotions
        StorePromotionsO.forEach(elem => {
          if (elem.n in row) {
            //console.log(`Set ${elem.n}: ${row[elem.n]}`);
            let sobj = {};
            try {
              if (elem.t === 34 || elem.t === 35) { // Date, DateTime
                sobj[elem.n] = new Date(row[elem.n]); // For use with DateTime Control
                //sobj[elem.n] = row[elem.n];
                SPV[elem.n] = new Date(row[elem.n]);
              } else if (elem.t === 21 || elem.t === 195 || elem.t === 201) { // String, FK-Translated, Enum
                sobj[elem.n] = row[elem.n];
                let sobjS = {};
                sobjS[elem.n + 'S'] = row[elem.n];
                //console.log(`Set String Field: ${elem.n + 'S'}, Value: ${ sobjS[elem.n + 'S']}`);
                this.setState(sobjS); // Set the String shadow var
                SPV[elem.n] = row[elem.n];
              } else if (elem.t === 22) { // Bool
                let val = row[elem.n].toString().charAt();
                if (val === '1' || val === 't' || val === 'T') {
                  sobj[elem.n] = true;
                  COV[elem.n] = true;
                } else {
                  sobj[elem.n] = false;
                  COV[elem.n] = false;
                }
              } else if (elem.t === 23) { // Decimal
                if (elem.n.indexOf('Percent') >= 0 || elem.n.indexOf('Markup') >= 0) {
                  sobj[elem.n] = row[elem.n];
                  let sobjS = {};
                  sobjS[elem.n + 'S'] = this.moneyFormat(row[elem.n]);
                  this.setState(sobjS); // Set the String shadow var
                  SPV[elem.n] = row[elem.n];
                } else { // Decimal
                  sobj[elem.n] = row[elem.n];
                  SPV[elem.n] = row[elem.n];
                }
              } else if (elem.t === 28) { // Money
                sobj[elem.n] = row[elem.n];
                let sobjS = {};
                sobjS[elem.n + 'S'] = this.moneyFormat(row[elem.n]);
                //console.log(`Set Money Field: ${elem.n + 'S'}, Value: ${ sobjS[elem.n + 'S']}`);
                this.setState(sobjS); // Set the String shadow var
                SPV[elem.n] = row[elem.n];
              } else if (elem.t === 41) { // Media - Image or Document
                sobj[elem.n] = row[elem.n];
                SPV[elem.n] = row[elem.n];
                if (elem.n === 'ImageID')
                  currentImageID = row[elem.n];
              } else if (elem.t === 15) { // Encoded
                //------ Assign Encoded Values ------

                let AList = [];

                let sobjV = {};
                sobjV[`${elem.n}Vals`] = AList; // Set the Vals Array
                this.setState(sobjV); // Set the Vals var
                sobj[elem.n] = row[elem.n];
                //obj[elem.n] = row[elem.n];
                SPV[elem.n] = row[elem.n];
                console.log(`Encoded sobj ${JSON.stringify(sobj)}, t: ${elem.t}, val: ${SPV[elem.n]}`);
              } else { // Default - Number
                sobj[elem.n] = row[elem.n];
                SPV[elem.n] = row[elem.n];
              }
              //console.log(`sobj ${JSON.stringify(sobj)}, t: ${elem.t}, val: ${SPV[elem.n]}`);
              if (this.state[elem.n] === undefined)
                console.log(`=== ${elem.n} is not defined in state ===`);
              this.setState(sobj);
              // Add item specific code here
              //if (elem.n === 'ImageID')
              //  ImageID = row[elem.n];
            }
            catch (error) {
              console.log(`Set StorePromotionsO Field: ${elem.n}, Error: ${error.message}`);
            }
          }
        });
        await this.GetPromotionImage(currentImageID);
        this.setItemStateChanged(false);
        this.setState({ selectedTopTab: this.Tabs.PROMOS });  // Promotions  
        this.compUpdate = true;
        this.forceUpdate();
      }
      else {
        LogOffResetMsg(`Web Session Has Failed in ${SessionInfo.currentPage} - Session: ${CD.x.s}`);
        this.props.navigate("/Login");
      }
    }
  }
  promoPageChange = (event) => {
    this.setState({ promoSkip: event.page.skip,promoTake: event.page.take });
  }

  retrieveImage = async (iVal) => {
    console.log("Retrieve Image: " + iVal);
    await this.GetPromotionImage(iVal);
  }
  //convertToBuffer = async (reader) => { // File is converted to a buffer to prepare for uploading to OM
  //  const UploadFile = await Buffer.from(reader.result);
  //  //set this buffer -using es6 syntax
  //  this.setState({ UploadFile });
  //  //console.log("converToBuffer - Image uploadfile Length: " + UploadFile.length);
  //  // Display the image
  //  let image = new Blob([UploadFile], { type: 'image/jpeg' });
  //  let imageURL = URL.createObjectURL(image);
  //  this.setState({ ProductItemPhotoURL: imageURL });
  //};
  //-----------------------
  addPromoImage = async (event) => {
    try {
      const fileObj = event.affectedFiles[0].getRawFile();
      let imageType = getDocumentType(event.affectedFiles[0].extension,"Person Photo");
      let reader = new FileReader();
      reader.readAsArrayBuffer(fileObj);
      reader.onloadend = async () => {
        let imageFile = await Buffer.from(reader.result);
        this.setState({ PromotionPhotoURL: URL.createObjectURL(fileObj) });
        let iVal = await saveImageFileToServer(imageFile,imageType,this.StorePromotionsOID,this.state.StorePromotionID);
        this.setState({ ImageID: iVal });
      }
      event.target.value = null;
    }
    catch (error) {
      console.log(`addPromoImage Error: ${error}`);
    }
  };
  //-----------------------
  GetPromotionImage = async (imageID) => {
    console.log("GetPromotionImage ID: " + imageID);
    const imageURL = await getImageFileFromServer(imageID,4);
    this.setState({ PromotionPhotoURL: imageURL });
  }
  //-------------------------------------------------------------- 
  // Store Image
  //--------------------------------------------------------------
  retrieveImage = async (iVal) => {
    console.log("Retrieve Store Image: " + iVal);
    await this.GetStoreImage(iVal);
  }
  GetStoreImage = async (imageID) => {
    console.log("GetsStoreImage ID: " + imageID);
    const imageURL = await getImageFileFromServer(imageID);
    this.setState({ StoreLocationPhotoURL: imageURL });
  }
  onAddStoreItem = async () => {
    this.setState({ StoreItemID: 0 });
    this.setState({ ProductItemCategoryID: 0 });
    this.setState({ ProductItemGroupID: 0 });
    this.setState({ ProductItemTypeID: 0 });
    this.setState({ ShelfLifeDays: 0 });
    this.setState({ ItemPrice: 0 });
    this.setState({ ItemStatusID: 0 });
    this.setState({ PackagingTypeID: 0 });
    this.setState({ PricingTypeID: 0 });
    this.setState({ RateTypeID: 0 });

    this.setState({ ItemName: 'NewItem' });
    this.setState({ ItemDescription: '' });
    this.setState({ UPCNumber: '' });
    this.setState({ BarcodeNumber: '' });
    displayMessage("Ready for New Store Item");
  }

  //------------------------- Edit StoreItem Grid ------------------------------------------------------------------ 
  StoreItemCommandCell;
  enterInsertSI() {
    const dataItem = { inEdit: true };
    const allRecords = this.state.StoreItems.slice();
    allRecords.unshift(dataItem); // Add to the beginning
    this.updateStoreItems(allRecords,dataItem);
    this.setState({ StoreItems: allRecords });
  }
  enterEditSI(dataItem) {
    this.updateStoreItems(this.state.StoreItems,dataItem).inEdit = true;
    this.setState({ StoreItems: this.state.StoreItems.slice() });
  }
  saveSI(dataItem) {
    dataItem.inEdit = undefined;
    if (dataItem.StoreItemID === undefined)
      dataItem.StoreItemID = 0;
    console.log(`Save StoreItemID=${dataItem.StoreItemID}`);
    //console.log("Update StoreItems: " + JSON.stringify(dataItem));
    dataItem.StoreItemID = this.updateStoreItems(this.state.StoreItems,dataItem).StoreItemID;  // Save to OM
    this.setState({ StoreItems: this.state.StoreItems.slice() });
    //console.log("Update Row - locationID: " + dataItem.StoreLocationID);
    UpdateRow(StoreItemsOID,0,this.state.StoreItems,dataItem,dataItem.StoreItemID,"StoreLocationID");  // Update StoreItem
  }
  cancelSI(dataItem) {
    if (dataItem.StoreItemID) {
      let originalItem = this.state.StoreItems.find(p => p.StoreItemID === dataItem.StoreItemID)
      if (originalItem.inEdit) originalItem.inEdit = false;
      this.updateStoreItems(this.state.StoreItems,originalItem);
    } else {
      this.updateStoreItems(this.state.StoreItems,dataItem,!dataItem.StoreItemID); // remove false
    }
    this.setState({ StoreItems: this.state.StoreItems.slice() });
  }
  removeSI(dataItem) {
    dataItem.inEdit = undefined;
    let key = dataItem.StoreItemID;
    console.log(`row delete: ${key}`);
    //console.log("dateitem: " + JSON.stringify(dataItem));
    //console.log("Before StoreItems: " + JSON.stringify(this.state.StoreItems.slice()));
    //this.updateStoreItems(this.state.StoreItems, dataItem, true);
    //console.log("After StoreItems: " + JSON.stringify(this.state.StoreItems.slice()));
    DeleteRowForce(StoreItemsOID,key); // Delete in the StoreItems Table in OM  
    this.setState({ StoreItems: this.state.StoreItems.slice() });
  }
  itemChangeSI(event) {
    const value = event.value;
    const name = event.field;
    if (!name) {
      return;
    }
    const updatedData = this.state.StoreItems.slice();
    const item = this.updateStoreItems(updatedData,event.dataItem);
    //console.log("itemChange - name: " + name + " value: " + JSON.stringify(value));
    if (!value.ddID)
      item[name] = value;
    else
      item[name] = value.ddID;
    this.setState({ StoreItems: updatedData });
  }
  updateStoreItems(data,item,remove) {  // data - is the entire data set (JSON), item - is the current line 
    let updated;
    //eslint-disable-next-line                   
    let index = data.findIndex(p => p === item || item.StoreItemID && p.StoreItemID === item.StoreItemID); // Note - for new line will find 0
    if (index >= 0) {
      updated = Object.assign({},item);
      data[index] = updated;
    }
    if (remove) {
      data = data.splice(index,1);
    }
    return data[index];
  }
  //------------------------- End Edit StoreItem Grid ------------------------------------------------------------------

  //------------------------ Promotions ---------------------------------------------------------------
  onCreatePromotion = async () => {
    await this.saveRecord();
  }
  //------------------------- Edit HoursOfOperation Grid ------------------------------------------------------------------
  rowHoursOfOpClick = async (event) => {
    const current = this.state.HoursOfOperation.findIndex(dataItem => dataItem === event.dataItem);
    if (current >= 0) {
      this.state.HoursOfOperation.forEach(item => item.selected = false);
      this.state.HoursOfOperation[current].selected = true;
    }
  }
  HOOItemCommandCell;
  enterInsertHOO() {
    console.log("Insert HoursOfOperation");
    const dataItem = { inEdit: true };
    const allRecords = this.state.HoursOfOperation.slice();
    allRecords.unshift(dataItem); // Add to the beginning 
    //console.log("Call Update HoursOfOperation");
    this.updateHOOItems(allRecords,dataItem);
    //console.log("After Update HoursOfOperation");
    this.setState({ HoursOfOperation: allRecords });
    console.log("end HoursOfOperation");
  }
  enterEditHOO(dataItem) {
    this.updateHOOItems(this.state.HoursOfOperation,dataItem).inEdit = true;
    this.setState({ HoursOfOperation: this.state.HoursOfOperation.slice() });
  }
  saveHOO(dataItem) {
    console.log(`saveHOO - dataItem: ${JSON.stringify(dataItem)}`);
    dataItem.inEdit = undefined;
    if (dataItem.HoursOfOpID === undefined)
      dataItem.HoursOfOpID = 0;
    dataItem.HoursOfOpID = this.updateHOOItems(this.state.HoursOfOperation,dataItem).HoursOfOpID;  // Update Grid Items
    this.setState({ HoursOfOperation: this.state.HoursOfOperation.slice() });
    this.updateHOOItems(this.state.HoursOfOperation,dataItem);
    let strtTime = new Date(dataItem.StartTime); // This is required when there has been NO change to the StartTime
    let offset = strtTime.getTimezoneOffset(); // UTC Offset
    let saveItem = JSON.parse(JSON.stringify(dataItem)); // Copy Object
    console.log(`starttime: ${dataItem.StartTime}, offset: ${offset}, HoursOfOpID: ${dataItem.HoursOfOpID}`);
    if (offset !== 0) {
      try {
        let strt = new Date(strtTime.toISOString()); // Convert to UTC and back to Date Object
        console.log(`strt: ${strt}`);
        saveItem.StartTime = new Date(strt.setTime(strt.getTime() + -offset * 60000));
      }
      catch (Ex) {
        console.log("saveHOO - StartTime: " + dataItem.StartTime + " Msg: " + Ex);
      }
      try {
        let endTime = new Date(dataItem.EndTime);
        console.log(`endtime: ${dataItem.EndTime}, date: ${endTime}, offset: {offset}`);
        let end = new Date(endTime.toISOString()); // Convert to UTC and back to Date Object
        console.log(`end: ${end}`);
        saveItem.EndTime = new Date(end.setTime(end.getTime() + -offset * 60000));
      }
      catch (Ex) {
        console.log("saveHOO - EndTime: " + dataItem.EndTime + " Msg: " + Ex);
      }
    }
    console.log(`Update Hours - saveItem: ${JSON.stringify(saveItem)}`);
    CallOM("UpdateHoursOfOperation",this.CurrentStoreLocationID,saveItem.HoursOfOpID,13,saveItem);
    console.log("after dataItem: " + JSON.stringify(dataItem));
  }
  cancelHOO(dataItem) {
    if (dataItem.HoursOfOpID) {
      let originalItem = this.state.HoursOfOperation.find(p => p.HoursOfOpID === dataItem.HoursOfOpID)
      if (originalItem.inEdit) originalItem.inEdit = false;
      this.updateHOOItems(this.state.HoursOfOperation,originalItem);
    } else {
      this.updateHOOItems(this.state.HoursOfOperation,dataItem,!dataItem.HoursOfOpID); // remove false
    }
    this.setState({ HoursOfOperation: this.state.HoursOfOperation.slice() });
  }
  removeHOO(dataItem) {
    dataItem.inEdit = undefined;
    let key = dataItem.HoursOfOpID;
    console.log("remove: " + key);
    this.updateHOOItems(this.state.HoursOfOperation,dataItem,true);
    this.state.saveHoursOfOperations = this.state.HoursOfOperation;
    DeleteRowTry(544608,key); // Delete in the HoursOfOperation Table in OM
    this.setState({ HoursOfOperation: this.state.HoursOfOperation.slice() });
  }
  itemChangeHOO(event) {
    const value = event.value;
    const name = event.field;
    if (!name)
      return;
    const updatedData = this.state.HoursOfOperation.slice();
    const item = this.updateHOOItems(updatedData,event.dataItem);
    console.log("itemChange - name: " + name + " value: " + JSON.stringify(value));
    if (!value.ddID)
      item[name] = value;
    else
      item[name] = value.ddID;
    this.setState({ HoursOfOperation: updatedData });
  }
  updateHOOItems(data,item,remove) {  // data - is the entire data set (JSON), item - is the current line 
    let updated;
    //eslint-disable-next-line                   
    let index = data.findIndex(p => p === item || item.HoursOfOpID && p.HoursOfOpID === item.HoursOfOpID); // Note - for new line will find 0
    if (index >= 0) {
      updated = Object.assign({},item);
      data[index] = updated;
    }
    if (remove) {
      data = data.splice(index,1);
    }
    return data[index];
  }
  //------------------------- End Edit HoursOfOperation Grid ------------------------------------------------------------------   

  //------------------------- Edit Sublocation Grid ------------------------------------------------------------------
  SublocationCommandCell;
  enterInsertSublocation() {
    //console.log("Insert sublocation");
    const dataItem = { inEdit: true };
    const allRecords = this.state.Sublocations.slice();
    allRecords.unshift(dataItem); // Add to the beginning
    this.updateSublocations(allRecords,dataItem);
    this.setState({ Sublocations: allRecords });
    this.InsertRecordType = 8;
  }
  enterEditSublocation(dataItem) {
    this.updateSublocations(this.state.Sublocations,dataItem).inEdit = true;
    this.setState({ Sublocations: this.state.Sublocations.slice() });
    this.clearQRPopup();
  }
  saveSublocation(dataItem) {
    dataItem.inEdit = undefined;
    if (dataItem.SublocationID === undefined)
      dataItem.SublocationID = 0;
    console.log("Save");
    dataItem.SublocationID = this.updateSublocations(this.state.Sublocations,dataItem).SublocationID;
    this.setState({ Sublocations: this.state.Sublocations.slice() });
    dataItem["LocationID"] = this.CurrentStoreLocationID;
    //console.log("Update Row - dataitem: " + JSON.stringify(dataItem));
    //console.log(`Update Row - sublocationID: ${dataItem.SublocationID}, CurrentStoreLocationID: ${this.CurrentStoreLocationID}`);
    UpdateRow(SublocationsOID,0,this.state.Sublocations,dataItem,dataItem.SublocationID,"SublocationID");  // Save to OM
    this.InsertRecordType = 0;
    SessionInfo.SublocationUpdate = true;
  }
  cancelSublocation(dataItem) {
    if (dataItem.SublocationID) {
      let originalItem = this.state.Sublocations.find(p => p.SublocationID === dataItem.SublocationID)
      if (originalItem.inEdit) originalItem.inEdit = false;
      this.updateSublocations(this.state.Sublocations,originalItem);
    } else {
      this.updateSublocations(this.state.Sublocations,dataItem,!dataItem.SublocationID);
    }
    this.setState({ Sublocations: this.state.Sublocations.slice() });
    this.InsertRecordType = 0;
  }
  removeSublocation(dataItem) {
    dataItem.inEdit = undefined;
    let key = dataItem.SublocationID;
    this.updateSublocations(this.state.Sublocations,dataItem,true);
    this.saveSublocations = this.state.Sublocations;
    DeleteRowTry(SublocationsOID,key); // Delete in the Sublocations Table in OM 
    this.setState({ Sublocations: this.state.Sublocations.slice() });
  }
  itemChangeSublocation(event) {
    const value = event.value;
    const name = event.field;
    if (!name) {
      return;
    }
    const updatedData = this.state.Sublocations.slice();
    const item = this.updateSublocations(updatedData,event.dataItem);
    if (!value.ddID)
      item[name] = value;
    else
      item[name] = value.ddID;
    this.setState({ Sublocations: updatedData });
    console.log(`-----> updateSubLocations`);
    this.updateSublocationsFlag = true;
  }
  updateSublocations(data,item,remove) {  // data - is the entire data set (JSON), item - is the current line 
    let updated;
    //eslint-disable-next-line
    let index = data.findIndex(p => p === item || item.SublocationID && p.SublocationID === item.SublocationID);
    if (index >= 0) {
      updated = Object.assign({},item);
      data[index] = updated;
    }
    if (remove) {
      data = data.splice(index,1);
    }
    return data[index];
  }
  //-------------------------- Sublocations ---------------------------------------------------------------------------------- 
  getSublocations = async (search) => {
    if (!search)
      search = '';
    search = search + "*LocationID=" + this.state.StoreLocationID; // NOTE - Sublocations is based on LocationID - NOT StoreLocationID   
    //console.log(`search: ${search}`);
    let CD = await GetTableSearchRaw(SublocationsOID,search,"*EDIT");
    try {
      const Sublocations = CD.d.rows.map(dataItem => Object.assign({ selected: false },dataItem));
      //console.log(`Sublocations: ${JSON.stringify(Sublocations)}`);
      await this.setState({ Sublocations });
      this.saveSublocations = Sublocations;
    } catch (error) {
      displayError("getSublocations: " + error.message);
    }
  }
  getSublocationsForStoreLocation = async (storeLocationID) => {
    let CD = await CallOM("GetLocationIDForStore",0,storeLocationID,0);
    console.log(`locationid : ${JSON.stringify(CD.d)}`);
    let search = "*LocationID=" + CD.d; // NOTE - Sublocations is based on LocationID - NOT StoreLocationID   
    console.log(`search: ${search}`);
    CD = await GetTableSearchRaw(SublocationsOID,search,"*EDIT");
    try {
      const Sublocations = CD.d.rows.map(dataItem => Object.assign({ selected: false },dataItem));
      //console.log(`Sublocations: ${JSON.stringify(Sublocations)}`);
      await this.setState({ Sublocations });
      this.saveSublocations = Sublocations;
    } catch (error) {
      displayError("getSublocationsForStoreLocation: " + error.message);
    }
  }
  SublocationsSingleRowClick = async (event) => {
    const current = this.state.Sublocations.findIndex(dataItem => dataItem === event.dataItem);
    if (current >= 0) {
      try {
        this.state.Sublocations.forEach(item => item.selected = false);
        this.state.Sublocations[current].selected = true;
        let sublocationID = this.state.Sublocations[current].SublocationID;
        let CD = await GetTableRow(SublocationsOID,sublocationID);
        this.setState({ SublocationID: sublocationID });
        for (var prop in CD.d.row) {
          if (prop in this.state) {
            let obj = {};
            obj[prop] = CD.d.row[prop];
            if (prop.indexOf("Date") >= 0) {
              obj[prop] = new Date(CD.d.row[prop]); //new Date()
            }
            this.setState(obj);
          }
        }
        this.setItemStateChanged(false);
      }
      catch (error) {
        displayError("SublocationRowClick: " + error);
      }
    }
  }
  SublocationsPageChange = (event) => {
    console.log(`SL page change - skip: ${event.page.skip}, take: ${event.page.take}`);
    this.setState({ SublocationsSkip: event.page.skip,SublocationsTake: event.page.take });
  }
  SublocationRowClick = async (event) => {
    const current = this.state.Sublocations.findIndex(dataItem => dataItem === event.dataItem);
    if (current >= 0) {
      let sublocationID = this.state.Sublocations[current].SublocationID;
      console.log(`SublocationRowClick: ${sublocationID}`);
      let CD = await GetTableRow(SublocationsOID,sublocationID);
      let row = CD.d.row;
      this.setState({ SublocationID: sublocationID });
      // Retrieved Value Assignment for: Sublocations
      SublocationsO.forEach(elem => {
        if (elem.n in row) {
          //console.log(`Set ${elem.n}: ${row[elem.n]}`);
          let sobj = {};
          try {
            if (elem.t === 34 || elem.t === 35) { // Date, DateTime
              sobj[elem.n] = new Date(row[elem.n]); // For use with DateTime Control
              //sobj[elem.n] = row[elem.n];
              SV[elem.n] = new Date(row[elem.n]);
            } else if (elem.t === 21 || elem.t === 195 || elem.t === 201) { // String, FK-Translated, Enum
              sobj[elem.n] = row[elem.n];
              let sobjS = {};
              sobjS[elem.n + 'S'] = row[elem.n];
              //console.log(`Set String Field: ${elem.n + 'S'}, Value: ${ sobjS[elem.n + 'S']}`);
              this.setState(sobjS); // Set the String shadow var
              SV[elem.n] = row[elem.n];
            } else if (elem.t === 22) { // Bool
              let val = row[elem.n].toString().charAt();
              if (val === '1' || val === 't' || val === 'T') {
                sobj[elem.n] = true;
                COV[elem.n] = true;
              } else {
                sobj[elem.n] = false;
                COV[elem.n] = false;
              }
            } else if (elem.t === 28) { // Money
              sobj[elem.n] = row[elem.n];
              let sobjS = {};
              sobjS[elem.n + 'S'] = this.moneyFormat(row[elem.n]);
              //console.log(`Set Money Field: ${elem.n + 'S'}, Value: ${ sobjS[elem.n + 'S']}`);
              this.setState(sobjS); // Set the String shadow var
              SV[elem.n] = row[elem.n];
            } else if (elem.t === 41) { // Media - Image or Document
              sobj[elem.n] = row[elem.n];
              SV[elem.n] = row[elem.n];
              if (elem.n === 'ImageID')
                currentImageID = row[elem.n];
            } else if (elem.t === 15) { // Encoded
              //------ Assign Encoded Values ------
              let AList = [];
              let sobjV = {};
              sobjV[`${elem.n}Vals`] = AList; // Set the Vals Array
              this.setState(sobjV); // Set the Vals var
              sobj[elem.n] = row[elem.n];
              //obj[elem.n] = row[elem.n];
              SV[elem.n] = row[elem.n];
              console.log(`Encoded sobj ${JSON.stringify(sobj)}, t: ${elem.t}, val: ${SV[elem.n]}`);
            } else { // Default - Number
              sobj[elem.n] = row[elem.n];
              SV[elem.n] = row[elem.n];
            }
            //console.log(`sobj ${JSON.stringify(sobj)}, t: ${elem.t}, val: ${SV[elem.n]}`);
            if (this.state[elem.n] === undefined)
              console.log(`=== ${elem.n} is not defined in state ===`);
            this.setState(sobj);
            // Add item specific code here
            //if (elem.n === 'ImageID')
            //  ImageID = row[elem.n];
          }
          catch (error) {
            console.log(`Set SublocationsO Field: ${elem.n}, Error: ${error.message}`);
          }
        }
      });
      console.log(`subloctype: ${SV.SublocationTypeID}`);
      if (SV.SublocationTypeID == 1) { // TableType
        console.log(`Location: ${this.CurrentStoreLocationID}, Sublocation: ${sublocationID}, Name: ${SV.Sublocation}`)
        SessionInfo.CurrentTableID = SV.SublocationID;
        SessionInfo.CurrentLocationID = this.CurrentStoreLocationID;
        this.setState({ TableName: SV.Sublocation });
        this.setQRLocation();
      }
    }
  }
  //------------------------- End Edit Sublocations Grid ------------------------------------------------------------------
  //------------------------- PricingOverride  ------------------------------------------------------------------
  getPricingOverrides = async (search) => {
    if (!search)
      search = '';
    let CD = await GetTableSearchRaw(PricingOverridesOID,search,"*DISP");
    try {
      console.log(`Get Pricing Overrides - CD: ${JSON.stringify(CD)}`);
      const PricingOverrides = CD.d.rows.map(dataItem => Object.assign({ selected: false },dataItem));
      await this.setState({ PricingOverrides });
      this.state.savePricingOverrides = PricingOverrides;
      console.log(`PricingOverrides: ${JSON.stringify(PricingOverrides)}`);
    } catch (error) {
      displayError("getPricingOverride: " + error.message);
    }
    this.forceUpdate();
    console.log(`Pricing Overrides${JSON.stringify(this.state.PricingOverrides)}`);
  }
  PricingOverridesSingleRowClick = async (event) => {
    const current = this.state.StoreItems.findIndex(dataItem => dataItem === event.dataItem);
    if (current >= 0) {
      try {
        SessionInfo.SelectStoreLocationID = this.state.StoreLocationID;
        SessionInfo.StoreItemID = this.state.StoreItems[current].StoreItemID;
        let CD = await GetTableRow(PricingOverridesOID,SessionInfo.StoreItemID);
        this.setState({ StoreLocationItemID: SessionInfo.StoreItemID });
        for (var prop in CD.d.row) {
          if (prop in this.state) {
            let obj = {};
            obj[prop] = CD.d.row[prop];
            if (prop.indexOf("Date") >= 0) {
              obj[prop] = new Date(CD.d.row[prop]); //new Date()
            }
            this.setState(obj);
          }
        }
        this.setState({ itemStateHasChanged: false });
        this.setState({ selectedTopTab: 3 });
      }
      catch (error) {
        displayError("PricingOverrideRowClick: " + error);
      }
    }
  }
  PricingOverridesPageChange = (event) => {
    this.setState({ PricingOverridesSkip: event.page.skip,PricingOverridesTake: event.page.take });
  }
  //------------------------- Edit PricingOverride Grid ------------------------------------------------------------------
  PricingOverrideHeaderSelectionChange = (event) => {
    const checked = event.syntheticEvent.target.checked;
    this.state.PricingOverrides.forEach(item => item.selected = checked);
    this.forceUpdate();
  }
  PricingOverrideSelectionChange = (event) => {
    event.dataItem.selected = !event.dataItem.selected;
    this.forceUpdate();
  }
  PricingOverrideCommandCell;
  enterInsertPricingOverride() {
    const dataItem = { inEdit: true };
    console.log(`InsertPricingOverride`);
    console.log(`Pricing Overrides - CD: ${JSON.stringify(this.state.PricingOverrides)}`);
    const allRecords = this.state.PricingOverrides.slice();
    allRecords.unshift(dataItem); // Add to the beginning
    this.updatePricingOverride(allRecords,dataItem);
    this.setState({ PricingOverrides: allRecords });
  }
  enterEditPricingOverride(dataItem) {
    this.updatePricingOverride(this.state.PricingOverrides,dataItem).inEdit = true;
    this.setState({ PricingOverrides: this.state.PricingOverrides.slice() });
  }
  savePricingOverride(dataItem) {
    dataItem.inEdit = undefined;
    if (dataItem.PricingOverrideID === undefined)
      dataItem.PricingOverrideID = 0;
    //console.log("Save PricingOverride");
    dataItem.StoreLocationID = this.state.StoreLocationID;
    dataItem.PricingOverrideID = this.updatePricingOverride(this.state.PricingOverrides,dataItem).PricingOverrideID;  // Save to OM
    this.setState({ PricingOverrides: this.state.PricingOverrides.slice() });
    //console.log("Update Row - PricingOverrideID: " + dataItem.PricingOverrideID);
    UpdateRow(PricingOverridesOID,0,this.state.PricingOverrides,dataItem,dataItem.PricingOverrideID,"PricingOverrideID");
  }
  cancelPricingOverride(dataItem) {
    if (dataItem.PricingOverrideID) {
      let originalItem = this.state.PricingOverrides.find(p => p.PricingOverrideID === dataItem.PricingOverrideID)
      if (originalItem.inEdit) originalItem.inEdit = false;
      this.updatePricingOverride(this.state.PricingOverrides,originalItem);
    } else {
      this.updatePricingOverride(this.state.PricingOverrides,dataItem,!dataItem.PricingOverrideID);
    }
    this.setState({ PricingOverrides: this.state.PricingOverrides.slice() });
  }
  removePricingOverride(dataItem) {
    dataItem.inEdit = undefined;
    let key = dataItem.PricingOverrideID;
    this.updatePricingOverride(this.state.PricingOverrides,dataItem,true);
    this.updatePricingOverride(this.state.savePricingOverrides,dataItem,true);
    DeleteRow(PricingOverridesOID,key); // Delete in the PricingOverrides Table in OM 
    this.setState({ PricingOverrides: this.state.PricingOverrides.slice() });
  }
  itemChangePricingOverride(event) {
    const value = event.value;
    const name = event.field;
    if (!name) {
      return;
    }
    const updatedData = this.state.PricingOverrides.slice();
    const item = this.updatePricingOverride(updatedData,event.dataItem);
    if (!value.ddID)
      item[name] = value;
    else
      item[name] = value.ddID;
    this.setState({ PricingOverrides: updatedData });
  }
  updatePricingOverride(data,item,remove) {  // data - is the entire data set (JSON), item - is the current line 
    let updated;
    //eslint-disable-next-line
    let index = data.findIndex(p => p === item || item.PricingOverrideID && p.PricingOverrideID === item.PricingOverrideID);
    if (index >= 0) {
      updated = Object.assign({},item);
      data[index] = updated;
    }
    if (remove) {
      data = data.splice(index,1);
    }
    return data[index];
  }
  //------------------------- End Edit PricingOverrides Grid ------------------------------------------------------------------
  //--------------------- List Element Formats -----------------------------------------------
  OrderHeader = () => {
    return (
      <ListViewHeader style={{ color: 'rgb(160, 160, 160)',fontSize: 14 }} className='pl-4 pb-2 pt-2'>
        Orders {this.state.OrderState}
      </ListViewHeader>
    );
  }
  OrderFormat = props => {
    const minDate = new Date(2019,9,9);
    let item = props.dataItem;
    let imageVal = undefined;
    if (item["Image"]) {
      let value = item["Image"];
      //console.log("ImageCell value: " + value);
      const byteCharacters = window.atob(value);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      let image = new Blob([byteArray],{ type: 'image/jpeg' });
      //console.log("image: " + JSON.stringify(image));
      imageVal = URL.createObjectURL(image);
    }
    return (
      <div className="listRow" onClick={(e) => this.ViewDetail(item)}>
        <div className="listCol1">
          <span style={{ fontSize: 16,color: "black" }} >Order Type: </span>
          <span style={{ fontSize: 16,color: "black",fontWeight: "bold" }} >{item.CustomOrderType}</span><br />
          <span style={{ fontSize: 16,color: "black",fontWeight: "bold" }} >{item.CustomerID}</span><br />
          <span style={{ fontSize: 16,color: "black",fontWeight: "bold" }} >{item.PhoneNumber}</span><br />
        </div>
        <div className="listCol2">
          <span style={{ fontSize: 16,color: "black" }} >Total: </span>
          <span style={{ fontSize: 16,color: "black",fontWeight: "bold" }} >{this.moneyFormat(item.TotalPrice)}</span> <br />
          <span style={{ fontSize: 16,color: "black" }} >Items: </span>
          <span style={{ fontSize: 16,color: "black",fontWeight: "bold" }} >{item.ItemCount}</span>
        </div>
        <div className="listCol3">
          <span style={{ fontSize: 16,color: "black" }} >Ordered: </span>
          <span style={{ fontSize: 16,color: "black",fontWeight: "bold" }} >{!item.OrderCreationDate || Date.parse(item.OrderCreationDate) < minDate ? "" : this.dateTimeSFormat(item.OrderCreationDate)}</span>  <br />
          <span style={{ fontSize: 16,color: "black" }} >Requested: </span>
          <span style={{ fontSize: 16,color: "black",fontWeight: "bold" }} >{!item.OrderCreationDate || Date.parse(item.RequestDateTime) < minDate ? "ASAP" : this.dateTimeSFormat(item.RequestDateTime)}</span> <br />
          <span style={{ fontSize: 16,color: "black" }} >Scheduled: </span>
          <span style={{ fontSize: 16,color: "black",fontWeight: "bold" }} >{!item.ScheduledPrepDateTime || Date.parse(item.ScheduledPrepDateTime) < minDate ? "" : this.dateTimeSFormat(item.ScheduledPrepDateTime)}</span>
        </div>
        <div className="listCol4">
          <img src={require("./images/delete.svg")} alt="" id="GridRefresh" title="Accept the current entry" className="functionImage" onClick={(e) => this.ClickPending(item)} /><br /> {/*onClick={this.ClickPending(item)} */}
          <Button imageUrl={require("./images/delete.svg")} onClick={(e) => this.ClickPending(item)}>View Details</Button> {/* */}
        </div>
      </div>
    );
  }
  OrderFooter = () => {
    return (
      <ListViewFooter className='listFooter'>
        <div className='footerLeft'>
          <span>SubTotal </span>
          <span>{this.moneyFormat(this.state.SubTotalPrice)}</span>
          <br />
          <span style={{ left: '180px',}}>Tax </span>
          <span>{this.moneyFormat(this.state.TotalTaxAmount)}</span>
        </div>
        <div className='footerRight'>
          <span>TOTAL </span>
          <span>{this.moneyFormat(this.state.TotalPrice)}</span>
          <br />
          <Button id="checkoutButton" icon="restore" style={{ cursor: 'pointer',fontSize: 'large',color: 'black',fontWeight: 'bold',borderRadius: '10px',left: '80px',}} onClick={this.onCheckout} >Checkout</Button>
        </div>
      </ListViewFooter>
    );
  }
  //---------------------------------------------------------------
  OrderItemHeader = () => {
    return (
      <ListViewHeader style={{ color: 'black',fontSize: 18 }} className='pl-4 pb-2 pt-2'>
        Order Details for: {this.state.CustomerName}
      </ListViewHeader>
    );
  }
  OrderItemFormat = props => {
    let item = props.dataItem;
    let imageVal = undefined;
    let optionsList = "<span style='color:#ff1a75'> OPTIONS </span><br/>";
    console.log("optionList value: " + optionsList);
    if (item["Image"]) {
      let value = item["Image"];
      //console.log("ImageCell value: " + value);
      const byteCharacters = window.atob(value);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      let image = new Blob([byteArray],{ type: 'image/jpeg' });
      //console.log("image: " + JSON.stringify(image));
      imageVal = URL.createObjectURL(image);
    }
    return (
      <div className="listRow">
        <div className="topItems">
          <div className="listCol1">
            <span style={{ fontSize: 16,color: "black" }} >Item: </span>
            <span style={{ fontSize: 16,color: "black",fontWeight: "bold" }} >{item.StoreItemID}</span><br />
            <span style={{ fontSize: 16,color: "black" }} >{item.ItemDescription}</span>
          </div>
          <div className="listCol2">
            <span style={{ fontSize: 16,color: "black" }} >Price: </span>
            <span style={{ fontSize: 16,color: "black",fontWeight: "bold" }} >{this.moneyFormat(item.Price)}</span> <br />
            <span style={{ fontSize: 16,color: "black" }} >Options: </span>
            <span style={{ fontSize: 16,color: "black",fontWeight: "bold" }} >{this.moneyFormat(item.OptionsPrice)}</span>
          </div>
          {/*<div className="listCol4">
            <Avatar shape='rounded' type='image' style={{ height: '50px', width: '50px', }}>
            <img src={imageVal} style={{ height: '50px', width: '50px', }} />
          </Avatar> 
          </div>  */}
          <div className="listCol3">
            <img src={require("./images/delete.svg")} alt="" id="GridRefresh" title="Delete the current entry" className="functionImage" onClick={(e) => this.removeSI(item)} /> {/* */}
            {/*<img src={require("./images/delete.svg")} alt="" id="GridRefresh" title="Delete the current entry" className="functionImage" onClick={(e) => window.confirm(`Delete ${item.ItemName.trimRight()}?`) && this.onDeleteItem(item)} />  */}
          </div>
        </div>
        {this.hasOptions === true ?
          <div className="bottomItems">
            <div id="itemOptions" dangerouslySetInnerHTML={{ __html: optionsList }} ref={(div) => { this.anchor = div; }}></div>
          </div> : null}
      </div>
    );
  }
  OrderItemFooter = () => {
    return (
      <ListViewFooter className='listFooter'>
        <div className='footerLeft'>
          <span>SubTotal </span>
          <span>{this.moneyFormat(this.state.SubTotalPrice)}</span>
          <br />
          <span style={{ left: '180px',}}>Tax </span>
          <span>{this.moneyFormat(this.state.TotalTaxAmount)}</span>
        </div>
        <div className='footerRight'>
          <span>TOTAL </span>
          <span>{this.moneyFormat(this.state.TotalPrice)}</span>
          <br />
        </div>
      </ListViewFooter>
    );
  }
  //------------------- ListView Events ----------------------------------------------------------
  ClickPending = (item) => {
    console.log("ClickPending: " + JSON.stringify(item));
    var CustomerID = item.CustomerID;
    //this.setState({ CustomerName: item.CustomOrderID }); 
    var CustomerName = item.CustomerID;
    console.log("CustomerName: " + CustomerName);
    this.setState({ CustomerName: item.CustomerID });
    const curTime = new Date();
    this.setState({ ScheduledPrepDateTime: curTime.setMinutes(curTime.getMinutes() + 30) });
    this.setState({ showPopup: !this.state.showPopup });
    this.forceUpdate();
  }
  ClickMinus = () => {
    console.log("ScheduledPrepDateTime: " + this.state.ScheduledPrepDateTime);
    //const curTime = Date.parse(this.state.ScheduledPrepDateTime);
    const curTime = new Date(this.state.ScheduledPrepDateTime);
    this.setState({
      ScheduledPrepDateTime: curTime.setMinutes(curTime.getMinutes() - 1)
    });
  }
  ClickPlus = () => {
    console.log("ScheduledPrepDateTime: " + this.state.ScheduledPrepDateTime);
    //const curTime = Date.parse(this.state.ScheduledPrepDateTime);
    const curTime = new Date(this.state.ScheduledPrepDateTime);
    this.setState({
      ScheduledPrepDateTime: curTime.setMinutes(curTime.getMinutes() + 1)
    });
  }
  ViewDetail = (item) => {
    this.setState({ CustomOrderID: item.CustomOrderID });
    this.getCustomOrderItemsStatus(2);
    this.setState({ CustomerName: item.CustomerID });
    var TaxAmt = item.TotalTaxAmount + item.TotalTax2Amount + item.TotalTax3Amount;
    this.setState({ TotalTaxAmount: TaxAmt });
    this.setState({ TotalPrice: item.TotalPrice });
    this.setState({ SubTotalPrice: item.TotalPrice - TaxAmt });
    this.forceUpdate();
  }
  //---------------------
  /**
 * Adds time to a date. Modelled after MySQL DATE_ADD function.
 * Example: dateAdd(new Date(), 'minute', 30)  //returns 30 minutes from now.
 * https://stackoverflow.com/a/1214753/18511
 * 
 * @param date  Date to start with
 * @param interval  One of: year, quarter, month, week, day, hour, minute, second
 * @param units  Number of units of the given interval to add.
 */
  dateAdd(date,interval,units) {
    if (!(date instanceof Date))
      return undefined;
    var ret = new Date(date); //don't change original date
    var checkRollover = function () { if (ret.getDate() !== date.getDate()) ret.setDate(0); };
    switch (String(interval).toLowerCase()) {
      case 'year': ret.setFullYear(ret.getFullYear() + units); checkRollover(); break;
      case 'quarter': ret.setMonth(ret.getMonth() + 3 * units); checkRollover(); break;
      case 'month': ret.setMonth(ret.getMonth() + units); checkRollover(); break;
      case 'week': ret.setDate(ret.getDate() + 7 * units); break;
      case 'day': ret.setDate(ret.getDate() + units); break;
      case 'hour': ret.setTime(ret.getTime() + units * 3600000); break;
      case 'minute': ret.setTime(ret.getTime() + units * 60000); break;
      case 'second': ret.setTime(ret.getTime() + units * 1000); break;
      default: ret = undefined; break;
    }
    return ret;
  }
  handleChange = (event) => {
    this.setState({
      value: event.target.value
    });
  }
  setItemStateChanged = (changed) => {
    this.setState({ itemStateHasChanged: changed });
    if (changed === true) {
      SessionInfo.setShowSave = 1; // Show Save in Header  
      if (this.clearScreen === true) {
        this.clearScreen = false;
        SessionInfo.setShowNew = 1;
      }
    } else
      SessionInfo.setShowSave = 2; // Turn Off
  }
  blurMaskFldVal(event,mask) {
    //console.log(`blur Mask: ${this.state[event.target.name]}`);
    this.setState({ [event.target.name]: UnmaskString(this.state[event.target.name],mask) });
    //console.log(`money target: ${this.state[event.target.name + 'S']}`);
  }
  chgFldValMask(event,mask) {
    //console.log(`chg masked value: ${event.target.value}, mask: ${mask}`);
    this.setState({ [event.target.name]: UnmaskString(event.target.value,mask) });
    //console.log(`chg masked - name: ${event.target.name}:${event.target.value}`);
    this.setItemStateChanged(true);
  }
  chgFldVal(event) {
    this.setState({ [event.target.name]: event.target.value });
    //console.log(`${event.target.name}:${event.target.value}`);
    this.setItemStateChanged(true);
  }
  setQuantity(event) {
    this.setState({ LockedQuantity: 0 });
    this.setState({ AvailableQuantity: event.target.value });
  }
  async chgMoneyFldVal(event) {
    let newVal = event.target.value.replace(/[^\d.-]/g,''); // Matches any single single character not in the bracket [] and replaces with ''  - https://www.infobyip.com/regularexpressioncalculator.php
    let tstVal = +newVal;
    if (Number.isNaN(tstVal))
      newVal = event.target.value.replace(/-/g,''); // Remove the - sign
    this.setState({ [event.target.name + 'S']: newVal });
    await this.setState({ [event.target.name]: newVal / 1.0 });
    this.setItemStateChanged(true);
  }
  chgFldValMon(event) {
    this.setState({ [event.target.name]: event.target.value });
    this.setItemStateChanged(true);
    this.monitorCounter = 11;
  }
  chgDDFldValMon = (event) => {
    this.setState({ [event.target.name]: event.target.value.ddID });
    this.setItemStateChanged(true);
    this.monitorCounter = 11;
  }
  //chgMoneyFldVal(event) {
  //  //var stateCopy = Object.assign({}, this.state[event.target.name]);
  //  let newVal = event.target.value.replace(/[^\d.-]/g, '');
  //  this.setState({ [event.target.name]: newVal });
  //}
  async focusMoneyFldVal(event) {
    //console.log(`money focus: ${this.state[event.target.name]} name: ${event.target.name}`);
    this.setState({ [event.target.name + 'S']: this.state[event.target.name] });
    //console.log(`money target: ${this.state[event.target.name + 'S']}`);
  }
  async blurMoneyFldVal(event) {
    //console.log(`money blur: ${this.state[event.target.name]} name: ${event.target.name}`);
    //console.log(`     blur money format: ${this.moneyFormat(this.state[event.target.name])} `);
    this.setState({ [event.target.name + 'S']: this.moneyFormat(this.state[event.target.name]) });
    //console.log(`money target: ${this.state[event.target.name + 'S']}`);
  }
  chgPercentFldVal(event) {
    //var stateCopy = Object.assign({}, this.state[event.target.name]);
    let newVal = event.target.value.replace(/[^\d.-]/g,'');
    //console.log("percent newval: " + newVal);
    let perVal = newVal / 100.0;
    if (Number.isNaN(perVal)) {
      newVal = event.target.value.replace(/[^\d.]/g,'');
      perVal = newVal / 100.0;
    }
    //console.log(`percent newval: ${perVal}`);
    this.setState({ [event.target.name + 'S']: newVal });
    this.setState({ [event.target.name]: perVal });
    this.setState({ itemStateHasChanged: true });
  }
  async focusPercentFldVal(event) {
    //console.log(`percent focus: ${this.state[event.target.name]} name: ${event.target.name}`);
    this.setState({ [event.target.name + 'S']: this.state[event.target.name] * 100.0 });
    //console.log(`money target: ${this.state[event.target.name + 'S']}`);
  }
  async blurPercentFldVal(event) {
    //console.log(`percent blur: ${this.state[event.target.name]} name: ${event.target.name}`);
    this.setState({ [event.target.name + 'S']: this.percentFormat(this.state[event.target.name]) });
    //console.log(`money target: ${this.state[event.target.name + 'S']}`);
  }
  chgPercentStart(event) {
    this.frmt = false;
    let newVal = event.target.value.replace(/[^\d.-]/g,'');
    console.log("start newval: " + newVal);
    this.setState({ [event.target.name]: newVal });
    this.setItemStateChanged(true);
  }
  chgPercFldVal(event) {
    let newVal = event.target.value.replace(/[^\d.-]/g,'');
    console.log("perc newval: " + newVal);
    this.setState({ [event.target.name]: newVal });
    this.setItemStateChanged(true);
  }
  chgPercentFldVal(event) {
    //var stateCopy = Object.assign({}, this.state[event.target.name]);
    let newVal = event.target.value.replace(/[^\d.-]/g,'');
    console.log("percent blur newval: " + newVal);
    newVal /= 100.0;
    this.setState({ [event.target.name]: newVal });
    this.setItemStateChanged(true);
    this.frmt = true;
  }
  chgDDFldVal = (event) => {
    this.setState({ [event.target.props.name]: event.target.value.ddID });
    this.setItemStateChanged(true);
  }
  onChangeStoreLocationControl = (event) => {
    this.setState({ StoreLocationControlVals: [...event.target.value] });
    this.setItemStateChanged(true);
  }
  onChangeStoreAmenities = (event) => {
    this.setState({ StoreAmenitiesVals: [...event.target.value] });
    this.setItemStateChanged(true);
  }
  onChangeDayPeriods = (event) => {
    this.setState({ DayPeriodsVals: [...event.target.value] });
    this.setItemStateChanged(true);
  }
  blurDaysForDiscount = (event) => {
    let days = event.target.value;
    console.log(`days=${days}`);
    let disDate = this.state.StartDateTime.valueOf();
    console.log(`disDate=${disDate}`);
    //let newDate = disDate.addDays(days);
    let newDate = new Date(disDate + 864E5 * days);
    console.log(`newDate=${newDate}`);
    this.setState({ EndDateTime: newDate });
  }
  blurEndDate = (event) => {
  }
  chgCheckboxVal(event) {
    //console.log("stateCopy-" + event.target.name + " : " + JSON.stringify(this.state[event.target.name]));
    let setVal = true;
    if (this.state[event.target.name] === true)
      setVal = false;
    this.setState({ [event.target.name]: setVal });
    this.setItemStateChanged(true);
  }
  //-------------------------------

  customOrderItemsPageChange = (event) => {
    this.setState({ customOrderItemsSkip: event.page.skip,customOrderItemsTake: event.page.take });
  }
  selectionCustomOrderItemsChange = (event) => {
    event.dataItem.selected = !event.dataItem.selected;
    this.forceUpdate();
  }
  getCustomOrderItemsSort = (sort) => {
    return orderBy(this.state.CustomOrderItems,sort);
  }
  //------------------------- Select StoreItem from Main Database -------------------------------------------------------------------------
  mainStoreItemsPageChange = (event) => {
    this.setState({ mainStoreItemsSkip: event.page.skip,mainStoreItemsTake: event.page.take });
  }
  selectionStoreItemChange = (event) => {
    event.dataItem.selected = !event.dataItem.selected;
    this.forceUpdate();
  }
  rowStoreItemClick = async (event) => { // Copy Main Catalog ProductItem to Location Store Items
    if (!this.state.MainStoreItems)
      await this.saveRecord();
    const current = this.state.MainStoreItems.findIndex(dataItem => dataItem === event.dataItem);
    this.state.MainStoreItems.forEach(item => item.selected = false); // Set selected to false for each item in the MainStore List
    this.state.MainStoreItems[current].selected = true;
    let StoreItemID = this.state.MainStoreItems[current].StoreItemID;
    console.log(`copy item: ${StoreItemID}, Location: ${this.state.StoreLocationID} - Main Item: ${JSON.stringify(this.state.MainStoreItems[current])}`);
    let CD = await CallOM("CheckStoreItemExists",this.state.StoreLocationID,StoreItemID,0);
    let RetVal = CD.d;
    if (RetVal === 1)
      displayWarning("Item already exists for this location");
    else {
      //const item = { StoreLocationID: this.state.StoreLocationID, StoreItemID: PKID };  CopyStoreItemToStore()
      let item = JSON.parse(JSON.stringify(this.state.MainStoreItems[current])); // Object Copy - Value copy
      console.log("copy item: " + JSON.stringify(item));
      item.StoreLocationID = this.state.StoreLocationID;
      item.ReferenceItemID = StoreItemID;
      item.StoreItemID = 0;
      let index = this.state.StoreItems.findIndex(p => p.StoreItemID === StoreItemID); // Look to see if already copied
      console.log("Select StoreItemID: " + StoreItemID + " index: " + index);
      if (index < 0) {
        console.log(`Save StoreItems - StoreLocationID: ${item.StoreLocationID}, StoreItemID: ${item.StoreItemID}, Ref: ${item.ReferenceItemID}`);
        let CD = await CallOM("CopyStoreItemToStore",this.state.StoreLocationID,StoreItemID,0);
        let RetVal = CD.d;
        if (RetVal === 1) {
          //await UpdateRow(544619, 0, this.state.StoreItems, item, 0, "StoreItemID"); Save Record - this does not work as did not load entire record
          await this.getStoreItems(); // Refresh StoreItems for this location
          // NOTE - need to delete copied row in MainStoreItems    
          //let data = this.state.MainStoreItems.splice(current, 1);
          let data = this.state.MainStoreItems.splice(current,1);
          this.setState({ MainStoreItems: this.state.MainStoreItems });
        }
        else
          displayWarning(`Item Copy for ${this.state.StoreItems[index].ItemName} Failed`);
      }
      else {
        displayWarning(this.state.StoreItems[index].ItemName + " - Item is already assigned to the Location");
      }
    }
  }
  onDisplayContract = async () => {
    window.open('https://rinkeby.etherscan.io/address/' + this.state.ProjectInstanceContractAddress,"_blank") //to open new page
  }
  onDisplayAccount = async () => {
    window.open('https://rinkeby.etherscan.io/address/' + this.state.MetaMaskAccount,"_blank") //to open new page
  }
  onDisplayTx = async () => {
    if (this.state.transactionHash === '')
      displayMessage("No Transaction Specified");
    else
      window.open('https://rinkeby.etherscan.io/tx/' + this.state.transactionHash,"_blank") //to open new page
  }
  sortChange = (event) => {
    this.setState({ products: this.GetProducts(event.sort),sort: event.sort });
  }
  getProductItemsSort = (sort) => {
    return orderBy(this.state.MainStoreItems,sort);
  }

  //------------------------- End Select StoreItem ----------------------------------------------------------------------
  //------------------------- Receipt Items ---------------------------------------------------------------
  rowCustomOrderItemClick = async (event) => { // Click on Receipt Item - see if there is additional information
    const current = this.state.CustomOrderItems.findIndex(dataItem => dataItem === event.dataItem);
    let CustomOrderItemID = this.state.CustomOrderItems[current].CustomOrderItemID;
    console.log(`OrderItemID: ${CustomOrderItemID} `);
    let CD = await CallOM("GetOrderItemOptionsList",0,CustomOrderItemID,0); // Or GetOrderItemOptionsList  
    //console.log("OrderItemOptions: " + JSON.stringify(CD.d));
    this.setState({ CustomOrderItemOptions: CD.d.rows });
    //console.log("OrderItemOptions: " + JSON.stringify(this.state.CustomOrderItemOptions));
    displayMessage(`${this.state.CustomOrderItemOptions.length} Options for OrderItemID: ${CustomOrderItemID}`);
  }
  //------------------------- Field Edit ------------------------------------------------------------------

  //------------ Upload Code ------------------------------
  // Updated 17Feb23
  addLocationImage = async (event) => { // Note - 28Feb23 - using this function bypasses the Progress Upload facility
    //console.log(`onAddLoc name: ${event.affectedFiles[0].name}, Ext: ${ event.affectedFiles[0].extension}, Lth: ${ event.affectedFiles[0].size}, Status: ${ event.affectedFiles[0].status}`);
    //console.log(fileObj);
    try {
      const fileObj = event.affectedFiles[0].getRawFile();
      let imageType = getDocumentType(event.affectedFiles[0].extension,"Person Photo");
      let reader = new FileReader();
      reader.readAsArrayBuffer(fileObj);
      reader.onloadend = async () => {
        let imageFile = await Buffer.from(reader.result);
        this.setState({ StoreLocationPhotoURL: URL.createObjectURL(fileObj) });
        console.log(`save Image Type: ${imageType} for storeLocation: ${this.state.StoreLocationID}, tableID: ${StoreLocationsOID}`);
        let iVal = await saveImageFileToServer(imageFile,imageType,StoreLocationsOID,this.state.StoreLocationID);
        this.setState({ ImageID: iVal });
      }
      event.target.value = null;
    }
    catch (error) {
      console.log(`addLocationImage Error: ${error}`);
    }
  }
  onRemove = (event) => {
    console.log('onRemove: ',event.affectedFiles);
    this.setState({ files: event.newState });
  }
  onProgress = (event) => {
    console.log('onProgress: ',event.affectedFiles,event.serverResponse);
    this.setState({ files: event.newState });
  }
  onStatusChange = (event) => {
    console.log('onStatusChange: ',event.affectedFiles,event.serverResponse);
    console.log(`onStatusChange newState: ${JSON.stringify(event.newState)}`);
    //console.log(event.newState);
    let cnt = event.newState.length;
    event.newState[cnt - 1].status = 1;
    //console.log(event.newState);
    this.setState({ files: event.newState });
  }
  //-----------------------------------------------------------

  onVerticalLayoutChange = (event) => {
    //console.log("vertical: " + JSON.stringify(event.newState));
    this.setState({ verticalPanes: event.newState });
  }
  onHorizontalLayoutChange = (event) => {
    //console.log("horizontal: " + JSON.stringify(updatedState));
    this.setState({ horizontalPanes: event.newState });
  }
  //onHorizontalSubLayoutChange = (event) => {
  //  //console.log("horizontal: " + JSON.stringify(updatedState));
  //  this.setState({ horizontalSubPanes: event.newState });
  //}
  //-----------------------------------------------------------
  // Designer
  //-----------------------------------------------------------
  handlePress = (_) => {
    this.setState({
      pressed: true,
    });
  };
  handleDragStart = ({ event }) => {
    console.log("Start");
    console.log(event);
    this.setState({
      pressed: true,
      initial: {
        x: event.clientX - this.state.position.x,
        y: event.clientY - this.state.position.y,
      },
    });
  };
  handleDrag = ({ event }) => {
    if (!this.state.initial) {
      return;
    }
    console.log("Drag");
    console.log(event);
    this.setState({
      dragged: true,
      position: {
        x: event.clientX - this.state.initial.x,
        y: event.clientY - this.state.initial.y,
      },
    });
  };
  handleDragEnd = (_) => {
    this.setState({ dragged: false,initial: null,});
  };
  handleRelease = (_) => {
    this.setState({ pressed: false,});
  };
  // Excel Export Support - copy just before render
  _export;
  exportExcel = () => {
    this._export.save();
  }
  _exportHours;
  exportHoursExcel = () => {
    this._exportHours.save();
  }
  _exportStoreItems;
  exportStoreItemsExcel = () => {
    this._exportStoreItems.save();
  }
  _exportCustomOrders;
  exportCustomOrdersExcel = () => {
    this._exportCustomOrders.save();
  }
  _exportPromotions;
  exportPromotionsExcel = () => {
    this._exportPromotions.save();
  }
  _exportSublocations;
  exportSublocationsExcel = () => {
    this._exportSublocations.save();
  }
  //-----------------------------------------------------------

  render() {
    if (!SessionInfo.session)
      return;
    //console.log("PageSide: " + JSON.stringify(SessionInfo.PageSide));
    return (
      <div id="StoreLocationsDiv" className="pageMain">
        <PageSide />
        <PageHeader L1='Home' Select='2' PageTitle='Store Location Details' NoDelete='true' IsApp='y' ItemName='Location' />
        <div id="mainCntr">
          <Splitter style={{ height: '100%',border: 'none' }} panes={this.state.horizontalPanes} orientation={'horizontal'} onChange={this.onHorizontalLayoutChange}>
            <div id="splitterLeft" className="splitPaneContent">
              <TGrid style={{ position: 'absolute',top: 0,height: '100%' }} data={this.state.StoreLocationsData} selectedField="selected"
                onRowClick={this.storeLocationRowClick} resizable={true} sortable={true} pageable={true} pageSize={20}>
                <Column field="StoreName" title="Store Name" width="140px" />
                <Column field="CityName" title="City" width="120px" />
                <Column field="Address" title="Address" width="150px" />
              </TGrid>
            </div>
            <Splitter panes={this.state.verticalPanes} orientation={'vertical'} onChange={this.onVerticalLayoutChange}>
              <div id="splitterMiddle" className="splitPaneContent">
                <TabStrip selected={this.state.selectedTopTab} onSelect={this.handleTopTabSelect} className="tabStrip">
                  <TabStripTab title="Store Information">
                    <div className="editTab">
                      <div className="editTabTop">
                        <br />
                        <div className="editTabLeftNarrow">
                          <br />
                          <div id="div1" className="editInside">
                            <div className="editField">
                              <span className="editFieldLabel">Store Name</span>
                              <input value={this.state.StoreName} name="StoreName" onChange={evt => this.chgFldVal(evt)} className="editInput" />
                            </div>
                            <div className="editField">
                              <span className="editFieldLabel">Description</span>
                              <input value={this.state.Description} name="Description" onChange={evt => this.chgFldVal(evt)} className="editInput" />
                            </div>
                            <div className="editField">
                              <span className="editFieldLabel">Location Name</span>
                              <input value={this.state.LocationName} name="LocationName" onChange={evt => this.chgFldVal(evt)} className="editInput" />
                            </div>
                            <div className="editField">
                              <span className="editFieldLabel">Store Manager</span>
                              <div className="editDDPos">
                                <DropDownList data={this.state.Contacts} textField="ddName" dataItemKey="ddID" value={this.state.Contacts.find(c => c.ddID === this.state.ContactID)} name="ContactID" onChange={evt => this.chgDDFldVal(evt)} className="editInputDD" />  {/* defaultItem={this.contactName} */}
                              </div>
                            </div>
                            <div className="editField">
                              <span className="editFieldLabel">Phone Number</span>
                              <MaskedTextBox value={MaskString(this.state.PhoneNumber,"(###) ###-####")} name='PhoneNumber' onChange={evt => this.chgFldValMask(evt,"(###) ###-####")} mask="(999) 000-0000" className='editPhoneInput' />
                            </div>
                            <div className="editField">
                              <span className="editFieldLabel">Email Address</span>
                              <input value={this.state.EmailAddress} name="EmailAddress" onChange={evt => this.chgFldVal(evt)} className="editInput" />
                            </div>
                            <br />
                            <h4>Address</h4>
                            <div className="editField">
                              <span className="editFieldLabel">Address</span>
                              <input value={this.state.Address} name="Address" onChange={evt => this.chgFldVal(evt)} className="editInput" />
                            </div>
                            <div className="editField">
                              <span className="editFieldLabel">UnitNumber</span>
                              <input value={this.state.UnitNumber} name="UnitNumber" onChange={evt => this.chgFldVal(evt)} className="editInputShort" />
                            </div>
                            <div className="editField">
                              <span className="editFieldLabel">City</span>
                              <input value={this.state.CityName} name="CityName" onChange={evt => this.chgFldVal(evt)} className="editInput" />
                            </div>
                            <div className="editField">
                              <span className="editFieldLabel">Province/State</span>
                              <div className="editDDPos">
                                <DropDownList data={this.state.ProvinceStatesDict} textField="ddName" dataItemKey="ddID" value={this.state.ProvinceStatesDict.find(c => c.ddID === this.state.ProvinceStateID)} name="ProvinceStateID" onChange={evt => this.chgDDFldVal(evt)} className="editInputDD" />
                              </div>
                            </div>
                            <div className="editField">
                              <span className="editFieldLabel">Postal Code</span>
                              <input value={this.state.PostalCode} name="PostalCode" readOnly={false} onChange={evt => this.chgFldVal(evt)} className="editInputShort" />
                            </div>
                            <div className="editField">
                              <span className="editFieldLabel">Geo Location</span>
                              <input value={this.state.GeoLocation} name="GeoLocation" onChange={evt => this.chgFldVal(evt)} className="editInput" />
                            </div>
                            {/*<h4>Store Description</h4>*/}
                            <br />
                            <div className="editTAHField">
                              <span className="editFieldLabel">Store Description</span>
                              <textarea value={this.state.Description} name="Description" onChange={evt => this.chgFldVal(evt)} className="editTAInputHigh" />
                            </div>
                            {(SessionInfo.PermLevel >= 499) ?
                              <>
                                <br />
                                <div id='div2' className='editField'>
                                  <span className='editFieldLabel'>Store Location ID</span>
                                  <input value={this.state.StoreLocationID} name='StoreLocationID' onChange={evt => this.chgFldVal(evt)} readOnly={true} className='editNumInputRO' />
                                </div>
                              </> : null}
                          </div>
                        </div>
                        <div className="editTabMiddle">
                          <div id="div1" className="editInside">
                            <h4>Control Values</h4>
                            <div className="editField">
                              <HelpAbout fill={SessionInfo.activeColor} alt="" id="ShowHelp" title="Show Help for Store Group" className="showFieldHelp" onClick={evt => this.ShowFieldHelp(544707)} />
                              <span className="editFieldLabel">Store Group</span>
                              <div className="editDDPos">
                                <DropDownList data={this.state.StoreGroupsDict} textField="ddName" dataItemKey="ddID" value={this.state.StoreGroupsDict.find(c => c.ddID === this.state.StoreGroupID)} name="StoreGroupID" onChange={evt => this.chgDDFldVal(evt)} className="editInputDD" />
                              </div>
                            </div>
                            <div className="editField">
                              <span className="editFieldLabel">Max Purchase Amnt</span>
                              <input value={this.state.MaximumPurchaseAmountS} name="MaximumPurchaseAmount" onChange={evt => this.chgMoneyFldVal(evt)} onFocus={evt => this.focusMoneyFldVal(evt)} onBlur={evt => this.blurMoneyFldVal(evt)} className="editMoneyInput" />
                            </div>
                            <div className="editField">
                              <span className="editFieldLabel">Standard Markup %</span>
                              <NumericTextBox format="p1" value={this.state.StandardMarkupPercent} name="StandardMarkupPercent" min={0.0} max={0.3} step={0.01} onChange={evt => this.chgFldVal(evt)} width={100} className="NumTxtExtShortInput" />
                            </div>
                            <div className="editField">
                              <span className="editFieldLabel">Extended Markup %</span>
                              <NumericTextBox format="p1" value={this.state.ExtendedMarkupPercent} name="ExtendedMarkupPercent" min={0.0} max={0.3} step={0.01} onChange={evt => this.chgFldVal(evt)} width={100} className="NumTxtExtShortInput" />
                            </div>
                            <div className="editField">
                              <span className="editFieldLabel">Store Size</span>
                              <input value={this.state.AreaSize} name="AreaSize" onChange={evt => this.chgFldVal(evt)} className="editNumShortInput" />
                            </div>
                            <div className="editField">
                              <span className="editFieldLabel">Location Type</span>
                              <div className="editDDPos">
                                <DropDownList data={this.state.LocationTypesDict} textField="ddName" dataItemKey="ddID" value={this.state.LocationTypesDict.find(c => c.ddID === this.state.LocationTypeID)} name="LocationTypeID" onChange={evt => this.chgDDFldVal(evt)} className="editInputDD" />
                              </div>
                            </div>
                            <h4>Order Times (Minutes)</h4>
                            <div className="editField">
                              <span className="editFieldLabel">In Store Wait</span>
                              <NumericTextBox name="CurrentInStoreWaitTime" value={this.state.CurrentInStoreWaitTime} min={1} max={180} step={1} onChange={evt => this.chgFldVal(evt)} width={100} className="NumTxtExtShortInput" />
                            </div>
                            <div className="editField">
                              <span className="editFieldLabel">Pickup Wait</span>
                              <NumericTextBox name="CurrentPickupWaitTime" value={this.state.CurrentPickupWaitTime} min={1} max={180} step={1} onChange={evt => this.chgFldVal(evt)} width={100} className="NumTxtExtShortInput" />
                            </div>
                            <div className="editField">
                              <span className="editFieldLabel">Delivery Wait</span>
                              <NumericTextBox name="CurrentDeliveryWaitTime" value={this.state.CurrentDeliveryWaitTime} min={1} max={180} step={1} onChange={evt => this.chgFldVal(evt)} width={100} className="editNumShortInput" />
                            </div>
                            <div className="editField">
                              <span className="editFieldLabel">Orders In Queue</span>
                              <input name="CurrentOrdersInQueue" value={this.state.CurrentOrdersInQueue} readOnly={true} className="editNumShortInput" />
                            </div>
                            <div className="editTAHField">
                              <span className="editFieldLabel">Marketing Message</span>
                              <textarea value={this.state.MarketingMessage} name="MarketingMessage" onChange={evt => this.chgFldVal(evt)} className="editTAInputHigh" />
                            </div>
                            <br />
                            <h4>Active Day Periods</h4>
                            <br />
                            <div id='div2' className='editTAField'>
                              <span className='editFieldLabel'>Active Periods</span>
                              <div className='editDDPos'>
                                <MultiSelect data={DayPeriodsList} value={this.state.DayPeriodsVals} name='DayPeriods' onChange={this.onChangeDayPeriods} className='editInputDD' />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="editTabRightNarrow">
                          <div id="div1" className="editInside">
                            <h4>Taxes</h4>
                            <div id="div2" className="editField">
                              <span className="editFieldLabel">Tax Rate 1</span>
                              <NumericTextBox format="p1" name="TaxRate" value={this.state.TaxRate} min={0.0} max={0.3} step={0.01} onChange={evt => this.chgFldVal(evt)} width={100} className="NumTxtExtShortInput" />
                            </div>
                            <div id="div2" className="editField">
                              <span className="editFieldLabel">Tax Rate 2</span>
                              <NumericTextBox format="p1" name="TaxRate2" value={this.state.TaxRate2} min={0.0} max={0.3} step={0.01} onChange={evt => this.chgFldVal(evt)} width={100} className="NumTxtExtShortInput" />
                            </div>
                            <div id="div2" className="editField">
                              <span className="editFieldLabel">Tax Rate 3</span>
                              <NumericTextBox format="p1" name="TaxRate3" value={this.state.TaxRate3} min={0.0} max={0.3} step={0.01} onChange={evt => this.chgFldVal(evt)} width={100} className="NumTxtExtShortInput" />
                            </div>
                            <div className="editField">
                              <span className="editFieldLabel">Tax Rule</span>
                              <div className="editDDPos">
                                <DropDownList data={this.state.TaxRulesDict} textField="ddName" dataItemKey="ddID" value={this.state.TaxRulesDict.find(c => c.ddID === this.state.TaxRuleID)} name="TaxRuleID" onChange={evt => this.chgDDFldVal(evt)} className="editInputDD" />
                              </div>
                            </div>
                            <h4>Tips</h4>
                            <div id="div2" className="editField">
                              <span className="editFieldLabel">Tip Rate 1</span>
                              <NumericTextBox format="p1" name="TipRate1" value={this.state.TipRate1} min={0.0} max={0.3} step={0.01} onChange={evt => this.chgFldVal(evt)} width={100} className="NumTxtExtShortInput" />
                            </div>
                            <div id="div2" className="editField">
                              <span className="editFieldLabel">Tip Rate 2</span>
                              <NumericTextBox format="p1" name="TipRate2" value={this.state.TipRate2} min={0.0} max={0.3} step={0.01} onChange={evt => this.chgFldVal(evt)} width={100} className="NumTxtExtShortInput" />
                            </div>
                            <div id="div2" className="editField">
                              <span className="editFieldLabel">Tip Rate 3</span>
                              <NumericTextBox format="p1" name="TipRate3" value={this.state.TipRate3} min={0.0} max={0.3} step={0.01} onChange={evt => this.chgFldVal(evt)} width={100} className="NumTxtExtShortInput" />
                            </div>
                            <br />
                            <div className="editTAHField">
                              <span className="editFieldLabel">Promo Message</span>
                              <textarea value={this.state.PromotionalMessage} name="PromotionalMessage" onChange={evt => this.chgFldVal(evt)} className="editTAInputHigh" />
                            </div>
                            <br />
                            <div className="editTAField">
                              <span className="editFieldLabel">Specialty List</span>
                              <textarea value={this.state.SpecialtyList} name="SpecialtyList<" onChange={evt => this.chgFldVal(evt)} className="editTAInput" />
                            </div>
                            <br />
                            <div className="editTAHField">
                              <span className="editFieldLabel">Store Amenities</span>
                              <div className="editDDPos">
                                <MultiSelect data={StoreAmenitiesList} value={this.state.StoreAmenitiesVals} name="StoreAmenities" onChange={this.onChangeAmenities} className="editInputDD" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/*  <div className="editTabBottom">*/}
                      {/*    <div id="div1" className="editInside">*/}
                      {/*      <h4>Store Description</h4>*/}
                      {/*      <div className="editTAHField">*/}
                      {/*        <span className="editFieldLabel">Store Description</span>*/}
                      {/*        <textarea value={this.state.Description} name="Description" onChange={evt => this.chgFldVal(evt)} className="editTAHInputWide" />*/}
                      {/*      </div>*/}
                      {/*      <div className="editTAHField">*/}
                      {/*        <span className="editFieldLabel">Marketing Message</span>*/}
                      {/*        <textarea value={this.state.MarketingMessage} name="MarketingMessage" onChange={evt => this.chgFldVal(evt)} className="editTAHInputWide" />*/}
                      {/*      </div>*/}
                      {/*      <div className="editTAHField">*/}
                      {/*        <span className="editFieldLabel">Promotional Message</span>*/}
                      {/*        <textarea value={this.state.PromotionalMessage} name="PromotionalMessage" onChange={evt => this.chgFldVal(evt)} className="editTAHInputWide" />*/}
                      {/*      </div>*/}
                      {/*      <div className="editTAField">*/}
                      {/*        <span className="editFieldLabel">Specialty List</span>*/}
                      {/*        <textarea value={this.state.SpecialtyList} name="SpecialtyList<" onChange={evt => this.chgFldVal(evt)} className="editTAInputWide" />*/}
                      {/*      </div>*/}
                      {/*    </div>*/}
                      {/*  </div>*/}
                    </div>
                  </TabStripTab>
                  <TabStripTab title="Hours of Operation">
                    <div className="editTab">
                      <ExcelExport data={this.state.HoursOfOperation} ref={(exporter) => { this._exportHours = exporter; }}>
                        <TGrid style={{ position: 'absolute',top: 0,height: '100%' }} data={this.state.HoursOfOperation} onItemChange={this.itemChangeHOO} selectedField="selected" editField="inEdit"
                          onRowClick={this.rowHoursOfOpClick} resizable={true} pageSize={8}>
                          <Column field="selected" width='1px' headerSelectionValue={this.state.HoursOfOperation.findIndex(dataItem => dataItem.selected === false) === -1} />
                          <Column field="DayOfWeek" title="Day of Week" cell={DayOfWeekDropDownCell} width='120px' />
                          <Column field="StartTime" title="Start Time" cell={TimePickerCell} width='150px' />
                          <Column field="EndTime" title="End Time" cell={TimePickerCell} width='150px' />
                          <Column field="Hours" title="Hours" cell={HourMinCell} width='100px' />
                          <Column field="ServiceTypeID" title="Service Type" cell={ServiceTypeDropDownCell} width='160px' />
                          <Column field="TimeOverrideType" title="Override Type" cell={TimeOverrideDropDownCell} width='120px' />
                          <Column field="OverrideStart" title="Override Start" cell={TimePickerCell} width='140px' />
                          <Column field="OverrideEnd" title="Override End" cell={TimePickerCell} width='140px' />
                          <Column cell={this.HOOItemCommandCell} width="180px" />
                        </TGrid>
                      </ExcelExport>
                    </div>
                  </TabStripTab>

                  <TabStripTab title="Store Items">
                    <div className="editTab">
                      <ExcelExport data={this.state.StoreItems} ref={(exporter) => { this._exportStoreItems = exporter; }}>
                        <TGrid style={{ position: 'absolute',top: 0,height: '100%' }} data={orderBy(this.state.StoreItems,this.state.itemSort).slice(this.state.storeItemsSkip,this.state.storeItemsTake + this.state.storeItemsSkip)} onItemChange={this.itemChangeSI} editField="inEdit" keyField="StoreItemID"
                          onRowClick={this.storeItemRowClick} selectedField="selected" resizable={true} sortable={true} sort={this.state.itemSort} onSortChange={(e) => { this.setState({ itemSort: e.sort }); console.log(`esort: ${JSON.stringify(e.sort)}`) }}
                          skip={this.state.storeItemsSkip} take={this.state.storeItemsTake} total={this.state.StoreItems.length} onPageChange={this.storeItemsPageChange} pageable={{ info: true,type: 'numeric',pageSizes: true,previousNext: true }} pageSize={6}>
                          {/*<Column field="StoreItemID" title="Item ID" filter="numeric" width='1px' />*/}
                          <Column field="ItemName" title="Item Name" width='200px' />
                          <Column field="ItemDescription" title="Description" width='300px' />
                          {SessionInfo.CInfo.HasBarcode === true ?
                            <Column field="BarcodeNumber" title="Barcode" width='130px' /> : null}
                          <Column field="TimeOfDayID" title="Time of Day" cell={TimeOfDayDropDownCell} width='110px' />
                          <Column field="DayOfWeek" title="Day of Week" cell={DayOfWeekDropDownCell} width='110px' />
                          <Column field="ProductItemCategoryID" title="Category" cell={ProductItemCategoryDropDownCell} width='120px' />
                          <Column field="ProductItemGroupID" title="Group" cell={ProductItemGroupDropDownCell} width='110px' />
                          <Column field="ItemPrice" title="Item Price" cell={MoneyCell} width='120px' />
                          <Column field="PromotionPrice" title="Promo Price" cell={MoneyCell} width='120px' />
                          <Column cell={this.StoreItemCommandCell} width="140px" />
                        </TGrid>
                      </ExcelExport>
                    </div>
                  </TabStripTab>
                  <TabStripTab title="Store Receipts">
                    <div className="editTab">
                      <ExcelExport data={this.state.CustomOrders} ref={(exporter) => { this._exportCustomOrders = exporter; }}>   {/* filterable */}
                        <TGrid style={{ position: 'absolute',top: 0,height: '100%' }} data={orderBy(filterBy(this.state.CustomOrders,this.state.customOrdersFilter),this.state.customOrdersSort).slice(this.state.customOrdersSkip,this.state.customOrdersTake + this.state.customOrdersSkip)}
                          skip={this.state.customOrdersSkip} take={this.state.customOrdersTake} total={this.state.CustomOrders.length} onPageChange={this.customOrdersPageChange} onRowClick={this.customOrderRowClick} selectedField="selected" resizable={true}
                          sortable={true} sort={this.state.customOrdersSort} onSortChange={(e) => { this.setState({ customOrdersSort: e.sort }); }} filter={this.state.customOrdersFilter} onFilterChange={(e) => { this.setState({ customOrdersFilter: e.filter }); }}
                          pageable={{ info: true,type: 'numeric',pageSizes: true,previousNext: true }} pageSize={6}>
                          {/*Column field="CustomOrderID" title="Order Id" filter="numeric" width='1px' />*/}
                          <Column field="CustomerID" title="Customer" width='180px' />
                          <Column field="CustomOrderID" title="Receipt #" width='100px' />
                          <Column field="CustomOrderStatus" title="Status" width='150px' />
                          <Column field="OrderCreationDate" title="Order Date" width='220px' filter='date' cell={cellDateTime} />
                          <Column field="OrderPaidDate" title="Paid Date" width='220px' filter='date' cell={cellDateTime} />{/*editor="date" format="{0:D}" /> format="{0:D}"*/}
                          <Column field="AmountOwing" title="Amount Owing" width='120px' filter='numeric' cell={cellCurrencyAmtOwe} />
                          <Column field="TipAmount" title="Tip" width='120px' filter='numeric' cell={cellCurrency} />
                          <Column field="AmountPaidWithTip" title="Amount" width='140px' filter='numeric' cell={cellCurrencyAmt} />
                          <Column field="ItemCount" title="Items" width='80px' filter='numeric' />
                        </TGrid>
                      </ExcelExport>
                    </div>
                  </TabStripTab>
                  <TabStripTab title="Promotions">
                    <div className="editTab">
                      <ExcelExport data={this.state.Promotions} ref={(exporter) => { this._exportPromotions = exporter; }}>   {/* filterable */}
                        <TGrid style={{ position: 'absolute',top: 0,height: '100%' }}
                          data={orderBy(filterBy(this.state.Promotions,this.state.promoFilter),this.state.promoSort).slice(this.state.promoSkip,this.state.promoTake + this.state.promoSkip)}
                          skip={this.state.customOrdersSkip} take={this.state.promoTake} total={this.state.Promotions.length} onPageChange={this.promoPageChange} onRowClick={this.promoRowClick} selectedField="selected" resizable={true}
                          sortable={true} sort={this.state.promoSort} onSortChange={(e) => { this.setState({ promoSort: e.sort }); }} filter={this.state.promoFilter} onFilterChange={(e) => { this.setState({ promoFilter: e.filter }); }}
                          pageable={{ info: true,type: 'numeric',pageSizes: true,previousNext: true }} pageSize={6}>
                          <Column field="StorePromotionName" title="Name" width='170px' />
                          <Column field="PromotionType" title="Promo Type" width='140px' />
                          <Column field="ProductItemCategoryID" title="Product Category" width='140px' />
                          <Column field="StartDateTime" title="Start Date" width='230px' filter='date' cell={cellDateTime} />{/*editor="date" format="{0:D}" /> format="{0:D}"*/}
                          <Column field="EndDateTime" title="End Date" width='230px' filter='date' cell={cellDateTime} />{/*editor="date" format="{0:D}" /> format="{0:D}"*/}
                          <Column field="DiscountPercentage" title="Percentage" width='120px' filter='numeric' />
                          <Column field="DiscountAmount" title="Discount" filter='numeric' cell={MoneyCell} width='120px' />
                        </TGrid>
                      </ExcelExport>
                    </div>
                  </TabStripTab>
                  {/*------ Sublocations Grid Definition ------*/}
                  {/*filterable filter={this.state.SublocationsFilter} onFilterChange={(e) => { this.setState({ SublocationsFilter: e.filter }); }} */}
                  <TabStripTab title="Tables & Stations">
                    <div className="editTab">
                      <ExcelExport data={this.state.Sublocations} ref={(exporter) => { this._exportSublocations = exporter; }}>
                        <TGrid style={{ position: 'absolute',top: 0,height: '100%' }}
                          data={orderBy(filterBy(this.state.Sublocations,this.state.SublocationsFilter),this.state.SublocationsSort).slice(this.state.SublocationsSkip,this.state.SublocationsTake + this.state.SublocationsSkip)}
                          skip={this.state.SublocationsSkip} take={this.state.SublocationsTake} total={this.state.Sublocations.length} onPageChange={this.SublocationsPageChange} onRowClick={this.SublocationRowClick} selectedField="selected" resizable={true}
                          sortable={true} sort={this.state.SublocationsSort} onSortChange={(e) => { this.setState({ SublocationsSort: e.sort }); }}
                          editField="inEdit" onItemChange={this.itemChangeSublocation}
                          pageable={{ info: true,type: 'numeric',pageSizes: true,previousNext: true }} pageSize={16}>
                          <Column field="Sublocation" title="Name" width='200px' editor="text" />
                          <Column field="Description" title="Description" width='200px' editor="text" />
                          <Column field="SublocationTypeID" title="Type" cell={SublocationTypesDropDownCell} width='160px' />
                          <Column field="LocationStatusID" title="Location Status" cell={LocationStatusSetDropDownCell} width='160px' />
                          <Column field="ProductItemCategoryID" title="Category" cell={ProductItemCategoryDropDownCell} width='160px' />
                          <Column field="LocationWidth" title="Width" width='100px' filter='numeric' />
                          <Column field="LocationLength" title="Length" width='100px' filter='numeric' />
                          <Column field="LocationSizeID" title="Location Size" cell={LocationSizesDropDownCell} width='160px' />
                          <Column field="SortOrder" title="Sort Order" width='100px' filter='numeric' />
                          <Column field="IsRecordActive" cell={BoolCell} title="Active" width='70px' />
                          <Column cell={this.SublocationCommandCell} width="200px" />
                        </TGrid>
                      </ExcelExport>
                    </div>
                  </TabStripTab>
                  <TabStripTab title="Table Layout">
                    <div
                      ref={(div) => { this._element = div; }}
                    />
                  </TabStripTab>
                  {/*<TabStripTab title="Table Layout">*/}
                  {/*  <div>*/}
                  {/*  <Typography.p fontSize={"large"} textAlign={"center"}>*/}
                  {/*    Draggable KendoReact Button*/}
                  {/*  </Typography.p>*/}
                  {/*  <Draggable*/}
                  {/*    onPress={this.handlePress}*/}
                  {/*    onDragStart={this.handleDragStart}*/}
                  {/*    onDrag={this.handleDrag}*/}
                  {/*    onDragEnd={this.handleDragEnd}*/}
                  {/*    onRelease={this.handleRelease}*/}
                  {/*  >*/}
                  {/*    <Button style={{position: "absolute", left: this.state.position.x,top: this.state.position.y,}} fillMode={this.state.pressed ? "outline" : "flat"}*/}
                  {/*      themeColor={this.state.dragged ? "primary" : undefined}*/}
                  {/*    >*/}
                  {/*      <Icon name="move" size="medium" />*/}
                  {/*      Drag Me!*/}
                  {/*      <SvgIcon icon={arrowsMoveIcon} size="medium" />*/}
                  {/*    </Button>*/}
                  {/*  </Draggable>*/}
                  {/*</div>*/}
                  {/*</TabStripTab>*/}
                  {/*------ PricingOverrides Grid Definition ------*/}
                  {/**/}
                  {/* filterable filter={this.state.PricingOverridesFilter} onFilterChange={(e) => { this.setState({ PricingOverridesFilter: e.filter }); }}*/}
                  {/*<TabStripTab title="Pricing Overrides">
                    <div className="editTab">
                      <ExcelExport data={this.state.PricingOverrides} ref={(exporter) => { this._export = exporter; }}>
                        <TGrid style={{ position: 'absolute',top: 0,height: '100%' }} onItemChange={this.itemChangePricingOverride} editField="inEdit"
                          data={orderBy(filterBy(this.state.PricingOverrides,this.state.PricingOverridesFilter),this.state.PricingOverridesSort).slice(this.state.PricingOverridesSkip,this.state.PricingOverridesTake + this.state.PricingOverridesSkip)}
                          skip={this.state.PricingOverridesSkip} take={this.state.PricingOverridesTake} total={this.state.PricingOverrides.length} onPageChange={this.PricingOverridePageChange} pageable={{ info: true,type: 'numeric',pageSizes: true,previousNext: true }} pageSize={6}>
                          sortable={true} sort={this.state.PricingOverridesSort} onSortChange={(e) => { this.setState({ PricingOverridesSort: e.sort }); }}
                          editField="inEdit" onItemChange={this.itemChangePricingOverride}
                          onSelectionChange={this.PricingOverrideSelectionChange} onHeaderSelectionChange={this.PricingOverrideHeaderSelectionChange} onRowClick={this.PricingOverrideRowClick} resizable={true}
                          <Column field="OverrideName" title="Override Name" width='200px' editor="text" />
                          <Column field="SeasonCodeID" title="SeasonCode" cell={SeasonCodeDropDownCell} width='160px' />
                          <Column field="DayOfWeek" title="Day of Week" cell={DayOfWeekDropDownCell} width='160px' />
                          <Column field="TimeOfDayID" title="Time Of Day" cell={TimeOfDayDropDownCell} width='160px' />
                          <Column field="PriceAdjustmentPercent" title="Price Adjustment Percent" cell={PercentCell} width='230px' filter='numeric' />
                          <Column field="PricingTypeID" title="Pricing Type" cell={PricingTypeDropDownCell} width='160px' />
                          <Column field="IsRecordActive" cell={BoolCell} title="Active Record" width='70px' />
                          <Column cell={this.PricingOverrideCommandCell} width="200px" />
                        </TGrid>
                      </ExcelExport>
                    </div>
                  </TabStripTab>*/}
                  <TabStripTab title="Reservations">
                    <div className="editTab">
                      <div className="editTabTop">
                        <br />
                        <div className="editTabLeftNarrow">
                          <div id="div1" className="editInside">
                            <h4>Table Wait List</h4>
                            <br />
                            <div className="editField">
                              <span className="editFieldLabel">Table Count</span>
                              <input value={this.state.TableCount} name="TableCount" readOnly={true} className="editNumShortInputRO" />
                            </div>
                            <div className="editField">
                              <span className="editFieldLabel">Tables In Use</span>
                              <input value={this.state.TablesInUse} name="TablesInUse" readOnly={true} className="editNumShortInputRO" />
                            </div>
                            <div className="editField">
                              <span className="editFieldLabel">Tables Dirty</span>
                              <input value={this.state.TablesDirty} name="TablesDirty" readOnly={true} className="editNumShortInputRO" />
                            </div>
                            <div className="editField">
                              <span className="editFieldLabel">Tables Reserved</span>
                              <input value={this.state.TablesReserved} name="TablesReserved" readOnly={true} className="editNumShortInputRO" />
                            </div>
                            <div className="editField">
                              <span className="editFieldLabel">Tables Available</span>
                              <input value={this.state.TablesAvailable} name="TablesAvailable" readOnly={true} className="editNumShortInputRO" />
                            </div>
                            <div className="editField">
                              <span className="editFieldLabel">Waitlist Count</span>
                              <input value={this.state.WaitlistCount} name="WaitlistCount" readOnly={true} className="editNumShortInputRO" />
                            </div>
                            <div className="editField">
                              <span className="editFieldLabel">Average Wait Time</span>
                              <input value={timeDisplay(this.state.AverageWaitTime)} name="AverageWaitTime" readOnly={true} className="NumTxtExtShortInputRO" />
                            </div>
                            <div className="editField">
                              <span className="editFieldLabel">Max Wait Time</span>
                              <input value={timeDisplay(this.state.MaxWaitTime)} name="MaxWaitTime" readOnly={true} className="NumTxtExtShortInputRO" />
                            </div>
                          </div>
                        </div>
                        <div className="editTabMiddleLeft">
                          <div id="div1" className="editInside">
                            {/*onDragStart={this.handleDragStart}*/}
                            {/*onDrop={this.handleDrop}*/}
                            <h4>Wait List</h4>
                            <ListBox
                              style={{ height: 400,width: "98%",}}
                              data={this.state.WaitList} textField="name" selectedField="selected" onItemClick={(e) => this.handleItemClick(e,"WaitList","SeatedList")}
                              toolbar={() => {
                                return (
                                  <ListBoxToolbar
                                    tools={["moveUp","moveDown","transferTo","transferFrom","transferAllTo","transferAllFrom","remove",]}
                                    data={this.state.WaitList}
                                    dataConnected={this.state.SeatedList}
                                    onToolClick={this.handleToolBarClick}
                                  />
                                );
                              }}
                            />
                          </div>
                        </div>
                        <div className="editTabMiddleRight">
                          <div id="div1" className="editInside">
                            {/*onDragStart={this.handleDragStart}*/}
                            {/*onDrop={this.handleDrop}*/}
                            <h4>Seated List</h4>
                            <ListBox
                              style={{ height: 400,width: "98%",}}
                              data={this.state.SeatedList} textField="name" selectedField="selected" onItemClick={(e) => this.handleItemClick(e,"SeatedList","WaitList")}
                            />
                          </div>
                        </div>
                        <div className="editTabRightNarrow">
                          <div id="div1" className="editInside">
                            <h4>Reservation Information</h4>
                            {/*<div id="div2" className="editField">
                              <span className="editFieldLabel">Tax Rate 1</span>
                              <NumericTextBox format="p1" name="TaxRate" value={this.state.TaxRate} min={0.0} max={0.3} step={0.01} onChange={evt => this.chgFldVal(evt)} width={100} className="NumTxtExtShortInput" />
                            </div>*/}
                          </div>
                        </div>
                      </div>
                    </div>
                  </TabStripTab>
                  <TabStripTab title="Management">
                    <div className="editTab">
                      <div className="editTabLeftNarrow">
                        <div id="div1" className="editTabLeftNarrowTop">
                          <h4>Period Selection</h4>
                          <br />
                          <div className="editField">
                            <span className="editFieldLabel">Period(s) Back</span>
                            <NumericTextBox name="PeriodBack" value={this.state.PeriodBack} min={0} max={60} step={1} onChange={evt => this.chgFldValMon(evt)} width={100} className="NumTxtExtShortInput" />
                          </div>
                          <div id='div2' className='editFieldShort'>
                            <span className='editFieldLabel'>Period Type</span>
                            <div className='editDDPos'>
                              <DropDownList data={this.PeriodsFixedValues} textField='ddName' dataItemKey='ddID' value={this.PeriodsFixedValues.find(c => c.ddID === this.state.PeriodType)} name='PeriodType' onChange={evt => this.chgDDFldValMon(evt)} className='editInputDDSh' />
                            </div>
                          </div>
                        </div>
                        <div id="div1" className="editTabLeftNarrowBot">
                          <h4>Current Period</h4>
                          <div id="div2" className="editField">
                            <span className="editFieldLabel">Period Date</span>
                            <br />
                            <DateTimePicker value={new Date(this.state.TxDate)} name="TxDate" className="editDateInputShort" readOnly="{true}" />
                          </div>
                        </div>
                        <div id="div1" className="editTabRightNarrowTop">
                          <h4>Current Orders for Period</h4>
                          <div className="editField">
                            <span className="editFieldLabel">Orders for Period: &nbsp; </span>
                            <label style={{ fontSize: 'large',color: this.labelColor,fontWeight: 'bold',}}>{this.state.orderCount}</label>
                          </div>
                          <br />
                          <RadialGauge
                            pointer={{ value: this.state.orderCount }}
                            transitions={false}
                            colors
                            scale={{
                              labels: { format: 'n',color: this.tickColor,visible: true },
                              majorTicks: { visible: true,color: this.tickColor },
                              minorTicks: { visible: true,color: this.tickColor },
                              rangeSize: 5,
                              rangeLineCap: 'round',
                              startAngle: 0,
                              endAngle: 270,
                              min: 0,
                              max: this.maxCurrentOrders,
                              reverse: false,
                              ranges: [{ to: this.xCO1,color: this.colorRCO[0] },{ from: this.xCO1,o: this.xCO2,color: this.colorRCO[1] },{ from: this.xCO2,to: this.xCO3,color: this.colorRCO[2] },{ from: this.xCO3,color: this.colorRCO[3] }]
                            }} />
                        </div>
                        <div id="div1" className="editTabRightNarrowBot">
                          <h4>Current Menu Items for Period</h4>
                          <div className="editField">
                            <span className="editFieldLabel">Order Items for Period: &nbsp; </span>
                            <label style={{ fontSize: 'large',color: this.labelColor,fontWeight: 'bold',}}>{this.state.itemCount}</label>
                          </div>
                          <br />
                          <RadialGauge
                            pointer={{ value: this.state.itemCount }}
                            transitions={false}
                            colors
                            scale={{
                              labels: { format: 'n',color: this.tickColor,visible: true },
                              majorTicks: { visible: true,color: this.tickColor },
                              minorTicks: { visible: true,color: this.tickColor },
                              rangeSize: 5,
                              rangeLineCap: 'round',
                              startAngle: 0,
                              endAngle: 270,
                              min: 0,
                              max: this.maxMenuItems,
                              reverse: false,
                              ranges: [{ to: this.xMI1,color: this.colorRMI[0] },{ from: this.xMI1,to: this.xMI2,color: this.colorRMI[1] },{ from: this.xMI2,to: this.xMI3,color: this.colorRMI[2] },{ from: this.xMI3,color: this.colorRMI[3] }]
                            }} />
                        </div>
                      </div>
                      <div className="editTabMiddle">
                        <div id="div1" className="graphTabNarrowLeftTop">
                          <h4>Tip Value for Period</h4>
                          <div className="editField">
                            <span className="editFieldLabel">Tips for Period: &nbsp; </span>
                            <label style={{ fontSize: 'large',color: this.labelColor,fontWeight: 'bold',}}>{this.moneyFormat(this.state.TipAmount)}</label>
                          </div>
                          <br />
                          <RadialGauge
                            pointer={{ value: this.state.TipAmount }}
                            transitions={false}
                            colors
                            scale={{
                              labels: { format: 'c',color: this.labelColor,visible: true },
                              majorTicks: { visible: true,color: '#002aff' },
                              minorTicks: { visible: true,color: '#00ff00' },
                              rangeSize: 9,
                              rangeLineCap: 'round',
                              startAngle: 0,
                              endAngle: 180,
                              min: 0,
                              max: this.maxTipValue,
                              reverse: false,
                              ranges: [{ to: this.xTP1,color: this.colorRTP[0] },{ from: this.xTP1,to: this.xTP2,color: this.colorRTP[1] },{ from: this.xTP2,to: this.xTP3,color: this.colorRTP[2] },{ from: this.xTP3,color: this.colorRTP[3] }]
                            }} />
                        </div>
                        <div id="div1" className="graphTabNarrowRightTop">
                          <h4>Total Taxes for Period</h4>
                          <div className="editField">
                            <span className="editFieldLabel">Taxes for Period: &nbsp; </span>
                            <label style={{ fontSize: 'large',color: this.labelColor,fontWeight: 'bold',}}>{this.moneyFormat(this.state.TotalTaxAmount)}</label>
                          </div>
                          <br />
                          <RadialGauge
                            pointer={{ value: this.state.TotalTaxAmount }}
                            transitions={false}
                            colors
                            scale={{
                              labels: { format: 'c',color: this.labelColor,visible: true },
                              majorTicks: { visible: true,color: '#002aff' },
                              minorTicks: { visible: true,color: '#00ff00' },
                              rangeSize: 9,
                              rangeLineCap: 'round',
                              startAngle: 0,
                              endAngle: 180,
                              min: 0,
                              max: this.maxTotalTaxes,
                              reverse: false,
                              ranges: [{ to: this.xTT1,color: this.colorRTT[0] },{ from: this.xTT1,to: this.xTT2,color: this.colorRTT[1] },{ from: this.xTT2,to: this.xTT3,color: this.colorRTT[2] },{ from: this.xTT3,color: this.colorRTT[3] }]
                            }} />
                        </div>
                        <div id="div1" className="graphTabNarrowLeftBot">
                          <h4>Option Count for Period</h4>
                          <div className="editField">
                            <span className="editFieldLabel">Option Count for Period: &nbsp; </span>
                            <label style={{ fontSize: 'large',color: this.labelColor,fontWeight: 'bold',}}>{this.state.OptionCount}</label>
                          </div>
                          <br />
                          <RadialGauge
                            pointer={{ value: this.state.OptionCount }}
                            transitions={false}
                            colors
                            scale={{
                              labels: { format: 'n',color: this.tickColor,visible: true },
                              majorTicks: { visible: true,color: this.tickColor },
                              minorTicks: { visible: true,color: this.tickColor },
                              rangeSize: 5,
                              rangeLineCap: 'round',
                              startAngle: 0,
                              endAngle: 270,
                              min: 0,
                              max: this.maxOptionCount,
                              reverse: false,
                              ranges: [{ to: this.xOC1,color: this.colorROC[0] },{ from: this.xOC1,to: this.xOC2,color: this.colorROC[1] },{ from: this.xOC2,to: this.xOC3,color: this.colorROC[2] },{ from: this.xOC3,color: this.colorROC[3] }]
                            }} />
                        </div>
                        <div id="div1" className="graphTabNarrowRightBot">
                          <h4>Options Value for Period</h4>
                          <div className="editField">
                            <span className="editFieldLabel">Options for Period: &nbsp; </span>
                            <label style={{ fontSize: 'large',color: this.labelColor,fontWeight: 'bold',}}>{this.moneyFormat(this.state.OptionsPrice)}</label>
                          </div>
                          <br />
                          <RadialGauge
                            pointer={{ value: this.state.customerCount }}
                            transitions={false}
                            colors
                            scale={{
                              labels: { format: 'c',color: this.labelColor,visible: true },
                              majorTicks: { visible: true,color: '#002aff' },
                              minorTicks: { visible: true,color: '#00ff00' },
                              rangeSize: 9,
                              rangeLineCap: 'round',
                              startAngle: 0,
                              endAngle: 180,
                              min: 0,
                              max: this.maxOptionValue,
                              reverse: false,
                              ranges: [{ to: this.xOV1,color: this.colorROV[0] },{ from: this.xOV1,to: this.xOV2,color: this.colorROV[1] },{ from: this.xOV2,to: this.xOV3,color: this.colorROV[2] },{ from: this.xOV3,color: this.colorROV[3] }]
                            }} />
                        </div>
                      </div>
                      <div className="editTabRightNarrow">
                        <div id="div1" className="graphTabNarrowTop">
                          <h4>Total Sales for Period</h4>
                          <div className="editField">
                            <span className="editFieldLabel">Sales for Period: &nbsp; </span>
                            <label style={{ fontSize: 'large',color: this.labelColor,fontWeight: 'bold',}}>{this.moneyFormat(this.state.totalPeriodSales)}</label>
                          </div>
                          <br />
                          <RadialGauge
                            pointer={{
                              value: this.state.totalPeriodSales,
                            }}
                            transitions={false}
                            scale={{
                              labels: { format: 'c',color: this.labelColor,visible: true },
                              majorTicks: { visible: true,color: '#002aff' },
                              minorTicks: { visible: true,color: '#00ff00' },
                              rangeSize: 9,
                              rangeLineCap: 'round',
                              startAngle: 0,
                              endAngle: 180,
                              min: 0,
                              max: this.maxTotalSales,
                              reverse: false,
                              ranges: [{ to: this.xTS1,color: this.colorRTS[0] },{ from: this.xTS1,to: this.xTS2,color: this.colorRTS[1] },{ from: this.xTS2,to: this.xTS3,color: this.colorRTS[2] },{ from: this.xTS3,color: this.colorRTS[3] }]
                            }} />
                        </div>
                        <div id="div1" className="editTabNarrowBot">
                          <h4>Active Customers for Period</h4>
                          <div className="editField">
                            <span className="editFieldLabel">Customers for Period: &nbsp; </span>
                            <label style={{ fontSize: 'large',color: this.labelColor,fontWeight: 'bold',}}>{this.state.customerCount}</label>
                          </div>
                          <br />
                          <RadialGauge
                            pointer={{ value: this.state.customerCount }}
                            transitions={false}
                            colors
                            scale={{
                              labels: { format: 'n',color: this.tickColor,visible: true },
                              majorTicks: { visible: true,color: this.tickColor },
                              minorTicks: { visible: true,color: this.tickColor },
                              rangeSize: 5,
                              rangeLineCap: 'round',
                              startAngle: 0,
                              endAngle: 270,
                              min: 0,
                              max: this.maxActiveCustomers,
                              reverse: false,
                              ranges: [{ to: this.xAC1,color: this.colorRAC[0] },{ from: this.xAC1,to: this.xAC2,color: this.colorRAC[1] },{ from: this.xAC2,to: this.xAC3,color: this.colorRAC[2] },{ from: this.xAC3,color: this.colorRAC[3] }]
                            }} />
                        </div>
                      </div>
                    </div>
                  </TabStripTab>
                  <TabStripTab title="View Menu App">
                    <Iframe src={this.subInteract} height="1100" width="750" token="T9D393637492" />
                  </TabStripTab>
                </TabStrip>
              </div>
              {/*----------------------- BOTTOM PANE -------------------------------------------------------------------------------------------------------------------------*/}
              <div id="splitterBottom" className="splitPaneContent">
                {this.state.selectedTopTab === this.Tabs.STOREINFO ?
                  <div className="editPane">  {/* Store Information */}
                    <div id="div1" className="editInside">
                    </div>
                  </div> : null}
                {this.state.selectedTopTab === this.Tabs.ITEMS ?
                  <div className="editPane">  {/* Store Items */}
                    <div className="editTabLeftNarrow">
                      <div id="div1" className="editInside">
                        <h4>Item Information</h4>
                        {(SessionInfo.PermLevel >= 499) ?
                          <>
                            <div id='div2' className='editField'>
                              <span className='editFieldLabel'>Store Item ID</span>
                              <input value={this.state.StoreItemID} name='StoreItemID' onChange={evt => this.chgFldVal(evt)} readOnly={true} className='editNumInputRO' />
                            </div>
                            <div id='div2' className='editField'>
                              <span className='editFieldLabel'>Reference ID</span>
                              <input value={this.state.ReferenceItemID} name='ReferenceItemID' onChange={evt => this.chgFldVal(evt)} readOnly={true} className='editNumInputRO' />
                            </div>
                            <br />
                          </> : null}
                        <div id="div2" className="editField">
                          <span className="editFieldLabel">Item Name</span>
                          <input value={this.state.ItemName} name="ItemName" onChange={evt => this.chgFldVal(evt)} className={this.ClassNames.find(t => t.n === "ItemName").c} />
                        </div>
                        <div id="div2" className="editTAField">
                          <span className="editFieldLabel">Item Description</span>
                          <textarea value={this.state.ItemDescription} name="ItemDescription" onChange={evt => this.chgFldVal(evt)} className={this.ClassNames.find(t => t.n === "ItemDescription").c} />
                        </div>
                        {SessionInfo.Cnfg.HasBarcodes === true ?
                          <div id="div2" className="editField">
                            <span className="editFieldLabel">Barcode Number</span>
                            <input value={this.state.BarcodeNumber} name="BarcodeNumber" onChange={evt => this.chgFldVal(evt)} className="editInput" />
                          </div> : null}
                        <div className="editField">
                          <span className="editFieldLabel">Item Status</span>
                          <div className="editDDPos">
                            <DropDownList data={this.state.ItemStatusSetDict} textField="ddName" dataItemKey="ddID" value={this.state.ItemStatusSetDict.find(c => c.ddID === this.state.ItemStatusID)} name="ItemStatusID" onChange={evt => this.chgDDFldVal(evt)} className={this.ClassNames.find(t => t.n === "ItemStatusID").c} />
                          </div>
                        </div>
                        <div className="editField">
                          <span className='editFieldLabel'>Season Code</span>
                          <div className='editDDPos'>
                            <DropDownList data={this.state.SeasonCodesDict} textField='ddName' dataItemKey='ddID' value={this.state.SeasonCodesDict.find(c => c.ddID === this.state.SeasonCodeID)} name='SeasonCodeID' onChange={evt => this.chgDDFldVal(evt)} className={this.ClassNames.find(t => t.n === "SeasonCodeID").c} />
                          </div>
                        </div>
                        <div className="editField">
                          <span className="editFieldLabel">Time Of Day</span>
                          <div className="editDDPos">
                            <DropDownList data={this.state.TimeOfDayDict} textField="ddName" dataItemKey="ddID" value={this.state.TimeOfDayDict.find(c => c.ddID === this.state.TimeOfDayID)} name="TimeOfDayID" onChange={evt => this.chgDDFldVal(evt)} className={this.ClassNames.find(t => t.n === "TimeOfDayID").c} />
                          </div>
                        </div>
                        <div className="editField">
                          <span className="editFieldLabel">Day of Week</span>
                          <div className="editDDPos">
                            <DropDownList data={this.state.DayOfWeekDict} textField="ddName" dataItemKey="ddID" value={this.state.DayOfWeekDict.find(c => c.ddID === this.state.DayOfWeek)} name="DayOfWeek" onChange={evt => this.chgDDFldVal(evt)} className={this.ClassNames.find(t => t.n === "DayOfWeek").c} />
                          </div>
                        </div>
                        {SessionInfo.CInfo.HasDaysOfWeek === true ?
                          <div className="editTAField">
                            <span className="editFieldLabel">Days of Week</span>
                            <div className="editDDPos">
                              <MultiSelect data={DaysOfWeekList} value={this.state.DaysOfWeekVals} name="DaysOfWeek" onChange={this.onChangeDaysOfWeek} className="editInputDD" />
                            </div>
                          </div> : null}
                        <div id='div2' className='editField'>
                          <span className='editFieldLabel'>Full Description</span>
                          <textarea value={this.state.ItemFullDescription} name='ItemFullDescription' onChange={evt => this.chgFldVal(evt)} className='editTAInputHigh' />
                        </div>
                      </div>
                    </div>
                    <div className="editTabMiddle">
                      <div id="div1" className="editInside">
                        <h4>Inventory</h4>
                        <div id='div2' className='editField'>
                          <span className='editFieldLabel'>Inventory Control</span>
                          <div className='editDDPos'>
                            <DropDownList data={this.state.InventoryControlTypeIDs} textField='ddName' dataItemKey='ddID' value={this.state.InventoryControlTypeIDs.find(c => c.ddID === this.state.InventoryControlTypeID)} name='InventoryControlTypeID' onChange={evt => this.chgDDFldVal(evt)} className='editInputDD' />
                          </div>{/* */}
                        </div>
                        <div id='div2' className='editField'>
                          <span className='editFieldLabel'>Total Quantity</span>
                          <input value={this.state.TotalQuantity} name='TotalQuantity' onChange={evt => this.chgFldVal(evt)} onBlur={evt => this.setQuantity(evt)} className='editNumInput' />
                        </div>
                        <div id='div2' className='editField'>
                          <span className='editFieldLabel'>Locked Quantity</span>
                          <input value={this.state.LockedQuantity} name='LockedQuantity' onChange={evt => this.chgFldVal(evt)} className='editNumInputRO' readOnly={true} />
                        </div>
                        <div id='div2' className='editField'>
                          <span className='editFieldLabel'>Available Quantity</span>
                          <input value={this.state.AvailableQuantity} name='AvailableQuantity' onChange={evt => this.chgFldVal(evt)} className='editNumInputRO' readOnly={true} />
                        </div>
                        <div id='div2' className='editField'>
                          <span className='editFieldLabel'>Received Quantity</span>
                          <input value={this.state.TotalReceivedQuantity} name='TotalReceivedQuantity' onChange={evt => this.chgFldVal(evt)} className='editNumInputRO' readOnly={true} />
                        </div>
                        <div id='div2' className='editField'>
                          <span className='editFieldLabel'>Total Sold Quantity</span>
                          <input value={this.state.TotalSoldQuantity} name='TotalSoldQuantity' onChange={evt => this.chgFldVal(evt)} className='editNumInputRO' readOnly={true} />
                        </div>
                        <br />
                        <br />
                        {this.state.ItemName != '' && SessionInfo.PermLevel >= 499 ?
                          <div className="editField">
                            <div className="buttonRight">
                              <Tooltip position="top" anchorElement="pointer">
                                <Button icon="refresh" className="medButton" title="Display QR Code" onClick={this.onDisplayItemQR}>Display QR Code</Button>
                              </Tooltip>
                            </div>
                          </div> : null}
                      </div>
                    </div>
                    <div className="editTabRightNarrow">
                      <div id="div1" className="editInside">
                        <h4>Pricing</h4>
                        <div id="div2" className="editField">
                          <span className="editFieldLabel">Item Price</span>
                          <input value={this.state.ItemPriceS} name="ItemPrice" onChange={evt => this.chgMoneyFldVal(evt)} onFocus={evt => this.focusMoneyFldVal(evt)} onBlur={evt => this.blurMoneyFldVal(evt)} className={this.ClassNames.find(t => t.n === "ItemPrice").c} />
                        </div>
                        <div id='div2' className='editField'>
                          <span className='editFieldLabel'>Promotion Price</span>
                          <input value={this.state.PromotionPriceS} name='PromotionPrice' onChange={evt => this.chgMoneyFldVal(evt)} onFocus={evt => this.focusMoneyFldVal(evt)} onBlur={evt => this.blurMoneyFldVal(evt)} className={this.ClassNames.find(t => t.n === "PromotionPrice").c} />
                        </div>
                        <div className="editField">
                          <span className="editFieldLabel">Rate Type</span>
                          <div className="editDDPos">
                            <DropDownList data={this.state.RateTypesDict} textField="ddName" dataItemKey="ddID" value={this.state.RateTypesDict.find(c => c.ddID === this.state.RateTypeID)} name="RateTypeID" onChange={evt => this.chgDDFldVal(evt)} className={this.ClassNames.find(t => t.n === "RateTypeID").c} />
                            {/*<DropDownList data={this.state.StoreGroups} textField="ddName" dataItemKey="ddID" value={this.state.StoreGroups.find(c => c.ddID === this.state.StoreGroupID)} name="StoreGroupID" onChange={evt => this.chgDDFldVal(evt)} className="editInputDD" />*/}
                          </div>
                        </div>
                        <div className="editField">
                          <span className="editFieldLabel">Product Category</span>
                          <div className="editDDPos">
                            <DropDownList data={this.state.ProductItemCategoriesDict} textField="ddName" dataItemKey="ddID" value={this.state.ProductItemCategoriesDict.find(c => c.ddID === this.state.ProductItemCategoryID)} name="ProductItemCategoryID" onChange={evt => this.chgDDFldVal(evt)} className={this.ClassNames.find(t => t.n === "ProductItemCategoryID").c} />
                            {/*<DropDownList data={this.state.ProductItemCategoriesDict} textField="ddName" dataItemKey="ddID" value={this.state.ProductItemCategoriesDict.find(c => c.ddID === this.state.ProductItemCategoryID)} name="ProductItemCategoryID" onChange={evt => this.chgDDFldVal(evt)} className={editInputDD} />*/}
                          </div>
                        </div>
                        <div className="editField">
                          <span className="editFieldLabel">Product Group</span>
                          <div className="editDDPos">
                            <DropDownList data={this.state.ProductItemGroupsDict} textField="ddName" dataItemKey="ddID" value={this.state.ProductItemGroupsDict.find(c => c.ddID === this.state.ProductItemGroupID)} name="ProductItemGroupID" onChange={evt => this.chgDDFldVal(evt)} className={this.ClassNames.find(t => t.n === "ProductItemGroupID").c} />
                          </div>
                        </div>
                        <div className="editField">
                          <span className="editFieldLabel">Product Type</span>
                          <div className="editDDPos">
                            <DropDownList data={this.state.ProductItemTypesDict} textField="ddName" dataItemKey="ddID" value={this.state.ProductItemTypesDict.find(c => c.ddID === this.state.ProductItemTypeID)} name="ProductItemTypeID" onChange={evt => this.chgDDFldVal(evt)} className={this.ClassNames.find(t => t.n === "ProductItemTypeID").c} />
                          </div>
                        </div>
                        <div className="editField">
                          <span className="editFieldLabel">Promotion Type</span>
                          <div className="editDDPos">
                            <DropDownList data={this.state.PromotionTypesDict} textField="ddName" dataItemKey="ddID" value={this.state.PromotionTypesDict.find(c => c.ddID === this.state.PromotionType)} name="PromotionType" onChange={evt => this.chgDDFldVal(evt)} className={this.ClassNames.find(t => t.n === "PromotionType").c} />
                          </div>
                        </div>
                        <br />
                        {this.state.ItemName != '' ?
                          <div className="editField">
                            <div className="buttonRight">
                              <Tooltip position="top" anchorElement="pointer">
                                <Button icon="refresh" className="medButton" title="Refresh with Product Item from Catalog" onClick={this.onRefreshStoreItem}>Refresh Item from Main</Button>
                              </Tooltip>
                            </div>
                          </div> : null}
                        <br />
                        <div className="editField">
                          <div className="buttonRight">
                            <Tooltip position="top" anchorElement="pointer">
                              <Button icon="refresh" className="medButton" title="Refresh with ALL Product Items from Catalog" onClick={this.onRefreshAllStoreItems}>Refresh All Items</Button>
                            </Tooltip>
                          </div>
                        </div>
                        <br />
                        {(1 === 0) &&
                          <div className="editField">
                            <Button icon="refresh" color="primary" onClick={this.onVerifyWorkflow}>Verify Workflow</Button>
                          </div>
                        }
                      </div>
                    </div>
                  </div> : null}
                {this.state.selectedTopTab === this.Tabs.RECEIPTS ?
                  <div className="editPane">  {/* Receipts Bottom Pane */}
                    <div className="editTabLeftNarrow">
                      <div id="div1" className="editInside">
                        <h4>Receipt Control</h4>
                        <div id="div2" className="editField">
                          <span className="editFieldLabel">Customer</span>
                          <input value={this.state.CustomerID} name="CustomerID" onChange={evt => this.chgFldVal(evt)} className="editInput" readOnly="{true}" />
                        </div>
                        <div id="div2" className="editField">
                          <span className="editFieldLabel">Receipt #</span>
                          <input value={this.state.CustomOrderID} name="CustomOrderID" onChange={evt => this.chgFldVal(evt)} className="editInput" readOnly="{true}" />
                        </div>
                        <div id="div2" className="editField">
                          <span className="editFieldLabel">Order Status</span>
                          <input value={this.state.CustomOrderStatus} name="CustomOrderStatus" onChange={evt => this.chgFldVal(evt)} className="editInput" readOnly="{true}" />
                        </div>
                        <div id="div2" className="editField">
                          <span className="editFieldLabel">Order Type</span>
                          <input value={this.state.CustomOrderType} name="CustomOrderType" onChange={evt => this.chgFldVal(evt)} className="editInput" readOnly="{true}" />
                        </div>
                        <div id="div2" className="editField">
                          <span className="editFieldLabel">Order Creation Date</span>
                          <div className="editDDPos">
                            <DateTimePicker value={new Date(this.state.OrderCreationDate)} name="OrderCreationDate" onChange={evt => this.chgFldVal(evt)} className="editDateInput" readOnly="{true}" />
                            {/*<DateTimePicker value={this.state.OrderCreationDate < new Date(2000, 1, 1) ? new Date(2000, 1, 1) : new Intl.DateTimeFormat("en-CA", { dateStyle: "long", timeStyle: "short" }).format(this.state.OrderCreationDate)} name="OrderCreationDate" onChange={evt => this.chgFldVal(evt)} className="editDateInput" readOnly="{true}" />*/}
                          </div>
                        </div>
                        <div id="div2" className="editField">
                          <span className="editFieldLabel">Order Paid Date</span>
                          <div className="editDDPos">
                            <DateTimePicker value={new Date(this.state.OrderPaidDate)} name="OrderPaidDate" onChange={evt => this.chgFldVal(evt)} className="editDateInput" readOnly="{true}" />
                          </div>
                        </div>
                        <div className="editField">
                          <span className="editFieldLabel">Payment Method</span>
                          <input value={this.state.PaymentMethodTypeID} name="PaymentMethodTypeID" onChange={evt => this.chgFldVal(evt)} className="editInput" readOnly="{true}" />
                        </div>
                        <div id='div2' className='editField'>
                          <span className='editFieldLabel'>Paid Comment</span>
                          <textarea value={this.state.PaidComment} name='PaidComment' onChange={evt => this.chgFldVal(evt)} className='editTAInput' />
                        </div>
                        <br />
                        <br />
                        <div id='div2' className='editField'>
                          <span className='editFieldLabel'>Comments</span>
                          <textarea value={this.state.OrderNotes} name='OrderNotes' onChange={evt => this.chgFldVal(evt)} className='editTAInput' />
                        </div>
                      </div>
                    </div>
                    <div className="editTabMiddle">
                      <div id="div1" className="editInside">
                        <h4>Receipt Totals</h4>
                        <div id="div2" className="editField">
                          <span className="editFieldLabel">Total</span>
                          <input value={this.moneyFormat(this.state.AmountPaid)} name="AmountPaid" onChange={evt => this.chgMoneyFldVal(evt)} className="editMoneyInput" readOnly="{true}" />
                        </div>
                        <div className="editField">
                          <span className="editFieldLabel">Total Tax Amount</span>
                          <input value={this.moneyFormat(this.state.TotalTaxAmount)} name="TotalTaxAmount" onChange={evt => this.chgMoneyFldVal(evt)} className="editMoneyInput" readOnly="{true}" />
                        </div>
                        <div className="editField">
                          <span className="editFieldLabel">Total Tax2 Amount</span>
                          <input value={this.moneyFormat(this.state.TotalTax2Amount)} name="TotalTax2Amount" onChange={evt => this.chgMoneyFldVal(evt)} className="editMoneyInput" readOnly="{true}" />
                        </div>
                        <div className="editField">
                          <span className="editFieldLabel">Total Tax3 Amount</span>
                          <input value={this.moneyFormat(this.state.TotalTax3Amount)} name="TotalTax2Amount" onChange={evt => this.chgMoneyFldVal(evt)} className="editMoneyInput" readOnly="{true}" />
                        </div>
                        <div className="editField">
                          <span className="editFieldLabel">Tip Amount</span>
                          <input value={this.moneyFormat(this.state.TipAmount)} name="TipAmount" onChange={evt => this.chgMoneyFldVal(evt)} className="editMoneyInput" readOnly="{true}" />
                        </div>
                        <div id="div2" className="editField">
                          <span className="editFieldLabel">Total Paid</span>
                          <input value={this.moneyFormat(this.state.AmountPaidWithTip)} name="AmountPaidWithTip" onChange={evt => this.chgMoneyFldVal(evt)} className="editMoneyInput" readOnly="{true}" />
                        </div>
                      </div>
                    </div>
                    <div className="editTabRightNarrow">
                      <div id="div1" className="editInside">
                        <br />
                        <h4>Delivery</h4>
                        <div id='div2' className='editField'>
                          <span className='editFieldLabel'>Address</span>
                          <textarea value={this.state.Address} name='Address' onChange={evt => this.chgFldVal(evt)} className='editTAInput' />
                        </div>
                        <br />
                        <br />
                        <div id='div2' className='editField'>
                          <span className='editFieldLabel'>Phone Number</span>
                          <MaskedTextBox value={MaskString(this.state.PhoneNumber,"(###) ###-####")} name='PhoneNumber' onChange={evt => this.chgFldValMask(evt,"(###) ###-####")} mask="(999) 000-0000" className='editPhoneInput' />
                        </div>
                        <br />
                        <h4>Additional Receipt Information</h4>
                        <div id="div2" className="editField">
                          <span className="editFieldLabel">Amount Owinge</span>
                          <input value={this.moneyFormat(this.state.AmountOwing)} name="AmountOwing" onChange={evt => this.chgMoneyFldVal(evt)} className="editMoneyInput" readOnly="{true}" />
                        </div>
                        <div id="div2" className="editField">
                          <span className="editFieldLabel">Total Amount</span>
                          <input value={this.moneyFormat(this.state.TotalAmount)} name="TotalAmount" onChange={evt => this.chgMoneyFldVal(evt)} className="editMoneyInput" readOnly="{true}" />
                        </div>
                        <div id="div2" className="editField">
                          <span className="editFieldLabel">Discount Amount</span>
                          <input value={this.moneyFormat(this.state.DiscountAmount)} name="DiscountAmount" onChange={evt => this.chgMoneyFldVal(evt)} className="editMoneyInput" readOnly="{true}" />
                        </div>
                        <div className="editField">
                          <span className="editFieldLabel">Credit Amount Used</span>
                          <input value={this.moneyFormat(this.state.CreditAmountUsed)} name="CreditAmountUsed" onChange={evt => this.chgMoneyFldVal(evt)} className="editMoneyInput" readOnly="{true}" />
                        </div>
                        <div className="editField">
                          <span className="editFieldLabel">Refund Amount</span>
                          <input value={this.moneyFormat(this.state.RefundAmount)} name="RefundAmount" onChange={evt => this.chgMoneyFldVal(evt)} className="editMoneyInput" readOnly="{true}" />
                        </div>
                      </div>
                    </div>
                  </div> : null}
                {this.state.selectedTopTab === this.Tabs.PROMOS ?
                  <div className="editPane">  {/* Promotions */}
                    <div className="editTabLeftNarrow">
                      <div id="div1" className="editInside">
                        <h4>Promotion Info</h4>
                        <div id='div2' className='editField'>
                          <span className='editFieldLabel'>Promotion Name</span>
                          <input value={this.state.StorePromotionName} name="StorePromotionName" onChange={evt => this.chgFldVal(evt)} className="editInput" />
                        </div>
                        <div id='div2' className='editField'>
                          <span className='editFieldLabel'>Promo Description</span>
                          <textarea value={this.state.PromotionDescription} name='PromotionDescription' onChange={evt => this.chgFldVal(evt)} className='editTAInput' />
                        </div>
                        <br />
                        <br />
                        <div id='div2' className='editField'>
                          <span className='editFieldLabel'>Store Location</span>
                          <div className='editDDPos'>
                            <DropDownList data={this.state.StoreLocationsDict} textField='ddName' dataItemKey='ddID' value={this.state.StoreLocationsDict.find(c => c.ddID === this.state.StoreLocationID)} name='StoreLocationID' onChange={evt => this.chgDDFldVal(evt)} className='editInputDD' />
                          </div>
                        </div>
                        <div id='div2' className='editField'>
                          <span className='editFieldLabel'>Promotion Type</span>
                          <div className='editDDPos'>
                            <DropDownList data={this.state.PromotionTypesDict} textField='ddName' dataItemKey='ddID' value={this.state.PromotionTypesDict.find(c => c.ddID === this.state.PromotionType)} name='PromotionType' onChange={evt => this.chgDDFldVal(evt)} className='editInputDD' />
                          </div>
                        </div>
                        <div id='div2' className='editField'>
                          <span className='editFieldLabel'>Discount Amount</span>
                          <input value={this.state.DiscountAmountS} name='DiscountAmount' onChange={evt => this.chgMoneyFldVal(evt)} onFocus={evt => this.focusMoneyFldVal(evt)} onBlur={evt => this.blurMoneyFldVal(evt)} className='editMoneyInput' />
                        </div>
                        <div id='div2' className='editField'>
                          <span className='editFieldLabel'>Discount Percentage</span>
                          <input value={this.state.DiscountPercentageS} name='DiscountPercentage' onChange={evt => this.chgPercentFldVal(evt)} onFocus={evt => this.focusPercentFldVal(evt)} onBlur={evt => this.blurPercentFldVal(evt)} className='editNumShortInput' />
                        </div>
                        <div id='div2' className='editField'>
                          <span className='editFieldLabel'>Promotion Price</span>
                          <input value={this.state.PromotionPriceS} name='PromotionPrice' onChange={evt => this.chgMoneyFldVal(evt)} onFocus={evt => this.focusMoneyFldVal(evt)} onBlur={evt => this.blurMoneyFldVal(evt)} className='editMoneyInput' />
                        </div>
                        <div id='div2' className='editField'>
                          <span className='editFieldLabel'>Active Promotion   </span>
                          <input type='checkbox' checked={this.state.PromotionIsActive} name='PromotionIsActive' onChange={evt => this.chgCheckboxVal(evt)} />
                        </div>
                      </div>
                    </div>
                    <div className="editTabMiddle">
                      <div id="div1" className="editInside">
                        <div className="framePromoPic">
                          <Avatar rounded='large' size='large' type='image' className='avatarPromoPic'>
                            <img src={this.state.PromotionPhotoURL} alt="" height={200} width={400} style={{ objectFit: 'fill',}} />
                          </Avatar>
                        </div>
                        <div className="framePromoPicUpload">
                          {/*<ExternalDropZone*/}
                          {/*  uploadRef={uploadPromoRef}*/}
                          {/*  customHint={<span>Drop the Promotion Image Here</span>}*/}
                          {/*  customNote={<span>The Image should have an aspect ratio of 4:3</span>}*/}
                          {/*  style={{backgroundColor: "antiquewhite", height: "10px", width: "400px"}}*/}
                          {/*/>*/}
                          <div style={{ position: "relative",top: "0px",width: "400px",border: "solid 2px grey" }}>
                            <Upload
                              ref={uploadPromoRef}
                              batch={false}
                              multiple={true}
                              files={this.state.files}
                              onAdd={this.addPromoImage}
                              onRemove={this.onRemove}
                              onStatusChange={this.onStatusChange}
                              saveUrl={null}
                              defaultFiles={[]}
                              withCredentials={false}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="editTabRightNarrow">
                      <div id="div1" className="editInside">
                        <h4>Promotion Period</h4>
                        <div id='div2' className='editField'>
                          <span className='editFieldLabel'>Start Date/Time</span>
                          <div className='editDDPos'>
                            <DateTimePicker value={new Date(this.state.StartDateTime)} name='StartDateTime' onChange={evt => this.chgFldVal(evt)} className='editDateInput' readOnly={false} />
                          </div>
                        </div>
                        <div id='div2' className='editField'>
                          <span className='editFieldLabel'>Days For Discount</span>
                          <input value={this.state.DaysForDiscount} name='DaysForDiscount' onChange={evt => this.chgFldVal(evt)} onBlur={evt => this.blurDaysForDiscount(evt)} className='editNumShortInput' />
                        </div>
                        <div id='div2' className='editField'>
                          <span className='editFieldLabel'>End Date/Time</span>
                          <div className='editDDPos'>
                            <DateTimePicker value={new Date(this.state.EndDateTime)} name='EndDateTime' onChange={evt => this.chgFldVal(evt)} onBlur={evt => this.blurEndDate(evt)} className='editDateInput' readOnly={false} />
                          </div>
                        </div>
                        <h4>Apply Promotion To</h4>
                        <br />
                        {/*<div id='div2' className='editField'>
                          <span className='editFieldLabel'>Store Group</span>
                          <div className='editDDPos'>
                            <DropDownList data={this.state.StoreGroupsDict} textField='ddName' dataItemKey='ddID' value={this.state.StoreGroupsDict.find(c => c.ddID === this.state.StoreGroupID)} name='StoreGroupID' onChange={evt => this.chgDDFldVal(evt)} className='editInputDD' />
                          </div>
                        </div>  */}
                        <div id='div2' className='editField'>
                          <span className='editFieldLabel'>Store Item</span>
                          <div className='editDDPos'>
                            <DropDownList data={this.state.StoreItemsDict} textField='ddName' dataItemKey='ddID' value={this.state.StoreItemsDict.find(c => c.ddID === this.state.StoreItemID)} name='StoreItemID' onChange={evt => this.chgDDFldVal(evt)} className='editInputDD' />
                          </div>
                        </div>
                        <div id='div2' className='editField'>
                          <span className='editFieldLabel'>Product Category</span>
                          <div className='editDDPos'>
                            <DropDownList data={this.state.ProductItemCategoriesDict} textField='ddName' dataItemKey='ddID' value={this.state.ProductItemCategoriesDict.find(c => c.ddID === this.state.ProductItemCategoryID)} name='ProductItemCategoryID' onChange={evt => this.chgDDFldVal(evt)} className='editInputDD' />
                          </div>
                        </div>
                        <div id='div2' className='editField'>
                          <span className='editFieldLabel'>Product Group</span>
                          <div className='editDDPos'>
                            <DropDownList data={this.state.ProductItemGroupsDict} textField='ddName' dataItemKey='ddID' value={this.state.ProductItemGroupsDict.find(c => c.ddID === this.state.ProductItemGroupID)} name='ProductItemGroupID' onChange={evt => this.chgDDFldVal(evt)} className='editInputDD' />
                          </div>
                        </div>
                        <div id='div2' className='editField'>
                          <span className='editFieldLabel'>Product Item Type</span>
                          <div className='editDDPos'>
                            <DropDownList data={this.state.ProductItemTypesDict} textField='ddName' dataItemKey='ddID' value={this.state.ProductItemTypesDict.find(c => c.ddID === this.state.ProductItemTypeID)} name='ProductItemTypeID' onChange={evt => this.chgDDFldVal(evt)} className='editInputDD' />
                          </div>
                        </div>
                        {/*<div className="editField">
                          <div className="buttonLeft">
                            <Tooltip position="top" anchorElement="pointer">
                              <Button icon="refresh" color="primary" title="Using the Current Promotion information - Create a Promotion" onClick={this.onCreatePromotion}>Create Promotion</Button>
                            </Tooltip>
                          </div>
                        </div>*/}
                        <h4>Reference Item</h4>
                        <br />
                        <div id='div2' className='editField'>
                          <span className='editFieldLabel'>Reference Item</span>
                          <div className='editDDPos'>
                            <DropDownList data={this.state.StoreItemsDict} textField='ddName' dataItemKey='ddID' value={this.state.StoreItemsDict.find(c => c.ddID === this.state.PromoStoreItemID)} name='PromoStoreItemID' onChange={evt => this.chgDDFldVal(evt)} className='editInputDD' />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> : null}
                {this.state.selectedTopTab === this.Tabs.HOURS ?
                  <div className="editTabLeft">
                    <div id="div1" className="editInside">
                      <h3>Store Location Image</h3>
                      <div className="framePromoPic">
                        <Avatar rounded='large' size='large' type='image' className='avatarPromoPic'>
                          <img src={this.state.StoreLocationPhotoURL} alt="" height={200} width={400} style={{ objectFit: 'fill',}} />
                        </Avatar>
                      </div>
                      <div className="framePromoPicUpload">
                        {/*<ExternalDropZone*/}
                        {/*  uploadRef={uploadRef}*/}
                        {/*  customHint={<span>Drop the Store Image Here</span>}*/}
                        {/*  customNote={<span>The Image should have an aspect ratio of 4:3</span>}*/}
                        {/*  style={{backgroundColor: "antiquewhite", height: "10px", width: "370px"}}*/}
                        {/*/>*/}
                        <div style={{ position: "relative",top: "0px",width: "400px",border: "solid 2px grey" }}>
                          <Upload
                            ref={uploadRef}
                            batch={false}
                            multiple={false}
                            files={this.state.files}
                            onAdd={this.addLocationImage}
                            onRemove={this.onRemove}
                            onStatusChange={this.onStatusChange}
                            saveUrl={null}
                            defaultFiles={[]}
                            withCredentials={false}
                          />
                        </div>
                      </div>
                    </div>
                  </div> : null}
                {this.state.selectedTopTab === this.Tabs.TableReservations ?
                  <div className="editPane">  {/* Management Dashboard */}
                    {/*<div className="editTabWholeLow">*/}
                    <ResSchedControl TaskData={this.state.ReservationData} />
                    {/*</div>*/}
                  </div> : null}
                {this.state.selectedTopTab === this.Tabs.MANAGE ?
                  <div className="editPane">  {/* Management Dashboard */}
                    <Chart>
                      <ChartTitle text="Sales by Period" />
                      <ChartCategoryAxis>
                        <ChartCategoryAxisItem title={{ text: 'Periods' }} categories={this.state.categories} />
                      </ChartCategoryAxis>
                      <ChartSeries>
                        <ChartSeriesItem name="Last 12 Periods" data={this.state.data1} color="#0f2df5" />
                      </ChartSeries>
                    </Chart>
                  </div> : null}
              </div>
            </Splitter>
            {/* Side Pane */}
            {/*----------------------- SIDE PANE -------------------------------------------------------------------------------------------------------------------------*/}
            <div id="splitterRight" className="splitPaneContent">
              {this.state.selectedTopTab === this.Tabs.ITEMS ?
                <div className="headerDiv">
                  <div id="div1" className="headerTitle">
                    <Tooltip position="top" anchorElement="pointer">
                      <div title="Click on any Item in the Grid to Copy the Item to the Current Store">
                        <h4>Company Main Item List</h4>
                      </div>
                    </Tooltip>
                    <h6>Click on Item to Copy</h6>
                  </div>
                  <br />
                  <div id="div1" className="headerGrid">
                    <TGrid style={{ position: 'absolute',top: 0,height: '100%' }} data={this.state.MainStoreItems.slice(this.state.mainStoreItemsSkip,this.state.mainStoreItemsTake + this.state.mainStoreItemsSkip)} selectedField="selected" sortable={{ allowUnsort: true,mode: 'multiple' }}
                      skip={this.state.mainStoreItemsSkip} take={this.state.mainStoreItemsTake} total={this.state.MainStoreItems.length} onPageChange={this.mainStoreItemsPageChange}
                      onRowClick={this.rowStoreItemClick} selectedField="selected" resizable={true} sort={this.state.sortMainStoreItems} onSortChange={(event) => { this.setState({ MainStoreItems: this.getProductItemsSort(event.sort),sortMainStoreItems: event.sort }); }}
                      pageable={{ info: true,type: 'numeric',pageSizes: true,previousNext: true }}
                      pageSize={18}>
                      <Column field="selected" width="0px" headerSelectionValue={this.state.MainStoreItems.findIndex(dataItem => dataItem.selected === false) === -1} />
                      <Column field="ItemName" title="Item Name" width='180px' />
                      <Column field="ItemDescription" title="Description" width='180px' />
                      <Column field="ItemPrice" title="Price" cell={MoneyCell} width='120px' />
                      <Column field="ProductItemCategoryID" title="Category" cell={ProductItemCategoryDropDownCell} width='110px' />
                    </TGrid>
                  </div>
                </div> : null}

              {this.state.selectedTopTab === this.Tabs.RECEIPTS ?
                <TGrid style={{ position: 'absolute',top: 0,height: '100%' }} data={this.state.CustomOrderItems.slice(this.state.customOrderItemsSkip,this.state.customOrderItemsTake + this.state.customOrderItemsSkip)} selectedField="selected" sortable={{ allowUnsort: true,mode: 'multiple' }}
                  skip={this.state.customOrderItemsSkip} take={this.state.customOrderItemsTake} total={this.state.CustomOrderItems.length} onPageChange={this.selectionCustomOrderItemsChange}
                  onRowClick={this.rowCustomOrderItemClick} selectedField="selected" resizable={true} sort={this.state.sortCustomOrderItems} onSortChange={(event) => { this.setState({ CustomOrderItems: this.getCustomOrderItemsSort(event.sort),customOrderItemsSort: event.sort }); }}
                  pageable={{ info: true,type: 'numeric',pageSizes: true,previousNext: true }}
                  pageSize={18}>
                  <Column field="selected" width="0px" headerSelectionValue={this.state.CustomOrderItems.findIndex(dataItem => dataItem.selected === false) === -1} />
                  <Column field="StoreItemID" title="Item Name" width='140px' />
                  <Column field="Quantity" title="Qty" width='60px' />
                  <Column field="TotalPrice" title="Price" cell={MoneyCell} width='120px' />
                </TGrid> : null}
            </div>
          </Splitter>
          {/* Table Location QR Code Popup */}
          <Popup offset={this.showLocQRPopupOffset} show={this.state.showQRCode} popupClass={'popupQRCode'}>
            <div className="wholeDiv" onClick={(e) => this.clearQRPopup()}>
              <div className="headerScan" onClick={(e) => this.clearQRPopup()}>
                <span style={{ fontSize: 18,color: "black",fontWeight: "bold",left: "22px",position: "absolute" }} >Location QR Code</span>
                <br />
                <br />
                <div style={{ fontSize: 16,color: "black",position: "relative",height: "20px" }}>
                  <span >Location</span>
                </div>
                <span style={{ fontSize: 18,color: "black",fontWeight: "bold" }} >&nbsp;{this.state.StoreName}</span>
                <br />
                <br />
                <div style={{ fontSize: 16,color: "black",position: "relative",height: "20px" }}>
                  <span >Table</span>
                </div>
                <span style={{ fontSize: 18,color: "black",fontWeight: "bold",left: "10px" }} >&nbsp;{this.state.TableName}</span>
              </div>
              <div style={{ position: "absolute",top: "125px",left: "25px" }} onClick={(e) => this.clearQRPopup()}>
                <QRCode
                  value={this.QRCodeLocation}
                  errorCorrection="M"
                />
              </div>
            </div>
          </Popup>
          {/* StoreItem QR Code Popup */}
          <Popup offset={this.showQRPopupOffset} show={this.state.showItemLocQRCode} popupClass={'popupQRCode'}>
            <div className="wholeDiv" onClick={(e) => this.clearLocQRPopup()}>
              <div className="headerScan" onClick={(e) => this.clearQRPopup()}>
                <span style={{ fontSize: 18,color: "black",fontWeight: "bold",left: "22px",position: "absolute" }} >Item QR Code</span>
                <br />
                <br />
                <div style={{ fontSize: 16,color: "black",position: "relative",height: "20px" }}>
                  <span >Item Barcode</span>
                </div>
                <span style={{ fontSize: 18,color: "black",fontWeight: "bold" }} >&nbsp;{this.QRCodeItem}</span>
                <br />
                <br />
                <div style={{ fontSize: 16,color: "black",position: "relative",height: "20px" }}>
                  <span >Name</span>
                </div>
                <span style={{ fontSize: 18,color: "black",fontWeight: "bold",left: "10px" }} >&nbsp;{this.state.ItemName}</span>
              </div>
              <div style={{ position: "absolute",top: "125px",left: "25px" }} onClick={(e) => this.clearLocQRPopup()}>
                <QRCode
                  value={this.QRCodeItem}
                  errorCorrection="M"
                />
              </div>
            </div>
          </Popup>
          <Popup offset={this.helpListPopupOffset} show={this.state.showHelpListPopup} popupClass={'popupHelp'} >
            <div id="helpBox">
              <div className="helpList">
                <ListView data={this.state.HelpPages} item={this.HelpPageFormat} header={this.HelpPageHeader} footer={this.HelpPageFooter} />
              </div>
              <br />
            </div>
          </Popup>
          <Popup offset={this.helpDetailOffset} show={this.state.showHelpDetail} popupClass={'popupHelpDetail'} onClick={this.closeHelpTime}>
            <div className="helpInfo" id="notifyBoxPU" dangerouslySetInnerHTML={{ __html: this.state.helpDetail }} onClick={this.closeHelpTime}></div>
          </Popup>
        </div >
        {SessionInfo.displayFooter === true ?
          <PageFooter L1='Home' IsApp='y' /> : null}
      </div >
    );
  }
}

export default withRouter(StoreLocations);