//----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// omAPI Login - InteractLite Logon  -   Version 1.005 - May 17, 2019
//----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './App.css';
import { Input } from "@progress/kendo-react-inputs";
import { Button } from '@progress/kendo-react-buttons';
import { FloatingLabel } from '@progress/kendo-react-labels'
import { LogOn,LogOff,UserRegister,AddressLogOn,GetTableFilter,setCookie,getCookie,MaskString,UnmaskString } from './CommonCode.js';
import { SessionInfo } from './App';

//-------------
//let LoginInProgress = false;
//const emailRegex = new RegExp(/\S+@\S+\.\S+/);
//const emailValidator = (value) =>
//  emailRegex.test(value) ? "" : "Please enter a valid email.";
//const LabelEmailInput = (fieldRenderProps) => {
//  const { validationMessage, visited, label, id, valid, ...others } =
//    fieldRenderProps;
//  const showValidationMessage = visited && validationMessage;
//  return (
//    <FieldWrapper>
//      <Label editorId={id} editorValid={valid}>
//        {label}
//      </Label>
//      <Input valid={valid} type={"email"} id={id} {...others} />
//      {!showValidationMessage && (
//        <Hint>Enter your personal email address.</Hint>
//      )}
//      {showValidationMessage && <Error>{validationMessage}</Error>}
//    </FieldWrapper>
//  );
//};
//const FloatingLabelEmailInput = (fieldRenderProps) => {
//  const { validationMessage, visited, label, id, valid, value, ...others } =
//    fieldRenderProps;
//  const showValidationMessage = visited && validationMessage;
//  return (
//    <FieldWrapper>
//      <FloatingLabel
//        label={label}
//        editorValue={value}
//        editorValid={valid}
//        editorId={id}
//      >
//        <Input value={value} valid={valid} type={"email"} id={id} {...others} />
//      </FloatingLabel>
//      {!showValidationMessage && (
//        <Hint>Enter your personal email address.</Hint>
//      )}
//      {showValidationMessage && <Error>{validationMessage}</Error>}
//    </FieldWrapper>
//  );
//};
            
function SignIn(props) {      
  SessionInfo.forceLogoff = false;
  const { classes } = props;
  const [isLogin, setShowLogin] = React.useState(true);
  const [showMsg, setShowMsg] = React.useState(false);
  const [loginMessage, setLoginMessage] = React.useState(true);
  const [companyCode,setCompanyCode] = React.useState('');
  const [emailAddress,setEmailAddress] = React.useState('');
  const [emailStyle,setEmailStyle] = React.useState('');
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [phoneNumber,setPhoneNumber] = React.useState('');
  const [phoneStyle,setPhoneStyle] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [confirmPassword, setConfirm] = React.useState('');
  const [showPassword, setShowPassword] = React.useState(false);
  const [stayLoggedOn, setStayLoggedOn] = React.useState(SessionInfo.stayLoggedOn);
  const [message, setMessage] = React.useState(false);
  const navigate = useNavigate();
  var LoginInProgress = false;

  const handleLogin = async (event) => {
    event.preventDefault();
    setShowMsg(false);
    setShowPassword(false);
    if (LoginInProgress)
      return;
    LoginInProgress = true;
    console.log(`logon companyCode: ${companyCode}, email: ${emailAddress}, password: ${password}`);
    let m = await LogOn(companyCode,emailAddress,password);
    LoginInProgress = false;
    //console.log("Sign in - m: " + m + " UserID: " + SessionInfo.OMUserID);
    if (m === 'ok') {
      clearMessage();
      if (!SessionInfo.StoreLocationID)
        SessionInfo.StoreLocationID = 1;
      showMessage(`Signed In: '${m}'`);
      //navigate("/AppMain");
      if (SessionInfo.gotoAutoPage === '') {
        if (SessionInfo.OrderModeType === 3)
          navigate("/ScannerPage");
        else
          navigate("/OrderPage");
      } else
        navigate(`/${SessionInfo.gotoAutoPage}`);
    }
    else if (m)
      setError(m);
    else if (emailAddress !== '')
      setWarning("Unable to Sign In");
  }
  const handleRegister = async (event) => { // Register
    SessionInfo.Email = emailAddress;
    SessionInfo.Password = password;
    if (confirmPassword === '') {
      confirmPassword = confirmPassword.value;
      SessionInfo.confirmPassword = confirmPassword;
    }
    console.log(`Email: ${emailAddress}, Password: ${password}, Confirm Password: ${confirmPassword}`);
    setShowMsg(false);
    setShowPassword(false);
    event.preventDefault();
    if (password !== confirmPassword) {
      showWarning("Passwords must match");
    }
    else if (password.length < 8) {
      showWarning("Password must be at least 8 characters");
    }
    else if (SessionInfo.registerCounter > 0)
      showWarning("You must wait to Register");
    else {
      console.log("----> Call Register");
      SessionInfo.registerCounter = 10;
      let m = await UserRegister(firstName,lastName,emailAddress,phoneNumber,password,confirmPassword,SessionInfo.registrationCode,"","STND"); // Register in CommonCode
      console.log("Return from UserRegister - m: " + m);
      SessionInfo.registerCounter = 0;
      if (m === 'ok') {
        showMessage(`Registration Successful - ID: ${SessionInfo.OMUserID}`);
        if (SessionInfo.OMUserID) {
          let CD = await GetTableFilter(1020151, "OMUserID=" + SessionInfo.OMUserID); // Get CustomerID 
          if (!CD.d.rows || !CD.d.rows.length) {
            showError(`Unable to Find Customer for the current User: ${SessionInfo.logonName}`);
            await LogOff(true);
            navigate("/AppMain"); // Note - that the componentWillUnmount will execute for the current page 
          }
          else {
            //console.log("Get User CD: " + JSON.stringify(CD));
            ////SessionInfo.CFParticipantID = CD.d.rows[0]["CFParticipantID"];
            ////SessionInfo.SelectedParticipantID = CD.d.rows[0]["CFParticipantID"];
            navigate("/OrderPage");
          }
        }
      }
      else if (m === 'pa') {
        showWarning("Passwords must match");
      }
      else if (m)
        showError(m);
      else
        showWarning("Register Error");
    }
  }
  //--
  const chgShowPW = (event) => {
    //event.preventDefault();
    //console.log(`start showPassword: ${showPassword}`);
    if (showPassword === true)
      setShowPassword(false);
    else
      setShowPassword(true);
    //console.log(`showPassword: ${showPassword}`);
  }
  const chgStayLO = (event) => {
    if (stayLoggedOn === true) {
      setStayLoggedOn(false);
      SessionInfo.stayLoggedOn = false;
    } else {
      setStayLoggedOn(true);
      SessionInfo.stayLoggedOn = true;
    }
  }
  const gotoRegister = (event) => {
    event.preventDefault();
    setShowLogin(false);
  }
  const gotoSignIn = (event) => {
    event.preventDefault();
    setShowLogin(true);
  }
  const showMessage = (msg) => {
    //console.log(`showMessage: ${msg}, showMsg: ${showMsg}`);
    setMessage(msg);
    setShowMsg(true);
    //console.log(`After: ${message}, showMsg: ${showMsg}`);
    //  sleep(3000);
    //  setShowMsg(false);
  }
  const showWarning = (msg) => {
    showMessage(`Warn: ${msg}`);
  }
  const showError = (msg) => {
    showMessage(`Error: ${msg}`);
  }
  const setWarning = (msg) => {
    setLoginMessage(msg);
  }
  const setError = (msg) => {
    setLoginMessage(msg);
  }
  //const setMessage = (msg) => {
  //  setLoginMessage(msg);
  //}
  const clearMessage = () => {
    setLoginMessage("");
  }
  const updateCompanyCode = (event) => {                                                 
    setCompanyCode(event.target.value);
  }
  const updateEmailAddress = (event) => {                     
    setEmailAddress(event.target.value);               
  }
  const blurEmail = (event) => {
    event.stopPropagation();
    console.log(`Email: ${emailAddress}`);
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(emailAddress)) {
      setEmailStyle({ border: "none", fontWeight: "normal" });
    } else {
      setEmailStyle({ border: "red 2px solid", fontWeight: "bold" });
      //EmailRef.current.focus()
    }
    console.log(`Email: ${emailAddress}`);
    SessionInfo.Email = emailAddress;
  }  
  //blurMaskFldVal(event,mask) {
  //  //console.log(`blur Mask: ${this.state[event.target.name]}`);
  //  this.setState({ [event.target.name]: UnmaskString(this.state[event.target.name],mask) });
  //  //console.log(`money target: ${this.state[event.target.name + 'S']}`);
  //}
  //chgFldValMask(event,mask) {
  //  //console.log(`chg masked value: ${event.target.value}, mask: ${mask}`);
  //  this.setState({ [event.target.name]: UnmaskString(event.target.value,mask) });
  //  //console.log(`chg masked - name: ${event.target.name}:${event.target.value}`);
  //  this.setState({ stateHasChanged: true });
  //}
  //chgFldVal(event) {
  //  this.setState({ [event.target.name]: event.target.value });
  //  //console.log(`${event.target.name}:${event.target.value}`);
  //  this.setState({ stateHasChanged: true });
  //}
  const updateFirstName = (event) => {
    setFirstName(event.target.value);
  }
  const updateLastName = (event) => {
    setLastName(event.target.value);
  }
  const updatePhoneNumber = (event) => {
    setPhoneNumber(event.target.value);
  }
  const blurPhone = (event) => {
    event.stopPropagation();
    console.log(`Email: ${emailAddress}`);
    console.log(`Phone: ${phoneNumber}`);
    if (phoneNumber.length >= 10) {
      setPhoneStyle({ border: "none", fontWeight: "normal" });
    } else {
      setPhoneStyle({ border: "red 2px solid", fontWeight: "bold" });
    }
    SessionInfo.PhoneNumber = phoneNumber;
  }
  const chgPassword = (event) => {
    setPassword(event.target.value);
  }
  const blurPassword = (event) => {
    event.preventDefault();
    console.log(`On Blur Password: ${password}`);
    SessionInfo.Password = password;
  }
  const chgConfirm = (event) => {
    setConfirm(event.target.value);
  }
  const blurconfirmPassword = (event) => {
    event.preventDefault();
    SessionInfo.confirmPassword = confirmPassword;
  }
  //const sleep = (delay) => {
  //  var start = new Date().getTime();
  //  while (new Date().getTime() < start + delay);
  //}

  return (
    <div id="Login" className="pageMain">
      {isLogin === true ?
        <div id="Login" className="pageLoginBorder">
          <div id="div1" className="editInside">
            <a id="headerLogo" href="http://www.omnovos.com" target="_blank" rel="noopener noreferrer" />
            <h2>{SessionInfo.appEntityName} Manager</h2>
            <label className="loginLabel">{loginMessage}</label>
            <br />
            {SessionInfo.hasCompanyCode ?
              <>
                <div id="div2" className="editField">
                  <label className="logLabel">Company Code</label><br />
                  <input name="CompanyCode" type="text" className="signinField" autoComplete="email" spellCheck={false} onChange={evt => updateCompanyCode(evt)} value={companyCode} />
                </div>
                <br />
              </> : null}
            <br/>
            <div id="div2" className="editField">
              <label className="logLabel">User Name</label><br />
              <input name="EmailAddress" type="text" className="signinField" autoComplete="email" spellCheck={false} onChange={evt => updateEmailAddress(evt)} value={emailAddress} />
            </div>
            <br />
            <br />
            <div id="div2" className="editField">
              <label className="logLabel">Password</label><br />
              {showPassword === false ?
                <input name="password" type="password" autoComplete="password" className="signinField" onChange={evt => chgPassword(evt)} value={password} />
                :
                <input name="password" autoComplete="password" className="signinField" onChange={evt => chgPassword(evt)} value={password} />}
            </div>
            <br />
            <div id="div2" className="pwField">
              <input type="checkbox" checked={showPassword} name="ShowPassword" onChange={chgShowPW} />
              <span className="editFieldLabel">&nbsp; Show Password</span>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <input type="checkbox" checked={stayLoggedOn} name="StayLoggedOn" onChange={chgStayLO} />
              <span className="editFieldLabel">&nbsp; Stay Logged On</span>
            </div>
            <Button className="submitButton" onClick={handleLogin}>SIGN IN</Button>
            <br />
            <br />
            {/*<label className="registerLabel">Not Registered yet?</label><br />
            <Button className="submitButton" onClick={gotoRegister}>REGISTER</Button>*/}
            <br />
            {/*<div className="logonBottom">
              <span >({SessionInfo.AppEntityID})</span>
            </div>*/}
          </div>
        </div>
        :
        <div id="Register" className="pageRegisterBorder">
          <div id="div1" className="editInside">
            <a id="headerLogo" href="http://www.omnovos.com" target="_blank" rel="noopener noreferrer" />
            <h2>Interact Register</h2>
            <div id="div2" className="editField">
              <FloatingLabel label="First Name *" style={{ width: '300', color: 'darkblue' }} editorId={'first-name'} editorValue={firstName}>
                <Input id={'first-name'} value={firstName} style={{ width: 210 }} onChange={evt => updateFirstName(evt)} />
              </FloatingLabel>
            </div>
            <br />
            <br />
            <div id="div2" className="editField">
              <FloatingLabel label="Last Name *" style={{ width: '300', color: 'darkblue' }} editorId={'last-name'} editorValue={lastName}>
                <Input id={'last-name'} value={lastName} style={{ width: 210 }} onChange={evt => updateLastName(evt)} />
              </FloatingLabel>
            </div>
            <br />
            <br />
            <div id="div2" className="editField">
              <FloatingLabel label="User Name *" style={{ width: '300', color: 'darkblue' }} editorId={'email-address'} editorValue={emailAddress}>
                <Input id={'email-address'} value={emailAddress} style={{ width: 210 }} onChange={evt => updateEmailAddress(evt)} />
              </FloatingLabel>
            </div>
            <br />
            <br />
            <div id="div2" className="editField">
              <FloatingLabel label="Phone Number *" style={{ width: '300', color: 'darkblue' }} editorId={'phone-number'} editorValue={phoneNumber}>
                <Input id={'phone-number'} value={phoneNumber} style={{ width: 210 }} onChange={evt => updatePhoneNumber(evt)} />
              </FloatingLabel>
            </div>
            <br />
            <br />
            <div id="div2" className="editField">
              <FloatingLabel label="Password *" style={{ width: '300', color: 'darkblue' }} editorId={'password'} editorValue={password}>
                {showPassword === false ?
                  <Input id={'password'} value={password} style={{ width: 210 }} type="password" autoComplete="false" onChange={evt => chgPassword(evt)} />
                  :
                  <Input id={'password'} value={password} style={{ width: 210 }} autoComplete="false" onChange={evt => chgPassword(evt)} />}
              </FloatingLabel>
            </div>
            <br />
            <div id="div2" className="pwField">
              <input type="checkbox" checked={showPassword} name="ShowPassword" onChange={chgShowPW} />
              <span className="editFieldLabel">&nbsp; Show Password</span>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <input type="checkbox" checked={stayLoggedOn} name="StayLoggedOn" onChange={chgStayLO} />
              <span className="editFieldLabel">&nbsp; Stay Logged On</span>
            </div>
            Your Password must be 8 or more characters    With at least one Capital, one Number and one Symbol
            <br />
            <br />
            <div id="div2" className="editField">
              <FloatingLabel label="Confirm Password" style={{ width: '300', color: 'darkblue' }} editorId={'confirmPassword'} editorValue={confirmPassword}>
                {showPassword === false ?
                  <Input id={'confirmPassword'} value={confirmPassword} style={{ width: 210 }} type="password" autoComplete="false" onChange={evt => chgConfirm(evt)} />
                  :
                  <Input id={'confirmPassword'} value={confirmPassword} style={{ width: 210 }} autoComplete="false" onChange={evt => chgConfirm(evt)} />}
              </FloatingLabel>
            </div>
            <br />
            <br />
            <br />
            <Button className="submitButton" onClick={handleRegister}>REGISTER</Button>
            <br />
            <br />
            <label className="registerLabel">Already Registered? Go to Sign In</label><br />
            <Button className="submitButton" onClick={gotoSignIn}>SIGN IN</Button>
          </div>
        </div>
      }
    </div >
  );
}

export default (SignIn);