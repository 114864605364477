//----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// CommonCode - Code to support working with React in conjunction with Telerik Controls and the omUIapi and general helper functions
//              Version 1.041- July 29, 2022
// Note - this is the Checkout Version of CommonFormat
//----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
import React, { Component } from 'react'; 
import { SessionInfo } from "./App";
import { GridCell } from '@progress/kendo-react-grid';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { DateInput, DatePicker, DateTimePicker, TimePicker } from '@progress/kendo-react-dateinputs';
import { Button } from '@progress/kendo-react-buttons';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { orderBy, filterBy } from '@progress/kendo-data-query';
import { formatNumber } from '@telerik/kendo-intl';
import { NumericTextBox } from '@progress/kendo-react-inputs';
//----------------------- Format Classes - Version 1.00 -------------------------------------------------------------------------------------------------------------------------
//------ Encoded Values - DaysOfWeek ------
export const DaysOfWeekFixedValues = [{ n: 'Monday', k: 1 }, { n: 'Tuesday', k: 2 }, { n: 'Wednesday', k: 3 }, { n: 'Thursday', k: 4 }, { n: 'Friday', k: 5 }, { n: 'Saturday', k: 6 }, { n: 'Sunday', k: 7 }];
//------ Encoded Entries - DaysOfWeek ------
export const DaysOfWeekList = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
export const Months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]; 
//------ Supported Fonts -------------------------------------------------
export const FontTypeValues = [{ n: 'Arial Unicode MS',k: 1 },{ n: 'Lucida Calligraphy',k: 2 },{ n: 'Verdana',k: 3 },{ n: 'Times New Roman',k: 4 },{ n: 'Franklin Gothic Medium',k: 5 },{ n: 'Brush Script MT',k: 6 },{ n: 'Lucida',k: 7 },{ n: 'Old English Text MT',k: 8 },{ n: 'Segoe Print',k: 9 },{ n: 'Rage',k: 10 },{ n: 'Trebuchet MS',k: 11 },{ n: 'Candra',k: 12 },{ n: 'Gadugi',k: 13 },{ n: 'Jokerman',k: 14 },{ n: 'Marlett',k: 15 },{ n: 'Ravie',k: 16 },{ n: 'Tahoma',k: 17 },{ n: 'Lexend',k:18}];
export const FontSizeValues = [{ n: '10px',k: 1 },{ n: '12px',k: 2 },{ n: '14px',k: 3 },{ n: '16px',k: 4 },{ n: '18px',k: 5 },{ n: '20px',k: 6 },{ n: '22px',k: 7 },{ n: '24px',k: 8 }];
export const FontWhtValues = [{ n: '100',k: 1 },{ n: '200',k: 2 },{ n: '300',k: 3 },{ n: '400',k: 4 },{ n: '500',k: 5 },{ n: '600',k: 6 },{ n: '700',k: 7 },{ n: '800',k: 8 },{ n: '900',k: 9 }];
//---- Drop Down for SeasonCodeID in Table SeasonCodes --------
export function SetSeasonCodes(DD) { SeasonCodesDD = DD; }
export var SeasonCodesDD = [{ ddName: '',ddID: 0 }];
export class SeasonCodeDropDownCell extends React.Component {
  handleChange = (e) => {
    this.props.onChange({ dataItem: this.props.dataItem,field: this.props.field,syntheticEvent: e.syntheticEvent,value: e.target.value });
    this.props.dataItem[this.props.field] = e.target.value.ddID;
    this.forceUpdate();
  }
  render() {
    let dataValue = this.props.dataItem[this.props.field];
    if (!dataValue)
      dataValue = 0;
    if (!this.props.dataItem.inEdit) {
      if (dataValue === 0)
        return (<td>{' '}</td>);
      else if (SeasonCodesDD.find(c => c.ddID === dataValue) === undefined)
        return (
          <td>{`Unknown: ${dataValue}`}</td>
        );
      else
        return (
          <td>
            {SeasonCodesDD.find(c => c.ddID === dataValue).ddName}
          </td>
        );
    }
    return (
      <td>
        <DropDownList style={{ width: '149px' }} data={SeasonCodesDD} textField='ddName' dataItemKey='ddID' value={SeasonCodesDD.find(c => c.ddID === dataValue)} onChange={this.handleChange} />
      </td>
    );
  }
}
//---- Drop Down for ServiceType in Dictionary Values List --------
export function SetServiceTypes(DD) { ServiceTypeDD = DD; }
export var ServiceTypeDD = [{ ddName: '',ddID: 0 }];
export class ServiceTypeDropDownCell extends React.Component {
  handleChange = (e) => {
    this.props.onChange({ dataItem: this.props.dataItem,field: this.props.field,syntheticEvent: e.syntheticEvent,value: e.target.value });
    this.props.dataItem[this.props.field] = e.target.value.ddID;
    this.forceUpdate();
  }
  render() {
    let dataValue = this.props.dataItem[this.props.field];
    if (!dataValue)
      dataValue = 0;
    if (!this.props.dataItem.inEdit) {
      if (dataValue === 0)
        return (<td>{' '}</td>);
      else if (ServiceTypeDD.find(c => c.ddID === dataValue) === undefined)
        return (
          <td>{`Unknown: ${dataValue}`}</td>
        );
      else
        return (
          <td>
            {ServiceTypeDD.find(c => c.ddID === dataValue).ddName}
          </td>
        );
    }
    return (
      <td>
        <DropDownList style={{ width: '149px' }} data={ServiceTypeDD} textField='ddName' dataItemKey='ddID' value={ServiceTypeDD.find(c => c.ddID === dataValue)} onChange={this.handleChange} />
      </td>
    );
  }
}
//---- Drop Down for DayOfWeekID ----
export var DayOfWeekDD = [{ ddName: '', ddID: 0 }];
export class DayOfWeekDropDownCell extends React.Component {
  handleChange = (e) => { // e.target.value is the object {ddID: n, ddName: 'n'}
    //console.log("change - field: " + this.props.field + "  value: " + JSON.stringify(e.target.value) + " dataitem: " + JSON.stringify(this.props.dataItem));
    this.props.onChange({
      dataItem: this.props.dataItem,
      field: this.props.field,
      syntheticEvent: e.syntheticEvent,
      value: e.target.value
    });
    //console.log("change data before: " + JSON.stringify(this.props.dataItem[this.props.field]));
    this.props.dataItem[this.props.field] = e.target.value.ddID;
    //console.log("change data after: " + JSON.stringify(this.props.dataItem[this.props.field]));
    this.forceUpdate();
  }
  render() {
    let dataValue = this.props.dataItem[this.props.field];
    if (!dataValue)
      dataValue = 0;                                            
    //console.log("Val: " + dataValue + " DayOfWeek Cell: " + JSON.stringify(DayOfWeekDD));
    if (!this.props.dataItem.inEdit) {
      if (dataValue === 0)
        return (<td>{" "}</td>);
      else if (DayOfWeekDD.find(c => c.ddID === dataValue) === undefined)
        return (
          <td>{"Unknown: " + dataValue}</td>
        );
      else
        return (
          <td>
            {DayOfWeekDD.find(c => c.ddID === dataValue).ddName}
          </td>
        );
    }
    return (
      <td>
        <DropDownList style={{ width: "100px" }} data={DayOfWeekDD} textField="ddName" dataItemKey="ddID" value={DayOfWeekDD.find(c => c.ddID === dataValue)} onChange={this.handleChange} />
      </td>
    );
  }
}
export function SetDayOfWeek(DD) {
  DayOfWeekDD = DD;
  //console.log(`--> SET DayOfWeekDD: ${JSON.stringify(DayOfWeekDD)}`);
}
export function SetTimeOverrideTypes(DD) {
  TimeOverrideTypeDD = DD;
}
export var TimeOverrideTypeDD = [{ ddName: '', ddID: 0 }];
export class TimeOverrideDropDownCell extends React.Component {
  handleChange = (e) => { // e.target.value is the object {ddID: n, ddName: 'n'}
    //console.log("change - field: " + this.props.field + "  value: " + JSON.stringify(e.target.value) + " dataitem: " + JSON.stringify(this.props.dataItem));
    this.props.onChange({
      dataItem: this.props.dataItem,
      field: this.props.field,
      syntheticEvent: e.syntheticEvent,
      value: e.target.value
    });
    //console.log("change data before: " + JSON.stringify(this.props.dataItem[this.props.field]));
    this.props.dataItem[this.props.field] = e.target.value.ddID;
    //console.log("change data after: " + JSON.stringify(this.props.dataItem[this.props.field]));
    this.forceUpdate();
  }
  render() {
    let dataValue = this.props.dataItem[this.props.field];
    if (!dataValue)
      dataValue = 0;
    if (!this.props.dataItem.inEdit) {
      if (dataValue === 0)
        return (<td>{"No Override "}</td>);
      else if (TimeOverrideTypeDD.find(c => c.ddID === dataValue) === undefined)
        return (
          <td>{"Unknown: " + dataValue}</td>
        );
      else
        return (
          <td>
            {TimeOverrideTypeDD.find(c => c.ddID === dataValue).ddName}
          </td>
        );
    }
    return (
      <td>
        <DropDownList style={{ width: "100px" }} data={TimeOverrideTypeDD} textField="ddName" dataItemKey="ddID" value={TimeOverrideTypeDD.find(c => c.ddID === dataValue)} onChange={this.handleChange} />
      </td>
    );
  }
}
export const PercentCell = (props) => {
  let value = props.dataItem[props.field];
  const handleChange = (e) => {
    props.onChange({
      dataItem: props.dataItem,
      field: props.field,
      syntheticEvent: e.syntheticEvent,
      value: e.target.value
    });
  }
  if (props.dataItem.inEdit) {
    return (
      <td>
        <NumericTextBox value={value} format={"p1"} onChange={handleChange} step={0.01} />
      </td>
    )
  } else {
    return (
      <td>
        {formatNumber(value, "p1")}
      </td>
    )
  }
}
export const MoneyCell = (props) => {
  let value = props.dataItem[props.field];
  const handleChange = (e) => {
    props.onChange({
      dataItem: props.dataItem,
      field: props.field,
      syntheticEvent: e.syntheticEvent,
      value: e.target.value
    });
  }
  if (props.dataItem.inEdit) {
    return (
      <td>
        <NumericTextBox value={value} format={"c2"} onChange={handleChange} />
      </td>
    )
  } else {
    return (
      <td>
        {formatNumber(value, "c2")}
      </td>
    )
  }
}
export const BoolCell = (props) => {
  let value = props.dataItem[props.field];
  value = (value === true || value === 'true'); 
  const handleChange = (e) => {
    //console.log("checkbox: " + value + " e.target.val: " + e.target.value + " inEdit: " + props.dataItem.inEdit);
    props.onChange({dataItem: props.dataItem,field: props.field,syntheticEvent: e.syntheticEvent,value: !value}); //e.target.value
  }
  if (props.dataItem.inEdit) {
    return (
      <td>
        <input type="checkbox" checked={value} onChange={handleChange} />
      </td>
    )
  } else {
    return (
      <td>
        <input disabled type="checkbox" checked={value} />
      </td>
    )
  }
}
// Used in one grid 
export const cellBool = (props) => {
  let value = props.dataItem[props.field];
  value = (value === true || value === 'true');
  //console.log(`field: ${props.field}, value: ${value}, fldval: ${props.dataItem[props.field]}`);
  const handleChange = (e) => {
    console.log("checkbox: " + value + " e.target.val: " + e.target.value + " inEdit: " + props.dataItem.inEdit);
    props.onChange({
      dataItem: props.dataItem,
      field: props.field,
      syntheticEvent: e.syntheticEvent,
      value: !value //e.target.value
    });
  }
  return (
    <td>
      <input type="checkbox" checked={value} onChange={handleChange} />
    </td>
  )
}
export const ImageCell = (props) => {
  //console.log("ImageCell - " + props.field);
  let value = props.dataItem[props.field];
  if (value === undefined) { // This is coke - need to figure out blank
    //value = "/9j/4AAQSkZJRgABAQEAYABgAAD/2wBDAAgGBgcGBQgHBwcJCQgKDBQNDAsLDBkSEw8UHRofHh0aHBwgJC4nICIsIxwcKDcpLDAxNDQ0Hyc5PTgyPC4zNDL/2wBDAQkJCQwLDBgNDRgyIRwhMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjL/wAARCAAZABkDASIAAhEBAxEB/8QAHwAAAQUBAQEBAQEAAAAAAAAAAAECAwQFBgcICQoL/8QAtRAAAgEDAwIEAwUFBAQAAAF9AQIDAAQRBRIhMUEGE1FhByJxFDKBkaEII0KxwRVS0fAkM2JyggkKFhcYGRolJicoKSo0NTY3ODk6Q0RFRkdISUpTVFVWV1hZWmNkZWZnaGlqc3R1dnd4eXqDhIWGh4iJipKTlJWWl5iZmqKjpKWmp6ipqrKztLW2t7i5usLDxMXGx8jJytLT1NXW19jZ2uHi4+Tl5ufo6erx8vP09fb3+Pn6/8QAHwEAAwEBAQEBAQEBAQAAAAAAAAECAwQFBgcICQoL/8QAtREAAgECBAQDBAcFBAQAAQJ3AAECAxEEBSExBhJBUQdhcRMiMoEIFEKRobHBCSMzUvAVYnLRChYkNOEl8RcYGRomJygpKjU2Nzg5OkNERUZHSElKU1RVVldYWVpjZGVmZ2hpanN0dXZ3eHl6goOEhYaHiImKkpOUlZaXmJmaoqOkpaanqKmqsrO0tba3uLm6wsPExcbHyMnK0tPU1dbX2Nna4uPk5ebn6Onq8vP09fb3+Pn6/9oADAMBAAIRAxEAPwD3+iivD9Q1zxqLi4nGqTQwGV3RUUMAu4gKAAT7DnsfSs6lRQ3R24PBSxTkoySt3/Q9woqCzleext5pAA8kaswHYkZqetDias7BXzbq11ZWqymz1u/uJCWHl/Z/LAwSMFix6biOB3PSvpKvCNb/AOQzdf8AX6//AKE1YV4cyR6+U4v6vKd1e68v8n+Fj2rSGL6LYM3VreMn/vkVdpsf+rX6CnVujyZO7uf/2Q==";
    value = "/9j/4AAQSkZJRgABAQEAYABgAAD/2wBDAAgGBgcGBQgHBwcJCQgKDBQNDAsLDBkSEw8UHRofHh0aHBwgJC4nICIsIxwcKDcpLDAxNDQ0Hyc5PTgyPC4zNDL/2wBDAQkJCQwLDBgNDRgyIRwhMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjL/wAARCAAZABkDASIAAhEBAxEB/8QAHwAAAQUBAQEBAQEAAAAAAAAAAAECAwQFBgcICQoL/8QAtRAAAgEDAwIEAwUFBAQAAAF9AQIDAAQRBRIhMUEGE1FhByJxFDKBkaEII0KxwRVS0fAkM2JyggkKFhcYGRolJicoKSo0NTY3ODk6Q0RFRkdISUpTVFVWV1hZWmNkZWZnaGlqc3R1dnd4eXqDhIWGh4iJipKTlJWWl5iZmqKjpKWmp6ipqrKztLW2t7i5usLDxMXGx8jJytLT1NXW19jZ2uHi4+Tl5ufo6erx8vP09fb3+Pn6/8QAHwEAAwEBAQEBAQEBAQAAAAAAAAECAwQFBgcICQoL/8QAtREAAgECBAQDBAcFBAQAAQJ3AAECAxEEBSExBhJBUQdhcRMiMoEIFEKRobHBCSMzUvAVYnLRChYkNOEl8RcYGRomJygpKjU2Nzg5OkNERUZHSElKU1RVVldYWVpjZGVmZ2hpanN0dXZ3eHl6goOEhYaHiImKkpOUlZaXmJmaoqOkpaanqKmqsrO0tba3uLm6wsPExcbHyMnK0tPU1dbX2Nna4uPk5ebn6Onq8vP09fb3+Pn6/9oADAMBAAIRAxEAPwD3+iiigAooooAKKKKACiiigD//2Q==";
    //console.log("Value replaced");
  }
  //console.log("ImageCell value: " + value);
  const byteCharacters = window.atob(value);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  let image = new Blob([byteArray], { type: 'image/jpeg' });
  //console.log("image: " + JSON.stringify(image));
  value = URL.createObjectURL(image);
  //console.log("ImageCell 2-value: " + value);

  const handleChange = (e) => {
    //console.log("imagecell: " + value + " e.target.val: " + e.target.value);
    props.onChange({
      dataItem: props.dataItem,
      field: props.field,
      syntheticEvent: e.syntheticEvent,
      value: e.target.value
    });
  }
  if (props.dataItem.inEdit) {
    return (
      <td>
        {/* <Avatar shape='circle' type='img'>*/}
        <img src={value} onChange={handleChange} />
      </td>
    )
  } else {
    return (
      <td>
        <img disabled src={value} />
      </td>
    )
  }
}

//export const BoolCell = (props) => {
//  let value = props.dataItem[props.field];
//  value = (value === true || value === 'true');
//  const handleChange = (e) => {
//    //console.log("checkbox: " + value + " e.target.val: " + e.target.value + " inEdit: " + props.dataItem.inEdit);
//    props.onChange({ dataItem: props.dataItem, field: props.field, syntheticEvent: e.syntheticEvent, value: !value }); //e.target.value
//  }
//  if (props.dataItem.inEdit) {
//    return (
//      <td>
//        <input type="checkbox" checked={value} onChange={handleChange} />
//      </td>
//    )
//  } else {
//    return (
//      <td>
//        <input disabled type="checkbox" checked={value} />
//      </td>
//    )
//  }
//}
//export class cellDateTime extends React.Component {
//  render() {
//    let value = props.dataItem[props.field];
//    const chgFldVal = (event) => {
//      var stateCopy = Object.assign({}, this.state[event.target.name]);
//      stateCopy = event.target.value;
//      this.setState({ [event.target.name]: stateCopy });
//      this.setState({ itemStateHasChanged: true });
//    }
//    const minDate = new Date(2018, 9, 9);
//    if (props.dataItem.inEdit) {
//      return (
//        <td>
//          <input type="checkbox" checked={value} onChange={handleChange} />
//          <DateTimePicker value={value} onChange={handleChange} className="editDateInput" />
//        </td>
//      )
//    } else {
//      return (
//        <td >
//          {!this.props.dataItem[this.props.field] || Date.parse(this.props.dataItem[this.props.field]) < minDate ? "" : new Intl.DateTimeFormat('en-CA', { dateStyle: 'long', timeStyle: 'short' }).format(Date.parse(this.props.dataItem[this.props.field]))}
//        </td>
//      );
//    }
//  }
//}

export const TimePickerCell = (props) => {
  // NOTE - 10Feb23 - see: saveTimeOfDay in ManagerPage - the value is being save as Date converted to local time and will be converted to GMT/UTC which adjusts the time
  let value = props.dataItem[props.field]
  //console.log("Field: " + props.field + " value: " + value);
  if (!value)
    value = new Date("0001-01-01T08:00:00");
  //console.log(" Field: " + props.field + " date value: " + new Date(value.toString()));
  const handleChange = (e) => {
    let dvalue = e.target.value;
    console.log(` Field: ${props.field} dvalue: ${dvalue}`);
    //let svalue = dvalue.toString();
    ////let ssvalue = svalue.substring(0, 24) + ".000Z";
    //let ssvalue = new Date(svalue).toUTCString();
    //let uvalue = new Date(e.target.value).toUTCString();
    //let adgTime = new Date(uvalue).getTime() - SessionInfo.timeOffset * 60000;
    //console.log(` svalue: ${svalue} ssvalue: ${ssvalue} utcTime: ${uvalue} adgTime: ${adgTime}`);
    ////adjTime = new Date(adgTime);
    ////console.log(` adgTime: ${adgTime}`);
    //let ndvalue = new Date(adgTime);
    //console.log(` ndvalue: ${ndvalue}`);
    props.onChange({
      dataItem: props.dataItem,
      field: props.field,
      syntheticEvent: e.syntheticEvent,
      value: dvalue   //.toLocalDateString()
    });
  }
  if (props.dataItem.inEdit) {
    return (
      <td>
        <TimePicker value={new Date(value)} onChange={handleChange} />
      </td>
    )
  } else {
    return (
      <td>
        <TimePicker value={new Date(value)} disabled={true} />
      </td>
    )
  }
}
export const addHours = (date, hours) => {
  date.setTime(date.getTime() + hours * 60 * 60 * 1000);
  return date;
}
//export const TimePickerCell = (props) => {
//  let value = props.dataItem[props.field]
//  console.log(` Time Field: ${props.field} value: ${value}`);
//  if (!value) {
//    value = new Date("0001-01-01T00:00:00"); // Note - converted to long
//    console.log(` Time Init Field: ${props.field} date value: ${value}`);
//  }
//  //console.log(" Field: " + props.field + " date value: " + new Date(value.toString()));
//  const handleChange = (e) => {
//    props.onChange({ dataItem: props.dataItem, field: props.field, syntheticEvent: e.syntheticEvent, value: e.target.value });
//  }
//  if (props.dataItem.inEdit) {
//    return (
//      <td>
//        <TimePicker value={new Date(value)} onChange={handleChange} />
//      </td>
//    )
//  } else {
//    return (
//      <td>
//        <TimePicker value={new Date(value)} />
//      </td>
//    )
//  }
//}
//
//export const TimePickerCell = (props) => {
//  let value = props.dataItem[props.field];
//  if (!value)
//    value = "0001-01-01T08:00:00";
//  else {
//    if (value === "0:00:00")
//      value = "0001-01-01T0:00:01";
//    else
//      value = "0001-01-01T" + value;
//  }
//  console.log(` Field: ${props.field} date value: ${value}`);
//  value = new Date(value);
//  //if (isNan(value))
//  //  value = new Date("0001-01-01T0:00:01");
//  //value = new Date("0001-01-01T08:00:00");
//  //console.log(" Field: " + props.field + " date value: " + new Date(value.toString()));
//  const handleChange = (e) => {
//    props.onChange({ dataItem: props.dataItem, field: props.field, syntheticEvent: e.syntheticEvent, value: e.target.value }); // .toLocalDateString()
//    console.log(" Field: " + props.field + " value: " + e.target.value);
//  }
//  if (props.dataItem.inEdit) {
//    return (
//      <td>
//        <TimePicker value={value} onChange={handleChange} />
//      </td>
//    )
//  } else {
//    return (
//      <td>
//        <TimePicker value={value} />
//      </td>
//    )
//  }
//}

export const cellDateTime = (props) => {
  let value = props.dataItem[props.field];
  //console.log(`cellDateTime - field: ${props.field}: value: ${value}`)
  const handleChange = (e) => {
    //console.log("DateTime: " + value + " e.target.val: " + e.target.value + " inEdit: " + props.dataItem.inEdit);
    props.onChange({ dataItem: props.dataItem, field: props.field, syntheticEvent: e.syntheticEvent, value: e.target.value.toLocalDateString() }); 
  }
  const minDate = new Date(2018, 9, 9);
  if (props.dataItem.inEdit) {
    return (
      <td>
        <DateTimePicker value={Date.parse(value)} onChange={handleChange} />
      </td>
    )
  } else {
    return (
      <td >
        {!value || Date.parse(value) < minDate ? "" : new Intl.DateTimeFormat('en-CA', { dateStyle: 'long', timeStyle: 'short' }).format(Date.parse(value))}
      </td>
    )
  }
}
//export class cellDateTime extends React.Component {
//  render() {
//    const minDate = new Date(2018, 9, 9);
//    return (
//      <td >
//        {!this.props.dataItem[this.props.field] || Date.parse(this.props.dataItem[this.props.field]) < minDate ? "" : new Intl.DateTimeFormat('en-CA', { dateStyle: 'long', timeStyle: 'short' }).format(Date.parse(this.props.dataItem[this.props.field]))}
//      </td>
//    );
//  }
//}
export const cellDate = (props) => {
  let value = props.dataItem[props.field];
  //console.log(`cellDate - field: ${props.field}: value: ${value}`)
  //console.log(`cellDate - dataitem: ${JSON.stringify(props.dataItem)}`)
  const handleChange = (e) => {
    console.log("Date: " + e.value);
    console.log(e);
    props.onChange({ dataItem: props.dataItem, field: props.field, syntheticEvent: e.syntheticEvent, value: e.target.value });
  }
  const minDate = new Date(2018, 9, 9);
  if (props.dataItem.inEdit) {
    //console.log(`Edit Date: ${value}, date: ${Date.parse(value)}`);
    return (
      <td>
        <DatePicker value={new Date(value)} onChange={handleChange} />
      </td>
    )
  } else {
    return (
      <td >
        {!value || Date.parse(value) < minDate ? "" : new Intl.DateTimeFormat('en-CA', { dateStyle: 'long' }).format(Date.parse(value))}
      </td>
    )
  }
}
export const cellTime = (props) => {
  let value = props.dataItem[props.field];
  console.log(`cellTime - value: ${value} `);
  const minDate = new Date(2018, 9, 9);
  return (
    <td >
      {!props.dataItem[props.field] || Date.parse(value) < minDate ? "" : new Intl.DateTimeFormat('en-CA', { timeStyle: 'short' }).format(Date.parse(value))}
    </td>
  );
}
export class cellCurrency extends React.Component {
  render() {
    return (
      <td >
        {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(this.props.dataItem[this.props.field])}
      </td>
    );
  }
}
export class cellCurrencyAmtOwe extends React.Component {
  render() {
    const amountGreater = this.props.dataItem[this.props.field] > 0;
    const style = {
      backgroundColor: amountGreater ?
        "rgb(243, 23, 0, 0.30)" :
        "rgb(253, 253, 253, 0.15)"
    };
    //moneyFormat = (value) => new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
    return (
      <td style={style}>
        {new Intl.NumberFormat('en-US',{ style: 'currency',currency: 'USD' }).format(this.props.dataItem[this.props.field])}
      </td>
    );
  }
}
export class HourMinCell extends React.Component {
  render() {
    const timeVal = this.props.dataItem[this.props.field]
    return (
      <td >
        {timeVal == 0 ? "" : timeDisplayHM(timeVal)}
      </td>
    );
  }
}
export const timeDisplayHM = (timeVal) => {
  let hours = parseInt(timeVal / 3600);
  let rem = timeVal - hours * 3600;
  let minutes = parseInt(rem / 60);
  let seconds = rem - minutes * 60;
  let secStr;
  if (seconds < 10)
    secStr = `0${seconds}`;
  else
    secStr = `${seconds}`;
  let timeStr = "";
  if (hours === 0) {
    timeStr = `${minutes}:${secStr}`;
  } else {
    if (minutes < 10)
      timeStr = `${hours}:0${minutes}`;
    else
      timeStr = `${hours}:${minutes}`;
  }
  //console.log(`--> Time: ${timeStr}, hours: ${hours}, min: ${minutes}`);
  return timeStr;
}
export const timeDisplay = (timeVal) => {
  let hours = parseInt(timeVal / 3600);
  let rem = timeVal - hours * 3600;
  let minutes = parseInt(rem / 60);
  let seconds = rem - minutes * 60;
  let secStr;
  if (seconds < 10)
    secStr = `0${seconds}`;
  else
    secStr = `${seconds}`;
  let timeStr = "";
  if (hours === 0) {
    timeStr = `${minutes}:${secStr}`;
  } else {
    if (minutes < 10)
      timeStr = `${hours}:0${minutes}:${secStr}`;
    else
      timeStr = `${hours}:${minutes}:${secStr}`;
  }
  //console.log(`--> Time: ${timeStr}, hours: ${hours}, min: ${minutes}`);
  return timeStr;
}
export class cellCurrencyAmt extends React.Component {
  render() {
    const amountGreater = this.props.dataItem[this.props.field] > 20;
    const style = {
      backgroundColor: amountGreater ?
        "rgb(55, 180, 0,0.30)" :
        "rgb(253, 253, 253, 0.15)"
    };
    //moneyFormat = (value) => new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
    return (
      <td style={style}>
        {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(this.props.dataItem[this.props.field])}
      </td>
    );
  }
}
export class cellOptions extends React.Component {
  render() {
    const optionsNonZero = this.props.dataItem[this.props.field] > 0;
    const style = {
      backgroundColor: optionsNonZero ?
        "rgb(55, 180, 0,0.32)" :
        "white"
    };
    return (
      <td style={style}>
        {this.props.dataItem[this.props.field]}
      </td>
    );
  }
}
export class cellQuantity extends React.Component {
  render() {
    const optionsNonZero = this.props.dataItem[this.props.field] > 1;
    // #248f24
    const style = {
      backgroundColor: optionsNonZero ?
        "rgb(36, 143, 36,0.32)" :
        "white"
    };
    return (
      <td style={style}>
        {this.props.dataItem[this.props.field]}
      </td>
    );
  }
}
//--------------------- EDIT Classes ----------------------------------------------------------
export const EditGridCommandCell = props => {
  const { dataItem } = props;                                                          
  //console.log(`Edit CC: editField: ${props.editField}, keyField: ${props.keyField}, nameField: ${props.nameField}, nameFieldVal: ${dataItem[props.nameField]}`); //dataItem: ${JSON.stringify(dataItem)}`);
  const inEdit = dataItem[props.editField];
  const isNewItem = dataItem[props.keyField] === undefined;
  return inEdit ? (
    <td className="k-command-cell">
      <button className="k-button k-grid-save-command gridButton" onClick={() => (isNewItem ? props.add(dataItem) : props.update(dataItem))}>
        {isNewItem ? "Add" : "Update"}
      </button>
      <button className="k-button k-grid-cancel-command gridButton" onClick={() => (isNewItem ? props.discard(dataItem) : props.cancel(dataItem))}>
        {isNewItem ? "Discard" : "Cancel"}
      </button>
    </td>
  ) : (
      <td className="k-command-cell">
        <button className="k-primary k-button k-grid-edit-command gridButton" onClick={() => props.edit(dataItem)}>
          Edit
      </button>
        <button className="k-button k-grid-remove-command gridButton" onClick={() => window.confirm("Confirm Delete: '" + dataItem[props.nameField] + "'") && props.remove(dataItem)}>
          Remove
      </button>
      </td>
    );
};
export const DeleteGridCommandCell = props => {
  const { dataItem } = props;
  //console.log(`Edit CC: editField: ${props.editField}, keyField: ${props.keyField}, dataItem: ${JSON.stringify(dataItem)}`);
  const inEdit = dataItem[props.editField];
  const isNewItem = dataItem[props.keyField] === undefined;
  return (
    <td className="k-command-cell">
      <button className="k-button k-grid-remove-command gridButton" onClick={() => props.remove(dataItem)}>
        Remove
      </button>
    </td>
  );
};
//-----------------------------------------------------------------------------------------------
export var PricingTypesDD = [{ ddName: '', ddID: 0 }];
export class PricingTypeDropDownCell extends React.Component {
  handleChange = (e) => { // e.target.value is the object {ddID: n, ddName: 'n'}
    //console.log("change - field: " + this.props.field + "  value: " + JSON.stringify(e.target.value) + " dataitem: " + JSON.stringify(this.props.dataItem));
    this.props.onChange({
      dataItem: this.props.dataItem,
      field: this.props.field,
      syntheticEvent: e.syntheticEvent,
      value: e.target.value
    });
    //console.log("change data before: " + JSON.stringify(this.props.dataItem[this.props.field]));
    this.props.dataItem[this.props.field] = e.target.value.ddID;
    //console.log("change data after: " + JSON.stringify(this.props.dataItem[this.props.field]));
    this.forceUpdate();
  }
  render() {
    //console.log("PricingTypes Cell: " + JSON.stringify(PricingTypesDD));
    let dataValue = this.props.dataItem[this.props.field];
    if (!dataValue)
      dataValue = 0;
    if (!this.props.dataItem.inEdit) {
      if (dataValue === 0)
        return (<td>{" "}</td>);
      else if (PricingTypesDD.find(c => c.ddID === dataValue) === undefined)
        return (
          <td>{"Unknown: " + dataValue}</td>
        );
      else
        return (
          <td>
            {PricingTypesDD.find(c => c.ddID === dataValue).ddName}
          </td>
        );
    }
    return (
      <td>
        <DropDownList style={{ width: "100px" }} data={PricingTypesDD} textField="ddName" dataItemKey="ddID" value={PricingTypesDD.find(c => c.ddID === dataValue)} onChange={this.handleChange} />
      </td>
    );
  }
}
export function SetPricingType(DD) {
  PricingTypesDD = DD;
}
//                                                                    
//-----------------------------------------------------------------------------------------------
export var RateTypesDD = [{ ddName: '', ddID: 0 }];
export class RateTypeDropDownCell extends React.Component {
  handleChange = (e) => { // e.target.value is the object {ddID: n, ddName: 'n'}
    //console.log("change - field: " + this.props.field + "  value: " + JSON.stringify(e.target.value) + " dataitem: " + JSON.stringify(this.props.dataItem));
    this.props.onChange({
      dataItem: this.props.dataItem,
      field: this.props.field,
      syntheticEvent: e.syntheticEvent,
      value: e.target.value
    });
    //console.log("change data before: " + JSON.stringify(this.props.dataItem[this.props.field]));
    this.props.dataItem[this.props.field] = e.target.value.ddID;
    //console.log("change data after: " + JSON.stringify(this.props.dataItem[this.props.field]));
    this.forceUpdate();
  }
  render() {
    //console.log("RateTypes Cell: " + JSON.stringify(RateTypesDD));
    let dataValue = this.props.dataItem[this.props.field];
    if (!dataValue)
      dataValue = 0;
    if (!this.props.dataItem.inEdit) {
      if (dataValue === 0)
        return (<td>{" "}</td>);
      else if (RateTypesDD.find(c => c.ddID === dataValue) === undefined)
        return (
          <td>{"Unknown: " + dataValue}</td>
        );
      else
        return (
          <td>
            {RateTypesDD.find(c => c.ddID === dataValue).ddName}
          </td>
        );
    }
    return (
      <td>
        <DropDownList style={{ width: "100px" }} data={RateTypesDD} textField="ddName" dataItemKey="ddID" value={RateTypesDD.find(c => c.ddID === dataValue)} onChange={this.handleChange} />
      </td>
    );
  }
}
export function SetRateType(DD) {
  RateTypesDD = DD;
}
//---- Drop Down for TimeOfDayID ----
export var TimeOfDayDD = [{ ddName: '', ddID: 0 }];
export class TimeOfDayDropDownCell extends React.Component {
  handleChange = (e) => { // e.target.value is the object {ddID: n, ddName: 'n'}
    //console.log("change - field: " + this.props.field + "  value: " + JSON.stringify(e.target.value) + " dataitem: " + JSON.stringify(this.props.dataItem));
    this.props.onChange({
      dataItem: this.props.dataItem,
      field: this.props.field,
      syntheticEvent: e.syntheticEvent,
      value: e.target.value
    });
    //console.log("change data before: " + JSON.stringify(this.props.dataItem[this.props.field]));
    this.props.dataItem[this.props.field] = e.target.value.ddID;
    //console.log("change data after: " + JSON.stringify(this.props.dataItem[this.props.field]));
    this.forceUpdate();
  }
  render() {
    let dataValue = this.props.dataItem[this.props.field];
    if (!dataValue)
      dataValue = 0;
    if (!this.props.dataItem.inEdit) {
      //console.log(`Categories: ${JSON.stringify(TimeOfDayDD)}`);
      if (TimeOfDayDD.find(c => c.ddID === dataValue) === undefined)
        return (
          <td>{"Unknown: " + dataValue}</td>
        );
      else
        return (
          <td>
            {TimeOfDayDD.find(c => c.ddID === dataValue).ddName}
          </td>
        );
    }
    return (
      <td>
        <DropDownList style={{ width: "120px" }} data={TimeOfDayDD} textField="ddName" dataItemKey="ddID" value={TimeOfDayDD.find(c => c.ddID === dataValue)} onChange={this.handleChange} />
      </td>
    );
  }
}
export function SetTimeOfDay(DD) {
  TimeOfDayDD = DD;
  //console.log(`--> SET Categories: ${JSON.stringify(TimeOfDayDD)}`);
}
//---- Drop Down for ProductItemCategoryID ----
export var ProductItemCategorysDD = [{ ddName: '', ddID: 0 }];
export class ProductItemCategoryDropDownCell extends React.Component {
  handleChange = (e) => { // e.target.value is the object {ddID: n, ddName: 'n'}
    //console.log("change - field: " + this.props.field + "  value: " + JSON.stringify(e.target.value) + " dataitem: " + JSON.stringify(this.props.dataItem));
    this.props.onChange({
      dataItem: this.props.dataItem,
      field: this.props.field,
      syntheticEvent: e.syntheticEvent,
      value: e.target.value
    });
    //console.log("change data before: " + JSON.stringify(this.props.dataItem[this.props.field]));
    this.props.dataItem[this.props.field] = e.target.value.ddID;
    //console.log("change data after: " + JSON.stringify(this.props.dataItem[this.props.field]));
    this.forceUpdate();
  }
  render() {
    let dataValue = this.props.dataItem[this.props.field];
    if (!dataValue)
      dataValue = 0;
    if (!this.props.dataItem.inEdit) {
      //console.log(`Categories: ${JSON.stringify(ProductItemCategorysDD)}`);
      if (ProductItemCategorysDD.find(c => c.ddID === dataValue) === undefined)
        return (
          <td>{"Unknown: " + dataValue}</td>
        );
      else
        return (
          <td>
            {ProductItemCategorysDD.find(c => c.ddID === dataValue).ddName}
          </td>
        );
    }
    return (
      <td>
        <DropDownList style={{ width: "150px" }} data={ProductItemCategorysDD} textField="ddName" dataItemKey="ddID" value={ProductItemCategorysDD.find(c => c.ddID === dataValue)} onChange={this.handleChange} />
      </td>
    );
  }
}
export function SetProductItemCategory(DD) {
  ProductItemCategorysDD = DD;
  //console.log(`--> SET Categories: ${JSON.stringify(ProductItemCategorysDD)}`);
}
//---- Drop Down for ProductItemGroupID in Table ProductItemGroups --------
export function SetProductItemGroups(DD) { ProductItemGroupsDD = DD; }
export var ProductItemGroupssDD = [{ ddName: '',ddID: 0 }];
export class ProductItemGroupsDropDownCell extends React.Component {
  handleChange = (e) => {
    this.props.onChange({ dataItem: this.props.dataItem,field: this.props.field,syntheticEvent: e.syntheticEvent,value: e.target.value });
    this.props.dataItem[this.props.field] = e.target.value.ddID;
    this.forceUpdate();
  }
  render() {
    let dataValue = this.props.dataItem[this.props.field];
    if (!dataValue)
      dataValue = 0;
    if (!this.props.dataItem.inEdit) {
      if (dataValue === 0)
        return (<td>{' '}</td>);
      else if (ProductItemGroupsDD.find(c => c.ddID === dataValue) === undefined)
        return (
          <td>{`Unknown: ${dataValue}`}</td>
        );
      else
        return (
          <td>
            {ProductItemGroupsDD.find(c => c.ddID === dataValue).ddName}
          </td>
        );
    }
    return (
      <td>
        <DropDownList style={{ width: '149px' }} data={ProductItemGroupsDD} textField='ddName' dataItemKey='ddID' value={ProductItemGroupsDD.find(c => c.ddID === dataValue)} onChange={this.handleChange} />
      </td>
    );
  }
}
//---- Drop Down for ProductItemGroupsID ----
export var ProductItemGroupsDD = [{ ddName: '', ddID: 0 }];
export class ProductItemGroupDropDownCell extends React.Component {
  handleChange = (e) => { // e.target.value is the object {ddID: n, ddName: 'n'}
    //console.log("change - field: " + this.props.field + "  value: " + JSON.stringify(e.target.value) + " dataitem: " + JSON.stringify(this.props.dataItem));
    this.props.onChange({
      dataItem: this.props.dataItem,
      field: this.props.field,
      syntheticEvent: e.syntheticEvent,
      value: e.target.value
    });
    //console.log("change data before: " + JSON.stringify(this.props.dataItem[this.props.field]));
    this.props.dataItem[this.props.field] = e.target.value.ddID;
    //console.log("change data after: " + JSON.stringify(this.props.dataItem[this.props.field]));
    this.forceUpdate();
  }
  render() {
    let dataValue = this.props.dataItem[this.props.field];
    if (!dataValue)
      dataValue = 0;
    if (!this.props.dataItem.inEdit) {
      if (dataValue === 0)
        return (<td>{" "}</td>);
      else if (ProductItemGroupsDD.find(c => c.ddID === dataValue) === undefined)
        return (
          <td>{"Unknown: " + dataValue}</td>
        );
      else
        return (
          <td>
            {ProductItemGroupsDD.find(c => c.ddID === dataValue).ddName}
          </td>
        );
    }
    return (
      <td>
        <DropDownList style={{ width: "120px" }} data={ProductItemGroupsDD} textField="ddName" dataItemKey="ddID" value={ProductItemGroupsDD.find(c => c.ddID === dataValue)} onChange={this.handleChange} />
      </td>
    );
  }
}
export function SetProductItemGroup(DD) {
  ProductItemGroupsDD = DD;
}
//---- Drop Down for ProductTypesID ----
export var ProductTypesDD = [{ ddName: '', ddID: 0 }];
export class ProductTypeDropDownCell extends React.Component {
  handleChange = (e) => { // e.target.value is the object {ddID: n, ddName: 'n'}
    //console.log("change - field: " + this.props.field + "  value: " + JSON.stringify(e.target.value) + " dataitem: " + JSON.stringify(this.props.dataItem));
    this.props.onChange({
      dataItem: this.props.dataItem,
      field: this.props.field,
      syntheticEvent: e.syntheticEvent,
      value: e.target.value
    });
    //console.log("change data before: " + JSON.stringify(this.props.dataItem[this.props.field]));
    this.props.dataItem[this.props.field] = e.target.value.ddID;
    //console.log("change data after: " + JSON.stringify(this.props.dataItem[this.props.field]));
    this.forceUpdate();
  }
  render() {
    let dataValue = this.props.dataItem[this.props.field];
    if (!dataValue)
      dataValue = 0;
    if (!this.props.dataItem.inEdit) {
      if (dataValue === 0)
        return (<td>{" "}</td>);
      else if (ProductTypesDD.find(c => c.ddID === dataValue) === undefined)
        return (
          <td>{"Unknown: " + dataValue}</td>
        );
      else
        return (
          <td>
            {ProductTypesDD.find(c => c.ddID === dataValue).ddName}
          </td>
        );
    }
    return (
      <td>
        <DropDownList style={{ width: "120px" }} data={ProductTypesDD} textField="ddName" dataItemKey="ddID" value={ProductTypesDD.find(c => c.ddID === dataValue)} onChange={this.handleChange} />
      </td>
    );
  }
}
export function SetProductTypes(DD) {
  ProductTypesDD = DD;
}
//---- Drop Down for ItemAssociationTypesID ----
export var ItemAssociationTypesDD = [{ ddName: '', ddID: 0 }];
export class ItemAssociationTypeDropDownCell extends React.Component {
  handleChange = (e) => {
    this.props.onChange({
      dataItem: this.props.dataItem,
      field: this.props.field,
      syntheticEvent: e.syntheticEvent,
      value: e.target.value
    });
    this.props.dataItem[this.props.field] = e.target.value.ddID;
    this.forceUpdate();
  }
  render() {
    let dataValue = this.props.dataItem[this.props.field];
    if (!dataValue)
      dataValue = 0;
    if (!this.props.dataItem.inEdit) {
      if (dataValue === 0)
        return (<td>{" "}</td>);
      else if (ItemAssociationTypesDD.find(c => c.ddID === dataValue) === undefined)
        return (
          <td>{"Unknown: " + dataValue}</td>
        );
      else
        return (
          <td>
            {ItemAssociationTypesDD.find(c => c.ddID === dataValue).ddName}
          </td>
        );
    }
    return (
      <td>
        <DropDownList style={{ width: "160px" }} data={ItemAssociationTypesDD} textField="ddName" dataItemKey="ddID" value={ItemAssociationTypesDD.find(c => c.ddID === dataValue)} onChange={this.handleChange} />
      </td>
    );
  }
}
export function SetItemAssociationTypes(DD) {
  ItemAssociationTypesDD = DD;
}
//---- Drop Down for SublocationID in Table Sublocations --------
export var SublocationsDD = [{ ddName: '',ddID: 0 }];
export class SublocationDropDownCell extends React.Component {
  handleChange = (e) => {
    this.props.onChange({
      dataItem: this.props.dataItem,
      field: this.props.field,
      syntheticEvent: e.syntheticEvent,
      value: e.target.value
    });
    this.props.dataItem[this.props.field] = e.target.value.ddID;
    this.forceUpdate();
  }
  render() {
    let dataValue = this.props.dataItem[this.props.field];
    if (!dataValue)
      dataValue = 0;
    if (!this.props.dataItem.inEdit) {
      if (dataValue === 0)
        return (<td>{' '}</td>);
      else if (SublocationsDD.find(c => c.ddID === dataValue) === undefined)
        return (
          <td>{'Unknown: ' + dataValue}</td>
        );
      else
        return (
          <td>
            {SublocationsDD.find(c => c.ddID === dataValue).ddName}
          </td>
        );
    }
    return (
      <td>
        <DropDownList style={{ width: "160px" }} data={SublocationsDD} textField='ddName' dataItemKey='ddID' value={SublocationsDD.find(c => c.ddID === dataValue)} onChange={this.handleChange} />
      </td>
    );
  }
}
export function SetSublocations(DD) {
  SublocationsDD = DD;
}
//---------------------------------------------------------------------------------------------
export function GetMonth(monthN) {
  return Months[monthN];
}
export function GetDay(dayN) {
  if (DayOfWeekDD.find(c => c.ddID === dayN) === undefined)
    return 'Sunday';
  else
    return DayOfWeekDD.find(c => c.ddID === dayN).ddName;
}
//---------------------------------------------------------------------------------------------
export function SetNotificationTypes(DD) { NotificationTypesDD = DD; }
export var NotificationTypesDD = [{ ddName: '', ddID: 0 }];
export class NotificationTypeDropDownCell extends React.Component {
  handleChange = (e) => { // e.target.value is the object {ddID: n, ddName: 'n'}
    //console.log("change - field: " + this.props.field + "  value: " + JSON.stringify(e.target.value) + " dataitem: " + JSON.stringify(this.props.dataItem)); 
    this.props.onChange({ dataItem: this.props.dataItem, field: this.props.field, syntheticEvent: e.syntheticEvent, value: e.target.value });
    //console.log("change data before: " + JSON.stringify(this.props.dataItem[this.props.field]));
    this.props.dataItem[this.props.field] = e.target.value.ddID;
    //console.log("change data after: " + JSON.stringify(this.props.dataItem[this.props.field]));
    this.forceUpdate();
  }
  render() {
    //console.log("PricingTypes Cell: " + JSON.stringify(PricingTypesDD));
    let dataValue = this.props.dataItem[this.props.field];
    if (!dataValue)
      dataValue = 0;
    if (!this.props.dataItem.inEdit) {
      if (dataValue === 0)
        return (<td>{" "}</td>);
      else if (NotificationTypesDD.find(c => c.ddID === dataValue) === undefined)
        return (
          <td>{"Unknown: " + dataValue}</td>
        );
      else
        return (
          <td>
            {NotificationTypesDD.find(c => c.ddID === dataValue).ddName}
          </td>
        );
    }
    return (
      <td>
        <DropDownList style={{ width: "100px" }} data={NotificationTypesDD} textField="ddName" dataItemKey="ddID" value={NotificationTypesDD.find(c => c.ddID === dataValue)} onChange={this.handleChange} />
      </td>
    );
  }
}
//---------------------------------------------------------------------------------------------
export function SetStoreLocations(DD) {
  StoreLocationsDD = DD;
  console.log("StoreLocationsDD: " + JSON.stringify(StoreLocationsDD));
}
export var StoreLocationsDD = [{ ddName: '', ddID: 0 }];
export class StoreLocationDropDownCell extends React.Component {
  handleChange = (e) => { // e.target.value is the object {ddID: n, ddName: 'n'}
    //console.log("change - field: " + this.props.field + "  value: " + JSON.stringify(e.target.value) + " dataitem: " + JSON.stringify(this.props.dataItem));
    this.props.onChange({ dataItem: this.props.dataItem, field: this.props.field, syntheticEvent: e.syntheticEvent, value: e.target.value });
    //console.log("change data before: " + JSON.stringify(this.props.dataItem[this.props.field]));
    this.props.dataItem[this.props.field] = e.target.value.ddID;
    //console.log("change data after: " + JSON.stringify(this.props.dataItem[this.props.field]));
    this.forceUpdate();
  }
  render() {
    //console.log("StoreLocation Types Cell: " + JSON.stringify(StoreLocationsDD));
    let dataValue = this.props.dataItem[this.props.field];
    if (!dataValue)
      dataValue = 0;
    if (!this.props.dataItem.inEdit) {
      if (dataValue === 0)
        return (<td>{" "}</td>);
      else if (StoreLocationsDD.find(c => c.ddID === dataValue) === undefined)
        return (
          <td>{"Unknown: " + dataValue}</td>
        );
      else
        return (
          <td>
            {StoreLocationsDD.find(c => c.ddID === dataValue).ddName}
          </td>
        );
    }
    return (
      <td>
        <DropDownList style={{ width: "200px" }} data={StoreLocationsDD} textField="ddName" dataItemKey="ddID" value={StoreLocationsDD.find(c => c.ddID === dataValue)} onChange={this.handleChange} />
      </td>
    );
  }
}
//---------------------------------------------------------------------------------------------
export function SetLocations(DD) { LocationsDD = DD; }
export var LocationsDD = [{ ddName: '', ddID: 0 }];
export class LocationDropDownCell extends React.Component {
  handleChange = (e) => { // e.target.value is the object {ddID: n, ddName: 'n'}
    this.props.onChange({ dataItem: this.props.dataItem, field: this.props.field, syntheticEvent: e.syntheticEvent, value: e.target.value });
    this.props.dataItem[this.props.field] = e.target.value.ddID;
    this.forceUpdate();
  }
  render() {
    let dataValue = this.props.dataItem[this.props.field];
    if (!dataValue)
      dataValue = 0;
    if (!this.props.dataItem.inEdit) {
      if (dataValue === 0)
        return (<td>{" "}</td>);
      else if (LocationsDD.find(c => c.ddID === dataValue) === undefined)
        return (
          <td>{"Unknown: " + dataValue}</td>
        );
      else
        return (
          <td>
            {LocationsDD.find(c => c.ddID === dataValue).ddName}
          </td>
        );
    }
    return (
      <td>
        <DropDownList style={{ width: "200px" }} data={LocationsDD} textField="ddName" dataItemKey="ddID" value={LocationsDD.find(c => c.ddID === dataValue)} onChange={this.handleChange} />
      </td>
    );
  }
}
//---------------------------------------------------------------------------------------------
//SublocationTypesDropDownCell, SetSublocationTypes                          
export function SetSublocationTypes(DD) { SublocationTypesDD = DD; }
export var SublocationTypesDD = [{ ddName: '', ddID: 0 }];
export class SublocationTypesDropDownCell extends React.Component {
  handleChange = (e) => { // e.target.value is the object {ddID: n, ddName: 'n'}
    this.props.onChange({ dataItem: this.props.dataItem, field: this.props.field, syntheticEvent: e.syntheticEvent, value: e.target.value });
    this.props.dataItem[this.props.field] = e.target.value.ddID;
    this.forceUpdate();
  }
  render() {
    let dataValue = this.props.dataItem[this.props.field];
    if (!dataValue)
      dataValue = 0;
    if (!this.props.dataItem.inEdit) {
      if (dataValue === 0)
        return (<td>{" "}</td>);
      else if (SublocationTypesDD.find(c => c.ddID === dataValue) === undefined)
        return (
          <td>{"Unknown: " + dataValue}</td>
        );
      else
        return (
          <td>
            {SublocationTypesDD.find(c => c.ddID === dataValue).ddName}
          </td>
        );
    }
    return (
      <td>
        <DropDownList style={{ width: "150px" }} data={SublocationTypesDD} textField="ddName" dataItemKey="ddID" value={SublocationTypesDD.find(c => c.ddID === dataValue)} onChange={this.handleChange} />
      </td>
    );
  }
}
//---- Drop Down for LocationStatusID in Table LocationStatusSet --------
export function SetLocationStatusSet(DD) { LocationStatusSetDD = DD; }
export var LocationStatusSetDD = [{ ddName: '',ddID: 0 }];
export class LocationStatusSetDropDownCell extends React.Component {
  handleChange = (e) => {
    this.props.onChange({ dataItem: this.props.dataItem,field: this.props.field,syntheticEvent: e.syntheticEvent,value: e.target.value });
    this.props.dataItem[this.props.field] = e.target.value.ddID;
    this.forceUpdate();
  }
  render() {
    let dataValue = this.props.dataItem[this.props.field];
    if (!dataValue)
      dataValue = 0;
    if (!this.props.dataItem.inEdit) {
      if (dataValue === 0)
        return (<td>{' '}</td>);
      else if (LocationStatusSetDD.find(c => c.ddID === dataValue) === undefined)
        return (
          <td>{`Unknown: ${dataValue}`}</td>
        );
      else
        return (
          <td>
            {LocationStatusSetDD.find(c => c.ddID === dataValue).ddName}
          </td>
        );
    }
    return (
      <td>
        <DropDownList style={{ width: '149px' }} data={LocationStatusSetDD} textField='ddName' dataItemKey='ddID' value={LocationStatusSetDD.find(c => c.ddID === dataValue)} onChange={this.handleChange} />
      </td>
    );
  }
}
//---- Drop Down for LocationSizeID in Table LocationSizes --------
export function SetLocationSizes(DD) { LocationSizesDD = DD; }
export var LocationSizesDD = [{ ddName: '',ddID: 0 }];
export class LocationSizesDropDownCell extends React.Component {
  handleChange = (e) => {
    this.props.onChange({ dataItem: this.props.dataItem,field: this.props.field,syntheticEvent: e.syntheticEvent,value: e.target.value });
    this.props.dataItem[this.props.field] = e.target.value.ddID;
    this.forceUpdate();
  }
  render() {
    let dataValue = this.props.dataItem[this.props.field];
    if (!dataValue)
      dataValue = 0;
    if (!this.props.dataItem.inEdit) {
      if (dataValue === 0)
        return (<td>{' '}</td>);
      else if (LocationSizesDD.find(c => c.ddID === dataValue) === undefined)
        return (
          <td>{`Unknown: ${dataValue}`}</td>
        );
      else
        return (
          <td>
            {LocationSizesDD.find(c => c.ddID === dataValue).ddName}
          </td>
        );
    }
    return (
      <td>
        <DropDownList style={{ width: '149px' }} data={LocationSizesDD} textField='ddName' dataItemKey='ddID' value={LocationSizesDD.find(c => c.ddID === dataValue)} onChange={this.handleChange} />
      </td>
    );
  }
}
//---- Drop Down for ProductItemCategoryID in Table ProductItemCategories --------
export var ProductItemCategoriesDD = [{ ddName: '',ddID: 0 }];
export class ProductItemCategoriesDropDownCell extends React.Component {
  handleChange = (e) => {
    this.props.onChange({ dataItem: this.props.dataItem,field: this.props.field,syntheticEvent: e.syntheticEvent,value: e.target.value });
    this.props.dataItem[this.props.field] = e.target.value.ddID;
    this.forceUpdate();
  }
  render() {
    let dataValue = this.props.dataItem[this.props.field];
    if (!dataValue)
      dataValue = 0;
    if (!this.props.dataItem.inEdit) {
      if (dataValue === 0)
        return (<td>{' '}</td>);
      else if (ProductItemCategoriesDD.find(c => c.ddID === dataValue) === undefined)
        return (
          <td>{'Unknown: ' + dataValue}</td>
        );
      else
        return (
          <td>
            {ProductItemCategoriesDD.find(c => c.ddID === dataValue).ddName}
          </td>
        );
    }
    return (
      <td>
        <DropDownList style={{ width: '160px' }} data={ProductItemCategoriesDD} textField='ddName' dataItemKey='ddID' value={ProductItemCategoriesDD.find(c => c.ddID === dataValue)} onChange={this.handleChange} />
      </td>
    );
  }
}
export function SetProductItemCategories(DD) {
  ProductItemCategoriesDD = DD;
}
//---- Drop Down for SublocationStatus in Dictionary Values List --------
export var SublocationStatusDD = [{ ddName: '',ddID: 0 }];
export class SublocationStatusDropDownCell extends React.Component {
  handleChange = (e) => {
    this.props.onChange({ dataItem: this.props.dataItem,field: this.props.field,syntheticEvent: e.syntheticEvent,value: e.target.value });
    this.props.dataItem[this.props.field] = e.target.value.ddID;
    this.forceUpdate();
  }
  render() {
    let dataValue = this.props.dataItem[this.props.field];
    if (!dataValue)
      dataValue = 0;
    if (!this.props.dataItem.inEdit) {
      if (dataValue === 0)
        return (<td>{' '}</td>);
      else if (SublocationStatusDD.find(c => c.ddID === dataValue) === undefined)
        return (
          <td>{'Unknown: ' + dataValue}</td>
        );
      else
        return (
          <td>
            {SublocationStatusDD.find(c => c.ddID === dataValue).ddName}
          </td>
        );
    }
    return (
      <td>
        <DropDownList style={{ width: '150px' }} data={SublocationStatusDD} textField='ddName' dataItemKey='ddID' value={SublocationStatusDD.find(c => c.ddID === dataValue)} onChange={this.handleChange} />
      </td>
    );
  }
}
export function SetSublocationStatus(DD) {
  SublocationStatusDD = DD;
}
//---------------------------------------------------------------------------------------------
export function SetProductType(DD) { ProductTypesDD = DD; }
export let ItemCategoriesDD = [];
export class CategoryDropDownCell extends React.Component {
  handleChange = (e) => { // e.target.value is the object {ddID: n, ddName: 'n'}
    //console.log("change - field: " + this.props.field + "  value: " + JSON.stringify(e.target.value) + " dataitem: " + JSON.stringify(this.props.dataItem));
    this.props.onChange({
      dataItem: this.props.dataItem,
      field: this.props.field,
      syntheticEvent: e.syntheticEvent,
      value: e.target.value
    });
    this.props.dataItem[this.props.field] = e.target.value.ddID;
    this.forceUpdate();
  }
  render() {
    let dataValue = this.props.dataItem[this.props.field];
    if (dataValue === undefined)
      dataValue = 0;
    if (!this.props.dataItem.inEdit) {
      if (dataValue === 0)
        return (<td>{" "}</td>);
      else if (ItemCategoriesDD.find(c => c.ddID === dataValue) === undefined)
        return (
          <td>{"Unknown: " + dataValue}</td>
        );
      else
        return (
          <td>
            {ItemCategoriesDD.find(c => c.ddID === dataValue).ddName}
          </td>
        );
    }
    return (
      <td>
        <DropDownList style={{ width: "100px" }} data={ItemCategoriesDD} textField="ddName" dataItemKey="ddID" value={ItemCategoriesDD.find(c => c.ddID === dataValue)} onChange={this.handleChange} />
      </td>
    );
  }
  //---------------------------------------------------------------------------------------------  
  // Sample code
  //---------------------------------------------------------------------------------------------
  is = (fileName, ext) => new RegExp(`.${ext}\$`).test(fileName);
  //conClassName = (text, items) => {
  //  if (items !== undefined) {
  //    return 'k-icon k-i-folder';
  //  } else if (is(text, 'pdf')) {
  //    return 'k-icon k-i-file-pdf';
  //  } else if (is(text, 'html')) {
  //    return 'k-icon k-i-html';
  //  } else if (is(text, 'jpg|png')) {
  //    return 'k-icon k-i-image';
  //  } else {
  //    return '';
  //  }
  //}
  //---------------------------------------------------------------------------------------------
}