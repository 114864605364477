//----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// AppMain - The main application page (Home Page) for the InteractMenu Manager Application
//              Version 1.009 - January 23, 2025
//              Version 1.008 - March 5, 2024
//              Version 1.007 - November 27, 2021
//----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------  
import React, { useEffect, useState } from 'react'
import { withRouter } from './withRouter';
import { Splitter } from '@progress/kendo-react-layout';
import { DropDownList } from '@progress/kendo-react-dropdowns';  
import { RadialGauge } from '@progress/kendo-react-gauges';
import { Popup } from '@progress/kendo-react-popup';
import { NumericTextBox } from '@progress/kendo-react-inputs';
import { ListView, ListViewHeader, ListViewFooter } from '@progress/kendo-react-listview';
import { Chart, ChartTitle, ChartLegend, ChartSeries, ChartSeriesItem, ChartSeriesLabels, ChartCategoryAxis, ChartCategoryAxisItem} from '@progress/kendo-react-charts';
import { Button } from '@progress/kendo-react-buttons';
import { DateTimePicker } from '@progress/kendo-react-dateinputs';
import { Scheduler, TimelineView, DayView, WeekView, MonthView, AgendaView } from '@progress/kendo-react-scheduler';
import { SvgIcon } from '@progress/kendo-react-common';
import accessOMAPI from './CommonCode.js';
import { CallOM,displayMessage,displayError,displayNotify,LogOffReset,LogOffResetMsg,getHelpTopics,getHelpDetail,getCookie,setCookie,deleteCookie,KeyLogOn,OMTrace,CTYP } from './CommonCode.js';
import { GetMonth } from './CommonFormat.js';
import PageHeader from './PageHeader.js';
import PageFooter from './PageFooter.js';
import PageSide from './PageSide.js';
import { SessionInfo } from './App';
import 'hammerjs';
//
const labelContent = (e) => (e.category);
//const colors = [{ to: 25, color: '#0058e9' }, { from: 25, to: 50, color: '#37b400' }, { from: 50, to: 75, color: '#ffc000' }, { from: 75, color: '#f31700' }];

//-------------------------------------------------------------------

class AppMain extends React.Component {
  constructor(props) {
    super(props);
    SessionInfo.props = props;
    //console.log(`AppMain Props: ${window.location.pathname}`);
    //console.log(`AppMain URL Props: ${window.location.search}`); 
    SessionInfo.forceLogoff = false;
    if (SessionInfo.pathname == '') {
      SessionInfo.pathname = window.location.pathname;
      SessionInfo.search = window.location.search;
      SessionInfo.hash = window.location.hash;
    }
    SessionInfo.History = props.history;
    this.AppMainProps = props;
    //console.log("AppMain SessionInfo History: " + JSON.stringify(SessionInfo.History));
    //console.log("AppMain History: " + JSON.stringify(this.AppMainProps.history));  
    if (SessionInfo.session === '') {
      //console.log("AppMain - Session not defined");
      //console.log(`Props: ${JSON.stringify(props)}`); 
      //console.log(`AppMain hash: ${SessionInfo.hash}`);
      if (SessionInfo.hash === "#register10002") { // Registration Verification Process
        //props.history.push("/Confirmation");
        SessionInfo.hash = "registerDone";
        return;
      }
      else if (SessionInfo.hash === "#token20001") { // Token Logon  
        if (SessionInfo.token !== '') {
          console.log(`Logon with token: ${window.location.search}`);
          SessionInfo.keyLogon = true;
          SessionInfo.token = window.location.search;
        }
      }
      else if (SessionInfo.keyLogon === false) {
        //this.props.navigate("/Login");
      }
    }
    if (SessionInfo.menuAction === undefined) {
      SessionInfo.menuAction = this.onMenuItemClick;
    }
    if (SessionInfo.AppMainPage) { // Data saved for this session
      this.state = SessionInfo.AppMainPage;
      this.loadExistingPage = true;
      this.currentShow = 2;
    }
    //console.log("Before PageSide New");
    SessionInfo.PageSide = new PageSide(props);
    //console.log("After PageSide New - x=" + JSON.stringify(SessionInfo.PageSide)); 
    //console.log("After PageSide props=" + JSON.stringify(props));
    SessionInfo.History = props.history;
    var LF = function () { console.log("Call Logoff"); LogOffReset("LogOff",props); }
    //--------------------------------------------------------  
    SessionInfo.MenuControl = [{
      text: 'Stores',expanded: false,route: 'StoreLocationDetail',key: 10,goToTab: 0,call: undefined,icon: storeLocations,className: 'sideMenuMain',space: <span>&nbsp;&nbsp;</span>,items: [
        { text: 'Operation Hours',goToTab: 1,route: 'StoreLocationDetail',key: 11 },
        { text: 'Store Items',goToTab: 2,route: 'StoreLocationDetail',key: 12 },
        { text: 'Store Receipts',goToTab: 3,route: 'StoreLocationDetail',key: 13 },
        { text: 'Store Promotions',goToTab: 4,route: 'StoreLocationDetail',key: 14 }]
    //    { text: 'Sublocations',goToTab: 5,route: 'StoreLocationDetail',key: 15 }]
    }];
    SessionInfo.MenuControl.push({
      text: 'Orders',expanded: false,route: 'OrderManagement',key: 20,goToTab: 0,call: undefined,icon: kitchen,className: 'sideMenuMain',space: <span>&nbsp;&nbsp;</span>,items: [
        //{ text: 'Pending Orders',goToTab: 0,route: 'OrderManagement',key: 21 },
        //{ text: 'In Process',goToTab: 1,route: 'OrderManagement',key: 22 },
        { text: 'Ready To Serve',goToTab: 2,route: 'OrderManagement',key: 23 },
        { text: 'Ready For Pickup',goToTab: 3,route: 'OrderManagement',key: 24 },
        { text: 'In Delivery',goToTab: 4,route: 'OrderManagement',key: 25 },
        { text: 'In Payment',goToTab: 5,route: 'OrderManagement',key: 26 }]
    //    { text: 'Completed Orders',goToTab: 6,route: 'OrderManagement',key: 27 },
    //    { text: 'Management',goToTab: 7,route: 'OrderManagement',key: 28 }]
    });
    SessionInfo.MenuControl.push({
      text: 'Kitchen',expanded: false,route: 'KitchenManagement',key: 30,goToTab: 0,call: undefined,icon: kitchen,className: 'sideMenuMain',space: <span>&nbsp;&nbsp;</span>,items: [
        { text: 'Kitchen Orders',goToTab: 0,route: 'KitchenManagement',key: 31 },
        //{ text: 'Pending Orders',goToTab: 1,route: 'KitchenManagement',key: 32 },
        //{ text: 'Ready Orders',goToTab: 2,route: 'KitchenManagement',key: 32 },
        //{ text: 'Completed Orders',goToTab: 3,route: 'KitchenManagement',key: 33 },
        { text: 'Management',goToTab: 4,route: 'KitchenManagement',key: 34 },
        { text: 'Scheduler',goToTab: 5,route: 'KitchenManagement',key: 35 }]
    });
    SessionInfo.MenuControl.push({
      text: 'Catalog',expanded: false,route: 'StoreItemDetail',key: 38,goToTab: 0,call: undefined,icon: products,className: 'sideMenuMain',space: <span>&nbsp;&nbsp;</span>,items: [
        { text: 'Item Detail',goToTab: 0,route: 'StoreItemDetail',key: 39 }]
    });
    SessionInfo.MenuControl.push({
      text: 'Manager',expanded: false,route: 'ManagerPage',key: 40,goToTab: 0,call: undefined,icon: manager,className: 'sideMenuMain',space: <span>&nbsp;&nbsp;</span>,items: [
        { text: 'Product Categories',goToTab: 0,route: 'ManagerPage',key: 41 },
        //{ text: 'Product Groups',goToTab: 1,route: 'ManagerPage',key: 42 },
        //{ text: 'Product Types',goToTab: 2,route: 'ManagerPage',key: 43 },
        //{ text: 'Pricing Types',goToTab: 3,route: 'ManagerPage',key: 44 },
        //{ text: 'Rate Types',goToTab: 4,route: 'ManagerPage',key: 45 },
        { text: 'Notifications',goToTab: 9,route: 'ManagerPage',key: 46 },
        { text: 'Promotions',goToTab: 10,route: 'ManagerPage',key: 47 },
        { text: 'Process Commands',goToTab: 11,route: 'ManagerPage',key: 48 },
        { text: 'Option Groups',goToTab: 12,route: 'ManagerPage',key: 49 }]
    });
    if (SessionInfo.PermLevel > 493) {
      SessionInfo.MenuControl.push({
        text: 'Accounts',expanded: false,route: 'CustomerProfile',key: 50,goToTab: 0,call: undefined,icon: users,className: 'sideMenuMain',space: <span>&nbsp;&nbsp;</span>,items: [
          { text: 'Setup',goToTab: 1,route: 'CustomerProfile',key: 51 },
          { text: 'Sales Receipts',goToTab: 2,route: 'CustomerProfile',key: 52 },
          { text: 'Credit Account',goToTab: 3,route: 'CustomerProfile',key: 53 }]
      });
    }
    SessionInfo.MenuControl.push({
      text: 'Dashboard',expanded: false,route: 'AppMain',key: 60,goToTab: 0,call: undefined,icon: dashboard,className: 'sideMenuMain',space: <span>&nbsp;&nbsp;</span>,items: [
        { text: 'Sales',goToTab: 1,route: 'AppMain',key: 61 },
        { text: 'Stress Test',goToTab: 2,route: 'AppMain',key: 62 },
        { text: 'Set Values',goToTab: 3,route: 'AppMain',key: 63 }]
    });
    if (SessionInfo.PermLevel > 493) {
      SessionInfo.MenuControl.push({
        text: 'Set Up',expanded: false,route: 'SettingsPage',key: 70,goToTab: 0,call: undefined,icon: settings,className: 'sideMenuMain',space: <span>&nbsp;&nbsp;</span>
      });
    }
    SessionInfo.MenuControl.push({
      text: 'Contact Us',expanded: false,route: 'ContactUs',key: 80,goToTab: 0,call: undefined,icon: contacts,className: 'sideMenuMain',space: <span>&nbsp;&nbsp;</span>
    });
    if (SessionInfo.PermLevel > 493) {
      SessionInfo.MenuControl.push({
        text: 'Help Info',expanded: false,route: 'AboutPage',key: 90,goToTab: 0,call: undefined,icon: about,className: 'sideMenuMain',space: <span>&nbsp;&nbsp;</span>
      });
    }
    SessionInfo.MenuControl.push({
      text: 'Logoff',expanded: false,route: '',key: 99,call: LF,icon: logout,space: <span>&nbsp;&nbsp;</span> // undefined //LogOffReset("LogOff")
    });
    //----------------------------------------------------------------------------------------------------------------
  }
  state = {
    AppNotificationID: 0,
    NotificationTypeID: 0,
    MCMessageID: 0,
    MessageID: 0,
    StoreLocationID: 0,
    CustomerID: 0,
    StoreGroupID: 0,
    NotificationDateTime: 0,
    ExpiryDateTime: 0,
    OMUserID: 0,

    setPromoNumber: 0,
    promoNumberID: 0,

    string: '',
    NotificationMessage: '',
    stateHasChanged: false,

    selectedTopTab: 0,
    style: { width: 1000,height: 1000 },
    horizontalPanes: [{ collapsible: true },{ size: '100%',min: '0px',collapsible: false }], // Left/Right
    verticalPanes: [{ size: '50%' },{ collapsible: true }],  // Top/Bottom
    bottomDisplay: 0,
    //SoundOn = (bool)DR["SoundOn"];
    //AutoScanLocation = (bool)DR["AutoScanLocation"];
    //FlashOnScan = (bool)DR["FlashOnScan"];
    //CustomerIsInStore = (bool)DR["CustomerIsInStore"];
    //CustomerIsActive = (bool)DR["CustomerIsActive"];
    //TotalPurchaseAmount = Convert.ToDecimal(DR["TotalPurchaseAmount"]);
    //MaximumPurchaseAmount = Convert.ToDecimal(DR["MaximumPurchaseAmount"]);
    //TodayPurchaseAmount = Convert.ToDecimal(DR["TodayPurchaseAmount"]);
    PersonPhotoID: 0,

    perfCounter: 0,
    runTest1: false,
    test1TimesPerSecond: 1,
    test1CustomOrderID: 1,
    test1WaitCount: 100,
    test1Counter: 0,
    testStartTime: 0,

    activeUsers: 0,
    orderCount: 0,
    itemCount: 0,
    totalPeriodSales: 0,
    TotalTaxAmount: 0,
    TipAmount: 0,
    OptionCount: 0,
    OptionsPrice: 0,
    customerCount: 0,
    totalTransactionCount: 0,

    strataCount: 0,
    test1SendTxCount: 0,
    test1ReturnLength: 0,

    activeCustomers: 40,
    transactionCount: 250,
    searchValue: '',
    MessagesValue: '',
    currentTime: '',
    HelpPages: [],
    showHelpDetail: false,
    showHelpListPopup: false,
    // Dictionary Arrays   
    NotificationTypes: [],
    StoreLocations: [],
    StoreGroups: [],
    storeNamesShare: [],

    categories: [],

    PeriodType: 0,
    PeriodBack: 0,
    TxDate: new Date(),
    PeriodStartDate: new Date(),

    locationCount: 0,
    data1: [0,0,0,0,0,0,0],
    data2: [0,0,0,0,0,0,0],
    data3: [0,0,0,0,0,0,0],
    data4: [0,0,0,0,0,0,0],
    data5: [0,0,0,0,0,0,0],
    data6: [0,0,0,0,0,0,0],
    data7: [0,0,0,0,0,0,0],
    data8: [0,0,0,0,0,0,0],
    dataName: [],

    //data: [[123,276,310,212,240,156,98], [165,210,287,144,190,167,212], [56,140,195,46,123,78,95], [196,190,225,176,203,198,215],  [96,90,125,76,103,98,115], [23,176,410,112,240,356,198]],

  };
  // Sample dashboard data
  distributiondata = [];
  dashboardInitialized = false;
  salesByStore = 20;
  //arcOptions = {
  //  value: this.state.activeCustomers,
  //  this.colors
  //};
  //PageSideInst = SessionInfo.PageSide;
  LogonSwitch = 0;

  AppMainProps = undefined;
  ProductCategoryOID = 1104137;
  ProductGroupOID = 1104119;
  ProductTypeOID = 1104125;
  PackagingTypeOID = 1104125;
  PricingTypeOID = 1104167;
  RateTypeOID = 545339;
  AppNotificationOID = 545857;
  StorePromotionOID = 544701;
  XXItemOID = 544619;
  refreshCount = 6;
  currentShow = 1; // Default dashboard
  timeout = undefined;
  test1InProgress = false;
  compUpdate = true;
  helpListPopupOffset = { left: 150,top: 60 };
  helpDetailOffset = { left: 200,top: 120 };
  PeriodsFixedValues = [{ ddName: 'Hour',ddID: 12 },{ ddName: 'Day',ddID: 0 },{ ddName: 'Week',ddID: 1 },{ ddName: 'Month',ddID: 4 }];
  //---- Maximums for each gauge ----
  maxCurrentOrders = 1000;
  xCO1 = 250; xCO2 = 500; xCO3 = 750;
  colorRCO = ['#eeffcc','#ddff99','#ccff66','#bbff33'];
  maxTipValue = 2000.0;
  xTP1 = 500.0; xTP2 = 1000.0; xTP3 = 1500.0;
  colorRTP = ['#ccffcc','#99ff99','#66ff66','#33ff33'];
  maxTotalTaxes = 2000.0;
  xTT1 = 500.0; xTT2 = 1000.0; xTT3 = 1500.0;
  colorRTT = ['#cce6ff','#99ceff','#66b5ff','#339cff'];
  maxTotalSales = 20000.0;
  xTS1 = 5000.0; xTS2 = 10000.0; xTS3 = 15000.0;
  colorRTS = ['#ecb3ff','#df80ff','#d24dff','#c61aff'];
  maxMenuItems = 10000;
  xMI1 = 2500; xMI2 = 5000; xMI3 = 7500;
  colorRMI = ['#ccd9ff','#99b3ff','#668cff','#3366ff'];
  maxOptionCount = 10000;
  xOC1 = 2500; xOC2 = 5000; xOC3 = 7500;
  colorROC = ['#e6ccff','#cc99ff','#b366ff','#9933ff'];
  maxOptionValue = 2000.0;
  xOV1 = 500.0; xOV2 = 1000.0; xOV3 = 1500.0;
  colorROV = ['#eeffcc','#eeffcc','#ccff66','#bbff33'];
  maxActiveCustomers = 200;
  xAC1 = 50; xAC2 = 100; xAC3 = 150;
  colorRAC = ['#99ddff','#99ffcc','#ff99ff','#e600e6'];
  //---- Colours ----
  tickColor = '#008000';
  labelColor = 'blue';
  colorRng1 = ['#0033cc','#ff00ff','#ccffcc','#00ff00'];


  async componentDidMount() {
    SessionInfo.forceLogoff = false;
    //const style = { width: 0, height: 0, visible: false };
    //this.setState({ style });         
    //this.setState({ stateHasChanged: false });
    if (SessionInfo.isConfirmationOnly === true) {
      this.gotoConfirmation();
    }
    else {
      console.log(`AppMain Mounted - Logged On: ${SessionInfo.loggedOn}, keyLogon: ${SessionInfo.keyLogon}, GoToTab: ${SessionInfo.GoToTab}, forceLogoff: ${SessionInfo.forceLogoff}`);
      console.log(`User: ${SessionInfo.Name}, AppEntityID: ${SessionInfo.AppEntityID}`);
      SessionInfo.currentPage = "AppMain";
      SessionInfo.tabSelectFunc = this.selectTab;
      //-----------------------------------------------------
      // auto-logon
      // Get Key Token from cookie if available
      if (SessionInfo.loggedOn === false) {
        let value = getCookie('IManagerID');
        if (value && value !== 'NF') { // OM returned NF token
          console.log(`IManagerID cookie: ${value}`);
          SessionInfo.loggedOn = true;
          SessionInfo.stayLoggedOn = true;
          SessionInfo.CheckoutToken = value;
          this.LogonSwitch = 1;
          //await this.mainLogon(value);
        }
        else {
          this.LogonSwitch = 2;
          //this.gotoLogin();
        }
      }
      //-----------------------------------------------------
      //console.log(`Props: ${JSON.stringify(this.props)}`);
      //await TestREST();
      //console.log("After Test REST");

      if (SessionInfo.loggedOn === true) {
      //  this.runMonitorProcess = true;
      //  this.monitorProcess();
      }
      else if (SessionInfo.keyLogon === true) {
        this.LogonSwitch = 3;
        //await this.autoLogon(); // auto logon and Start Monitor
      }
      else {
        this.LogonSwitch = 2;
        //this.gotoLogin();
      }
      if (SessionInfo.GoToTab >= 0) {
        await this.selectTab(SessionInfo.GoToTab)
        SessionInfo.GoToTab = 0;
      }          
      this.LogonSwitch += 10;
      this.runMonitorProcess = true;
      this.monitorProcess(); 
    }
    SessionInfo.currShowHelp = this.showHelp;
  }
  componentDidUpdate() {
    //let value = this.context;
  }
  componentWillUnmount() {
    if (SessionInfo.session !== '')
      SessionInfo.AppMainPage = this.state;
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.runMonitorProcess = false;
  }
  shouldComponentUpdate() {
    //console.log("Should Update?");
    if (this.compUpdate === true)
      return true;
    else
      return false;
  }
  //-------------------------------------------------- 
  async gotoConfirmation() {
    console.log(`AppMain Mounted - go to Confirmation`);
    this.props.navigate("/Confirmation");
  }
  async gotoLogin() {
    SessionInfo.loggedOn = false;
    this.props.navigate(`/Login`); // Logon
  }
  async mainLogon(token) {
    if (!token)
      token = SessionInfo.CheckoutToken;
    console.log(`main Logon - token: ${token}`);
    let m = await KeyLogOn(token);
    SessionInfo.keyLogon = false;
    //SessionInfo.gotoAutoPage = ''; // 'OrderManagement';
    console.log(`After KeyLogon msg: ${JSON.stringify(m)}`);
    if (m === 'ok') {
      SessionInfo.loggedOn = true;
      console.log(`Auto Signed In - go to ${SessionInfo.gotoAutoPage}`);
      if (SessionInfo.gotoAutoPage === '')
        this.props.navigate("/OrderPage");
      else
        this.props.navigate(`/${SessionInfo.gotoAutoPage}`);
    }
    else {
      console.log(`Unable to Login: ${m} - Delete the Logon Token`);
      deleteCookie('IManagerID'); // Delete the logon token
      SessionInfo.loggedOn = false;
      this.props.navigate(`/Login`); // Logon
    }
  }
  //---
  percentFormat = (value) => new Intl.NumberFormat('en-US',{ style: 'percent',minimumFractionDigits: 2,maximumFractionDigits: 2 }).format(value);
  moneyFormat = (value) => new Intl.NumberFormat('en-US',{ style: 'currency',currency: 'USD' }).format(value);
  onLayoutChange = (event) => {
    this.setState({ horizontalPanes: event.newState });
  }
  onNestedLayoutChange = (event) => {
    this.setState({ verticalPanes: event.newState });
  }
  //--------------- Field Change Values --------------------------------
  chgFldVal(event) {
    this.setState({ [event.target.name]: event.target.value });
    this.setState({ stateHasChanged: true });
  }
  chgFldValMon(event) {
    this.setState({ [event.target.name]: event.target.value });
    this.setState({ stateHasChanged: true });
    this.monitorCounter = 11;
  }
  chgMoneyFldVal(event) {
    //var stateCopy = Object.assign({}, this.state[event.target.name]);
    let newVal = event.target.value.replace(/[^\d.-]/g,'');
    this.setState({ [event.target.name]: newVal });
  }
  chgPercentFldVal(event) {
    //var stateCopy = Object.assign({}, this.state[event.target.name]);
    let newVal = event.target.value.replace(/[^\d.-]/g,'');
    //console.log("percent newval: " + newVal);
    newVal /= 100.0;
    this.setState({ [event.target.name]: newVal });
    this.setState({ stateHasChanged: true });
  }
  chgDDFldVal = (event) => {
    this.setState({ [event.target.props.name]: event.target.value.ddID });
    this.setState({ stateHasChanged: true });
  }
  chgDDFldValMon = (event) => {
    this.setState({ [event.target.name]: event.target.value.ddID });
    this.setState({ stateHasChanged: true });
    this.monitorCounter = 11;
  }
  chgCheckboxVal(event) {
    let setVal = true;
    if (this.state[event.target.name] === true)
      setVal = false;
    this.setState({ [event.target.name]: setVal });
    this.setState({ stateHasChanged: true });
  }
  //------------------------------------------------------------------- 
  onMenuItemClick = async () => {
    //console.log(`MenuText: ${JSON.stringify(SessionInfo.MenuSelect)}`);
    //console.log("Menu Click Session History: " + JSON.stringify(SessionInfo.History));
    //console.log("Menu Click AppMain History: " + JSON.stringify(this.AppMainProps.history)); 
    if (SessionInfo.MenuSelect.call !== undefined)
      SessionInfo.MenuSelect.call(); // This is used for direct function calls - like Logoff
    else { // Select Screen and Tab if applicable     
      //console.log("currentPage: " + SessionInfo.currentPage);
      if (SessionInfo.currentPage === SessionInfo.MenuSelect.route) { // Selected different Tab on the current screen 
        //console.log("currentPage - Tab: " + SessionInfo.MenuSelect.goToTab);
        if (SessionInfo.tabSelectFunc)
          SessionInfo.tabSelectFunc(SessionInfo.MenuSelect.goToTab);
      }
      else { // Go to the New Screen           
        console.log(`goto New screen: ${SessionInfo.MenuSelect.route}, goToTab: ${SessionInfo.MenuSelect.goToTab}`);
        this.props.navigate("/" + SessionInfo.MenuSelect.route);
        SessionInfo.GoToTab = SessionInfo.MenuSelect.goToTab;
      }
    }
  }
  //-------------------------------------------------------------------
  onItemClick = async (event) => {
    //console.log("Event - name: " + JSON.stringify(event.item));
    if (event.item.text === "Sales") {
      event.item.selected = true;
      this.currentShow = 1; // Dashboard
      this.state.bottomDisplay = 5;
    }
    else if (event.item.text === "Customer Ticker") {
      event.item.selected = true;
      this.currentShow = 1; // Dashboard
      this.state.bottomDisplay = 5;
    }
    else if (event.item.text === "Food Preparation") { // 
      event.item.selected = true;
      SessionInfo.displayHeader = false;
      SessionInfo.displayFooter = false;
      document.documentElement.style.setProperty('--main-top','0px');
      document.documentElement.style.setProperty('--main-bottom','0px');
      event.item.selected = !event.item.selected;
      this.currentShow = 1; // Dashboard
      this.state.bottomDisplay = 5;
      //event.item.text = "Show Header";
    }
    else if (event.item.text === "Hide Header") { // 
      event.item.selected = true;
      SessionInfo.displayHeader = false;
      SessionInfo.displayFooter = false;
      document.documentElement.style.setProperty('--main-top','0px');
      document.documentElement.style.setProperty('--main-bottom','0px');
      event.item.selected = !event.item.selected;
      event.item.text = "Show Header";
    }
    else if (event.item.text === "Show Header") { // 
      event.item.selected = true;
      SessionInfo.displayHeader = true;
      SessionInfo.displayFooter = true;
      document.documentElement.style.setProperty('--main-top','106px');
      document.documentElement.style.setProperty('--main-bottom','40px');
      event.item.selected = !event.item.selected;
      event.item.text = "Hide Header";
    }
    else if (event.item.text === "Stress Test") { // 
      event.item.selected = true;
      SessionInfo.displayHeader = true;
      SessionInfo.displayFooter = true;
      this.currentShow = 3; // Stess Test
      this.state.bottomDisplay = 6;
    }
    else if (event.item.text === "Set Values") { // 
      event.item.selected = true;
      SessionInfo.displayHeader = true;
      SessionInfo.displayFooter = true;
      this.currentShow = 4; // Set Values and Test TCPIP
      this.state.bottomDisplay = 5;
    }
    this.forceUpdate();
  }
  //-------------------------------------------------------------------
  onStartStressTest1 = async () => {
    displayMessage("Start Stress Test 1");
    if (this.state.test1TimesPerSecond > 100)
      this.state.test1TimesPerSecond = 100;
    else if (this.state.test1TimesPerSecond <= 0)
      this.state.test1TimesPerSecond = 1;
    this.state.test1WaitCount = 100 / this.state.test1TimesPerSecond;
    this.state.testStartTime = new Date();
    this.state.test1Counter = 0;
    this.state.runTest1 = true;
    console.log("Stress Test 1 - counter: " + this.state.test1WaitCount);
  }
  onStopTests = async () => {
    if (this.state.runTest1 === true) {
      this.state.runTest1 = false;
      displayMessage("STOP Stress Test 1");
    }
  }
  async runTest1() {
    try {
      this.test1InProgress = true;
      console.log("Run Test1");
      let Desc = `Test 1 using OrderID: {this.state.test1CustomOrderID} Frequency: {this.state.test1TimesPerSecond}`;
      let CD = await CallOM("TransTest1",0,this.state.test1CustomOrderID,2,Desc); // TransTest1 
      this.setState({ test1SendTxCount: this.state.test1SendTxCount + 1 });
      console.log("test1 data: " + JSON.stringify(CD.d));
      console.log("test1 data length: " + Object.keys(CD.d).length);
      this.setState({ test1ReturnLength: this.state.test1ReturnLength += Object.keys(CD.d).length });
    }
    catch (ex) {
      console.log("Error in runTest1: " + ex);
    }
    this.test1InProgress = false;
  }
  //-------------------------------------------------------------------
  async perfProbe() {
    try { // Get Performance Data for Test1
      let CD = await CallOM("GetPerfData",0,0,0); // PerfProbe
      //console.log("PerfData: " + JSON.stringify(CD.d));
      let activeUsers = CD.d["NumberLoggedOn"];
      let transactionCount = CD.d["TxCount"];
      let totalTransactionCount = CD.d["TotalTxCount"];
      let strataCount = CD.d["StrataCount"];
      //console.log("Users: " + activeUsers + "transactionCount: " + transactionCount);
      this.setState({ activeUsers });
      this.setState({ transactionCount });
      this.setState({ totalTransactionCount });
      this.setState({ strataCount });
    }
    catch (ex) {
      console.log("Error in perfProbe: " + ex);
      displayError("Error in Testing - Tests Cancelled");
      this.state.runTest1 = false;
    }
  }
  //------------------------------------------------------------------- 
  async keyLogon() {
    console.log("Manager Key Logon");
    let m = await KeyLogOn(SessionInfo.token);
    SessionInfo.keyLogon = false;
    SessionInfo.gotoAutoPage = 'OrderManagement';
    console.log(`After KeyLogon m:${m}`);
    if (m === 'ok') {
      if (SessionInfo.HasPerm51) {
        //this.props.navigate("/StoreLocationDetail");
      } else {
        displayMessage(`Auto Signed In - go to ${SessionInfo.gotoAutoPage}`);
        if (SessionInfo.gotoAutoPage !== '') {
          //this.props.navigate("/" + SessionInfo.gotoAutoPage);
        }
        SessionInfo.loggedOn = true;
      }
    }
    else if (m) {
      displayNotify("Unable to Login: " + m);
      //this.props.navigate("/Login");
    }
    else {
      displayNotify("Unable to Sign In");
      //this.props.navigate("/Login");
    }
  }
  async autoLogon() {
    if (SessionInfo.keyLogon === true) {
      await this.keyLogon();
      SessionInfo.keyLogon = false;
      this.monitorProcess();
    }
  }
  //--------------- Monitor Process ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
  runMonitorProcess = true;
  isMonitorRunning = false;
  monitorCounter = 20;
  inMonitor = false;
  setTimer() {
    console.log(`Time LogonSwitch: ${this.LogonSwitch}`);
    if (SessionInfo.loggedOn === true || this.LogonSwitch > 0) {
      this.isMonitorRunning = true;
      if (this.LogonSwitch > 0)
        this.timeout = setTimeout(this.monitorProcess.bind(this),1); // 1 milli second
      else
        this.timeout = setTimeout(this.monitorProcess.bind(this),1000); // 1 second  
    } else {
      console.log(`----> Stop Monitor Process`);
      this.runMonitorProcess = false;
      this.isMonitorRunning = false;
    }
  }
  //----- Timer Process - Application Control Logic -------------------------------------------------
  monitorProcess = async () => {
    //-----------------    
    if (this.LogonSwitch > 0) {
      console.log(`Monitor LogonSwitch: ${this.LogonSwitch}`);
      if (this.LogonSwitch >= 10)
        this.LogonSwitch -= 10;
      else {
        if (this.LogonSwitch == 1)
          this.mainLogon(SessionInfo.CheckoutToken);
        else if (this.LogonSwitch == 2)
          this.gotoLogin();
        else if (this.LogonSwitch == 3)
          this.autoLogon();
        this.LogonSwitch = 0;
      }
      this.setTimer();
    }
    else {
      if (SessionInfo.forceLogoff === true || SessionInfo.loggedOn === false) {
        console.log(`forceLogoff set in AppMain - go to Login`);
        this.props.navigate("/Login");
      } else {
        if (this.runMonitorProcess === true) {
          if (this.monitorCounter++ >= 10) {
            try {
              if (this.inMonitor === false) {
                this.inMonitor = true;
                this.monitorCounter = 0;
                if (this.state.runTest1 === true) { // Run TEST1
                  if (this.state.test1Counter++ > this.state.test1WaitCount) {
                    this.setState({ test1Counter: 0 });
                    if (this.test1InProgress === false) {
                      //console.log("Run Test 1 - waitcount: " + this.state.test1WaitCount);
                      this.runTest1();
                    }
                  }
                  if (this.state.perfCounter++ > 100) {
                    this.setState({ perfCounter: 0 });
                    this.perfProbe();
                  }
                } else if (this.currentShow === 1) { // Perform normal Dashboard Processing
                  if (this.dashboardInitialized == false) { // Initialize Dashboard Information
                    this.dashboardInitialized = true;
                    let CD = await CallOM("GetAllStoreNames",0,0,0); // Get StoreNames Dictionary  
                    console.log(`Get Stores Return - CD: ${JSON.stringify(CD)}`);
                    let ents = CD.d.rows;
                    //console.log(`ents: ${JSON.stringify(ents)}`);
                    this.distributiondata = CD.d.rows.slice();
                    let storeNamesShare = [];
                    //  "kind": "Vancouver","share": 0.175
                    let name = [''];
                    ents.forEach((ent) => {
                      //console.log(`ent: ${JSON.stringify(ent)}`);
                      storeNamesShare.push({ kind: ent.v,share: 1.0 });
                      name.push(ent.v);
                    });
                    this.setState({ dataName: name });
                    console.log(`----> Store Names: ${JSON.stringify(name)}`);
                    console.log(`distributiondata: ${JSON.stringify(this.distributiondata)}`);
                    console.log(`storeNamesShare: ${JSON.stringify(storeNamesShare)}`);
                    this.setState({ storeNamesShare });
                  }
                  //-------------------------------------------------------------------------
                  // Get latest Stats 
                  let CD;
                  let storeLocation = 0;
                  if (this.salesByStore++ > 20) {
                    this.salesByStore = 0;
                    CD = await CallOM("GetDailySalesByLocation",storeLocation,this.state.PeriodType,0,"","",this.state.PeriodBack); // Get StoreNames Dictionary 
                    if (CD) {
                      console.log(`current Store Stats: ${JSON.stringify(CD)}`);
                      if (CD.d) {
                        let ents = CD.d.rows;
                        console.log(`daily stats: ${JSON.stringify(ents)}`);
                        this.dailydata = CD.d.rows.slice();
                        let storeNamesShare = [];
                        //  "kind": pkid,"share": 0.175
                        ents.forEach((ent) => {
                          //console.log(`ent: ${JSON.stringify(ent)}`);
                          let sn = "";
                          for (let ix = 0; ix < this.distributiondata.length; ix++) {
                            if (ent.k == this.distributiondata[ix].k) {
                              sn = this.distributiondata[ix].v;
                              break;
                            }
                          }
                          if (sn !== "")
                            storeNamesShare.push({ kind: sn,share: ent.v });
                        });
                        console.log(`storeNamesShare: ${JSON.stringify(storeNamesShare)}`);
                        this.setState({ storeNamesShare });
                      }
                      else
                        SessionInfo.forceLogoff = true;
                    }
                    else
                      SessionInfo.forceLogoff = true;
                  }
                  // Stats
                  console.log(`===> Get Current Stats - PeriodType: ${this.state.PeriodType}, PeriodBack: ${this.state.PeriodBack}`);
                  CD = await CallOM("GetCurrentStats",storeLocation,this.state.PeriodType,0,"","",this.state.PeriodBack); // Get Current Statistics in a List structure - see GetCurrentStats  
                  if (CD) {
                    console.log(`current Daily Stats: ${JSON.stringify(CD)}`);
                    if (CD.d) {
                      let PeriodStartDate = new Date(CD.d[0]);
                      console.log(`startDate: ${PeriodStartDate}, 0: ${CD.d[0]}`)
                      this.setState({ PeriodStartDate });
                      let itemCount = CD.d[1];
                      let orderCount = CD.d[2];
                      let totalPeriodSales = CD.d[3];
                      let TotalTaxAmount = CD.d[4];
                      let TipAmount = CD.d[5];
                      let OptionCount = CD.d[6];
                      let OptionsPrice = CD.d[7];
                      let customerCount = CD.d[8];
                      this.setState({ itemCount });
                      this.setState({ orderCount });
                      this.setState({ totalPeriodSales });
                      this.setState({ TotalTaxAmount });
                      this.setState({ TipAmount });
                      this.setState({ TipAmount });
                      this.setState({ OptionCount });
                      this.setState({ OptionsPrice });
                      await this.setState({ customerCount });
                    }
                    else
                      SessionInfo.forceLogoff = true;
                  }
                  else
                    SessionInfo.forceLogoff = true;
                  //------------------- Get Store Stats for Past 6 periods - for the Sales Comparison graph ---------------------------------   
                  console.log(`===> Get SalesLocationPeriod - PeriodType: ${this.state.PeriodType}, PeriodBack: ${this.state.PeriodBack}`);
                  CD = await CallOM("GetSalesLocationPeriod",storeLocation,this.state.PeriodType,0,"","",this.state.PeriodBack); // Get Current Statistics in a List structure - see GetCurrentStats  
                  if (CD) {
                    //console.log(`current Daily Stats: ${JSON.stringify(CD)}`);
                    let PT = this.state.PeriodType;
                    if (CD.d) {
                      let rows = CD.d.rows;
                      this.setState({ storeCount: rows.length });
                      //console.log(`rows: ${JSON.stringify(rows)}`);
                      let row = rows[0];
                      //console.log(`row: ${JSON.stringify(row)}`);
                      let categories = [];
                      for (let ix = 6; ix >= 0; ix--) {
                        let curDate = new Date(row[ix])
                        if (PT == 12) // Hourly
                          categories.push(GetMonth(curDate.getMonth()) + ' ' + curDate.getDate() + ' ' + curDate.getHours() + ':00');
                        else if (PT == 4) // Monthly
                          categories.push(GetMonth(curDate.getMonth()));
                        else
                          categories.push(GetMonth(curDate.getMonth()) + ' ' + curDate.getDate());
                      }
                      this.setState({ categories });
                      console.log(`categories: ${JSON.stringify(categories)}`);
                      //let PeriodStartDate = new Date(CD.d[0]);
                      //this.setState({ PeriodStartDate });
                      // Get Data for each location
                      let locationCount = rows.length - 1;
                      if (locationCount > 8)
                        locationCount = 8;
                      this.setState({ locationCount });
                      let data = [];
                      let cnt = 10;
                      this.compUpdate = false;
                      for (let ix = 1; ix <= locationCount; ix++) {
                        //cnt = 10 + ix * 3;
                        row = rows[ix];
                        data = [];
                        // Note - When using numeric keys, the values are returned in the keys' numerical order
                        // So reverse the values
                        for (let jx = 6; jx >= 0; jx--) {
                          data.push(parseInt(row[jx.toString()]));
                          //data.push(parseInt(row[jx]));
                          //data.push(row[jx]);
                          //data[jx] = Number(dict[jx.toString()]);
                          //cnt += 7;
                          //data[jx] = cnt;
                        }
                        //console.log(`row${ix}: ${JSON.stringify(data)}`);
                        // 16Oct23 - for some reason when data 1-6 are updated - they show as 0 on the graph 
                        if (ix === 1)
                          this.setState({ data1: data });
                        else if (ix === 2)
                          this.setState({ data2: data });
                        else if (ix === 3)
                          this.setState({ data3: data });
                        else if (ix === 4)
                          this.setState({ data4: data });
                        else if (ix === 5)
                          this.setState({ data5: data });
                        else if (ix === 6)
                          this.setState({ data6: data });
                        else if (ix === 7)
                          this.setState({ data7: data });
                        else if (ix === 8)
                          this.setState({ data8: data });
                      }
                      this.compUpdate = true;
                    }
                    //this.forceUpdate();
                  }
                  console.log(`------------------------------ Finished Get Stats - PeriodType: ${this.state.PeriodType}, PeriodBack: ${this.state.PeriodBack} -----`);
                }
              }
            } catch (ex) {
              console.log(`Failure in appmain monitor - error ${ex.message}`);
            }
            finally {
              this.inMonitor = false;
            }
          }
          this.setTimer();
        }
      }
    }
  }
  //------ Help Information -------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
  showHelp = async (key) => {
    if (this.state.showHelpListPopup == true) { // Close Help
      this.setState({ showHelpListPopup: false });
      this.setState({ showHelpDetail: false });
    } else {
      if (!key)
        key = '';
      this.setState({ showHelpDetail: false });
      let HelpPages = await getHelpTopics(key);
      //console.log("caller HelpPages: " + JSON.stringify(HelpPages));
      this.setState({ HelpPages });
      this.setState({ showHelpListPopup: true });
    }
  }
  closeHelpList = () => {
    this.setState({ showHelpListPopup: false });
  }
  onHelpItemClick = async (item) => {
    let helpDetail = await getHelpDetail(item.HelpPageID);
    this.setState({ helpDetail });
    this.setState({ showHelpDetail: true });
  }
  closeHelpTime = async () => {
    this.setState({ showHelpDetail: false });
  }
  onObjectHelp = async (objectID) => {
    let helpDetail = await getHelpDetail(objectID,2);
    this.setState({ helpDetail });
    this.setState({ showHelpDetail: true });
  }
  closeHelpTime = async () => {
    this.setState({ showHelpDetail: false });
  }
  // Help List Layout
  HelpPageHeader = () => {
    return (
      <ListViewHeader className='listHelpHeader' onClick={(e) => this.closeHelpList()} >
        <div className='listHeaderLeft' onClick={(e) => this.closeHelpList()}>
          <span className="listHelpTitle">{SessionInfo.HelpTitle}</span>
        </div>
        <div className='listHeaderRight' onClick={(e) => this.closeHelpList()}>
          <Button id="cancelButton" icon="cancel" look="outline" className="medButton" onClick={this.closeHelpList} >EXIT HELP</Button>
        </div>
      </ListViewHeader>
    );
  }
  HelpPageFormat = props => {
    let item = props.dataItem;
    return (
      <div>
        <div className="listHelpRow" onClick={(e) => this.onHelpItemClick(item)}>
          <div className="listColA">
            <span className="listHelpItemTitle" >{item.HelpTitle}&nbsp;&nbsp;&nbsp;</span>
            <div dangerouslySetInnerHTML={{ __html: item.HelpContent }}></div>
          </div>
        </div>
      </div>
    );
  }
  HelpPageFooter = () => {
    //const [state, onCheckout] = React.useContext(StateContext);
    return (
      <ListViewFooter className='listHelpFooter' onClick={(e) => this.closeHelpList()} >
        <div className='listHeaderRight' onClick={(e) => this.closeHelpList()}>
          <Button id="cancelButton" icon="cancel" look="outline" className="medButton" onClick={this.closeHelpList} >EXIT HELP</Button>
        </div>
      </ListViewFooter>
    );
  }
  ShowFieldHelp = async (key) => {
    console.log(`Field Help - Key: ${key}`);
    this.onObjectHelp(key);
    //this.showHelp(key);
    //console.log(`Field Help: ${JSON.stringify(item)}`);
  }
  //------ End of Help -------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
  //-------------------------------------------------------------------
  saveImage = (event) => {
    console.log(`saveImage`);
  }
  //-------------------------------------------------------------------
  // SetValues
  onSetPromoNumber = async (e) => {
    //this.setState({ setPromoNumber: 1 });
    //this.setState({ promoNumberID: 6 });
    //console.log("set Promotion: " + this.state.setPromoNumber);
    try {
      console.log(`set Promotion: ${this.state.setPromoNumber} to: ${this.state.promoNumberID}`);
      await CallOM("SetRootPromotion",0,this.state.setPromoNumber,1,this.state.promoNumberID); // Set the Promotion Number
    }
    catch (ex) {
      console.log(`Error in onSetPromoNumber: ${ex}`);
    }
  }
  connectTCPIPServer = async (e) => {
    try {
      let tableID = 1;
      let key = 2;
      console.log(`connectTCPIPServer table: ${tableID}, key: ${key}`);
      let CD = await accessOMAPI(130,0,tableID,key);
      console.log("Returned from Connect");
      if (CD)
        console.log("connectTCPIPServer post CD: " + JSON.stringify(CD));
    } catch (error) {
      console.log("connectTCPIPServer Error: " + error.message);
    }
  }
  disconnectTCPIPServer = async (e) => {
    try {
      let tableID = 1;
      let key = 2;
      console.log(`disconnectTCPIPServer `);
      let CD = await accessOMAPI(131,0,tableID,key);
      console.log("Returned from Disconnect");
      if (CD)
        console.log("disconnectTCPIPServer post CD: " + JSON.stringify(CD));
    } catch (error) {
      console.log("disconnectTCPIPServer Error: " + error.message);
    }
  }
  sendOMOLWtoTCPIPServer = async (e) => {
    try {
      let tableID = 1;
      let key = 2;
      console.log(`send short OMOLW `);
      let CD = await accessOMAPI(132,0,tableID,key);
      console.log("Returned from send OMOLW");
      if (CD)
        console.log("Send OMOLW to TCPIPServer post CD: " + JSON.stringify(CD));
    } catch (error) {
      console.log("Send OMOLW to TCPIPServer Error: " + error.message);
    }
  }
  sendLOMOLWtoTCPIPServer = async (e) => {
    try {
      let tableID = 1;
      let key = 2;
      console.log(`send Large OMOLW `);
      let CD = await accessOMAPI(133,0,tableID,key);
      console.log("Returned from send Large OMOLW");
      if (CD)
        console.log("Send OMOLW to TCPIPServer post CD: " + JSON.stringify(CD));
    } catch (error) {
      console.log("Send OMOLW to TCPIPServer Error: " + error.message);
    }
  }
  //-------------------------------------------------------------------
  onExpandChange = (event) => {
    event.item.expanded = !event.item.expanded;
    this.forceUpdate();
  }
  handleTopTabSelect = async (e) => { // Tab Selection
    await this.selectTab(e.selected);
  }
  selectTab = async (tabNumber) => {
    //console.log("selectTab - TabN=" + tabNumber);
    try {
      if (tabNumber === 0) {
        this.currentShow = 1;
        this.state.bottomDisplay = 0;
      }
      else if (tabNumber === 1) {
        this.currentShow = 1;
        this.state.bottomDisplay = 0;
      }
      //else if (event.item.text === "Hide Header") { // 
      //  event.item.selected = true;
      //  SessionInfo.displayHeader = false;
      //  SessionInfo.displayFooter = false;
      //  document.documentElement.style.setProperty('--main-top', '0px');
      //  document.documentElement.style.setProperty('--main-bottom', '0px');
      //  event.item.selected = !event.item.selected;
      //  event.item.text = "Show Header";
      //}
      else if (tabNumber === 2) { // Stress Test
        SessionInfo.displayHeader = true;
        SessionInfo.displayFooter = true;
        this.currentShow = 3;
        this.state.bottomDisplay = 6;
      }
      else if (tabNumber === 3) { // Set Values
        SessionInfo.displayHeader = true;
        SessionInfo.displayFooter = true;
        this.currentShow = 4;
        this.state.bottomDisplay = 5;
      }
      this.forceUpdate();
    }
    catch (ex) {
      displayError("Error in Tab Select: " + ex);
    }
  }
  //  useEffect(() => {
  //    // Effect goes here  
  //    console.log("use Effect Active");
  //});

  //useEffect(() => {
  //  //displayMessage("started");
  //  //return function cleanup() {
  //  //return () => {
  //  //  displayMessage("finished");
  //  //};
  //}); // add if want to execute based on change, [orderNone]););
  //-------------------------------------------------------------------------------------------------------------
  render() {
    return (
      <div>
        {SessionInfo.loggedOn === false && SessionInfo.keyLogon === false ?
          <div id="AppMain" className="pageMain">
            {SessionInfo.displayHeader === true ?
              <PageHeader L1='Home' Select='0' PageTitle='Interact Suite X ' /> : null}
            {/*<div id="mainCntr">*/}
            {/*  {SessionInfo.isConfirmationOnly ?*/}
            {/*    this.props.navigate("/Confirmation") :*/}
            {/*    this.props.navigate("/Login")}*/}
            {/*</div>*/}
            {SessionInfo.displayFooter === true ?
              <PageFooter L1='Home' /> : null}
          </div>
          :
          <div id="AppMain" className="pageMain">
            <PageSide />
            {SessionInfo.displayHeader === true ?
              <PageHeader L1='Home' Select='0' PageTitle='Interact Suite - Manager ' /> : null}
            <div id="mainCntr">
              <Splitter style={{ height: '100%',border: 'none' }} panes={this.state.verticalPanes} orientation={'vertical'} onChange={this.onNestedLayoutChange}>
                <Splitter panes={this.state.horizontalPanes} onChange={this.onLayoutChange}>
                  <div id="splitterLeft">
                    {/*<div id="div1" className="editInside">
                      <TreeView
                        data={tree}
                        expandIcons={true}
                        onExpandChange={this.onExpandChange}
                        onItemClick={this.onItemClick}
                        aria-multiselectable={true}
                      />
                    </div>*/}
                  </div>
                  <div id="splitterRight" className="splitPaneContent">
                    {this.currentShow === 1 ?
                      <div className="editTab">
                        <div className="editTabLeftNarrow">
                          <div id="div1" className="editTabLeftNarrowTop">
                            <h4>Period Selection</h4>
                            <br />
                            <div className="editField">
                              <span className="editFieldLabel">Period(s) Back</span>
                              <NumericTextBox name="PeriodBack" value={this.state.PeriodBack} min={0} max={60} step={1} onChange={evt => this.chgFldValMon(evt)} width={100} className="NumTxtExtShortInput" />
                            </div>
                            <div id='div2' className='editField'>
                              <span className='editFieldLabel'>Period Type</span>
                              <div className='editDDPos'>
                                <DropDownList data={this.PeriodsFixedValues} textField='ddName' dataItemKey='ddID' value={this.PeriodsFixedValues.find(c => c.ddID === this.state.PeriodType)} name='PeriodType' onChange={evt => this.chgDDFldValMon(evt)} className='editInputDDSh' />
                              </div>
                            </div>
                          </div>
                          <div id="div1" className="editTabLeftNarrowBot">
                            <h4>Current Period</h4>
                            <div id="div2" className="editField">
                              <span className="editFieldLabel">Period Date</span>
                              <br />
                              <DateTimePicker value={new Date(this.state.PeriodStartDate)} name="TxDate" className="editDateInputShort" readOnly="{true}" />
                            </div>
                          </div>
                          {/*maxCurrentOrders = 1000;
                          xCO1 = 250; xCO2 = 500; xCO3 = 750;
                          maxTipValue = 2000.0;
                          xTP1 = 500.0; xTP2 = 1000.0; xTP3 = 1500.0;
                          maxTotalTaxes = 2000.0;
                          xTT1 = 500.0; xTT2 = 1000.0; xTT3 = 1500.0;
                          maxTotalSales = 20000.0;
                          xTS1 = 5000.0; xTS2 = 10000.0; xTS3 = 15000.0;
                          maxMenuItems = 10000;
                          xMI1 = 2500; xMI2 = 5000; xMI3 = 7500;
                          maxOptionCnt = 10000;
                          xOC1 = 2500; xOC2 = 5000; xOC3 = 7500;
                          maxOptionValue = 2000.0;
                          xOV1 = 500.0; xOV2 = 1000.0; xOV3 = 1500.0;
                          maxCustomers = 200;
                          xC1 = 50; xC2 = 100; xC3 = 150; */}
                          <div id="div1" className="editTabRightNarrowTop">
                            <h4>Current Orders for Period</h4>
                            <div className="editField">
                              <span className="editFieldLabel">Orders for Period: &nbsp; </span>
                              <label style={{ fontSize: 'large',color: this.labelColor,fontWeight: 'bold',}}>{this.state.orderCount}</label>
                            </div>
                            <br />
                            <RadialGauge
                              pointer={{ value: this.state.orderCount }}
                              transitions={false}
                              colors
                              scale={{
                                labels: { format: 'n',color: this.tickColor,visible: true },
                                majorTicks: { visible: true,color: this.tickColor },
                                minorTicks: { visible: true,color: this.tickColor },
                                rangeSize: 5,
                                rangeLineCap: 'round',
                                startAngle: 0,
                                endAngle: 270,
                                min: 0,
                                max: this.maxCurrentOrders,
                                reverse: false,
                                ranges: [{ to: this.xCO1,color: this.colorRCO[0] },{ from: this.xCO1,o: this.xCO2,color: this.colorRCO[1] },{ from: this.xCO2,to: this.xCO3,color: this.colorRCO[2] },{from: this.xCO3,color: this.colorRCO[3] }]
                              }} />
                          </div>
                          <div id="div1" className="editTabRightNarrowBot">
                            <h4>Current Menu Items for Period</h4>
                            <div className="editField">
                              <span className="editFieldLabel">Order Items for Period: &nbsp; </span>
                              <label style={{ fontSize: 'large',color: this.labelColor,fontWeight: 'bold',}}>{this.state.itemCount}</label>
                            </div>
                            <br />
                            <RadialGauge
                              pointer={{ value: this.state.itemCount }}
                              transitions={false}
                              colors
                              scale={{
                                labels: { format: 'n',color: this.tickColor,visible: true },
                                majorTicks: { visible: true,color: this.tickColor },
                                minorTicks: { visible: true,color: this.tickColor },
                                rangeSize: 5,
                                rangeLineCap: 'round',
                                startAngle: 0,
                                endAngle: 270,
                                min: 0,
                                max: this.maxMenuItems,
                                reverse: false,
                                ranges: [{ to: this.xMI1,color: this.colorRMI[0] },{ from: this.xMI1,to: this.xMI2,color: this.colorRMI[1] },{ from: this.xMI2,to: this.xMI3,color: this.colorRMI[2] },{ from: this.xMI3,color: this.colorRMI[3] }]
                              }} />
                          </div>
                        </div>
                        <div className="editTabMiddle">
                          <div id="div1" className="graphTabNarrowLeftTop">
                            <h4>Tip Value for Period</h4>
                            <div className="editField">
                              <span className="editFieldLabel">Tips for Period: &nbsp; </span>
                              <label style={{ fontSize: 'large',color: this.labelColor,fontWeight: 'bold',}}>{this.moneyFormat(this.state.TipAmount)}</label>
                            </div>
                            <br />
                            <RadialGauge
                              pointer={{ value: this.state.TipAmount }}
                              transitions={false}
                              colors
                              scale={{
                                labels: { format: 'c',color: this.labelColor,visible: true },
                                majorTicks: { visible: true,color: '#002aff' },
                                minorTicks: { visible: true,color: '#00ff00' },
                                rangeSize: 9,
                                rangeLineCap: 'round',
                                startAngle: 0,
                                endAngle: 180,
                                min: 0,
                                max: this.maxTipValue,
                                reverse: false,
                                ranges: [{ to: this.xTP1,color: this.colorRTP[0] },{ from: this.xTP1,to: this.xTP2,color: this.colorRTP[1] },{ from: this.xTP2,to: this.xTP3,color: this.colorRTP[2] },{ from: this.xTP3,color: this.colorRTP[3] }]
                              }} />
                          </div>
                          <div id="div1" className="graphTabNarrowRightTop">
                            <h4>Total Taxes for Period</h4>
                            <div className="editField">
                              <span className="editFieldLabel">Taxes for Period: &nbsp; </span>
                              <label style={{ fontSize: 'large',color: this.labelColor,fontWeight: 'bold',}}>{this.moneyFormat(this.state.TotalTaxAmount)}</label>
                            </div>
                            <br />
                            <RadialGauge
                              pointer={{ value: this.state.TotalTaxAmount }}
                              transitions={false}
                              colors
                              scale={{
                                labels: { format: 'c',color: this.labelColor,visible: true },
                                majorTicks: { visible: true,color: '#002aff' },
                                minorTicks: { visible: true,color: '#00ff00' },
                                rangeSize: 9,
                                rangeLineCap: 'round',
                                startAngle: 0,
                                endAngle: 180,
                                min: 0,
                                max: this.maxTotalTaxes,
                                reverse: false,
                                ranges: [{ to: this.xTT1,color: this.colorRTT[0] },{ from: this.xTT1,to: this.xTT2,color: this.colorRTT[1] },{ from: this.xTT2,to: this.xTT3,color: this.colorRTT[2] },{ from: this.xTT3,color: this.colorRTT[3] }]
                              }} />
                          </div>
                          <div id="div1" className="graphTabNarrowLeftBot">
                            <h4>Option Count for Period</h4>
                            <div className="editField">
                              <span className="editFieldLabel">Option Count for Period: &nbsp; </span>
                              <label style={{ fontSize: 'large',color: this.labelColor,fontWeight: 'bold',}}>{this.state.OptionCount}</label>
                            </div>
                            <br />
                            <RadialGauge
                              pointer={{ value: this.state.OptionCount }}
                              transitions={false}
                              colors
                              scale={{
                                labels: { format: 'n',color: this.tickColor,visible: true },
                                majorTicks: { visible: true,color: this.tickColor },
                                minorTicks: { visible: true,color: this.tickColor },
                                rangeSize: 5,
                                rangeLineCap: 'round',
                                startAngle: 0,
                                endAngle: 270,
                                min: 0,
                                max: this.maxOptionCount,
                                reverse: false,
                                ranges: [{ to: this.xOC1,color: this.colorROC[0] },{ from: this.xOC1,to: this.xOC2,color: this.colorROC[1] },{ from: this.xOC2,to: this.xOC3,color: this.colorROC[2] },{ from: this.xOC3,color: this.colorROC[3] }]
                              }} />
                          </div>
                          <div id="div1" className="graphTabNarrowRightBot">
                            <h4>Options Value for Period</h4>
                            <div className="editField">
                              <span className="editFieldLabel">Options for Period: &nbsp; </span>
                              <label style={{ fontSize: 'large',color: this.labelColor,fontWeight: 'bold',}}>{this.moneyFormat(this.state.OptionsPrice)}</label>
                            </div>
                            <br />
                            <RadialGauge
                              pointer={{ value: this.state.customerCount }}
                              transitions={false}
                              colors
                              scale={{
                                labels: { format: 'c',color: this.labelColor,visible: true },
                                majorTicks: { visible: true,color: '#002aff' },
                                minorTicks: { visible: true,color: '#00ff00' },
                                rangeSize: 9,
                                rangeLineCap: 'round',
                                startAngle: 0,
                                endAngle: 180,
                                min: 0,
                                max: this.maxOptionValue,
                                reverse: false,
                                ranges: [{ to: this.xOV1,color: this.colorROV[0] },{ from: this.xOV1,to: this.xOV2,color: this.colorROV[1] },{ from: this.xOV2,to: this.xOV3,color: this.colorROV[2] },{ from: this.xOV3,color: this.colorROV[3] }]
                              }} />
                          </div>
                        </div>
                        <div className="editTabRightNarrow">
                          <div id="div1" className="graphTabNarrowTop">
                            <h4>Total Sales for Period</h4>
                            <div className="editField">
                              <span className="editFieldLabel">Sales for Period: &nbsp; </span>
                              <label style={{ fontSize: 'large',color: this.labelColor,fontWeight: 'bold',}}>{this.moneyFormat(this.state.totalPeriodSales)}</label>
                            </div>
                            <br />
                            <RadialGauge
                              pointer={{
                                value: this.state.totalPeriodSales,
                              }}
                              transitions={false}
                              scale={{
                                labels: { format: 'c',color: this.labelColor,visible: true },
                                majorTicks: { visible: true,color: '#002aff' },
                                minorTicks: { visible: true,color: '#00ff00' },
                                rangeSize: 9,
                                rangeLineCap: 'round',
                                startAngle: 0,
                                endAngle: 180,
                                min: 0,
                                max: this.maxTotalSales,
                                reverse: false,
                                ranges: [{ to: this.xTS1,color: this.colorRTS[0] },{ from: this.xTS1,to: this.xTS2,color: this.colorRTS[1] },{ from: this.xTS2,to: this.xTS3,color: this.colorRTS[2] },{ from: this.xTS3,color: this.colorRTS[3] }]
                              }} />
                          </div>
                          <div id="div1" className="editTabNarrowBot">
                            <h4>Active Customers for Period</h4>
                            <div className="editField">
                              <span className="editFieldLabel">Customers for Period: &nbsp; </span>
                              <label style={{ fontSize: 'large',color: this.labelColor,fontWeight: 'bold',}}>{this.state.customerCount}</label>
                            </div>
                            <br />
                            <RadialGauge
                              pointer={{ value: this.state.customerCount }}
                              transitions={false}
                              colors
                              scale={{
                                labels: { format: 'n',color: this.tickColor,visible: true },
                                majorTicks: { visible: true,color: this.tickColor },
                                minorTicks: { visible: true,color: this.tickColor },
                                rangeSize: 5,
                                rangeLineCap: 'round',
                                startAngle: 0,
                                endAngle: 270,
                                min: 0,
                                max: this.maxActiveCustomers,
                                reverse: false,
                                ranges: [{ to: this.xAC1,color: this.colorRAC[0] },{ from: this.xAC1,to: this.xAC2,color: this.colorRAC[1] },{ from: this.xAC2,to: this.xAC3,color: this.colorRAC[2] },{ from: this.xAC3,color: this.colorRAC[3] }]
                              }} />
                          </div>
                        </div>
                      </div> : null}
                    {this.currentShow === 2 ?
                      <div className="editTab">  {/*------- Stress TEST --------------*/}
                        <div className="editTabLeftNarrow">
                          <div id="div1" className="editInside">
                            <div className="editField">
                              <span className="editFieldLabel">Times Per Second</span>
                              <input value={this.state.test1TimesPerSecond} name="test1TimesPerSecond" onChange={evt => this.chgFldVal(evt)} className="editNumShortInput" />
                            </div>
                            <div className="editField">
                              <span className="editFieldLabel">Custom Order #</span>
                              <input value={this.state.test1CustomOrderID} name="test1CustomOrderID" onChange={evt => this.chgFldVal(evt)} className="editNumShortInput" />
                            </div>
                            <br />
                            <div className="editField">
                              <span className="editFieldLabel">   Start Time   </span>
                              <DateTimePicker value={new Date(this.state.testStartTime)} name="testStartTime" onChange={evt => this.chgFldVal(evt)} className="editDateInput" readOnly="{true}" />
                            </div>
                            <br />
                            <div className="editField">
                              <span className="editFieldLabel">Send Tx Count</span>
                              <input value={this.state.test1SendTxCount} name="test1SendTxCount" onChange={evt => this.chgFldVal(evt)} className="editNumShortInput" readOnly="{true}" />
                            </div>
                            <div className="editField">
                              <span className="editFieldLabel">Return Data Length</span>
                              <input value={this.state.test1ReturnLength} name="test1ReturnLength" onChange={evt => this.chgFldVal(evt)} className="editNumShortInput" readOnly="{true}" />
                            </div>
                            <br />
                            <div className="editField">
                              <Button icon="refresh" color="primary" onClick={this.onStartStressTest1}>Start Test1</Button>
                            </div>
                            <br />
                            <div className="editField">
                              <Button icon="refresh" color="primary" onClick={this.onStopTests}>Stop Test1</Button>
                            </div>
                          </div>
                        </div>
                        <div className="editTabMiddle">
                          <div id="div1" className="editInside">
                            <h4>Total Transactions Sent</h4>
                            <br />
                            <RadialGauge
                              pointer={{
                                value: this.state.test1SendTxCount
                              }}
                              transitions={false}
                              scale={{
                                labels: { format: 'n',color: 'green',visible: true },
                                majorTicks: { visible: true,color: '#002aff' },
                                minorTicks: { visible: true,color: '#00ff00' },
                                rangeSize: 9,
                                rangeLineCap: 'round',
                                startAngle: 0,
                                endAngle: 180,
                                min: 0,
                                max: 40000,
                                reverse: false,
                                ranges: [{ to: 10000,color: '#c2f0c2' },{ rom: 10000,o: 20000,color: '#86f086' },{ from: 20000,to: 30000,color: '#0ff5a0' },{ from: 30000,color: '#24ed24' }]
                              }} />
                          </div>
                        </div>
                        <div className="editTabRightNarrow">
                          <div id="div1" className="editInside">
                            <h4>Total Transactions this Hour</h4>
                            <br />
                            <RadialGauge
                              pointer={{
                                value: this.state.transactionCount
                              }}
                              transitions={false}
                              scale={{
                                labels: { format: 'n',color: this.labelColor,visible: true },
                                majorTicks: { visible: true,color: '#002aff' },
                                minorTicks: { visible: true,color: '#00ff00' },
                                rangeSize: 9,
                                rangeLineCap: 'round',
                                startAngle: 0,
                                endAngle: 180,
                                min: 0,
                                max: 80000,
                                reverse: false,
                                ranges: [{ to: 20000,color: '#c2f0c2' },{ rom: 20000,o: 40000,color: '#86f086' },{ from: 40000,to: 60000,color: '#0ff5a0' },{ from: 60000,color: '#24ed24' }]
                              }} />
                          </div>
                        </div>
                      </div> : null}
                    {this.currentShow === 3 ?
                      <div className="editTab">  {/*------- Station Order List --------------*/}
                        <div className="editTabLeftNarrow">
                          <div id="div1" className="editInside">
                            <div className="editField">
                              <span className="editFieldLabel">Times Per Second</span>
                              <input value={this.state.test1TimesPerSecond} name="test1TimesPerSecond" onChange={evt => this.chgFldVal(evt)} className="editNumShortInput" />
                            </div>
                            <div className="editField">
                              <span className="editFieldLabel">Custom Order #</span>
                              <input value={this.state.test1CustomOrderID} name="test1CustomOrderID" onChange={evt => this.chgFldVal(evt)} className="editNumShortInput" />
                            </div>
                            <br />
                            <div className="editField">
                              <span className="editFieldLabel">   Start Time   </span>
                              <div className='editDDPos'>
                                <DateTimePicker value={new Date(this.state.testStartTime)} name="testStartTime" onChange={evt => this.chgFldVal(evt)} className="editDateInput" readOnly="{true}" />
                              </div>
                            </div>
                            <br />
                            <div className="editField">
                              <span className="editFieldLabel">Send Tx Count</span>
                              <input value={this.state.test1SendTxCount} name="test1SendTxCount" onChange={evt => this.chgFldVal(evt)} className="editNumShortInput" readOnly="{true}" />
                            </div>
                            <div className="editField">
                              <span className="editFieldLabel">Return Data Length</span>
                              <input value={this.state.test1ReturnLength} name="test1ReturnLength" onChange={evt => this.chgFldVal(evt)} className="editNumShortInput" readOnly="{true}" />
                            </div>
                            <br />
                            <div className="editField">
                              <Button icon="refresh" color="primary" onClick={this.onStartStressTest1}>Start Test1</Button>
                            </div>
                            <br />
                            <div className="editField">
                              <Button icon="refresh" color="primary" onClick={this.onStopTests}>Stop Tests</Button>
                            </div>
                          </div>
                        </div>
                        <div className="editTabMiddle">
                          <div id="div1" className="editInside">
                            <h4>Total Transactions Sent</h4>
                            <br />
                            <RadialGauge
                              pointer={{
                                value: this.state.test1SendTxCount
                              }}
                              transitions={false}
                              scale={{
                                labels: { format: 'n',color: this.labelColor,visible: true },
                                majorTicks: { visible: true,color: '#002aff' },
                                minorTicks: { visible: true,color: '#00ff00' },
                                rangeSize: 9,
                                rangeLineCap: 'round',
                                startAngle: 0,
                                endAngle: 180,
                                min: 0,
                                max: 40000,
                                reverse: false,
                                ranges: [{ to: 10000,color: '#c2f0c2' },{ rom: 10000,o: 20000,color: '#86f086' },{ from: 20000,to: 30000,color: '#0ff5a0' },{ from: 30000,color: '#24ed24' }]
                              }} />
                          </div>
                        </div>
                        <div className="editTabRightNarrow">
                          <div id="div1" className="editInside">
                            <h4>Total Transactions this Hour</h4>
                            <br />
                            <RadialGauge
                              pointer={{
                                value: this.state.transactionCount
                              }}
                              transitions={false}
                              scale={{
                                labels: { format: 'n',color: this.labelColor,visible: true },
                                majorTicks: { visible: true,color: '#002aff' },
                                minorTicks: { visible: true,color: '#00ff00' },
                                rangeSize: 9,
                                rangeLineCap: 'round',
                                startAngle: 0,
                                endAngle: 180,
                                min: 0,
                                max: 80000,
                                reverse: false,
                                ranges: [{ to: 20000,color: '#c2f0c2' },{ rom: 20000,o: 40000,color: '#86f086' },{ from: 40000,to: 60000,color: '#0ff5a0' },{ from: 60000,color: '#24ed24' }]
                              }} />
                          </div>
                        </div>
                      </div> : null}
                    {this.currentShow === 4 ?
                      <div className="editTab">  {/*------- Panel for testing connection to the OM using TCPIP Server --------------*/}
                        <div className="editTabLeftNarrow">
                          <div id="div1" className="editInside">
                            <div className="editField">
                              <span className="editFieldLabel">Set Promo Number</span>
                              <input value={this.state.setPromoNumber} name="setPromoNumber" onChange={evt => this.chgFldVal(evt)} className="editNumShortInput" />
                            </div>
                            <div className="editField">
                              <span className="editFieldLabel">Promo Number ID</span>
                              <input value={this.state.promoNumberID} name="promoNumberID" onChange={evt => this.chgFldVal(evt)} className="editNumShortInput" />
                            </div>
                            <br />
                            <div className="editField">
                              <Button icon="refresh" color="primary" onClick={this.onSetPromoNumber}>Set Promo ID</Button>
                            </div>
                            <div>
                              <Button icon="refresh" color="primary">Changed primary color</Button>
                            </div>
                            <br />
                            <div className="editField">
                              <Button icon="refresh" color="primary" onClick={this.connectTCPIPServer}>Connect to TCPIP Server</Button>
                            </div>
                            <div className="editField">
                              <Button icon="refresh" color="primary" onClick={this.disconnectTCPIPServer}>Disconnect from TCPIP Server</Button>
                            </div>
                            <div className="editField">
                              <Button icon="refresh" color="primary" onClick={this.sendOMOLWtoTCPIPServer}>Send OMOLW to TCPIP Server</Button>
                            </div>
                            <div className="editField">
                              <Button icon="refresh" color="primary" onClick={this.sendLOMOLWtoTCPIPServer}>Send Large OMOLW to TCPIP Server</Button>
                            </div>
                          </div>
                        </div>
                        <div className="editTabMiddle">
                          <div id="div1" className="editInside">
                          </div>
                        </div>
                        <div className="editTabRightNarrow">
                          <div id="div1" className="editInside">
                          </div>
                        </div>
                      </div> : null}
                  </div>
                </Splitter>
                {/*------------------------- Bottom ------------------------------*/}
                <div id="splitterBottom" onMouseDown={e => e.preventDefault()}>
                  {this.state.bottomDisplay === 0 ?
                    <Chart>
                      <ChartTitle text="Sales by Store" />
                      <ChartCategoryAxis>
                        <ChartCategoryAxisItem title={{ text: 'Periods' }} categories={this.state.categories} />
                      </ChartCategoryAxis>
                      <ChartSeries>
                        <ChartSeriesItem type="line" name={this.state.dataName[1]} data={this.state.data1} color="#0f2df5" />
                        {this.state.locationCount > 1 ?
                          <ChartSeriesItem type="line" name={this.state.dataName[2]} data={this.state.data2} /> : null}
                        {this.state.locationCount > 2 ?
                          <ChartSeriesItem type="line" name={this.state.dataName[3]} data={this.state.data3} /> : null}
                        {this.state.locationCount > 3 ?
                          <ChartSeriesItem type="line" name={this.state.dataName[4]} data={this.state.data4} color="#f50ff5" /> : null}
                        {this.state.locationCount > 4 ?
                          <ChartSeriesItem type="line" name={this.state.dataName[5]} data={this.state.data5} /> : null}
                        {this.state.locationCount > 5 ?
                          <ChartSeriesItem type="line" name={this.state.dataName[6]} data={this.state.data6} />: null}
                      </ChartSeries>
                    </Chart> : null}
                  {this.state.bottomDisplay === 1 ?
                    <Scheduler data={sampleData} defaultDate={displayDate} defaultView="month">
                      <AgendaView />
                      <TimelineView />
                      <DayView startTime="07:00" />
                      <WeekView />
                      <MonthView />
                    </Scheduler> : null}
                  {this.state.bottomDisplay === 2 ?
                    <div className="editTab">
                      <div className="editTabLeft">
                        <div id="div1" className="editInside">
                          <h4>Message</h4>
                          <br />
                          <div id="div2" className="editField">
                            <span className="editFieldLabel">Notification Message</span>
                            <textarea value={this.state.NotificationMessage} name="NotificationMessage" onChange={evt => this.chgFldVal(evt)} className="editTAInputWide" />
                          </div>
                          <br />
                          <br />
                          <div id="div3" className="editField">
                            <span className="editFieldLabel">Notification Type</span>
                            <div className="editDDPos">
                              <DropDownList data={this.state.NotificationTypes} textField="ddName" dataItemKey="ddID" value={this.state.NotificationTypes.find(c => c.ddID === this.state.NotificationTypeID)} name="NotificationTypeID" onChange={evt => this.chgDDFldVal(evt)} className="editInputDD" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="editTabRight">
                        <div id="div1" className="editInside">
                          <h4>Send To Information</h4>
                          <br />
                          <div id="div2" className="editField">
                            <span className="editFieldLabel">Store Location</span>
                            <div className="editDDPos">
                              <DropDownList data={this.state.StoreLocations} textField="ddName" dataItemKey="ddID" value={this.state.StoreLocations.find(c => c.ddID === this.state.StoreLocationID)} name="StoreLocationID" onChange={evt => this.chgDDFldVal(evt)} className="editInputDD" />
                            </div>
                          </div>
                          <div id="div2" className="editField">
                            <span className="editFieldLabel">Store Group</span>
                            <div className="editDDPos">
                              <DropDownList data={this.state.StoreGroups} textField="ddName" dataItemKey="ddID" value={this.state.StoreGroups.find(c => c.ddID === this.state.StoreGroupsID)} name="StoreGroupsID" onChange={evt => this.chgDDFldVal(evt)} className="editInputDD" />
                            </div>
                          </div>
                          <div id="div2" className="editField">
                            <span className="editFieldLabel">Notification Date</span>
                            <div className="editDDPos">
                              <DateTimePicker value={new Date(this.state.NotificationDateTime)} name="NotificationDateTime" onChange={evt => this.chgFldVal(evt)} className="editDateInput" />
                            </div>
                          </div>
                          <div id="div2" className="editField">
                            <span className="editFieldLabel">Expiry Date Time</span>
                            <div className="editDDPos">
                              <DateTimePicker value={new Date(this.state.ExpiryDateTime)} name="ExpiryDateTime" onChange={evt => this.chgFldVal(evt)} className="editDateInput" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> : null}
                  {this.state.bottomDisplay === 15 ?
                    <div className="editTab">
                      <div className="editTabLeft">
                        <div id="div1" className="editInside">
                          <h4>Receipt Control</h4>
                          <div id="div2" className="editField">
                            <span className="editFieldLabel">Customer</span>
                            <input value={this.state.CustomerID} name="CustomerID" onChange={evt => this.chgFldVal(evt)} className="editInput" readOnly="{true}" />
                          </div>
                          <div id="div2" className="editField">
                            <span className="editFieldLabel">Order Status</span>
                            <input value={this.state.CustomOrderStatus} name="CustomOrderStatus" onChange={evt => this.chgFldVal(evt)} className="editInput" readOnly="{true}" />
                          </div>
                          <div id="div2" className="editField">
                            <span className="editFieldLabel">Order Type</span>
                            <input value={this.state.CustomOrderType} name="CustomOrderType" onChange={evt => this.chgFldVal(evt)} className="editInput" readOnly="{true}" />
                          </div>
                          <div id="div2" className="editField">
                            <span className="editFieldLabel">Order Creation Date</span>
                            <input value={this.state.OrderCreationDate < new Date(2000,1,1) ? "" : new Intl.DateTimeFormat("en-CA",{ dateStyle: "long",timeStyle: "short" }).format(this.state.OrderCreationDate)} name="OrderCreationDate" onChange={evt => this.chgFldVal(evt)} className="editInput" readOnly="{true}" />
                          </div>
                          <div id="div2" className="editField">
                            <span className="editFieldLabel">Order Paid Date</span>
                            <input value={this.state.OrderPaidDate < new Date(2000,1,1) ? "" : new Intl.DateTimeFormat("en-CA",{ dateStyle: "long",timeStyle: "short" }).format(this.state.OrderPaidDate)} name="OrderPaidDate" onChange={evt => this.chgFldVal(evt)} className="editInput" readOnly="{true}" />
                          </div>
                          <div className="editField">
                            <span className="editFieldLabel">Payment Method</span>
                            <input value={this.state.PaymentMethodID} name="PaymentMethodID" onChange={evt => this.chgFldVal(evt)} className="editInput" readOnly="{true}" />
                          </div>
                        </div>
                      </div>
                      <div className="editTabRight">
                        <div id="div1" className="editInside">
                          <h4>Receipt Totals</h4>
                          <div id="div2" className="editField">
                            <span className="editFieldLabel">Total Price</span>
                            <input value={this.moneyFormat(this.state.TotalPrice)} name="TotalPrice" onChange={evt => this.chgMoneyFldVal(evt)} className="editMoneyInput" readOnly="{true}" />
                          </div>
                          <div id="div2" className="editField">
                            <span className="editFieldLabel">Total Amount</span>
                            <input value={this.moneyFormat(this.state.TotalAmount)} name="TotalAmount" onChange={evt => this.chgMoneyFldVal(evt)} className="editMoneyInput" readOnly="{true}" />
                          </div>
                          <div id="div2" className="editField">
                            <span className="editFieldLabel">Discount Amount</span>
                            <input value={this.moneyFormat(this.state.DiscountAmount)} name="DiscountAmount" onChange={evt => this.chgMoneyFldVal(evt)} className="editMoneyInput" readOnly="{true}" />
                          </div>
                          <div className="editField">
                            <span className="editFieldLabel">Credit Amount Used</span>
                            <input value={this.moneyFormat(this.state.CreditAmountUsed)} name="CreditAmountUsed" onChange={evt => this.chgMoneyFldVal(evt)} className="editMoneyInput" readOnly="{true}" />
                          </div>
                          <div className="editField">
                            <span className="editFieldLabel">Refund Amount</span>
                            <input value={this.moneyFormat(this.state.RefundAmount)} name="RefundAmount" onChange={evt => this.chgMoneyFldVal(evt)} className="editMoneyInput" readOnly="{true}" />
                          </div>
                          <div className="editField">
                            <span className="editFieldLabel">Total Tax Amount</span>
                            <input value={this.moneyFormat(this.state.TotalTaxAmount)} name="TotalTaxAmount" onChange={evt => this.chgMoneyFldVal(evt)} className="editMoneyInput" readOnly="{true}" />
                          </div>
                          <div className="editField">
                            <span className="editFieldLabel">Total Tax2 Amount</span>
                            <input value={this.moneyFormat(this.state.TotalTax2Amount)} name="TotalTax2Amount" onChange={evt => this.chgMoneyFldVal(evt)} className="editMoneyInput" readOnly="{true}" />
                          </div>
                          <div className="editField">
                            <span className="editFieldLabel">Tip Amount</span>
                            <input value={this.moneyFormat(this.state.TipAmount)} name="TipAmount" onChange={evt => this.chgMoneyFldVal(evt)} className="editMoneyInput" readOnly="{true}" />
                          </div>
                          <div id="div2" className="editField">
                            <span className="editFieldLabel">Amount Paid</span>
                            <input value={this.moneyFormat(this.state.AmountPaid)} name="AmountPaid" onChange={evt => this.chgMoneyFldVal(evt)} className="editMoneyInput" readOnly="{true}" />
                          </div>
                        </div>
                      </div>
                    </div> : null}
                  {this.state.bottomDisplay === 6 ?
                    <div className="editTab"> {/* Goes with Stress Test */}
                      <div className="editTabLeftNarrow">
                        <div id="div1" className="editInside">
                          <h4>Currently Active Users</h4>
                          <br />
                          <RadialGauge
                            pointer={{
                              value: this.state.activeUsers
                            }}
                            transitions={false}
                            colors
                            scale={{
                              labels: { format: 'n',color: this.tickColor,visible: true },
                              majorTicks: { visible: true,color: this.tickColor },
                              minorTicks: { visible: true,color: this.tickColor },
                              rangeSize: 5,
                              rangeLineCap: 'round',
                              startAngle: 0,
                              endAngle: 270,
                              reverse: false,
                              ranges: [{ to: 25,color: '#0058e9' },{ from: 25,to: 50,color: '#37b400' },{ from: 50,to: 75,color: '#ffc000' },{ from: 75,color: '#f31700' }]
                            }} />
                        </div>
                        <div id="div1" className="editInside">
                          <h4>Currently Active Users</h4>
                          <br />
                          <RadialGauge
                            pointer={{
                              value: this.state.activeUsers
                            }}
                            transitions={false}
                            colors
                            scale={{
                              labels: { format: 'n',color: this.tickColor,visible: true },
                              majorTicks: { visible: true,color: this.tickColor },
                              minorTicks: { visible: true,color: this.tickColor },
                              rangeSize: 5,
                              rangeLineCap: 'round',
                              startAngle: 0,
                              endAngle: 270,
                              reverse: false,
                              ranges: [{ to: 25,color: '#0058e9' },{ from: 25,to: 50,color: '#37b400' },{ from: 50,to: 75,color: '#ffc000' },{ from: 75,color: '#f31700' }]
                            }} />
                        </div>
                      </div>
                      <div className="editTabMiddle">
                        <div id="div1" className="editInside">
                          <h4>Active Execution Threads</h4>
                          <br />
                          <RadialGauge
                            pointer={{
                              value: this.state.strataCount
                            }}
                            transitions={false}
                            colors
                            scale={{
                              labels: { format: 'n',color: this.tickColor,visible: true },
                              majorTicks: { visible: true,color: this.tickColor },
                              minorTicks: { visible: true,color: this.tickColor },
                              rangeSize: 5,
                              rangeLineCap: 'round',
                              startAngle: 0,
                              endAngle: 270,
                              reverse: false,
                              ranges: [{ to: 25,color: '#0058e9' },{ from: 25,to: 50,color: '#37b400' },{ from: 50,to: 75,color: '#ffc000' },{ from: 75,color: '#f31700' }]
                            }} />
                        </div>
                      </div>
                      <div className="editTabRightNarrow">
                        <div id="div1" className="editInside">
                          <h4>Total Transactions Count</h4>
                          <br />
                          <RadialGauge
                            pointer={{
                              value: this.state.totalTransactionCount
                            }}
                            transitions={false}
                            scale={{
                              labels: { format: 'n',color: this.labelColor,visible: true },
                              majorTicks: { visible: true,color: '#002aff' },
                              minorTicks: { visible: true,color: '#00ff00' },
                              rangeSize: 9,
                              rangeLineCap: 'round',
                              startAngle: 0,
                              endAngle: 320,
                              min: 0,
                              max: 1000000,
                              reverse: false,
                              ranges: [{ to: 250000,color: '#c2f0c2' },{ from: 250000,o: 500000,color: '#86f086' },{ from: 550000,to: 750000,color: '#0ff5a0' },{ from: 750000,color: '#24ed24' }]
                            }} />
                        </div>
                      </div>
                    </div> : null}
                </div>
              </Splitter>
              <Popup offset={this.helpListPopupOffset} show={this.state.showHelpListPopup} popupClass={'popupHelp'} >
                <div id="helpBox">
                  <div className="helpList">
                    <ListView data={this.state.HelpPages} item={this.HelpPageFormat} header={this.HelpPageHeader} footer={this.HelpPageFooter} />
                  </div>
                  <br />
                </div>
              </Popup>
              <Popup offset={this.helpDetailOffset} show={this.state.showHelpDetail} popupClass={'popupHelpDetail'} onClick={this.closeHelpTime}>
                <div className="frameInfo" id="notifyBoxPU" dangerouslySetInnerHTML={{ __html: this.state.helpDetail }} onClick={this.closeHelpTime}></div>
              </Popup>
              {/*---------------------------------------------------------------------------------------------------------------------------------------------------------*/}
            </div>
            {SessionInfo.displayFooter === true ?
              <PageFooter L1='Home' /> : null}
          </div >}
      </div >
    );
  }
}
export default withRouter(AppMain)
{/* Menu SVG Icons */}
const about = <SvgIcon viewBox="0 0 512 512" size="default" themeColor="inherit">
  <path fill="current" d="M487.7,13.5H24.3c-5.9,0-10.8,4.8-10.8,10.8v463.4c0,5.9,4.8,10.8,10.8,10.8h463.4c5.9,0,10.8-4.8,10.8-10.8
	V24.3C498.5,18.4,493.6,13.5,487.7,13.5z M278.6,432.4c-7.4,6.6-15.9,9.8-25.6,9.8c-10,0-18.7-3.2-26.2-9.7
	c-7.5-6.5-11.2-15.5-11.2-27.2c0-10.3,3.6-19,10.8-26.1c7.2-7,16.1-10.6,26.6-10.6c10.3,0,19,3.5,26.1,10.6
	c7,7.1,10.6,15.7,10.6,26.1C289.7,416.8,286,425.8,278.6,432.4z M370.5,204.6c-5.7,10.5-12.4,19.6-20.2,27.2
	c-7.8,7.6-21.8,20.5-41.9,38.5c-5.6,5.1-10,9.6-13.4,13.4c-3.4,3.9-5.9,7.4-7.5,10.6c-1.6,3.2-2.9,6.4-3.8,9.6
	c-0.9,3.2-2.3,8.8-4.1,16.9c-3.1,17.1-12.9,25.6-29.3,25.6c-8.5,0-15.7-2.8-21.5-8.4c-5.8-5.6-8.7-13.9-8.7-24.8
	c0-13.8,2.1-25.7,6.4-35.8c4.3-10.1,9.9-18.9,17-26.6c7-7.6,16.6-16.7,28.5-27.2c10.5-9.2,18.1-16.1,22.8-20.8
	c4.7-4.7,8.6-9.9,11.8-15.6c3.2-5.7,4.8-12,4.8-18.7c0-13.1-4.9-24.2-14.6-33.2c-9.8-9-22.3-13.5-37.8-13.5
	c-18,0-31.3,4.6-39.9,13.7c-8.5,9.1-15.7,22.5-21.6,40.2c-5.6,18.5-16.2,27.8-31.7,27.8c-9.2,0-16.9-3.2-23.2-9.7
	c-6.3-6.5-9.5-13.5-9.5-21c0-15.6,5-31.4,15-47.4c10-16,24.6-29.2,43.8-39.7C211,75,233.4,69.8,259,69.8c23.8,0,44.8,4.4,63,13.2
	c18.2,8.8,32.3,20.7,42.2,35.8c9.9,15.1,14.9,31.5,14.9,49.2C379,181.9,376.2,194.1,370.5,204.6z"/>
</SvgIcon>
const contacts = <SvgIcon viewBox="0 0 512 512" size="default" themeColor="inherit">
  <path d="M259.4,262.2L456,66.1c3.8-3.8,1.1-10.3-4.3-10.3H58.6c-5.4,0-8.1,6.5-4.3,10.3l196.6,196.1
	  C253.3,264.5,257.1,264.5,259.4,262.2z"/>
  <path d="M13,450.9c0,15.5,12.6,28.1,28.1,28.1h427.7c15.5,0,28.1-12.6,28.1-28.1v-19.7c0-1.8-0.7-3.5-2-4.8
		L328.4,259.9c-2.6-2.6-6.9-2.6-9.5,0l-34.5,34.5c-7.8,7.8-18.2,12.1-29.3,12.1s-21.4-4.3-29.3-12.1l-34.5-34.5
		c-2.6-2.6-6.9-2.6-9.5,0L15,426.8c-1.3,1.3-2,3-2,4.8V450.9z"/>
  <path d="M154,222.4L23.5,91.9C19.6,88,13,90.8,13,96.3v261c0,5.5,6.6,8.2,10.5,4.4L154,231.2
		C156.5,228.8,156.5,224.9,154,222.4z"/>
  <path d="M497,357.3v-261c0-5.4-6.5-8.1-10.3-4.3L356.2,222.5c-2.4,2.4-2.4,6.2,0,8.5l130.5,130.5
		C490.5,365.4,497,362.7,497,357.3z"/>
</SvgIcon>
const dashboard = <SvgIcon viewBox="0 0 512 512" size="default" themeColor="inherit">
  <path d="M256,40.1C114.7,40.1,0.2,154.7,0.2,295.9c0,60.4,21,116,56,159.7h399.6c35-43.8,56-99.3,56-159.7
	C511.8,154.7,397.3,40.1,256,40.1z M256,91.8c14.1,0,25.6,11.5,25.6,25.6S270.1,143,256,143s-25.6-11.5-25.6-25.6
	S241.9,91.8,256,91.8z M76.8,322.2c-14.1,0-25.6-11.5-25.6-25.6c0-14.1,11.5-25.6,25.6-25.6s25.6,11.5,25.6,25.6
	C102.4,310.8,90.9,322.2,76.8,322.2z M129.3,195.5c-14.1,0-25.6-11.5-25.6-25.6s11.5-25.6,25.6-25.6c14.1,0,25.6,11.5,25.6,25.6
	S143.4,195.5,129.3,195.5z M351.4,247.4L300,298.8c2.3,5.4,3.5,11.3,3.5,17.5c0,25-20.3,45.3-45.3,45.3s-45.3-20.3-45.3-45.3
	s20.3-45.3,45.3-45.3c7.8,0,15.2,2,21.7,5.5l50.4-50.4c4.7-4.7,12.3-4.7,17,0l4.2,4.2C356.1,235,356.1,242.7,351.4,247.4z
	 M383,195.5c-14.1,0-25.6-11.5-25.6-25.6s11.5-25.6,25.6-25.6s25.6,11.5,25.6,25.6S397.2,195.5,383,195.5z M435.2,322.2
	c-14.1,0-25.6-11.5-25.6-25.6c0-14.1,11.5-25.6,25.6-25.6s25.6,11.5,25.6,25.6C460.8,310.8,449.3,322.2,435.2,322.2z"/>
</SvgIcon>
const kitchen = <SvgIcon viewBox="0 0 512 512" size="default" themeColor="inherit">
  <path d="M401.1,443H100.7v40.8c0,11.1,9,20.2,20.2,20.2h270.3c11.1,0,20.2-9,20.2-20.2V443H401.1z" />
  <path d="M502.7,208.6c-5.7-51.9-49.3-91.4-101.5-92H401c-3.7,0-7.3,0.2-11,0.6c-22.5-70.8-98-109.9-168.8-87.5
	c-42.3,13.4-75.3,46.9-88.1,89.4c-7.3-1.6-14.8-2.4-22.3-2.4C53.9,117.3,8.2,163.9,8.7,220.9c0.5,52.2,40,95.9,92,101.5v100.4h310.7
	V322.3C467.9,316.1,508.8,265.2,502.7,208.6z M198.3,371.9c0,6.6-5.4,12-12,12h-10.5c-6.6,0-12-5.4-12-12V271.4c0-6.6,5.4-12,12-12
	h10.5c6.6,0,12,5.4,12,12V371.9z M273.2,371.9c0,6.6-5.4,12-12,12h-10.5c-6.6,0-12-5.4-12-12V271.4c0-6.6,5.4-12,12-12h10.5
	c6.6,0,12,5.4,12,12V371.9z M348.2,371.9c0,6.6-5.4,12-12,12h-10.5c-6.6,0-12-5.4-12-12V271.4c0-6.6,5.4-12,12-12h10.5
	c6.6,0,12,5.4,12,12V371.9z"/>
</SvgIcon>
const logout = <SvgIcon viewBox="0 0 512 512" size="default" themeColor="inherit">
  <path fill="current" d="M357.7,122.1V36.5c0-13.6-11.2-24.8-24.8-24.8H26.8C13.2,11.6,2,22.8,2,36.5v16.1V376v11.2
		c0,9.1,5.4,17.8,13.2,21.9l188.6,97.6c8.3,4.1,18.2-1.7,18.2-11.2v-95.1h110.8c13.7,0,24.8-11.2,24.8-24.8V239.9h-49.6v98.4
		c0,7-5.4,12.4-12.4,12.4h-73.2V223.4v-80.7v-2.5c0-9.1-5.4-17.8-13.2-21.9L98,61.3h197.7c7,0,12.4,5.4,12.4,12.4v48.8h49.6V122.1z"
  />
  <path fill="current" d="M421.4,81.5l80.7,80.7c10.3,10.3,10.3,25.6,0,36l-80.7,80.7c-10.3,10.3-26.1,10.8-36.4,0.4
		c-9.9-9.9-8.7-26.5,0.8-36.4l36.4-36h-141c-7,0-14.1-2.9-18.6-8.3c-11.6-12.4-8.7-34.3,6.2-42.6c3.7-2.1,8.3-3.3,12.4-3.3h141
		c0,0-36-36-36.4-36c-9.5-9.5-10.8-26.5-0.8-36C394.9,70.8,411,71.2,421.4,81.5z"/>
</SvgIcon>
const manager = <SvgIcon viewBox="0 0 512 512" size="default" themeColor="inherit">
  <path d="M314.4,35H287l-3.5-9.7C279.4,13.7,268.3,5.8,256,5.8s-23.4,7.8-27.5,19.5L225,35h-27.4
			c-16.1,0-29.2,13.1-29.2,29.2v14.6c0,8.1,6.5,14.6,14.6,14.6h146c8.1,0,14.6-6.5,14.6-14.6V64.2C343.6,48.1,330.5,35,314.4,35z"/>
  <path d="M444.9,64.2h-72.1v14.6c0,24.2-19.6,43.8-43.8,43.8H183c-24.2,0-43.8-19.6-43.8-43.8V64.2H67.1
	c-17.1,0-30.9,13.8-30.9,30.9v378.1c0,17,13.9,30.9,30.9,30.9h375.8c18.2,0,32.9-14.7,32.9-32.9V95.2
	C475.8,78.1,461.9,64.2,444.9,64.2z M415.5,378.7c0,10.1-8.2,18.3-18.3,18.3h-35.9c-8.3,16.3-25.2,27.5-44.7,27.5
	c-19.5,0-36.4-11.2-44.7-27.5H114.8c-10.1,0-18.3-8.2-18.3-18.3v-8.6c0-10.1,8.2-18.3,18.3-18.3h157.1c8.3-16.3,25.2-27.5,44.7-27.5
	s36.4,11.2,44.7,27.5h35.9c10.1,0,18.3,8.2,18.3,18.3V378.7z M415.5,243.7c0,10.1-8.2,18.3-18.3,18.3H244.3
	c-8.3,16.3-25.2,27.5-44.7,27.5c-19.5,0-36.4-11.2-44.7-27.5h-40.1c-10.1,0-18.3-8.2-18.3-18.3v-8.6c0-10.1,8.2-18.3,18.3-18.3h40.1
	c8.3-16.3,25.2-27.5,44.7-27.5s36.4,11.2,44.7,27.5h152.9c10.1,0,18.3,8.2,18.3,18.3V243.7z"/>
</SvgIcon>
const products = <SvgIcon viewBox="0 0 512 512" size="default" themeColor="inherit">
  <path d="M314.4,35H287l-3.5-9.7C279.4,13.7,268.3,5.8,256,5.8s-23.4,7.8-27.5,19.5L225,35h-27.4
			c-16.1,0-29.2,13.1-29.2,29.2v14.6c0,8.1,6.5,14.6,14.6,14.6h146c8.1,0,14.6-6.5,14.6-14.6V64.2C343.6,48.1,330.5,35,314.4,35z"/>
  <path d="M444.9,64.2h-72.1v14.6c0,24.2-19.6,43.8-43.8,43.8H183c-24.2,0-43.8-19.6-43.8-43.8V64.2H67.1
	c-17.1,0-30.9,13.8-30.9,30.9v378.1c0,17,13.9,30.9,30.9,30.9h375.8c18.2,0,32.9-14.7,32.9-32.9V95.2
	C475.8,78.1,461.9,64.2,444.9,64.2z M415.5,378.7c0,10.1-8.2,18.3-18.3,18.3h-35.9c-8.3,16.3-25.2,27.5-44.7,27.5
	c-19.5,0-36.4-11.2-44.7-27.5H114.8c-10.1,0-18.3-8.2-18.3-18.3v-8.6c0-10.1,8.2-18.3,18.3-18.3h157.1c8.3-16.3,25.2-27.5,44.7-27.5
	s36.4,11.2,44.7,27.5h35.9c10.1,0,18.3,8.2,18.3,18.3V378.7z M415.5,243.7c0,10.1-8.2,18.3-18.3,18.3H244.3
	c-8.3,16.3-25.2,27.5-44.7,27.5c-19.5,0-36.4-11.2-44.7-27.5h-40.1c-10.1,0-18.3-8.2-18.3-18.3v-8.6c0-10.1,8.2-18.3,18.3-18.3h40.1
	c8.3-16.3,25.2-27.5,44.7-27.5s36.4,11.2,44.7,27.5h152.9c10.1,0,18.3,8.2,18.3,18.3V243.7z"/>
</SvgIcon>
const settings = <SvgIcon viewBox="0 0 512 512" size="default" themeColor="inherit">
  <path d="M481.6,308.6l-44.1-34.5c0.6-5.3,1-11.5,1-18.1c0-6.6-0.5-12.8-1-18.1l44.2-34.5c8.2-6.5,10.4-18,5.2-27.6
			L441,96.4c-4.9-9-15.6-13.6-26.5-9.5l-52.1,20.9c-10-7.2-20.4-13.3-31.3-18.1l-7.9-55.2C321.9,23.9,312.8,16,302,16h-92
			c-10.8,0-19.9,7.9-21.2,18.2l-7.9,55.4c-10.5,4.7-20.8,10.7-31.2,18.1l-52.2-21c-9.8-3.8-21.5,0.5-26.3,9.4l-45.9,79.5
			c-5.4,9.2-3.2,21.1,5.2,27.7l44.1,34.5c-0.7,6.7-1,12.6-1,18.1c0,5.5,0.3,11.4,1,18.1l-44.2,34.5c-8.2,6.5-10.4,18-5.2,27.6
			l45.9,79.4c4.9,8.9,15.5,13.6,26.5,9.5l52.1-20.9c10,7.2,20.4,13.3,31.2,18.1l7.9,55.2c1.3,10.5,10.4,18.4,21.2,18.4h92
			c10.8,0,19.9-7.9,21.2-18.2l7.9-55.4c10.5-4.7,20.8-10.7,31.2-18.2l52.2,21c2.5,1,5.1,1.5,7.8,1.5c7.8,0,14.9-4.2,18.5-10.8
			L487,336C492.1,326.6,489.9,315.1,481.6,308.6z M256,336c-44.1,0-80-35.9-80-80s35.9-80,80-80c44.1,0,80,35.9,80,80
			S300.1,336,256,336z"/>
</SvgIcon>
const storeLocations = <SvgIcon viewBox="0 0 512 512" size="default" themeColor="inherit">
  <path d="M507.5,171.7L464.2,38.8c-3.5-14-16.1-23.8-30.6-23.8H78.4c-14.5,0-27.1,9.8-30.6,23.8L4.5,171.7
				C4.2,173,4,174.3,4,175.5c0,43.4,33.5,78.7,74.8,78.7c24,0,45.4-11.9,59.1-30.5c13.7,18.5,35.1,30.5,59.1,30.5
				s45.4-11.9,59.1-30.5c13.7,18.5,35.1,30.5,59.1,30.5c24,0,45.4-11.9,59.1-30.5c13.7,18.5,35.1,30.5,59.1,30.5
				c41.3,0,74.8-35.3,74.8-78.7C508,174.3,507.8,173,507.5,171.7z"/>
  <path d="M433.2,285.8c-21.4,0-41.8-6.6-59.1-18.5c-34.5,24-83.7,24-118.1,0c-34.5,24-83.7,24-118.1,0
				c-17.2,12-37.6,18.5-59.1,18.5c-15.5,0-30-3.6-43.3-9.8v189.5c0,17.4,14.1,31.5,31.5,31.5h126V390.7c0-10.9,8.8-19.7,19.7-19.7
				h86.6c10.9,0,19.7,8.8,19.7,19.7V497h126c17.4,0,31.5-14.1,31.5-31.5V276C463.2,282.2,448.6,285.8,433.2,285.8z"/>
</SvgIcon>
const barcode = <SvgIcon viewBox="0 0 512 512" size="default" themeColor="inherit">
  <path d="M0 448V64h18v384H0zm26.857-.273V64H36v383.727h-9.143zm27.143 0V64h8.857v383.727H54zm44.857 0V64h8.857v383.727h-8.857zm36
        0V64h17.714v383.727h-17.714zm44.857 0V64h8.857v383.727h-8.857zm18 0V64h8.857v383.727h-8.857zm18 0V64h8.857v383.727h-8.857zm35.715
        0V64h18v383.727h-18zm44.857 0V64h18v383.727h-18zm35.999 0V64h18.001v383.727h-18.001zm36.001 0V64h18.001v383.727h-18.001zm26.857
        0V64h18v383.727h-18zm45.143 0V64h26.857v383.727h-26.857zm35.714 0V64h9.143v383.727H476zm18 .273V64h18v384h-18z" />
</SvgIcon>
const users = <SvgIcon viewBox="0 0 512 512" size="default" themeColor="inherit">
  <circle cx="258.6" cy="114.1" r="79.7" />
  <circle cx="97.4" cy="143.4" r="50.4" />
  <circle cx="419.7" cy="143.4" r="50.4" />
  <path d="M370.1,238.9c19.8-16.2,37.8-14.1,60.7-14.1c34.3,0,62.2,27.7,62.2,61.8v140.1c0,14.8-12.1,26.8-26.9,26.8
		c-64.1,0-56.4,1.2-56.4-2.8C409.7,380,418.1,287.9,370.1,238.9z"/>
  <path d="M236.8,225.2c40-3.3,74.8,0,104.9,24.8c50.2,40.2,40.6,134.6,40.6,200.8c0,17.5-14.3,32.1-32.1,32.1
		c-193.3,0-201,6.2-212.4-19.1c-3.8-8.6-2.7-46-2.7-128.1C135,270.4,191.4,225.2,236.8,225.2L236.8,225.2z"/>
  <path d="M86.4,224.9c23,0,40.9-2.1,60.7,14.1c-47.6,48.6-39.5,137.1-39.5,211.9c0,4,6.4,2.8-55.4,2.8
		c-15.4,0-27.9-12.5-27.9-27.8V286.7C24.2,252.6,52.1,224.9,86.4,224.9z"/>
</SvgIcon>
//---------------------- Event Data --------------------

const baseData = [
  {
    "TaskID": 4,
    "OwnerID": 2,
    "Title": "Bowling tournament",
    "Description": "",
    "StartTimezone": null,
    "Start": "2013-06-09T21:00:00.000Z",
    "End": "2013-06-10T00:00:00.000Z",
    "EndTimezone": null,
    "RecurrenceRule": null,
    "RecurrenceID": null,
    "RecurrenceException": null,
    "isAllDay": false
  },
  {
    "TaskID": 5,
    "OwnerID": 2,
    "Title": "Take the dog to the vet",
    "Description": "",
    "StartTimezone": null,
    "Start": "2013-06-10T07:00:00.000Z",
    "End": "2013-06-10T08:00:00.000Z",
    "EndTimezone": null,
    "RecurrenceRule": null,
    "RecurrenceID": null,
    "RecurrenceException": null,
    "isAllDay": false
  },
  {
    "TaskID": 6,
    "OwnerID": 2,
    "Title": "Call Charlie about the project",
    "Description": "",
    "StartTimezone": null,
    "Start": "2013-06-11T11:30:00.000Z",
    "End": "2013-06-11T13:00:00.000Z",
    "EndTimezone": null,
    "RecurrenceRule": null,
    "RecurrenceID": null,
    "RecurrenceException": null,
    "isAllDay": false
  },
  {
    "TaskID": 7,
    "OwnerID": 3,
    "Title": "Meeting with Alex",
    "Description": "",
    "StartTimezone": null,
    "Start": "2013-06-12T11:00:00.000Z",
    "End": "2013-06-12T12:00:00.000Z",
    "EndTimezone": null,
    "RecurrenceRule": null,
    "RecurrenceID": null,
    "RecurrenceException": null,
    "isAllDay": false
  },
  {
    "TaskID": 9,
    "OwnerID": 2,
    "Title": "Alex's Birthday",
    "Description": "",
    "StartTimezone": null,
    "Start": "2013-06-14T02:00:00.000Z",
    "End": "2013-06-14T02:00:00.000Z",
    "EndTimezone": null,
    "RecurrenceRule": null,
    "RecurrenceID": null,
    "RecurrenceException": null,
    "isAllDay": true
  },
  {
    "TaskID": 12,
    "OwnerID": 2,
    "Title": "Car Service",
    "RoomID": 1,
    "Description": "Might come to work later!",
    "StartTimezone": null,
    "Start": "2013-06-24T08:30:00.000Z",
    "End": "2013-06-24T10:00:00.000Z",
    "EndTimezone": null,
    "RecurrenceRule": null,
    "RecurrenceID": null,
    "RecurrenceException": null,
    "isAllDay": false
  },
  {
    "TaskID": 14,
    "OwnerID": 3,
    "RoomID": 2,
    "PersonID": 3,
    "Title": "Replace the printer on the 1st floor",
    "Description": "",
    "StartTimezone": null,
    "Start": "2013-06-24T10:00:00.000Z",
    "End": "2013-06-24T11:00:00.000Z",
    "EndTimezone": null,
    "RecurrenceRule": null,
    "RecurrenceID": null,
    "RecurrenceException": null,
    "isAllDay": true
  },
  {
    "TaskID": 15,
    "OwnerID": 1,
    "Title": "Attending HR Conference",
    "Description": "",
    "StartTimezone": null,
    "Start": "2013-06-25T00:00:00.000Z",
    "End": "2013-06-26T00:00:00.000Z",
    "EndTimezone": null,
    "RecurrenceRule": null,
    "RecurrenceID": null,
    "RecurrenceException": null,
    "isAllDay": true
  },
  {
    "TaskID": 16,
    "OwnerID": 1,
    "Title": "Business Lunch with Gregory Watkins",
    "Description": "",
    "StartTimezone": null,
    "Start": "2013-06-25T12:00:00.000Z",
    "End": "2013-06-25T13:00:00.000Z",
    "EndTimezone": null,
    "RecurrenceRule": null,
    "RecurrenceID": null,
    "RecurrenceException": null,
    "isAllDay": false
  },
  {
    "TaskID": 17,
    "OwnerID": 1,
    "Title": "Breakfast with CFO and COO",
    "Description": "",
    "StartTimezone": null,
    "Start": "2013-06-27T08:30:00.000Z",
    "End": "2013-06-27T09:30:00.000Z",
    "EndTimezone": null,
    "RecurrenceRule": null,
    "RecurrenceID": null,
    "RecurrenceException": null,
    "isAllDay": false
  },
  {
    "TaskID": 18,
    "OwnerID": 1,
    "Title": "Job Interview - Mathew Stevens",
    "Description": "Junior Researcher",
    "StartTimezone": null,
    "Start": "2013-06-27T10:00:00.000Z",
    "End": "2013-06-27T11:00:00.000Z",
    "EndTimezone": null,
    "RecurrenceRule": null,
    "RecurrenceID": null,
    "RecurrenceException": null,
    "isAllDay": false
  },
  {
    "TaskID": 19,
    "OwnerID": 1,
    "Title": "Review CVs with Tim",
    "Description": "",
    "StartTimezone": null,
    "Start": "2013-06-27T11:00:00.000Z",
    "End": "2013-06-27T11:30:00.000Z",
    "EndTimezone": null,
    "RecurrenceRule": null,
    "RecurrenceID": null,
    "RecurrenceException": null,
    "isAllDay": false
  },
  {
    "TaskID": 20,
    "OwnerID": 1,
    "Title": "Lunch with Monica",
    "Description": "Discuss the Employee handbook",
    "StartTimezone": null,
    "Start": "2013-06-27T12:00:00.000Z",
    "End": "2013-06-27T13:30:00.000Z",
    "EndTimezone": null,
    "RecurrenceRule": null,
    "RecurrenceID": null,
    "RecurrenceException": null,
    "isAllDay": false
  },
  {
    "TaskID": 21,
    "OwnerID": 1,
    "Title": "Job Interview - John Stewart",
    "Description": "Accountant",
    "StartTimezone": null,
    "Start": "2013-06-27T14:00:00.000Z",
    "End": "2013-06-27T15:00:00.000Z",
    "EndTimezone": null,
    "RecurrenceRule": null,
    "RecurrenceID": null,
    "RecurrenceException": null,
    "isAllDay": false
  },
  {
    "TaskID": 22,
    "OwnerID": 1,
    "Title": "Job Interview - Mary Smith",
    "Description": "Accountant",
    "StartTimezone": null,
    "Start": "2013-06-27T15:30:00.000Z",
    "End": "2013-06-27T16:30:00.000Z",
    "EndTimezone": null,
    "RecurrenceRule": null,
    "RecurrenceID": null,
    "RecurrenceException": null,
    "isAllDay": false
  },
  {
    "TaskID": 24,
    "OwnerID": 3,
    "Title": "Register new Access Cards",
    "Description": "",
    "StartTimezone": null,
    "Start": "2013-06-24T12:00:00.000Z",
    "End": "2013-06-24T12:30:00.000Z",
    "EndTimezone": null,
    "RecurrenceRule": null,
    "RecurrenceID": null,
    "RecurrenceException": null,
    "isAllDay": true
  },
  {
    "TaskID": 25,
    "OwnerID": 1,
    "Title": "HR Lecture",
    "Description": "",
    "StartTimezone": null,
    "Start": "2013-06-04T19:00:00.000Z",
    "End": "2013-06-04T21:00:00.000Z",
    "EndTimezone": null,
    "RecurrenceRule": "FREQ=WEEKLY;BYDAY=TU,TH",
    "RecurrenceID": null,
    "RecurrenceException": null,
    "isAllDay": false
  },
  {
    "TaskID": 26,
    "OwnerID": 1,
    "Title": "Dentist",
    "Description": "",
    "StartTimezone": null,
    "Start": "2013-06-28T08:00:00.000Z",
    "End": "2013-06-28T09:00:00.000Z",
    "EndTimezone": null,
    "RecurrenceRule": null,
    "RecurrenceID": null,
    "RecurrenceException": null,
    "isAllDay": false
  },
  {
    "TaskID": 27,
    "OwnerID": 1,
    "Title": "Job Interview - Laura Bailey",
    "Description": "Helpdesk",
    "StartTimezone": null,
    "Start": "2013-06-28T09:30:00.000Z",
    "End": "2013-06-28T10:30:00.000Z",
    "EndTimezone": null,
    "RecurrenceRule": null,
    "RecurrenceID": null,
    "RecurrenceException": null,
    "isAllDay": false
  },
  {
    "TaskID": 28,
    "OwnerID": 1,
    "Title": "Job Interview - Jenny Baxter",
    "Description": "Helpdesk",
    "StartTimezone": null,
    "Start": "2013-06-28T11:00:00.000Z",
    "End": "2013-06-28T12:00:00.000Z",
    "EndTimezone": null,
    "RecurrenceRule": null,
    "RecurrenceID": null,
    "RecurrenceException": null,
    "isAllDay": false
  },
  {
    "TaskID": 31,
    "OwnerID": 1,
    "Title": "Team building prep tasks",
    "Description": "",
    "StartTimezone": null,
    "Start": "2013-06-28T14:00:00.000Z",
    "End": "2013-06-28T17:00:00.000Z",
    "EndTimezone": null,
    "RecurrenceRule": null,
    "RecurrenceID": null,
    "RecurrenceException": null,
    "isAllDay": false
  },
  {
    "TaskID": 32,
    "OwnerID": 2,
    "RoomID": 2,
    "Title": "Job Interview - Bernard Atkins",
    "Description": "Helpdesk",
    "StartTimezone": null,
    "Start": "2013-06-24T13:30:00.000Z",
    "End": "2013-06-24T14:30:00.000Z",
    "EndTimezone": null,
    "RecurrenceRule": null,
    "RecurrenceID": null,
    "RecurrenceException": null,
    "isAllDay": false
  },
  {
    "TaskID": 34,
    "OwnerID": 1,
    "Title": "Review Job Applications",
    "Description": "",
    "StartTimezone": null,
    "Start": "2013-06-24T15:00:00.000Z",
    "End": "2013-06-24T17:30:00.000Z",
    "EndTimezone": null,
    "RecurrenceRule": null,
    "RecurrenceID": null,
    "RecurrenceException": null,
    "isAllDay": false
  },
  {
    "TaskID": 35,
    "OwnerID": 1,
    "Title": "Grand Canyon tour",
    "Description": "",
    "StartTimezone": null,
    "Start": "2013-06-23T00:00:00.000Z",
    "End": "2013-06-23T00:00:00.000Z",
    "EndTimezone": null,
    "RecurrenceRule": null,
    "RecurrenceID": null,
    "RecurrenceException": null,
    "isAllDay": true
  },
  {
    "TaskID": 40,
    "OwnerID": 3,
    "Title": "Install new laptops in conference rooms",
    "Description": "",
    "StartTimezone": null,
    "Start": "2013-06-24T13:30:00.000Z",
    "End": "2013-06-24T15:00:00.000Z",
    "EndTimezone": null,
    "RecurrenceRule": null,
    "RecurrenceID": null,
    "RecurrenceException": null,
    "isAllDay": false
  },
  {
    "TaskID": 66,
    "OwnerID": 3,
    "Title": "Bob's Birthday",
    "Description": "",
    "StartTimezone": null,
    "Start": "2013-06-29T08:00:00.000Z",
    "End": "2013-06-29T06:00:00.000Z",
    "EndTimezone": null,
    "RecurrenceRule": null,
    "RecurrenceID": null,
    "RecurrenceException": null,
    "isAllDay": true
  },
  {
    "TaskID": 68,
    "OwnerID": 1,
    "RoomID": 2,
    "Title": "Breakfast with Tom",
    "Description": "",
    "StartTimezone": null,
    "Start": "2013-06-24T09:45:00.000Z",
    "End": "2013-06-24T11:30:00.000Z",
    "EndTimezone": null,
    "RecurrenceRule": null,
    "RecurrenceID": null,
    "RecurrenceException": null,
    "isAllDay": false
  },
  {
    "TaskID": 69,
    "OwnerID": 2,
    "Title": "Team planning meeting",
    "Description": "",
    "StartTimezone": null,
    "Start": "2013-06-24T10:00:00.000Z",
    "End": "2013-06-24T12:00:00.000Z",
    "EndTimezone": null,
    "RecurrenceRule": null,
    "RecurrenceID": null,
    "RecurrenceException": null,
    "isAllDay": true
  },
  {
    "TaskID": 70,
    "OwnerID": 2,
    "Title": "Support Phone Call",
    "Description": "",
    "StartTimezone": null,
    "Start": "2013-06-24T16:00:00.000Z",
    "End": "2013-06-24T16:30:00.000Z",
    "EndTimezone": null,
    "RecurrenceRule": null,
    "RecurrenceID": null,
    "RecurrenceException": null,
    "isAllDay": true
  },
  {
    "TaskID": 71,
    "OwnerID": 2,
    "Title": "Business breakfast with Caroline",
    "Description": "",
    "StartTimezone": null,
    "Start": "2013-06-25T09:00:00.000Z",
    "End": "2013-06-25T10:00:00.000Z",
    "EndTimezone": null,
    "RecurrenceRule": null,
    "RecurrenceID": null,
    "RecurrenceException": null,
    "isAllDay": false
  },
  {
    "TaskID": 72,
    "OwnerID": 2,
    "Title": "Discuss preojects' deadlines",
    "Description": "",
    "StartTimezone": null,
    "Start": "2013-06-25T11:00:00.000Z",
    "End": "2013-06-25T11:30:00.000Z",
    "EndTimezone": null,
    "RecurrenceRule": null,
    "RecurrenceID": null,
    "RecurrenceException": null,
    "isAllDay": false
  },
  {
    "TaskID": 73,
    "OwnerID": 2,
    "Title": "Support Meeting",
    "Description": "",
    "StartTimezone": null,
    "Start": "2013-06-25T15:00:00.000Z",
    "End": "2013-06-25T16:00:00.000Z",
    "EndTimezone": null,
    "RecurrenceRule": null,
    "RecurrenceID": null,
    "RecurrenceException": null,
    "isAllDay": false
  },
  {
    "TaskID": 74,
    "OwnerID": 2,
    "Title": "Dine with Mathew",
    "Description": "",
    "StartTimezone": null,
    "Start": "2013-06-25T18:30:00.000Z",
    "End": "2013-06-25T20:00:00.000Z",
    "EndTimezone": null,
    "RecurrenceRule": null,
    "RecurrenceID": null,
    "RecurrenceException": null,
    "isAllDay": false
  },
  {
    "TaskID": 79,
    "OwnerID": 2,
    "Title": "Banking",
    "Description": "",
    "StartTimezone": null,
    "Start": "2013-06-26T09:00:00.000Z",
    "End": "2013-06-26T10:00:00.000Z",
    "EndTimezone": null,
    "RecurrenceRule": null,
    "RecurrenceID": null,
    "RecurrenceException": null,
    "isAllDay": false
  },
  {
    "TaskID": 80,
    "OwnerID": 3,
    "Title": "Software updates",
    "Description": "",
    "StartTimezone": null,
    "Start": "2013-06-25T10:00:00.000Z",
    "End": "2013-06-25T12:00:00.000Z",
    "EndTimezone": null,
    "RecurrenceRule": null,
    "RecurrenceID": null,
    "RecurrenceException": null,
    "isAllDay": false
  },
  {
    "TaskID": 81,
    "OwnerID": 3,
    "Title": "UPS maintenance",
    "Description": "",
    "StartTimezone": null,
    "Start": "2013-06-25T16:30:00.000Z",
    "End": "2013-06-25T18:00:00.000Z",
    "EndTimezone": null,
    "RecurrenceRule": null,
    "RecurrenceID": null,
    "RecurrenceException": null,
    "isAllDay": false
  },
  {
    "TaskID": 82,
    "OwnerID": 2,
    "Title": "Support Call",
    "Description": "",
    "StartTimezone": null,
    "Start": "2013-06-26T11:30:00.000Z",
    "End": "2013-06-26T12:00:00.000Z",
    "EndTimezone": null,
    "RecurrenceRule": null,
    "RecurrenceID": null,
    "RecurrenceException": null,
    "isAllDay": false
  },
  {
    "TaskID": 83,
    "OwnerID": 3,
    "Title": "Phone Sync with NY office ",
    "Description": "",
    "StartTimezone": null,
    "Start": "2013-06-26T13:30:00.000Z",
    "End": "2013-06-26T14:30:00.000Z",
    "EndTimezone": null,
    "RecurrenceRule": null,
    "RecurrenceID": null,
    "RecurrenceException": null,
    "isAllDay": false
  },
  {
    "TaskID": 84,
    "OwnerID": 3,
    "Title": "Phone Sync with Boston Office",
    "Description": "",
    "StartTimezone": null,
    "Start": "2013-06-26T15:00:00.000Z",
    "End": "2013-06-26T16:00:00.000Z",
    "EndTimezone": null,
    "RecurrenceRule": null,
    "RecurrenceID": null,
    "RecurrenceException": null,
    "isAllDay": false
  },
  {
    "TaskID": 85,
    "OwnerID": 3,
    "Title": "Server maintenance",
    "Description": "",
    "StartTimezone": null,
    "Start": "2013-06-26T18:30:00.000Z",
    "End": "2013-06-26T21:00:00.000Z",
    "EndTimezone": null,
    "RecurrenceRule": null,
    "RecurrenceID": null,
    "RecurrenceException": null,
    "isAllDay": true
  },
  {
    "TaskID": 86,
    "OwnerID": 2,
    "Title": "Status meeting",
    "Description": "",
    "StartTimezone": null,
    "Start": "2013-06-28T13:30:00.000Z",
    "End": "2013-06-28T15:30:00.000Z",
    "EndTimezone": null,
    "RecurrenceRule": null,
    "RecurrenceID": null,
    "RecurrenceException": null,
    "isAllDay": false
  },
  {
    "TaskID": 87,
    "OwnerID": 3,
    "Title": "Helpdesk status meeting",
    "Description": "",
    "StartTimezone": null,
    "Start": "2013-06-27T10:30:00.000Z",
    "End": "2013-06-27T11:30:00.000Z",
    "EndTimezone": null,
    "RecurrenceRule": null,
    "RecurrenceID": null,
    "RecurrenceException": null,
    "isAllDay": false
  },
  {
    "TaskID": 88,
    "OwnerID": 2,
    "Title": "Business Lunch",
    "Description": "",
    "StartTimezone": null,
    "Start": "2013-06-27T12:00:00.000Z",
    "End": "2013-06-27T13:00:00.000Z",
    "EndTimezone": null,
    "RecurrenceRule": null,
    "RecurrenceID": null,
    "RecurrenceException": null,
    "isAllDay": false
  },
  {
    "TaskID": 89,
    "OwnerID": 3,
    "Title": "Employee database update",
    "Description": "",
    "StartTimezone": null,
    "Start": "2013-06-27T14:00:00.000Z",
    "End": "2013-06-27T15:30:00.000Z",
    "EndTimezone": null,
    "RecurrenceRule": null,
    "RecurrenceID": null,
    "RecurrenceException": null,
    "isAllDay": false
  },
  {
    "TaskID": 90,
    "OwnerID": 3,
    "Title": "Website upload",
    "Description": "",
    "StartTimezone": null,
    "Start": "2013-06-27T07:30:00.000Z",
    "End": "2013-06-27T08:30:00.000Z",
    "EndTimezone": null,
    "RecurrenceRule": null,
    "RecurrenceID": null,
    "RecurrenceException": null,
    "isAllDay": false
  },
  {
    "TaskID": 91,
    "OwnerID": 2,
    "Title": "Meeting with marketing guys",
    "Description": "",
    "StartTimezone": null,
    "Start": "2013-06-27T17:00:00.000Z",
    "End": "2013-06-27T18:30:00.000Z",
    "EndTimezone": null,
    "RecurrenceRule": null,
    "RecurrenceID": null,
    "RecurrenceException": null,
    "isAllDay": false
  },
  {
    "TaskID": 92,
    "OwnerID": 3,
    "Title": "Meeting with Internet provider",
    "Description": "",
    "StartTimezone": null,
    "Start": "2013-06-28T10:30:00.000Z",
    "End": "2013-06-28T11:30:00.000Z",
    "EndTimezone": null,
    "RecurrenceRule": null,
    "RecurrenceID": null,
    "RecurrenceException": null,
    "isAllDay": false
  },
  {
    "TaskID": 93,
    "OwnerID": 3,
    "Title": "Bob's Birthday Party",
    "Description": "",
    "StartTimezone": null,
    "Start": "2013-06-29T20:00:00.000Z",
    "End": "2013-06-29T23:30:00.000Z",
    "EndTimezone": null,
    "RecurrenceRule": null,
    "RecurrenceID": null,
    "RecurrenceException": null,
    "isAllDay": false
  },
  {
    "TaskID": 95,
    "OwnerID": 2,
    "Title": "Dance Practice",
    "Description": "",
    "StartTimezone": null,
    "Start": "2013-06-03T18:30:00.000Z",
    "End": "2013-06-03T20:00:00.000Z",
    "EndTimezone": null,
    "RecurrenceRule": "FREQ=WEEKLY;BYDAY=MO,WE",
    "RecurrenceID": null,
    "RecurrenceException": null,
    "isAllDay": false
  },
  {
    "TaskID": 114,
    "OwnerID": 3,
    "Title": "Software updates",
    "Description": "",
    "StartTimezone": null,
    "Start": "2013-06-04T09:00:00.000Z",
    "End": "2013-06-04T12:00:00.000Z",
    "EndTimezone": null,
    "RecurrenceRule": "",
    "RecurrenceID": null,
    "RecurrenceException": null,
    "isAllDay": false
  },
  {
    "TaskID": 115,
    "OwnerID": 1,
    "Title": "Breakfast at Starbucks",
    "Description": "",
    "StartTimezone": null,
    "Start": "2013-06-04T08:00:00.000Z",
    "End": "2013-06-04T09:30:00.000Z",
    "EndTimezone": null,
    "RecurrenceRule": "",
    "RecurrenceID": null,
    "RecurrenceException": null,
    "isAllDay": false
  },
  {
    "TaskID": 116,
    "OwnerID": 2,
    "Title": "Performance review",
    "Description": "",
    "StartTimezone": null,
    "Start": "2013-06-04T14:00:00.000Z",
    "End": "2013-06-04T17:00:00.000Z",
    "EndTimezone": null,
    "RecurrenceRule": "",
    "RecurrenceID": null,
    "RecurrenceException": null,
    "isAllDay": false
  },
  {
    "TaskID": 118,
    "OwnerID": 1,
    "Title": "HR seminar preparation",
    "Description": "",
    "StartTimezone": null,
    "Start": "2013-06-05T10:00:00.000Z",
    "End": "2013-06-05T12:00:00.000Z",
    "EndTimezone": null,
    "RecurrenceRule": "",
    "RecurrenceID": null,
    "RecurrenceException": null,
    "isAllDay": false
  },
  {
    "TaskID": 119,
    "OwnerID": 3,
    "Title": "Helpdesk weekly meeting",
    "Description": "",
    "StartTimezone": null,
    "Start": "2013-06-05T15:00:00.000Z",
    "End": "2013-06-05T16:00:00.000Z",
    "EndTimezone": null,
    "RecurrenceRule": "FREQ=WEEKLY;BYDAY=WE",
    "RecurrenceID": null,
    "RecurrenceException": null,
    "isAllDay": false
  },
  {
    "TaskID": 120,
    "OwnerID": 3,
    "Title": "Website upload",
    "Description": "",
    "StartTimezone": null,
    "Start": "2013-06-07T07:00:00.000Z",
    "End": "2013-06-07T08:30:00.000Z",
    "EndTimezone": null,
    "RecurrenceRule": "",
    "RecurrenceID": null,
    "RecurrenceException": null,
    "isAllDay": false
  }
];

const customModelFields = {
  id: 'TaskID',
  title: 'Title',
  description: 'Description',
  start: 'Start',
  end: 'End',
  recurrenceRule: 'RecurrenceRule',
  recurrenceId: 'RecurrenceID',
  recurrenceExceptions: 'RecurrenceException',
};

const currentYear = new Date().getFullYear();
const parseAdjust = (eventDate) => {
  const date = new Date(eventDate);
  date.setFullYear(currentYear);
  return date;
};

const randomInt = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;

const displayDate = new Date(currentYear, 5, 24);

const sampleData = baseData.map(dataItem => (
  {
    id: dataItem.TaskID,
    start: parseAdjust(dataItem.Start),
    startTimezone: dataItem.startTimezone,
    end: parseAdjust(dataItem.End),
    endTimezone: dataItem.endTimezone,
    isAllDay: dataItem.isAllDay,
    title: dataItem.Title,
    description: dataItem.Description,
    recurrenceRule: dataItem.RecurrenceRule,
    recurrenceId: dataItem.RecurrenceID,
    recurrenceExceptions: dataItem.RecurrenceException,

    roomId: dataItem.RoomID,
    ownerID: dataItem.OwnerID,
    personId: dataItem.OwnerID
  }
));

const sampleDataWithResources = baseData.map(dataItem => (
  {
    id: dataItem.TaskID,
    start: parseAdjust(dataItem.Start),
    startTimezone: dataItem.startTimezone,
    end: parseAdjust(dataItem.End),
    endTimezone: dataItem.endTimezone,
    isAllDay: dataItem.isAllDay,
    title: dataItem.Title,
    description: dataItem.Description,
    recurrenceRule: dataItem.RecurrenceRule,
    recurrenceId: dataItem.RecurrenceID,
    recurrenceExceptions: dataItem.RecurrenceException,
    roomId: randomInt(1, 2),
    personId: randomInt(1, 2),
  }
));

const sampleDataWithCustomSchema = baseData.map(dataItem => (
  {
    ...dataItem,
    Start: parseAdjust(dataItem.Start),
    End: parseAdjust(dataItem.End),
    PersonIDs: randomInt(1, 2),
    RoomID: randomInt(1, 2),
  }
));