//----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// Common Controls - Controls used in CheckoutManager
//              Version 1.041- July 29, 2022
//----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
import React, { Component, useState, useEffect, useMemo, useRef, useCallback} from 'react';
import { Grid as TGrid, GridColumn as Column} from '@progress/kendo-react-grid';
import { TabStrip, TabStripTab, Splitter, TileLayout } from '@progress/kendo-react-layout';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { DropDownList } from '@progress/kendo-react-dropdowns';  
import { DateTimePicker } from '@progress/kendo-react-dateinputs';   
import { Popup } from '@progress/kendo-react-popup';
import { Button } from '@progress/kendo-react-buttons';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { orderBy, filterBy } from '@progress/kendo-data-query';
import { ListView, ListViewHeader, ListViewFooter } from '@progress/kendo-react-listview';
//--------------                                                           
import { guid } from '@progress/kendo-react-common';
import { IntlProvider, load, LocalizationProvider, loadMessages } from '@progress/kendo-react-intl';
import { Scheduler, TimelineView, DayView, WeekView, MonthView, AgendaView } from '@progress/kendo-react-scheduler';
import weekData from 'cldr-core/supplemental/weekData.json';
import currencyData from 'cldr-core/supplemental/currencyData.json';
import likelySubtags from 'cldr-core/supplemental/likelySubtags.json';
import numbers from 'cldr-numbers-full/main/es/numbers.json';
import dateFields from 'cldr-dates-full/main/es/dateFields.json';
import currencies from 'cldr-numbers-full/main/es/currencies.json';
import caGregorian from 'cldr-dates-full/main/es/ca-gregorian.json';
import timeZoneNames from 'cldr-dates-full/main/es/timeZoneNames.json';
import '@progress/kendo-date-math/tz/Etc/UTC';
import '@progress/kendo-date-math/tz/Europe/Sofia';
import '@progress/kendo-date-math/tz/Europe/Madrid';
import '@progress/kendo-date-math/tz/Asia/Dubai';
import '@progress/kendo-date-math/tz/Asia/Tokyo';
import '@progress/kendo-date-math/tz/America/New_York';
import '@progress/kendo-date-math/tz/America/Los_Angeles';
//--------------
import { NumericTextBox } from '@progress/kendo-react-inputs'; 
import { MultiSelect } from '@progress/kendo-react-dropdowns';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { SessionInfo } from './App';
import moment from 'moment';
import PageHeader from './PageHeader.js';
import PageFooter from './PageFooter.js';
import PageSide from './PageSide.js';
//-------------------------------------- TimeZone -----------------------------   
import { timezoneNames } from "@progress/kendo-date-math";
//-----------------------------------------------------------------------------
import { CallOM, GetTableRow, GetTableRowRaw, GetTableRowTrans, GetTableData, GetTableSearch, UpdateRow, DeleteRow, GetFileExtension, displayMessage, displayError, displayNotify, GetDropdownData, GetDependentDropdownData, displayWarning } from './CommonCode.js';
import { EditGridCommandCell } from './CommonFormat.js';
//---------------
//--------------------------------------------------------------------------------------------------------                                  
//--- Sched ------ 
//import esMessages from './es.json'; 
//loadMessages(esMessages, 'es-ES');
//import { sampleDataWithCustomSchema, displayDate, customModelFields } from './events-utc';
load(likelySubtags, currencyData, weekData, numbers, currencies, caGregorian, dateFields, timeZoneNames);
const esMessages = {
  "scheduler": {
    "today": "Hoy",
    "allDay": "todo el dia",
    "showWorkDay": "Mostrar horas laborables",
    "showFullDay": "Mostrar d�a completo",
    "agendaViewTitle": "Agenda",
    "dayViewTitle": "D�a",
    "weekViewTitle": "Semana",
    "workWeekViewTitle": "Semana laboral",
    "monthViewTitle": "Mes"
  }
}
//-------------------------------------
export const customModelFields = {
  id: "TaskID",
  title: "Title",     
  description: "Parameters",
  start: "Start",
  end: "End",
  recurrenceRule: "RecurrenceRule",
  recurrenceId: "RecurrenceID",
  recurrenceExceptions: "RecurrenceException",
  Parameters: "Command Parameters",
};
//const currentYear = new Date().getFullYear();

const parseAdjust = (eventDate) => {
  const date = new Date(eventDate);
  //date.setFullYear(currentYear);
  return date;
};

const randomInt = (min, max) =>
  Math.floor(Math.random() * (max - min + 1)) + min;

export const schedDate = new Date();    
let taskid = 10;
export const getTaskID = async () => {
  let CD = await CallOM("GetTaskID", 0, 0, 0);
  //console.log(`CD: ${JSON.stringify(CD)}`);
  taskid = CD.d;
  //displayMessage(`taskID: ${taskid}`);
  //console.log(`current tasks: ${JSON.stringify(baseSchedData)}`);
  return taskid;
}
const gettaskid = () => {
  return taskid;
}
export const getSchedTasks = async (locationID, startDate, view) => {
  console.log("Get Tasks -------------------------------------------------");
  console.log(`location: ${locationID}, start: ${startDate}, view: ${view}`);
  let parms = [];
  parms.push(startDate);
  parms.push(view);
  let CD = await CallOM("GetTasks", 0, locationID,9,parms); // TaskList Returned for Scheduler
  getTaskID(); // Initialize TaskID for create
  //console.log("W Tasks: " + JSON.stringify(CD.d));
  let taskData = CD.d.rows;
  //console.log("TaskData: " + JSON.stringify(this.taskData));
  let TaskData = taskData.map(dataItem => (
    {
      TaskID: dataItem.COTaskID,
      OwnerID: 2,
      Title: dataItem.Title,
      Description: dataItem.Description,
      Start: new Date(dataItem.StartDateTime),
      End: new Date(dataItem.EndDateTime),
      isAllDay: dataItem.isAllDay,
      RecurrenceRule: dataItem.RecurrenceRule,
      RecurrenceId: dataItem.RecurrenceID,
      RecurrenceExceptions: dataItem.RecurrenceException,
      TaskCommandID: dataItem.TaskCommandID,
      Parameters: dataItem.Parameters,
    }
  ));
  console.log("SchdTasks TaskData: " + JSON.stringify(TaskData));
  return TaskData;
  //this.setState({ SchedulerData: TaskData });
}
// End of Sample Data
//--------------------------------------------------------------------------------------------------------
export const SchedControl = ({ TaskData }) => {
  //console.log(`Sched Control TaskData: ${JSON.stringify(TaskData)}`);
  const timezones = useMemo(() => timezoneNames(), []);
  const locales = [{ language: "en-US", locale: "en", }, { language: "es-ES", locale: "es", },];
  const [dayStart, setStart] = useState("06:00");   // Hooks                      
  const [dayEnd, setEnd] = useState("22:00");   // Hooks
  const [schdView, setView] = useState("month");   // Hooks
  const schdViewRef = useRef(); // Hook to provide reference - without reference, the schdView value only shows the initial value (bizarre)
  schdViewRef.current = schdView; // Reference to the schdView value - note - like setState, the update is not immediate
  const sDate = new Date();
  const startDate = new Date(Date.UTC(sDate));
  const [schdStrtDate, setDate] = useState(schedDate);
  const schdStrtDateRef = useRef();
  schdStrtDateRef.current = schdStrtDate;
  const [schdSaveDate, setSaveDate] = useState(schedDate);
  const schdSaveDateRef = useRef();
  schdSaveDateRef.current = schdSaveDate;
  const [schdLocale, setLocale] = useState(locales[0]);
  const schdLocaleRef = useRef(); // Hook to provide reference  
  //------ Date -----
  const getSchedTasks = async (locationID, startDate, view) => {
    //console.log("Get Tasks -------------------------------------------------");
    //console.log(`location: ${locationID}, start: ${startDate}, view: ${view}`);
    let parms = [];
    parms.push(startDate);
    parms.push(view);
    let CD = await CallOM("GetTasks", locationID, 0, 9, parms); // TaskList Returned for Scheduler
    getTaskID(); // Initialize TaskID for create
    //console.log("W Tasks: " + JSON.stringify(CD.d));
    let taskData = CD.d.rows;
    //console.log("TaskData: " + JSON.stringify(this.taskData));
    let TaskData = taskData.map(dataItem => (
      {
        TaskID: dataItem.COTaskID,
        OwnerID: 2,
        Title: dataItem.Title,
        Description: dataItem.Description,
        Start: new Date(dataItem.StartDateTime),
        End: new Date(dataItem.EndDateTime),
        isAllDay: dataItem.isAllDay,
        RecurrenceRule: dataItem.RecurrenceRule,
        RecurrenceId: dataItem.RecurrenceID,
        RecurrenceExceptions: dataItem.RecurrenceException,
        TaskCommandID: dataItem.TaskCommandID,
        Parameters: dataItem.Parameters,
      }));
    setData(TaskData);
    setCanChangeDate((old) => true);
    //console.log("SchdTasks TaskData: " + JSON.stringify(TaskData));
    //handleDateChange.trigger(startDate);
    setDate(startDate);
    return TaskData;
    //this.setState({ SchedulerData: TaskData });
  }
  const [canChangeDate, setCanChangeDate] = React.useState(false);
  const handleDateChange = useCallback(
    (event) => {
      //console.log(`Date Change event: ${event.value}, view: ${schdViewRef.current}`);
      if (canChangeDate) {
        setDate(event.value);
      }
      else {
        TaskData = getSchedTasks(0, event.value, schdViewRef.current);
        //console.log("Callback TaskData: " + JSON.stringify(TaskData));
      }
    }, [setDate]);
  const handleOverride = React.useCallback(
    (event) => {
      if (event.value) {
        setDate(event.value);
      }
    },
    [setDate]);
  const handleLockChange = React.useCallback(() => {
    setCanChangeDate((old) => !old);
  }, [setCanChangeDate]);
  //--------------
  schdLocaleRef.current = schdLocale;
  const [schdTimezone, setTimezone] = useState(window.navigator.timezone);
  const schdTimezoneRef = useRef();
  schdTimezoneRef.current = schdTimezone;
  const [schdOrientation, setOrientation] = useState("horizontal");
  const schdOrientationRef = useRef();
  schdOrientationRef.current = schdOrientation;

  const [schdData, setData] = React.useState(TaskData);
  const schdDataRef = useRef();
  schdDataRef.current = TaskData;

  const handleViewChange = useCallback(
    (event) => {
      //console.log(`New view: ${event.value}`);
      setView(event.value)
      //console.log(`set view: ${schdViewRef.current}`);
    }, [schdView,setView]);
  const handleLocaleChange = useCallback((event) => {setLocale(event.target.value);}, [setLocale]);
  const handleTimezoneChange = useCallback((event) => {setTimezone(event.target.value);}, [setTimezone]);
  const handleOrientationChange = useCallback((event) => { setOrientation(event.target.getAttribute("data-orientation")); }, []);

  const handleDataChange = useCallback(
    ({ created, updated, deleted }) => {
      //console.log(`Sched Start Date: ${schdStrtDate}, Date: ${schdStrtDateRef.current}`);
      SessionInfo.CurrentSchedDate = schdStrtDateRef.current;
      if (created && created.length > 0) {
        console.log(`Update Server Here - created: ${JSON.stringify(created)}`);
        CallOM("CreateTask", gettaskid(), SessionInfo.OrderLocationID, 13, created);
      }
      if (updated && updated.length > 0) {
        //console.log(`updated: ${JSON.stringify(updated)}`);
        CallOM("UpdateTask", 0, SessionInfo.OrderLocationID, 13, updated);
      }
      if (deleted && deleted.length > 0) {
        //console.log(`deleted: ${JSON.stringify(deleted)}`);
        CallOM("DeleteTask", 0, SessionInfo.OrderLocationID, 13, deleted);
      }
      setData((old) =>
        old
          .filter((item) => deleted.find((current) => current.TaskID === item.TaskID) === undefined)
          .map((item) => updated.find((current) => current.TaskID === item.TaskID) || item)
          .concat(created.map((item) => Object.assign({}, item, { TaskID: getTaskID(), }))
        )  
      );     
      if (created && created.length > 0) {
        console.log(`Get TaskID - created: ${JSON.stringify(created)}`);
        getTaskID();
      }
    },[setData]);
  return (
    <div>
      {/*<div className="configSched">
        <div className="row">
          <div className="col">
            <h4>Timezone:</h4>
            <DropDownList value={schdTimezone} onChange={handleTimezoneChange} data={timezones} />
          </div>
          <div className="col">
            <h4>Locale:</h4>
            <DropDownList value={schdLocale} onChange={handleLocaleChange} data={locales} textField="language" dataItemKey="locale" />
          </div>
          <div className="col">
            <h4>Orientation:</h4>
            <input type="radio" name="orientation" id="horizontal" data-orientation="horizontal" className="k-radio" checked={schdOrientation === "horizontal"} onChange={handleOrientationChange} />
            <label className="k-radio-label" htmlFor="horizontal">
              Horizontal
            </label>
            <br />
            <input type="radio" name="orientation" id="vertical" data-orientation="vertical" className="k-radio" checked={schdOrientation === "vertical"} onChange={handleOrientationChange} />
            <label className="k-radio-label" htmlFor="vertical">
              Vertical
            </label>
          </div>
        </div>
      </div> */}
      <LocalizationProvider language={schdLocale.language}>
        <IntlProvider locale={schdLocale.locale}>
          <Scheduler height='100%'
            data={schdData}
            onDataChange={handleDataChange}
            view={schdView}
            onViewChange={handleViewChange}
            date={schdStrtDate}
            onDateChange={handleDateChange}
            editable={true}
            timezone={schdTimezone}
            modelFields={customModelFields}
            resources={[
              {
                name: "Commands",
                data: [
                  {
                    text: "Command 1",
                    value: 1,
                  },
                  {
                    text: "Command 2",
                    value: 2,
                    color: "#FF7272",
                  },
                ],
                field: "TaskCommandID",
                valueField: "value",
                textField: "text",
                colorField: "color",
              },
              {
                name: "Parameters",
                data: [
                  {
                    text: "Parm 1",
                    value: 1,
                  },
                  {
                    text: "Parm 2",
                    value: 2,
                  },
                ],
                field: "Parameters",
                valueField: "value",
                textField: "text",
              }
              
            ]}
          >
          <TimelineView
            workDayStart={dayStart}
            workDayEnd={dayEnd}
          />
          <DayView
            defaultShowWorkHours={false}
            workDayStart={dayStart}
            workDayEnd={dayEnd}
          />
          <WeekView
            workDayStart={dayStart}
            workDayEnd={dayEnd}
          />
          <MonthView />
          <AgendaView />
          </Scheduler>
        </IntlProvider>
      </LocalizationProvider>
    </div>
  );
}

// Updated SchedControl
//export const SchedControl = async ({ TaskData }) => {
//  //console.log(`Sched Control TaskData: ${JSON.stringify(TaskData)}`);
//  const timezones = useMemo(() => timezoneNames(), []);
//  const locales = [{ language: "en-US", locale: "en", }, { language: "es-ES", locale: "es", },];
//  const [dayStart, setStart] = useState("06:00");   // Hooks                      
//  const [dayEnd, setEnd] = useState("22:00");   // Hooks
//  const [schdView, setView] = useState("month");   // Hooks
//  const schdViewRef = useRef(); // Hook to provide reference - without reference, the schdView value only shows the initial value (bizarre)
//  schdViewRef.current = schdView; // Reference to the schdView value - note - like setState, the update is not immediate 
//  const sDate = new Date();
//  const startDate = new Date(Date.UTC(sDate));
//  const [schdStrtDate, setDate] = useState(schedDate);
//  const schdStrtDateRef = useRef();
//  schdStrtDateRef.current = schdStrtDate;
//  const [schdSaveDate, setSaveDate] = useState(schedDate);
//  const schdSaveDateRef = useRef();
//  schdSaveDateRef.current = schdSaveDate;
//  const [schdLocale, setLocale] = useState(locales[0]);
//  const schdLocaleRef = useRef(); // Hook to provide reference
//  //------ Date -----
//  //const initSchedTasks = async (locationID) => {    
//  //  console(`getSchedTasks - location: ${locationID}`);
//  //  let startDate = new Date();
//  //  let view = "month";
//  //  getSchedTasks(locationID,startDate,view);
//  //}
//  const getSchedTasks = async (locationID, startDate, view) => {
//    //console.log("Get Tasks -------------------------------------------------");
//    console.log(`getSchedTasks location: ${locationID}, start: ${startDate}, view: ${view}`);
//    let parms = [];
//    parms.push(startDate);
//    parms.push(view);
//    let CD = await CallOM("GetTasks",locationID,0,9,parms); // TaskList Returned for Scheduler
//    getTaskID(); // Initialize TaskID for create
//    //console.log("W Tasks: " + JSON.stringify(CD.d));
//    let taskData = CD.d.rows;
//    //console.log("TaskData: " + JSON.stringify(this.taskData));
//    let TaskData = taskData.map(dataItem => (
//      {
//        TaskID: dataItem.COTaskID,
//        OwnerID: 2,
//        Title: dataItem.Title,
//        Description: dataItem.Description,
//        Start: new Date(dataItem.StartDateTime),
//        End: new Date(dataItem.EndDateTime),
//        isAllDay: dataItem.isAllDay,
//        RecurrenceRule: dataItem.RecurrenceRule,
//        RecurrenceId: dataItem.RecurrenceID,
//        RecurrenceExceptions: dataItem.RecurrenceException,
//        TaskCommandID: dataItem.TaskCommandID,
//        Parameters: dataItem.Parameters,
//      }));
//    setData(TaskData);
//    setCanChangeDate((old) => true);
//    //console.log("SchdTasks TaskData: " + JSON.stringify(TaskData));
//    //handleDateChange.trigger(startDate);
//    setDate(startDate);
//    return TaskData;
//    //this.setState({ SchedulerData: TaskData });
//  }
//  const [canChangeDate, setCanChangeDate] = React.useState(false);
//  const handleDateChange = useCallback(
//    (event) => {
//      //console.log(`Date Change event: ${event.value}, view: ${schdViewRef.current}`);
//      if (canChangeDate) {
//        setDate(event.value);
//      }
//      else {
//        TaskData = getSchedTasks(0, event.value, schdViewRef.current);
//        //console.log("Callback TaskData: " + JSON.stringify(TaskData));
//      }
//    }, [setDate]);
//  const handleOverride = React.useCallback(
//    (event) => {
//      if (event.value) {
//        setDate(event.value);
//      }
//    },
//    [setDate]);
//  const handleLockChange = React.useCallback(() => {
//    setCanChangeDate((old) => !old);
//  }, [setCanChangeDate]);
//  //--------------
//  schdLocaleRef.current = schdLocale;
//  const [schdTimezone, setTimezone] = useState(window.navigator.timezone);
//  const schdTimezoneRef = useRef();
//  schdTimezoneRef.current = schdTimezone;
//  const [schdOrientation, setOrientation] = useState("horizontal");
//  const schdOrientationRef = useRef();
//  schdOrientationRef.current = schdOrientation;

//  const [schdData, setData] = React.useState(TaskData);
//  const schdDataRef = useRef();
//  schdDataRef.current = TaskData;

//  const handleViewChange = useCallback(
//    (event) => {
//      //console.log(`New view: ${event.value}`);
//      setView(event.value)
//      //console.log(`set view: ${schdViewRef.current}`);
//    }, [schdView,setView]);
//  const handleLocaleChange = useCallback((event) => {setLocale(event.target.value);}, [setLocale]);
//  const handleTimezoneChange = useCallback((event) => {setTimezone(event.target.value);}, [setTimezone]);
//  const handleOrientationChange = useCallback((event) => { setOrientation(event.target.getAttribute("data-orientation")); }, []);

//  const handleDataChange = useCallback(
//    ({ created, updated, deleted }) => {
//      //console.log(`Sched Start Date: ${schdStrtDate}, Date: ${schdStrtDateRef.current}`);
//      SessionInfo.CurrentSchedDate = schdStrtDateRef.current;
//      if (created && created.length > 0) {
//        console.log(`Update Server Here - created: ${JSON.stringify(created)}`);
//        CallOM("CreateTask", gettaskid(), SessionInfo.OrderLocationID, 13, created);
//      }
//      if (updated && updated.length > 0) {
//        //console.log(`updated: ${JSON.stringify(updated)}`);
//        CallOM("UpdateTask", 0, SessionInfo.OrderLocationID, 13, updated);
//      }
//      if (deleted && deleted.length > 0) {
//        //console.log(`deleted: ${JSON.stringify(deleted)}`);
//        CallOM("DeleteTask", 0, SessionInfo.OrderLocationID, 13, deleted);
//      }
//      setData((old) =>
//        old
//          .filter((item) => deleted.find((current) => current.TaskID === item.TaskID) === undefined)
//          .map((item) => updated.find((current) => current.TaskID === item.TaskID) || item)
//          .concat(created.map((item) => Object.assign({}, item, { TaskID: getTaskID(), }))
//        )  
//      );     
//      if (created && created.length > 0) {
//        console.log(`Get TaskID - created: ${JSON.stringify(created)}`);
//        getTaskID();
//      }
//    },[setData]);
//  //if (TaskData.length === 0 || TaskData === null) {
//  //  console.log(`===========> TaskData is Null, LocationID: ${LocationID}`);
//  //  await initSchedTasks(LocationID); 
//  //}
//  return (
//    <div>
//      <LocalizationProvider language={schdLocale.language}>
//        <IntlProvider locale={schdLocale.locale}>
//          <Scheduler height='100%'
//            data={schdData}
//            onDataChange={handleDataChange}
//            view={schdView}
//            onViewChange={handleViewChange}
//            date={schdStrtDate}          
//            editable={true}
//            timezone={schdTimezone}
//            modelFields={customModelFields}
//            resources={[
//              {
//                name: "Commands",
//                data: [
//                  {
//                    text: "Command 1",
//                    value: 1,
//                  },
//                  {
//                    text: "Command 2",
//                    value: 2,
//                    color: "#FF7272",
//                  },
//                ],
//                field: "TaskCommandID",
//                valueField: "value",
//                textField: "text",
//                colorField: "color",
//              },
//              {
//                name: "Parameters",
//                data: [
//                  {
//                    text: "Parm 1",
//                    value: 1,
//                  },
//                  {
//                    text: "Parm 2",
//                    value: 2,
//                  },
//                ],
//                field: "Parameters",
//                valueField: "value",
//                textField: "text",
//              }
              
//            ]}
//          >
//          <TimelineView
//            workDayStart={dayStart}
//            workDayEnd={dayEnd}
//          />
//          <DayView
//            defaultShowWorkHours={false}
//            workDayStart={dayStart}
//            workDayEnd={dayEnd}
//          />
//          <WeekView
//            workDayStart={dayStart}
//            workDayEnd={dayEnd}
//          />
//          <MonthView />
//          <AgendaView />
//          </Scheduler>
//        </IntlProvider>
//      </LocalizationProvider>
//    </div>
//  );
//}
//--------------------------------------------------------------------------------------------------------  
// Reservation Scheduler Control
export const resSchedDate = new Date();
let restaskid = 10;
export const getResTaskID = async () => {
  let CD = await CallOM("GetResTaskID",0,0,0);
  //console.log(`CD: ${JSON.stringify(CD)}`);
  restaskid = CD.d;
  //displayMessage(`taskID: ${taskid}`);
  //console.log(`current tasks: ${JSON.stringify(baseSchedData)}`);
  return restaskid;
}
const getrestaskid = () => {
  return restaskid;
}
export const ResSchedControl = ({ TaskData }) => {
  //console.log(`Sched Control TaskData: ${JSON.stringify(TaskData)}`);
  const timezones = useMemo(() => timezoneNames(),[]);
  const locales = [{ language: "en-US",locale: "en",},{ language: "es-ES",locale: "es",},];
  const [dayStart,setStart] = useState("06:00");   // Hooks                      
  const [dayEnd,setEnd] = useState("22:00");   // Hooks
  const [schdView,setView] = useState("month");   // Hooks
  const schdViewRef = useRef(); // Hook to provide reference - without reference, the schdView value only shows the initial value (bizarre)
  schdViewRef.current = schdView; // Reference to the schdView value - note - like setState, the update is not immediate
  //const startDate = new Date(Date.UTC(startDate));
  const [schdStrtDate,setDate] = useState(schedDate);
  const schdStrtDateRef = useRef();
  schdStrtDateRef.current = schdStrtDate;
  const [schdSaveDate,setSaveDate] = useState(schedDate);
  const schdSaveDateRef = useRef();
  schdSaveDateRef.current = schdSaveDate;
  const [schdLocale,setLocale] = useState(locales[0]);
  const schdLocaleRef = useRef(); // Hook to provide reference  
  //------ Date -----
  const getResSchedTasks = async (locationID,startDate,view) => {
    //console.log("Get Tasks -------------------------------------------------");
    console.log(`getResSchedTasks location: ${locationID}, start: ${startDate}, view: ${view}`);
    let parms = [];
    parms.push(startDate);
    parms.push(view);
    let CD = await CallOM("GetCurrentReservationTasks",locationID,0,9,parms); // TaskList Returned for Scheduler  
    await getResTaskID(); // Initialize TaskID for create
    console.log(`Reservations: ${JSON.stringify(CD.d)}`);
    let taskData = CD.d.rows;
    //console.log("taskData: " + JSON.stringify(this.taskData));
    TaskData = taskData.map(dataItem => (
      {
        TaskID: dataItem.ReservationID,
        OwnerID: 2,                                  
        Title: `Reservation For: ${dataItem.CustomerID}`,
        Description: dateFormat(dataItem.ReservationDateTime),
        Start: new Date(dataItem.ReservationDateTime),
        End: new Date(dataItem.EndDateTime),
        isAllDay: false,
        RecurrenceRule: null,
        RecurrenceId: null,
        RecurrenceExceptions: null,
        //  TaskCommandID: dataItem.TaskCommandID,
        //  Parameters: dataItem.Parameters,
      }
    ));
    console.log(`Res TaskData: ${JSON.stringify(TaskData)}`);
    setData(TaskData);
    setCanChangeDate((old) => true);
    setDate(startDate);
    return TaskData;
  }
  const [canChangeDate,setCanChangeDate] = React.useState(false);
  const handleDateChange = useCallback(
    (event) => {
      //console.log(`Date Change event: ${event.value}, view: ${schdViewRef.current}`);
      if (canChangeDate) {
        setDate(event.value);
      }
      else {
        TaskData = getResSchedTasks(0,event.value,schdViewRef.current);
        //console.log("Callback TaskData: " + JSON.stringify(TaskData));
      }
    },[setDate]);
  const handleOverride = React.useCallback(
    (event) => {
      if (event.value) {
        setDate(event.value);
      }
    },
    [setDate]);
  const handleLockChange = React.useCallback(() => {
    setCanChangeDate((old) => !old);
  },[setCanChangeDate]);
  //--------------
  schdLocaleRef.current = schdLocale;
  const [schdTimezone,setTimezone] = useState(window.navigator.timezone);
  const schdTimezoneRef = useRef();
  schdTimezoneRef.current = schdTimezone;
  const [schdOrientation,setOrientation] = useState("horizontal");
  const schdOrientationRef = useRef();
  schdOrientationRef.current = schdOrientation;

  const [schdData,setData] = React.useState(TaskData);
  const schdDataRef = useRef();
  schdDataRef.current = TaskData;

  const handleViewChange = useCallback(
    (event) => {
      //console.log(`New view: ${event.value}`);
      setView(event.value)
      //console.log(`set view: ${schdViewRef.current}`);
    },[schdView,setView]);
  const handleLocaleChange = useCallback((event) => { setLocale(event.target.value); },[setLocale]);
  const handleTimezoneChange = useCallback((event) => { setTimezone(event.target.value); },[setTimezone]);
  const handleOrientationChange = useCallback((event) => { setOrientation(event.target.getAttribute("data-orientation")); },[]);

  const handleDataChange = useCallback(
    ({ created,updated,deleted }) => {
      //console.log(`Sched Start Date: ${schdStrtDate}, Date: ${schdStrtDateRef.current}`);
      SessionInfo.CurrentSchedDate = schdStrtDateRef.current;
      if (created && created.length > 0) {
        console.log(`Update Server Here - created: ${JSON.stringify(created)}`);
        CallOM("CreateReservation",getrestaskid(),SessionInfo.OrderLocationID,13,created);
      }
      if (updated && updated.length > 0) {
        console.log(`updated info list: ${JSON.stringify(updated)}`);
        CallOM("UpdateReservation",0,SessionInfo.OrderLocationID,13,updated);
      }
      if (deleted && deleted.length > 0) {
        //console.log(`deleted: ${JSON.stringify(deleted)}`);
        CallOM("DeleteReservation",0,SessionInfo.OrderLocationID,13,deleted);
      }
      setData((old) =>
        old
          .filter((item) => deleted.find((current) => current.TaskID === item.TaskID) === undefined)
          .map((item) => updated.find((current) => current.TaskID === item.TaskID) || item)
          .concat(created.map((item) => Object.assign({},item,{ TaskID: getTaskID(),}))
          )
      );
      if (created && created.length > 0) {
        console.log(`Get TaskID - created: ${JSON.stringify(created)}`);
        getTaskID();
      }
    },[setData]);
  return (
    <div>
      <LocalizationProvider language={schdLocale.language}>
        <IntlProvider locale={schdLocale.locale}>
          <Scheduler height='100%'
            data={schdData}
            onDataChange={handleDataChange}
            view={schdView}
            onViewChange={handleViewChange}
            date={schdStrtDate}
            onDateChange={handleDateChange}
            editable={true}
            timezone={schdTimezone}
            modelFields={customModelFields}
            resources={[]} >
            <TimelineView
              workDayStart={dayStart}
              workDayEnd={dayEnd}
            />
            <DayView
              defaultShowWorkHours={false}
              workDayStart={dayStart}
              workDayEnd={dayEnd}
            />
            <WeekView
              workDayStart={dayStart}
              workDayEnd={dayEnd}
            />
            <MonthView />
            <AgendaView />
          </Scheduler>
        </IntlProvider>
      </LocalizationProvider>
    </div>
  );
}
// End of: Reservation Scheduler Control
//-------------------------- IFrame ----------------------------------------------------------------------------------  
//export const Iframe = React.createClass({    
//export const Iframe = ({ AuthData }) => {
export const Iframe = ({ src,height,width,token }) => {
  return (
    <div>
      <iframe src={src} height={ height } width={width} />
    </div>
  );
}
//-------------------------- ProductTypes ----------------------------------------------------------------------------------   
export const EditGridControl = ({ InData }) => {
  console.log(`EditGridControl - Data: ${GridData}`);
  const [Skip, setSkip] = useState(8);
  const [Take, setTake] = useState(8);
  const [GridData, setData] = useState(InData);
  //getProductTypes = async (search) => {
  //  if (!search)
  //    search = '';
  //  let CD = await GetTableSearchRaw(this.ProductTypeOID, search, "ProductItemTypeID,ProductItemType,Description,IsRecordActive");
  //  try {
  //    const ProductTypes = CD.d.rows.map(dataItem => Object.assign({ selected: false }, dataItem));
  //    this.setState({ ProductTypes });
  //    this.state.saveProductTypes = ProductTypes;
  //  } catch (error) {
  //    displayError("getProductType: " + error.message);
  //  }
  //}
//  ProductTypeRowClick = async (event) => {
//    const current = this.state.ProductTypes.findIndex(dataItem => dataItem === event.dataItem);
//    if (current >= 0) {
//      try {
//        this.state.ProductTypes.forEach(item => item.selected = false);
//        this.state.ProductTypes[current].selected = true;
//        let ProductItemTypeID = this.state.ProductTypes[current].ProductItemTypeID;
//        this.setState({ ProductItemTypeID });
//        let CD = await GetTableRow(this.ProductTypeOID, ProductItemTypeID);
//        for (var prop in CD.d.row) {
//          if (prop in this.state) {
//            let obj = {};
//            obj[prop] = CD.d.row[prop];
//            if (prop.indexOf("Date") >= 0) {
//              obj[prop] = new Date(CD.d.row[prop]); //new Date()
//            }
//            this.setState(obj);
//          }
//        }
//        this.setItemStateChanged(false);
//      }
//      catch (error) {
//        displayError("ProductTypeRowClick: " + error);
//      }
//    }
//  }
//  ProductTypesPageChange = (event) => {
//    this.setState({ ProductTypesSkip: event.page.skip, ProductTypesTake: event.page.take });
//  }
//  //------------------------- Edit ProductType Grid ------------------------------------------------------------------  
//  ProductTypeCommandCell;
//  enterInsertProductType = () => {
//    const dataItem = { inEdit: true };
//    const allRecords = this.state.ProductTypes.slice();
//    allRecords.unshift(dataItem); // Add to the beginning
//    this.updateProductTypes(allRecords, dataItem);
//    this.setState({ ProductTypes: allRecords });
//    this.InsertRecordType = 3;
//  }
//  enterEditProductType = (dataItem) => {
//    this.updateProductTypes(this.state.ProductTypes, dataItem).inEdit = true;
//    this.setState({ ProductTypes: this.state.ProductTypes.slice() });
//  }
//  saveProductType = (dataItem) => {
//    dataItem.inEdit = undefined;
//    if (dataItem.ProductItemTypeID === undefined)
//      dataItem.ProductItemTypeID = 0;
//    //console.log("Save");
//    dataItem.ProductItemTypeID = this.updateProductTypes(this.state.ProductTypes, dataItem).ProductItemTypeID;  // Save to OM
//    this.setState({ ProductTypes: this.state.ProductTypes.slice() });
//    //console.log("Update Row - locationID: " + dataItem.StoreLocationID); 
//    UpdateRow(this.ProductTypeOID, 0, this.state.ProductTypes, dataItem, dataItem.ProductItemTypeID, "ProductItemTypeID");  // tableID, control, allData, item, key, keyName 
//    SessionInfo.ProductItemTypeUpdate = true;
//    this.InsertRecordType = 0;
//    SessionInfo.ProductItemTypeUpdate = true;
//  }
//  cancelProductType = (dataItem) => {
//    if (dataItem.ProductItemTypeID) {
//      let originalItem = this.state.ProductTypes.find(p => p.ProductItemTypeID === dataItem.ProductItemTypeID)
//      if (originalItem.inEdit) originalItem.inEdit = false;
//      this.updateProductTypes(this.state.ProductTypes, originalItem);
//    } else {
//      this.updateProductTypes(this.state.ProductTypes, dataItem, !dataItem.ProductItemTypeID); // remove false
//    }
//    this.setState({ ProductTypes: this.state.ProductTypes.slice() });
//    this.InsertRecordType = 0;
//  }
//  removeProductType = (dataItem) => {
//    dataItem.inEdit = undefined;
//    let key = dataItem.ProductItemTypeID;
//    this.updateProductTypes(this.state.ProductTypes, dataItem, true);
//    this.state.saveProductTypes = this.state.ProductTypes;
//    DeleteRowTry(this.ProductTypeOID, key); // Delete in the ProductTypes Table in OM
//    this.setState({ ProductTypes: this.state.ProductTypes.slice() });
//  }
//  itemChangeProductType = (event) => {
//    const value = event.value;
//    const name = event.field;
//    if (!name) {
//      return;
//    }
//    const updatedData = this.state.ProductTypes.slice();
//    const item = this.updateProductTypes(updatedData, event.dataItem);
//    if (!value.ddID)
//      item[name] = value;
//    else
//      item[name] = value.ddID;
//    this.setState({ ProductTypes: updatedData });
//  }
//  updateProductTypes = (data, item, remove) => {  // data - is the entire data set (JSON), item - is the current line
//    let updated;
//    //eslint-disable-next-line                   
//    let index = data.findIndex(p => p === item || item.ProductItemTypeID && p.ProductItemTypeID === item.ProductItemTypeID); // Note - for new line will find 0
//    if (index >= 0) {
//      updated = Object.assign({}, item);
//      data[index] = updated;
//    }
//    if (remove) {
//      data = data.splice(index, 1);
//    }
//    return data[index];
//  }
//  return (
//  //  <TGrid style={{ position: 'absolute', top: 0, height: '100%' }} data={GridData.slice(Skip, Take + Skip)} onItemChange={this.itemChangeProductType}
//  //    onRowClick={ProductTypeRowClick} resizable={true} selectedField="selected" editField="inEdit"
//  //    skip={this.state.ProductTypesSkip} take={this.state.ProductTypesTake} total={this.state.ProductTypes.length} onPageChange={this.ProductTypesPageChange} pageable={{ info: true, type: 'numeric', pageSizes: true, previousNext: true }} pageSize={6}>
//  //    <Column field="ProductItemType" title="Product Type" width='200px' editor="text" />
//  //    <Column field="Description" title="Description" width='350px' editor="text" />
//  //    <Column field="IsRecordActive" cell={BoolCell} title="Record Active" width='180px' />
//  //    <Column cell={this.ProductTypeCommandCell} width="260px" />
//  //  </TGrid>
//  );
}
//-------------------------- End of ProductTypes ----------------------------------------------------------------------------------