// InteractManager - Using React 18.1.0 
import React from 'react';
import ReactDOM,{ createRoot } from "react-dom/client";

import App from './App';
import * as serviceWorker from './serviceWorker';

const container = document.getElementById("root");
const root = createRoot(container);
root.render(<App />);

serviceWorker.unregister(); // Without this, changes may not update 