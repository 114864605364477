//----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// PageHeader - General Application Page Header Component
//              Version 1.041- July 29, 2022
//----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
import React from 'react';
import * as ReactDOM from 'react-dom';
import { withRouter } from './withRouter';
import { Link } from 'react-router-dom'
import { Popup } from '@progress/kendo-react-popup';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Error } from '@progress/kendo-react-labels';
import { Input, NumericTextBox, TextArea } from '@progress/kendo-react-inputs';
import { FloatingLabel } from '@progress/kendo-react-labels'
import { Form, Field, FormElement } from '@progress/kendo-react-form';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { Avatar } from '@progress/kendo-react-layout';
import { Menu, MenuItem } from '@progress/kendo-react-layout';
//import { SvgIcon } from '@progress/kendo-react-common';
import PropTypes from 'prop-types';
import { SessionInfo } from './App';
import { CallOM, displayMessage, displayWarning, displayError, LogOffReset, QueryPing, saveImageFileToServer, getDocumentType, getImageFileFromServer } from './CommonCode.js';
import { GetMonth, GetDay } from './CommonFormat.js';
//
import 'hammerjs';
// Note - the following two are used as src for img
import AngleLeft from "./images/angle-left.svg"; // https://dev.to/abachi/how-to-change-svg-s-color-in-react-42g2
import AngleRight from "./images/angle-right.svg";
//
import { ReactComponent as ArrowDown } from "./images/arrow-down.svg";
import { ReactComponent as ArrowUp } from "./images/arrow-up.svg";
import { ReactComponent as StopLight } from "./images/circle.svg";
import { ReactComponent as ButtonSave } from "./images/button-save.svg";
import { ReactComponent as ButtonNew } from "./images/button-add.svg";
import { ReactComponent as ButtonAddRow } from "./images/button-add-row.svg";
import { ReactComponent as ButtonDelete } from "./images/button-delete.svg";
import { ReactComponent as ButtonSync } from "./images/button-sync.svg";
import { ReactComponent as HelpAbout } from "./images/help-about.svg";
import { ReactComponent as ButtonExportCSV } from "./images/button-export-csv.svg";
import { ReactComponent as ButtonSearch } from "./images/search.svg";
import { ReactComponent as ButtonClear } from "./images/button-clear.svg";
import { ReactComponent as SwitchSecond } from "./images/searchSecond.svg";
import { ReactComponent as SwitchFirst } from "./images/searchFirst.svg";
//
const emailRegex = new RegExp(/\S+@\S+\.\S+/);
const emailValidator = (value) => (emailRegex.test(value) ? "" : "Please enter a valid email.");
const EmailInput = (fieldRenderProps) => {
  const { validationMessage, visited, ...others } = fieldRenderProps;
  return (
    <div>
      <Input {...others} />
      {
        visited && validationMessage &&
        (<Error>{validationMessage}</Error>)
      }
    </div>
  );
};

class PageHeader extends React.Component {
  constructor(props) {
    super(props);
    if (this.props.IsApp)
      SessionInfo.IsApp = true;
    else
      SessionInfo.IsApp = false;
    if (SessionInfo.HeaderPage) { // Data saved for this session
      this.state = SessionInfo.HeaderPage;
      this.loadExistingPage = true;
    }
    else {
      this.loadExistingPage = false;
    }
    //console.log(`Header LoadExisting: ${this.loadExistingPage}`);
    this.searchButton = this.searchButton.bind(this);
    this.handleLogoff = this.handleLogoff.bind(this);
    this.selectColor = props.Select;
    SessionInfo.headerTitle = this.props.PageTitle;
    SessionInfo.itemName = this.props.ItemName;
    SessionInfo.clearSearch = this.ClearSearch;
  }
  state = {
    searchValue: '',
    lastSearchValue: '',
    searchFirst: true,
    clearSetAll: false,
    searchTitle: 'xx',
    MessagesValue: '',
    notifyMessage: '',
    currentTime: '',
    currentDate: '',
    isLogon: false,
    logonName: '',
    appDisplay: false,
    msgDisplay: false,
    forceShowMessageBox: false,
    showMessage: SessionInfo.ForceShowMessageBox,
    showFlag1: false,
    showFlag2: false,
    showFlag3: false,
    colors: ['', '', '', '', '', '', ''],
    showMessagePopup: false,
    showProfilePopup: false,
    dialogVisible: false,
    showNotify: false,
    showSave: false,
    showNew: false,
    showDelete: false,
    showGridRefresh: false,
    showGridAdd: false,
    showExcelExport: false,
    showPaneClose: false,
    showPaneOpen: false,
    showPaneRClose: false,
    showPaneROpen: false,
    headerTitle: '',
    itemName: '',
    showCMenu: false,
    left: 0,
    top: 0,
  };
  loadExistingPage = false;
  messageAnchor = null;
  notifyOffset = { left: 150, top: 90 };
  cmenuOffset = { left: 150, top: 90 };
  profileAnchor = null;
  profileOffset = { left: 650, top: 90 };
  refreshCount = 6;
  pingRetryCount = 0;
  pingFail = 0;
  flagTime = 0;
  popupTimer = 0;
  notifyTimer = 0;
  timeout = undefined;
  activeColor = "#3e80ed";
  doubleColor = "#33cc33"
  inactiveColor = "#BBBDC7";
  okColor = "#00FF00";
  warnColor = "#ffb01f";
  errorColor = "#FF0000";
  selectColor = undefined;
  compUpdate = true;
  currentWidth = 0;
  messageWidth = 392;
  //
  componentDidMount() {
    //console.log(`Header componentDidMount LoadExisting: ${this.loadExistingPage}`);
    this.setState({ currentTime: this.getTime() });
    console.log(`Start Monitor`);
    this.monitorProcess();
    console.log(`Monitor Started`);
    if (this.props.IsApp)
      this.setState({ appDisplay: true });
    if (this.props.IsLogon)
      this.setState({ isLogon: true });
    //if (this.loadExistingPage === true)
    //  this.forceUpdate();
    //else {
    //}            
    this.setState({ showMessage: SessionInfo.ForceShowMessageBox }); // Only hide the Message box if the message popup is hiden
    this.setState({ headerTitle: this.props.PageTitle });
    this.setState({ itemName: this.props.ItemName });
    this.setState({ searchTitle: "Search" });
    document.addEventListener('click', this.contextMenu);
    this.setState({ clearSetAll: false });
    //if (this.selectColor !== undefined)
    //  this.state.colors[this.selectColor] = '#16f053';
    this.forceUpdate();
  }
  componentDidUpdate() {
    //let value = this.context;
  }
  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.runMonitorProcess = false;
    //if (SessionInfo.session !== '')
    //  SessionInfo.HeaderPage = this.state;
  }
  shouldComponentUpdate() {
    //console.log("Should Update?");
    if (this.compUpdate === true)
      return true;
    else
      return false;
  }
  contextMenu = async (e) => {
    if (this.state.showCMenu)
      this.setState({ showCMenu: false });
  }
  handleSubmit = (dataItem) => {
    alert(JSON.stringify(dataItem, null, 2));
    this.setState({ showProfilePopup: false })
  }
  loadImage = async (e) => {
    console.log(`load Person Image: ${SessionInfo.PersonPhotoID}`);
    if (SessionInfo.PersonPhotoURL === undefined) {
      SessionInfo.PersonPhotoURL = await getImageFileFromServer(SessionInfo.PersonPhotoID, 0);
    }
    this.setState({ PersonPhotoURL: SessionInfo.PersonPhotoURL });
  }
  gotoAccountInfo = async (e) => {
    SessionInfo.AccountShowCurrent = true;
    SessionInfo.MenuSelect = { text: 'Accounts',expanded: false,route: 'CustomerProfile',key: 50,goToTab: 0,call: undefined,icon: undefined,className: 'sideMenuMain',space: <span>&nbsp;&nbsp;</span>,items: [] };
    console.log(`go to Customer Profile - Account: ${SessionInfo.userID}`);
    await SessionInfo.menuAction(); // onMenuItemClick in AppMain
  }
  handleContextMenu = (e) => {
    console.log(`offset x: ${e.clientX}, y: ${e.clientY}`)
    e.preventDefault();
    this.cmenuOffset = { left: e.clientX,top: e.clientY };
    this.setState({ showCMenu: true });
  }
  menuChoice = async (e) => { // Item Selection
    console.log(`Menu Choice: ${e.itemId}`);
    if (e.itemId === '0') {
      displayWarning("User Profile not available yet");
      await this.setState({ showProfilePopup: true });
    }
    else if (e.itemId === '1')
      LogOffReset("LogOff");
  }
  searchInput() {
    console.log("Search Input: " + this.state.searchValue);
    //addMessage(searchStr);
  }
  searchButton = async (event) => {
    console.log('search: ' + this.state.searchValue);
    event.preventDefault();
    event.stopPropagation();
    if (this.state.searchValue.length > 0)
      displayMessage("Search: " + this.state.searchValue);
    else
      displayMessage("Clear Search");
    if (SessionInfo.searchFunc !== undefined)
      await SessionInfo.searchFunc(this.state.searchValue);
  }
  updateSearchValue = async (evt) => {
    //console.log('search: ' + evt.target.value);
    this.setState({ searchValue: evt.target.value });
    if (!(evt.target.value.length > 0 && (evt.target.value[0] === '*' || evt.target.value[0] === '`'))) {
      if (evt.target.value.length >= 3 || this.state.lastSearchValue.length > evt.target.value.length) {
        this.setState({ lastSearchValue: evt.target.value });
        if (SessionInfo.searchFunc !== undefined)
          await SessionInfo.searchFunc(evt.target.value);
      }
      else {
        this.setState({ lastSearchValue: evt.target.value });
      }
    }
  }
  //  useEffect(() => { // HOOK - not sure why I can't call this
  //    const listener = event => {
  //      if (event.code === "Enter" || event.code === "NumpadEnter") {
  //        console.log("Enter key was pressed. Run your function.");
  //        // callMyFunction();
  //      }
  //    };
  //    document.addEventListener("keydown", listener);
  //    return () => {
  //      document.removeEventListener("keydown", listener);
  //    };
  //}, []);
  getTime() { // Get CLOCK time
    const curTime = new Date();
    if (curTime) {
      let currentDate = GetDay(curTime.getDay()) + ' - ' + GetMonth(curTime.getMonth()) + ' ' + curTime.getDate(); // + ', ' + curTime.getFullYear();
      this.setState({ currentDate });
      const currentTime =
      {
        hours: curTime.getHours(),
        minutes: curTime.getMinutes(),
        //seconds: curTime.getSeconds(),
        //ampm: curTime.getHours() >= 12 ? "pm" : "am"
      };
      return currentTime;
    }
    else
      return 'Time';
  }
  isMonitorRunning = false;
  setTimer() {
    //if (SessionInfo.loggedOn === true)     
    this.isMonitorRunning = true;
    this.timeout = setTimeout(this.monitorProcess.bind(this), 200); // 200 microseconds  
    //this.interval = setInterval(this.monitorProcess.bind(this), 200); // 200 microseconds
    // clearInterval(this.interval);
  }
  //----- Timer Process - Application Control Logic -------------------------------------------------
  monitorProcess() { // Note - Monitor only runs if logged on
    if (SessionInfo.forceLogoff === true) {
      console.log(`forceLogoff set - go to Login`);
      this.props.navigate("/Login");
    }
    if (SessionInfo.loggedOn === true) {
      this.setTimer();
      if (this.refreshCount++ > 4) { // Once a second
        this.setState({ currentTime: this.getTime() });
        this.refreshCount = 0;
        if (this.state.logonName !== SessionInfo.logonName)
          this.setState({ logonName: SessionInfo.logonName });
        if (this.state.AppEntityID !== SessionInfo.AppEntityID)
          this.setState({ AppEntityID: SessionInfo.AppEntityID });
        if (SessionInfo.registerCounter > 0)
          SessionInfo.registerCounter--;
      }
      if (SessionInfo.message !== this.state.MessagesValue)
        this.setState({ MessagesValue: SessionInfo.message });
      //console.log("flag: " + SessionInfo.flagLevel);
      if (SessionInfo.flagLevel === 1) {
        this.setState({ showFlag1: true }); // Green
        this.setState({ showFlag2: false });
        this.setState({ showFlag3: false });
        this.flagTime = 25;
        SessionInfo.flagLevel = 0;
      }
      else if (SessionInfo.flagLevel === 2) { // Warning
        this.setState({ showFlag1: false });
        this.setState({ showFlag2: true }); // Yellow
        this.setState({ showFlag3: false });
        this.flagTime = 30;
        SessionInfo.flagLevel = 0;
        if (SessionInfo.ForceShowMessageBox === true) {
          this.setState({ showMessage: true }); // Show Message Box
        }
      }
      else if (SessionInfo.flagLevel === 3) { // Error
        this.setState({ showFlag1: false });
        this.setState({ showFlag2: false });
        this.setState({ showFlag3: true }); // Red
        this.flagTime = 35;
        SessionInfo.flagLevel = 0;
        if (SessionInfo.ForceShowMessageBox === true) {
          this.setState({ showMessage: true }); // Show Message Box
        }
      }
      else if (SessionInfo.flagLevel === 4) { // SevereError
        this.setState({ showFlag1: false });
        this.setState({ showFlag2: false });
        this.setState({ showFlag3: true }); // Red
        this.flagTime = 45;
        SessionInfo.flagLevel = 0;
        if (SessionInfo.ForceShowMessageBox === true) {
          this.setState({ showMessage: true }); // Show Message Box
        }
      }
      else if (this.flagTime <= 0) { // Flag Time has Expired
        this.setState({ showFlag1: false });
        this.setState({ showFlag2: false });
        this.setState({ showFlag3: false });
        if (this.state.showMessagePopup == false && SessionInfo.ForceShowMessageBox === false) // Only hide the Message box if the message popup is hiden
          this.setState({ showMessage: false }); // Hide Message Box 
      }
      else {
        this.flagTime--;
      }
      if (SessionInfo.notifyMessage !== "") {
        this.setState({ notifyMessage: "<br/><span style='color:#ed1cd1;font-size:14pt;font-weight:bold'>&nbsp;&nbsp;" + SessionInfo.notifyMessage + "&nbsp;&nbsp;</span><br/><br/>" });
        this.notifyTimer = 35;
        this.setState({ showNotify: true });
        SessionInfo.notifyMessage = '';
      }
      if (SessionInfo.loggedOn == true && this.state.PersonPhotoURL === undefined) {
        this.loadImage();
      }
      if (SessionInfo.headerTitle !== "") {
        this.setState({ headerTitle: SessionInfo.headerTitle });
        SessionInfo.headerTitle = "";
      }
      if (SessionInfo.itemName !== "") {
        this.setState({ itemName: SessionInfo.itemName });
        SessionInfo.itemName = "";
      }
      if (SessionInfo.searchTitle !== "") {
        this.setState({ searchTitle: SessionInfo.searchTitle });
        SessionInfo.searchTitle = "";
      }
      if (SessionInfo.clearSearch === true) {
        this.setState({ searchValue: "" });
        SessionInfo.clearSearch = false;
      }
      if (SessionInfo.setShowNotify !== 0) {
        this.setState({ showNotify: SessionInfo.setShowNotify === 1 });
        SessionInfo.setShowNotify = 0;
      }
      if (SessionInfo.setShowSave !== 0) {
        //console.log("setshowsave: " + SessionInfo.setShowSave);
        this.setState({ showSave: SessionInfo.setShowSave === 1 });
        SessionInfo.setShowSave = 0;
      }
      if (SessionInfo.setShowNew !== 0) { // Clear
        if (SessionInfo.setShowNew === 2) {
          this.setState({ showNew: false });
          this.setState({ showNew2: false });
          SessionInfo.clearStage = 0;
        } else if (SessionInfo.setShowNew === 1) {
          this.setState({ showNew: true });
          this.setState({ showNew2: false });
          SessionInfo.clearStage = 1;
        } else if (SessionInfo.setShowNew === 3) {
          this.setState({ showNew: true });
          this.setState({ showNew2: true });
          SessionInfo.clearStage = 2;
        }
        SessionInfo.setShowNew = 0;
      }
      if (SessionInfo.setShowDelete !== 0) {
        this.setState({ showDelete: SessionInfo.setShowDelete === 1 });
        SessionInfo.setShowDelete = 0;
      }
      if (SessionInfo.setGridRefresh !== 0) {
        this.setState({ showGridRefresh: SessionInfo.setGridRefresh === 1 });
        SessionInfo.setGridRefresh = 0;
      }
      if (SessionInfo.setGridAdd !== 0) {
        this.setState({ showGridAdd: SessionInfo.setGridAdd === 1 });
        SessionInfo.setGridAdd = 0;
      }
      if (SessionInfo.setExcelExport !== 0) {
        this.setState({ showExcelExport: SessionInfo.setExcelExport === 1 });
        SessionInfo.setExcelExport = 0;
      }
      if (SessionInfo.setLPaneClose !== 0) {
        this.setState({ showPaneClose: SessionInfo.setLPaneClose === 1 });
        SessionInfo.setLPaneClose = 0;
      }
      if (SessionInfo.setLPaneOpen !== 0) {
        this.setState({ showPaneOpen: SessionInfo.setLPaneOpen === 1 });
        SessionInfo.setLPaneOpen = 0;
      }
      if (SessionInfo.setRPaneClose !== 0) {
        this.setState({ showRPaneClose: SessionInfo.setRPaneClose === 1 });
        SessionInfo.setRPaneClose = 0;
      }
      if (SessionInfo.setRPaneOpen !== 0) {
        this.setState({ showRPaneOpen: SessionInfo.setRPaneOpen === 1 });
        SessionInfo.setRPaneOpen = 0;
      } 
      if (SessionInfo.setMessageOpen !== 0) {
        if (SessionInfo.setMessageOpen === 1)
          this.ShowMessageBox();
        SessionInfo.setMessageOpen = 0;
      }
      if (SessionInfo.setPopupMessageOpen !== 0) {
        if (SessionInfo.setPopupMessageOpen === 1) {
          //this.ShowMessageBox();
          //console.log(`Popup Message OPEN`);
          this.PopupMessage(true);
        }
        SessionInfo.setPopupMessageOpen = 0;
      }
      if (this.popupTimer > 0) {
        if (--this.popupTimer <= 0)
          this.setState({ showMessagePopup: false });
      }
      if (this.notifyTimer > 0) { // Notify Message Popup
        if (--this.notifyTimer <= 0)
          this.setState({ showNotify: false });
      }
      if (this.pingRetryCount++ > 60) { // 1 minute
        QueryPing(); // Keep Session Alive - Note that in dormant tabs this will not work - NOTE - QueryPing is Async - and will set SessionInfo.forceLogoff if it fails
        this.pingRetryCount = 0;
      }
      if (this.currentWidth != window.innerWidth) {
        //console.log(`width: ${window.innerWidth}`);
        this.currentWidth = window.innerWidth;
        if (this.currentWidth >= 1700)
          this.messageWidth = 392;
        else {
          this.messageWidth = 392 - (1700 - this.currentWidth);
          if (this.messageWidth <= 100) {
            this.messageWidth = 100;
            this.setState({ showMessage: false });
            this.setState({ forceShowMessageBox: false });
            SessionInfo.ForceShowMessageBox = false;
            this.popupTimer = 0;
          }
        }
      }
    }
    else { // Not logged on            
      this.setTimer();
      if (SessionInfo.registerCounter > 0)
        SessionInfo.registerCounter--;
      if (SessionInfo.notifyMessage !== "") {
        this.setState({ notifyMessage: "<br/><span style='color:#ed1cd1'>&nbsp;&nbsp;" + SessionInfo.notifyMessage + "&nbsp;&nbsp;</span><br/><br/>" });
        this.notifyTimer = 25;
        this.setState({ showNotify: true });
        SessionInfo.notifyMessage = '';
      }
      else if (SessionInfo.message !== "") {
        if (SessionInfo.message !== this.state.MessagesValue)
          this.setState({ MessagesValue: SessionInfo.message });
      }
      if (this.notifyTimer > 0) {
        if (--this.notifyTimer <= 0)
          this.setState({ showNotify: false });
      }
    }
  }
  ClearSearch = async () => { // ClearDisplay, Clear Messages - see updateSearchValue
    if (this.state.searchValue != '') {
      this.setState({ searchValue: '' });
      this.setState({ lastSearchValue: '' });
      this.setState({ clearSetAll: true }); // Set for double search
    }
    else { // Note - Click twice to perform clear search
      if (this.state.clearSetAll === true)
        await SessionInfo.searchFunc('');
      this.setState({ clearSetAll: false });
    }
  }
  ClearMessage = async () => { // ClearDisplay, Clear Messages
    //console.log("ClearMessage Pressed - for: " + SessionInfo.currentPage);
    this.setState({ MessagesValue: '' });
    SessionInfo.message = ''; // MessagesValue is set to SessionInfo.message in the Timer loop  
    this.setState({ showMessagePopup: false });
  }
  SwitchToFirst = () => {
    SessionInfo.searchChoice = 0;
    this.setState({ searchFirst: true });
    this.setState({ searchTitle: SessionInfo.searchTitle1 });
  }
  SwitchToSecond = () => {
    SessionInfo.searchChoice = 1;
    this.setState({ searchFirst: false });
    this.setState({ searchTitle: SessionInfo.searchTitle2 });
  }
  PopupMessage = async (force) => {
    // Keep this code for example
    //var tag = document.createElement('div');
    //var newString = this.state.MessagesValue.replace(/<br\/>/g, "XXX");
    //console.log("newString: " + newString);
    //tag.innerHTML = newString;
    //newString = tag.innerHTML.replace(/XXX/g, "&#10;");
    //console.log("newString: " + newString);
    //this.setState({ MessagesText: newString });
    if (force) {
      this.popupTimer = 50;
      this.setState({ showMessagePopup: true });
    } else {
      if (this.state.showMessagePopup === false)
        this.popupTimer = 50;
      else
        this.popupTimer = 0;
      this.setState({ showMessagePopup: !this.state.showMessagePopup });
    }
    //this.setState({ dialogVisible: true });
  }
  ClosePopupMessage = () => {
    this.setState({ showMessagePopup: false });
    this.popupTimer = 0;
  }
  ShowMessageBox = async () => {
    if (SessionInfo.ForceShowMessageBox === false) {
      this.setState({ showMessage: true });
      this.setState({ forceShowMessageBox: true });
      SessionInfo.ForceShowMessageBox = true;
      this.popupTimer = 50000;
    }
    else {
      this.setState({ showMessage: false });
      this.setState({ forceShowMessageBox: false });
      SessionInfo.ForceShowMessageBox = false;
      this.popupTimer = 0;
    }
    //this.setState({ dialogVisible: true });
  }
  ShowHelp = async () => {
    if (SessionInfo.currShowHelp)
      await SessionInfo.currShowHelp();
  //  else
  //    navigate("/AboutPage");
  }
  toggleDialog = async () => {
    this.setState({ dialogVisible: !this.state.dialogVisible });
  }
  toggleShowGridAdd = async () => {
    this.setState({ showGridAdd: !this.state.showGridAdd });
  }
  toggleShowExcelExport = async () => {
    this.setState({ showExcelExport: !this.state.showExcelExport });
  }
  togglePaneOpen = async () => {
    this.setState({ showPaneOpen: !this.state.showPaneOpen });
  }
  togglePaneClose = async () => {
    this.setState({ showPaneClose: !this.state.showPaneClose });
  }
  //-------------------- on Click Functions --------------------
  onSaveCurrent = async () => {
    console.log("onSaveCurrent Pressed - for: " + SessionInfo.currentPage);
    await SessionInfo.currSaveFunc();
  }
  onClearCurrent = async () => { // Note - AddNew
    console.log("onClearCurrent Pressed - for: " + SessionInfo.currentPage);
    if (SessionInfo.currClearFunc)
      SessionInfo.currClearFunc();
  }
  onDeleteCurrent = async () => {
    //console.log("onDeleteCurrent Pressed - for: " + SessionInfo.currentPage);
    await SessionInfo.currDeleteFunc();
  }
  onRefreshGrid = async () => {
    //console.log("onDeleteCurrent Pressed - for: " + SessionInfo.currentPage);
    await SessionInfo.currRefreshGridFunc();
  }
  onAddGridRow = async () => {
    //console.log("onDeleteCurrent Pressed - for: " + SessionInfo.currentPage);
    await SessionInfo.currAddGridRowFunc();
  }
  onExcelExport = async () => {
    //console.log("onDeleteCurrent Pressed - for: " + SessionInfo.currentPage);
    await SessionInfo.currExcelExportFunc();
  }
  onPaneOpen = async () => {
    //console.log("onDeleteCurrent Pressed - for: " + SessionInfo.currentPage);
    await SessionInfo.currExpandLPane();
  }
  onPaneClose = async () => {
    //console.log("onDeleteCurrent Pressed - for: " + SessionInfo.currentPage);
    await SessionInfo.currCollapseLPane();
  }
  onRPaneOpen = async () => {
    //console.log("onDeleteCurrent Pressed - for: " + SessionInfo.currentPage);
    await SessionInfo.currExpandRPane();
  }
  onRPaneClose = async () => {
    //console.log("onDeleteCurrent Pressed - for: " + SessionInfo.currentPage);
    await SessionInfo.currCollapseRPane();
  }
  // Log off, logoff, sign out, sign off, signoff
  handleLogoff = async () => {
    await LogOffReset("Logoff");
  }
  setTitle = async (title) => {
    console.log("headerTitle: " + title);
    this.setState({ headerTitle: title });
  }
  //------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
  render() {
    const { hours, minutes } = this.state.currentTime;
    if (this.state.isLogon) {
      return (
        <div id="headerContainer">
          <div id="headerBotLeft">
            <Popup offset={this.notifyOffset} show={this.state.showNotify} popupClass={'popup-notifycontent'} ><div id="notifyBoxPU" dangerouslySetInnerHTML={{ __html: this.state.notifyMessage }}></div></Popup>
          </div>
        </div>
      )
    }
    else {
      return (
        <div id="headerContainer">
          {/*<div id="headerBotRight"> NOTE - 27Jan21 - I had included this div twice and it was blocking input - forcing the second div to the Z top?! */}
          {this.state.appDisplay ?
            <div id="functionDiv"> {/* Free floating Div */}
              <Tooltip position="top" anchorElement="pointer">
                {this.state.showSave === true ?
                  <ButtonSave fill={this.activeColor} alt="" id="Save" title={`Save the Current ${this.state.itemName} Information`} className="functionImage" onClick={this.onSaveCurrent} /> :
                  <ButtonSave fill={this.inactiveColor} alt="" id="Save" title={`Save the Current ${this.state.itemName} Information`} className="functionImage" onClick={this.onSaveCurrent} />}
                {this.state.showNew === false ?
                  <ButtonNew alt="" fill={this.inactiveColor} id="Clear" title={`Add a New ${this.state.itemName}`} className="functionImage" onClick={this.onClearCurrent} /> :
                  <>
                  {this.state.showNew2 === false ?
                    <ButtonNew alt="" fill={this.activeColor} id="Clear" title={`Add a New ${this.state.itemName}`} className="functionImage" onClick={this.onClearCurrent} /> :
                    <ButtonNew alt="" fill={this.doubleColor} id="Clear" title={`Add a New ${this.state.itemName}`} className="functionImage" onClick={this.onClearCurrent} />
                    }
                  </>}
                {this.state.showGridRefresh === true ?
                  <ButtonSync alt="" fill={this.activeColor} id="GridRefresh" title={`Refresh the Current ${this.state.itemName} Grid`} className="functionImage" onClick={this.onRefreshGrid} /> :
                  <ButtonSync alt="" fill={this.inactiveColor} id="GridRefreshG" title={`Refresh Grid Disabled`} className="functionImage" />}    
                &nbsp;&nbsp;
                {this.state.showDelete === true ?
                  <ButtonDelete alt="" fill={this.activeColor} id="Delete" title={`Delete the Current ${this.state.itemName}`} className="functionImage" onClick={this.onDeleteCurrent} /> :
                  <ButtonDelete alt="" fill={this.inactiveColor} id="DeleteG" title={`Delete the Current ${this.state.itemName} Disabled`} className="functionImage" />}
                &nbsp;&nbsp;
                {this.state.showGridAdd === true ?
                  <ButtonAddRow alt="" fill={this.activeColor} id="GridAdd" title={`Add a New Grid Row for ${this.state.itemName}`} className="functionImage" onClick={this.onAddGridRow} /> :
                  <ButtonAddRow alt="" fill={this.inactiveColor} id="GridAddG" title={`Add Grid Row Disabled`} className="functionImage" />}
                {this.state.showExcelExport === true ?
                  <ButtonExportCSV alt="" fill={this.activeColor} id="ExcelExport" title={`Export Grid to Excel`} className="functionImage" onClick={this.onExcelExport} /> :
                  <ButtonExportCSV alt="" fill={this.inactiveColor} id="ExcelExportG" title={`Export Grid Disabled`} className="functionImage" />}
              </Tooltip>
            </div>
            : null}

          <div id="headerTopLeft">
            <HelpAbout fill={this.activeColor} alt="" id="ShowHelp" title="Show Help for Current Page" className="showHelpImage" onClick={this.ShowHelp} /> 
          </div>

          <div id="headerBotLeft">
            <div id="leftButtons" className="buttonDivLeft">
              {this.state.showPaneClose === true &&
                <img src={AngleLeft} alt="" id="PaneClose" title="Close Left Pane" className="functionImage" onClick={this.onPaneClose} />}
              {this.state.showPaneOpen === true &&
                <img src={AngleRight} fill={this.activeColor} alt="" id="PaneOpen" title="Open Left Pane" className="functionImage" onClick={this.onPaneOpen} />}
            </div>
            {this.state.appDisplay ?
              <div id="headerSearch">
                <div id="searchBtnDiv">
                  <Tooltip position="top" anchorElement="pointer">
                    <ButtonSearch alt="" fill={this.activeColor} id="ClearSearch" title={`Search Current Active Grid`} className="functionImage" onClick={this.searchButton} />
                  </Tooltip>
                </div>
                <div id="searchBoxDiv">
                  <FloatingLabel label={this.state.searchTitle} style={{ width: '300', color: 'darkblue' }} editorId={'search-id'} editorValue={this.state.searchValue}>
                    <Input id={'search-id'} value={this.state.searchValue} style={{ width: 210 }} onChange={evt => this.updateSearchValue(evt)} />
                  </FloatingLabel>
                </div>
                {this.state.searchValue.length > 0 || this.state.clearSetAll ?
                <div id="clearSearchBtnDiv">
                    <Tooltip position="top" anchorElement="pointer">
                      <ButtonClear alt="" fill={this.activeColor} id="ClearSearch" title={`Clear Search Box`} className="functionImage" onClick={this.ClearSearch} />
                   </Tooltip>
                  </div> :
                  <div id="clearSearchBtnDiv">
                    {SessionInfo.ShowSwitchSearch > 0 ?
                      <Tooltip position="top" anchorElement="pointer">
                        {this.state.searchFirst === true ?
                          <SwitchFirst alt="" fill={this.activeColor} id="SwitchSearch1" title={`Switch to Search Grid 1`} className="functionImage" onClick={this.SwitchToSecond} /> :
                          <SwitchSecond alt="" fill={this.activeColor} id="SwitchSearch2" title={`Switch to Search Grid 2`} className="functionImage" onClick={this.SwitchToFirst} />}
                      </Tooltip> : null}
                   </div> }
                <Popup offset={this.profileOffset} show={this.state.showProfilePopup} popupClass={'popup-content'} >
                  <div id="profilePopup" ref={(div) => { this.profileAnchor = div; }}>
                  <Form
                    onSubmit={this.handleSubmit}
                    render={(formRenderProps) => (
                      <FormElement style={{ maxWidth: 650 }}>
                        <fieldset className={'k-form-fieldset'}>
                          <legend className={'k-form-legend'}>Please fill in the fields:</legend>
                          <div className="mb-3">
                            <Field name={'firstName'} component={Input} label={'First name'} />
                          </div>

                          <div className="mb-3">
                            <Field name={'lastName'} component={Input} label={'Last name'} />
                          </div>

                          <div className="mb-3">
                            <Field name={"email"} type={"email"} component={EmailInput} label={"Email"} validator={emailValidator} />
                          </div>
                        </fieldset>
                        <div className="k-form-buttons">
                          <button type={'submit'} className="k-button" disabled={!formRenderProps.allowSubmit} >Submit</button>
                        </div>
                      </FormElement>
                    )}
                  />
                </div></Popup>
              </div>
              : null}
            <Popup offset={this.notifyOffset} show={this.state.showNotify} popupClass={'popup-notifycontent'} ><div id="notifyBoxPU" dangerouslySetInnerHTML={{ __html: this.state.notifyMessage }}></div></Popup>
            {this.state.dialogVisible &&
              <Dialog title={"Please confirm"} onClose={this.toggleDialog}>
                <p style={{ margin: "25px", textAlign: "center" }}>Are you sure you want to continue?</p>
                <DialogActionsBar>
                  <button className="k-button" onClick={this.toggleDialog}>No</button>
                  <button className="k-button" onClick={this.toggleDialog}>Yes</button>
                </DialogActionsBar>
              </Dialog>}
          </div>

          {(this.state.appDisplay || this.state.msgDisplay || this.state.showMessagePopup) && this.state.showMessage ?  // Show the Message box in the top left - may not be visible all of the time                            
            <div id="headerTopMid" style={{ width: this.messageWidth }}> {/* Message Box */}
              <ButtonClear fill={this.activeColor} alt="" id="ClearMessage" className="messageImage" onClick={this.ClearMessage} />
              {this.state.showMessagePopup ? <ArrowUp fill={this.activeColor} alt="" id="PopupMessage" className="popupImage" onClick={this.ClosePopupMessage} /> :
                <ArrowDown fill={this.activeColor} alt="" id="PopupMessage" className="popupImage" onClick={this.PopupMessage} />}
              <Popup anchor={this.messageAnchor} show={this.state.showMessagePopup} popupClass={'popup-content'} ><div id="messageBoxPU" dangerouslySetInnerHTML={{ __html: this.state.MessagesValue }}></div></Popup>
              <div id="messageBox" dangerouslySetInnerHTML={{ __html: this.state.MessagesValue }} ref={(div) => { this.messageAnchor = div; }}></div>
            </div> : null}
          {this.state.appDisplay || this.state.msgDisplay ?  // Show the Message box in the top left - may not be visible all of the time 
                  <div id="headerTopMidIcon"> {/* Message Box Expand Icon (Triangle) */}
              <Tooltip position="top" anchorElement="pointer">
              {SessionInfo.ForceShowMessageBox ? <ArrowUp fill={this.activeColor} alt="" id="ShowMessageBox" title="Show the Message Box to Display Application Messages" className="showMessageImage" onClick={this.ShowMessageBox} />
                  : <ArrowDown fill={this.activeColor} alt="" id="ShowMessageBox" title="Hide the Message Box" className="showMessageImage" onClick={this.ShowMessageBox} />}
                </Tooltip>
            </div>
            : null}
          {this.state.appDisplay || this.state.msgDisplay || (SessionInfo.loggedOn === true && SessionInfo.tempLogon === false) ?
            <div id="statusDivMid">
              {this.state.showFlag1 ? <StopLight alt="" id="Flag1" fill={this.okColor} className="statusImage" /> : null}
              {this.state.showFlag2 ? <StopLight alt="" id="Flag2" fill={this.warnColor} className="statusImage" /> : null}
              {this.state.showFlag3 ? <StopLight alt="" id="Flag3" fill={this.errorColor} className="statusImage" /> : null}
            </div> : null}

          <div id="headerTopRight">
            <div id="clockDiv">
              {hours === 0 ? 12 : (hours > 12) ? hours - 12 : hours}:
              {minutes > 9 ? minutes : `0${minutes}`} {/*:{seconds > 9 ? seconds : `0${seconds}`} {ampm}*/}
            </div>    
            <div id="dateDiv">
              {this.state.currentDate}
            </div>
            {SessionInfo.loggedOn === true && SessionInfo.tempLogon === false ?
              <div id="avatarDiv">
                <div id="logonName" style={{ cursor: 'pointer' }} onClick={this.gotoAccountInfo}>
                  {SessionInfo.PersonInfo.FirstName} {SessionInfo.PersonInfo.LastName}
                </div>
                <div style={{ border: 'none',width: 36,height: 36,justifyContent: "center",alignItems: "center" }} onClick={this.gotoAccountInfo}> {/* onContextMenu={this.handleContextMenu}>*/}
                  <Avatar type='image' size='medium' shape="circle" >
                    <img src={this.state.PersonPhotoURL} style={{ width: 35,height: 35 }} />
                  </Avatar>
                  <Popup show={this.state.showCMenu} offset={this.cmenuOffset}>
                      <Menu vertical={true} style={{ display: 'inline-block' }} onSelect={this.menuChoice}>
                        <MenuItem text="My Profile Settings" />
                        <MenuItem text="Logout" />
                      </Menu>
                    </Popup>
                </div>
              </div> : null}
          </div>

          <div id="headerBotRight">
            <h2>{this.state.headerTitle}</h2>
            {SessionInfo.slimHeader === false ?
              <div id="menuCntr">
                <ul>
                  <li><Link style={{ color: this.state.colors[0] }} to="/AppMain" className="select">{this.props.L1}</Link></li>
                  {/*{SessionInfo.loggedOn === true ? <li><Link id="1000" style={{ color: this.state.colors[1] }} to="/StoreLocations">Locations</Link></li> : null} */}
                  {SessionInfo.loggedOn === true ? <li><Link id="1001" style={{ color: this.state.colors[2] }} to="/StoreLocationDetail">Stores</Link></li> : null}
                  {SessionInfo.loggedOn === true ? <li><Link id="1002" style={{ color: this.state.colors[3] }} to="/StoreItemDetail">Products</Link></li> : null}
                  {SessionInfo.loggedOn === true ? <li><Link id="1004" style={{ color: this.state.colors[5] }} to="/ManagerPage">Manager</Link></li> : null}
                  {SessionInfo.loggedOn === true ? <li><Link id="1003" style={{ color: this.state.colors[4] }} to="/CustomerProfile">Accounts</Link></li> : null}
                </ul>
              </div> : null}
            <div id="rightButtons" className="buttonDivRight">
              {this.state.showRPaneClose === true &&
                <img src={AngleRight} alt="" id="PaneRClose" title="Close Right Pane - Change Search Focus" className="functionImage" onClick={this.onRPaneClose} />}
              {this.state.showRPaneOpen === true &&
                <img src={AngleLeft} alt="" id="PaneROpen" title="Open Right Pane - Change Search Focus" className="functionImage" onClick={this.onRPaneOpen} />}
            </div>
          </div>
          {SessionInfo.slimHeader === false ?
            <div id="headerBotStrip">
            </div> : null}
        </div>
      )
    }
  }
}
PageHeader.propTypes = {
  counts: PropTypes.array,
  users: PropTypes.arrayOf(PropTypes.object),
  alarmColor: PropTypes.oneOf(['red', 'blue'])
}
//PageHeader.contextType = SessionContext;

export default withRouter(PageHeader);