//----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// AppCommon - Application Specific Common functionality
//              Version 1.001 - July 11, 2023
// Note - this is the InteractManager - Manager Version of AppCommon
//----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { SessionInfo } from "./App";
import { Surface,Path,Text,Group,Image,LinearGradient,geometry } from '@progress/kendo-drawing';
import { CallOM,OMTrace,displayWarning,LogOffResetMsg,LogOffReset,CTYP } from './CommonCode.js';


//-------------------------------------------------------------------------------------------------------------------------------------------
// App Configuration
//-------------------------------------------------------------------------------------------------------------------------------------------
const EntityConfigurationO = [{ n: 'EntityConfigurationID',t: 195,p: 100 },{ n: 'CompanyID',t: 195,p: 100 },{ n: 'StorePromotionID',t: 195,p: 100 },{ n: 'Promo1Link',t: 21,p: 0 },{ n: 'StorePromotion2',t: 195,p: 100 },{ n: 'Promo2Link',t: 21,p: 0 },{ n: 'StorePromotion3',t: 195,p: 100 },{ n: 'Promo3Link',t: 21,p: 0 },{ n: 'StorePromotion4',t: 195,p: 100 },{ n: 'Promo4Link',t: 21,p: 0 },{ n: 'GreetingMessage',t: 21,p: 0 },{ n: 'SecondMessage',t: 21,p: 0 },{ n: 'InputTextColor',t: 21,p: 0 },{ n: 'InputBGColor',t: 21,p: 0 },{ n: 'InputBorderColor',t: 21,p: 0 },{ n: 'ButtonTextColor',t: 21,p: 0 },{ n: 'ButtonBGColor',t: 21,p: 0 },{ n: 'TextColor',t: 21,p: 0 },{ n: 'PromptColor',t: 21,p: 0 },{ n: 'SelectColor',t: 21,p: 0 },{ n: 'BrandLogo',t: 41,p: 0 },{ n: 'BackgroundImage',t: 41,p: 0 },{ n: 'ListBGColor',t: 21,p: 0 },{ n: 'ListHeadBGColor',t: 21,p: 0 },{ n: 'ListSectionBGColor',t: 21,p: 0 },{ n: 'DefaultStoreLocationID',t: 24,p: 0 },{ n: 'DefaultOrderType',t: 24,p: 0 },{ n: 'GeoFenceMeters',t: 24,p: 0 },{ n: 'RegistrationCode',t: 21,p: 0 },{ n: 'BaseDayOfWeek',t: 24,p: 0 },{ n: 'Tax1Percent',t: 16,p: 8 },{ n: 'Tax2Percent',t: 16,p: 8 },{ n: 'Tax3Percent',t: 16,p: 8 },{ n: 'ApplicationConfiguration',t: 15,p: 0 },{ n: 'LayoutConfiguration',t: 15,p: 0 },{ n: 'LastUpdatedOn',t: 35,p: 0 },{ n: 'ProcessCommand',t: 201,p: 0 }];
//       Object Value Definition For EntityConfiguration
const ECV = { 'EntityConfigurationID': 0,'CompanyID': 0,'StorePromotionID': 0,'Promo1Link': '','StorePromotion2': 0,'Promo2Link': '','StorePromotion3': 0,'Promo3Link': '','StorePromotion4': 0,'Promo4Link': '','GreetingMessage': '','SecondMessage': '','InputTextColor': '','InputBGColor': '','InputBorderColor': '','ButtonTextColor': '','ButtonBGColor': '','TextColor': '','PromptColor': '','SelectColor': '','BrandLogo': '','BackgroundImage': '','ListBGColor': '','ListHeadBGColor': '','ListSectionBGColor': '','DefaultStoreLocationID': 0,'DefaultOrderType': 0,'GeoFenceMeters': 0,'RegistrationCode': '','BaseDayOfWeek': 0,'Tax1Percent': 0,'Tax2Percent': 0,'Tax3Percent': 0,'ApplicationConfiguration': 0,'LayoutConfiguration': 0,'LastUpdatedOn': new Date(),'ProcessCommand': 0 };
//       Object Index Definition For EntityConfiguration
const ECX = { 'EntityConfigurationID': 0,'CompanyID': 1,'StorePromotionID': 2,'Promo1Link': 3,'StorePromotion2': 4,'Promo2Link': 5,'StorePromotion3': 6,'Promo3Link': 7,'StorePromotion4': 8,'Promo4Link': 9,'GreetingMessage': 10,'SecondMessage': 11,'InputTextColor': 12,'InputBGColor': 13,'InputBorderColor': 14,'ButtonTextColor': 15,'ButtonBGColor': 16,'TextColor': 17,'PromptColor': 18,'SelectColor': 19,'BrandLogo': 20,'BackgroundImage': 21,'ListBGColor': 22,'ListHeadBGColor': 23,'ListSectionBGColor': 24,'DefaultStoreLocationID': 25,'DefaultOrderType': 26,'GeoFenceMeters': 27,'RegistrationCode': 28,'BaseDayOfWeek': 29,'Tax1Percent': 30,'Tax2Percent': 31,'Tax3Percent': 32,'ApplicationConfiguration': 33,'LayoutConfiguration': 34,'LastUpdatedOn': 35,'ProcessCommand': 36 };

export async function GetConfigInfo() {
  try {
    let CD = await CallOM("GetEntityConfigurationForEntity",0,0);
    let row = CD.d;
    //this.setState({ EntityConfigurations: row });
    EntityConfigurationO.forEach((elem) => {
      if (elem.n in row) {
        try {
          if (elem.t === 34 || elem.t === 35) { // Date, DateTime
            ECV[elem.n] = new Date(row[elem.n]);
          } else if (elem.t === 21 || elem.t === 195 || elem.t === 201) { // String, FK-Translated, Enum
            ECV[elem.n] = row[elem.n];
          } else if (elem.t === 28) { // Money
            ECV[elem.n] = row[elem.n];
          } else if (elem.t === 41) { // Media - Image or Document
            ECV[elem.n] = row[elem.n];
          } else if (elem.t === 15) { // Encoded
            ECV[elem.n] = row[elem.n];
          } else {
            ECV[elem.n] = row[elem.n];
          }
        }
        catch (error) {
          console.log(`Set EntityConfigurationO Field: ${elem.n}, Error: ${error.message}`);
        }
      }
    });
    //if (row.DefaultTableID)
    //  ECV.DefaultTableID = row.DefaultTableID;
    //if (row.DefaultTableName)
    //  ECV.DefaultTableName = row.DefaultTableName;
    //console.log("===> EntityConfiguration: " + JSON.stringify(ECV));
    //if (!SessionInfo.StoreLocationID)
    //  SessionInfo.StoreLocationID = ECV.DefaultStoreLocationID;  
    SessionInfo.ApplicationTypeID = ECV.ApplicationTypeID;
    CD = await CallOM("GetLogoImage",0,0); // From Company record
    SessionInfo.LogoImageURL = imagePrep(CD.d.Item2);
    let imageID = ECV.BackgroundImage;
    SessionInfo.BackgroundImageURL = await getImageFileFromServer(imageID,5);
    //SessionInfo.divStyle = { position: 'absolute', top: '135px', left: '0', right: '0', backgroundImage: `url(${SessionInfo.BackgroundImageURL})`, }; images / rest2.jpg
    SessionInfo.divStyle = { position: 'absolute',top: '135px',left: '0',right: '0',backgroundImage: 'url(images/rest2.jpg)',};
    console.log(`divStyle=${JSON.stringify(SessionInfo.divStyle)}`);
    console.log(SessionInfo.divStyle);
    //
    //--
    // ---- Setup CSS Variable - Menu App ----
    //document.documentElement.style.setProperty('--InputTextColor',ECV.InputTextColor);
    //document.documentElement.style.setProperty('--InputBGColor',ECV.InputBGColor);
    //document.documentElement.style.setProperty('--InputBorderColor',ECV.InputBorderColor);
    //document.documentElement.style.setProperty('--ButtonTextColor',ECV.ButtonTextColor);
    //SessionInfo.activeColor = ECV.ButtonTextColor; // Used for Menu
    //document.documentElement.style.setProperty('--ButtonBGColor',ECV.ButtonBGColor);
    //document.documentElement.style.setProperty('--HeadingTextColor',ECV.HeadingTextColor);
    //document.documentElement.style.setProperty('--TextColor',ECV.TextColor);
    //document.documentElement.style.setProperty('--PromptColor',ECV.PromptColor);
    //document.documentElement.style.setProperty('--BrandLogo',ECV.BrandLogo);
    //document.documentElement.style.setProperty('--ListBGColor',ECV.ListBGColor);
    //document.documentElement.style.setProperty('--ListHeadBGColor',ECV.ListHeadBGColor);
    //console.log(`--> ListSection: ${ECV.ListSectionBGColor}`);
    //document.documentElement.style.setProperty('--ListSectionBGColor',ECV.ListSectionBGColor);
    // ---- Setup Configuration Controls ----
    SessionInfo.AppCfg = ECV.ApplicationConfiguration;
    console.log(`----> AppCfg: ${SessionInfo.AppCfg}, HasScn: ${ESessionInfo.AppCfg & ENAppCnfg.HasScanAndPay},  & HasScn: ${SessionInfo.AppCfg & ENAppCnfg.HasScanAndPay}`);

    //SessionInfo.Config.HasScanAndPay: false,
    //SessionInfo.Config.HasOrderAndPay: false,
    //SessionInfo.Config.ScanPayIndividualItems: false,
    //SessionInfo.Config.HasIngredients: false,
    //SessionInfo.Config.HasOrderToTable: false,
    //SessionInfo.Config.HasDelivery: false,
    //SessionInfo.Config.HasTip: false,
    //SessionInfo.Config.HasItemLookup: false,
    //SessionInfo.Config.HasRecipeLookup: false,
    //SessionInfo.Config.HasStorePromotion: false,
    //SessionInfo.Config.HasAccountInfo: false,
    //SessionInfo.Config.HasGiftCards: false,
    //SessionInfo.Config.HasCoupons: false,
    //SessionInfo.Config.HasShoppingList: false,
    //SessionInfo.Config.HasCategoryPage: false,
    //SessionInfo.Config.HasPartialPayment: false,
    //SessionInfo.Config.HasSeats: false,
    //SessionInfo.Config.HasGuestInvite: false,
    //SessionInfo.Config.HasDiscounts: false,
    //SessionInfo.Config.HasCredit: false,
    //SessionInfo.Config.HasReturns: false,
    //SessionInfo.Config.HasTax1: false,
    //SessionInfo.Config.HasTax2: false,
    //SessionInfo.Config.HasTax3: false,
    //}
    if ((SessionInfo.AppCfg & ENAppCnfg.HasScanAndPay) > 0)
      SessionInfo.HasScanAndPay = true;
    else
      SessionInfo.HasScanAndPay = false;

    if (SessionInfo.CurrentTableID <= 0) {
      SessionInfo.CurrentTableID = ECV.DefaultTableID;
      SessionInfo.CurrentTableName = ECV.DefaultTableName;
      setCookie('IMSublocationID',SessionInfo.CurrentTableID,30);
    }
    console.log(`HasSeats: ${SessionInfo.AppCfg & ENAppCnfg.HasSeats},  AppCfg: ${SessionInfo.AppCfg}, Seats: ${ENAppCnfg.HasSeats}`);
    let LayoutCfg = ECV.LayoutConfiguration;
    if ((LayoutCfg & ENLayoutCnfg.HasMenuImage) > 0)
      SessionInfo.HasMenuImage = true; // Background Image for Order/Scan Screen
    else
      SessionInfo.HasMenuImage = false;
    if ((LayoutCfg & ENLayoutCnfg.UseAlternateItem) > 0)
      SessionInfo.useAlternateFormat = true
    console.log(`ScanPay: ${SessionInfo.HasScanAndPay}, MenuImage: ${SessionInfo.HasMenuImage}, BGColor: ${ECV.ListBGColor}`);
    // ---- Set Colors ----
    let rgb = hexToRgb(ECV.ListBGColor); // 'rgba(240, 240, 240, 0.75)';
    console.log(`rgb: ${JSON.stringify(rgb)}`);
    if (SessionInfo.HasMenuImage === true)
      SessionInfo.orderBG = `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${rgb.o})`
    else
      SessionInfo.orderBG = `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${rgb.o})`
    console.log(`orderBG: ${SessionInfo.orderBG}`);
    // ---- Call Menu Setup ----
    SessionInfo.AppMainRef.SetupMenu();
  }
  catch (error) {
    console.log(`*** Error - GetEntityConfiguration: ${error.message}`);
  }
}
//-------------------------------------------------------------------------------------------------------------------------------------------
// Table Information
//-------------------------------------------------------------------------------------------------------------------------------------------

export const gradient1 = new LinearGradient({
  name: "LG1",
  stops: [
    {
      offset: 0,
      color: "#ffb3e6",
      opacity: 1
    },{
      offset: 0.5,
      color: "#ffb3e6",
      opacity: 0.8
    }
  ]
});
export const gradient2 = new LinearGradient({
  name: "LG2",
  stops: [
    {
      offset: 0,
      color: "#b3e6ff",
      opacity: 1
    },{
      offset: 0.5,
      color: "#b3e6ff",
      opacity: 0.8
    }
  ]
});
export const gradient3 = new LinearGradient({
  name: "LG3",
  stops: [
    {
      offset: 0,
      color: "#b3ffb3",
      opacity: 0.8
    },{
      offset: 0.5,
      color: "#b3ffb3",
      opacity: 0.8
    }
  ]
});
const red = "#ff33bb";
const blue = "#33bbff";
const green = "#1aff1a"; 
// Control arrays
var TabMod = [];
var Sublocs = [];
export var Tables = [];
export var Groups = [];

export function getAmenities(AList) {
  let StoreAmenities = 0x0000000000000000;
  if (AList.includes("Beer"))
    StoreAmenities |= 0x0000000000000800;
  if (AList.includes("Coffee"))
    StoreAmenities |= 0x0000000000000004;
  if (AList.includes("Desserts"))
    StoreAmenities |= 0x0000000000000800;
  if (AList.includes("Salads"))
    StoreAmenities |= 0x0000000000000200;
  if (AList.includes("Soups"))
    StoreAmenities |= 0x0000000000000040;
  if (AList.includes("Hot Meals"))
    StoreAmenities |= 0x0000000000000080;
  if (AList.includes("Specialty Coffee"))
    StoreAmenities |= 0x0000000000000008;
  if (AList.includes("Wifi Available"))
    StoreAmenities |= 0x0000000000000002;
  if (AList.includes("Wine"))
    StoreAmenities |= 0x0000000000000400;
  console.log(`Set Amenities: ${StoreAmenities}`);
  //console.log("StoreAmenitiesVals: " + this.state.StoreAmenitiesVals);
  return StoreAmenities;
}
// Load table information for the current Location from the OM
export async function LoadTableInformation(storeLocationID) {
  Sublocs = [];
  Tables = [];
  console.log(`Load TableInformation for Location: ${storeLocationID}`);
  let CD = await CallOM("GetTableLocations",0,storeLocationID,0);
  //console.log(`Locations: ${JSON.stringify(CD.d)}`);
  let tables = CD.d.rows;
  tables.forEach(row => {
    let color = green;
    let grad = gradient3;
    if (row.LocationSizeID > 2) {
      color = blue;
      grad = gradient2;
      if (row.LocationSizeID > 5) {
        color = red;
        grad = gradient1;
      }
    }
    let wid = parseInt(row.LocationWidth);
    if (wid == 0)
      wid = 3;
    let lth = parseInt(row.LocationLength);
    if (lth == 0)
      lth = 2;
    Sublocs.push({ x: parseInt(row.PositionX),y: parseInt(row.PositionY),width: wid * 25,length: lth * 25,rx: 5,border: color,fill: grad,name: row.Sublocation,desc: row.Description,id: row.SublocationID });
    TabMod.push(false);
  });

}
// Load the Table Information in prepartion for display in Drawing
export async function GetTableInformation() { // Convet Sublocations to Tables
  if (Tables.length == 0) {             
    Tables = [];
    try {
      console.log(`Load Tables - Sublocs cnt: ${Sublocs.length}`);
      let cnt = 0;
      let ycnt = 10;
      Sublocs.forEach(tbl => {
        let xpos = tbl.x;
        let ypos = tbl.y;
        if (ypos == 0) {
          ypos = ycnt;
          ycnt += tbl.length + 10;
          xpos = 100;
        }
        let tab = { x: xpos,y: ypos,width: tbl.width,length: tbl.length,rx: 5,border: tbl.border,fill: tbl.fill,name: tbl.name,desc: tbl.desc,newX: 0,newY: 0,Xoff: 0,Yoff: 0,id: tbl.id };
        Tables.push(tab);
        //console.log(`Table-row: ${cnt++}: ${JSON.stringify(tab)}`); 
        //console.log(`Get Table: ${tab.name} - X: ${tab.x}-${tbl.x}, Y: ${tab.y}-${tbl.y}`);
      });
    } catch (ex) {
      console.log(`Error in GetTableInformation - ${ex}`);
    }
    console.log(`Tables cnt: ${Tables.length}`);
  }
  Groups = [];
}
export async function UpdateTableInformation() {
  let ix = 0;
  Tables.forEach(tbl => {
    //console.log(`Update Table: ${tbl.name} - newX: ${tbl.newX}, newY: ${tbl.newY}`);
    if (tbl.newX != 0) {
      tbl.x = Math.round(tbl.newX - tbl.Xoff);
      tbl.y = Math.round(tbl.newY - tbl.Yoff);;
      tbl.newX = 0;
      tbl.newY = 0;
      tbl.mod = true;
      TabMod[ix] = true;
    }
    ix++;
  });
}
export async function SaveTableInformation() {
  console.log(`Save Table Information`);
  for (let ix = 0; ix < Tables.length; ix++) {
    let tbl = Tables[ix];
    if (TabMod[ix] == true) {
      console.log(`Save Table: ${tbl.name} - newX: ${tbl.x}, newY: ${tbl.y}`);
      let xyList = [tbl.x,tbl.y];
      let CD = await CallOM("SaveTableLocation",0,tbl.id,CTYP.INTA,xyList);
      TabMod[ix] = false;
      Sublocs[ix].x = tbl.x;
      Sublocs[ix].y = tbl.y;
    }
  }
  Tables = [];
}